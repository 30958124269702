import { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import exceljs from 'exceljs';
import fileSaver from 'file-saver';
import ExcelPedidoBase64 from './excelPedidoBase64/excelPedidoBase64';
import TemplateTxtPedido from './txtTemplatePedido/txtTemplatePedido';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import LerXml from '../../../../components/functions/lerXml';
import { xml2json } from 'xml-js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import { AnyAaaaRecord } from 'dns';
import { useNavigate } from 'react-router-dom';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';
function CriacaoDePedidos() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoPedidoVisualizarImpArquivo, setPermissaoPedidoVisualizarImpArquivo] = useState<boolean>(false);
	const [permissaoPedidoImportarArquivo, setPermissaoPedidoImportarArquivo] = useState<boolean>(false);
	const [permissaoPedidoVisualizarNFeParaPedido, setPermissaoPedidoVisualizarNFeParaPedido] = useState<boolean>(false);
	const [permissaoPedidoImportarPedido, setPermissaoPedidoImportarPedido] = useState<boolean>(false);
	const [permissaoPedidoVisualizarPedidoManual, setPermissaoPedidoVisualizarPedidoManual] = useState<boolean>(false);
	const [permissaoCriarPedido, setPermissaoCriarPedido] = useState<boolean>(false);
	const [permissaoAdicionarItem, setPermissaoAdicionarItem] = useState<boolean>(false);
	const [permissaoExcluirItem, setPermissaoExcluirItem] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (
				!permissoes.perfilPermissao.visualizarImportacaoArquivo &&
				!permissoes.perfilPermissao.visualizarNfeParaPedido &&
				!permissoes.perfilPermissao.visualizarPedidoManual
			) {
				toast.warning('Você não tem permissão para acessar Criação de Pedidos');
				navigate('/');
			} else {
				setPermissaoPedidoVisualizarImpArquivo(permissoes.perfilPermissao.visualizarImportacaoArquivo);
				setPermissaoPedidoImportarArquivo(permissoes.perfilPermissao.importarArquivo);
				setPermissaoPedidoVisualizarNFeParaPedido(permissoes.perfilPermissao.visualizarNfeParaPedido);
				setPermissaoPedidoImportarPedido(permissoes.perfilPermissao.importarNfeParaPedido);
				setPermissaoPedidoVisualizarPedidoManual(permissoes.perfilPermissao.visualizarPedidoManual);
				setPermissaoCriarPedido(permissoes.perfilPermissao.criarPedidoManual);
				setPermissaoAdicionarItem(permissoes.perfilPermissao.adicionarItemPedidoManual);
				setPermissaoExcluirItem(permissoes.perfilPermissao.excluirItemPedidoManual);
			}
		}
	}, [permissoes, erro, navigate]);

	const [showCarregando, setShowCarregando] = useState(false);
	const [showModalImportacao, setShowModalImportacao] = useState(false);
	const [ListaPedidosImportados, setlistaPedidosImportados] = useState<any>([]);
	const [ListaDetalhePedidos, setListaDetalhePedidos] = useState<any>([]);
	const [ListaPedidos, setlistaPedidos] = useState([]);
	async function LerExcelPedido() {
		let erroAoLerLinha = false;
		if (DepositanteSelecionado == '') {
			const InputFileExcel = document.querySelector('#importExcel') as HTMLInputElement;
			if (InputFileExcel) {
				InputFileExcel.value = '';
			}
			toast.error('Selecione um depositante', {
				autoClose: 900,
			});
			return;
		} else if (ListaPedidosImportados.length > 0) {
			try {
				setShowModalImportacao(false);
				setShowCarregando(true);
				const pedidosCabecalho: any = [];
				const pedidosDetalhe: any = [];
				for (let i = 0; i < ListaPedidosImportados.length; i = i + 1) {
					const file = ListaPedidosImportados[i];
					const arrayBuffer = await file.arrayBuffer();
					const workbook = new exceljs.Workbook();
					await workbook.xlsx.load(arrayBuffer);
					const worksheet = workbook.getWorksheet(1);
					worksheet?.eachRow(function (row, index) {
						if (index > 1) {
							if (DepositanteSelecionado == row.getCell(1).value) {
								if (row.getCell(1).value == null) {
									toast.error('Campo CNPJ vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(2).value == null) {
									toast.error('Campo DATA CARGA vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(3).value == null) {
									toast.error('Campo NUMERO PEDIDO vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(4).value == null) {
									toast.error('Campo CODIGO PRODUTO vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(5).value == null) {
									toast.error('Campo QTDE EMBALAGENS vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(6).value == null) {
									toast.error('Campo TIPO EMBALAGEM vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(7).value == null) {
									toast.error('Campo DESTINATARIO vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (row.getCell(8).value == null) {
									toast.error('Campo ROTEIRO vazio na linha: ' + index, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else {
									const PedidoCabecalhoEncontrado = pedidosCabecalho.filter(function (pedido: any) {
										return pedido.cnpj_depositante == row.getCell(1).value && pedido.num_pedido == row.getCell(3).value;
									});
									/*
                                    cnpj_depositante: row.getCell(1).value,
                                            data_carga: row.getCell(2).value,
                                            num_pedido: row.getCell(3).value,
                                            cod_produto: row.getCell(4).value,
                                            qtde_emb: row.getCell(5).value,
                                            tipo_emb: row.getCell(6).value,
                                            destinatario: row.getCell(7).value,
                                            roteiro: row.getCell(8).value,
                                            restricao: row.getCell(9).value,
                                            lote: row.getCell(10).value
                                    */
									if (PedidoCabecalhoEncontrado.length == 0) {
										//cria cabecalho de um pedido
										pedidosCabecalho.push({
											cnpj_depositante: row.getCell(1).value,
											data_carga: row.getCell(2).value,
											num_pedido: row.getCell(3).value,
											destinatario: row.getCell(7).value,
											roteiro: row.getCell(8).value,
											qtde_emb: row.getCell(5).value,
										});
										pedidosDetalhe.push({
											cnpj_depositante: row.getCell(1).value,
											num_pedido: row.getCell(3).value,
											cod_produto: row.getCell(4).value,
											qtde_emb: row.getCell(5).value,
											tipo_emb: row.getCell(6).value,
											restricao: row.getCell(9).value,
											lote: row.getCell(10).value,
										});
									} else if (PedidoCabecalhoEncontrado.length == 1) {
										//atualiza qtde de embalagens do pedido
										const indexObj_pedido = pedidosCabecalho.findIndex(function (pedido: any) {
											return pedido.cnpj_depositante == row.getCell(1).value && pedido.num_pedido == row.getCell(3).value;
										});
										pedidosCabecalho[indexObj_pedido].qtde_emb =
											pedidosCabecalho[indexObj_pedido].qtde_emb + row.getCell(5).value;
										//adiciona apenas o produto ao pedido
										const produtoEncontrado = pedidosDetalhe.filter(function (produto: any) {
											return (
												produto.num_pedido == row.getCell(3).value &&
												produto.cod_produto == row.getCell(4).value &&
												produto.tipo_emb == row.getCell(6).value
											);
										});
										if (produtoEncontrado.length == 0) {
											pedidosDetalhe.push({
												cnpj_depositante: row.getCell(1).value,
												num_pedido: row.getCell(3).value,
												cod_produto: row.getCell(4).value,
												qtde_emb: row.getCell(5).value,
												tipo_emb: row.getCell(6).value,
												restricao: row.getCell(9).value,
												lote: row.getCell(10).value,
											});
										} else {
											//se o produto ja existe para o pedido, altera a penas a qtde de embalagens
											const indexObj_produto = pedidosDetalhe.findIndex(function (produto: any) {
												return (
													produto.num_pedido == row.getCell(3).value &&
													produto.cod_produto == row.getCell(4).value &&
													produto.tipo_emb == row.getCell(6).value
												);
											});
											pedidosDetalhe[indexObj_produto].qtde_emb =
												pedidosDetalhe[indexObj_produto].qtde_emb + row.getCell(5).value;
										}
									} else {
										toast.error('Erro ao ler linha: ' + index + ' Importação cancelada.', {
											autoClose: 600,
										});
										return;
									}
								}
							} else {
								toast.error('CNPJ da linha: ' + index + ' não confere com o CNPJ do Depositante selecionado', {
									autoClose: 6000,
								});
								erroAoLerLinha = true;
								return;
							}
						} else if (worksheet.rowCount == index) {
							toast.error('Excel não possuí campos preenchidos.', {
								autoClose: 6000,
							});
							erroAoLerLinha = true;
							return;
						}
					});
				}
				if (!erroAoLerLinha) {
					setlistaPedidos(pedidosCabecalho);
					setListaDetalhePedidos(pedidosDetalhe);
					toast.info('Listando pedidos importados.', {
						autoClose: 900,
					});
				}
				setShowCarregando(false);
				const InputFileExcel = document.querySelector('#importExcel') as HTMLInputElement;
				if (InputFileExcel) {
					InputFileExcel.value = '';
				}
			} catch (error: any) {
				toast.error(error.message, {
					autoClose: 900,
				});
				setShowCarregando(false);
				const InputFileExcel = document.querySelector('#importExcel') as HTMLInputElement;
				if (InputFileExcel) {
					InputFileExcel.value = '';
				}
			}
		}
	}
	useEffect(
		function () {
			if (ListaPedidosImportados.length > 0) {
				LerExcelPedido();
			}
		},
		[ListaPedidosImportados]
	);
	const [showModalConfirmacaoLimparPedidos, setshowModalConfirmacaoLimparPedidos] = useState(false);
	function LimparPedidos() {
		setlistaPedidos([]);
		setshowModalConfirmacaoLimparPedidos(false);
	}
	const ColunaListaPedidos: ITabela[] = [
		{
			titulo: 'CNPJ Depositante',
			acesso: 'cnpj_depositante',
		},
		{
			titulo: 'Data Carga',
			acesso: 'data_carga',
		},
		{
			titulo: 'N° Pedido',
			acesso: 'num_pedido',
		},
		{
			titulo: 'Qtde Emb',
			acesso: 'qtde_emb',
		},
		{
			titulo: 'Destinatario',
			acesso: 'destinatario',
		},
		{
			titulo: 'Roteiro',
			acesso: 'roteiro',
		},
	];
	const [itensPorPaginaListaPedidos, setItensPorPaginaListaPedidos] = useState(10);
	const [showModalDetalhesPedido, setShowModalDetalhesPedidos] = useState(false);
	const colunaItensPedido: ITabela[] = [
		{
			titulo: 'CNPJ Depositante',
			acesso: 'cnpj_depositante',
		},
		{
			titulo: 'N° Pedido',
			acesso: 'num_pedido',
		},
		{
			titulo: 'Cód.Produto',
			acesso: 'cod_produto',
		},
		{
			titulo: 'Qtde Emb',
			acesso: 'qtde_emb',
		},
		{
			titulo: 'Qtde Emb',
			acesso: 'qtde_emb',
		},
		{
			titulo: 'Tipo Emb',
			acesso: 'tipo_emb',
		},
		{
			titulo: 'Restrição',
			acesso: 'restricao',
		},
		{
			titulo: 'Lote',
			acesso: 'lote',
		},
	];
	const [ListaDepositantes, setListaDepositantes] = useState([]);
	const [DepositanteSelecionado, setDepositanteSelecionado] = useState('');
	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/${id_filial}/${id_usuario}`, {
				headers: {
					authorization: token,
				},
			});
			setListaDepositantes(resposta.data.depositantes);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message);
		}
	}
	useEffect(function () {
		CarregarDepositantes(id_filial, id_usuario);
	}, []);
	const [ListaPedidosImportadosTXT, setlistaPedidosImportadosTXT] = useState<any>([]);
	useEffect(
		function () {
			if (ListaPedidosImportadosTXT.length > 0) {
				ImportarPedidoTXT();
			}
		},
		[ListaPedidosImportadosTXT]
	);
	function ImportarPedidoTXT() {
		try {
			let erroAoLerLinha = false;
			const QtdArquivos = ListaPedidosImportadosTXT.length;
			if (DepositanteSelecionado == '') {
				const InputFileExcel = document.querySelector('#importTXT') as HTMLInputElement;
				if (InputFileExcel) {
					InputFileExcel.value = '';
				}
				toast.error('Selecione um depositante', {
					autoClose: 900,
				});
				return;
			} else if (QtdArquivos > 0) {
				setShowModalImportacao(false);
				setShowCarregando(true);
				const pedidosCabecalho: any = [];
				const pedidosDetalhe: any = [];
				//ler .txt e gerar pedido
				for (let i = 0; i < QtdArquivos; i = i + 1) {
					const reader = new FileReader();
					reader.onload = function (event: any) {
						const pedidos = event.target?.result.split('|');
						for (let j = 1; j < pedidos.length; j = j + 1) {
							const conteudo = pedidos[j].split(';');
							if (conteudo[0] == DepositanteSelecionado) {
								if (conteudo[0] == '') {
									toast.error('Campo CNPJ vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[1] == '') {
									toast.error('Campo DATA CARGA vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[2] == '') {
									toast.error('Campo NUMERO PEDIDO vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[3] == '') {
									toast.error('Campo CÓDIGO PRODUTO vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[4] == '') {
									toast.error('Campo QTDE EMBALAGEM vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[5] == '') {
									toast.error('Campo TIPO EMBALAGEM vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[6] == '') {
									toast.error('Campo DESTINATARIO vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else if (conteudo[7] == '') {
									toast.error('Campo ROTEIRO vazio na linha: ' + j, {
										autoClose: 6000,
									});
									erroAoLerLinha = true;
									setShowCarregando(false);
									return;
								} else {
									const PedidoCabecalhoEncontrado = pedidosCabecalho.filter(function (pedido: any) {
										return pedido.cnpj_depositante == conteudo[0] && pedido.num_pedido == conteudo[2];
									});
									if (PedidoCabecalhoEncontrado.length == 0) {
										//cria cabecalho de um pedido
										pedidosCabecalho.push({
											cnpj_depositante: conteudo[0],
											data_carga: conteudo[1],
											num_pedido: conteudo[2],
											destinatario: conteudo[6],
											roteiro: conteudo[7],
											qtde_emb: parseFloat(conteudo[4]),
										});
										pedidosDetalhe.push({
											cnpj_depositante: conteudo[0],
											num_pedido: conteudo[2],
											cod_produto: conteudo[3],
											qtde_emb: parseFloat(conteudo[4]),
											tipo_emb: conteudo[5],
											restricao: conteudo[8],
											lote: conteudo[9],
										});
									} else if (PedidoCabecalhoEncontrado.length == 1) {
										//atualiza qtde de embalagens do pedido
										const indexObj = pedidosCabecalho.findIndex(function (pedido: any) {
											return pedido.cnpj_depositante == conteudo[0] && pedido.num_pedido == conteudo[2];
										});
										pedidosCabecalho[indexObj].qtde_emb = pedidosCabecalho[indexObj].qtde_emb + parseFloat(conteudo[4]);
										//consulta se produto já existe no pedido
										const ProdutoEncontrado = pedidosDetalhe.filter(function (produto: any) {
											return (
												produto.num_pedido == conteudo[2] &&
												produto.tipo_emb == conteudo[5] &&
												produto.cod_produto == conteudo[3]
											);
										});
										if (ProdutoEncontrado.length == 0) {
											//adiciona o produto ao pedido
											pedidosDetalhe.push({
												cnpj_depositante: conteudo[0],
												num_pedido: conteudo[2],
												cod_produto: conteudo[3],
												qtde_emb: parseFloat(conteudo[4]),
												tipo_emb: conteudo[5],
												restricao: conteudo[8],
												lote: conteudo[9],
											});
										} else {
											//atualiza a quantidade de embalagens do produto para o pedido
											const indexObj_produto = pedidosDetalhe.findIndex(function (produto: any) {
												return (
													produto.num_pedido == conteudo[2] &&
													produto.cod_produto == conteudo[3] &&
													produto.tipo_emb == conteudo[5]
												);
											});
											pedidosDetalhe[indexObj_produto].qtde_emb =
												pedidosDetalhe[indexObj_produto].qtde_emb + parseFloat(conteudo[4]);
										}
									} else {
										toast.error('Erro ao ler linha: ' + j + ' Importação cancelada.', {
											autoClose: 6000,
										});
										setShowCarregando(false);
										return;
									}
								}
							} else {
								toast.error('CNPJ na linha ' + j + ' não confere com o depositante selecionado.', {
									autoClose: 6000,
								});
								setShowCarregando(false);
								return;
							}
						}
						if (!erroAoLerLinha) {
							setlistaPedidos(pedidosCabecalho);
							setListaDetalhePedidos(pedidosDetalhe);
							toast.info('Listando pedidos importados.', {
								autoClose: 900,
							});
						}
						setShowCarregando(false);
						const InputFileExcel = document.querySelector('#importExcel') as HTMLInputElement;
						if (InputFileExcel) {
							InputFileExcel.value = '';
						}
					};
					reader.readAsText(ListaPedidosImportadosTXT[i]);
				}
			}
		} catch (error: any) {
			toast.error('Erro ao ler arquivos .txt: ' + error.message || error);
			setShowCarregando(false);
			const InputFileExcel = document.querySelector('#importTXT') as HTMLInputElement;
			if (InputFileExcel) {
				InputFileExcel.value = '';
			}
		}
	}
	const [DetalhesPedidoSelecionado, setDetalhesPedidoSelecionado] = useState([]);
	function MostrarDetalhesPedido(numPedido: any) {
		const detalhesPedido = ListaDetalhePedidos.filter(function (pedido: any) {
			return pedido.num_pedido == numPedido;
		});
		setDetalhesPedidoSelecionado(detalhesPedido);
		setShowModalDetalhesPedidos(true);
	}
	//#region AREA NFE
	const [pedidos, setpedidos] = useState([]);
	const [itensPorPaginaNFePedido, setItensPorPaginaNFePedido] = useState(10);
	const [ListaNotasImportadas, setListaNotasImportadas] = useState<any>([]);
	const [ListaNotasNaoImportadas, setListaNotasNaoImportadas] = useState<any>([]);
	const [showModalListaNF, setShowModalListaNF] = useState(false);
	const todasNotasFiscais = [
		{
			id_filial: '',
			nfestatus: '',
			nfechave: '',
			nfecuf: '',
			nfenatoper: '',
			nfecfop: '',
			nfemod: '',
			nfeserie: '',
			nfennf: '',
			nfedtemissao: '',
			nfedtsaida: '',
			nfentpnf: '',
			nfeiddest: '',
			nfecmunfg: '',
			tpimp: '',
			nfetpemis: '',
			nfecdv: '',
			nfetpamb: '',
			nfefinnfe: '',
			nfeindfinal: '',
			nfeindpres: '',
			nfeprocemi: '',
			nfeverproc: '',
			nfedhconting: '',
			nfejustconting: '',

			// DadosEmitente '',
			nfeemitcnpj: '',
			nfeemitcpf: '',
			nfeemitnome: '',
			nfeemitfantasia: '',
			nfeemitie: '',
			nfeemitiest: '',
			nfeemitcrt: '',

			// EnderecoEmitente '',
			nfeemitlograd: '',
			nfeemitnumero: '',
			nfeemitcompl: '',
			nfeemitbairro: '',
			nfeemitcmun: '',
			nfeemitmun: '',
			nfeemituf: '',
			nfeemitcep: '',
			nfeemitcpais: '',
			nfeemitpais: '',
			nfeemitfone: '',

			// DadosDestinatario '',
			nfedestcnpj: '',
			nfedestcpf: '',
			nfedestnome: '',
			nfedestindie: '',
			nfedestie: '',
			nfedestisuf: '',
			nfedestim: '',
			nfedestemail: '',

			// EnderecoDestinatario '',
			nfedestlograd: '',
			nfedestnumero: '',
			nfedestcompl: '',
			nfedestbairro: '',
			nfedestcmun: '',
			nfedestmun: '',
			nfedestuf: '',
			nfedestcep: '',
			nfedestcpais: '',
			nfedestpais: '',
			nfedestfone: '',

			//Tributos '',
			nfebcicms: 0,
			nfevicms: 0,
			nfevicmsdeson: 0,
			nfebcicmsst: 0,
			nfevicmsst: 0,
			nfevfcp: 0,
			nfevfcpst: 0,
			nfevfcpstret: 0,
			nfevtotprod: 0,
			nfevfrete: 0,
			nfevseguro: 0,
			nfevdesconto: 0,
			nfevii: 0,
			nfevipi: 0,
			nfevipidevol: 0,
			nfevpis: 0,
			nfevcofins: 0,
			nfevoutros: 0,
			nfevtotnota: 0,
			nfevtottrib: 0,

			//Transporte '',
			transpmodfrete: '',
			transpcnpj: '',
			transpcpf: '',
			transpnome: '',
			transpie: '',
			transpend: '',
			transpmun: '',
			transpuf: '',
			transpvolqtde: 0,
			transpvolespecie: 0,
			transpvolpb: 0,
			transpvolpl: 0,
			transplacre: '',

			//Cobrança '',
			cobrindpag: '',
			cobrtpag: '',
			cobrvpag: 0,

			//Informações '',
			nfeinfadfisco: '',
			nfeinfcpl: '',
			protocol_nprot: '',
			protocol_nrecbto: '',
			protocol_dhrecbto: '',
			protocol_digval: '',
			nfecstat: '',
			nfexmotivo: '',
			frasehomologacao: '',
			frasecontingencia: '',
			/*VALIADR*/
			protcanc: '',
			responsavelcancel: '',
			tpevento: '',
			xevento: '',
			protocol_dtcancel: '',
			nfecce: '',
			protcce: '',
			protocol_dtcce: '',
			responsavelcce: '',
			responsavel: '',
			tipo_geracao: '',
			confirmado_envio_user: '',
			nfedtadicao: '',
			id_nf_emissor: null,
			// id_depositante: '',
			nfeenvioemail: '',
			nfedtenvioemail: '',
			nfeprocessofalta: null,
			id_entidade: null,
			nfeplaca: '',
			nfeplacauf: '',
			produtos: [
				{
					itemnitem: '',
					itemcodigo: '',
					itemean: '',
					itemncm: '',
					itemdescricao: '',
					itemchavenfe: '',
					itemnnfe: '',
					itemserie: '',
					itemcest: '',
					itemcfop: '',
					itemucom: '',
					itemqcom: 0,
					itemvunit: 0,
					itemvtotal: 0,
					itemeantrib: '',
					itemuntrib: '',
					itemqtrib: 0,
					itemvuntrib: 0,
					itemindtot: '',
					itemxped: '',
					itemnlote: '',
					itemqlote: 0,
					itemdfab: '',
					itemdval: '',
					itemorig: '',
					itemcst: '',
					itemmodbc: 0,
					itembcicms: 0,
					itempicms: 0,
					itemvicms: 0,
					itempredbc: 0,
					itembcicmsst: 0,
					itempicmsst: 0,
					itemvicmsst: 0,
					itemcenqipi: '',
					itemcstipi: '',
					itembcipi: 0,
					itemvipi: 0,
					itemipi: 0,
					itemcstpis: '',
					itembcpis: 0,
					itemppis: 0,
					itemvpis: 0,
					itemcstcofins: '',
					itembccofins: 0,
					itempcofins: 0,
					itemvcofins: 0,
					iteminfadprod: '',
					id_nf_emissor: null,
					id_filial_emissor: null,
					id_produto_emissor: null,
					itemindescala: '',
					itemcnpjfab: '',
					itemcbenef: '',
					itempipi: '',
				},
			],
		},
	];
	const importarXmlConverterJson = async (xml: any) => {
		setShowCarregando(true);

		const arquivos = xml || null;

		if (arquivos) {
			for (let i = 0; i < arquivos.length; i++) {
				const file = arquivos[i];

				const xmlConteudo = await LerXml(file);

				const reader = new FileReader();
				reader.onload = () => {
					const xmlString = reader.result as string;

					const json = xml2json(xmlString, { compact: true, spaces: 2 });
					const nota = JSON.parse(json);

					const modeloHomologacao = nota.NFe?.infNFe?.ide?.mod?._text;
					const modelo = nota.nfeProc?.NFe?.infNFe?.ide?.mod?._text;

					if (modeloHomologacao === '55') {
						let itens = nota.NFe.infNFe.det;

						// Verifica se 'itens' é um array; se não for, converte em um array
						if (!Array.isArray(itens)) {
							itens = [itens];
						}
						const qtdItens = itens.length;
						const produtos = [];

						if (qtdItens === 1) {
							const prod = {
								itemnitem: nota.NFe.infNFe.det._attributes.nItem || '',
								itemcodigo: nota.NFe.infNFe.det.prod.cProd?._text || '',
								itemean: nota.NFe.infNFe.det.prod.cEAN?._text || '',
								itemncm: nota.NFe.infNFe.det.prod.NCM?._text || '',
								itemdescricao: nota.NFe.infNFe.det.prod.xProd?._text || '',
								itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
								itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
								itemserie: nota.NFe.infNFe.ide.serie?._text || '',
								itemcest: nota.NFe.infNFe.det.prod.CEST?._text || '',
								itemcfop: nota.NFe.infNFe.det.prod.CFOP?._text || '',
								itemucom: nota.NFe.infNFe.det.prod.uCom?._text || '',
								itemqcom: parseFloat(nota.NFe.infNFe.det.prod.qCom?._text || 0),
								itemvunit: parseFloat(nota.NFe.infNFe.det.prod.vUnCom?._text || 0),
								itemvtotal: parseFloat(nota.NFe.infNFe.det.prod.vProd?._text || 0),
								itemeantrib: nota.NFe.infNFe.det.prod.cEANTrib?._text || '',
								itemuntrib: nota.NFe.infNFe.det.prod.uTrib?._text || '',
								itemqtrib: parseFloat(nota.NFe.infNFe.det.prod.qTrib?._text || 0),
								itemvuntrib: parseFloat(nota.NFe.infNFe.det.prod.vUnTrib?._text || 0),
								itemindtot: nota.NFe.infNFe.det.prod.indTot?._text || '',
								itemxped: nota.NFe.infNFe.det.prod.xPed?._text || '',
								itemnlote: nota.NFe.infNFe.det.prod.nLote?._text || '',
								itemqlote: parseFloat(nota.NFe.infNFe.det.prod.qLote?._text || 0),
								itemdfab: nota.NFe.infNFe.det.prod.dFab?._text || '',
								itemdval: nota.NFe.infNFe.det.prod.dVal?._text || '',
								itemorig:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
									'',
								itemcst:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
									'',
								itemmodbc:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
									0,
								itembcicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
										0
								),
								itempicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
										0
								),
								itemvicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
										0
								),
								itempredbc: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
										0
								),
								itembcicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
										0
								),
								itempicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
										0
								),
								itemvicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
										0
								),
								itemcenqipi: nota.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
								itemcstipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
								itembcipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
								itemvipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
								itemipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
								itemcstpis: nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
								itembcpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
								itemppis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
								itemvpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
								itemcstcofins: nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
								itembccofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
								itempcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
								itemvcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
								iteminfadprod: nota.NFe.infNFe.det.infAdProd?._text || '',
								id_nf_emissor: null,
								id_filial_emissor: null,
								id_produto_emissor: null,
								itemindescala: nota.NFe.infNFe.det.prod.indEscala?._text || '',
								itemcnpjfab: nota.NFe.infNFe.det.prod?.CNPJFab?._text || '',
								itemcbenef: nota.NFe.infNFe.det.prod?.cBenef?._text || '',
								itempipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
							};
							produtos.push(prod);
						} else {
							for (let i = 0; i < qtdItens; i++) {
								const prod = {
									itemnitem: nota.NFe.infNFe.det[i]._attributes.nItem || '',
									itemcodigo: nota.NFe.infNFe.det[i].prod.cProd?._text || '',
									itemean: nota.NFe.infNFe.det[i].prod.cEAN?._text || '',
									itemncm: nota.NFe.infNFe.det[i].prod.NCM?._text || '',
									itemdescricao: nota.NFe.infNFe.det[i].prod.xProd?._text || '',
									itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
									itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
									itemserie: nota.NFe.infNFe.ide.serie?._text || '',
									itemcest: nota.NFe.infNFe.det[i].prod.CEST?._text || '',
									itemcfop: nota.NFe.infNFe.det[i].prod.CFOP?._text || '',
									itemucom: nota.NFe.infNFe.det[i].prod.uCom?._text || '',
									itemqcom: parseFloat(nota.NFe.infNFe.det[i].prod.qCom?._text || 0),
									itemvunit: parseFloat(nota.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
									itemvtotal: parseFloat(nota.NFe.infNFe.det[i].prod.vProd?._text || 0),
									itemeantrib: nota.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
									itemuntrib: nota.NFe.infNFe.det[i].prod.uTrib?._text || '',
									itemqtrib: parseFloat(nota.NFe.infNFe.det[i].prod.qTrib?._text || 0),
									itemvuntrib: parseFloat(nota.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
									itemindtot: nota.NFe.infNFe.det[i].prod.indTot?._text || '',
									itemxped: nota.NFe.infNFe.det[i].prod.xPed?._text || '',
									itemnlote: nota.NFe.infNFe.det[i].prod.nLote?._text || '',
									itemqlote: parseFloat(nota.NFe.infNFe.det[i].prod.qLote?._text || 0),
									itemdfab: nota.NFe.infNFe.det[i].prod.dFab?._text || '',
									itemdval: nota.NFe.infNFe.det[i].prod.dVal?._text || '',
									itemorig:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
										'',
									itemcst:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
										'',
									itemmodbc:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
										0,
									itembcicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
											0
									),
									itempicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
											0
									),
									itemvicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
											0
									),
									itempredbc: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
											0
									),
									itembcicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
											0
									),
									itempicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
											0
									),
									itemvicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
											0
									),
									itemcenqipi: nota.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
									itemcstipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
									itembcipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
									itemvipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
									itemipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
									itemcstpis: nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
									itembcpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
									itemppis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
									itemvpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
									itemcstcofins: nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
									itembccofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
									itempcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
									itemvcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
									iteminfadprod: nota.NFe.infNFe.det[i].infAdProd?._text || '',
									id_nf_emissor: null,
									id_filial_emissor: null,
									id_produto_emissor: null,
									itemindescala: nota.NFe.infNFe.det[i].prod.indEscala?._text || '',
									itemcnpjfab: nota.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
									itemcbenef: nota.NFe.infNFe.det[i].prod?.cBenef?._text || '',
									itempipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
								};
								produtos.push(prod);
							}
						}
						const dadosNFe = {
							// DadosNota
							id_filial: id_filial,
							nfestatus: nota.protNFe?.infProt?.cStat?._text || '',
							nfechave: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
							nfecuf: nota.NFe.infNFe.ide.cUF?._text || '',
							nfenatoper: nota.NFe.infNFe.ide.natOp?._text || '',
							nfecfop: nota.NFe.infNFe.ide.CFOP?._text || '',
							nfemod: nota.NFe.infNFe.ide.mod?._text || '',
							nfeserie: nota.NFe.infNFe.ide.serie?._text || '',
							nfennf: nota.NFe.infNFe.ide.nNF?._text || '',
							nfedtemissao: nota.NFe.infNFe.ide.dhEmi?._text || '',
							nfedtsaida: nota.NFe.infNFe.ide.dhSaiEnt?._text || '',
							nfentpnf: nota.NFe.infNFe.ide.tpNF?._text || '',
							nfeiddest: nota.NFe.infNFe.ide.iDest?._text || '',
							nfecmunfg: nota.NFe.infNFe.ide.cMunFG?._text || '',
							tpimp: nota.NFe.infNFe.ide.tpImp?._text || '',
							nfetpemis: nota.NFe.infNFe.ide.tpEmis?._text || '',
							nfecdv: nota.NFe.infNFe.ide.cDV?._text || '',
							nfetpamb: nota.NFe.infNFe.ide.tpAmb?._text || '',
							nfefinnfe: nota.NFe.infNFe.ide.finNFe?._text || '',
							nfeindfinal: nota.NFe.infNFe.ide.indFinal?._text || '',
							nfeindpres: nota.NFe.infNFe.ide.indPres?._text || '',
							nfeprocemi: nota.NFe.infNFe.ide.procEmi?._text || '',
							nfeverproc: nota.NFe.infNFe.ide.verProc?._text || '',
							nfedhconting: nota.NFe.infNFe.ide.dhCont?._text || '',
							nfejustconting: nota.NFe.infNFe.ide.xJust?._text || '',

							// DadosEmitente
							nfeemitcnpj: nota.NFe.infNFe.emit.CNPJ?._text || '',
							nfeemitcpf: nota.NFe.infNFe.emit.CPF?._text || '',
							nfeemitnome: nota.NFe.infNFe.emit.xNome?._text || '',
							nfeemitfantasia: nota.NFe.infNFe.emit.xFant?._text || '',
							nfeemitie: nota.NFe.infNFe.emit.IE?._text || '',
							nfeemitiest: nota.NFe.infNFe.emit.IEST?._text || '',
							nfeemitcrt: nota.NFe.infNFe.emit.CRT?._text || '',

							// EnderecoEmitente
							nfeemitlograd: nota.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
							nfeemitnumero: nota.NFe.infNFe.emit.enderEmit.nro?._text || '',
							nfeemitcompl: nota.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
							nfeemitbairro: nota.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
							nfeemitcmun: nota.NFe.infNFe.emit.enderEmit.cMun?._text || '',
							nfeemitmun: nota.NFe.infNFe.emit.enderEmit.xMun?._text || '',
							nfeemituf: nota.NFe.infNFe.emit.enderEmit.UF?._text || '',
							nfeemitcep: nota.NFe.infNFe.emit.enderEmit.CEP?._text || '',
							nfeemitcpais: nota.NFe.infNFe.emit.enderEmit.cPais?._text || '',
							nfeemitpais: nota.NFe.infNFe.emit.enderEmit.xPais?._text || '',
							nfeemitfone: nota.NFe.infNFe.emit.enderEmit.fone?._text || '',

							// DadosDestinatario
							nfedestcnpj: nota.NFe.infNFe.dest.CNPJ?._text || '',
							nfedestcpf: nota.NFe.infNFe.dest.CPF?._text || '',
							nfedestnome: nota.NFe.infNFe.dest.xNome?._text || '',
							nfedestindie: nota.NFe.infNFe.dest.indIEDest?._text || '',
							nfedestie: nota.NFe.infNFe.dest.IE?._text || '',
							nfedestisuf: nota.NFe.infNFe.dest.ISUF?._text || '',
							nfedestim: nota.NFe.infNFe.dest.IM?._text || '',
							nfedestemail: nota.NFe.infNFe.dest.email?._text || '',

							// EnderecoDestinatario
							nfedestlograd: nota.NFe.infNFe.dest.enderDest.xLgr?._text || '',
							nfedestnumero: nota.NFe.infNFe.dest.enderDest.nro?._text || '',
							nfedestcompl: nota.NFe.infNFe.dest.enderDest.xCpl?._text || '',
							nfedestbairro: nota.NFe.infNFe.dest.enderDest.xBairro?._text || '',
							nfedestcmun: nota.NFe.infNFe.dest.enderDest.cMun?._text || '',
							nfedestmun: nota.NFe.infNFe.dest.enderDest.xMun?._text || '',
							nfedestuf: nota.NFe.infNFe.dest.enderDest.UF?._text || '',
							nfedestcep: nota.NFe.infNFe.dest.enderDest.CEP?._text || '',
							nfedestcpais: nota.NFe.infNFe.dest.enderDest.cPais?._text || '',
							nfedestpais: nota.NFe.infNFe.dest.enderDest.xPais?._text || '',
							nfedestfone: nota.NFe.infNFe.dest.enderDest.fone?._text || '',

							//Tributos/Totais
							nfebcicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
							nfevicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
							nfevicmsdeson: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
							nfebcicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
							nfevicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vST?._text || 0),
							nfevfcp: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
							nfevfcpst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
							nfevfcpstret: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
							nfevtotprod: parseFloat(nota.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
							nfevfrete: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
							nfevseguro: parseFloat(nota.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
							nfevdesconto: parseFloat(nota.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
							nfevii: parseFloat(nota.NFe.infNFe.total.ICMSTot.vII?._text || 0),
							nfevipi: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
							nfevipidevol: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
							nfevpis: parseFloat(nota.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
							nfevcofins: parseFloat(nota.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
							nfevoutros: parseFloat(nota.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
							nfevtotnota: parseFloat(nota.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
							nfevtottrib: parseFloat(nota.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

							//Transporte nota.nfeProc.NFe.infNFe.transp

							//nota.nfeProc.NFe.infNFe.transp.transporta?.xNome._text
							transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
							transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
							transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
							transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
							transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
							transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
							transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
							transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
							transpvolqtde: parseFloat(nota.NFe.infNFe.transp?.vol?.qVol?._text || 0),
							transpvolespecie: parseFloat(nota.NFe.infNFe.transp?.vol?.esp?._text || 0),
							transpvolpb: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
							transpvolpl: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
							transplacre: nota.NFe.infNFe.transp?.vol?.Lacres?._text || '',

							//Cobrança
							cobrindpag: nota.NFe.infNFe.cobr?.dup?.nDup?._text || '',
							cobrtpag: nota.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
							cobrvpag: parseFloat(nota.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

							//Informações Adicionais
							nfeinfadfisco: nota.NFe.infNFe.infAdic?.infAdFisco?._text || '',
							nfeinfcpl: nota.NFe.infNFe.infAdic?.infCpl?._text || '',
							protocol_nprot: nota.protNFe?.infProt?.nProt?._text || '',
							protocol_nrecbto: nota.protNFe?.infProt?.nRec?._text || '',
							protocol_dhrecbto: nota.protNFe?.infProt?.dhRecbto?._text || '',
							protocol_digval: nota.protNFe?.infProt?.digVal?._text || '',
							nfecstat: nota.protNFe?.infProt?.cStat?._text || '',
							nfexmotivo: nota.protNFe?.infProt?.xMotivo?._text || '',
							frasehomologacao: nota.protNFe?.infProt?.xMotivo?._text || '',
							frasecontingencia: nota.protNFe?.infProt.xJust?._text || '',
							/*VALIADR COM FRANKLIN */
							protcanc: nota.protNFe?.infProt?.cStat?._text || '',
							responsavelcancel: nota.protNFe?.infProt?.xMotivo?._text || '',
							tpevento: nota.retEvento?.infEvento?.tpEvento?._text || '',
							xevento: nota.retEvento?.infEvento?.xEvento?._text || '',
							protocol_dtcancel: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
							nfecce: nota.retEvento?.infEvento?.cStat?._text || '',
							protcce: nota.retEvento?.infEvento?.xMotivo?._text || '',
							protocol_dtcce: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
							responsavelcce: nota.retEvento?.infEvento?.xJust?._text || '',
							responsavel: nota.retEvento?.infEvento?.xNome?._text || '',
							tipo_geracao: '',
							confirmado_envio_user: '',
							nfedtadicao: '',
							id_nf_emissor: null,
							//id_depositante: selecionaDepostitante || '',
							nfeenvioemail: '',
							nfedtenvioemail: '',
							nfeprocessofalta: null,
							id_entidade: null,
							nfeplaca: '',
							nfeplacauf: '',
							produtos: produtos,
						};

						todasNotasFiscais.push(dadosNFe);
					} else if (modelo === '55') {
						let itens = nota.nfeProc.NFe.infNFe.det;

						// Verifica se 'itens' é um array; se não for, converte em um array
						if (!Array.isArray(itens)) {
							itens = [itens];
						}
						const qtdItens = itens.length;

						const chavenfe = nota.nfeProc.protNFe.infProt.chNFe?._text || '';
						const produtos = [];
						if (qtdItens === 1) {
							const prod = {
								itemnitem: nota.nfeProc.NFe.infNFe.det._attributes.nItem || '',
								itemcodigo: nota.nfeProc.NFe.infNFe.det.prod.cProd?._text || '',
								itemean: nota.nfeProc.NFe.infNFe.det.prod.cEAN?._text || '',
								itemncm: nota.nfeProc.NFe.infNFe.det.prod.NCM?._text || '',
								itemdescricao: nota.nfeProc.NFe.infNFe.det.prod.xProd?._text || '',
								itemchavenfe: chavenfe,
								itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
								itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
								itemcest: nota.nfeProc.NFe.infNFe.det.prod.CEST?._text || '',
								itemcfop: nota.nfeProc.NFe.infNFe.det.prod.CFOP?._text || '',
								itemucom: nota.nfeProc.NFe.infNFe.det.prod.uCom?._text || '',
								itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qCom?._text || 0),
								itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnCom?._text || 0),
								itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vProd?._text || 0),
								itemeantrib: nota.nfeProc.NFe.infNFe.det.prod.cEANTrib?._text || '',
								itemuntrib: nota.nfeProc.NFe.infNFe.det.prod.uTrib?._text || '',
								itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qTrib?._text || 0),
								itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnTrib?._text || 0),
								itemindtot: nota.nfeProc.NFe.infNFe.det.prod.indTot?._text || '',
								itemxped: nota.nfeProc.NFe.infNFe.det.prod.xPed?._text || '',
								itemnlote: nota.nfeProc.NFe.infNFe.det.prod.nLote?._text || '',
								itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qLote?._text || 0),
								itemdfab: nota.nfeProc.NFe.infNFe.det.prod.dFab?._text || '',
								itemdval: nota.nfeProc.NFe.infNFe.det.prod.dVal?._text || '',
								itemorig:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
									'',
								itemcst:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
									'',
								itemmodbc:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
									0,
								itembcicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
										0
								),
								itempicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
										0
								),
								itemvicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
										0
								),
								itempredbc: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
										0
								),
								itembcicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
										0
								),
								itempicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
										0
								),
								itemvicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
										0
								),
								itemcenqipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
								itemcstipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
								itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
								itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
								itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
								itemcstpis: nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
								itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
								itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
								itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
								itemcstcofins: nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
								itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
								itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
								itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
								iteminfadprod: nota.nfeProc.NFe.infNFe.det.infAdProd?._text || '',
								id_nf_emissor: null,
								id_filial_emissor: null,
								id_produto_emissor: null,
								itemindescala: nota.nfeProc.NFe.infNFe.det.prod.indEscala?._text || '',
								itemcnpjfab: nota.nfeProc.NFe.infNFe.det.prod?.CNPJFab?._text || '',
								itemcbenef: nota.nfeProc.NFe.infNFe.det.prod?.cBenef?._text || '',
								itempipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
								pfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.pFCP || 0,
								vfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.vFCP || 0,
							};

							produtos.push(prod);
						} else {
							for (let i = 0; i < qtdItens; i++) {
								const prod = {
									itemnitem: nota.nfeProc.NFe.infNFe.det[i]._attributes.nItem || '',
									itemcodigo: nota.nfeProc.NFe.infNFe.det[i].prod.cProd?._text || '',
									itemean: nota.nfeProc.NFe.infNFe.det[i].prod.cEAN?._text || '',
									itemncm: nota.nfeProc.NFe.infNFe.det[i].prod.NCM?._text || '',
									itemdescricao: nota.nfeProc.NFe.infNFe.det[i].prod.xProd?._text || '',
									itemchavenfe: chavenfe,
									itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
									itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
									itemcest: nota.nfeProc.NFe.infNFe.det[i].prod.CEST?._text || '',
									itemcfop: nota.nfeProc.NFe.infNFe.det[i].prod.CFOP?._text || '',
									itemucom: nota.nfeProc.NFe.infNFe.det[i].prod.uCom?._text || '',
									itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qCom?._text || 0),
									itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
									itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vProd?._text || 0),
									itemeantrib: nota.nfeProc.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
									itemuntrib: nota.nfeProc.NFe.infNFe.det[i].prod.uTrib?._text || '',
									itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qTrib?._text || 0),
									itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
									itemindtot: nota.nfeProc.NFe.infNFe.det[i].prod.indTot?._text || '',
									itemxped: nota.nfeProc.NFe.infNFe.det[i].prod.xPed?._text || '',
									itemnlote: nota.nfeProc.NFe.infNFe.det[i].prod.nLote?._text || '',
									itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qLote?._text || 0),
									itemdfab: nota.nfeProc.NFe.infNFe.det[i].prod.dFab?._text || '',
									itemdval: nota.nfeProc.NFe.infNFe.det[i].prod.dVal?._text || '',
									itemorig:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
										'',
									itemcst:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
										'',
									itemmodbc:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
										0,
									itembcicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
											0
									),
									itempicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
											0
									),
									itemvicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
											0
									),
									itempredbc: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
											0
									),
									itembcicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
											0
									),
									itempicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
											0
									),
									itemvicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
											0
									),
									itemcenqipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
									itemcstipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
									itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
									itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
									itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
									itemcstpis: nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
									itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
									itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
									itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
									itemcstcofins: nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
									itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
									itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
									itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
									iteminfadprod: nota.nfeProc.NFe.infNFe.det[i].infAdProd?._text || '',
									id_nf_emissor: null,
									id_filial_emissor: null,
									id_produto_emissor: null,
									itemindescala: nota.nfeProc.NFe.infNFe.det[i].prod.indEscala?._text || '',
									itemcnpjfab: nota.nfeProc.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
									itemcbenef: nota.nfeProc.NFe.infNFe.det[i].prod?.cBenef?._text || '',
									itempipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
									pfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.pFCP?._text || 0,
									vfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.vFCP?._text || 0,
								};
								produtos.push(prod);
							}
						}

						try {
							//console.log(nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco)
							const dadosNFe = {
								// DadosNota
								id_filial: id_filial,
								nfestatus: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								nfechave: chavenfe,
								nfecuf: nota.nfeProc.NFe.infNFe?.ide?.cUF?._text || '',
								nfenatoper: nota.nfeProc.NFe.infNFe.ide.natOp?._text || '',
								nfecfop: nota.nfeProc.NFe.infNFe.ide.CFOP?._text || '',
								nfemod: nota.nfeProc.NFe.infNFe.ide.mod?._text || '',
								nfeserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
								nfennf: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
								nfedtemissao: nota.nfeProc.NFe.infNFe.ide.dhEmi?._text || '',
								nfedtsaida: nota.nfeProc.NFe.infNFe.ide.dhSaiEnt?._text || '',
								nfentpnf: nota.nfeProc.NFe.infNFe.ide.tpNF?._text || '',
								nfeiddest: nota.nfeProc.NFe.infNFe.ide.iDest?._text || '',
								nfecmunfg: nota.nfeProc.NFe.infNFe.ide.cMunFG?._text || '',
								tpimp: nota.nfeProc.NFe.infNFe.ide.tpImp?._text || '',
								nfetpemis: nota.nfeProc.NFe.infNFe.ide.tpEmis?._text || '',
								nfecdv: nota.nfeProc.NFe.infNFe.ide.cDV?._text || '',
								nfetpamb: nota.nfeProc.NFe.infNFe.ide.tpAmb?._text || '',
								nfefinnfe: nota.nfeProc.NFe.infNFe.ide.finNFe?._text || '',
								nfeindfinal: nota.nfeProc.NFe.infNFe.ide.indFinal?._text || '',
								nfeindpres: nota.nfeProc.NFe.infNFe.ide.indPres?._text || '',
								nfeprocemi: nota.nfeProc.NFe.infNFe.ide.procEmi?._text || '',
								nfeverproc: nota.nfeProc.NFe.infNFe.ide.verProc?._text || '',
								nfedhconting: nota.nfeProc.NFe.infNFe.ide.dhCont?._text || '',
								nfejustconting: nota.nfeProc.NFe.infNFe.ide.xJust?._text || '',

								// DadosEmitente
								nfeemitcnpj: nota.nfeProc.NFe.infNFe.emit.CNPJ?._text || '',
								nfeemitcpf: nota.nfeProc.NFe.infNFe.emit.CPF?._text || '',
								nfeemitnome: nota.nfeProc.NFe.infNFe.emit.xNome?._text || '',
								nfeemitfantasia: nota.nfeProc.NFe.infNFe.emit.xFant?._text || '',
								nfeemitie: nota.nfeProc.NFe.infNFe.emit.IE?._text || '',
								nfeemitiest: nota.nfeProc.NFe.infNFe.emit.IEST?._text || '',
								nfeemitcrt: nota.nfeProc.NFe.infNFe.emit.CRT?._text || '',

								// EnderecoEmitente
								nfeemitlograd: nota.nfeProc.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
								nfeemitnumero: nota.nfeProc.NFe.infNFe.emit.enderEmit.nro?._text || '',
								nfeemitcompl: nota.nfeProc.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
								nfeemitbairro: nota.nfeProc.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
								nfeemitcmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.cMun?._text || '',
								nfeemitmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.xMun?._text || '',
								nfeemituf: nota.nfeProc.NFe.infNFe.emit.enderEmit.UF?._text || '',
								nfeemitcep: nota.nfeProc.NFe.infNFe.emit.enderEmit.CEP?._text || '',
								nfeemitcpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.cPais?._text || '',
								nfeemitpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.xPais?._text || '',
								nfeemitfone: nota.nfeProc.NFe.infNFe.emit.enderEmit.fone?._text || '',

								// DadosDestinatario
								nfedestcnpj: nota.nfeProc.NFe.infNFe.dest.CNPJ?._text || '',
								nfedestcpf: nota.nfeProc.NFe.infNFe.dest.CPF?._text || '',
								nfedestnome: nota.nfeProc.NFe.infNFe.dest.xNome?._text || '',
								nfedestindie: nota.nfeProc.NFe.infNFe.dest.indIEDest?._text || '',
								nfedestie: nota.nfeProc.NFe.infNFe.dest.IE?._text || '',
								nfedestisuf: nota.nfeProc.NFe.infNFe.dest.ISUF?._text || '',
								nfedestim: nota.nfeProc.NFe.infNFe.dest.IM?._text || '',
								nfedestemail: nota.nfeProc.NFe.infNFe.dest.email?._text || '',

								// EnderecoDestinatario
								nfedestlograd: nota.nfeProc.NFe.infNFe.dest.enderDest.xLgr?._text || '',
								nfedestnumero: nota.nfeProc.NFe.infNFe.dest.enderDest.nro?._text || '',
								nfedestcompl: nota.nfeProc.NFe.infNFe.dest.enderDest.xCpl?._text || '',
								nfedestbairro: nota.nfeProc.NFe.infNFe.dest.enderDest.xBairro?._text || '',
								nfedestcmun: nota.nfeProc.NFe.infNFe.dest.enderDest.cMun?._text || '',
								nfedestmun: nota.nfeProc.NFe.infNFe.dest.enderDest.xMun?._text || '',
								nfedestuf: nota.nfeProc.NFe.infNFe.dest.enderDest.UF?._text || '',
								nfedestcep: nota.nfeProc.NFe.infNFe.dest.enderDest.CEP?._text || '',
								nfedestcpais: nota.nfeProc.NFe.infNFe.dest.enderDest.cPais?._text || '',
								nfedestpais: nota.nfeProc.NFe.infNFe.dest.enderDest.xPais?._text || '',
								nfedestfone: nota.nfeProc.NFe.infNFe.dest.enderDest.fone?._text || '',

								//Tributos/Totais
								nfebcicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
								nfevicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
								nfevicmsdeson: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
								nfebcicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
								nfevicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vST?._text || 0),
								nfevfcp: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
								nfevfcpst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
								nfevfcpstret: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
								nfevtotprod: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
								nfevfrete: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
								nfevseguro: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
								nfevdesconto: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
								nfevii: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vII?._text || 0),
								nfevipi: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
								nfevipidevol: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
								nfevpis: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
								nfevcofins: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
								nfevoutros: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
								nfevtotnota: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
								nfevtottrib: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

								//Transporte
								transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
								transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
								transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
								transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
								transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
								transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
								transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
								transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
								transpvolqtde: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.qVol?._text || 0),
								transpvolespecie: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.esp?._text || 0),
								transpvolpb: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
								transpvolpl: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
								transplacre: nota.nfeProc.NFe.infNFe.transp?.vol?.Lacres?._text || '',

								//Cobrança
								cobrindpag: nota.nfeProc.NFe.infNFe.cobr?.dup?.nDup?._text || '',
								cobrtpag: nota.nfeProc.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
								cobrvpag: parseFloat(nota.nfeProc.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

								//Informações Adicionais
								nfeinfadfisco: nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco?._text || '',
								nfeinfcpl: nota.nfeProc.NFe.infNFe.infAdic?.infCpl?._text || '',
								protocol_nprot: nota.nfeProc.protNFe.infProt.nProt?._text || '',
								protocol_nrecbto: nota.nfeProc.protNFe.infProt.nRec?._text || '',
								protocol_dhrecbto: nota.nfeProc.protNFe.infProt.dhRecbto?._text || '',
								protocol_digval: nota.nfeProc.protNFe.infProt.digVal?._text || '',
								nfecstat: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								nfexmotivo: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								frasehomologacao: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								frasecontingencia: nota.nfeProc.protNFe.infProt.xJust?._text || '',
								/*VALIADR COM FRANKLIN */
								protcanc: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								responsavelcancel: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								tpevento: nota.nfeProc.retEvento?.infEvento?.tpEvento?._text || '',
								xevento: nota.nfeProc.retEvento?.infEvento?.xEvento?._text || '',
								protocol_dtcancel: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
								nfecce: nota.nfeProc.retEvento?.infEvento?.cStat?._text || '',
								protcce: nota.nfeProc.retEvento?.infEvento?.xMotivo?._text || '',
								protocol_dtcce: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
								responsavelcce: nota.nfeProc.retEvento?.infEvento?.xJust?._text || '',
								responsavel: nota.nfeProc.retEvento?.infEvento?.xNome?._text || '',
								tipo_geracao: '',
								confirmado_envio_user: '',
								nfedtadicao: '',
								id_nf_emissor: null,
								// id_depositante: selecionaDepostitante || '',
								nfeenvioemail: '',
								nfedtenvioemail: '',
								nfeprocessofalta: null,
								id_entidade: null,
								nfeplaca: '',
								nfeplacauf: '',
								produtos: produtos,
							};
							// postXmlNFe(dadosNFe);

							todasNotasFiscais.push(dadosNFe);
						} catch (error: any) {
							toast.error(error.message || error, {
								autoClose: 900,
							});
						}
					}

					if (i === arquivos.length - 1) {
						const notas = {
							todasNotasFiscais,
							xmlConteudo,
						};
						postXmlNFe(notas);
					}
				};
				reader.readAsText(file);
			}
		}
	};
	const postXmlNFe = (nota: any) => {
		const qtdNotas = nota.todasNotasFiscais.length;
		const ListaNotas_sucesso: any = [];
		const ListaNotas_erro: any = [];
		for (let i = 0; i < qtdNotas; i++) {
			if (nota.todasNotasFiscais[i].nfechave !== '') {
				setShowCarregando(true);
				const dados = {
					nota: nota.todasNotasFiscais[i],
					xmlText: nota.xmlConteudo,
				};
				axios
					.post(`${process.env.REACT_APP_API_BASE_URL}/import/nfe/inserirxml/nota/pedido?usuarioLogado=${usuarioLogado}`, dados, {
						headers: {
							authorization: token,
						},
					})
					.then(function (resposta) {
						ListaNotas_sucesso.push(resposta.data.nf);

						if (i == qtdNotas - 1) {
							setListaNotasNaoImportadas(ListaNotas_erro);
							setListaNotasImportadas(ListaNotas_sucesso);
							setShowCarregando(false);
							setShowModalListaNF(true);
							CarregarNFsDepositante();
						}
					})
					.catch(function (erro) {
						if (erro.response.data.nf) {
							//console.log([...ListaNotasNaoImportadas, erro.response.data.nf])
							ListaNotas_erro.push(erro.response.data.nf);
						}

						if (i == qtdNotas - 1) {
							setListaNotasNaoImportadas(ListaNotas_erro);
							setListaNotasImportadas(ListaNotas_sucesso);
							setShowCarregando(false);
							setShowModalListaNF(true);
							CarregarNFsDepositante();
						}
					});
			}
		}
	};
	const [NotasCarregadasPedidos, setNotasCarregadasPedidos] = useState([]);
	const ColunasNotasNF: ITabela[] = [
		{ titulo: 'NFe', acesso: 'nf' },
		{ titulo: 'Série', acesso: 'serie' },
		{ titulo: 'Destinátario', acesso: 'razao_dest' },
		{ titulo: 'Vol.', acesso: 'vol' },
		{ titulo: 'Peso Líq.', acesso: 'pl' },
		{ titulo: 'UF Dest.', acesso: 'uf_dest' },
		{ titulo: 'Cidade Dest.', acesso: 'mun_dest' },
	];
	async function CarregarNFsDepositante() {
		try {
			setShowCarregando(true);
			if (DepositanteSelecionado.length > 0) {
				const resposta = await axios.get(
					`${process.env.REACT_APP_API_BASE_URL}/carregar/notas/pedido/${DepositanteSelecionado}/${id_filial}`,
					{
						headers: {
							authorization: token,
						},
					}
				);
				setNotasCarregadasPedidos(resposta.data.notas);
				setShowCarregando(false);
				setResetKey((prevAnt) => prevAnt + 1);
			} else {
				setShowCarregando(false);
				toast.info("Selecione um depositante para carregar NFe's", {
					autoClose: 900,
				});
			}
		} catch (error: any) {
			setShowCarregando(false);
			toast.error('Erro ao carregar NFs: ' + error.response.data.message || error);
		}
	}
	//#region AREA MANUAL
	const [ListaPedidosManual, setListaPedidosManual] = useState<any>([]); //lista de PRODUTOS do pedido manual
	const [inputsPedidoCabecalhoManual, setInputsPedidoCabecalhoManual] = useState({
		cnpj_depositante: '',
		data_carga: '',
		num_pedido: '',
		destinatario: '',
		roteiro: '',
		qtde_emb: 0,
	});
	const [inputsPedidoDetalhe, setInputsPedidoDetalhe] = useState({
		cnpj_depositante: '',
		descricao: '',
		num_pedido: '',
		cod_produto: '',
		qtde_emb: 0,
		tipo_emb: '',
		restricao: '',
		lote: '',
		id_wms: '',
	});
	const [ListaProdutos, setListaProdutos] = useState([]);
	const colunasProdutosPedidoManual: ITabela[] = [
		{ titulo: 'Código', acesso: 'cod_produto' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Qtde', acesso: 'qtde_emb' },
		{ titulo: 'Embalagem', acesso: 'tipo_emb' },
		{ titulo: 'Menor Validade', acesso: 'restricao' },
		{ titulo: 'Lote', acesso: 'lote' },
	];
	const [itensPorPaginaPedidoManual, setItensPorPaginaPedidoManual] = useState(10);
	useEffect(
		function () {
			setInputsPedidoCabecalhoManual({ ...inputsPedidoCabecalhoManual, cnpj_depositante: DepositanteSelecionado });
		},
		[DepositanteSelecionado]
	);
	function addProdutoPedidoManual() {
		if (inputsPedidoCabecalhoManual.cnpj_depositante == '') {
			toast.error('Selecione um depositante para prosseguir', {
				autoClose: 2000,
			});
		} else if (inputsPedidoDetalhe.cod_produto == '') {
			toast.error('Selecione um produto', {
				autoClose: 2000,
			});
		} else if (inputsPedidoDetalhe.qtde_emb <= 0) {
			toast.error('A Qtde de embalagem não pode ser 0 ou negativa', {
				autoClose: 2000,
			});
		} else if (inputsPedidoDetalhe.tipo_emb == '') {
			toast.error('Selecione um tipo de embalagem para o produto', {
				autoClose: 2000,
			});
		} else {
			const ProdutoEncontrado = ListaPedidosManual.filter(function (produto: any) {
				return produto.cod_produto == inputsPedidoDetalhe.cod_produto && produto.tipo_emb == inputsPedidoDetalhe.tipo_emb;
			});
			if (ProdutoEncontrado.length > 0) {
				toast.error('Este produto já está no pedido para a embalagem: ' + inputsPedidoDetalhe.tipo_emb, {
					autoClose: 2000,
				});
			} else {
				const embalagemExiste = ListaEmbalagens.filter(function (embalagem: any) {
					return (
						inputsPedidoDetalhe.tipo_emb.toUpperCase() == embalagem.unid_manuseio ||
						inputsPedidoDetalhe.tipo_emb.toLocaleLowerCase() == embalagem.unid_manuseio
					);
				});
				if (embalagemExiste.length > 0) {
					setListaPedidosManual([
						...ListaPedidosManual,
						{
							cnpj_depositante: inputsPedidoDetalhe.cnpj_depositante,
							descricao: inputsPedidoDetalhe.descricao,
							num_pedido: inputsPedidoDetalhe.num_pedido,
							cod_produto: inputsPedidoDetalhe.cod_produto,
							qtde_emb: inputsPedidoDetalhe.qtde_emb,
							tipo_emb: inputsPedidoDetalhe.tipo_emb,
							restricao: ConverterDataEUAparaBR(inputsPedidoDetalhe.restricao),
							lote: inputsPedidoDetalhe.lote,
							id_wms: inputsPedidoDetalhe.id_wms,
						},
					]);
					toast.success('Produto adicionado ao pedido com sucesso.', {
						autoClose: 900,
					});
					setInputsPedidoDetalhe({
						cnpj_depositante: '',
						descricao: '',
						num_pedido: '',
						cod_produto: '',
						qtde_emb: 0,
						tipo_emb: '',
						restricao: '',
						lote: '',
						id_wms: '',
					});
				} else {
					toast.error('Embalagem não existe para esse produto', {
						autoClose: 2000,
					});
				}
			}
		}
	}
	const [produtoAExcluir, setPordutoAExcluir] = useState<any>();
	function removerProdutoPedidoManual(produtoRemover: any) {
		const novaListaPedidosManual = ListaPedidosManual.filter(function (produto: any) {
			return produto.cod_produto != produtoRemover.cod_produto || produto.tipo_emb != produtoRemover.tipo_emb;
		});
		setListaPedidosManual(novaListaPedidosManual);
		toast.success('Produto removido do pedido', {
			autoClose: 900,
		});
		setshowModalConfirmarRemoverProdutoPedidoManual(false);
	}
	const [showModalConfirmarRemoverProdutoPedidoManual, setshowModalConfirmarRemoverProdutoPedidoManual] = useState(false);
	function ConverterDataEUAparaBR(data: string) {
		if (data != '') {
			let dataFormatada: any = data.replaceAll('-', '/');
			dataFormatada = dataFormatada.split('/');
			dataFormatada = dataFormatada[2] + '/' + dataFormatada[1] + '/' + dataFormatada[0];
			return dataFormatada;
		} else {
			return data;
		}
	}
	const [idDepositanteSelecionado, setIdDepositanteSelecioando] = useState('');
	async function CarregarProdutosDepositante() {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lista/prodarm/filial/${id_filial}/${idDepositanteSelecionado}`, {
				headers: {
					authorization: token,
				},
			});
			setListaProdutos(resposta.data.produtos);
			setShowCarregando(false);
		} catch (error) {
			setShowCarregando(false);
		}
	}
	useEffect(
		function () {
			if (inputsPedidoCabecalhoManual.cnpj_depositante != '') {
				CarregarProdutosDepositante();
			}
		},
		[inputsPedidoCabecalhoManual.cnpj_depositante]
	);
	const [ListaEmbalagens, setListaEmbalagens] = useState([]);
	async function CarregarEmbalagensProduto() {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/produtoarm/edit/${id_filial}/${inputsPedidoDetalhe.id_wms}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaEmbalagens(resposta.data.embalagem);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}
	useEffect(
		function () {
			if (inputsPedidoDetalhe.id_wms != '') {
				CarregarEmbalagensProduto();
			} else {
				setInputsPedidoDetalhe({ ...inputsPedidoDetalhe, id_wms: '' });
			}
		},
		[inputsPedidoDetalhe.id_wms]
	);
	async function CriarPedidoManual() {
		try {
			setshowModalConfirmacaoPedidoManual(false);
			setShowCarregando(true);
			const dados = {
				pedidoDetalhe: ListaPedidosManual,
				pedidoCabecalho: inputsPedidoCabecalhoManual,
				usuario: usuarioLogado,
			};
			const resposta = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/criar/pedido/manual/${id_filial}/${idDepositanteSelecionado}`,
				dados,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setInputsPedidoDetalhe({
				cnpj_depositante: '',
				descricao: '',
				num_pedido: '',
				cod_produto: '',
				qtde_emb: 0,
				tipo_emb: '',
				restricao: '',
				lote: '',
				id_wms: '',
			});
			setInputsPedidoCabecalhoManual({
				cnpj_depositante: '',
				data_carga: '',
				num_pedido: '',
				destinatario: '',
				roteiro: '',
				qtde_emb: 0,
			});
			setListaPedidosManual([]);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
			setDepositanteSelecionado('');
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 3000,
			});
		}
	}
	const [showModalConfirmacaoPedidoManual, setshowModalConfirmacaoPedidoManual] = useState(false);
	//#region POST pedido por importação
	async function PostPedidoImportacao() {
		try {
			setshowModalConfirmacaoPedidoManual(false);
			setShowCarregando(true);
			const dados = {
				pedidoDetalhe: ListaDetalhePedidos,
				pedidoCabecalho: ListaPedidos,
				usuario: usuarioLogado,
			};
			const resposta = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/criar/pedido/importado/${id_filial}/${idDepositanteSelecionado}`,
				dados,
				{
					headers: {
						authorization: token,
					},
				}
			);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
			setDepositanteSelecionado('');
			setListaDetalhePedidos([]);
			setlistaPedidos([]);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 3000,
			});
		}
	}
	//GERAR PEDIDO DE NF
	const [nfsSelecionadas, setNfsSelecionadas] = useState<any>([]);
	useEffect(
		function () {
			//console.log(nfsSelecionadas)
		},
		[nfsSelecionadas]
	);
	const [DetalheItensNota, setDetalheItensNota] = useState([]);
	const ColunasItensNota: ITabela[] = [
		{ titulo: 'NItem', acesso: 'nitem' },
		{ titulo: 'Cód.', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Qtde Com.', acesso: 'qtd_com' },
		{ titulo: 'Und Com.', acesso: 'und_com' },
		{ titulo: 'CFOP', acesso: 'cfop' },
		{ titulo: 'NCM', acesso: 'ncm' },
	];
	const [showModalItensNota, setShowModalItensNota] = useState(false);
	async function CarregarDetalhesNota(chave: string, id_filial: string) {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/itens/nota/ped/${chave}/${id_filial}`, {
				headers: {
					authorization: token,
				},
			});
			setDetalheItensNota(resposta.data.itens);
			setShowCarregando(false);
			setShowModalItensNota(true);
		} catch (error: any) {
			setShowCarregando(false);
			setShowModalItensNota(false);
			toast.error(error.response.data.message, {
				autoClose: 3000,
			});
		}
	}
	//deletar NF e itens - >corpo ped e item ped
	const [nfDeletar, setNfdeletar] = useState<any>();
	const [showModalDeletarNF, setshowModalDeletarNF] = useState(false);
	async function DeletarNFped(chave: string, id_filial: string) {
		console.log(chave);
		console.log(id_filial);
		try {
			setshowModalDeletarNF(false);
			setShowCarregando(true);
			const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/remover/nf/pedido/${chave}/${id_filial}`, {
				headers: {
					authorization: token,
				},
			});
			CarregarNFsDepositante();
			setShowCarregando(false);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message, {
				autoClose: 3000,
			});
		}
	}
	//GERAR PEDIDOS SELECIONANDO NF's
	const [showOpcoesPedidosNFE, setshowOpcoesPedidosNFE] = useState(false);
	function selecionarNota(nf: any, selected: boolean) {
		if (selected) {
			setNfsSelecionadas([
				...nfsSelecionadas,
				{
					nf: nf.nf,
					cnpjDepositante: DepositanteSelecionado,
					chave_acesso: nf.chave_acesso,
					razao_dest: nf.razao_dest,
				},
			]);
		} else if (!selected) {
			const NovaLista = nfsSelecionadas.filter(function (nota: any) {
				return nota.nf != nf.nf;
			});
			setNfsSelecionadas(NovaLista);
		}
	}
	async function CriarUmPedidoParaCadaNFe() {
		try {
			if (nfsSelecionadas.length > 0) {
				setshowOpcoesPedidosNFE(false);
				setShowCarregando(true);
				const dados = {
					nfsSelecionadas: nfsSelecionadas,
					usuario: usuarioLogado,
				};
				const resposta = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/criar/ped/nf/cada/nf/${id_filial}/${idDepositanteSelecionado}`,
					dados,
					{
						headers: {
							authorization: token,
						},
					}
				);
				setNfsSelecionadas([]);
				setShowCarregando(false);
				CarregarNFsDepositante();
				toast.success(resposta.data.message, {
					autoClose: 3000,
				});
			} else {
				toast.info('Selecione ao menos 1 NFe.', {
					autoClose: 900,
				});
			}
		} catch (error: any) {
			toast.error(error.response.data.message, {
				autoClose: 900,
			});
			setShowCarregando(false);
		}
	}
	async function CriarPedidoConsolidado() {
		try {
			if (nfsSelecionadas.length > 0) {
				setshowOpcoesPedidosNFE(false);
				setShowCarregando(true);
				const dados = {
					nfsSelecionadas: nfsSelecionadas,
					usuario: usuarioLogado,
				};
				const resposta = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/criar/pedido/consolidado/por/nf/${id_filial}/${idDepositanteSelecionado}`,
					dados,
					{
						headers: {
							authorization: token,
						},
					}
				);
				setNfsSelecionadas([]);
				setShowCarregando(false);
				CarregarNFsDepositante();
				toast.success(resposta.data.message, {
					autoClose: 3000,
				});
			} else {
				toast.info('Selecione ao menos 1 NFe.', {
					autoClose: 900,
				});
			}
		} catch (error: any) {
			toast.error(error.response.data.message, {
				autoClose: 900,
			});
			setShowCarregando(false);
		}
	}
	const [resetKey, setResetKey] = useState(0);
	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Criação de Pedidos</h4>
			</div>
			<div className="card">
				<div className="card-body">
					<ul className="nav nav-pills bg-nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
						{permissaoPedidoVisualizarImpArquivo && (
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active rounded-0"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#importacaoDeArquivos"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									Importação de Arquivos
								</button>
							</li>
						)}
						{permissaoPedidoVisualizarNFeParaPedido && (
							<li className="nav-item" role="presentation">
								<button
									className="nav-link rounded-0"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#NFePedido"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									NFe para Pedido
								</button>
							</li>
						)}
						{permissaoPedidoVisualizarPedidoManual && (
							<li className="nav-item" role="presentation">
								<button
									className="nav-link rounded-0"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#PedidoManual"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									Pedido Manual
								</button>
							</li>
						)}
					</ul>
					<div className="tab-content" id="pills-tabContent">
						<div className="tab-pane fade show active" id="importacaoDeArquivos" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="card mt-2 pb-3">
								<div className="container p-0 m-0">
									<div className="row">
										<div className="col-sm col-md-6 col-lg-3">
											<div className="form-floating me-1 ">
												<select
													value={DepositanteSelecionado + '|' + idDepositanteSelecionado}
													onChange={function (e) {
														setDepositanteSelecionado(e.target.value.split('|')[0]);
														setIdDepositanteSelecioando(e.target.value.split('|')[1]);
													}}
													className="form-select bordasInferiorInput text-black"
													id="floatingSelect"
													aria-label="Floating label select example"
												>
													<option value="">Selecione...</option>
													{ListaDepositantes.map(function (depositante: any) {
														return (
															<option value={depositante.cnpj + '|' + depositante.id_cliente}>
																{depositante.fantasia}
															</option>
														);
													})}
												</select>
												<label>Depositante</label>
											</div>
										</div>
										<div className="col-sm col-md col-lg-6">
											{permissaoPedidoImportarArquivo && (
												<button
													className="btn btn-primary me-1 mt-2"
													onClick={function () {
														setShowModalImportacao(true);
													}}
												>
													<i className="bi bi-box-arrow-in-down fs-4 me-1"></i>
													Importar Pedidos
												</button>
											)}
											{ListaPedidos.length > 0 ? (
												<>
													<button
														onClick={function () {
															PostPedidoImportacao();
														}}
														className="btn btn-secondary me-1"
													>
														<i className="bi bi-card-list fs-4 me-1"></i>
														Gerar Pedidos
													</button>
													<button
														className="btn btn-danger"
														onClick={function () {
															setshowModalConfirmacaoLimparPedidos(true);
														}}
													>
														<i className="bi bi-trash fs-4 me-1"></i>
														Limpar Importações
													</button>
												</>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
								<ClickableTable
									coluna={ColunaListaPedidos}
									itemsPerPage={itensPorPaginaListaPedidos}
									data={ListaPedidos}
									usaRowClick={true}
									onRowClick={function (pedido) {
										MostrarDetalhesPedido(pedido.num_pedido);
									}}
									acaoEditar={function () {}}
									acaoExcluir={function () {}}
									acaoVisualizar={function () {}}
									usaAcoes={false}
									usaEditar={true}
									usaExcluir={true}
									usaVisualizar={false}
									acessopesquisa="num_pedido"
									labelpesquisa="Procurar por N° Pedido"
								/>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-2 m-auto">
											<select
												value={itensPorPaginaListaPedidos}
												onChange={function (e: any) {
													setItensPorPaginaListaPedidos(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10}>10</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade show" id="NFePedido" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="container m-0 p-0">
								<div className="row">
									<div className="col-sm col-md-6 col-lg-3">
										<div className="form-floating me-1 ">
											<select
												value={DepositanteSelecionado + '|' + idDepositanteSelecionado}
												onChange={function (e) {
													setDepositanteSelecionado(e.target.value.split('|')[0]);
													setIdDepositanteSelecioando(e.target.value.split('|')[1]);
												}}
												className="form-select bordasInferiorInput text-black"
												id="floatingSelect"
												aria-label="Floating label select example"
											>
												<option value="">Selecione...</option>
												{ListaDepositantes.map(function (depositante: any) {
													return (
														<option value={depositante.cnpj + '|' + depositante.id_cliente}>
															{depositante.fantasia}
														</option>
													);
												})}
											</select>
											<label>Depositante</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-8 mt-2">
										{permissaoPedidoImportarPedido && (
											<>
												<label htmlFor="impNFS" className="btn btn-primary me-1">
													<i className="bi bi-download fs-4 me-1"></i> Importar NF's
												</label>
												<input
													id="impNFS"
													type="file"
													style={{ display: 'none' }}
													accept=".xml"
													multiple
													onChange={(e) => importarXmlConverterJson(e.target.files)}
												/>
											</>
										)}
										<button
											className="btn btn-danger me-1"
											onClick={function () {
												CarregarNFsDepositante();
											}}
										>
											<i className="bi bi-arrow-clockwise fs-4 me-1"></i>
											Listar NFe's
										</button>
										<button
											onClick={function () {
												setshowOpcoesPedidosNFE(true);
											}}
											className="btn btn-secondary me-1"
											hidden={NotasCarregadasPedidos.length == 0 ? true : false}
										>
											<i className="bi bi-card-list fs-4 me-1"></i>
											Gerar Pedidos
										</button>
									</div>
								</div>
							</div>
							<ClickableTable
								coluna={ColunasNotasNF}
								itemsPerPage={itensPorPaginaNFePedido}
								data={NotasCarregadasPedidos}
								usaRowClick={true}
								resetKey={resetKey}
								usaCheckBox={true}
								acaoCheckBox={function (nf, selected) {
									selecionarNota(nf, selected);
								}}
								onRowClick={function (nf) {
									CarregarDetalhesNota(nf.chave_acesso, id_filial);
								}}
								acaoEditar={function () {}}
								acaoExcluir={function (nf) {
									setNfdeletar(nf);
									setshowModalDeletarNF(true);
								}}
								acaoVisualizar={function () {}}
								usaAcoes={true}
								usaEditar={false}
								usaExcluir={true}
								usaVisualizar={false}
								acessopesquisa="nf"
								labelpesquisa="Procurar por NFe"
							/>
							<div className="container">
								<div className="row">
									<div className="col-lg-2 m-auto text-center">
										<label>Itens por Página</label>
									</div>
									<div className="w-100"></div>
									<div className="col-lg-2 m-auto">
										<select
											value={itensPorPaginaNFePedido}
											onChange={function (e: any) {
												setItensPorPaginaNFePedido(e.target.value);
											}}
											className="form-select form-select-sm"
											aria-label=".form-select-sm example"
										>
											<option value={10}>10</option>
											<option value={20}>20</option>
											<option value={30}>30</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade show" id="PedidoManual" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="container m-0 p-0">
								<div className="row">
									<div className="col-sm col-md col-lg-6">
										<h4>
											<b>Cabeçalho do Pedido</b>
										</h4>
									</div>
									{permissaoCriarPedido && (
										<div className="col-sm col-md col-lg-6 text-end">
											<button
												onClick={function () {
													setshowModalConfirmacaoPedidoManual(true);
												}}
												type="button"
												className="btn btn-secondary"
												hidden={ListaPedidosManual.length > 0 ? false : true}
											>
												<i className="bi bi-card-list me-1 fs-4"></i>
												Gerar Pedido
											</button>
										</div>
									)}
									<div className="col-sm col-md-6 col-lg-3">
										<div className="form-floating">
											<select
												value={DepositanteSelecionado + '|' + idDepositanteSelecionado}
												onChange={function (e) {
													setDepositanteSelecionado(e.target.value.split('|')[0]);
													setIdDepositanteSelecioando(e.target.value.split('|')[1]);
												}}
												className="form-select bordasInferiorInput text-black"
												id="floatingSelect"
												aria-label="Floating label select example"
											>
												<option value="">Selecione...</option>
												{ListaDepositantes.map(function (depositante: any) {
													return (
														<option value={depositante.cnpj + '|' + depositante.id_cliente}>
															{depositante.fantasia}
														</option>
													);
												})}
											</select>
											<label>Depositante*</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												required
												onChange={function (e) {
													setInputsPedidoCabecalhoManual({ ...inputsPedidoCabecalhoManual, data_carga: e.target.value });
												}}
												value={inputsPedidoCabecalhoManual.data_carga}
												type="date"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Data de Carga*</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-6">
										<div className="form-floating">
											<input
												required
												onChange={function (e) {
													setInputsPedidoCabecalhoManual({ ...inputsPedidoCabecalhoManual, num_pedido: e.target.value });
												}}
												value={inputsPedidoCabecalhoManual.num_pedido}
												type="text"
												className="form-control bordasInferiorInput text-black"
												placeholder=" "
											/>
											<label>Pedido*</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												onChange={function (e) {
													setInputsPedidoCabecalhoManual({ ...inputsPedidoCabecalhoManual, destinatario: e.target.value });
												}}
												value={inputsPedidoCabecalhoManual.destinatario}
												type="text"
												className="form-control bordasInferiorInput text-black"
												placeholder=" "
											/>
											<label>Destinatário</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												onChange={function (e) {
													setInputsPedidoCabecalhoManual({ ...inputsPedidoCabecalhoManual, roteiro: e.target.value });
												}}
												value={inputsPedidoCabecalhoManual.roteiro}
												type="text"
												className="form-control bordasInferiorInput text-black"
												placeholder=" "
											/>
											<label>Roteiro</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-12 mt-2">
										<h4>
											<b>Adicionar produto ao pedido</b>
										</h4>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												required
												onChange={function (e) {
													// Encontrar o option correspondente
													const option = Array.from(document.querySelectorAll('#ListaProdutos option')).find(
														(opt: any) => opt.value === e.target.value
													);
													// Obter o itemID
													const itemID = option ? option.getAttribute('itemID') : null;
													if (itemID != null && itemID != undefined) {
														setInputsPedidoDetalhe({
															...inputsPedidoDetalhe,
															cod_produto: e.target.value.split('|')[0],
															descricao: e.target.value.split('|')[1],
															id_wms: itemID,
														});
													} else {
														setInputsPedidoDetalhe({
															...inputsPedidoDetalhe,
															cod_produto: e.target.value.split('|')[0],
															descricao: e.target.value.split('|')[1],
															id_wms: '',
														});
													}
												}}
												value={inputsPedidoDetalhe.descricao}
												type="text"
												className="form-control bordasInferiorInput text-black"
												list="ListaProdutos"
												placeholder=" "
											/>
											<label>Produto*</label>
										</div>
										<datalist id="ListaProdutos">
											{ListaProdutos.map(function (produto: any) {
												return <option value={produto.codigo + '|' + produto.descricao} itemID={produto.id_wms}></option>;
											})}
										</datalist>
									</div>
									<div className="col-sm col-md col-lg-1">
										<div className="form-floating">
											<input
												required
												onChange={function (e) {
													setInputsPedidoDetalhe({ ...inputsPedidoDetalhe, qtde_emb: parseFloat(e.target.value) });
												}}
												value={inputsPedidoDetalhe.qtde_emb}
												type="number"
												className="form-control bordasInferiorInput text-black"
												placeholder=" "
											/>
											<label>Qtde*</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												required
												onChange={function (e) {
													setInputsPedidoDetalhe({ ...inputsPedidoDetalhe, tipo_emb: e.target.value });
												}}
												value={inputsPedidoDetalhe.tipo_emb}
												type="text"
												className="form-control bordasInferiorInput text-black"
												list="ListaEmbalagens"
												placeholder=" "
											/>
											<label>Tipo de Embalagem*</label>
										</div>
										<datalist id="ListaEmbalagens">
											{ListaEmbalagens.map(function (embalagem: any) {
												return <option value={embalagem.unid_manuseio}></option>;
											})}
										</datalist>
									</div>
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												onChange={function (e) {
													setInputsPedidoDetalhe({ ...inputsPedidoDetalhe, restricao: e.target.value });
												}}
												value={inputsPedidoDetalhe.restricao}
												type="date"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Menor Validade</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												onChange={function (e) {
													setInputsPedidoDetalhe({ ...inputsPedidoDetalhe, lote: e.target.value });
												}}
												value={inputsPedidoDetalhe.lote}
												type="text"
												className="form-control bordasInferiorInput text-black"
												placeholder=" "
											/>
											<label>Lote</label>
										</div>
									</div>
									{permissaoAdicionarItem && (
										<div className="col-sm col-md col-lg">
											<button
												type="button"
												className="btn btn-danger mt-3"
												onClick={function () {
													addProdutoPedidoManual();
												}}
											>
												Add. Produto
											</button>
										</div>
									)}
								</div>
							</div>
							<ClickableTable
								coluna={colunasProdutosPedidoManual}
								itemsPerPage={itensPorPaginaPedidoManual}
								data={ListaPedidosManual}
								usaRowClick={false}
								onRowClick={function () {}}
								acaoEditar={function () {}}
								acaoExcluir={function (produto) {
									setPordutoAExcluir(produto);
									setshowModalConfirmarRemoverProdutoPedidoManual(true);
								}}
								acaoVisualizar={function () {}}
								usaAcoes={true}
								usaEditar={false}
								usaExcluir={permissaoExcluirItem}
								usaVisualizar={false}
								acessopesquisa="cod_produto"
								labelpesquisa="Procurar por Código"
							/>
							<div className="container">
								<div className="row">
									<div className="col-lg-2 m-auto text-center">
										<label>Itens por Página</label>
									</div>
									<div className="w-100"></div>
									<div className="col-lg-2 m-auto">
										<select
											value={itensPorPaginaPedidoManual}
											onChange={function (e: any) {
												setItensPorPaginaPedidoManual(e.target.value);
											}}
											className="form-select form-select-sm"
											aria-label=".form-select-sm example"
										>
											<option value={10}>10</option>
											<option value={20}>20</option>
											<option value={30}>30</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalCarregando mostrar={showCarregando} mensagem="Carregando" />
			<ModalConfirmacao
				mostrar={showModalConfirmacaoLimparPedidos}
				msgAcao1="Cancelar"
				msgAcao2="Continuar"
				msgPrincipal="Deletar todos os pedido vai excluir os pedidos importados, deseja continuar ?"
				Acao1={function () {
					setshowModalConfirmacaoLimparPedidos(false);
				}}
				Acao2={function () {
					LimparPedidos();
				}}
			/>

			<ModalConfirmacao
				mostrar={showModalConfirmarRemoverProdutoPedidoManual}
				msgAcao1="Cancelar"
				msgAcao2="Remover"
				msgPrincipal="Remover produto do pedido?"
				Acao1={function () {
					setshowModalConfirmarRemoverProdutoPedidoManual(false);
				}}
				Acao2={function () {
					removerProdutoPedidoManual(produtoAExcluir);
				}}
			/>

			<ModalConfirmacao
				mostrar={showModalConfirmacaoPedidoManual}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal="Confirmar geração do pedido?"
				Acao1={function () {
					setshowModalConfirmacaoPedidoManual(false);
				}}
				Acao2={function () {
					CriarPedidoManual();
				}}
			/>

			<Modal
				size="lg"
				show={showModalImportacao}
				onHide={function () {
					setShowModalImportacao(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Opções de importação de pedidos</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md-12 col-lg-12 border p-1">
								<p className="text-center">
									Importação/Exportação <b>.xslx</b>
								</p>
								<div className="d-flex justify-content-center">
									<a download="TemplatePedido.xlsx" href={ExcelPedidoBase64()} className="btn btn-secondary me-1">
										Exportar template .xlsx
									</a>

									<input
										onChange={function (e) {
											setlistaPedidosImportados(e.target.files);
										}}
										type="file"
										id="importExcel"
										className="d-none"
										multiple
										accept=".xlsx"
									/>
									<label className="btn btn-primary" htmlFor="importExcel">
										Importar .xlsx
									</label>
								</div>
							</div>
							<div className="col-sm col-md-12 col-lg-12 border p-1 mt-1">
								<p className="text-center">
									Importação/Exportação <b>.txt</b>
								</p>
								<div className="d-flex justify-content-center">
									<a download="TemplatePedido.txt" href={TemplateTxtPedido()} className="btn btn-secondary me-1">
										Exportar template .txt
									</a>
									<input
										onChange={function (e) {
											setlistaPedidosImportadosTXT(e.target.files);
										}}
										type="file"
										id="importTXT"
										className="d-none"
										multiple
										accept=".txt"
									/>
									<label className="btn btn-primary" htmlFor="importTXT">
										Importar .txt
									</label>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showModalDetalhesPedido}
				size="lg"
				onHide={function () {
					setShowModalDetalhesPedidos(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Detalhes do pedido</h4>
				</Modal.Header>
				<Modal.Body>
					<ClickableTable
						coluna={colunaItensPedido}
						itemsPerPage={10}
						data={DetalhesPedidoSelecionado}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={true}
						usaExcluir={true}
						usaVisualizar={false}
						acessopesquisa="cod_produto"
						labelpesquisa="Cód.Produto"
					/>
				</Modal.Body>
			</Modal>

			<Modal
				centered
				size="lg"
				show={showModalListaNF}
				onHide={function () {
					setListaNotasImportadas([]);
					setListaNotasNaoImportadas([]);
					setShowModalListaNF(false);
				}}
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md-12 col-lg-12">
								<h4 className="text-center bg-primary text-white p-1">Importadas com sucesso.</h4>
								<ClickableTable
									coluna={[
										{
											titulo: 'NF',
											acesso: 'nf',
										},
										{
											titulo: 'Mensagem',
											acesso: 'mensagem',
										},
									]}
									itemsPerPage={10}
									data={ListaNotasImportadas}
									onRowClick={function () {}}
									acaoEditar={function () {}}
									acaoExcluir={function () {}}
									acaoVisualizar={function () {}}
									usaAcoes={false}
									usaEditar={false}
									usaExcluir={false}
									usaVisualizar={false}
									acessopesquisa="nf"
									labelpesquisa="Procurar por NF."
								/>
							</div>
							<div className="col-sm col-md-12 col-lg-12">
								<h4 className="text-center bg-danger text-white p-1">Notas não importadas ou com erro ao importar.</h4>
								<ClickableTable
									coluna={[
										{
											titulo: 'NF',
											acesso: 'nf',
										},
										{
											titulo: 'Mensagem',
											acesso: 'mensagem',
										},
									]}
									itemsPerPage={10}
									data={ListaNotasNaoImportadas}
									onRowClick={function () {}}
									acaoEditar={function () {}}
									acaoExcluir={function () {}}
									acaoVisualizar={function () {}}
									usaAcoes={false}
									usaEditar={false}
									usaExcluir={false}
									usaVisualizar={false}
									acessopesquisa="nf"
									labelpesquisa="Procurar por NF."
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md col-lg text-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Fechar"
									icone={mdiKeyboardReturn}
									onclick={() => {
										setListaNotasImportadas([]);
										setListaNotasNaoImportadas([]);
										setShowModalListaNF(false);

										const importacaoDeNota = document.querySelector('#impNFS') as HTMLInputElement;
										importacaoDeNota.value = '';
									}}
								/>
							</div>
						</div>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showModalItensNota}
				size="xl"
				onHide={function () {
					setShowModalItensNota(false);
					setDetalheItensNota([]);
				}}
			>
				<Modal.Header closeButton>
					<h4>Produtos da NFe</h4>
				</Modal.Header>
				<Modal.Body>
					<ClickableTable
						coluna={ColunasItensNota}
						itemsPerPage={10}
						data={DetalheItensNota}
						usaRowClick={false}
						usaCheckBox={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={true}
						usaVisualizar={false}
						acessopesquisa="codigo"
						labelpesquisa="Procurar por Código"
					/>
				</Modal.Body>
			</Modal>

			<ModalConfirmacao
				mostrar={showModalDeletarNF}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal="Deseja realmente excluir a NFe?"
				Acao1={function () {
					setshowModalDeletarNF(false);
				}}
				Acao2={function () {
					DeletarNFped(nfDeletar.chave_acesso, id_filial);
				}}
			/>

			<Modal
				centered
				show={showOpcoesPedidosNFE}
				onHide={function () {
					setshowOpcoesPedidosNFE(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Opções de criação</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md-12 col-lg-12 text-center m-auto">
								<button
									className="btn btn-primary w-100"
									onClick={function () {
										CriarUmPedidoParaCadaNFe();
									}}
								>
									Um Pedido para cada NFe selecionada
								</button>
							</div>
							<div
								className="col-sm col-md-12 col-lg-12 text-center mt-2"
								onClick={function () {
									CriarPedidoConsolidado();
								}}
							>
								<button className="btn btn-primary w-100">Pedido único para todas as NFe's selecionadas</button>
							</div>
							<div className="col-sm col-md-12 col-lg-12 text-center mt-2">
								<button className="btn btn-primary w-100">Pedido para cada destinátario das NFe's selecionadas</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default CriacaoDePedidos;
