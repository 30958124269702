import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PdfDocumentParams } from './interface';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface PdfDocumentArrayParams {
	documentos: PdfDocumentParams[];
}

export function PdfDocument({ documentos }: PdfDocumentArrayParams) {
	const GerarPDF = () => {
		// Calculando o tamanho da página
		const pageWidth = documentos[0].tamanho.largura * 28.35; // Conversão de cm para pontos (1 cm = 28.35 pontos)
		const pageHeight = documentos[0].tamanho.altura * 28.35; // Conversão de cm para pontos (1 cm = 28.35 pontos)

		// Fator de margem para ajustar de acordo com o tamanho da página
		const marginFactor = pageHeight * 0.05; // 5% da altura da página como margem

		const pages = documentos
			.map((docParams) => {
				const { logo, nomeDepositante, codigoUA, codigoBarras, textoCodigoBarras, descricao, codigo, pesoliquido, datavalidade } = docParams;

				const pageContent: any[] = [
					{
						columns: [
							{
								image: logo,
								width: pageWidth * 0.2, // 20% da largura da página
								height: pageHeight * 0.1, // 10% da altura da página								
								margin: [0, 0, 0, 0], 
							},
							{
								text: nomeDepositante.length > 20 ? nomeDepositante.substring(0, 19) : nomeDepositante, // Limita o nome do depositante a 20 caracteres
								fontSize: pageWidth * 0.04, // Ajusta a fonte proporcionalmente 
								width: pageWidth * 0.45, // 45% da largura da página								
								margin: [0, 0, 0, 0], 
							},
							{
								text: 'U.A.:',
								background: '#000',
								color: '#fff',
								fontSize: pageWidth * 0.04, // Ajusta a fonte proporcionalmente
								width: pageWidth * 0.1, // 10% da largura da página								
								margin: [0, 0, 0, 0], 
							},
							{
								text: codigoUA,
								fontSize: pageWidth * 0.05, // Ajusta a fonte proporcionalmente
								bold: true,
								width: pageWidth * 0.2, // 20% da largura da página							
								margin: [0, 0, 0, 0],
							},
						],
						margin: [0, 0, 0, pageHeight * 0.05], // Ajuste proporcional da margem
					},
					{
						image: codigoBarras,
						alignment: 'center',
						width: pageWidth * 0.9, // 90% da largura da página
						height: pageHeight * 0.25, // 25% da altura da página
						margin: [0, -pageHeight * 0.07, 0, 0],  // Ajuste proporcional da margem
					},
					{
						columns: [
							{
								text: 'Código:',
								fontSize: pageWidth * 0.06, // Ajusta a fonte proporcionalmente
								background: '#000',
								color: '#fff',
								margin: [0, 0, 0, 0],
								width: pageWidth * 0.55, // 55% da largura da página
							},
							{
								text: textoCodigoBarras,
								alignment: 'center',
								fontSize: pageWidth * 0.06, // Ajusta a fonte proporcionalmente
								margin: [-pageWidth * 0.35, 0, 0, 0], // Ajuste proporcional da margem
							},
						],
					},
					{
						columns: [
							{
								text: codigo,
								alignment: 'center',
								bold: true,
								fontSize: pageWidth * 0.07, // Ajusta a fonte proporcionalmente
								margin: [0, 0, 0, 0],
								width: pageWidth * 0.35, // 35% da largura da página
							},
							{
								text: ' PL ',
								alignment: 'center',
								background: '#000',
								color: '#fff',
								fontSize: pageWidth * 0.045, // Ajusta a fonte proporcionalmente
								margin: [0, 0, 0, 0],
							},
							{
								text: pesoliquido.toLocaleString('pt-BR', {
									minimumFractionDigits: 3,
									maximumFractionDigits: 3,
								}),
								alignment: 'left',
								bold: true,
								fontSize: pageWidth * 0.045, // Ajusta a fonte proporcionalmente
								margin: [0, 0, 0, 0],
							},
						],
					},
					{
						columns: [
							{
								text: '',
								alignment: 'center',
								bold: true,
								fontSize: pageWidth * 0.035, // Ajusta a fonte proporcionalmente
								margin: [0, 0, 0, 0],
								width: pageWidth * 0.35, // 35% da largura da página
							},
							{
								text: 'Val.',
								alignment: 'center',
								background: '#000',
								color: '#fff',
								fontSize: pageWidth * 0.045, // Ajusta a fonte proporcionalmente
								margin: [0, -pageHeight * 0.03, 0, 0], // Ajuste proporcional da margem
							},
							{
								text: datavalidade,
								alignment: 'left',
								bold: true,
								fontSize: pageWidth * 0.045, // Ajusta a fonte proporcionalmente
								margin: [0, -pageHeight * 0.03, 0, 0], // Ajuste proporcional da margem
							},
						],
					},
					{
						text: descricao.length > 35 ? descricao.substring(0, 34) : descricao, // Limita a descrição a 35 caracteres
						alignment: 'left',
						bold: true,
						fontSize: pageWidth * 0.05, // Ajusta a fonte proporcionalmente
						width: pageWidth * 0.9, // 90% da largura da página
						height: pageHeight * 0.15, // 15% da altura da página						
						margin: [
							0,
							documentos[0].tamanho.altura === documentos[0].tamanho.largura
								? pageHeight * 0.1
								: documentos[0].tamanho.altura > 6
								? pageHeight * 0.1
								: 0.05, 
							0,
							documentos[0].tamanho.altura === documentos[0].tamanho.largura ? pageHeight * 0.25 :
							
							documentos[0].tamanho.altura > 6
							? pageHeight * 0.15
							:0.1,
						], // Ajuste proporcional da margem
					},
				];

				return pageContent;
			})
			.flat();

		const docDefinition: any = {
			pageSize: { width: pageWidth, height: pageHeight },
			pageMargins: [marginFactor, marginFactor, marginFactor, marginFactor], // Margens dinâmicas
			pageOrientation: documentos[0].orientacao === 'retrato' ? 'portrait' : 'landscape', // Orientação da página dinâmica 
			content: pages,
		};

		pdfMake.createPdf(docDefinition).open(); // Abre o PDF em uma nova aba
	};

	return GerarPDF;
}
