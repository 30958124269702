const gerarAlfabetoCabecalho = (num: number) => {
	const letters = [];
	for (let i = 0; i < num; i++) {
		let letter = '';
		let temp = i;
		while (temp >= 0) {
			letter = String.fromCharCode((temp % 26) + 65) + letter;
			temp = Math.floor(temp / 26) - 1;
		}
		letters.push(letter);
	}
	return letters;
};

export default gerarAlfabetoCabecalho;
