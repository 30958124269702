import { Link } from 'react-router-dom';

const EmbalagemSemTexto = (): JSX.Element => {
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Embalagem s/TxT</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2">
									<div className="col-sm-4">
										<a
											data-bs-toggle="modal"
											data-bs-target="#modal-confEntrada-embalagemSTxt-Novo"
											className="btn rounded-pill mb-2"
											style={{ backgroundColor: "#1c4b9b", color: "white" }}
										>
											<i className="mdi mdi-plus-circle me-2"></i>
											Nova Embalagem
										</a>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>Processos</th>
												<th>Depositante</th>
												<th>Chegada</th>
												<th>Tipo Operação</th>
												<th>Modalidade</th>
												<th>Status</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>61</td>
												<td>Depositante 1</td>
												<td>29/11/21 16:00:00</td>
												<td>ENTRADA</td>
												<td>CROSSDOCKING</td>
												<td>ABERTO</td>
												<td className="table-action">
													<Link to={`/operacao/confEntrada/embalagemSemTexto/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
											<tr>
												<td>60</td>
												<td>Depositante 2</td>
												<td>29/11/21 15:00:00</td>
												<td>ENTRADA</td>
												<td>ARMAZENAGEM</td>
												<td>CARGA/DESCARGA</td>
												<td className="table-action">
													<Link to={`/operacao/confEntrada/embalagemSemTexto/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
											<tr>
												<td>59</td>
												<td>Depositante 3</td>
												<td>28/11/21 12:00:00</td>
												<td>ENTRADA</td>
												<td>ARMAZENAGEM</td>
												<td>AUTORIZAÇÃO DE ENTRADA</td>
												<td className="table-action">
													<Link to={`/operacao/confEntrada/embalagemSemTexto/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
											<tr>
												<td>58</td>
												<td>Depositante 4</td>
												<td>27/11/21 14:00:00</td>
												<td>ENTRADA</td>
												<td>DEVOLUÇÕES</td>
												<td>LIBERADO</td>
												<td className="table-action">
													<Link to={`/operacao/confEntrada/embalagemSemTexto/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
											<tr>
												<td>57</td>
												<td>Depositante 5</td>
												<td>26/11/21 16:30:00</td>
												<td>ENTRADA</td>
												<td>RE-ENTRADA</td>
												<td>CANCELADO</td>
												<td className="table-action">
													<Link to={`/operacao/confEntrada/embalagemSemTexto/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-confEntrada-embalagemSTxt-Novo"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Nova Embalagem
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="processo" aria-describedby="processoHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="processo">Processo</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="depositante" aria-describedby="depositanteHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="depositante">Depositante</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="codigo_produto" aria-describedby="codigoProdutoHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="codigo_produto">Código produto</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="rg_embalagem" aria-describedby="rgEmbalagemHelp" placeholder="" />
												<label htmlFor="rg_embalagem">RG Embalagem</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="codigo_barras" aria-describedby="codigoBarrasHelp" placeholder="" />
												<label htmlFor="codigo_barras">Código Barras</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="peso_bruto" aria-describedby="pesoBrutoHelp" placeholder="" />
												<label htmlFor="peso_bruto">Peso Bruto</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="peso_liquido" aria-describedby="pesoLiquidoHelp" placeholder="" />
												<label htmlFor="peso_liquido">Peso Líquido</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="data_producao" aria-describedby="dataProducaoHelp" placeholder="" />
												<label htmlFor="data_producao">Data Produção</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="data_validade" aria-describedby="dataValidadeHelp" placeholder="" />
												<label htmlFor="data_validade">Data Validade</label>
											</div>
										</div>
									</div>
								</div>

								<div className="modal-footer">
									<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { EmbalagemSemTexto };
