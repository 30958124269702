import { useNavigate, useParams } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSave } from "react-icons/fa";
import { RiFileList2Fill } from "react-icons/ri";
import { SiVerizon } from "react-icons/si";
import {
	mdiArrowDownBoldBoxOutline,
	mdiArrowUpBoldBoxOutline,
	mdiEye,
	mdiFileExport,
	mdiFileImport,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { FaPlus } from "react-icons/fa";
import { toast } from 'react-toastify';
import { Produto } from '../../../configuracoes/cadastro/produto/produto';

const NovaConferenciaEntrada = (): JSX.Element => {
	const navigate = useNavigate();
	const params = useParams()

	const [informacoesConferencia, set_informacoesConferencia] = useState<any>([])
	const [informacoesNotas, set_informacoesNotas] = useState<any>([])

	function carregarInformacaoConferencia(processo: any) {

		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/infos/conferenrencia?processo=${processo}&auth=${sessionStorage.getItem("IsFKLTOKEN")}&codfilial=${sessionStorage.getItem("IsCodFilialPadraoFKL")}`)
			.then(function (resposta) {
				console.log("resposta: " + resposta.data)
				if (resposta.data.codigo != 200) {

					alert(resposta.data.message)
				}
				else {
					setNumeroNF(resposta.data.informacoesConferencia[1].nf_info[0].nfennf)
					set_informacoesConferencia(resposta.data.informacoesConferencia[0].informacoesBasicas)
					set_informacoesNotas(resposta.data.informacoesConferencia[1].nf_info)
				}
			}).catch(function (erro) {

				alert(erro)
			})
	}

	const [tiposCarga, set_tiposCarga] = useState([])
	function CarregarTiposCarga() {

		axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistemadet/json/tabela/?auth=${sessionStorage.getItem("IsFKLTOKEN")}&tabela=TIPOCARGA`)
			.then(function (resposta) {

				set_tiposCarga(resposta.data)
			}).catch(function (erro) {

				alert(erro)
			})
	}

	useEffect(function () {

		carregarInformacaoConferencia(params.id)
		CarregarTiposCarga()
		carregaFamilia()

	}, [])

	//Buscar familias para listbox
	const [familias, setFamilias] = useState([])
	function carregaFamilia() {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistemadet/json/tabela/?tabela=FAMILIA&auth=${sessionStorage.getItem("IsFKLTOKEN")}`)
			.then(function (resposta) {
				setFamilias(resposta.data)
			}).catch(function (erro) {
				toast.error("Não foi possível carregar dados de familias: " + erro)
			})
	}



	//PAGINAÇÃO DE PROutos na nota aba "Geral"
	//-----PAGINAÇÃO-------//
	//#region Paginação,
	const [itensPerPage, setItensPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pages = Math.ceil(informacoesNotas.length / itensPerPage);

	const startIndex = currentPage * itensPerPage;
	const endIndex = startIndex + itensPerPage;
	const itensSelecionados = informacoesNotas.slice(startIndex, endIndex);
	//#endregion
	//#region Modifica pagina da lista de relatorios
	const primeiraPagina = () => {
		if (currentPage > 0) {
			setCurrentPage(0);
		}
	};
	const paginaAnterior = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(pages - 1);
		}
	};


	//filtro de busca por cod produto
	const [busca, set_busca] = useState("")
	function procurarCodProduto(string: string) {

		if (string == '' || string == ' ') {

			carregarInformacaoConferencia(params.id)
		}
		else if (string.length >= 3) {

			//deixar a string minuscula e comparar com informacao.itemdescricao minuscul tambem = .toLowerCase()

			const encontrados = informacoesNotas.filter(function (informacao: any) {



				return informacao.itemcodigo.includes(string) || informacao.itemdescricao.toLowerCase().includes(string.toLowerCase())
			})

			set_informacoesNotas(encontrados)
		}
	}

	const [lerBarra, set_lerBarra] = useState("")


	const [conferencia, set_conferencia] = useState<any>(false)
	function IniciarConferencia() {

		const dados = {
			usuario: sessionStorage.getItem("IsUserFKL")
		}

		axios.put(`${process.env.REACT_APP_API_BASE_URL}/updateconf/status/${params.id}?auth=${sessionStorage.getItem("IsFKLTOKEN")}`, dados)
			.then(function (resposta) {


				alert(resposta.data.message)
				set_conferencia(resposta.data.conferencia)
			}).catch(function (erro: any) {

				alert(erro)
			})
	}

	//Variávei de estado para carregar informacoes de produtos
	const [checkProduto, setCheckProduto] = useState<any>("")
	const [numeroNF, setNumeroNF] = useState<any>()
	const [infoProduto, setInfoProduto] = useState<any>()

	//Funcao para carregarar unidade de manuseio na tela Cadastro de produtos
	// function carregaInfoCadProduto() {
	// 	console.log("numero nf: " + numeroNF)

	// 	axios.get(`${process.env.REACT_APP_API_BASE_URL}/informacoes/produtos?auth=${sessionStorage.getItem("IsFKLTOKEN")}&numeroNF=${numeroNF}&checkProduto=${checkProduto}`)
	// 		.then(function (resposta) {
	// 			console.log(resposta.data)
	// 			setInfoProduto(resposta.data)
	// 		}).catch(function (erro) {
	// 			alert("Erro ao carregar informações do produto: " + erro)
	// 		})
	// }

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Conferência Entrada</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a href="#tabConfEntrGeral" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
									<li className="nav-item">
										<a href="#tabConfEntrPaltCadast" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">U.A Cadastrado</span>
										</a>
									</li>
									<li className="nav-item">
										<a href="#tabConfEntrDiverg" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-settings-outline d-md-none d-block"></i>
											<span className="d-none d-md-block">Divergência</span>
										</a>
									</li>
									<li className="nav-item">
										<a href="#tabConfEntrEquip" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-settings-outline d-md-none d-block"></i>
											<span className="d-none d-md-block">Equipamentos</span>
										</a>
									</li>
									{/*<li className="nav-item">
										<a href="#tabConfEntrCheckList" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-settings-outline d-md-none d-block"></i>
											<span className="d-none d-md-block">Check List</span>
										</a>
	</li> 							*/}
									<li className="nav-item">
										<a href="#tabConfEntrAudit" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-settings-outline d-md-none d-block"></i>
											<span className="d-none d-md-block">Qualidade</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tabConfEntrGeral">
										{informacoesConferencia.map(function (informacao: any) {

											return (
												<>
													<div className="row">
														<div className="col-sm-2 form-floating">

															<input type="text" value={informacao.num_processo} disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
															<label className="form-label">Processo</label>
														</div>
														<div className="col-sm-3">
															<div className="mb-1 form-floating">

																<input type="text" value={informacao.fantasia} disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
																<label className="form-label">Depositante</label>
															</div>
															{/*<div className="mb-1">
													<label className="form-label">Carreta</label>
													<input type="text" disabled className="form-control" />
												</div> */}
														</div>
														<div className="col-sm-2">
															<div className="mb-1 form-floating">

																<input type="text" value={informacao.criterio} disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
																<label className="form-label">Critério</label>
															</div>
															{/*<div className="mb-1">
													<label className="form-label">Tipo Veículo</label>
													<input type="text" disabled className="form-control" />
												</div> */}
														</div>
														<div className="col-sm-3">
															<div className="mb-1 form-floating">

																<input type="text" value={informacao.descricao_modalidade} disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
																<label className="form-label">Modalidade</label>
															</div>
															{/*<div className="mb-1">
													<label className="form-label">Motorista</label>
													<input type="text" disabled className="form-control" />
												</div> */}
														</div>
														<div className='col-sm-2'>
															<div className='mb-1 form-floating'>

																<input type="text" value={informacao.cavalo || informacao.carreta} disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
																<label className="form-label">Placa</label>
															</div>
														</div>
													</div>
												</>
											)
										})}
										<hr className="mt-1 mb-1" />
										<div className="row mt-1">

											<div className='col pt-3'>
												<button
													className='btn btn-danger me-2 rounded' onClick={function () {
														IniciarConferencia()
													}}>Iniciar</button>
												<button className='btn btn-cancelar me-2 rounded'>Concluir</button>
												<button className='btn btn-cancelar me-2 rounded' data-toggle="modal" data-target=".preCadProd">Cad.Produto</button>
												<button type="button" className="btn-cancelar btn rounded" data-bs-toggle="modal" data-bs-target="#ModalLerBarra">
													Ler Barra
												</button>
											</div>
											<div className='col form-floating'>
												<input
													type="search"
													placeholder='Procure aqui...'
													className='form-control bordasInferiorInput text-black w-75'
													onChange={function (e: any) {
														set_busca(e.target.value)
														procurarCodProduto(e.target.value)
													}}
													value={busca}
												/>
												<label htmlFor="">Buscar por produto</label>
											</div>
											<div className="row">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
														<thead className="table" style={{ backgroundColor: "#1c4b9b", color: "white" }} >
															<tr>
																<th>#</th>
																<th>Cod Produto</th>
																<th>Descrição</th>
																<th>Temperatura</th>
																<th>Tipo Peso</th>
																<th>Unid Manuseio</th>
																{/*<th>Fator Fiscal</th> */}
																<th>Tara</th>
																<th>Ações</th>
															</tr>
														</thead>
														<tbody>
															{informacoesNotas.map(function (informacao: any) {

																return (
																	<>
																		<tr>

																			<td style={{ backgroundColor: "#1c4b9b", color: "white" }}><input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={function () {
																				setCheckProduto(informacao.itemcodigo)
																				//carregaInfoCadProduto()
																			}} /></td>
																			<td><label htmlFor="" className='mt-1'>{informacao.itemcodigo}</label></td>
																			<td><label className='mt-1'>{informacao.itemdescricao}</label></td>
																			<td><label className='mt-1'>{informacao.geral_temperatura || "---"}</label></td>
																			<td><label className='mt-1'>{informacao.geral_tipopeso || "---"}</label></td>
																			<td><label className='mt-1'>{informacao.geral_idunidademanuseio || "---"}</label></td>
																			{/*<td><label className='mt-1'>1</label></td> */}
																			<td><label className='mt-1'>{informacao.geral_tara || "---"}</label></td>
																			<td className="table-action"
																				onClick={function () {
																					//colcoar func para carregar informações no modal
																				}}>
																				<a
																					data-bs-toggle="modal"
																					data-bs-target="#modal-confEntrada-detPaletes"
																					className="action-icon"
																				>
																					<i className="mdi mdi-square-edit-outline"></i>
																				</a>
																			</td>
																		</tr>
																	</>
																)
															})}
														</tbody>
														<tfoot>
															{/*paginação aqui*/}
															<tr className="rodapeTabelaPaginacao">
																<td colSpan={14}>
																	<a onClick={primeiraPagina} title="Primeira Página">
																		<Icon path={mdiSkipBackward} size={1} />
																	</a>
																	<a onClick={paginaAnterior} title="Página Anterior">
																		<Icon path={mdiSkipPrevious} size={1} />
																	</a>
																	Página: {currentPage + 1} de {pages} | Registros Total: {informacoesNotas.length}
																	<a onClick={proxPagina} title="Próxima Página">
																		<Icon path={mdiSkipNext} size={1} />
																	</a>
																	<a onClick={ultimaPagina} title="Última Página">
																		<Icon path={mdiSkipForward} size={1} />
																	</a>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div >
									<div className="tab-pane show" id="tabConfEntrPaltCadast">
										<div className="row">
											<div className="col-sm-10 mb-2">
												{/*<button type="button" className="btn btn-cancelar me-2">
													Limpar Qtd Romaneadas
										</button> */}
												<button type="button" className="btn btn-cancelar rounded">
													<span className="iconify me-1" data-icon="dashicons:printer"></span>
													Imprimir Etiquetas
												</button>
											</div>
										</div>
										<div className="row">
											<div className="table-responsive">
												<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
													<thead className="table" style={{ backgroundColor: "#1c4b9b", color: "white" }} >
														<tr>
															{/*<th>#</th> */}
															<th>Palete</th>
															<th>Código</th>
															<th>Descrição</th>
															<th>Qtd Volumes</th>
															<th>Peso Bruto</th>
															<th>Peso Liquido</th>
															<th>Ações</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															{/*<td>
																<input type="checkbox" />
															</td> */}
															<td>61</td>
															<td>7001.000322</td>
															<td>COXINHA DE FRANGO FRITA LANCHE CX 3X10UN</td>
															<td>34</td>
															<td>120.50</td>
															<td>110.00</td>
															<td className="table-action">
																<a
																	data-bs-toggle="modal"
																	data-bs-target="#modal-confEntrada-detPaletes"
																	className="action-icon"
																>
																	<i className="mdi mdi-square-edit-outline"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="tab-pane show" id="tabConfEntrDiverg">
										<div className="row">
											<div className="col-sm-5 mb-2">
												<button type="button" className="btn btn-cancelar rounded">
													<span><SiVerizon size={15} /> Aprovar Divergência</span>
												</button>
											</div>
										</div>
										<div className="row">
											<div className="table-responsive">
												<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
													<thead className="table" style={{ backgroundColor: "#1c4b9b", color: "white" }} >
														<tr>

															<th>Código</th>
															<th>Descrição</th>
														</tr>
													</thead>
													<tbody>
														<tr>

															<td>7001.000322</td>
															<td>ESFIHA DE FRANGO ASSADA COQUETEL CX 8X1KG</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="tab-pane show" id="tabConfEntrEquip">
										{/*<div className="row">
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Processo</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Cliente</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Tipo Operação</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Modal Operação</label>
											</div>
										</div> */}
										<div className="row">
											<div className="col-sm-5 mb-2">
												<button
													type="button"
													className="btn btn-cancelar"
													data-bs-toggle="modal"
													data-bs-target="#modal-confEntrada-novoEquip"
												>
													<span className='me-1'> <FaPlus size={15} /></span>
													Novo Equipamento
												</button>
											</div>
										</div>
										<div className="row">
											<div className="table-responsive">
												<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
													<thead className="table" style={{ backgroundColor: "#1c4b9b", color: "white" }} >
														<tr>
															<th>Equipamento</th>
															<th>Tipo Operação</th>
															<th>Qtde Retirada</th>
															<th>Qtde Depositada</th>
															<th>Peso Total</th>
															<th>Ação</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>PALLET PBR 1 x1,2 MTS</td>
															<td>ENTRADA</td>
															<td>0,00</td>
															<td>0,00</td>
															<td>0,00</td>
															<td className="table-action">
																<a href="javascript:void(0);" className="action-icon">
																	<i className="mdi mdi-delete"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>






									<div className="tab-pane show" id="tabConfEntrAudit">
										<div className='mb-2 w-100 d-flex'>



											<button type="button" className='btn btn-cancelar rounded' data-toggle="modal" data-target="#checklist">
												<RiFileList2Fill size={15} />  Preencher
											</button>

											<div className='statusCheckList'>

												<div
													className="ms-1"
													style={{ alignItems: 'baseline', justifyContent: 'space-between' }}
												>
													<label
														className="form-label"
														htmlFor="switch37"
														style={{ position: 'relative', top: '-7px' }}
													>

													</label>
													<input type="checkbox" id="switch37" data-switch="bool" />
													<label htmlFor="switch37" data-on-label="On" data-off-label="Off"></label>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-sm-2">
												<div className="row">

													<div className="col form-floating">
														<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
														<label className="form-label align-middle">TEMP INICIO</label>
													</div>
												</div>
											</div>
											<div className="col-sm-2">
												<div className="row">

													<div className="col form-floating">
														<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
														<label className="form-label">TEMP MEIO</label>
													</div>
												</div>
											</div>
											<div className="col-sm-2">
												<div className="row">

													<div className="col form-floating">
														<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
														<label className="form-label">TEMP FIM</label>
													</div>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="row">

													<div className="col form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize">
															<option>SEM LACRE</option>
														</select>
														<label className="form-label">LACRE</label>
													</div>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="row">

													<div className="col form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize">
															<option value=""></option>
															{tiposCarga.map(function (tipoCarga: any) {

																return (
																	<>
																		<option value={tipoCarga.descricao}>{tipoCarga.descricao}</option>
																	</>
																)
															})}
														</select>
														<label className="form-label">TIPO CARGA</label>
													</div>
												</div>
											</div>
										</div>
										<div className='row pt-2'>
											<hr />
											<div className='col-4 d-flex'>

												<button className='btn btn-cancelar w-50 btn-sm rounded' onClick={function () {
													const file = document.querySelector("#img1input") as HTMLElement
													file.click()
												}}>Img 1</button>
												<div
													className="pt-1 ms-1"
													style={{ alignItems: 'baseline', justifyContent: 'space-between' }}
												>
													<input type="checkbox" id="switch38" data-switch="bool" />
													<label htmlFor="switch38" data-on-label="On" data-off-label="Off"></label>
												</div>
												<input type="file" id='img1input' hidden />
											</div>
											<div className='w-100 pb-2'></div>
											<div className='col-4 d-flex'>

												<button className='btn btn-cancelar w-50 btn-sm rounded' onClick={function () {

													const file = document.querySelector("#img2input") as HTMLElement
													file.click()
												}}>Img 2</button>
												<div
													className="pt-1 ms-1"
													style={{ alignItems: 'baseline', justifyContent: 'space-between' }}
												>
													<input type="checkbox" id="switch39" data-switch="bool" />
													<label htmlFor="switch39" data-on-label="On" data-off-label="Off"></label>
												</div>
												<input type="file" id='img2input' hidden />
											</div>
											<div className='w-100 pb-2'></div>
											<div className='col-4 d-flex'>

												<button className='btn btn-cancelar w-50 btn-sm rounded' onClick={function () {

													const file = document.querySelector("#img3input") as HTMLElement
													file.click()
												}}>Img 3</button>
												<div
													className="pt-1 ms-1"
													style={{ alignItems: 'baseline', justifyContent: 'space-between' }}
												>
													<input type="checkbox" id="switch40" data-switch="bool" />
													<label htmlFor="switch40" data-on-label="On" data-off-label="Off"></label>
													<input type="file" id='img3input' hidden />
												</div>
											</div>
											<div className='w-100 pb-2'></div>
											<div className='col-4 d-flex'>

												<button className='btn btn-cancelar w-50 btn-sm rounded' onClick={function () {

													const file = document.querySelector("#img3input") as HTMLElement
													file.click()
												}}>Img 4</button>
												<div
													className="pt-1 ms-1"
													style={{ alignItems: 'baseline', justifyContent: 'space-between' }}
												>
													<input type="checkbox" id="switch41" data-switch="bool" />
													<label htmlFor="switch41" data-on-label="On" data-off-label="Off"></label>
												</div>
												<input type="file" id='img4input' hidden />
											</div>
										</div>

									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-cancelar mb-2 me-1 voltar rounded"
											type="button"
											onClick={() => navigate('/operacao/confEntrada/confEntrada')}
										>
											Voltar
										</button>
										{/*
										<button
											className="btn btn-cancelar mb-2 me-1"
											type="button"
											onClick={() => navigate('/operacao/confEntrada/confEntrada')}
										>
											Salvar
										</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-confEntrada-detPaletes"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe da U.A
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-2 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Palete</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Código</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Descrição</label>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Volume</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso Bruto</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso Liquido</label>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Menor data Prod</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Menor data Valid</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Maior data Prod</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Maior data Valid</label>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Lote</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">SIF</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Temperatura</label>
										</div>
										<div className="col-sm-3 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Data Cadastro</label>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-confEntrada-novoEquip"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title rounded" id="staticBackdropLabel">
								Novo Equipamento
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<div className="mb-1 form-floating ">

											<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
											<label className="form-label">ID do Equipamento</label>
										</div>
										<div className="mb-1 form-floating">

											<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
											<label className="form-label">Quantidade</label>
										</div>
										<div className="mb-1 form-floating">

											<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
											<label className="form-label">Preço Total</label>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-cancelar" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-confEntrada-cadPalete"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe da U.A
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="palete" placeholder=" " />
												<label htmlFor="palete" className="form-label">Palete</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="codigo" placeholder=" " />
												<label htmlFor="codigo" className="form-label">Código</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="descricao" placeholder=" " />
												<label htmlFor="descricao" className="form-label">Descrição</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="volume" placeholder=" " />
												<label htmlFor="volume" className="form-label">Volume</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pesoBruto" placeholder=" " />
												<label htmlFor="pesoBruto" className="form-label">Peso Bruto</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pesoLiquido" placeholder=" " />
												<label htmlFor="pesoLiquido" className="form-label">Peso Líquido</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="menorDataProd" placeholder=" " />
												<label htmlFor="menorDataProd" className="form-label">Menor Data Prod</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="menorDataValid" placeholder=" " />
												<label htmlFor="menorDataValid" className="form-label">Menor Data Valid</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="maiorDataProd" placeholder=" " />
												<label htmlFor="maiorDataProd" className="form-label">Maior Data Prod</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="maiorDataValid" placeholder=" " />
												<label htmlFor="maiorDataValid" className="form-label">Maior Data Valid</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="lote" placeholder=" " />
												<label htmlFor="lote" className="form-label">Lote</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="sif" placeholder=" " />
												<label htmlFor="sif" className="form-label">SIF</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="temperatura" placeholder=" " />
												<label htmlFor="temperatura" className="form-label">Temperatura</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="dataCadastro" placeholder=" " />
												<label htmlFor="dataCadastro" className="form-label">Data Cadastro</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="temperaturaRotulo" placeholder=" " />
												<label htmlFor="temperaturaRotulo" className="form-label">Temperatura Rótulo</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="lastro" placeholder=" " />
												<label htmlFor="lastro" className="form-label">Lastro</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="camadas" placeholder=" " />
												<label htmlFor="camadas" className="form-label">Camadas</label>
											</div>
										</div>
									</div>
								</div>

								<div className="modal-footer">
									<button type="button" className="btn btn-danger rounded" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button className="btn btn-cancelar me-1 rounded" type="button" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>





			<div className="modal fade" id="ModalLerBarra" tabIndex={-1} aria-labelledby="ModalLerBarraLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="text-center w-100" id="ModalLerBarraLabel">Ler Barra</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className='col text-center form-floating'>
								<input type="text"
									className='form-control bordasInferiorInput text-black text-capitalize'
									onChange={function (e) {
										set_lerBarra(e.target.value)
									}}
									value={lerBarra}
									placeholder='Ler Barra' />
								<label>Barra</label>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-cancelar rounded" data-bs-dismiss="modal">Cancelar</button>
							<button type="button" className="btn btn-novo rounded">Continuar</button>
						</div>
					</div>
				</div>
			</div>





			<div className="modal fade" id="checklist" tabIndex={-1} role="dialog" aria-labelledby="checklistLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="checklistLabel">CheckList</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="tab-pane show" id="tabConfEntrCheckList">
								{/*
										<div className="row">
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Processo</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Cliente</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Tipo Operação</label>
											</div>
											<div className="col-sm-3 form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">Modal Operação</label>
											</div>
										</div>
										 */}

								<div className="row">

									<div className="col-sm-10 mb-2">
										{/*<button type="button" className="btn btn-cancelar me-2">
													Limpar Qtd Romaneadas
										</button> */}

									</div>
									<div className="col-sm-4">
										<div className="mb-1 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
											<label className="form-label" style={{ paddingTop: '5px' }}>
												<strong>CONDIÇÕES E HIGIÊNE DO VEÍCULO</strong>
											</label>
										</div>
										<div className="row">
											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>C</option>
												</select>
												<label className="form-label">HIGIENE E LIMPEZA</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>N/C</option>
												</select>
												<label className="form-label">LIVRE DE ODORES</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>C</option>
												</select>
												<label className="form-label">TEMPERATURA ADEQUADA</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>N/A</option>
												</select>
												<label className="form-label">VEDAÇÃO EFICIENTE</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>C</option>
												</select>
												<label className="form-label">INTEGRIDADE DAS LÂMPADAS</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>C</option>
												</select>
												<label className="form-label">ASSEIO DO MOTORISTA</label>
											</div>
										</div>
										<div className="row">
											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>C</option>
												</select>
												<label className="form-label">MOTORISTA UNIFORMIZADO</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
													<option>N/A</option>
												</select>
												<label className="form-label">CONTROLE DE PRAGAS VIGENTE</label>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="mb-1 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
											<label className="form-label" style={{ paddingTop: '5px' }}>
												<strong>CONTROLE AVARIAS</strong>
											</label>
										</div>
										<div className="row">

											<div className="col form-floating">

												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">QTD AVARIA INTERNA</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">QTD AVARIA EXTERNA</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">QTD AVARIA AMASSADO</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">QTD AVARIA MOLHADO</label>
											</div>
										</div>
										<div className="mb-1 mt-2 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
											<label className="form-label" style={{ paddingTop: '5px' }}>
												<strong>SERVIÇOS EXTRAS</strong>
											</label>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">REPALETIZAÇÃO COM FILME</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">APLICAÇÃO/REFORÇO FILME</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">COLAGEM DE ETIQUETAS (CX)</label>
											</div>
										</div>
										<div className="row">

											<div className="col form-floating">
												<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
												<label className="form-label">RE-EMBALAGEM(CX)</label>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="mb-1 text-center" style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
											<label className="form-label" style={{ paddingTop: '5px' }}>
												<strong>OBSERVAÇÕES GERAIS CHECK-LIST</strong>
											</label>
										</div>
										<div className='form-floating'>
											<textarea className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' rows={14}></textarea>
											<label htmlFor="">OBSERVAÇÕES</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-cancelar" data-dismiss="modal">Cancelar</button>
							<button type="button" className="btn btn-novo">
								<span className='me-1'><FaSave size={15} /></span>Salvar Check List
							</button>
						</div>
					</div>
				</div>
			</div>

			{/*MODAL PRE CAD PRODUTOD */}
			<div className="modal fade preCadProd" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="preCadProdLabel">Pré cadastro de produto</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form>
								<div className='d-flex'>
									{/*<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' />
										<label htmlFor="">Código</label>
									</div> */}
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' disabled />
										<label htmlFor="">Código</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' disabled />
										<label htmlFor="">Fator Fiscal</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' disabled />
										<label htmlFor="">Und. Fiscal</label>
									</div>
									<div className='form-floating me-2 w-75'>
										<input type="text" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' disabled />
										<label htmlFor="">Descrição</label>
									</div>
								</div>
								<br />
								<div className='d-flex'>
									<div className='form-floating me-2 w-50'>
										<select className='form-select bordasInferiorInput text-black text-capitalize' name="" id="" disabled>
											<option value=""></option>
										</select>
										<label htmlFor="">Tipo de peso</label>
									</div>
									<div className='form-floating me-2 w-25'>
										<select className='form-select bordasInferiorInput text-black text-capitalize' name="" id="" disabled>
											<option value=""></option>
										</select>
										<label htmlFor="">Unid. Fiscal</label>
									</div>
								</div>
								<br />
								<div className='d-flex'>
									<div className='form-floating me-2 w-50'>

										<select className='form-select bordasInferiorInput text-black text-capitalize'>
											<option value=""></option>

											{familias.map(function (familia: any) {

												return (
													<>
														<option key={familia.id} value={familia.descricao}>{familia.descricao}</option>
													</>
												)
											})}
										</select>
										<label className='form-label'>Familia</label>
									</div>
									<div className='form-floating me-2 w-25'>
										<select className='form-select bordasInferiorInput text-black text-capitalize' name="" id="">

											{/* {infoProduto.map(function (produto: any, index: number) {

												return (
													<>
														<option key={index} value={produto.itemuntrib}>{produto.itemuntrib}</option>
													</>
												)
											})} */}
										</select>
										<label htmlFor="">Unid. de manuseio</label>
									</div>
									<div className='form-floating me-2 w-25'>
										<select className='form-select bordasInferiorInput text-black text-capitalize' name="" id="">
											<option value=""></option>
										</select>
										<label htmlFor="">Temperatura</label>
									</div>
									<div className='form-floating me-2 w-25'>

										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' />
										<label htmlFor="">Dias de validade</label>
									</div>
								</div>
								<br />
								<div className='d-flex'>

									<div className='form-floating me-2 w-75'>
										<input type="text" placeholder='' className='form-control bordasInferiorInput text-black text-capitalize' />
										<label htmlFor="">GTIN</label>
									</div>
								</div>
								<br />
								<p className='text-center mx-auto w-50 bg-warning'>Atenção! Pesos em <b>Quilos</b></p>
								<div className='d-flex'>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Tara</label>
									</div>

									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Peso liquido</label>
									</div>
								</div>
								<br />
								<p className='text-center mx-auto w-50 bg-warning'>Atenção! Medidas em <b>Centimetros</b></p>
								<div className='d-flex'>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Comprimento</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Largura</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Altura</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Lastro</label>
									</div>
									<div className='form-floating me-2'>
										<input type="number" placeholder='' className='form-control bordasInferiorInput text-black' />
										<label htmlFor="">Camadas</label>
									</div>
								</div>
								<div className='d-flex'>
									<div className='form-floating w-50 me-2'>
										<select className='form-select bordasInferiorInput text-black text-capitalize' name="" id="">
											<option value=""></option>
										</select>
										<label htmlFor="">Grupo S.I.F</label>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-cancelar" data-dismiss="modal">Cancelar</button>
							<button type="button" className="btn btn-novo">Salvar</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovaConferenciaEntrada };
