import { Link } from 'react-router-dom';

const BarraPallet = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Barras Ativas</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-3">
								<div className="col-sm-3">
									<div className="form-floating">
										<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
										<label htmlFor="cliente">Cliente</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-floating">
										<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
										<label htmlFor="codigo">Código</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-floating">
										<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
										<label htmlFor="pallet">Pallet</label>
									</div>
								</div>


								<div className="col-sm-1">
									<button className="btn" style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<i className="mdi mdi-magnify"></i>
									</button>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
									<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<tr>
											<th>Pallet</th>
											<th>Processos</th>
											<th>Cliente</th>
											<th>WMS</th>
											<th>Código</th>
											<th>Quantidade</th>
											<th>Empenho</th>
											<th>Validade</th>
											<th>Peso bruto</th>
											<th>Critério</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Pallet</td>
											<td>2791</td>
											<td>FKL Services</td>
											<td>60</td>
											<td>00127-4</td>
											<td>7</td>
											<td>0</td>
											<td>30/12/2023</td>
											<td>42,70</td>
											<td>ENTRADA KPL - NF 33</td>
											<td className="table-action">
												<Link to={`/gerenciamento/estoque/barraPallet/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { BarraPallet };
