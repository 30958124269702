import { useNavigate } from 'react-router-dom';

const NovaConferenciaSaida = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Conferência Saída</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
								<li className="nav-item">
									<a href="#tabConfSaidGeral" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">Geral</span>
									</a>
								</li>
								<li className="nav-item">
									<a href="#tabConfSaidCheckList" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">Check-List</span>
									</a>
								</li>
								<li className="nav-item">
									<a href="#tabConfSaidAudit" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
										<i className="mdi mdi-settings-outline d-md-none d-block"></i>
										<span className="d-none d-md-block">Auditoria</span>
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane show active" id="tabConfSaidGeral">
									<div className="row">
										<div className="col-sm-3">
											<div className="mb-1">
												<label className="form-label">Processo</label>
												<input type="text" className="form-control" />
											</div>
											<div className="mb-1">
												<label className="form-label">Cavalo</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1">
												<label className="form-label">Depositante</label>
												<input type="text" className="form-control" />
											</div>
											<div className="mb-1">
												<label className="form-label">Carreta</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1">
												<label className="form-label">Critério</label>
												<input type="text" className="form-control" />
											</div>
											<div className="mb-1">
												<label className="form-label">Tipo Veículo</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1">
												<label className="form-label">Modal Operação</label>
												<input type="text" className="form-control" />
											</div>
											<div className="mb-1">
												<label className="form-label">Motorista</label>
												<input type="text" className="form-control" />
											</div>
										</div>
									</div>
									<div className="row mt-1">
										<div className="col-sm-2">
											<div className="row">
												<div className="col-sm-5">
													<label className="form-label align-middle">TEMP INICIO</label>
												</div>
												<div className="col-sm-7">
													<input type="text" className="form-control" />
												</div>
											</div>
										</div>
										<div className="col-sm-2">
											<div className="row">
												<div className="col-sm-5">
													<label className="form-label">TEMP MEIO</label>
												</div>
												<div className="col-sm-7">
													<input type="text" className="form-control" />
												</div>
											</div>
										</div>
										<div className="col-sm-2">
											<div className="row">
												<div className="col-sm-5">
													<label className="form-label">TEMP FIM</label>
												</div>
												<div className="col-sm-7">
													<input type="text" className="form-control" />
												</div>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="row">
												<div className="col-sm-5">
													<label className="form-label">LACRE</label>
												</div>
												<div className="col-sm-7">
													<select className="form-select">
														<option>SEM LACRE</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="row">
												<div className="col-sm-5">
													<label className="form-label">TIPO CARGA</label>
												</div>
												<div className="col-sm-7">
													<select className="form-select">
														<option>PALETIZADA</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<hr className="mt-1 mb-1" />
									<div className="row mt-1">
										<div className="col-sm-3 mb-2">
											<button
												type="button"
												className="btn btn-warning dropdown-toggle rounded"
												data-bs-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												Ações
											</button>
											<div className="dropdown-menu">
												<a className="dropdown-item">Iniciar</a>
												<a className="dropdown-item">Concluir</a>
												<a className="dropdown-item">Baixar OS</a>
											</div>
										</div>
										<div className="row">
											<div className="table-responsive">
												<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
													<thead className="table-light">
														<tr>
															<th>#</th>
															<th>Cliente</th>
															<th>CNPJ</th>
															<th>OS</th>
															<th>Pedido</th>
															<th>Critério</th>
															<th>Qtde Embal</th>
															<th>Peso Bruto</th>
															<th>Peso Liquido</th>
															<th>Qtde Fiscal</th>
															<th>Dt Programação</th>
															<th>Dt Prevista</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input type="checkbox" />
															</td>
															<td>FKL Service</td>
															<td>001123432000181</td>
															<td>1234</td>
															<td>Pedido 1234/HRR</td>
															<td>ROMANEIO OPTICO</td>
															<td>2</td>
															<td>21,8</td>
															<td>20,0</td>
															<td>20,0</td>
															<td>13/12/2021 09:52</td>
															<td>14/12/2021 09:52</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane show" id="tabConfSaidCheckList">
									<div className="row">
										<div className="col-sm-3">
											<label className="form-label">Processo</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Cliente</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Tipo Operação</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Modal Operação</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<hr className="mb-3 mt-2" />
									<div className="row">
										<div className="col-sm-4">
											<div className="mb-1 text-center bg-info text-white">
												<label className="form-label" style={{ paddingTop: '5px' }}>
													<strong>CONDIÇÕES E HIGIÊNE DO VEÍCULO</strong>
												</label>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">HIGIENE E LIMPEZA</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">LIVRE DE ODORES</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>N/C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">TEMPERATURA ADEQUADA</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">VEDAÇÃO EFICIENTE</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>N/A</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">INTEGRIDADE DAS LÂMPADAS</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">ASSEIO DO MOTORISTA</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">MOTORISTA UNIFORMIZADO</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>C</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">CONTROLE DE PRAGAS VIGENTE</label>
												</div>
												<div className="col-sm-4">
													<select className="form-select">
														<option>N/A</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 text-center bg-info text-white">
												<label className="form-label" style={{ paddingTop: '5px' }}>
													<strong>CONTROLE AVARIAS</strong>
												</label>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">QTD AVARIA INTERNA</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">QTD AVARIA EXTERNA</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">QTD AVARIA AMASSADO</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">QTD AVARIA MOLHADO</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="mb-1 mt-2 text-center bg-info text-white">
												<label className="form-label" style={{ paddingTop: '5px' }}>
													<strong>CONTROLE AVARIAS</strong>
												</label>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">REPALETIZAÇÃO COM FILME</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">APLICAÇÃO/REFORÇO FILME</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">COLAGEM DE ETIQUETAS (CX)</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<label className="form-label">RE-EMBALAGEM(CX)</label>
												</div>
												<div className="col-sm-4">
													<input type="number" className="form-control" />
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 text-center bg-info text-white">
												<label className="form-label" style={{ paddingTop: '5px' }}>
													<strong>OBSERVAÇÕES GERAIS CHECK-LIST</strong>
												</label>
											</div>
											<div>
												<textarea className="form-control" rows={14}></textarea>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane show" id="tabConfSaidAudit">
									<div className="row"></div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-danger mb-2 me-1 rounded"
										type="button"
										onClick={() => navigate('/operacao/confSaida')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-info mb-2 me-1 rounded"
										type="button"
										onClick={() => navigate('/operacao/confSaida')}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NovaConferenciaSaida };
