import { useNavigate } from 'react-router-dom';

const OSProgramada = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">OS Programadas</h4>
					</div>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<ul className="nav nav-pills bg-nav-pills nav-justified mb-2">
						<li className="nav-item">
							<a
								href="#tab-porcEnt-digNotFisc-OSProg-Armaz"
								data-bs-toggle="tab"
								aria-expanded="true"
								className="nav-link rounded-0 active"
							>
								<i className="mdi mdi-home-variant d-md-none d-block"></i>
								<span className="d-none d-md-block">Armazenagem</span>
							</a>
						</li>
						<li className="nav-item">
							<a
								href="#tab-porcEnt-digNotFisc-OSProg-CrossDock"
								data-bs-toggle="tab"
								aria-expanded="true"
								className="nav-link rounded-0"
							>
								<i className="mdi mdi-home-variant d-md-none d-block"></i>
								<span className="d-none d-md-block">Crossdocking</span>
							</a>
						</li>
					</ul>
					<div className="tab-content">
						<div className="tab-pane show active" id="tab-porcEnt-digNotFisc-OSProg-Armaz">
							<div className="row mb-2">
								<div className="col-sm-12">
									<a
										className="btn btn-warning dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Funções
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item">Desprogramar</a>
										<a className="dropdown-item">Reprogramar</a>
										<a className="dropdown-item">Importar Arq NF</a>
										<a className="dropdown-item">Separação</a>
										<a className="dropdown-item">Pallets da OS</a>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap">
									<thead className="table-light">
										<tr>
											<th></th>
											<th>OS</th>
											<th>Cliente</th>
											<th>Pedido</th>
											<th>Data carga Prevista</th>
											<th>Total Caixas</th>
											<th>Peso Bruto</th>
											<th>Peso Liq</th>
											<th>Programação</th>
											<th>Critério</th>
											<th>CNPJ Cliente</th>
											<th>Pedido Cliente</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>61</td>
											<td>KPL</td>
											<td>123456</td>
											<td>29/11/2021 16:00:00</td>
											<td>12,00</td>
											<td>1200,00</td>
											<td>1200,00</td>
											<td>29/11/2021 16:00:00</td>
											<td>1234</td>
											<td>11321432000122</td>
											<td>344</td>
											<td className="d-flex flex-nowrap table-action">
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</a>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="tab-pane show" id="tab-porcEnt-digNotFisc-OSProg-CrossDock">
							<div className="row mb-2">
								<div className="col-sm-12">
									<a
										className="btn btn-warning dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Funções
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item">Desprogramar</a>
										<a className="dropdown-item">Reprogramar</a>
										<a className="dropdown-item">Importar Arq NF</a>
										<a className="dropdown-item">Separação</a>
										<a className="dropdown-item">Pallets da OS</a>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap">
									<thead className="table-light">
										<tr>
											<th></th>
											<th>OS</th>
											<th>Cliente</th>
											<th>Pedido</th>
											<th>Data carga Prevista</th>
											<th>Total Caixas</th>
											<th>Peso Bruto</th>
											<th>Peso Liq</th>
											<th>Programação</th>
											<th>Critério</th>
											<th>CNPJ Cliente</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>61</td>
											<td>KPL</td>
											<td>123456</td>
											<td>29/11/2021 16:00:00</td>
											<td>12,00</td>
											<td>1200,00</td>
											<td>1200,00</td>
											<td>29/11/2021 16:00:00</td>
											<td>1234</td>
											<td>11321432000122</td>
											<td className="d-flex flex-nowrap table-action">
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</a>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="card-footer">
					<div className="col">
						<div className="text-sm-end mb-2">
							<button
								className="btn btn-danger me-1"
								type="button"
								onClick={() => navigate('/pco/contrFisc/gestPedidos/listagemDosPedidos')}
							>
								Cancelar
							</button>
							<button
								className="btn btn-info me-1"
								type="button"
								onClick={() => navigate('/pco/contrFisc/gestPedidos/listagemDosPedidos')}
							>
								Salvar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { OSProgramada };
