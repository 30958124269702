import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NovoColaborador = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const idusuario = sessionStorage.getItem('IsIDFKL');
	const cod_filial = sessionStorage.getItem('IsCodFilialPadraoFKL');

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  

	const [options, setOptions] = useState([])

	const [inputs, setInputs] = useState({
		matricula: '',
		nome: '',
		funcao: '',
		turno: '',
		custo_hh: '',
		situacao: '',
		confere: '',
		transporte: '',
		picking: '',
		desfragmentacao: '',
		opera_empilhadaira: '',
		reposicao_primaria: '',
		bloqueio: '',
		motivo_Bloq: '',
		data_adicao: '',
		data_alteracao: '',
		codfilial: ''
	});

	const [checkConfere, setCheckConfere] = useState(false);
	const [checkTransporta, setCheckTransporta] = useState(false);
	const [checkPicking, setCheckPicking] = useState(false);
	const [checkEmpilha, setCheckEmpilha] = useState(false);
	const [checkDesfrag, setCheckDesfrag] = useState(false);
	const [checkRessupri, setCheckRessupri] = useState(false);
	const [checkBloqueio, setCheckBloqueio] = useState(false);
	//const [check, setCheck] = useState(false);
	const [selected, setSelected] = useState(0);

	const handleCheckedConfere = (e: any) => {
		setCheckConfere(e.target.checked);
	}
	const handleCheckedPicking = (e: any) => {
		setCheckPicking(e.target.checked);
	}
	const handleCheckedEmpilha = (e: any) => {
		setCheckEmpilha(e.target.checked);
	}
	const handleCheckedDesfrag = (e: any) => {
		setCheckDesfrag(e.target.checked);
	}
	const handleCheckedRessupri = (e: any) => {
		setCheckRessupri(e.target.checked);
	}
	const handleCheckedBloqueio = (e: any) => {
		setCheckBloqueio(e.target.checked);
	}
	const handleCheckedTransporta = (e: any) => {
		setCheckTransporta(e.target.checked);
	}

	const handleChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	};
	const [status, setStatus] = useState('1');
	const selectedStatus = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setStatus(value)
	}

	//#region Carregar/Selecionar Turno
	const [turno, setTurno] = useState([])
	const [selecionaTurno, setSelecionaTurno] = useState(0);
	const capturaTurno = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaTurno(value);
	};

	//#region Requisição de dados na api - CARREGAR FAMILIA
	const carregarDadosTurno = async () => {
		const response = await axios.get(Api + `/equipe/turno/json/?tabela=COLABTURNO&auth=${token}`);
		setTurno(response.data);
	};
	const BuscarTurno = () => {
		carregarDadosTurno();
	}
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Função
	const [funcao, setFuncao] = useState([])
	const [selecionaFuncao, setSelecionaFuncao] = useState(0);
	const capturaFuncao = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaFuncao(value);
	};

	//#region Requisição de dados na api - CARREGAR FAMILIA
	const carregarDadosFuncao = async () => {
		const response = await axios.get(Api + `/equipe/funcao/json/?tabela=COLABFUNC&auth=${token}`);
		setFuncao(response.data);
	};
	const BuscarFuncao = () => {
		carregarDadosFuncao();
	}
	//#endregion
	//#endregion

	//#region const para CADASTRAR uma equipe/colaborador na API - POST
	const postDados = (e: any) => {
		try {
			setShowCarregando(true)
			axios.post(Api + `/equipe/?&auth=${token}`, {
				matricula: inputs.matricula.toUpperCase(),
				nome: inputs.nome.toUpperCase(),
				custo_hh: inputs.custo_hh || 0,
				situacao: status,
				turno: selecionaTurno,
				funcao: selecionaFuncao,
				confere: inputs.confere,
				transporte: inputs.transporte,
				picking: inputs.picking,
				desfragmentacao: inputs.desfragmentacao,
				opera_empilhadaira: inputs.opera_empilhadaira,
				reposicao_primaria: inputs.reposicao_primaria,
				bloqueio: inputs.bloqueio,
				motivo_Bloq: inputs.motivo_Bloq,
				data_adicao: inputs.data_adicao,
				codfilial: cod_filial

			}).then((response) => {
				return response.data
			}).then(data => {
				if (data.status == 201) {
					setTimeout(() => navigate(-1), 500);
					setShowCarregando(false)
				}
				else {
					setShowCarregando(false)
					toast.error(data.message, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "dark",
					})
					//alert(data.message)
				}
			}).catch(err => {
				setShowCarregando(false)
				toast.error(err, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "dark",
				})
			})
		} catch (error) {
			setShowCarregando(false);
			toast.error(`${error}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
		}


	}

	//#endregion

	const _id = useParams();



	//#region Carregar DADOS - USEEFFECT



	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (checkConfere === true) {
			inputs.confere = '1';
		}
		else if (checkConfere === false) {
			inputs.confere = '0';
		}
		if (checkTransporta === true) {
			inputs.transporte = '1';
		}
		else if (checkTransporta === false) {
			inputs.transporte = '0';
		}
		if (checkPicking === true) {
			inputs.picking = '1';
		}
		else if (checkPicking === false) {
			inputs.picking = '0';
		}
		if (checkDesfrag === true) {
			inputs.desfragmentacao = '1';
		}
		else if (checkDesfrag === false) {
			inputs.desfragmentacao = '0';
		}
		if (checkEmpilha === true) {
			inputs.opera_empilhadaira = '1';
		}
		else if (checkEmpilha === false) {
			inputs.opera_empilhadaira = '0';
		}
		if (checkRessupri === true) {
			inputs.reposicao_primaria = '1';
		}
		else if (checkRessupri === false) {
			inputs.reposicao_primaria = '0';
		}
		if (checkBloqueio === true) {
			inputs.bloqueio = '1';
		}
		else if (checkBloqueio === false) {
			inputs.bloqueio = '0';
		}

		if (_id.id === 'novo') {
			//alert('Você vai incluir!');
			postDados(inputs);
		}


	}
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Novo Colaborador</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<form onSubmit={handleSubmit}>
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralColaborador" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralColaborador">
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="matricula"
															name="matricula"
															value={inputs.matricula || ""}
															onChange={handleChange}
															required />
														<label className="form-label" htmlFor="matricula">
															Matrícula
														</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="nome"
															name="nome"
															value={inputs.nome || ""}
															onChange={handleChange}
															required />
														<label className="form-label" htmlFor="nome">
															Nome
														</label>
													</div>
												</div>
											</div>
											<div className="row">

												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" id="funcao" title='Tabela: COLABFUNC'
															value={selecionaFuncao} onChange={capturaFuncao} onClick={BuscarFuncao}>
															{
																funcao.map(function (dados: any) {
																	return (
																		<option value={dados.idfuncao}>{dados.idfuncao} - {dados.funcao}</option>
																	)
																})
															}
														</select>
														<label className="form-label" htmlFor="funcao" >
															Função
														</label>
													</div>
												</div>

												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" id="turno" title='Tabela: COLABTURNO'
															value={selecionaTurno} onChange={capturaTurno} onClick={BuscarTurno}>
															{
																turno.map(function (dados: any) {
																	return (
																		<option value={dados.idturno}>{dados.idturno} - {dados.turno}</option>
																	)
																})
															}

														</select>
														<label className="form-label" htmlFor="turno">
															Turno
														</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="custo_hh"
															name="custo_hh"
															value={inputs.custo_hh || ""}
															onChange={handleChange} />
														<label className="form-label" htmlFor="custoHr">
															Custo Hr.
														</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" id="ativo"
															value={status} onChange={selectedStatus}>
															<option value="0">Inativo</option>
															<option value="1" selected>Ativo</option>
														</select>
														<label className="form-label" htmlFor="ie">
															Status
														</label>
													</div>
												</div>
											</div>
											<div className="row" style={{ textAlign: 'center' }}>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="confere">
															Confere
														</label>
														<input id="c-tptw-1" type="checkbox" checked={checkConfere}
															onChange={handleCheckedConfere} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Transporta
														</label>
														<input id="c-tptw-12" type="checkbox" checked={checkTransporta}
															onChange={handleCheckedTransporta} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Picking
														</label>
														<input id="c-tpt-14" type="checkbox" checked={checkPicking}
															onChange={handleCheckedPicking} />
													</div>
												</div>
												<div className="col-sm-2 form-floating">
													<div className="mb-1">
														<label className="form-label" htmlFor="valdiaed">
															Empilhadeira
														</label>
														<input id="c-tpt-16" type="checkbox" checked={checkEmpilha}
															onChange={handleCheckedEmpilha} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Desfragmenta
														</label>
														<input id="c-tpt-17" type="checkbox" checked={checkDesfrag}
															onChange={handleCheckedDesfrag} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Ressuprimento
														</label>
														<input id="c-tpt-18" type="checkbox" checked={checkRessupri}
															onChange={handleCheckedRessupri} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button
												className="btn btn-cancelar mb-2 me-1"
												onClick={() => navigate('/config/cadast/colaborador')}
												type="button"
											>
												Cancelar
											</button>
											<button
												className="btn btn-novo mb-2 me-1" type="submit">Salvar</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<ToastContainer newestOnTop />
			</div>
			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>
		</>
	);
};

export { NovoColaborador };
