import React from 'react';

interface InputsProps {
	value: string;
	onchange?: (value: string) => void;
	placeholder?: string;
	maxLength?: number;
	classNameInput?: string;
	label?: string;
	classNameLabel?: string;
	name?: string;
	type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url' | 'search';
	step?: string;
	min?: string;
	max?: string;
	disabled?: boolean;
	required?: boolean;
	autoFocus?: boolean;
	readOnly?: boolean;
	autoComplete?: string;
	usaMascara?: boolean;
}
const Inputs: React.FC<InputsProps> = ({
	onchange,
	value,
	autoComplete,
	autoFocus,
	classNameInput,
	classNameLabel,
	disabled,
	label,
	maxLength,
	max,
	min,
	name,
	placeholder,
	readOnly,
	required,
	step,
	type,
	usaMascara,
}) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawValue = event.target.value;
		const numberMask = (value: any) => {
			if (!value) return '';

			// Remove todos os não-dígitos
			value = value.replace(/\D/g, '');

			// Adiciona os pontos de formatação para o número no formato 0.000
			value = value.replace(/(\d{1})(\d{3})$/, '$1.$2');

			return value;
		};

		if (usaMascara) {
			const formattedValue = numberMask(rawValue);
			if (onchange) {
				onchange(formattedValue);
			}
		} else {
			if (onchange) {
				onchange(rawValue);
			}
		}
	};

	return (
		<>

			<input
				type={type}
				value={value}
				onChange={handleInputChange}
				placeholder={placeholder}
				maxLength={maxLength}
				className={classNameInput}
				name={name}
				step={step}
				min={min}
				max={max}
				disabled={disabled}
				required={required}
				autoFocus={autoFocus}
				readOnly={readOnly}
				autoComplete={autoComplete}
			/>
			{label ? (
				<label className={classNameLabel} htmlFor={name}>
					{label}
				</label>
			) : null}
		</>
	);
};

export default Inputs;
