import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';

import CheckBox from '../../../../components/componentes/formulario/Checkbox';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { DadosEntradaSaida } from './components/dadosEntradaSaida';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
const NovoCFOP = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_cfop = params.id;
	const acao = params.acao || '';

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	const [msgShowCarregando, setMsgShowCarregando] = useState('');
	//#endregion
	//#region Modifica o status do Modal Pesquisa

	//DADOS DO CFOP
	const [cfop, setCFOP] = useState<string>('');
	const [natureza_op, setNaturezaOp] = useState<string>('');
	const [ativo, setAtivo] = useState<boolean>(true);
	const [tipo, setTipo] = useState<string>('');

	//#region ==> Função para buscar os dados do CFOP
	async function carregarDados() {
		setShowCarregando(true);
		setMsgShowCarregando('Carregando dados do CFOP');
		await axios
			.get(`${Api}/carrega/dados/cfop/edit/${id_cfop}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				setCFOP(response.data.cfop[0].cfop);
				setNaturezaOp(response.data.cfop[0].natureza_op);
				setAtivo(response.data.cfop[0].ativo);
				setTipo(response.data.cfop[0].tipo_cfop);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//Carregar dados da tabela ao abrir a tela
	useEffect(() => {
		if (acao !== 'criar') {
			carregarDados();
		}
	}, []);

	const dadosEnvio = {
		cfop,
		natureza_op,
		ativo: ativo ? 1 : 0,
		tipo,
		usuario: usuarioLogado,
	};

	//#region  ==> Função para inserir novo cfop
	async function insereNovoCFOP() {
		setShowCarregando(true);
		setMsgShowCarregando('Inserindo novo CFOP');
		await axios
			.post(`${Api}/insere/cfop`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para editar cfop
	async function editarCFOP() {
		setShowCarregando(true);
		setMsgShowCarregando('Editando CFOP');
		await axios
			.put(`${Api}/editar/cfop/${id_filial}/${id_cfop}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">{acao === 'criar' ? 'Novo CFOP' : acao === 'editar' ? 'Editar CFOP' : 'Visualizar CFOP'}</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoCFOP();
								} else if (acao === 'editar') {
									editarCFOP();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_cfop"
															name="id_cfop"
															value={id_cfop}
															disabled
														/>
														<label className="form-label">ID CFOP</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="cfop"
															name="cfop"
															maxLength={4}
															value={cfop}
															onChange={(e) => setCFOP(e.target.value)}
															disabled={acao === 'criar' ? false : true}
														/>
														<label className="form-label">CFOP</label>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="natureza_op"
															name="natureza_op"
															value={natureza_op}
															maxLength={150}
															onChange={(e) => setNaturezaOp(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Natureza de Operação</label>
													</div>
												</div>
												<div className="col-sm-2">
													<InputSelect
														label="Tipo E/S"
														value={tipo}
														onChange={setTipo}
														onClick={() => {}}
														dados={DadosEntradaSaida}
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														required
													/>
												</div>

												<div className="col-sm-2 pt-3">
													<div className="form-floating mb-1">
														<CheckBox label="Ativo" name="ativo" checked={ativo} onChange={setAtivo} disabled={acao} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando show={showCarregando} mensagem={msgShowCarregando} />
		</>
	);
};

export { NovoCFOP };
