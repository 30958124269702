import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { PdfDocumentProdutoPickingParams } from './interface';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface PdfDocumentArrayParams {
	documentos: PdfDocumentProdutoPickingParams[];
}

export function PdfDocumentProdutoPicking({ documentos }: PdfDocumentArrayParams) {
	const GerarEtiquetaProdutoPicking = () => {
		// Calculando o tamanho da página
		const pageWidth = documentos[0].tamanho.largura * 28.35; // Conversão de cm para pontos (1 cm = 28.35 pontos)
		const pageHeight = documentos[0].tamanho.altura * 28.35; // Conversão de cm para pontos (1 cm = 28.35 pontos)

		// Fator de margem para ajustar de acordo com o tamanho da página
		const marginFactor = pageHeight * 0.05; // 5% da altura da página como margem

		const pages = documentos
			.map((docParams) => {
				const { logo, codigoBarras, depositante, local, produto, descricao } = docParams;

				const pageContent: any[] = [
					{
						columns: [
							{
								image: logo,
								width: pageWidth * 0.2, // 20% da largura da página
								height: pageHeight * 0.1, // 10% da altura da página
								margin: [0, 0, 0, -pageHeight * 0.1],
							},							
						],
						margin: [0, 0, 0, pageHeight * 0.05], // Ajuste proporcional da margem
					},
					{
						columns: [
							{
								text: depositante,
								fontSize: pageWidth * 0.035, // Ajusta a fonte proporcionalmente
								bold: true,
								alignment: 'center',
								width: pageWidth * 0.9, // 90% da largura da página
								margin: [0, -pageHeight * 0.01, 0, 0],
							},
						],
					},
					{
						columns: [
							{
								text: local,
								fontSize: pageWidth * 0.055, // Ajusta a fonte proporcionalmente
								bold: true,
								alignment: 'center',
								width: pageWidth * 0.9, // 90% da largura da página
								margin: [0, 0, 0, 0],
							},
						],
					},
					{
						image: codigoBarras,
						alignment: 'center',
						width: pageWidth * 0.8, // 90% da largura da página
						height: pageHeight * 0.25, // 25% da altura da página
						margin: [0, pageHeight * 0.01, 0, 0], // Ajuste proporcional da margem
					},
					{
						columns: [
							{
								text: produto,
								fontSize: pageWidth * 0.065, // Ajusta a fonte proporcionalmente
								bold: true,
								alignment: 'center',
								width: pageWidth * 0.9, // 90% da largura da página
								margin: [0, 0, 0, 0],
							},
						],
					},
					{
						columns: [
							{
								text: descricao,
								fontSize: pageWidth * 0.045, // Ajusta a fonte proporcionalmente
								bold: true,
								alignment: 'center',
								width: pageWidth * 0.9, // 90% da largura da página
								margin: [0, 0, 0, -pageHeight * 0.05],
							},
						],
					},

					{
						columns: [
							{
								text: 'Emitido por SisWebWms em: ' + new Date().toLocaleString(),
								fontSize: pageWidth * 0.025, // Ajusta a fonte proporcionalmente
								alignment: 'center',
								width: pageWidth * 0.9, // 90% da largura da página
								margin: [
									0,
									pageHeight * 0.05,
									0,
									documentos[0].tamanho.altura === documentos[0].tamanho.largura ? pageHeight * 0.15 : 0,
								], // Ajuste proporcional da margem
							},
						],
						margin: [0, 0, 0, pageHeight * 0.1],
					},
				];

				return pageContent;
			})
			.flat();

		const docDefinition: any = {
			pageSize: { width: pageWidth, height: pageHeight },
			pageMargins: [marginFactor, marginFactor, marginFactor, marginFactor], // Margens dinâmicas
			pageOrientation: documentos[0].orientacao === 'retrato' ? 'portrait' : 'landscape', // Orientação da página dinâmica
			content: pages,
		};

		pdfMake.createPdf(docDefinition).open(); // Abre o PDF em uma nova aba
	};

	return GerarEtiquetaProdutoPicking;
}
