import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle, mdiThermometerAuto } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { colunasGridTelaProdutosPicking } from './components/colunasGridTelaProdutoPicking';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const EnderecoArmazem = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);
	const [permissaoEditarTemperatura, setPermissaoEditarTemperatura] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarEnderecosArmz) {
				toast.warning('Você não tem permissão para acessar Lista de Endereços de Armazém');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarEnderecosArmz);
				setPermissaoCriar(permissoes.perfilPermissao.criarEnderecosArmz);
				setPermissaoEditar(permissoes.perfilPermissao.editarEnderecosArmz);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirEnderecosArmz);
				setPermissaoEditarTemperatura(permissoes.perfilPermissao.editarTempEnderecosArmz);
			}
		}
	}, [permissoes, erro, navigate]);

	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [id_local, setIdLocal] = useState<string>('');
	const [produto, setProduto] = useState<string>('');
	const [id_cliente, setIdCliente] = useState<string>('');
	const [id_wms, setIDWMS] = useState<string>('');
	const [tipoAcao, setTipoAcao] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [textoModalCarregando, setTextoModalCarregando] = useState<string>('');
	const [itensPorPagina, setItensPorPagina] = useState(10);

	// Alterar temperarura do endereço
	const [abrirModalAltrarTemperatura, setAbrirModalAltrarTemperatura] = useState<boolean>(false);
	const [temperatura, setTemperatura] = useState<string>('');
	const [armazem, setArmazem] = useState<string>('');
	const [qtdeUa, setQtdeUA] = useState<number>(0);

	// Alerta de produtos de picking no armazém
	const [alertaProdutoPicking, setAlertaProdutoPicking] = useState<boolean>(false);
	const [dadosProdPicking, setDadosProdPicking] = useState([]);

	const editarEndereco = (dados: any) => {
		navigate(`/cadast/endereco/enderecoarm/${dados.id_local}/editar`);
	};
	const excluirEndereco = (dados: any) => {
		setTipoAcao('excluir');
		verificarProdutoPickingLocal(dados.id_local, dados.tipo, dados.amz, dados.cor, dados.box, dados.alt, dados.prof);
	};
	const visualizarEndereco = (dados: any) => {
		navigate(`/cadast/endereco/enderecoarm/${dados.id_local}/visualizar`);
	};

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setTextoModalCarregando('Carregando dados...');
		axios
			.get(Api + `/lista/endereco/filial/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setData(resposta.data.enderecos);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region -> Excluir
	function deleteEndereco() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setTextoModalCarregando('Excluindo endereço...');
		axios
			.delete(Api + `/excluir/endereco/${id_filial}/${id_local}/${usuario}/${produto ? id_cliente : 'x'}/${produto ? id_wms : 'x'}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setTipoAcao('');
				toast.success(resposta.data.message);
				carregarDados();
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setTipoAcao('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fechar o modal alterar temperatura
	const fecharModalAlterarTemperatura = () => {
		setAbrirModalAltrarTemperatura(false);
	};
	// fechar o modal alterar temperatura
	const fecharModalListarProdPiking = () => {
		setAlertaProdutoPicking(false);
	};

	// abrir o modal alterar temperatura
	const abrirModalAlterarTemperatura = () => {
		ListarTemperatura();
		ListarArmazem();
		setArmazem('');
		setTemperatura('');
		setAbrirModalAltrarTemperatura(true);
	};

	//#region -> Função para buscar o cadastro de temperatura  para alimentar o select/option
	const [dadosTemperatura, setDadosTemperatura] = useState([]);
	async function ListarTemperatura() {
		axios
			.get(Api + `/lista/enderecoarm/temperatura/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTemperatura(resposta.data.temperatura);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar os Armazens do cadastro de endereço  para alimentar o select/option
	const [dadosArmazem, setDadosArmazem] = useState([]);
	async function ListarArmazem() {
		axios
			.get(Api + `/lista/enderecoarm/armazem/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosArmazem(resposta.data.armazem);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const verificarUA = () => {
		axios
			.get(Api + `/lista/endereco/verificarua/${armazem}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setQtdeUA(resposta.data.qtdeua);
				setMsgAcao1('Cancelar');
				setMostraModalConfirm(true);
				setTipoAcao('alterar');
				setMsgPrincipalModal(
					qtdeUa > 0
						? `Foram encontradas ${qtdeUa} U.A. endereçados no Armazem: ${armazem}. Deseja realizar a troca da temperatura mesmo assim?`
						: `Deseja alterar a temperatura do armazém ${armazem}?`
				);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				setTipoAcao('');
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	};

	// Verificar se existe produto de picking no armazém
	const verificarProdutoPicking = () => {
		axios
			.get(Api + `/lista/endereco/verificarprodpicking/${id_filial}/${armazem}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const qtdeProdPicking = resposta.data.prodpicking.length || 0;

				// se tiver produto de picking no armazém, ajustar as mensagens e abrir o modal de confirmação
				if (qtdeProdPicking > 0) {
					setAlertaProdutoPicking(true);
					setDadosProdPicking(resposta.data.prodpicking);
				} else {
					verificarUA();
				}
			})
			.catch(function (erro) {
				const status = erro.response.status;
				setTipoAcao('');
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	};

	// Verificar se existe produto de picking no local
	const verificarProdutoPickingLocal = (id_local: string, tipo: string, amz: string, cor: string, box: string, alt: string, prof: string) => {
		axios
			.get(Api + `/lista/endereco/verificarprodpicking/local/${id_filial}/${id_local}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const qtdeProdPicking = resposta.data.prodpicking.length || 0;
				setIdLocal(id_local);
				const _endereco = tipo + ' ' + amz + '-' + cor + '-' + box + '-' + alt + '-' + prof;

				// se tiver produto de picking no local, ajustar as mensagens e abrir o modal de confirmação
				if (qtdeProdPicking > 0) {
					const item = resposta.data.prodpicking[0].codigo + ' ' + resposta.data.prodpicking[0].descricao || '';
					setProduto(item);
					setIDWMS(resposta.data.prodpicking[0].id_wms);
					setIdCliente(resposta.data.prodpicking[0].id_cliente);

					setMsgPrincipalModal(`O endereço: ${_endereco}.
						Possui o produto de picking vinculado: ${item}.
						Deseja excluir o endereço?`);
					setMsgAcao1('Cancelar');

					setMostraModalConfirm(true);
				} else {
					setIDWMS('');
					setIdCliente('');
					setProduto('');
					setMsgPrincipalModal(`Deseja excluir o endereço: ${_endereco}?`);
					setMsgAcao1('Cancelar');
					setMostraModalConfirm(true);
					//deleteEndereco();
				}
			})
			.catch(function (erro) {
				const status = erro.response.status;
				setTipoAcao('');
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	};

	//dados para atualizacao
	const dadosEnvio = {
		armazem,
		temperatura,
		usuario,
	};
	//#region -> Excluir
	function alterarEndereco() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setTextoModalCarregando('Atualizando Temperatura do Armazém...');
		axios
			.put(Api + `/alterar/endereco/temperatura/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setTipoAcao('');
				setTemperatura('');
				setArmazem('');
				setAbrirModalAltrarTemperatura(false);
				toast.success(resposta.data.message);
				carregarDados();
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setTipoAcao('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Endereços de Armazém</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								{(permissaoCriar || permissaoEditarTemperatura) && (
									<div className="row">
										<div className="col d-flex justify-content-start">
											{permissaoCriar && (
												<Link className="btn btn-secondary  m-1" to={`/cadast/endereco/enderecoarm/novo/criar`}>
													<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
													Novo Endereço
												</Link>
											)}
											{permissaoEditarTemperatura && (
												<button className="btn btn-info m-1" type="button" onClick={abrirModalAlterarTemperatura}>
													<Icon path={mdiThermometerAuto} size={1} className="me-2 rounded" />
													Alterar Temperatura
												</button>
											)}
										</div>
									</div>
								)}
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaRowClick={false}
										usaAcoes={true}
										acaoEditar={editarEndereco}
										acaoExcluir={excluirEndereco}
										acaoVisualizar={visualizarEndereco}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="amz"
										labelpesquisa="Procurar por armazém do endereço"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={tipoAcao === 'excluir' ? deleteEndereco : alterarEndereco}
			/>

			<ModalCarregando mostrar={mostraModalCarregar} mensagem={textoModalCarregando} />

			{/* MODAL ALTERAR A TEMPERATURA DO LOCAL*/}

			<Modal
				show={abrirModalAltrarTemperatura}
				backdrop="static"
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title>Alterar Temperatura</Modal.Title>
				</Modal.Header>
				<form
					onSubmit={function (e) {
						e.preventDefault();
						verificarProdutoPicking();
					}}
				>
					<Modal.Body>
						<div className="row">
							<div className="col-sm-6">
								<InputSelect
									label="Armazém"
									value={armazem}
									onChange={setArmazem}
									onClick={ListarArmazem}
									dados={dadosArmazem}
									disabled={false}
									required
								/>
							</div>
							<div className="col-sm-6">
								<InputSelect
									label="Tempertura"
									value={temperatura}
									onChange={setTemperatura}
									onClick={ListarTemperatura}
									dados={dadosTemperatura}
									disabled={false}
									required
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={fecharModalAlterarTemperatura}
								/>
								<BotaoCustomizado
									type="submit"
									className="btn btn-light text-info-emphasis bg-white border-0"
									descricaoBotao="Alterar Temperatura"
									icone={mdiCheckCircle}
								/>
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>

			{/* MODAL LISTAR PRODUTO DE PICKING NO ARMAZEM */}
			<Modal show={alertaProdutoPicking} backdrop="static" keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header>
					<Modal.Title>
						{dadosProdPicking.length > 1
							? `Foram encontrados ${dadosProdPicking.length} produtos de picking. `
							: `Foi encontrado ${dadosProdPicking.length} produto de picking. `}{' '}
						Vinculado ao armazém selecionado. <br />
						Para alterar a temperatura do armazém desvincule os produtos e tente novamente.
					</Modal.Title>
				</Modal.Header>
				<form
					onSubmit={function (e) {
						e.preventDefault();
						verificarProdutoPicking();
					}}
				>
					<Modal.Body>
						<ClickableTable
							coluna={colunasGridTelaProdutosPicking}
							itemsPerPage={itensPorPagina}
							data={dadosProdPicking}
							onRowClick={() => {}}
							usaRowClick={false}
							usaAcoes={false}
							acaoEditar={editarEndereco}
							acaoExcluir={excluirEndereco}
							acaoVisualizar={visualizarEndereco}
							usaVisualizar={true}
							usaEditar={true}
							usaExcluir={true}
							acessopesquisa="amz"
							labelpesquisa="Procurar por armazém do endereço"
						/>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={fecharModalListarProdPiking}
								/>
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>
		</>
	);
};

export { EnderecoArmazem };
