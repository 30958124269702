import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiIdentifier, mdiKeyboardReturn, mdiMagnify, mdiPlusCircle, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const Entidade = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN') || '';
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem("IsUserFKL")
	const [idEntidade, setIdEntidade] = useState<string>()
	const [data, setData] = useState([])
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false)
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
	const navigate = useNavigate()

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarEntidade) {
				toast.warning('Você não tem permissão para acessar Lista de Entidades');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarEntidade);
				setPermissaoCriar(permissoes.perfilPermissao.criarEntidade);
				setPermissaoEditar(permissoes.perfilPermissao.editarEntidade);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirEntidade);
			}
		}
	}, [permissoes, erro, navigate]);

	const editarEntidade = (dados: any) => {
		navigate(`/config/cadast/entidade/${dados.id_entidade}/editar`)
	};
	const excluirEntidade = (dados: any) => {
		setIdEntidade(dados.id_entidade)
		setMostraModalConfirm(true)
	};
	const visualizarEntidade = (dados: any) => {
		navigate(`/config/cadast/entidade/${dados.id_entidade}/visualizar`)
	};

	const colunas: ITabela[] = [
		{ titulo: "", acesso: `avatar` },
		{ titulo: "Id", acesso: "id_entidade" },
		{ titulo: "Nome Fantasia", acesso: "fantasia" },
		{ titulo: "Endereço", acesso: "logradouro" },
		{ titulo: "Cidade", acesso: "cidade" },
		{ titulo: "Usuário", acesso: "usuario" }
	]

	function carregaDadosEntidade() {
		setMostraModalCarregar(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/entidade/${id_filial}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregar(false)
			setData(resposta.data.data)
		}).catch(function (erro) {
			setMostraModalCarregar(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	function deleteEntidade() {
		setMostraModalCarregar(true)
		setMostraModalConfirm(false)
		axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/entidade/${idEntidade}/${id_filial}/${usuarioLogado}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregar(false)
			toast.success(resposta.data.message)
			carregaDadosEntidade()
		}).catch(function (erro) {
			setMostraModalCarregar(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	useEffect(() => {
		carregaDadosEntidade()
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem das Entidades</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								{permissaoCriar && <div className="row">
									<div className="col d-flex justify-content-start">
										<Link className='btn btn-secondary' to={`/config/cadast/entidade/novaEntidade/criar`}>
											<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
											Nova Entidade
										</Link>
									</div>
								</div>}
								<div>
									<ClickableTable
										coluna={colunas}
										itemsPerPage={10}
										data={data}
										onRowClick={function () { }}
										usaAcoes={true} 
										acaoEditar={editarEntidade}
										acaoExcluir={excluirEntidade}
										acaoVisualizar={visualizarEntidade}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa='fantasia'
										labelpesquisa='Procurar por fantasia'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1='Cancelar'
				msgAcao2='Confirmar'
				msgPrincipal='Deseja excluir a entidade'
				Acao1={function () { setMostraModalConfirm(false) }}
				Acao2={deleteEntidade}
			/>

			<ModalCarregando
				mostrar={mostraModalCarregar}
				mensagem='Processando exclusão'
			/>
		</>
	);
};

export { Entidade };
