import Titulo from "../../../../components/componentes/formulario/ContainerTitulo"
import Inputs from "../../../../components/componentes/formulario/Inputs"
import { useNavigate } from 'react-router-dom';
import { BsCardChecklist } from 'react-icons/bs';
import { Modal, Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ModalCarregando from "../../../../components/componentes/formulario/ModalCarregando";
import { toast } from 'react-toastify';
import axios from "axios";
import { Api } from "../../../../services/api";

function VerGrupo() {
    const Navigate = useNavigate()
    const params = useParams()
    const token = sessionStorage.getItem('IsFKLTOKEN');

    const [showCarregando, setShowCarregando] = useState(false)

    //grupo carregado
    const [grupo, setGrupo] = useState([])

    //pega a info do produto com base no id
    async function carregarGrupo() {

        setShowCarregando(true)
        const response = await axios.get(Api + `/pegar/grupoId/?auth=${token}&id=${params.id}`)

        if (response.data.message) {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            setShowCarregando(false)
        }
        else {
            setGrupo(response.data)
            setShowCarregando(false)
        }
    }

    useEffect(function () {
        carregarGrupo()
    }, [])

    return (
        <div className="container-fluid">
            <Titulo titulo="Cadastrar novo Grupo Tributário" />
            <div className="row">

                <div className="card">
                    <div className="card-body">

                        <form>

                            {grupo.map(function (info: any) {
                                return (
                                    <>
                                        <div className="container">
                                            <div className="col-1">
                                                <Inputs
                                                    label="ID"
                                                    maxLength={4}
                                                    name="ID grupo"
                                                    classNameInput="form-control"
                                                    classNameLabel="form-label"
                                                    required={true}
                                                    value={info.id || ""}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-5">
                                                <Inputs
                                                    label="Descrição"
                                                    maxLength={160}
                                                    name="Descrição grupo"
                                                    classNameInput="form-control"
                                                    classNameLabel="form-label"
                                                    required={true}
                                                    disabled
                                                    value={info.grupo || ""}
                                                />
                                            </div>
                                        </div>

                                        <br />
                                        <br />

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm">
                                                    <b>ICMS</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>IPI</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>PIS</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>COFINS</b>
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="Origem"
                                                            maxLength={15}
                                                            name="Origem"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.icms_origem || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST icms"
                                                            maxLength={15}
                                                            name="CST icms"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.icms_cst || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%Red.Base Cálculo"
                                                            maxLength={70}
                                                            name="%Red.Base Cálculo"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.icms_percredcal}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%ICMS"
                                                            maxLength={70}
                                                            name="%ICMS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.icms_percentual}

                                                        />
                                                    </div>
                                                </div>




                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="Cod.Enquadramento"
                                                            maxLength={15}
                                                            name="Cod.Enquadramento"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.ipi_enquadramento || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST IPI"
                                                            maxLength={15}
                                                            name="CST IPI"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.ipi_cst || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%IPI"
                                                            maxLength={70}
                                                            name="%IPI"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.ipi_percentual || ""}
                                                        />
                                                    </div>
                                                </div>



                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST PIS"
                                                            maxLength={70}
                                                            name="CST PIS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.pis_cst || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%PIS"
                                                            maxLength={70}
                                                            name="%PIS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.pis_percentual}
                                                        />
                                                    </div>
                                                </div>



                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST Cofins"
                                                            maxLength={70}
                                                            name="CST Cofins"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.cofins_cst || ""}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%COFINS"
                                                            maxLength={70}
                                                            name="%COFINS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            disabled
                                                            value={info.cofins_percentual || ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}


                            <br />

                            <div className="card-footer">
                                <div className="col">
                                    <div className="text-sm-end">
                                        <button className="btn btn-cancelar mb-2 me-1" onClick={function () {
                                            Navigate(-1)
                                        }} type="button">
                                            Voltar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <ModalCarregando show={showCarregando} />
        </div>

    )
}

export default VerGrupo