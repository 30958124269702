function mascaraCelular(celular_: string) {

    let celular = celular_.replace(/\D/g, '')

    // Aplica a máscara de celular (XX) XXXXX-XXXX
    if (celular.length <= 11) {
        celular = celular.replace(/(\d{2})(\d)/, '($1) $2');
        celular = celular.replace(/(\d{5})(\d)/, '$1-$2');
    } else {
        celular = celular.substring(0, 11);
        celular = celular.replace(/(\d{2})(\d)/, '($1) $2');
        celular = celular.replace(/(\d{5})(\d)/, '$1-$2');
    }
    return celular
}

export default mascaraCelular