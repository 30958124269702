import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const CodigosDoSistema = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nomePerfil = sessionStorage.getItem('NomePerfil')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarCodSistema) {
				toast.warning('Você não tem permissão para acessar Lista de Códigos do Sistema');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarCodSistema);
				setPermissaoCriar(permissoes.perfilPermissao.criarCodSistema);
				setPermissaoEditar(permissoes.perfilPermissao.editarCodSistema);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirCodSistema);
			}
		}
	}, [permissoes, erro, navigate]);

	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');

	const [id_codsis, setId_codsis] = useState<string>('');
	const [id_filial_Reg, setId_filial_Reg] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregando, setMsgModalCarregando] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const editarCodigoSistema = (dados: any) => {
		navigate(`/admin/codsis/codsis/${dados.id_codsis}/editar`);
	};
	const excluirCodigoSistema = (dados: any) => {
		setId_codsis(dados.id_codsis);
		setId_filial_Reg(dados.cod_id_filial);
		setMsgPrincipalModal(`Deseja excluir o código do sistema ${dados.descricao}?`);
		setMsgAcao1('Cancelar');

		setMostraModalConfirm(true);
	};
	const visualizarCodigoSistema = (dados: any) => {
		navigate(`/admin/codsis/codsis/${dados.id_codsis}/visualizar`);
	};

	//Criação das colunas da tabela
	const colunas: ITabela[] = [
		{
			titulo: 'ID. Tabela',
			acesso: 'id_codsis',
		},
		{
			titulo: 'Status',
			acesso: 'situacao',
		},
		{
			titulo: 'Tabela',
			acesso: 'tabela',
		},
		{
			titulo: 'Descrição',
			acesso: 'descricao',
		},
		{
			titulo: 'Tipo Acesso',
			acesso: 'cod_id_filial',
		},
		{
			titulo: 'Usuário',
			acesso: 'usuario',
		},
	];

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregando('Aguarde, carregando dados...');
		axios
			.get(Api + `/lista/codsis/${nomePerfil === 'Master' ? 0 : id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				setData(resposta.data.controlenumercao);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region -> Excluir
	function deleteCodigoSistema() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setMsgModalCarregando('Aguarde, excluindo registro...');
		axios
			.delete(Api + `/excluir/codsis/${id_filial_Reg}/${id_codsis}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				toast.success(resposta.data.message);
				setId_codsis('');
				setId_filial_Reg('');
				carregarDados();
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Códigos do Sistema</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								{permissaoCriar && (
									<div className="row">
										<div className="col d-flex justify-content-start">
											<Link className="btn btn-secondary" to={`/admin/codsis/codsis/novo/criar`}>
												<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
												Novo Código do Sistema
											</Link>
										</div>
									</div>
								)}
								<div className="">
									<ClickableTable
										coluna={colunas}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={permissaoVisualizar || permissaoEditar || permissaoExcluir}
										acaoEditar={editarCodigoSistema}
										acaoExcluir={excluirCodigoSistema}
										acaoVisualizar={visualizarCodigoSistema}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="descricao"
										labelpesquisa="Procurar código do sistema"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteCodigoSistema}
			/>

			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregando} />
		</>
	);
};

export { CodigosDoSistema };
