import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Api } from '../../../../services/api';

const animarSelect = makeAnimated();

interface InputMultSelectProps {
	apiEndpoint: string; // Usar o tipo apropriado para a URL da API
	value: any; // Usar o tipo apropriado para o valor do select
	onChange: (value: any) => void; // Usar o tipo apropriado para a função onChange
	disabled?: boolean;
	label?: string;
	name?: string;
	required?: boolean;
	placeholder?: string;
	isMulti?: boolean;
}

const InputMultSelect: React.FC<InputMultSelectProps> = ({
	apiEndpoint,
	value,
	onChange,
	disabled = false,
	label,
	name,
	required = false,
	placeholder = 'Selecione...',
	isMulti = false,
}) => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const [dados, setDados] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const buscarDados = async () => {
		try {
			setLoading(true);
			const response = await axios.get(Api + apiEndpoint, {
				headers: {
					authorization: token,
				},
			});
			setLoading(false);
			setDados(response.data);
		} catch (error: any) {
			setLoading(false);
			const msg = error.response?.data?.message || error.message;
			toast.error(`Falha ao buscar dados. Motivo: ${msg}`);
		}
	};

	return (
		<>
			<div className="form-group mb-1">
				{label && (
					<label className="form-label" htmlFor={name}>
						{label}
					</label>
				)}
				<Select
					classNamePrefix="react-select"
					isMulti={isMulti}
					placeholder={placeholder}
					options={dados}
					value={value}
					onChange={onChange}
					components={animarSelect}
					isDisabled={disabled}
					name={name}
					required={required}
					closeMenuOnSelect={!isMulti}
					maxMenuHeight={120}
					onMenuOpen={buscarDados}
					isLoading={loading}
					styles={{
						control: (base) => ({
							...base,
							border: 'none',
							borderRadius: 0,
							borderBottom: '1px solid #ccc',
							boxShadow: 'none',
							minHeight: '38px',
						}),
						placeholder: (base) => ({
							...base,
							color: '#6c757d',
						}),
						singleValue: (base) => ({
							...base,
							color: '#000',
						}),
						indicatorSeparator: (base) => ({
							...base,
							display: 'none',
						}),
						dropdownIndicator: (base) => ({
							...base,
							color: '#ccc',
							'&:hover': {
								color: '#3165f5',
							},
						}),
						menu: (base) => ({
							...base,
							borderRadius: 0,
						}),
						menuList: (base) => ({
							...base,
							padding: 0,
						}),
					}}
				/>
			</div>
			<ToastContainer newestOnTop />
		</>
	);
};

export default InputMultSelect;
