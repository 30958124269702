import { Link } from 'react-router-dom';

const ConferenciaSaida = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Conferência Saída</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-4">
									<a href="#" className="btn rounded mb-2" style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<i className="mdi mdi-plus-circle me-2"></i>
										Conferência Saída
									</a>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
									<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<tr>
											<th>Doca</th>
											<th>Processos</th>
											<th>Cliente</th>
											<th>Dt. Processo</th>
											<th>Dt. Chegada</th>
											<th>Tipo Operação</th>
											<th>Modalidade</th>
											<th>Cavalo</th>
											<th>Carreta</th>
											<th>Motorista</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>02</td>
											<td>55</td>
											<td>FKL Services</td>
											<td>26/11/21 16:00:00</td>
											<td>26/11/21 16:00:00</td>
											<td>SAÍDA</td>
											<td>ARMAZENAGEM</td>
											<td>QPO1F78</td>
											<td>QPO1F78</td>
											<td>HRSILVA</td>
											<td className="table-action">
												<Link to={`/operacao/confSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ConferenciaSaida };
