import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Api } from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn, mdiMagnify } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
import { colunasGridPesquisaProduto } from './components/colunasGridPesquisaProduto';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
const NovoProdutoDePicking = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');
	const id_cliente = sessionStorage.getItem('IsFKLDepositantePicking') || '';

	const params = useParams();
	const id_local = params.id || '';
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Embalagem

	const [abrirPesquisaProduto, setAbrirPesquisaProduto] = useState(false);
	//#endregion

	// dados do produto de picking
	const [id_wms, setIdWms] = useState<string>('');
	const [codigo, setCodigo] = useState<string>('');
	const [descricao, setDescricao] = useState<string>('');
	const [temperatura, setTemperatura] = useState<string>('');
	const codigoRef = useRef<HTMLInputElement>(null);

	// dados do local
	const [armazem, setArmazem] = useState<string>('');
	const [corredor, setCorredor] = useState<string>('');
	const [box, setBox] = useState<string>('');
	const [altura, setAltura] = useState<string>('');
	const [profundidade, setProfundidade] = useState<string>('');
	const [idLocal, setIdLocal] = useState<string>('');
	const [seq_sep, setSeqSep] = useState<number>(0);

	const focarCodigo = () => {
		setTimeout(() => {
			setCodigo('');
			codigoRef.current?.focus();
		}, 100);
	};

	//#region ==> Buscar dados do produto do depositante filtrando pelo codigo
	async function dadosProdutoCodigo(id_filial: string, id_cliente: string, codigo: string) {
		if (!codigo) {
			setDescricao('');
			setTemperatura('');
			return;
		}
		setShowCarregando(true);
		axios
			.get(Api + `/carregar/prodpick/produto/${id_filial}/${id_cliente}/${codigo}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				if (resposta.data.produto.length === 0) {
					toast.error('Produto não encontrado');
					setDescricao('');
					setTemperatura('');
					focarCodigo();
					return;
				}
				setIdWms(resposta.data.produto[0].id_wms);
				setDescricao(resposta.data.produto[0].descricao);
				setTemperatura(resposta.data.produto[0].temperatura);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados de armazem
	const selecionaArmazem = (dados: any) => {
		const id_armazem = dados;
		setCorredor('');
		setDadosCorredor([]);
		setBox('');
		setDadosBox([]);
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);
		setIdLocal('');
		setSeqSep(0);
		setArmazem(id_armazem);
	};

	//#region -> Função para buscar no cadastro de endereco o armazem para alimentar o select/option
	const [dadosArmazem, setDadosArmazem] = useState<any[]>([]);
	async function ListarArmazem(temperatura: string) {
		axios
			.get(Api + `/lista/prodpick/end/armazem/${id_filial}/${temperatura}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosArmazem;
				if (resposta.data.armazem.length === 0) {
					setDadosArmazem(dados);
					return;
				}
				setDadosArmazem(resposta.data.armazem);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados de corredor
	const selecionaCorredor = (dados: any) => {
		const id_corredor = dados;
		setBox('');
		setDadosBox([]);
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);
		setIdLocal('');
		setSeqSep(0);
		setCorredor(id_corredor);
	};

	//#region -> Função para buscar no cadastro de endereco o armazem para alimentar o select/option
	const [dadosCorredor, setDadosCorredor] = useState<any[]>([]);
	async function ListarCorredor(temperatura: string, armazem: string) {
		axios
			.get(Api + `/lista/prodpick/end/corredor/${id_filial}/${temperatura}/${armazem}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosCorredor;
				if (resposta.data.corredor.length === 0) {
					setDadosCorredor(dados);
					return;
				}
				setDadosCorredor(resposta.data.corredor);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do box
	const selecionaBox = (dados: any) => {
		const id_box = dados;
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);
		setIdLocal('');
		setSeqSep(0);
		setBox(id_box);
	};

	//#region -> Função para buscar no cadastro de endereco o box para alimentar o select/option
	const [dadosBox, setDadosBox] = useState<any[]>([]);
	async function ListarBox(temperatura: string, armazem: string, corredor: string) {
		axios
			.get(Api + `/lista/prodpick/end/box/${id_filial}/${temperatura}/${armazem}/${corredor}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosBox;
				if (resposta.data.box.length === 0) {
					setDadosBox(dados);
					return;
				}
				setDadosBox(resposta.data.box);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do altura
	const selecionaAltura = (dados: any) => {
		const id_altura = dados;
		setProfundidade('');
		setDadosProfundidade([]);
		setIdLocal('');
		setSeqSep(0);
		setAltura(id_altura);
	};

	//#region -> Função para buscar no cadastro de endereco o altura para alimentar o select/option
	const [dadosAltura, setDadosAltura] = useState<any[]>([]);	
	async function ListarAltura(temperatura: string, armazem: string, corredor: string, box: string) {
		axios
			.get(Api + `/lista/prodpick/end/altura/${id_filial}/${temperatura}/${armazem}/${corredor}/${box}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosAltura;
				if (resposta.data.altura.length === 0) {
					setDadosAltura(dados);
					return;
				}
				setDadosAltura(resposta.data.altura);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do profundidade
	const selecionaProfundidade = (dados: any) => {
		const id_profundidade = dados;
		setProfundidade(id_profundidade);

		// extrair o id_local de dadosProfundidade de acordo o id_profundidade selecionado
		if (id_profundidade === '') return;

		const dadosLocal: any = dadosProfundidade.filter((item: any) => item.value === parseInt(id_profundidade));

		setSeqSep(dadosLocal[0].seq_sep || 0);
		setIdLocal(dadosLocal[0].id_local);
	};

	//#region -> Função para buscar no cadastro de endereco a profundidade para alimentar o select/option
	const [dadosProfundidade, setDadosProfundidade] = useState<any[]>([]);
	async function ListarProfundidade(temperatura: string, armazem: string, corredor: string, box: string, altura: string) {
		axios
			.get(Api + `/lista/prodpick/end/profundidade/${id_filial}/${temperatura}/${armazem}/${corredor}/${box}/${altura}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosProfundidade;
				if (resposta.data.profundidade.length === 0) {
					setDadosProfundidade(dados);
					return;
				}
				setDadosProfundidade(resposta.data.profundidade);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const dadosDeEnvio = {
		id_wms,
		codigo,
		id_cliente,
		temperatura,
		armazem,
		corredor,
		box,
		altura,
		profundidade,
		id_local: idLocal,
		id_local_antigo: id_local,
		usuario: usuarioLogado,
	};

	//#region -> Função para inserir um novo produto
	function insereNovoproduto() {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/produtopicking/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				sessionStorage.removeItem('IsFKLDepositantePicking');
				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/prodpick/edit/${id_filial}/${id_cliente}/${id_local}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				const dados = resposta.data.produto[0];
				setIdWms(dados.id_wms);
				setCodigo(dados.codigo);
				setDescricao(dados.descricao);
				setTemperatura(dados.temperatura);
				setArmazem(dados.amz);
				setCorredor(dados.cor);
				setBox(dados.box);
				setAltura(dados.alt);
				setProfundidade(dados.prof);
				setIdLocal(dados.id_local);
				setSeqSep(dados.seq_sep);

				setDadosArmazem([{ value: dados.amz }]);
				setDadosCorredor([{ value: dados.cor }]);
				setDadosBox([{ value: dados.box }]);
				setDadosAltura([{ value: dados.alt }]);
				setDadosProfundidade([{ value: dados.prof }]);

				// ListarArmazem(dados.temperatura);

				// ListarCorredor(dados.temperatura, dados.amz);

				// ListarBox(dados.temperatura, dados.amz, dados.cor);

				// ListarAltura(dados.temperatura, dados.amz, dados.cor, dados.box);

				// ListarProfundidade(dados.temperatura, dados.amz, dados.cor, dados.box, dados.alt, dados.id_wms);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarproduto() {
		axios
			.put(Api + `/editar/prodpick/${id_filial}/${id_cliente}/${id_wms}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					sessionStorage.removeItem('IsFKLDepositantePicking');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	const selecionaProduto = (dados: any) => {
		setIdWms(dados.id_wms);
		setCodigo(dados.codigo);
		setDescricao(dados.descricao);
		setTemperatura(dados.temperatura);
		setAbrirPesquisaProduto(false);
	};
	const fecharPesquisaProduto = () => {
		setAbrirPesquisaProduto(false);
	};
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Produto de Picking'
									: acao === 'editar'
									? 'Editar Produto de Picking'
									: 'Visualizar Produto de Picking'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoproduto();
								} else if (acao === 'editar') {
									editarproduto();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#prodPicking" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="prodPicking">
											<div className="row">
												<div className="col-sm-3 position-relative">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="codigo"
															name="codigo"
															value={codigo}
															ref={codigoRef}
															onChange={(e) => setCodigo(e.target.value)}
															onBlur={() => dadosProdutoCodigo(id_filial, id_cliente, codigo)}
															disabled={acao === 'criar' ? false : true}
														/>
														<label className="form-label">Código</label>
														<BotaoCustomizado
															type="button"
															className={
																id_cliente !== '' && acao === 'criar'
																	? `btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y`
																	: `btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y disabled`
															}
															descricaoBotao=""
															icone={mdiMagnify}
															onclick={() => setAbrirPesquisaProduto(true)}
														/>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="descricao"
															name="descricao"
															value={descricao}
															onChange={(e) => setDescricao(e.target.value)}
															disabled
														/>
														<label className="form-label">Descrição</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="temperatura"
															name="temperatura"
															value={temperatura}
															disabled
														/>
														<label className="form-label">Temperatura</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm">
													<InputSelect
														label="Armazém"
														value={armazem}
														onChange={selecionaArmazem}
														onClick={() => ListarArmazem(temperatura)}
														dados={dadosArmazem}
														disabled={(acao === 'criar' || acao === 'editar') && codigo ? false : true}
														required
													/>
												</div>
												<div className="col-sm">
													<InputSelect
														label="Corredor"
														value={corredor}
														onChange={selecionaCorredor}
														onClick={() => ListarCorredor(temperatura, armazem)}
														dados={dadosCorredor}
														disabled={(acao === 'criar' || acao === 'editar') && armazem ? false : true}
														required
													/>
												</div>

												<div className="col-sm">
													<InputSelect
														label="Box"
														value={box}
														onChange={selecionaBox}
														onClick={() => ListarBox(temperatura, armazem, corredor)}
														dados={dadosBox}
														disabled={(acao === 'criar' || acao === 'editar') && corredor ? false : true}
														required
													/>
												</div>
												<div className="col-sm">
													<InputSelect
														label="Altura"
														value={altura}
														onChange={selecionaAltura}
														onClick={() => ListarAltura(temperatura, armazem, corredor, box)}
														dados={dadosAltura}
														disabled={(acao === 'criar' || acao === 'editar') && box ? false : true}
														required
													/>
												</div>
												<div className="col-sm">
													<InputSelect
														label="Profundidade"
														value={profundidade}
														onChange={selecionaProfundidade}
														onClick={() => ListarProfundidade(temperatura, armazem, corredor, box, altura)}
														dados={dadosProfundidade}
														disabled={(acao === 'criar' || acao === 'editar') && altura ? false : true}
														required
													/>
												</div>
												<div className="col-sm">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="seq_sep"
															name="seq_sep"
															value={seq_sep}
															step={1}
															disabled //={acao === 'criar' ? false : acao === 'editar' ? false : true} //acao desabilitada pois o cadastro de endereço ja esta trazendo o valor
														/>
														<label className="form-label">Seq. Separação</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													sessionStorage.removeItem('IsFKLDepositantePicking');
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando show={showCarregando} mensagem="Carregando dados..." />

			{/* MODAL PARA PESQUISA DE PRODUTO */}
			<ModalPesquisa
				colunas={colunasGridPesquisaProduto}
				fetchUrl={Api + `/carregar/prodpick/item/depositante/${id_filial}/${id_cliente}`}
				filtro="descricao"
				textoFiltro="Pesquisar Produto"
				visible={abrirPesquisaProduto}
				onSelect={selecionaProduto}
				onClose={fecharPesquisaProduto}
			/>
		</>
	);
};

export { NovoProdutoDePicking };
