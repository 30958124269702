import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Inputs from '../../../../components/componentes/formulario/Inputs';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';

export function CadastroCFOPIncluirEntradaXSaida() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';
	const navigate = useNavigate();


	{/*Carrega os CFOPs*/ }
	const [cfop, setCfop] = useState([])

	async function carregarCfops() {
		const response = await axios.get(Api + `/cfop/json/?&auth=${token}`)
		setCfop(response.data)
	}

	useEffect(function () {
		carregarCfops()
	}, [])

	{/*PEGA e SETA a natureza da operação ENTRADA e SAIDA*/ }
	const [natOperSaida, setnatOperSaida] = useState("")
	const [cfopEntrada, setcfopEntrada] = useState("")
	const [cfopSaida, setcfopSaida] = useState("")
	const [padrao, set_padrao] = useState(false)

	async function pegarNatoperSaida(cfopDeSaida: any) {

		if (cfopDeSaida.target.value == "") {
			setcfopEntrada("")
			setnatOperSaida("")
		}
		else {
			setcfopSaida(cfopDeSaida.target.value) //seta o CFOP de saida

			const response = await axios.get(Api + `/natOper?&cfop=${cfopDeSaida.target.value}&auth=${token}`)
			setnatOperSaida(response.data[0].natoper)
		}
	}

	const [natOperEntrada, setnatOperEntrada] = useState("")

	async function pegarNatoperEntrada(cfopDeEntrada: any) {

		if (cfopDeEntrada.target.value == "") {
			setcfopSaida("")
			setnatOperEntrada("")
		}
		else {
			setcfopEntrada(cfopDeEntrada.target.value) //seta o CFOP de Entrada

			const response = await axios.get(Api + `/natOper?&cfop=${cfopDeEntrada.target.value}&auth=${token}`)
			setnatOperEntrada(response.data[0].natoper)
		}
	}

	{/*Todo o processo para realizar o Insert*/ }

	//cfop de saida e entrada ja foi setado ao executar a função pegarNatoper
	//natoper ja esta setado também.

	async function insertCfopEntradaXSaida(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()

		const cfopEntradaFormatado = cfopEntrada.split("|")[0]
		const cfopSaidaFormatado = cfopSaida.split("|")[0]

		if (cfopEntradaFormatado.length != 4) {
			alert("Selecione um CFOP de entrada.")
		}
		else if (cfopSaidaFormatado.length != 4) {
			alert("Selecione um CFOP de Saída.")
		}
		else {
			const dados = {
				cfopent: cfopEntradaFormatado,
				cfopsai: cfopSaidaFormatado,
				natoper: natOperSaida,
				operador_adicao: usuario,
				padrao: padrao
			}
			const resposta = await axios.post(Api + `/newCfopXsaida?&auth=${token}`, dados)
			if (resposta.data.message == "CFOP entrada X saída criado com sucesso.") {
				setTimeout(() => navigate(-1), 500);
			}
			else {
				alert(resposta.data.message)
			}
		}

	}

	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Incluir CFOP Entrada X Saída" />
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form onSubmit={insertCfopEntradaXSaida}>
								<div className="card-body">
									<div className="row">
										<div className="col-sm-6">
											<label className="form-label" htmlFor="cfopEntrada">
												CFOP de Entrada
											</label>
											<select
												className="form-select"
												id="cfopEntrada"
												name="cfopEntrada"
												onChange={pegarNatoperEntrada}
												required
											>
												<option value="">Selecione...</option>
												{cfop.map(function (dados: any) {

													if (dados.tipo_cfop == "Entrada") {
														return (
															<option value={dados.cfop}>{dados.cfop} | {dados.natoper}</option>
														)
													}
												})}
											</select>
										</div>

										<div className="col-sm-2">
											<label
												className="form-check-label"
												htmlFor="switch35"
												style={{ position: 'relative', top: '-7px', marginRight: "6%" }}
											>
												Definir como CFOP padrão
											</label>
											<input type="checkbox" id="switch35" data-switch="bool" checked={padrao} onChange={function(e: any){
												set_padrao(e.target.checked)
											}}/>
											<label htmlFor="switch35" data-on-label="On" data-off-label="Off"></label>
										</div>

										<div className="w-100"></div>
										<br />
										<div className="col-sm-6">
											<label className="form-label" htmlFor="cfopSaida">
												CFOP de Saída
											</label>
											<select
												className="form-select"
												id="cfopSaida"
												name="cfopSaida"
												onChange={pegarNatoperSaida}
												required
											>

												<option value="">Selecione...</option>
												{cfop.map(function (dados: any) {
													if (dados.tipo_cfop == "Saída") {
														return (
															<option value={dados.cfop}>{dados.cfop} | {dados.natoper}</option>
														)
													}
												})}
											</select>
										</div>

									</div>
									<br />
									<div className="col-sm-10">

										<div className="mb-1">
											<Inputs
												label="Natureza de Operação Entrada"
												maxLength={250}
												name="natOp"
												disabled
												classNameInput="form-control"
												classNameLabel="form-label"
												required={true}
												value={natOperEntrada}
											/>
										</div>

										<div className="mb-1">
											<Inputs
												label="Natureza de Operação Saída"
												maxLength={250}
												name="natOp"
												disabled
												classNameInput="form-control"
												classNameLabel="form-label"
												required={true}
												value={natOperSaida}
											/>
										</div>
									</div>
									<ToastContainer newestOnTop />
								</div>

								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button className="btn btn-cancelar mb-2 me-1" onClick={() => navigate(-1)} type="button">
												Cancelar
											</button>
											<button className="btn btn-novo mb-2 me-1" type="submit">
												Salvar
											</button>
										</div>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
			{/*<ModalCarregando show={showCarregando} /> */}
		</>
	);
}
