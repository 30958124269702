import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Inputs from '../../../../components/componentes/formulario/Inputs';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';

export function EditarCFOPEntradaXSaida() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';
	const navigate = useNavigate();

	const paramsUrl = useParams()

	//informações atuais do cadastro -- inicio
	const [cfopEntrada, setCfopEntrada] = useState("")
	//const [natopEntrada, setNatOpEntrada] = useState("")

	const [cfopSaida, setCfopSaida] = useState("")
	const [natopSaida, setNatOpSaida] = useState("")
	const [padrao, set_padrao] = useState(false)


	async function pegarInfoCFOP() {
		const resposta = await axios.get(Api + `/cfopXentradaID/?auth=${token}&idCfop=${paramsUrl.id}`)
		setCfopEntrada(resposta.data[0].cfopent)
		setCfopSaida(resposta.data[0].cfopsai)
		setNatOpSaida(resposta.data[0].natoper)
		set_padrao(resposta.data[0].padrao)
	}
	useEffect(function () {
		pegarInfoCFOP()
	}, [])
	//informações atuais do cadastro -- fim


	//pegar e setar info atualizada para atualizar o cadastro -- inicio
	function attCfopEntrada(event: any) {
		const conteudo = event.target.value
		setCfopEntrada(conteudo.split("|")[0])
		//setNatOpEntrada(conteudo.split("|")[1])
	}

	function attCfopSaida(event: any) {

		const conteudo = event.target.value

		if (conteudo.split("|")[1] == undefined) {
			setCfopSaida(conteudo.split("|")[0])
		}
		else {
			setCfopSaida(conteudo.split("|")[0])
			setNatOpSaida(conteudo.split("|")[1])
		}

	}
	//pegar e setar info atualizada para atualizar o cadastro -- inicio

	//carrega todos os CFOPs -- inicio
	const [cfop, setCfop] = useState([])

	async function carregarCfops() {
		const response = await axios.get(Api + `/cfop/json/?&auth=${token}`)
		setCfop(response.data)
	}
	useEffect(function () {
		carregarCfops()
	}, [])
	//carrega todos os CFOPs -- fim


	//fazer o update --inicio
	async function attCfopXentradaXSaida(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()

		const data = {
			cfopent: cfopEntrada,
			cfopsai: cfopSaida,
			natoper: natopSaida,
			operadorEdicao: usuario,
			id: paramsUrl.id,
			padrao: padrao
		}
		const resposta = await axios.put(Api + `/attCfopXsaida/?&auth=${token}`, data)
		alert(resposta.data)
		if (resposta.data == "CFOP Entrada X Saida atualizado com sucesso") {
			navigate(-1)
		}
	}
	//fazer o update --fim


	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Incluir CFOP Entrada X Saída" />
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form onSubmit={attCfopXentradaXSaida}>
								<div className="card-body">
									<div className="row">
										<div className="col-sm-2">
											<label className="form-label" htmlFor="cfopEntrada">
												CFOP de Entrada
											</label>
											<select
												className="form-select"
												id="cfopEntrada"
												name="cfopEntrada"
												onChange={attCfopEntrada}
												required
											>
												<option value={cfopEntrada}>{cfopEntrada}</option>
												{cfop.map(function (dados: any) {
													if (dados.tipo_cfop == "Entrada") {
														return (
															<option value={dados.cfop + "|" + dados.natoper}>{dados.cfop} | {dados.natoper}</option>
														)
													}
												})}
											</select>
										</div>

										<div className="col-sm-2">
											<label
												className="form-check-label"
												htmlFor="switch35"
												style={{ position: 'relative', top: '-7px', marginRight: "6%" }}
											>
												Definir como CFOP padrão
											</label>
											<input type="checkbox" id="switch35" data-switch="bool" checked={padrao} onChange={function (e: any) {
												set_padrao(e.target.checked)
											}} />
											<label htmlFor="switch35" data-on-label="On" data-off-label="Off"></label>
										</div>

										<div className="w-100"></div>
										<br />

										<div className="col-sm-2">
											<label className="form-label" htmlFor="cfopSaida">
												CFOP de Saída
											</label>
											<select
												className="form-select"
												id="cfopSaida"
												name="cfopSaida"
												onChange={attCfopSaida}
												required
											>

												<option value={cfopSaida}>{cfopSaida}</option>
												{cfop.map(function (dados: any) {
													if (dados.tipo_cfop == "Saída") {
														return (
															<option value={dados.cfop + "|" + dados.natoper}>{dados.cfop} | {dados.natoper}</option>
														)
													}
												})}
											</select>
										</div>
										<div className="col-sm-10">
											<div className="mb-1">
												<Inputs
													label="Natureza de Operação Saída"
													maxLength={250}
													name="natOp"
													disabled
													classNameInput="form-control"
													classNameLabel="form-label"
													required={true}
													value={natopSaida}
												/>
											</div>
										</div>
									</div>
									<ToastContainer newestOnTop />
								</div>

								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button className="btn btn-cancelar mb-2 me-1" onClick={() => navigate(-1)} type="button">
												Cancelar
											</button>
											<button className="btn btn-novo mb-2 me-1" type="submit">
												Salvar
											</button>
										</div>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
			{/*<ModalCarregando show={showCarregando} /> */}
		</>
	);
}
