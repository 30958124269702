import axios from "axios"
import { Api } from "../../../../services/api"
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Spinner } from 'react-bootstrap';

const VisualizarEndereco = (): JSX.Element => {
	//#region => VARIÁVEIS
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const idUsuario = sessionStorage.getItem('IsIDFKL');
	const { endereco, filial } = useParams();
	const [informacoesEndereco, setInformacoesEndereco] = useState([]);
	const [carregando, setCarregando] = useState(false);
	const [razaoSocial, setRazaoSocial] = useState('');
	const navigate = useNavigate();
	//#endregion

	//#region => BUSCAR e CARREGAR - Informações do endereço
	const carregarDadosDoEndereco = async () => {
		try {
			const resposta = await axios.get(Api + `/endereco/?auth=${token}&tag=${endereco}&filial=${filial}`);
			setInformacoesEndereco(resposta.data);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion


	//#region => CARREGAR - Informações na tela
	useEffect(() => {
		setCarregando(true);
		carregarDadosDoEndereco();
		setCarregando(false);
	}, []);
	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Visualizar Endereço: {endereco} - {razaoSocial}</h4>
						</div>
					</div>
				</div>
				<form id="form-utilitarios">
					<div className="card">
						<div className="card-body">
							{
								informacoesEndereco.map((info: any, index: number) => {
									return (
										<div key={index}>
											<div className="row">
												<div className="col">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="tipoPosicao"
															name="tipoPosicao"
															title="Tabela: TIPPOSICAO"
															disabled
														>
															<option>{info.tipo_posicao}</option>
														</select>
														<label className="form-label" htmlFor="tipoPosicao">
															Tipo de Posição
														</label>
													</div>
												</div>
												<div className="col">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="temperatura"
															name="tipoTemperatura"
															title="Tabela: TIPTEMP"
															disabled
															placeholder=""
														>
															<option>{info.tipo_temperatura}</option>
														</select>
														<label className="form-label" htmlFor="temperatura">
															Temperatura
														</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col form-floating">

													<input
														type="text"
														id="armazem"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
														name="armazem"
														defaultValue={info.armazem}
														disabled
													/>
													<label htmlFor="armazem" className="form-label">
														Armazém:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="text"
														id="corredor"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
														name="corredor"
														defaultValue={info.corredor}
														disabled
													/>
													<label htmlFor="corredor" className="form-label">
														Corredor:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="number"
														id="boxIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
														name="box"
														defaultValue={info.box}
														disabled
													/>
													<label htmlFor="boxIni" className="form-label">
														Box:
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col form-floating ">

													<input
														type="number"
														id="altIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
														name="altura"
														defaultValue={info.altura}
														disabled
													/>
													<label htmlFor="altIni" className="form-label">
														Altura:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="number"
														id="profIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
														name="profundidade"
														defaultValue={info.profundidade}
														disabled
													/>
													<label htmlFor="profIni" className="form-label">
														Profundidade:
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="qtdePicking"
															title="Tabela: SIMNAO"
															disabled
														>
															<option>{info.conf_qtde === "0" ? "NÃO" : "SIM"}</option>
														</select>
														<label className="form-label" htmlFor="qtdePicking">
															Conf. Qtde Durante Picking
														</label>
													</div>
												</div>

											</div>
										</div>
									)
								})
							}
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-cancelar mb-2 me-1"
										type="button"
										onClick={() => navigate('/config/cadast/endereco')}
									>
										Voltar
									</button>
									<button
										className="btn btn-novo mb-2 me-1"
										type="submit"
										disabled
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			{/*MODAL CARREGANDO*/}
			<Modal show={carregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>
			</Modal>
		</>
	)
}

export { VisualizarEndereco }