import { useNavigate } from 'react-router-dom';

const NovosUtilitarios = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Novo Utilitário</h4>
					</div>
				</div>
			</div>
			<form id="form-utilitarios">
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-6 mb-1">
								<label htmlFor="nomTabela" className="form-label">
									Nome da Tabela:
								</label>
								<input type="text" id="nomTabela" className="form-control" required />
								<div className="invalid-feedback">Informe o nome da Tabela!</div>
							</div>
							<div className="col-10 mb-4">
								<label htmlFor="descTabela" className="form-label">
									Descrição:
								</label>
								<input type="text" id="descTabela" className="form-control" required />
								<div className="invalid-feedback">Informe a descrição da tabela!</div>
							</div>
						</div>
						<hr className="mb-4" />
						<div className="row">
							<div className="col-6 mb-1">
								<label htmlFor="codTabela" className="form-label">
									Código:
								</label>
								<input type="text" id="codTabela" className="form-control" required />
								<div className="invalid-feedback">Informe o código da tabela!</div>
							</div>
							<div className="col-6 mb-1">
								<div className="form-check">
									<input type="checkbox" className="form-check-input" id="chAtivo" />
									<label className="form-check-label" htmlFor="chAtivo">
										Ativo
									</label>
								</div>
							</div>
							<div className="mb-1 col-12">
								<label htmlFor="descCodigo" className="form-label">
									Descrição:
								</label>
								<input type="text" id="descCodigo" className="form-control" />
								<div className="invalid-feedback">Informe uma descrição para o código!</div>
							</div>
							<div className="mb-1 col-6">
								<label htmlFor="numSeq" className="form-label">
									Sequência:
								</label>
								<input type="text" id="numSeq" className="form-control" required />
								<div className="invalid-feedback">Informe um sequencial!</div>
							</div>
							<div className="mb-1 col-12">
								<label htmlFor="anotacao" className="form-label">
									Anotações:
								</label>
								<input type="text" id="anotacao" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu1" className="form-label">
									Definido pelo usuário 1:
								</label>
								<input type="text" id="defPeloUsu1" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu2" className="form-label">
									Definido pelo usuário 2:
								</label>
								<input type="text" id="defPeloUsu2" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu3" className="form-label">
									Definido pelo usuário 3:
								</label>
								<input type="text" id="defPeloUsu3" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu4" className="form-label">
									Definido pelo usuário 4:
								</label>
								<input type="text" id="defPeloUsu4" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu5" className="form-label">
									Definido pelo usuário 5:
								</label>
								<input type="text" id="defPeloUsu5" className="form-control" />
							</div>
							<div className="mb-1 col-4">
								<label htmlFor="defPeloUsu6" className="form-label">
									Definido pelo usuário 6:
								</label>
								<input type="text" id="defPeloUsu6" className="form-control" />
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className="col">
							<div className="text-sm-end">
								<button
									className="btn btn-cancelar mb-2 me-1"
									type="button"
									onClick={() => navigate('/config/cadast/utilitarios')}
								>
									Cancelar
								</button>
								<button
									className="btn btn-novo mb-2 me-1"
									type="submit"
									onClick={() => navigate('/config/cadast/utilitarios')}
								>
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export { NovosUtilitarios };
