import { mdiEye, mdiSquareEditOutline, mdiTrashCanOutline, mdiCheckCircle, mdiCloseCircle, mdiSkipBackward, mdiSkipPrevious, mdiSkipNext, mdiSkipForward } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import axios from 'axios';
import { Api } from '../../../../services/api';

const ListagemDosPedidos = (): JSX.Element => {
	//#region
	//#region => VARIÁVEIS
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const [listaDePedidos, setListaDePedidos] = useState([]);
	const [mostrarModalDelecao, setMostrarModalDelecao] = useState(false);
	const [itemClicado, setItemClicado] = useState({
		cod_produto: '',
		criterio: null,
		data_adicao: '',
		data_alteracao: null,
		data_importacao: null,
		data_programacao: null,
		data_quebrafefo: null,
		data_validade: null,
		descricao: '',
		id: 0,
		id_chave: null,
		id_pedido: 0,
		id_processo: null,
		os: null,
		pb_pedido: 0,
		pb_separado: null,
		pick_concluido: null,
		pl_pedido: 0,
		pl_separado: null,
		produto_wms: 0,
		qtd_caixas: 0,
		qtd_fiscal_separada: null,
		qtd_original: 0,
		qtd_referencia: null,
		qtd_separada: null,
		quebra_fefo: null,
		romaneado: null,
		rota: null,
		roteiro: null,
		status: null,
		tipo_peso: '',
		tipo_temperatura: '',
		unid_referencia: null,
		unid_separacao: null,
		usuario_adicao: '',
		usuario_alteracao: null,
		usuario_quebrafefo: null
	})
	const navigate = useNavigate();
	//#endregion

	//#region => CARREGAR Lista de Pedidos
	const carregarListaDePedidos = async () => {
		const resposta = await axios.get(Api + `/pedidos/?auth=${token}`);
		setListaDePedidos(resposta.data);
	}
	//#endregion

	//#region => DELETAR PEDIDO
	const deletarPedido = (id: number) => {
		const item = listaDePedidos.filter((pedido: any) => pedido.id_pedido === id);
		setItemClicado(item[0]);
		setMostrarModalDelecao(true);
	}

	const deletarPedidoDaLista = async (id: number) => {

		try {
			await axios.delete(Api + `/deletar-pedido/?auth=${token}&id=${id}`);
			const resposta = await axios.get(Api + `/pedidos/?auth=${token}`);
			setListaDePedidos(resposta.data);
			setMostrarModalDelecao(false);
		} catch (error) {
			console.log(error);
		}
	}
	//#endregion

	//#region => CARREGAR DADOS NA TELA
	useEffect(() => {
		carregarListaDePedidos();
	}, [])
	//#endregion

	//#endregion
	//#region Paginação  do AR
	const [qtdItensPorPagina, setQtdItensPorPagina] = useState(10);
	const [paginaAtual, setPaginaAtual] = useState(0);

	const pagina = Math.ceil(listaDePedidos.length / qtdItensPorPagina);

	const indiceInicial = paginaAtual * qtdItensPorPagina;
	const indiceFinal = indiceInicial + qtdItensPorPagina;
	const itensSelecionados = listaDePedidos.slice(indiceInicial, indiceFinal);
	//#endregion

	//#region  Modifica pagina da listagem de Pedidos
	const primeiraPagina = () => {
		if (paginaAtual > 0) {
			setPaginaAtual(0)
		}
	}
	const paginaAnterior = () => {
		if (paginaAtual > 0) {
			setPaginaAtual(paginaAtual - 1)
		}
	}
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + paginaAtual
		if (pagina < pagina) {
			setPaginaAtual(paginaAtual + 1)
		}
	}
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + paginaAtual
		if (pagina < pagina) {
			setPaginaAtual(pagina - 1)
		}
	}
	//#endregion
	const itensPorPagina = (itens: any) => {
		setQtdItensPorPagina(Number(itens));
		setPaginaAtual(0);
	}
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Listagem dos Pedidos</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-12 mb-2">
									<Link to={`/pco/contrFisc/gestPedidos/listagemDosPedidos/novo`} className="btn btn-info rounded-pill me-2">
										<i className="mdi mdi-plus-circle me-2"></i>
										Novo
									</Link>
									<button className="btn btn-warning rounded-pill dropdown-toggle me-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Ações
									</button>
									<div className="dropdown-menu">
										<button className="dropdown-item">Programar Pedido</button>
										<button className="dropdown-item">Descongelar</button>
										<button className="dropdown-item">Gerar Impressão</button>
									</div>
									<Link to={'/pco/contrFisc/gestPedidos/OSPRogramados'} className="btn btn-info rounded-pill me-2">
										OS Programadas
									</Link>
									<button className="btn btn-warning rounded-pill dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Importação Pedidos
									</button>
									<div className="dropdown-menu">
										<label htmlFor="btn-impTXT" className="dropdown-item">
											Importar TXT
										</label>
										<input type="file" style={{ display: 'none' }} className="form-control-file" id="btn-impTXT" />
										<label htmlFor="btn-impExcel" className="dropdown-item">
											Importar Excel
										</label>
										<input type="file" style={{ display: 'none' }} className="form-control-file" id="btn-impExcel" />
										<button className="dropdown-item">
											Nota Fiscal
										</button>
										<label htmlFor="btn-impCustom" className="dropdown-item">
											Importação Customizada
										</label>
										<input type="file" style={{ display: 'none' }} className="form-control-file" id="btn-impCustom" />
									</div>
								</div>
							</div>
							{/**tabela mostrando a lista de pedidos */}
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
									<thead className="table-cabecalho">
										<tr className="cabecalho-Pedidos-Entrada">
											<th>#</th>
											<th>ID Pedido</th>
											<th>Cliente</th>
											<th>CNPJ</th>
											<th>Data Carga</th>
											<th>Pedido</th>
											<th>Destinatário</th>
											<th>Pedido Cliente</th>
											<th>Status</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody className='corpoTabela'>
										{
											itensSelecionados.map((pedido: any, index: number) => {
												return (
													<tr key={index}>
														<td>
															<input type="checkbox" />
														</td>
														<td>{pedido.id_pedido}</td>
														<td>{pedido.nome_filial}</td>
														<td>{pedido.cnpj_depositante}</td>
														<td>{pedido.data_carga.split('T').join(' ').slice(0, 19)}</td>
														<td>Pedidos HRR/1234</td>
														<td>{pedido.destinatario}</td>
														<td>{pedido.pedido_cliente}</td>
														<td>PENDENTE</td>
														<td className="d-flex flex-nowrap table-action">
															<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { navigate(`visualizar/${pedido.id_pedido}`) }} title='Visualizar'>
																<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
															</button>
															<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { navigate(`editar/${pedido.id_pedido}`) }} title='Editar'>
																<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
															</button>
															<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { deletarPedido(pedido.id_pedido) }} title='Excluir'>
																<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
															</button>
														</td>
													</tr>
												)
											})
										}
									</tbody>
									<tfoot>
										<tr className="rodapeTabelaPaginacao">
											<td colSpan={14}>
												<a onClick={primeiraPagina} title="Primeira Página">
													<Icon path={mdiSkipBackward} size={1} />
												</a>
												<a onClick={paginaAnterior} title="Página Anterior">
													<Icon path={mdiSkipPrevious} size={1} />
												</a>
												Página: {paginaAtual + 1} de {pagina} | Registros Total: {listaDePedidos.length}
												<a onClick={proxPagina} title="Próxima Página">
													<Icon path={mdiSkipNext} size={1} />
												</a>
												<a onClick={ultimaPagina} title="Última Página">
													<Icon path={mdiSkipForward} size={1} />
												</a>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
							{/**modal para deleção de item */}
							<Modal show={mostrarModalDelecao} onHide={() => setMostrarModalDelecao(false)} size="lg" centered>
								<Modal.Header closeButton>
									<Modal.Title>Confirmar Exclusão</Modal.Title>
								</Modal.Header>
								<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
									<BsExclamationCircleFill size={"100px"} color="#f58619" />
									<div className="row" style={{ textAlign: 'center' }}>
										<div className="col-sm" >
											<h3>Tem certeza que deseja excluir o pedido {itemClicado.id_pedido}?</h3>
											<h4>- Esta ação não poderá ser desfeita -</h4>
										</div>
									</div>
									<hr />
									<div className="d-flex my-2 gap-1 justify-content-between">
										<Button type="button" onClick={() => deletarPedidoDaLista(itemClicado.id_pedido)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />Sim</Button>
										<Button type="button" onClick={() => setMostrarModalDelecao(false)} style={{ borderRadius: '20px', float: 'left' }}><Icon path={mdiCloseCircle} size={1} className="me-2" />Não</Button>
									</div>
								</Modal.Body>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ListagemDosPedidos };
