import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Inputs from '../../../../components/componentes/formulario/Inputs';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';

export function CadastroCFOPxSaidaVisualizar() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';
	const navigate = useNavigate();

	const [cfopEntrada, setCfopEntrada] = useState('');
	const [cfopSaida, setCfopSaida] = useState('');
	const [natOp, setNatOp] = useState('');
	const [padrao, set_padrao] = useState(false)

	const [showCarregando, setShowCarregando] = useState(false);
	const idCfop = useParams();

	async function pegarInfoCFOP(cfopId: any) {
		const resposta = await axios.get(Api + `/cfopXentradaID/?auth=${token}&idCfop=${cfopId}`)
		setCfopEntrada(resposta.data[0].cfopent)
		setCfopSaida(resposta.data[0].cfopsai)
		setNatOp(resposta.data[0].natoper)
		set_padrao(resposta.data[0].padrao)
	}

	useEffect(function () {
		pegarInfoCFOP(idCfop.id)
	}, [])



	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Visualizar CFOP" />
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form //onSubmit={enviar}
							>
								<div className="card-body">
									<div className="row">
										<div className="col-sm-2">
											<div className="mb-1">
												<Inputs
													label="CFOP de Entrada"

													usaMascara={true}
													maxLength={5}
													name="cfop"
													//onchange={setCfop}
													classNameInput="form-control"
													classNameLabel="form-label"
													readOnly={true}
													value={cfopEntrada}
												/>
											</div>
										</div>
										<div className="col-sm-2">
											<label
												className="form-check-label"
												htmlFor="switch35"
												style={{ position: 'relative', top: '-7px', marginRight: "6%" }}
											>
												Definir como CFOP padrão
											</label>
											<input type="checkbox" id="switch35" data-switch="bool" checked={padrao} onChange={function (e: any) {
												set_padrao(e.target.checked)
											}}disabled/>
											<label htmlFor="switch35" data-on-label="On" data-off-label="Off"></label>
										</div>
										<div className="w-100"></div>
										<br />
										<div className="col-sm-2">
											<div className="mb-1">
												<Inputs
													label="CFOP de Saída"

													usaMascara={true}
													maxLength={5}
													name="cfop"
													//onchange={setCfop}
													classNameInput="form-control"
													classNameLabel="form-label"
													readOnly={true}
													value={cfopSaida}
												/>
											</div>
										</div>
										<div className="col-sm-10">
											<div className="mb-1">
												<Inputs
													label="Natureza de Operação de Saída"
													maxLength={250}
													name="natOp"
													//onchange={setNatOp}
													classNameInput="form-control"
													classNameLabel="form-label"
													readOnly={true}
													value={natOp}
												/>
											</div>
										</div>
									</div>

									<ToastContainer newestOnTop />
								</div>
								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button className="btn btn-cancelar mb-2 me-1" onClick={() => navigate(-1)} type="button">
												Voltar
											</button>
											{/*<button className="btn btn-novo mb-2 me-1 disabled" type="submit">
												Salvar
											</button> */}
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando show={showCarregando} />
		</>
	);
}
