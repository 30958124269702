import { useNavigate } from 'react-router-dom';

const NovoPalletSemEnderecamento = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Autorização de Entrada</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tab-ger-end-palletSEnd-geral"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tab-ger-end-palletSEnd-geral">
										<div className="row mb-2">
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="processo"
														placeholder=""
													/>
													<label htmlFor="processo">Processo</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="depositante"
														placeholder=""
													/>
													<label htmlFor="depositante">Depositante</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="criterio"
														placeholder=""
													/>
													<label htmlFor="criterio">Critério</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="modal_operacao"
														placeholder=""
													/>
													<label htmlFor="modal_operacao">Modal Operação</label>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="table-responsive">
												<table className="table table-centered w-100 dt-responsive nowrap">
													<thead className="table-light">
														<tr>
															<th>Palete</th>
															<th>Código</th>
															<th>Descrição</th>
															<th>Qtd Volumes</th>
															<th>Peso Bruto</th>
															<th>Peso Liquido</th>
															<th>Ações</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>61</td>
															<td>700100322</td>
															<td>COXINHA DE FRANGO FRITA LANCHE CX 3x10UN</td>
															<td>34</td>
															<td>120,00</td>
															<td>110,00</td>
															<td className="d-flex flex-nowrap table-action">
																<a
																	data-bs-toggle="modal"
																	data-bs-target="#modal-ger-end-palletSEnderecamentoNovo-detPallet"
																	className="action-icon"
																>
																	<i className="mdi mdi-file-find-outline"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/gerenciamento/enderecamento/palletSemEnderecamento')}
										>
											Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-ger-end-palletSEnderecamentoNovo-detPallet"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe do Palete
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="row mb-1">
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="palete"
												placeholder=""
											/>
											<label htmlFor="palete">Palete</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="codigo"
												placeholder=""
											/>
											<label htmlFor="codigo">Código</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="descricao"
												placeholder=""
											/>
											<label htmlFor="descricao">Descrição</label>
										</div>
									</div>
								</div>
								<div className="row mb-1">
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="quantidade"
												placeholder=""
											/>
											<label htmlFor="quantidade">Quantidade</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="lote"
												placeholder=""
											/>
											<label htmlFor="lote">Lote</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="data_validade"
												placeholder=""
											/>
											<label htmlFor="data_validade">Data Validade</label>
										</div>
									</div>
								</div>
								<div className="row mb-1">
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="data_cadastro"
												placeholder=""
											/>
											<label htmlFor="data_cadastro">Data Cadastro</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="temperatura"
												placeholder=""
											/>
											<label htmlFor="temperatura">Temperatura</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="empenho"
												placeholder=""
											/>
											<label htmlFor="empenho">Empenho</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="observacao"
												placeholder=""
											/>
											<label htmlFor="observacao">Observação</label>
										</div>
									</div>
								</div>
								<div className="row mb-1">
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="critério"
												placeholder=""
											/>
											<label htmlFor="critério">Critério</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="usuario"
												placeholder=""
											/>
											<label htmlFor="usuario">Usuário</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="camara"
												placeholder=""
											/>
											<label htmlFor="camara">Câmara</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="corredor"
												placeholder=""
											/>
											<label htmlFor="corredor">Corredor</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="box"
												placeholder=""
											/>
											<label htmlFor="box">Box</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="altura"
												placeholder=""
											/>
											<label htmlFor="altura">Altura</label>
										</div>
									</div>
									<div className="col-sm-2">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="profundidade"
												placeholder=""
											/>
											<label htmlFor="profundidade">Profundidade</label>
										</div>
									</div>
								</div>

								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovoPalletSemEnderecamento };
