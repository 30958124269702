const ModalIntegracaoSaidaExport = (): JSX.Element => {
	return (
		<div
			className="modal fade"
			id="modal-oper-gestPed-integrSaida-export"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabIndex={-1}
			aria-labelledby="staticBackdropLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="staticBackdropLabel">
							Nova Exportação
						</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
					</div>
					<div className="modal-body">
						<div className="card">
							<div className="card-body">
								<div className="row bg-info text-center">
									<h2 className="text-dark mt-4">Selecione o Tipo de Exportação desejada!</h2>
									<h4 className="text-danger mb-4">
										<strong>Isso é necessário para que o sistema possa identificar o layout do arquivo a ser exportado.</strong>
									</h4>
								</div>
								<div className="row mt-4 mb-4">
									<div className="offset-sm-2 col-sm-8">
										<label className="form-label">Depositante</label>
										<select className="form-select">
											<option>---</option>
										</select>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="col-4 for-group mr-auto">
									<label htmlFor="btn-oper-gestPed-integrSaida-exoport" className="btn btn-info rounded-pill">
										<i className="mdi mdi-plus-circle me-2"></i>Exportar Arq.
									</label>
									<input
										type="file"
										style={{ display: 'none' }}
										className="form-control-file"
										id="btn-oper-gestPed-integrSaida-exoport"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ModalIntegracaoSaidaExport };
