import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConverterEmBase64 from '../../../../components/functions/converterEmBase64';
import { Switch } from '@mui/material';
import { useFilters } from 'react-datalist-input';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';

const NovaEntidade = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN') || '';
	const idusuario = sessionStorage.getItem('IsIDFKL');
	const codfilial = sessionStorage.getItem('IsIDFilialPadraoFKL');
	const cod_filial = sessionStorage.getItem('IsCodFilialPadraoFKL');

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  

	const navigate = useNavigate();
	const [options, setOptions] = useState([])

	const [inputs, setInputs] = useState({
		codentidade: '',
		coderp: '',
		fantasia: '',
		cnpj: '',
		razao_social: '',
		ie: '',
		logradouro: '',
		complemento: '',
		bairro: '',
		cidade: '',
		uf: '',
		cep: '',
		pais: '',
		contato: '',
		fone_contato: '',
		email_contado: '',
		lista_email: '',
		def_01: '',
		def_02: '',
		def_03: '',
		def_04: '',
		def_05: '',
		anotacoes: '',
		coddepositante: 0,
		fantasia_depositante: '',
		transportadora: '',
		codfilial: ''

	});

	const [check, setCheck] = useState(false);
	const handleChecked = (e: any) => {
		setCheck(e.target.checked);
	};

	const [depositante, setDepositante] = useState(0);

	const handleChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	};
	const handleDepositante = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setDepositante(value)

	}

	//#region Carregar/Selecionar Filial
	const [filial, setFilial] = useState([])
	const [selecionafilial, setSelecionaFilial] = useState(0);
	const capturaFilial = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaFilial(value);
	};

	//#region Requisição de dados na api - CARREGAR filial
	const carregarDadosFilial = async () => {
		const response = await axios.get(Api + `/entidade/filial/json/?&auth=${token}&usuario=${idusuario}`);
		setFilial(response.data);
	};
	const BuscarFilial = () => {
		carregarDadosFilial();
	};
	//#endregion
	//#endregion
	//#region const para CADASTRAR uma Entidade na API - POST
	const postDados = (e: any) => {
		try {
			axios.post(Api + `/entidade/?&auth=${token}`, {
				codentidade: inputs.codentidade.toUpperCase(),
				coderp: inputs.coderp.toUpperCase(),
				fantasia: inputs.fantasia.toUpperCase(),
				cnpj: inputs.cnpj.toUpperCase(),
				razao_social: inputs.razao_social.toUpperCase(),
				ie: inputs.ie.toUpperCase(),
				logradouro: inputs.logradouro.toUpperCase(),
				complemento: inputs.complemento.toUpperCase(),
				bairro: inputs.bairro.toUpperCase(),
				cidade: inputs.cidade.toUpperCase(),
				uf: inputs.uf.toUpperCase(),
				cep: inputs.cep.toUpperCase(),
				pais: inputs.pais.toUpperCase(),
				contato: inputs.contato.toUpperCase(),
				fone_contato: inputs.fone_contato.toUpperCase(),
				email_contado: inputs.email_contado.toLowerCase(),
				lista_email: inputs.lista_email,
				def_01: inputs.def_01.toUpperCase(),
				def_02: inputs.def_02.toUpperCase(),
				def_03: inputs.def_03.toUpperCase(),
				def_04: inputs.def_04.toUpperCase(),
				def_05: inputs.def_05.toUpperCase(),
				anotacoes: inputs.anotacoes.toUpperCase(),
				coddepositante: depositante,
				transportadora: inputs.transportadora,
				codfilial: cod_filial


			}).then((response) => {
				return response.data
			}).then(data => {
				if (data.status == 201) {
					setTimeout(() => navigate(-1), 500);
					setShowCarregando(false)
				}
				else {
					setShowCarregando(false)
					toast.error(data.message, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "dark",
					})
					//alert(data.message)
				}
			}).catch(err => {
				setShowCarregando(false)
				toast.error(err, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "dark",
				})
			})
		} catch (error) {
			setShowCarregando(false);
			toast.error(`${error}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
		}

	};
	//#endregion
	const _id = useParams();

	//#endregion
	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (check === true) {
			inputs.transportadora = '1';
		}
		else if (check === false) {
			inputs.transportadora = '0';
		}

		if (_id.id === 'novaentidade') {
			//alert('Você vai incluir!');
			postDados(inputs);
		}
	}


	//#region -> Novo Desenvolvimento
	const [CNPJ, setCNPJ] = useState<string>()
	const [razaoSocial, setRazaoSocial] = useState<string>()
	const [nomeFantasia, setNomeFantasia] = useState<string>()
	const [inscricaoEstadual, setInscricaoEstadual] = useState<string>()
	const [pais, setPais] = useState<string>()
	const [logradouro, setLogradouro] = useState<string>()
	const [contato, setContato] = useState<string>()
	const [numero, setNumero] = useState<number>()
	const [complemento, setComplemento] = useState<string>()
	const [bairro, setBairro] = useState<string>()
	const [cidade, setCidade] = useState<string>()
	const [estado, setEstado] = useState<string>()
	const [telContato, setTelContato] = useState<string>()
	const [cep, setCep] = useState<string>()
	const [regimeFiscal, setRegimeFiscal] = useState<any>()
	const [indContribuinte, setIndContribuinte] = useState<any>()
	const [transportadora, setTranspordadora] = useState<boolean>(false)
	const [email, setEmail] = useState<string>()

	const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

	const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")
	const usuarioLogado = sessionStorage.getItem("IsUserFKL")
	const params = useParams()
	const acao = params.acao
	const idEntidade = params.id

	const dadosDeEnvio = {
		idFilial,
		idEntidade,
		CNPJ,
		razaoSocial,
		nomeFantasia,
		inscricaoEstadual,
		pais,
		logradouro,
		numero,
		complemento,
		bairro,
		cidade,
		estado,
		cep,
		regimeFiscal,
		indContribuinte,
		transportadora,
		email,
		usuarioLogado
	}

	function inserirEntidade() {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/inserir/entidade`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}
		).then(function (resposta) {
			toast.success(resposta.data.message)

			setTimeout(() => {
				navigate("/config/cadast/entidade")
			}, 2000);
		}).catch(function (erro) {
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	//#region -> Editar
	function carregarDados() {
		setMostraModalCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/dados/${idEntidade}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregando(false)
			setCNPJ(resposta.data.data[0].cnpj)
			setRazaoSocial(resposta.data.data[0].razao_social)
			setNomeFantasia(resposta.data.data[0].fantasia)
			setInscricaoEstadual(resposta.data.data[0].ie)
			setPais(resposta.data.data[0].pais)
			setLogradouro(resposta.data.data[0].logradouro)
			setNumero(resposta.data.data[0].numero)
			setComplemento(resposta.data.data[0].complemento)
			setBairro(resposta.data.data[0].bairro)
			setCidade(resposta.data.data[0].cidade)
			setEstado(resposta.data.data[0].uf)
			setEmail(resposta.data.data[0].email)
			setCep(resposta.data.data[0].cep)
			setRegimeFiscal(resposta.data.data[0].crt)
			setIndContribuinte(resposta.data.data[0].indiedest)
			setTranspordadora(resposta.data.data[0].transportadora)
		}).catch(function (erro) {
			setMostraModalCarregando(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}


	function editarEntidade() {
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/editar/entidade`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}
		).then(function (resposta) {
			toast.success(resposta.data.message)

			setTimeout(() => {
				navigate("/config/cadast/entidade")
			}, 2000);
		}).catch(function (erro) {
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	useEffect(() => {
		if (acao != 'criar') {
			carregarDados()
		}
	}, [])

	async function carregarDadosCNPJ(CNPJ: any) {
		setMostraModalCarregando(true)
		if (CNPJ.length == 14) {
			axios.get(`https://publica.cnpj.ws/cnpj/${CNPJ}`).then(function (response) {
				setMostraModalCarregando(false)
				setCNPJ(response.data.estabelecimento.cnpj)
				setNomeFantasia(response.data.estabelecimento.nome_fantasia)
				setLogradouro(response.data.estabelecimento.logradouro)
				setNumero(response.data.estabelecimento.numero)
				setComplemento(response.data.estabelecimento.complemento)
				setBairro(response.data.estabelecimento.bairro)
				setCidade(response.data.estabelecimento.cidade.nome)
				setCep(response.data.estabelecimento.cep)
				setEstado(response.data.estabelecimento.estado.sigla)
				setPais(response.data.estabelecimento.pais.nome)
				setRazaoSocial(response.data.razao_social)
				setInscricaoEstadual(response.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual)
			}).catch(function (erro) {
				setMostraModalCarregando(false)
				const status = erro.response.status
				if (status == 403 || status == 401) {
					toast.error("Acesso negado, faça seu login novamente")
					navigate("/login")
				} else {
					toast.error("CNPJ não encontrado")
					setCNPJ("")
					setNomeFantasia("")
					setLogradouro("")
					setNumero(0)
					setComplemento("")
					setBairro("")
					setCidade("")
					setCep("")
					setEstado("")
					setPais("")
					setRazaoSocial("")
					setInscricaoEstadual("")
				}
			})
		}
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">{acao == 'criar' ? 'Nova Entidade' : acao == 'editar' ? 'Editar Entidade' : 'Visualizar Entidade'}</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<form onSubmit={function (e) {
								e.preventDefault()
								if (acao == 'criar') {
									inserirEntidade()
								} else if (acao == 'editar') {
									editarEntidade()
								}
							}}>
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralEntidade" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralEntidade">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Codigo da filial" id="codfilial"
															name="codfilial"
															value={idEntidade}
															disabled
														/>
														<label className="form-label">Entidade</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="cnpj"
															name="cnpj"
															value={CNPJ}
															placeholder=''
															onBlur={(event) => carregarDadosCNPJ(event.target.value)}
															onChange={(event) => {
																setCNPJ((event.target.value).replace(/[.\-\/]/g, ''))
															}}
															required />
														<label className="form-label">CNPJ</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="razao_social"
															name="razao_social"
															placeholder=''
															value={razaoSocial}
															onChange={(event) => setRazaoSocial(event.target.value)}
															required
														/>
														<label className="form-label">Razão Social</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="fantasia"
															name="fantasia"
															value={nomeFantasia}
															placeholder=''
															onChange={(event) => setNomeFantasia(event.target.value)}
															required
														/>
														<label className="form-label">Nome Fantasia</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="IE"
															name="IE"
															value={inscricaoEstadual}
															placeholder=''
															onChange={(event) => setInscricaoEstadual(event.target.value)}
															required
														/>
														<label className="form-label">IE</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={pais}
															placeholder=''
															onChange={(event) => setPais(event.target.value)}
														/>
														<label className="form-label">País</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className='col-sm-3'>
													<div className="form-floating mb-1">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="logradouro"
															value={logradouro}
															placeholder=''
															onChange={(event) => setLogradouro(event.target.value)}
														/>
														<label className="form-label">Logradouro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input
															type="number"
															className="form-control bordasInferiorInput text-black " id="contato"
															name="contato"
															placeholder=''
															value={numero}
															onChange={(event) => setNumero(parseInt(event.target.value))}
														/>
														<label className="form-label">Numero</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={complemento}
															onChange={(event) => setComplemento(event.target.value)}
														/>
														<label className="form-label">Complemento</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={bairro}
															onChange={(event) => setBairro(event.target.value)}
														/>
														<label className="form-label">Bairro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="cidade"
															placeholder=''
															value={cidade}
															onChange={(event) => setCidade(event.target.value)}
														/>
														<label className="form-label">Cidade</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={estado}
															onChange={(event) => setEstado(event.target.value)}
														/>
														<label className="form-label">Estado</label>
													</div>
												</div>

											</div>
											<div className='row'>
												<div className="col-12 col-sm-6 col-md-3 mt-1">
													<div className="form-floating">

														<input type="text" className="form-control bordasInferiorInput text-black" id="email"
															name="email"
															placeholder=''
															value={email}
															required
															onChange={(event) => setEmail(event.target.value)}
														/>
														<label className="form-label">E-mail</label>
													</div>
												</div>
												<div className='col-12 col-sm-6 col-md-3 mt-1'>
													<div className="form-floating">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={cep}
															onChange={(event) => setCep(event.target.value)}
														/>
														<label className="form-label">CEP</label>
													</div>
												</div>
												<div className="col-12 col-md-6 mt-1">
													<div className="form-floating">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															name="regime_fiscal"
															value={regimeFiscal}
															onChange={(event) => setRegimeFiscal(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="1">Simples Nacional</option>
															<option value="2">Simples Nacional, excesso sublimite de receita bruta</option>
															<option value="3">Regime Normal</option>
															<option value="4">MEI</option>
														</select>
														<label className="form-label" htmlFor="cfopEntrada">Regime Fiscal</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className="col-12 col-md-6 mt-1">
													<div className="form-floating mb-1">

														<select className="form-select bordasInferiorInput text-black text-capitalize" value={indContribuinte} name="regime_fiscal" onChange={(event) => setIndContribuinte(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="1">Contribuinte</option>
															<option value="2">Não contribuinte</option>
															<option value="3">Isento</option>
														</select>
														<label className="form-label" htmlFor="cfopEntrada">Ind. Contribuinte</label>
													</div>
												</div>
												<div className="col-6 form-check mt-3">
													<label htmlFor="" className="form-label me-2">
														Transportadora
													</label>
													<Switch
														defaultChecked={false}
														checked={transportadora}
														onChange={(event) => setTranspordadora(event.target.checked)}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<ToastContainer newestOnTop />
			</div>

			<ModalCarregando
				mostrar={mostraModalCarregando}
				mensagem='Carregando...'
			/>
		</>
	);
};

export { NovaEntidade };
