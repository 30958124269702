import { useNavigate, useParams } from "react-router-dom"
import ModalCarregando from "../../../../../components/ModalCarregando/ModalCarregando";
import axios from "axios";
import { ITabela } from '../../../../../components/Table/TabelaInterface';
import ClickableTable from '../../../../../components/ClickableTable/ClickableTabl';
import MascaraCNPJ from "../../../../../components/functions/mascaraCNPJ"
import { useEffect, useState } from "react"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mascaraCPF from "../../../../../components/functions/mascaraCPF";
import { xml2json } from 'xml-js';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import ModalConfirmacao from "../../../../../components/ModalDeConfirmacao/ModalConfirmacao";
import LerXml from "../../../../../components/functions/lerXml";
import { BotaoCustomizado } from '../../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';

function ProcessoEntradaForm() {

    const params = useParams()
    const navigate = useNavigate()

    const token = sessionStorage.getItem("IsFKLTOKEN")!
    const id_filial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const id_usuario = sessionStorage.getItem("IsIDUserFKL")!
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")!

    const [showModalCarregando, setShowModalCarregando] = useState(false)

    const [inputsProcesso, setInputsProcesso] = useState({
        id_processo: "",
        dt_chegada: "",
        dt_processo: "",
        dt_inicio: "",
        dt_fim: "",
        dt_liberacao: "",
        modalidade: "",
        statusProcesso: params.id_processo == "novo" ? "Aberto" : "",
        id_depositante: "",
        criterio: "",
        placaCavalo: "",
        placaCarreta: "",
        tipoVeiculo: "",
        transportadora: "",
        motorista: "",
        docMotorista: "",
        observacoes: ""
    })

    const colunasNotas: ITabela[] = [
        {
            titulo: "N° NFe", acesso: "nf"
        },
        {
            titulo: "Série", acesso: "serie"
        },
        {
            titulo: "CNPJ Emitente", acesso: "cnpj_emit"
        },
        {
            titulo: "Emitente", acesso: "razao_emit"
        },
        {
            titulo: "CNPJ Destinatário", acesso: "cnpj_dest"
        },
        {
            titulo: "Destinatário", acesso: "razao_dest"
        }
    ]

    const [listaDeNotas, setListaDeNotas] = useState([])
    const [listaDeNotasVinculadas, setListaDeNotasVinculadas] = useState([])


    const [itensPorPagina, setItensPorPagina] = useState(10)
    const [itensPorPagina2, setItensPorPagina2] = useState(10)

    const [ListaModalidades, setListaModalidades] = useState([])
    function ConsultarCODsistema(tabela: string, id_filial: string) {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cod/sistema/${tabela}/${id_filial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            if (tabela == "modalidade_proc") {
                setListaModalidades(resposta.data.codigos)
            }
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    function atualizarInputsProcesso(value: any, propriedade: string) {

        setInputsProcesso(function (InputsProcessoAtual) {
            return {
                ...InputsProcessoAtual,
                [propriedade]: value
            }
        })
    }

    const [ListaDepositantes, setListaDepositantes] = useState([])
    function carregarDepositantes() {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/dep/processo/${id_usuario}/${id_filial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            setListaDepositantes(resposta.data.depositantes)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [ListaVeiculos, setListaVeiculos] = useState([])
    function carregarVeiculos(id_filial: string) {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/veiculos/para/processo/${id_filial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            setListaVeiculos(resposta.data.veiculos)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [ListaMotoristas, setListaMotoristas] = useState([])
    function setInformacoesVeiculo(placaCavalo: string) {

        const veiculoSelecionado: any = ListaVeiculos.find(function (veiculo: any) {

            return veiculo.placa_1 == placaCavalo
        })
        if (veiculoSelecionado) {

            atualizarInputsProcesso(veiculoSelecionado.placa_2, "placaCarreta")

            setShowModalCarregando(true)
            //carrega tipos de veiculo
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/lista/veiculos/tipoveiculo/${id_filial}`, {
                headers: {
                    authorization: token
                }
            }).then(function (resposta) {

                const tiposDeVeiculos = resposta.data.tipoveiculo
                const TipoVeiculoSelecionado = tiposDeVeiculos.find(function (veiculo: any) {

                    return veiculo.id_codsis_det == veiculoSelecionado.tipo_veiculo
                })

                atualizarInputsProcesso(TipoVeiculoSelecionado.descricao, "tipoVeiculo")

                //carrega transportadora
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/dados/${veiculoSelecionado.transportadora}/${id_filial}`, {
                    headers: {
                        authorization: token
                    }
                }).then(function (resposta) {

                    const transportadora = resposta.data.data[0]
                    atualizarInputsProcesso(transportadora.razao_social, "transportadora")

                    //carrega motoristas
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/motoristas/para/processo/${id_filial}/${veiculoSelecionado.id_veiculo}`, {
                        headers: {
                            authorization: token
                        }
                    }).then(function (resposta) {

                        setListaMotoristas(resposta.data.motoristas)
                        setShowModalCarregando(false)
                    }).catch(function (erro) {

                        setShowModalCarregando(false)
                        toast.error(erro.response.message || erro.message, {
                            autoClose: 900
                        })
                    })
                }).catch(function (erro) {

                    setShowModalCarregando(false)
                    toast.error(erro.response.message || erro.message, {
                        autoClose: 900
                    })
                })
            }).catch(function (erro) {

                setShowModalCarregando(false)
                toast.error(erro.response.message || erro.message, {
                    autoClose: 900
                })
            })
        }
        else {

            atualizarInputsProcesso("", "placaCarreta")
            atualizarInputsProcesso("", "tipoVeiculo")
        }
    }

    function CarregarProcesso(id_filial: string, id_processo: string) {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/unico/processo/entrada/${id_filial}/${id_processo}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            const processo = resposta.data.processo

            setInputsProcesso({
                id_processo: processo.id_processo,
                dt_chegada: processo.dt_chegada.split("T")[0],
                dt_processo: processo.dt_processo ? processo.dt_processo.split("T")[0] : "",
                dt_inicio: processo.dt_inicio ? processo.dt_inicio.split("T")[0] : "",
                dt_fim: processo.dt_fim ? processo.processo.dt_fim.split("T")[0] : "",
                dt_liberacao: processo.dt_liberacao ? processo.dt_liberacao.split("T")[0] : "",
                modalidade: processo.modalidade,
                statusProcesso: params.id_processo == "novo" ? "Abertura" : processo.status,
                id_depositante: processo.id_cliente,
                criterio: processo.criterio,
                placaCavalo: processo.placa_1,
                placaCarreta: processo.placa_2,
                tipoVeiculo: processo.tipo_veiculo,
                transportadora: processo.transportadora,
                motorista: processo.motorista,
                docMotorista: processo.doc_motorista,
                observacoes: processo.obs
            })

            CarregarNFsVinculadas(id_filial, params.id_processo!, processo.id_cliente)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            console.log(erro)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    useEffect(function () {

        if (params.id_processo != "novo" && params.acao != "criar") {
            CarregarProcesso(id_filial, params.id_processo!)
        }
        ConsultarCODsistema("modalidade_proc", id_filial)
        carregarVeiculos(id_filial)
        carregarDepositantes()
    }, [])

    useEffect(function () {

        if (inputsProcesso.placaCavalo != "" && params.acao != "acao") {

            setInformacoesVeiculo(inputsProcesso.placaCavalo)
        }
    }, [inputsProcesso.placaCavalo, ListaVeiculos])

    useEffect(function () {

        if (inputsProcesso.docMotorista != "") {
            const motoristaSelecionado: any = ListaMotoristas.find(function (motorista: any) {

                return motorista.cpf == inputsProcesso.docMotorista
            })

            if (motoristaSelecionado) {

                atualizarInputsProcesso(motoristaSelecionado.cpf, "docMotorista")
                atualizarInputsProcesso(motoristaSelecionado.nome, "motorista")
            }
        }
    }, [inputsProcesso.docMotorista])

    function CriarNovoProcesso(id_filial: string, id_cliente: string, usuarioLogado: string) {

        const dados = {
            inputsProcesso,
            usuarioLogado
        }

        setShowModalCarregando(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/novo/processo/${id_filial}/${id_cliente}`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            toast.success(resposta.data.message, {
                autoClose: 900
            })
            setShowModalCarregando(false)
            navigate(`/controle/processo/entrada/editar/${resposta.data.processo.id_processo}`)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    function AtualizarProcesso(id_filial: string, id_cliente: string) {

        const dados = {
            placa_1: inputsProcesso.placaCavalo,
            placa_2: inputsProcesso.placaCarreta,
            tipo_veiculo: inputsProcesso.tipoVeiculo,
            motorista: inputsProcesso.motorista,
            doc_motorista: inputsProcesso.docMotorista,
            transportadora: inputsProcesso.transportadora
        }

        setShowModalCarregando(true)
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/processo/entrada/${id_filial}/${id_cliente}/${params.id_processo}`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            toast.success(resposta.data.message, {
                autoClose: 900
            })
            setShowModalCarregando(false)
            setTimeout(() => {
                navigate(-1)
            }, 1800)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }


    //#region IMPORTAÇÃO DE XML -- INICIO
    const todasNotasFiscais = [
        {
            id_filial: '',
            nfestatus: '',
            nfechave: '',
            nfecuf: '',
            nfenatoper: '',
            nfecfop: '',
            nfemod: '',
            nfeserie: '',
            nfennf: '',
            nfedtemissao: '',
            nfedtsaida: '',
            nfentpnf: '',
            nfeiddest: '',
            nfecmunfg: '',
            tpimp: '',
            nfetpemis: '',
            nfecdv: '',
            nfetpamb: '',
            nfefinnfe: '',
            nfeindfinal: '',
            nfeindpres: '',
            nfeprocemi: '',
            nfeverproc: '',
            nfedhconting: '',
            nfejustconting: '',

            // DadosEmitente '',
            nfeemitcnpj: '',
            nfeemitcpf: '',
            nfeemitnome: '',
            nfeemitfantasia: '',
            nfeemitie: '',
            nfeemitiest: '',
            nfeemitcrt: '',

            // EnderecoEmitente '',
            nfeemitlograd: '',
            nfeemitnumero: '',
            nfeemitcompl: '',
            nfeemitbairro: '',
            nfeemitcmun: '',
            nfeemitmun: '',
            nfeemituf: '',
            nfeemitcep: '',
            nfeemitcpais: '',
            nfeemitpais: '',
            nfeemitfone: '',

            // DadosDestinatario '',
            nfedestcnpj: '',
            nfedestcpf: '',
            nfedestnome: '',
            nfedestindie: '',
            nfedestie: '',
            nfedestisuf: '',
            nfedestim: '',
            nfedestemail: '',

            // EnderecoDestinatario '',
            nfedestlograd: '',
            nfedestnumero: '',
            nfedestcompl: '',
            nfedestbairro: '',
            nfedestcmun: '',
            nfedestmun: '',
            nfedestuf: '',
            nfedestcep: '',
            nfedestcpais: '',
            nfedestpais: '',
            nfedestfone: '',

            //Tributos '',
            nfebcicms: 0,
            nfevicms: 0,
            nfevicmsdeson: 0,
            nfebcicmsst: 0,
            nfevicmsst: 0,
            nfevfcp: 0,
            nfevfcpst: 0,
            nfevfcpstret: 0,
            nfevtotprod: 0,
            nfevfrete: 0,
            nfevseguro: 0,
            nfevdesconto: 0,
            nfevii: 0,
            nfevipi: 0,
            nfevipidevol: 0,
            nfevpis: 0,
            nfevcofins: 0,
            nfevoutros: 0,
            nfevtotnota: 0,
            nfevtottrib: 0,

            //Transporte '',
            transpmodfrete: '',
            transpcnpj: '',
            transpcpf: '',
            transpnome: '',
            transpie: '',
            transpend: '',
            transpmun: '',
            transpuf: '',
            transpvolqtde: 0,
            transpvolespecie: 0,
            transpvolpb: 0,
            transpvolpl: 0,
            transplacre: '',

            //Cobrança '',
            cobrindpag: '',
            cobrtpag: '',
            cobrvpag: 0,

            //Informações '',
            nfeinfadfisco: '',
            nfeinfcpl: '',
            protocol_nprot: '',
            protocol_nrecbto: '',
            protocol_dhrecbto: '',
            protocol_digval: '',
            nfecstat: '',
            nfexmotivo: '',
            frasehomologacao: '',
            frasecontingencia: '',
            /*VALIADR*/
            protcanc: '',
            responsavelcancel: '',
            tpevento: '',
            xevento: '',
            protocol_dtcancel: '',
            nfecce: '',
            protcce: '',
            protocol_dtcce: '',
            responsavelcce: '',
            responsavel: '',
            tipo_geracao: '',
            confirmado_envio_user: '',
            nfedtadicao: '',
            id_nf_emissor: null,
            // id_depositante: '',
            nfeenvioemail: '',
            nfedtenvioemail: '',
            nfeprocessofalta: null,
            id_entidade: null,
            nfeplaca: '',
            nfeplacauf: '',
            produtos: [
                {
                    itemnitem: '',
                    itemcodigo: '',
                    itemean: '',
                    itemncm: '',
                    itemdescricao: '',
                    itemchavenfe: '',
                    itemnnfe: '',
                    itemserie: '',
                    itemcest: '',
                    itemcfop: '',
                    itemucom: '',
                    itemqcom: 0,
                    itemvunit: 0,
                    itemvtotal: 0,
                    itemeantrib: '',
                    itemuntrib: '',
                    itemqtrib: 0,
                    itemvuntrib: 0,
                    itemindtot: '',
                    itemxped: '',
                    itemnlote: '',
                    itemqlote: 0,
                    itemdfab: '',
                    itemdval: '',
                    itemorig: '',
                    itemcst: '',
                    itemmodbc: 0,
                    itembcicms: 0,
                    itempicms: 0,
                    itemvicms: 0,
                    itempredbc: 0,
                    itembcicmsst: 0,
                    itempicmsst: 0,
                    itemvicmsst: 0,
                    itemcenqipi: '',
                    itemcstipi: '',
                    itembcipi: 0,
                    itemvipi: 0,
                    itemipi: 0,
                    itemcstpis: '',
                    itembcpis: 0,
                    itemppis: 0,
                    itemvpis: 0,
                    itemcstcofins: '',
                    itembccofins: 0,
                    itempcofins: 0,
                    itemvcofins: 0,
                    iteminfadprod: '',
                    id_nf_emissor: null,
                    id_filial_emissor: null,
                    id_produto_emissor: null,
                    itemindescala: '',
                    itemcnpjfab: '',
                    itemcbenef: '',
                    itempipi: '',
                },
            ],
        },
    ]

    const [ListaNotasImportadas, setListaNotasImportadas] = useState<any>([])
    const [ListaNotasNaoImportadas, setListaNotasNaoImportadas] = useState<any>([])

    const [showModalListaNF, setShowModalListaNF] = useState(false)

    const importarXmlConverterJson = async (xml: any) => {
        setShowModalCarregando(true)

        const arquivos = xml || null;

        if (arquivos) {
            for (let i = 0; i < arquivos.length; i++) {

                const file = arquivos[i];


                const xmlConteudo = await LerXml(file)

                const reader = new FileReader();
                reader.onload = () => {

                    const xmlString = reader.result as string;

                    const json = xml2json(xmlString, { compact: true, spaces: 2 });
                    const nota = JSON.parse(json);


                    const modeloHomologacao = nota.NFe?.infNFe?.ide?.mod?._text;
                    const modelo = nota.nfeProc?.NFe?.infNFe?.ide?.mod?._text;

                    if (modeloHomologacao === '55') {
                        let itens = nota.NFe.infNFe.det;

                        // Verifica se 'itens' é um array; se não for, converte em um array
                        if (!Array.isArray(itens)) {
                            itens = [itens];
                        }
                        const qtdItens = itens.length;
                        const produtos = [];

                        if (qtdItens === 1) {
                            const prod = {
                                itemnitem: nota.NFe.infNFe.det._attributes.nItem || '',
                                itemcodigo: nota.NFe.infNFe.det.prod.cProd?._text || '',
                                itemean: nota.NFe.infNFe.det.prod.cEAN?._text || '',
                                itemncm: nota.NFe.infNFe.det.prod.NCM?._text || '',
                                itemdescricao: nota.NFe.infNFe.det.prod.xProd?._text || '',
                                itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
                                itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
                                itemserie: nota.NFe.infNFe.ide.serie?._text || '',
                                itemcest: nota.NFe.infNFe.det.prod.CEST?._text || '',
                                itemcfop: nota.NFe.infNFe.det.prod.CFOP?._text || '',
                                itemucom: nota.NFe.infNFe.det.prod.uCom?._text || '',
                                itemqcom: parseFloat(nota.NFe.infNFe.det.prod.qCom?._text || 0),
                                itemvunit: parseFloat(nota.NFe.infNFe.det.prod.vUnCom?._text || 0),
                                itemvtotal: parseFloat(nota.NFe.infNFe.det.prod.vProd?._text || 0),
                                itemeantrib: nota.NFe.infNFe.det.prod.cEANTrib?._text || '',
                                itemuntrib: nota.NFe.infNFe.det.prod.uTrib?._text || '',
                                itemqtrib: parseFloat(nota.NFe.infNFe.det.prod.qTrib?._text || 0),
                                itemvuntrib: parseFloat(nota.NFe.infNFe.det.prod.vUnTrib?._text || 0),
                                itemindtot: nota.NFe.infNFe.det.prod.indTot?._text || '',
                                itemxped: nota.NFe.infNFe.det.prod.xPed?._text || '',
                                itemnlote: nota.NFe.infNFe.det.prod.nLote?._text || '',
                                itemqlote: parseFloat(nota.NFe.infNFe.det.prod.qLote?._text || 0),
                                itemdfab: nota.NFe.infNFe.det.prod.dFab?._text || '',
                                itemdval: nota.NFe.infNFe.det.prod.dVal?._text || '',
                                itemorig:
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
                                    '',
                                itemcst:
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
                                    '',
                                itemmodbc:
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
                                    0,
                                itembcicms: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
                                    0
                                ),
                                itempicms: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
                                    0
                                ),
                                itemvicms: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
                                    0
                                ),
                                itempredbc: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
                                    0
                                ),
                                itembcicmsst: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
                                    0
                                ),
                                itempicmsst: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
                                    0
                                ),
                                itemvicmsst: parseFloat(
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
                                    nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
                                    0
                                ),
                                itemcenqipi: nota.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
                                itemcstipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
                                itembcipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
                                itemvipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
                                itemipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
                                itemcstpis: nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
                                itembcpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
                                itemppis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
                                itemvpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
                                itemcstcofins: nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
                                itembccofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
                                itempcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
                                itemvcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
                                iteminfadprod: nota.NFe.infNFe.det.infAdProd?._text || '',
                                id_nf_emissor: null,
                                id_filial_emissor: null,
                                id_produto_emissor: null,
                                itemindescala: nota.NFe.infNFe.det.prod.indEscala?._text || '',
                                itemcnpjfab: nota.NFe.infNFe.det.prod?.CNPJFab?._text || '',
                                itemcbenef: nota.NFe.infNFe.det.prod?.cBenef?._text || '',
                                itempipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
                                pfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.pFCP || 0,
                                vfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.vFCP || 0
                            };
                            produtos.push(prod);
                        } else {
                            for (let i = 0; i < qtdItens; i++) {
                                const prod = {
                                    itemnitem: nota.NFe.infNFe.det[i]._attributes.nItem || '',
                                    itemcodigo: nota.NFe.infNFe.det[i].prod.cProd?._text || '',
                                    itemean: nota.NFe.infNFe.det[i].prod.cEAN?._text || '',
                                    itemncm: nota.NFe.infNFe.det[i].prod.NCM?._text || '',
                                    itemdescricao: nota.NFe.infNFe.det[i].prod.xProd?._text || '',
                                    itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
                                    itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
                                    itemserie: nota.NFe.infNFe.ide.serie?._text || '',
                                    itemcest: nota.NFe.infNFe.det[i].prod.CEST?._text || '',
                                    itemcfop: nota.NFe.infNFe.det[i].prod.CFOP?._text || '',
                                    itemucom: nota.NFe.infNFe.det[i].prod.uCom?._text || '',
                                    itemqcom: parseFloat(nota.NFe.infNFe.det[i].prod.qCom?._text || 0),
                                    itemvunit: parseFloat(nota.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
                                    itemvtotal: parseFloat(nota.NFe.infNFe.det[i].prod.vProd?._text || 0),
                                    itemeantrib: nota.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
                                    itemuntrib: nota.NFe.infNFe.det[i].prod.uTrib?._text || '',
                                    itemqtrib: parseFloat(nota.NFe.infNFe.det[i].prod.qTrib?._text || 0),
                                    itemvuntrib: parseFloat(nota.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
                                    itemindtot: nota.NFe.infNFe.det[i].prod.indTot?._text || '',
                                    itemxped: nota.NFe.infNFe.det[i].prod.xPed?._text || '',
                                    itemnlote: nota.NFe.infNFe.det[i].prod.nLote?._text || '',
                                    itemqlote: parseFloat(nota.NFe.infNFe.det[i].prod.qLote?._text || 0),
                                    itemdfab: nota.NFe.infNFe.det[i].prod.dFab?._text || '',
                                    itemdval: nota.NFe.infNFe.det[i].prod.dVal?._text || '',
                                    itemorig:
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
                                        '',
                                    itemcst:
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
                                        '',
                                    itemmodbc:
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
                                        0,
                                    itembcicms: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
                                        0
                                    ),
                                    itempicms: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
                                        0
                                    ),
                                    itemvicms: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
                                        0
                                    ),
                                    itempredbc: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
                                        0
                                    ),
                                    itembcicmsst: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
                                        0
                                    ),
                                    itempicmsst: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
                                        0
                                    ),
                                    itemvicmsst: parseFloat(
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
                                        nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
                                        0
                                    ),
                                    itemcenqipi: nota.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
                                    itemcstipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
                                    itembcipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
                                    itemvipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
                                    itemipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
                                    itemcstpis: nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
                                    itembcpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
                                    itemppis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
                                    itemvpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
                                    itemcstcofins: nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
                                    itembccofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
                                    itempcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
                                    itemvcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
                                    iteminfadprod: nota.NFe.infNFe.det[i].infAdProd?._text || '',
                                    id_nf_emissor: null,
                                    id_filial_emissor: null,
                                    id_produto_emissor: null,
                                    itemindescala: nota.NFe.infNFe.det[i].prod.indEscala?._text || '',
                                    itemcnpjfab: nota.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
                                    itemcbenef: nota.NFe.infNFe.det[i].prod?.cBenef?._text || '',
                                    itempipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
                                    pfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.pFCP || 0,
                                    vfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.vFCP || 0
                                };
                                produtos.push(prod);
                            }
                        }
                        const dadosNFe = {
                            // DadosNota
                            id_filial: id_filial,
                            nfestatus: nota.protNFe?.infProt?.cStat?._text || '',
                            nfechave: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
                            nfecuf: nota.NFe.infNFe.ide.cUF?._text || '',
                            nfenatoper: nota.NFe.infNFe.ide.natOp?._text || '',
                            nfecfop: nota.NFe.infNFe.ide.CFOP?._text || '',
                            nfemod: nota.NFe.infNFe.ide.mod?._text || '',
                            nfeserie: nota.NFe.infNFe.ide.serie?._text || '',
                            nfennf: nota.NFe.infNFe.ide.nNF?._text || '',
                            nfedtemissao: nota.NFe.infNFe.ide.dhEmi?._text || '',
                            nfedtsaida: nota.NFe.infNFe.ide.dhSaiEnt?._text || '',
                            nfentpnf: nota.NFe.infNFe.ide.tpNF?._text || '',
                            nfeiddest: nota.NFe.infNFe.ide.iDest?._text || '',
                            nfecmunfg: nota.NFe.infNFe.ide.cMunFG?._text || '',
                            tpimp: nota.NFe.infNFe.ide.tpImp?._text || '',
                            nfetpemis: nota.NFe.infNFe.ide.tpEmis?._text || '',
                            nfecdv: nota.NFe.infNFe.ide.cDV?._text || '',
                            nfetpamb: nota.NFe.infNFe.ide.tpAmb?._text || '',
                            nfefinnfe: nota.NFe.infNFe.ide.finNFe?._text || '',
                            nfeindfinal: nota.NFe.infNFe.ide.indFinal?._text || '',
                            nfeindpres: nota.NFe.infNFe.ide.indPres?._text || '',
                            nfeprocemi: nota.NFe.infNFe.ide.procEmi?._text || '',
                            nfeverproc: nota.NFe.infNFe.ide.verProc?._text || '',
                            nfedhconting: nota.NFe.infNFe.ide.dhCont?._text || '',
                            nfejustconting: nota.NFe.infNFe.ide.xJust?._text || '',

                            // DadosEmitente
                            nfeemitcnpj: nota.NFe.infNFe.emit.CNPJ?._text || '',
                            nfeemitcpf: nota.NFe.infNFe.emit.CPF?._text || '',
                            nfeemitnome: nota.NFe.infNFe.emit.xNome?._text || '',
                            nfeemitfantasia: nota.NFe.infNFe.emit.xFant?._text || '',
                            nfeemitie: nota.NFe.infNFe.emit.IE?._text || '',
                            nfeemitiest: nota.NFe.infNFe.emit.IEST?._text || '',
                            nfeemitcrt: nota.NFe.infNFe.emit.CRT?._text || '',

                            // EnderecoEmitente
                            nfeemitlograd: nota.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
                            nfeemitnumero: nota.NFe.infNFe.emit.enderEmit.nro?._text || '',
                            nfeemitcompl: nota.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
                            nfeemitbairro: nota.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
                            nfeemitcmun: nota.NFe.infNFe.emit.enderEmit.cMun?._text || '',
                            nfeemitmun: nota.NFe.infNFe.emit.enderEmit.xMun?._text || '',
                            nfeemituf: nota.NFe.infNFe.emit.enderEmit.UF?._text || '',
                            nfeemitcep: nota.NFe.infNFe.emit.enderEmit.CEP?._text || '',
                            nfeemitcpais: nota.NFe.infNFe.emit.enderEmit.cPais?._text || '',
                            nfeemitpais: nota.NFe.infNFe.emit.enderEmit.xPais?._text || '',
                            nfeemitfone: nota.NFe.infNFe.emit.enderEmit.fone?._text || '',

                            // DadosDestinatario
                            nfedestcnpj: nota.NFe.infNFe.dest.CNPJ?._text || '',
                            nfedestcpf: nota.NFe.infNFe.dest.CPF?._text || '',
                            nfedestnome: nota.NFe.infNFe.dest.xNome?._text || '',
                            nfedestindie: nota.NFe.infNFe.dest.indIEDest?._text || '',
                            nfedestie: nota.NFe.infNFe.dest.IE?._text || '',
                            nfedestisuf: nota.NFe.infNFe.dest.ISUF?._text || '',
                            nfedestim: nota.NFe.infNFe.dest.IM?._text || '',
                            nfedestemail: nota.NFe.infNFe.dest.email?._text || '',

                            // EnderecoDestinatario
                            nfedestlograd: nota.NFe.infNFe.dest.enderDest.xLgr?._text || '',
                            nfedestnumero: nota.NFe.infNFe.dest.enderDest.nro?._text || '',
                            nfedestcompl: nota.NFe.infNFe.dest.enderDest.xCpl?._text || '',
                            nfedestbairro: nota.NFe.infNFe.dest.enderDest.xBairro?._text || '',
                            nfedestcmun: nota.NFe.infNFe.dest.enderDest.cMun?._text || '',
                            nfedestmun: nota.NFe.infNFe.dest.enderDest.xMun?._text || '',
                            nfedestuf: nota.NFe.infNFe.dest.enderDest.UF?._text || '',
                            nfedestcep: nota.NFe.infNFe.dest.enderDest.CEP?._text || '',
                            nfedestcpais: nota.NFe.infNFe.dest.enderDest.cPais?._text || '',
                            nfedestpais: nota.NFe.infNFe.dest.enderDest.xPais?._text || '',
                            nfedestfone: nota.NFe.infNFe.dest.enderDest.fone?._text || '',

                            //Tributos/Totais
                            nfebcicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
                            nfevicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
                            nfevicmsdeson: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
                            nfebcicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
                            nfevicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vST?._text || 0),
                            nfevfcp: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
                            nfevfcpst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
                            nfevfcpstret: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
                            nfevtotprod: parseFloat(nota.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
                            nfevfrete: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
                            nfevseguro: parseFloat(nota.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
                            nfevdesconto: parseFloat(nota.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
                            nfevii: parseFloat(nota.NFe.infNFe.total.ICMSTot.vII?._text || 0),
                            nfevipi: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
                            nfevipidevol: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
                            nfevpis: parseFloat(nota.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
                            nfevcofins: parseFloat(nota.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
                            nfevoutros: parseFloat(nota.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
                            nfevtotnota: parseFloat(nota.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
                            nfevtottrib: parseFloat(nota.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

                            //Transporte nota.nfeProc.NFe.infNFe.transp

                            //nota.nfeProc.NFe.infNFe.transp.transporta?.xNome._text
                            transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
                            transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
                            transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
                            transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
                            transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
                            transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
                            transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
                            transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
                            transpvolqtde: parseFloat(nota.NFe.infNFe.transp?.vol?.qVol?._text || 0),
                            transpvolespecie: parseFloat(nota.NFe.infNFe.transp?.vol?.esp?._text || 0),
                            transpvolpb: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
                            transpvolpl: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
                            transplacre: nota.NFe.infNFe.transp?.vol?.Lacres?._text || '',

                            //Cobrança
                            cobrindpag: nota.NFe.infNFe.cobr?.dup?.nDup?._text || '',
                            cobrtpag: nota.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
                            cobrvpag: parseFloat(nota.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

                            //Informações Adicionais
                            nfeinfadfisco: nota.NFe.infNFe.infAdic?.infAdFisco?._text || '',
                            nfeinfcpl: nota.NFe.infNFe.infAdic?.infCpl?._text || '',
                            protocol_nprot: nota.protNFe?.infProt?.nProt?._text || '',
                            protocol_nrecbto: nota.protNFe?.infProt?.nRec?._text || '',
                            protocol_dhrecbto: nota.protNFe?.infProt?.dhRecbto?._text || '',
                            protocol_digval: nota.protNFe?.infProt?.digVal?._text || '',
                            nfecstat: nota.protNFe?.infProt?.cStat?._text || '',
                            nfexmotivo: nota.protNFe?.infProt?.xMotivo?._text || '',
                            frasehomologacao: nota.protNFe?.infProt?.xMotivo?._text || '',
                            frasecontingencia: nota.protNFe?.infProt.xJust?._text || '',
                            /*VALIADR COM FRANKLIN */
                            protcanc: nota.protNFe?.infProt?.cStat?._text || '',
                            responsavelcancel: nota.protNFe?.infProt?.xMotivo?._text || '',
                            tpevento: nota.retEvento?.infEvento?.tpEvento?._text || '',
                            xevento: nota.retEvento?.infEvento?.xEvento?._text || '',
                            protocol_dtcancel: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
                            nfecce: nota.retEvento?.infEvento?.cStat?._text || '',
                            protcce: nota.retEvento?.infEvento?.xMotivo?._text || '',
                            protocol_dtcce: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
                            responsavelcce: nota.retEvento?.infEvento?.xJust?._text || '',
                            responsavel: nota.retEvento?.infEvento?.xNome?._text || '',
                            tipo_geracao: '',
                            confirmado_envio_user: '',
                            nfedtadicao: '',
                            id_nf_emissor: null,
                            //id_depositante: selecionaDepostitante || '',
                            nfeenvioemail: '',
                            nfedtenvioemail: '',
                            nfeprocessofalta: null,
                            id_entidade: null,
                            nfeplaca: '',
                            nfeplacauf: '',
                            produtos: produtos,
                        };

                        todasNotasFiscais.push(dadosNFe);
                    } else if (modelo === '55') {
                        let itens = nota.nfeProc.NFe.infNFe.det;

                        // Verifica se 'itens' é um array; se não for, converte em um array
                        if (!Array.isArray(itens)) {
                            itens = [itens];
                        }
                        const qtdItens = itens.length;

                        const chavenfe = nota.nfeProc.protNFe.infProt.chNFe?._text || '';
                        const produtos = [];
                        if (qtdItens === 1) {
                            const prod = {
                                itemnitem: nota.nfeProc.NFe.infNFe.det._attributes.nItem || '',
                                itemcodigo: nota.nfeProc.NFe.infNFe.det.prod.cProd?._text || '',
                                itemean: nota.nfeProc.NFe.infNFe.det.prod.cEAN?._text || '',
                                itemncm: nota.nfeProc.NFe.infNFe.det.prod.NCM?._text || '',
                                itemdescricao: nota.nfeProc.NFe.infNFe.det.prod.xProd?._text || '',
                                itemchavenfe: chavenfe,
                                itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
                                itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
                                itemcest: nota.nfeProc.NFe.infNFe.det.prod.CEST?._text || '',
                                itemcfop: nota.nfeProc.NFe.infNFe.det.prod.CFOP?._text || '',
                                itemucom: nota.nfeProc.NFe.infNFe.det.prod.uCom?._text || '',
                                itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qCom?._text || 0),
                                itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnCom?._text || 0),
                                itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vProd?._text || 0),
                                itemeantrib: nota.nfeProc.NFe.infNFe.det.prod.cEANTrib?._text || '',
                                itemuntrib: nota.nfeProc.NFe.infNFe.det.prod.uTrib?._text || '',
                                itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qTrib?._text || 0),
                                itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnTrib?._text || 0),
                                itemindtot: nota.nfeProc.NFe.infNFe.det.prod.indTot?._text || '',
                                itemxped: nota.nfeProc.NFe.infNFe.det.prod.xPed?._text || '',
                                itemnlote: nota.nfeProc.NFe.infNFe.det.prod.nLote?._text || '',
                                itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qLote?._text || 0),
                                itemdfab: nota.nfeProc.NFe.infNFe.det.prod.dFab?._text || '',
                                itemdval: nota.nfeProc.NFe.infNFe.det.prod.dVal?._text || '',
                                itemorig:
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
                                    '',
                                itemcst:
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
                                    '',
                                itemmodbc:
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
                                    0,
                                itembcicms: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
                                    0
                                ),
                                itempicms: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
                                    0
                                ),
                                itemvicms: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
                                    0
                                ),
                                itempredbc: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
                                    0
                                ),
                                itembcicmsst: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
                                    0
                                ),
                                itempicmsst: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
                                    0
                                ),
                                itemvicmsst: parseFloat(
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
                                    nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
                                    0
                                ),
                                itemcenqipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
                                itemcstipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
                                itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
                                itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
                                itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
                                itemcstpis: nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
                                itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
                                itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
                                itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
                                itemcstcofins: nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
                                itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
                                itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
                                itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
                                iteminfadprod: nota.nfeProc.NFe.infNFe.det.infAdProd?._text || '',
                                id_nf_emissor: null,
                                id_filial_emissor: null,
                                id_produto_emissor: null,
                                itemindescala: nota.nfeProc.NFe.infNFe.det.prod.indEscala?._text || '',
                                itemcnpjfab: nota.nfeProc.NFe.infNFe.det.prod?.CNPJFab?._text || '',
                                itemcbenef: nota.nfeProc.NFe.infNFe.det.prod?.cBenef?._text || '',
                                itempipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
                            };

                            produtos.push(prod)
                        } else {
                            for (let i = 0; i < qtdItens; i++) {
                                const prod = {
                                    itemnitem: nota.nfeProc.NFe.infNFe.det[i]._attributes.nItem || '',
                                    itemcodigo: nota.nfeProc.NFe.infNFe.det[i].prod.cProd?._text || '',
                                    itemean: nota.nfeProc.NFe.infNFe.det[i].prod.cEAN?._text || '',
                                    itemncm: nota.nfeProc.NFe.infNFe.det[i].prod.NCM?._text || '',
                                    itemdescricao: nota.nfeProc.NFe.infNFe.det[i].prod.xProd?._text || '',
                                    itemchavenfe: chavenfe,
                                    itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
                                    itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
                                    itemcest: nota.nfeProc.NFe.infNFe.det[i].prod.CEST?._text || '',
                                    itemcfop: nota.nfeProc.NFe.infNFe.det[i].prod.CFOP?._text || '',
                                    itemucom: nota.nfeProc.NFe.infNFe.det[i].prod.uCom?._text || '',
                                    itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qCom?._text || 0),
                                    itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
                                    itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vProd?._text || 0),
                                    itemeantrib: nota.nfeProc.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
                                    itemuntrib: nota.nfeProc.NFe.infNFe.det[i].prod.uTrib?._text || '',
                                    itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qTrib?._text || 0),
                                    itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
                                    itemindtot: nota.nfeProc.NFe.infNFe.det[i].prod.indTot?._text || '',
                                    itemxped: nota.nfeProc.NFe.infNFe.det[i].prod.xPed?._text || '',
                                    itemnlote: nota.nfeProc.NFe.infNFe.det[i].prod.nLote?._text || '',
                                    itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qLote?._text || 0),
                                    itemdfab: nota.nfeProc.NFe.infNFe.det[i].prod.dFab?._text || '',
                                    itemdval: nota.nfeProc.NFe.infNFe.det[i].prod.dVal?._text || '',
                                    itemorig:
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
                                        '',
                                    itemcst:
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
                                        '',
                                    itemmodbc:
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
                                        0,
                                    itembcicms: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
                                        0
                                    ),
                                    itempicms: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
                                        0
                                    ),
                                    itemvicms: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
                                        0
                                    ),
                                    itempredbc: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
                                        0
                                    ),
                                    itembcicmsst: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
                                        0
                                    ),
                                    itempicmsst: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
                                        0
                                    ),
                                    itemvicmsst: parseFloat(
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
                                        nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
                                        0
                                    ),
                                    itemcenqipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
                                    itemcstipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
                                    itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
                                    itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
                                    itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
                                    itemcstpis: nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
                                    itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
                                    itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
                                    itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
                                    itemcstcofins: nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
                                    itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
                                    itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
                                    itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
                                    iteminfadprod: nota.nfeProc.NFe.infNFe.det[i].infAdProd?._text || '',
                                    id_nf_emissor: null,
                                    id_filial_emissor: null,
                                    id_produto_emissor: null,
                                    itemindescala: nota.nfeProc.NFe.infNFe.det[i].prod.indEscala?._text || '',
                                    itemcnpjfab: nota.nfeProc.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
                                    itemcbenef: nota.nfeProc.NFe.infNFe.det[i].prod?.cBenef?._text || '',
                                    itempipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
                                };
                                produtos.push(prod);
                            }
                        }


                        try {
                            //console.log(nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco)
                            const dadosNFe = {
                                // DadosNota
                                id_filial: id_filial,
                                nfestatus: nota.nfeProc.protNFe.infProt.cStat?._text || '',
                                nfechave: chavenfe,
                                nfecuf: nota.nfeProc.NFe.infNFe?.ide?.cUF?._text || '',
                                nfenatoper: nota.nfeProc.NFe.infNFe.ide.natOp?._text || '',
                                nfecfop: nota.nfeProc.NFe.infNFe.ide.CFOP?._text || '',
                                nfemod: nota.nfeProc.NFe.infNFe.ide.mod?._text || '',
                                nfeserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
                                nfennf: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
                                nfedtemissao: nota.nfeProc.NFe.infNFe.ide.dhEmi?._text || '',
                                nfedtsaida: nota.nfeProc.NFe.infNFe.ide.dhSaiEnt?._text || '',
                                nfentpnf: nota.nfeProc.NFe.infNFe.ide.tpNF?._text || '',
                                nfeiddest: nota.nfeProc.NFe.infNFe.ide.iDest?._text || '',
                                nfecmunfg: nota.nfeProc.NFe.infNFe.ide.cMunFG?._text || '',
                                tpimp: nota.nfeProc.NFe.infNFe.ide.tpImp?._text || '',
                                nfetpemis: nota.nfeProc.NFe.infNFe.ide.tpEmis?._text || '',
                                nfecdv: nota.nfeProc.NFe.infNFe.ide.cDV?._text || '',
                                nfetpamb: nota.nfeProc.NFe.infNFe.ide.tpAmb?._text || '',
                                nfefinnfe: nota.nfeProc.NFe.infNFe.ide.finNFe?._text || '',
                                nfeindfinal: nota.nfeProc.NFe.infNFe.ide.indFinal?._text || '',
                                nfeindpres: nota.nfeProc.NFe.infNFe.ide.indPres?._text || '',
                                nfeprocemi: nota.nfeProc.NFe.infNFe.ide.procEmi?._text || '',
                                nfeverproc: nota.nfeProc.NFe.infNFe.ide.verProc?._text || '',
                                nfedhconting: nota.nfeProc.NFe.infNFe.ide.dhCont?._text || '',
                                nfejustconting: nota.nfeProc.NFe.infNFe.ide.xJust?._text || '',

                                // DadosEmitente
                                nfeemitcnpj: nota.nfeProc.NFe.infNFe.emit.CNPJ?._text || '',
                                nfeemitcpf: nota.nfeProc.NFe.infNFe.emit.CPF?._text || '',
                                nfeemitnome: nota.nfeProc.NFe.infNFe.emit.xNome?._text || '',
                                nfeemitfantasia: nota.nfeProc.NFe.infNFe.emit.xFant?._text || '',
                                nfeemitie: nota.nfeProc.NFe.infNFe.emit.IE?._text || '',
                                nfeemitiest: nota.nfeProc.NFe.infNFe.emit.IEST?._text || '',
                                nfeemitcrt: nota.nfeProc.NFe.infNFe.emit.CRT?._text || '',

                                // EnderecoEmitente
                                nfeemitlograd: nota.nfeProc.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
                                nfeemitnumero: nota.nfeProc.NFe.infNFe.emit.enderEmit.nro?._text || '',
                                nfeemitcompl: nota.nfeProc.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
                                nfeemitbairro: nota.nfeProc.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
                                nfeemitcmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.cMun?._text || '',
                                nfeemitmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.xMun?._text || '',
                                nfeemituf: nota.nfeProc.NFe.infNFe.emit.enderEmit.UF?._text || '',
                                nfeemitcep: nota.nfeProc.NFe.infNFe.emit.enderEmit.CEP?._text || '',
                                nfeemitcpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.cPais?._text || '',
                                nfeemitpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.xPais?._text || '',
                                nfeemitfone: nota.nfeProc.NFe.infNFe.emit.enderEmit.fone?._text || '',

                                // DadosDestinatario
                                nfedestcnpj: nota.nfeProc.NFe.infNFe.dest.CNPJ?._text || '',
                                nfedestcpf: nota.nfeProc.NFe.infNFe.dest.CPF?._text || '',
                                nfedestnome: nota.nfeProc.NFe.infNFe.dest.xNome?._text || '',
                                nfedestindie: nota.nfeProc.NFe.infNFe.dest.indIEDest?._text || '',
                                nfedestie: nota.nfeProc.NFe.infNFe.dest.IE?._text || '',
                                nfedestisuf: nota.nfeProc.NFe.infNFe.dest.ISUF?._text || '',
                                nfedestim: nota.nfeProc.NFe.infNFe.dest.IM?._text || '',
                                nfedestemail: nota.nfeProc.NFe.infNFe.dest.email?._text || '',

                                // EnderecoDestinatario
                                nfedestlograd: nota.nfeProc.NFe.infNFe.dest.enderDest.xLgr?._text || '',
                                nfedestnumero: nota.nfeProc.NFe.infNFe.dest.enderDest.nro?._text || '',
                                nfedestcompl: nota.nfeProc.NFe.infNFe.dest.enderDest.xCpl?._text || '',
                                nfedestbairro: nota.nfeProc.NFe.infNFe.dest.enderDest.xBairro?._text || '',
                                nfedestcmun: nota.nfeProc.NFe.infNFe.dest.enderDest.cMun?._text || '',
                                nfedestmun: nota.nfeProc.NFe.infNFe.dest.enderDest.xMun?._text || '',
                                nfedestuf: nota.nfeProc.NFe.infNFe.dest.enderDest.UF?._text || '',
                                nfedestcep: nota.nfeProc.NFe.infNFe.dest.enderDest.CEP?._text || '',
                                nfedestcpais: nota.nfeProc.NFe.infNFe.dest.enderDest.cPais?._text || '',
                                nfedestpais: nota.nfeProc.NFe.infNFe.dest.enderDest.xPais?._text || '',
                                nfedestfone: nota.nfeProc.NFe.infNFe.dest.enderDest.fone?._text || '',

                                //Tributos/Totais
                                nfebcicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
                                nfevicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
                                nfevicmsdeson: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
                                nfebcicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
                                nfevicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vST?._text || 0),
                                nfevfcp: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
                                nfevfcpst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
                                nfevfcpstret: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
                                nfevtotprod: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
                                nfevfrete: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
                                nfevseguro: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
                                nfevdesconto: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
                                nfevii: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vII?._text || 0),
                                nfevipi: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
                                nfevipidevol: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
                                nfevpis: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
                                nfevcofins: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
                                nfevoutros: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
                                nfevtotnota: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
                                nfevtottrib: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

                                //Transporte
                                transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
                                transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
                                transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
                                transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
                                transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
                                transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
                                transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
                                transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
                                transpvolqtde: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.qVol?._text || 0),
                                transpvolespecie: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.esp?._text || 0),
                                transpvolpb: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
                                transpvolpl: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
                                transplacre: nota.nfeProc.NFe.infNFe.transp?.vol?.Lacres?._text || '',

                                //Cobrança
                                cobrindpag: nota.nfeProc.NFe.infNFe.cobr?.dup?.nDup?._text || '',
                                cobrtpag: nota.nfeProc.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
                                cobrvpag: parseFloat(nota.nfeProc.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

                                //Informações Adicionais
                                nfeinfadfisco: nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco?._text || '',
                                nfeinfcpl: nota.nfeProc.NFe.infNFe.infAdic?.infCpl?._text || '',
                                protocol_nprot: nota.nfeProc.protNFe.infProt.nProt?._text || '',
                                protocol_nrecbto: nota.nfeProc.protNFe.infProt.nRec?._text || '',
                                protocol_dhrecbto: nota.nfeProc.protNFe.infProt.dhRecbto?._text || '',
                                protocol_digval: nota.nfeProc.protNFe.infProt.digVal?._text || '',
                                nfecstat: nota.nfeProc.protNFe.infProt.cStat?._text || '',
                                nfexmotivo: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
                                frasehomologacao: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
                                frasecontingencia: nota.nfeProc.protNFe.infProt.xJust?._text || '',
                                /*VALIADR COM FRANKLIN */
                                protcanc: nota.nfeProc.protNFe.infProt.cStat?._text || '',
                                responsavelcancel: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
                                tpevento: nota.nfeProc.retEvento?.infEvento?.tpEvento?._text || '',
                                xevento: nota.nfeProc.retEvento?.infEvento?.xEvento?._text || '',
                                protocol_dtcancel: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
                                nfecce: nota.nfeProc.retEvento?.infEvento?.cStat?._text || '',
                                protcce: nota.nfeProc.retEvento?.infEvento?.xMotivo?._text || '',
                                protocol_dtcce: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
                                responsavelcce: nota.nfeProc.retEvento?.infEvento?.xJust?._text || '',
                                responsavel: nota.nfeProc.retEvento?.infEvento?.xNome?._text || '',
                                tipo_geracao: '',
                                confirmado_envio_user: '',
                                nfedtadicao: '',
                                id_nf_emissor: null,
                                // id_depositante: selecionaDepostitante || '',
                                nfeenvioemail: '',
                                nfedtenvioemail: '',
                                nfeprocessofalta: null,
                                id_entidade: null,
                                nfeplaca: '',
                                nfeplacauf: '',
                                produtos: produtos,
                            };
                            // postXmlNFe(dadosNFe);

                            todasNotasFiscais.push(dadosNFe);
                        } catch (error: any) {
                            toast.error(error.message || error, {
                                autoClose: 900
                            })
                        }
                    }


                    if (i === arquivos.length - 1) {

                        const notas = {
                            todasNotasFiscais,
                            xmlConteudo
                        }
                        postXmlNFe(notas);
                    }
                };
                reader.readAsText(file);
            }
        }

    }

    const postXmlNFe = (nota: any) => {

        const qtdNotas = nota.todasNotasFiscais.length;
        //limpa input
        const inputFile: any = document.querySelector("#ImportNfe")
        inputFile.value = ""

        const ListaNotas_sucesso: any = []
        const ListaNotas_erro: any = []
        for (let i = 0; i < qtdNotas; i++) {

            if (nota.todasNotasFiscais[i].nfechave !== '') {

                setShowModalCarregando(true)
                const dados = {
                    nota: nota.todasNotasFiscais[i],
                    xmlText: nota.xmlConteudo
                }
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/carregar/Nfs/proc/entrada/${inputsProcesso.id_depositante}/${params.id_processo}?usuarioLogado=${usuarioLogado}`, dados, {
                    headers: {
                        authorization: token
                    }
                }).then(function (resposta) {


                    ListaNotas_sucesso.push(resposta.data.nf)
                    if (i == qtdNotas - 1) {

                        setListaNotasNaoImportadas(ListaNotas_erro)
                        setListaNotasImportadas(ListaNotas_sucesso)
                        setShowModalCarregando(false)
                        setShowModalListaNF(true)
                        CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
                    }
                }).catch(function (erro) {

                    if (erro.response.data.nf) {
                        //console.log([...ListaNotasNaoImportadas, erro.response.data.nf])
                        ListaNotas_erro.push(erro.response.data.nf)
                    }

                    if (i == qtdNotas - 1) {
                        setListaNotasNaoImportadas(ListaNotas_erro)
                        setListaNotasImportadas(ListaNotas_sucesso)
                        setShowModalCarregando(false)
                        setShowModalListaNF(true)
                        CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
                    }
                })
            }
        }
    }

    //#region IMPORTAÇÃO DE XML -- FIM

    function CarregarNFsVinculadas(id_filial: string, id_processo: string, id_depositante: string) {


        if (inputsProcesso.modalidade != "") {

            const tipoNota = inputsProcesso.modalidade == "Crossdocking" || inputsProcesso.modalidade == "Re-Entrega" ? "0" : "1"
            setShowModalCarregando(true)
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/nfs/vinculadas/${id_filial}/${id_depositante}/${id_processo}?tipoNota=${tipoNota}`, {
                headers: {
                    authorization: token
                }
            }).then(function (resposta) {

                setShowModalCarregando(false)
                setListaDeNotasVinculadas(resposta.data.notas)
            }).catch(function (erro) {

                setShowModalCarregando(false)
                toast.error(erro.response.data.message || erro.message, {
                    autoClose: 900
                })
            })
        }
    }

    useEffect(function () {

        if (params.acao != "criar" && params.id_processo != "novo") {

            CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
        }
    }, [inputsProcesso.modalidade])

    const [ListaDeItensNota, setListaDeItensNota] = useState([])
    const [showModalProduto, setShowModalProduto] = useState(false)
    const colunasItensNotaCross: ITabela[] = [
        {
            titulo: "Item", acesso: "nitem"
        },
        {
            titulo: "Cfop", acesso: "cfop"
        },
        {
            titulo: "Ncm", acesso: "ncm"
        },
        {
            titulo: "Código", acesso: "codigo"
        },
        {
            titulo: "Gtin", acesso: "gtin"
        },
        {
            titulo: "Descrição", acesso: "descricao"
        },
        {
            titulo: "Qtde.Com", acesso: "qtd_com"
        },
        {
            titulo: "Unid.Com", acesso: "und_com"
        },
        {
            titulo: "Valor Unit.", acesso: "vlr_unit"
        },
        {
            titulo: "Valor Total -", acesso: "vlr_prod"
        },
        {
            titulo: "Cest", acesso: "cest"
        },
        {
            titulo: "Gtin Tib", acesso: "gtintrib"
        },
        {
            titulo: "Unid.Trib", acesso: "qtrib"
        },
        {
            titulo: "Valor Unit.Trib", acesso: "vuntrib"
        }
    ]
    const colunasItensNota: ITabela[] = [
        {
            titulo: "Item", acesso: "nitem"
        },
        {
            titulo: "Cfop", acesso: "cfop"
        },
        {
            titulo: "Ncm", acesso: "ncm"
        },
        {
            titulo: "Código", acesso: "codigo"
        },
        {
            titulo: "Gtin", acesso: "gtin"
        },
        {
            titulo: "Descrição", acesso: "descricao"
        },
        {
            titulo: "Qtde.Com", acesso: "qtd_com"
        },
        {
            titulo: "Unid.Com", acesso: "und_com"
        },
        {
            titulo: "Valor Unit.", acesso: "vlr_unit"
        },
        {
            titulo: "Valor Total -", acesso: "vlr_prod"
        },
        {
            titulo: "Cest", acesso: "cest"
        },
        {
            titulo: "Gtin Tib", acesso: "gtintrib"
        },
        {
            titulo: "Unid.Trib", acesso: "qtrib"
        },
        {
            titulo: "Valor Unit.Trib", acesso: "vuntrib"
        },
        {
            titulo: "Cst Icms", acesso: "cst_icms"
        },
        {
            titulo: "Base Icms", acesso: "vbc_icms"
        },
        {
            titulo: "%Icms", acesso: "p_icms"
        },
        {
            titulo: "Valor Icms", acesso: "v_icms"
        },
        {
            titulo: "Cód.Enq.Ipi", acesso: "cenq_ipi"
        },
        {
            titulo: "Cst Ipi", acesso: "cst_ipi"
        },
        {
            titulo: "Base Ipi", acesso: "vbc_ipi"
        },
        {
            titulo: "% Ipi", acesso: "p_ipi"
        },
        {
            titulo: "Valor Ipi", acesso: "v_ipi"
        },
        {
            titulo: "Cst Pis", acesso: "vbc_pis"
        },
        {
            titulo: "Base Pis", acesso: "vbc_pis"
        },
        {
            titulo: "% Pis", acesso: "p_pis"
        },
        {
            titulo: "Valor Pis", acesso: "v_pis"
        },
        {
            titulo: "Cst Cofins", acesso: "cst_cofins"
        },
        {
            titulo: "Base Cofins", acesso: "vbc_cofins"
        },
        {
            titulo: "% Cofins", acesso: "p_cofins"
        },
        {
            titulo: "Valor Cofins", acesso: "v_cofins"
        }
    ]
    function CarregarItensDaNota(id_filial: string, chave: string) {

        setShowModalCarregando(true)

        const tipoNota = inputsProcesso.modalidade == "Crossdocking" || inputsProcesso.modalidade == "Re-Entrega" ? "0" : "1"

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/itens/nota/${id_filial}/${chave}?tipoNota=${tipoNota}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            setListaDeItensNota(resposta.data.itens)
            setShowModalProduto(true)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            setShowModalProduto(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }


    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [notaAExcluir, setNotaAexcluir] = useState<any>({})
    function DeletarNF(id_filial: string, chave: string) {

        setShowModalConfirmacao(false)
        setShowModalCarregando(true)
        if (inputsProcesso.modalidade == "Crossdocking" || inputsProcesso.modalidade == "Re-Entrega") {

            axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/nfe/cross/${id_filial}/${chave}/${usuarioLogado}`, {
                headers: {
                    authorization: token
                }
            }).then(function (resposta) {

                setShowModalCarregando(false)
                CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
                toast.success(resposta.data.message, {
                    autoClose: 900
                })
            }).catch(function (erro) {

                setShowModalCarregando(false)
                CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
                toast.error(erro.response.data.message || erro.message, {
                    autoClose: 900
                })
            })
        }
        else if (inputsProcesso.modalidade == "Armazenagem" || inputsProcesso.modalidade == "Devolução") {

            axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/nota/${chave}/${id_filial}`, {
                headers: {
                    authorization: token
                }
            }).then(function (resposta) {


                setShowModalCarregando(false)
                toast.success(resposta.data.message, {
                    autoClose: 900
                })
                CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
            }).catch(function (erro) {

                toast.error(erro.response.data.message || erro.message, {
                    autoClose: 900
                })
                setShowModalCarregando(false)
                CarregarNFsVinculadas(id_filial, params.id_processo!, inputsProcesso.id_depositante)
            })
        }
        else {

            setShowModalCarregando(false)
            toast.error("Modalidade não cadastrada", {
                autoClose: 900
            })
        }
    }

    const [ListaProdutosCadFornecedorPendente, setListaProdutosCadFornecedorPendente] = useState([])
    function CarregarNFEarmz(id_processo: string, id_filial: string, id_depositante: string, usuarioLogado: string) {

        //carregar/nfs/amz/processo/:id_processo/:id_filial/:id_depositante/:usuarioLogado
        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/nfs/amz/processo/${id_processo}/${id_filial}/${id_depositante}/${usuarioLogado}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)

            if (resposta.status == 207) {

                toast.info(resposta.data.message, {
                    autoClose: 900
                })
            }
            else {

                toast.success(resposta.data.message, {
                    autoClose: 900
                })
            }

            setListaDeNotasVinculadas(resposta.data.nfs_vinculadas)
            setListaDeNotas(resposta.data.nfs_disponiveis)
            setListaProdutosCadFornecedorPendente(resposta.data.ListaProdutosNaoCadastrados)

        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [showModalCadFornecedor_produto, setshowModalCadFornecedor_produto] = useState(false)
    useEffect(function () {

        if (ListaProdutosCadFornecedorPendente.length > 0) {

            CarregarProdutosDepositante(id_filial, inputsProcesso.id_depositante)
            setshowModalCadFornecedor_produto(true)
        }
        else {

            if (showModalCadFornecedor_produto == true) {

                setTimeout(() => {
                    toast.success("Produtos para fornecedor cadastrados. Continue com o processo normalmente.", {
                        autoClose: 900
                    })
                }, 900)
            }
            setshowModalCadFornecedor_produto(false)
        }
    }, [ListaProdutosCadFornecedorPendente])

    const [inputsProdutoFornecedor, setInputsProdutoFornecedor] = useState({
        nota: "",
        cnpj_depositante: "",
        cnpj_fornecedor: "",
        codigo: "",
        descricao: "",
        und_fiscal: "",
        id_wms: "",
        cod_fornecedor: "",
        und_fiscal_fornecedor: "",
        fator_conversao: ""
    })

    const [ListaProdutosDepositante, setListaProdutosDepositante] = useState([])
    function CarregarProdutosDepositante(id_filial: string, id_depositante: string) {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/prod/proc/dep/${id_filial}/${id_depositante}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            setListaProdutosDepositante(resposta.data.produtos)
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    function AtualizarInputsProdutoFornecedor(value: any, propriedade: string) {

        setInputsProdutoFornecedor(function (produtoFornecedorAtual) {

            return {
                ...produtoFornecedorAtual,
                [propriedade]: value
            }
        })
    }

    function FormatarFatorConversao(valor: string) {

        // Primeiro, remove todas as letras e símbolos não desejados
        let valorLimpo = valor.replace(/[a-zA-Z#\$%\^\&\*\(\)\[\]\{\};:\/\\|<>\?\!\=\+\@\~\`\_]/g, '')

        // Depois, substitui pontos (.) por vírgulas (,)
        valorLimpo = valorLimpo.replace(/\./g, ',')

        AtualizarInputsProdutoFornecedor(valorLimpo, "fator_conversao")
    }

    function CriarFatorConversao(id_filial: string) {

        setShowModalCarregando(true)
        const dados = {
            id_depositante: inputsProcesso.id_depositante,
            cnpjDepositante: inputsProdutoFornecedor.cnpj_depositante,
            cnpjFornecedor: inputsProdutoFornecedor.cnpj_fornecedor,
            id_wms: inputsProdutoFornecedor.id_wms,
            codigo: inputsProdutoFornecedor.codigo,
            codprodforn: inputsProdutoFornecedor.cod_fornecedor,
            unidfiscal: inputsProdutoFornecedor.und_fiscal,
            unidfiscalforn: inputsProdutoFornecedor.und_fiscal_fornecedor,
            fator: inputsProdutoFornecedor.fator_conversao,
            usuario: usuarioLogado
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/insere/produtofornecedor/${id_filial}`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            const ProdutosFornecedorRestante: any = ListaProdutosCadFornecedorPendente.filter(function (produto: any) {

                return produto.codigo_fornecedor != dados.codprodforn
            })

            setListaProdutosCadFornecedorPendente(ProdutosFornecedorRestante)
            setInputsProdutoFornecedor({
                nota: "",
                cnpj_depositante: "",
                cnpj_fornecedor: "",
                codigo: "",
                descricao: "",
                und_fiscal: "",
                id_wms: "",
                cod_fornecedor: "",
                und_fiscal_fornecedor: "",
                fator_conversao: ""
            })

            toast.success(resposta.data.message, {
                autoClose: 900
            })
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [showModalConfirmacaoVincular, setShowModalConfirmacaoVincular] = useState(false)
    const [showModalConfirmacaoDesvincular, setShowModalConfirmacaoDesvincular] = useState(false)
    function VincularNfeProcesso(id_filial: string, chave: string) {

        setShowModalConfirmacaoVincular(false)
        setShowModalCarregando(true)
        const dados = {
            id_filial: id_filial,
            chave: chave,
            id_depositante: inputsProcesso.id_depositante,
            id_processo: params.id_processo
        }
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/vinc/nfe/processo`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            CarregarNFEarmz(params.id_processo!, id_filial, inputsProcesso.id_depositante, usuarioLogado)
            setNotaAexcluir([])
            toast.success(resposta.data.message, {
                autoClose: 900
            })
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    function DesvincularNfeProcesso(id_filial: string, chave: string) {

        setShowModalConfirmacaoDesvincular(false)
        setShowModalCarregando(true)

        const dados = {
            id_filial: id_filial,
            chave: chave,
            id_depositante: inputsProcesso.id_depositante,
            id_processo: params.id_processo
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/desvin/nfe/processo`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            CarregarNFEarmz(params.id_processo!, id_filial, inputsProcesso.id_depositante, usuarioLogado)
            setNotaAexcluir([])
            toast.success(resposta.data.message, {
                autoClose: 900
            })
        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    /*
        ANOTAÇÕES PARA FAZER:
        -Criar entidade ao importar notas de armz quando: 
        se existe cnpj emit ou dest como entidade, se não tiver:cadastrar entidade
    */

    return (
        <div className="main">
            <div className='tituloDaPagina mt-2 ms-1'>
                <h4>{params.acao == "criar" ? "Novo processo de entrada" : params.acao == "editar" ? "Editar processo de entrada" : "Visualizar processo de entrada"}</h4>
            </div>
            <div className="card mt-2 pb-3">
                <div className="card-body">
                    
                        <ul className="nav nav-pills bg-nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active rounded-0" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#processo" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Dados do processo</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button disabled={params.id_processo == "novo" ? true : false}
                                    className="nav-link rounded-0" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#documentos" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Controle de Documentos</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="processo" role="tabpanel" aria-labelledby="pills-home-tab">
                                <form onSubmit={function (e) {
                                    e.preventDefault()
                                    if (params.acao == "criar" && params.id_processo == "novo") {
                                        CriarNovoProcesso(id_filial, inputsProcesso.id_depositante, usuarioLogado)
                                    }
                                    else if (params.acao == "editar" && params.id_processo != "novo") {
                                        AtualizarProcesso(id_filial, inputsProcesso.id_depositante)
                                    }
                                }}>
                                    
                                        <h4>Dados do processo</h4>
                                        <div className="row">
                                            <div className="col-sm col-md col-lg d-flex">
                                                <button className="btn btn-warning"
                                                    onClick={function () {
                                                        navigate("/cadast/veiculo/veiculo")
                                                    }}><i className="bi bi-plus-circle me-2"></i>Cadastrar Veículo</button>
                                            </div>
                                            <div className="w-100 pb-2"></div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "id_processo")
                                                    }}
                                                        readOnly
                                                        value={params.id_processo} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>N° Processo</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "dt_chegada")
                                                    }}
                                                        readOnly={params.acao == "visualizar" || params.acao == "editar" ? true : false}
                                                        required
                                                        value={inputsProcesso.dt_chegada} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>Chegada</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "dt_processo")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.dt_processo} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>Data Processo</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "dt_inicio")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.dt_inicio} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>Data Inicio</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "dt_fim")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.dt_fim} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>Data Fim</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "dt_liberacao")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.dt_liberacao} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                                    <label>Data Liberação</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <select value={inputsProcesso.modalidade}
                                                        required
                                                        disabled={params.acao == "visualizar" || params.acao == "editar" ? true : false}
                                                        onChange={function (e) {
                                                            atualizarInputsProcesso(e.target.value, "modalidade")
                                                        }}
                                                        className="form-select bordasInferiorInput text-black" id="floatingSelect" aria-label="Floating label select example">
                                                        <option value="">Modalidade...</option>
                                                        {ListaModalidades.map(function (modalidade: any) {

                                                            return <option value={modalidade.value}>{modalidade.value}</option>
                                                        })}
                                                    </select>
                                                    <label>Modalidade</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "statusProcesso")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.statusProcesso} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Status</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-4">
                                                <div className="form-floating">
                                                    <select value={inputsProcesso.id_depositante}
                                                        disabled={params.acao == "visualizar" || params.acao == "editar" ? true : false}
                                                        required
                                                        onChange={function (e) {
                                                            atualizarInputsProcesso(e.target.value, "id_depositante")
                                                        }}
                                                        className="form-select bordasInferiorInput text-black" id="floatingSelect" aria-label="Floating label select example">
                                                        <option value="">Depositante...</option>
                                                        {
                                                            ListaDepositantes.map(function (depositante: any) {

                                                                return <option value={depositante.id_cliente}>{depositante.fantasia}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <label>Depositante</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-4">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "criterio")
                                                    }}
                                                        readOnly={params.acao == "visualizar" || params.acao == "editar" ? true : false}
                                                        value={inputsProcesso.criterio} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Critério</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input required
                                                        disabled={params.acao == "visualizar" ? true : false}
                                                        className="form-control bordasInferiorInput text-black"
                                                        value={inputsProcesso.placaCavalo}
                                                        onBlur={function () {
                                                            setInformacoesVeiculo(inputsProcesso.placaCavalo)
                                                        }}
                                                        onChange={function (e) {
                                                            atualizarInputsProcesso(e.target.value, "placaCavalo")
                                                        }}
                                                        list="datalistOptions" placeholder=" " />
                                                    <label htmlFor="">Procurar Veículo</label>

                                                    <datalist id="datalistOptions">
                                                        {ListaVeiculos.map(function (veiculo: any) {

                                                            return <option value={veiculo.placa_1} onClick={function () {
                                                            }}>{veiculo.placa_1}</option>
                                                        })}
                                                    </datalist>
                                                </div>
                                            </div>
                                            {
                                                /*
                                                <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {
    
                                                        atualizarInputsProcesso(e.target.value, "placaCavalo")
                                                    }}
                                                        readOnly={params.acao == "visualizar" || params.acao == "editar" ? true : false}
                                                        required
                                                        value={inputsProcesso.placaCavalo} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Cavalo</label>
                                                </div>
                                            </div>
                                                */
                                            }
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "placaCarreta")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.placaCarreta} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Carreta</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-4">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "tipoVeiculo")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.tipoVeiculo} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Tipo Veículo</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-4">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "transportadora")
                                                    }}
                                                        readOnly
                                                        value={inputsProcesso.transportadora} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Transportadora</label>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-4">
                                                <div className="form-floating">
                                                    <select value={inputsProcesso.docMotorista}
                                                        disabled={params.acao == "visualizar" || inputsProcesso.placaCavalo == "" ? true : false}
                                                        onChange={function (e) {

                                                            atualizarInputsProcesso(e.target.value, "docMotorista")
                                                        }}
                                                        className="form-select bordasInferiorInput text-black" id="floatingSelect" aria-label="Floating label select example">
                                                        <option value="">Motorista...</option>
                                                        {
                                                            ListaMotoristas.map(function (motorista: any) {

                                                                return <option value={motorista.cpf}>{motorista.nome}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm col-md col-lg-2">
                                                <div className="form-floating">
                                                    <input onChange={function (e) {

                                                        atualizarInputsProcesso(e.target.value, "docMotorista")
                                                    }}
                                                        readOnly
                                                        value={mascaraCPF(inputsProcesso.docMotorista)} type="text" className="form-control bordasInferiorInput text-black" placeholder=" " />
                                                    <label>Doc.Motorista</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <BotaoCustomizado
                                                type="button"
                                                className="btn btn-light text-danger bg-white border-0"
                                                descricaoBotao="Voltar"
                                                onclick={function () {
                                                    navigate("/controle/processo/entrada/lista")
                                                }}
                                                icone={mdiKeyboardReturn}
                                            />
                                            <BotaoCustomizado
                                                type="submit"
                                                className="btn btn-light text-black bg-white border-0"
                                                disabled={params.acao == "visualizar" ? true : false}
                                                descricaoBotao="Salvar"
                                                icone={mdiCheckCircle}
                                            />
                                        </div>
                                    
                                </form>
                            </div>
                            <div className="tab-pane fade" id="documentos" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="conatainer p-0 m-0">
                                    <h4>Controle de Documentos</h4>
                                    <div className="row">
                                        <div className="col-sm col-md col-lg-12">
                                            <button
                                                className="btn btn-primary me-2 mt-2"
                                                onClick={function () {

                                                    CarregarNFEarmz(params.id_processo!, id_filial, inputsProcesso.id_depositante, usuarioLogado)
                                                }}
                                                disabled={params.acao == "visualizar" ? true : false}
                                                hidden={inputsProcesso.modalidade == "Crossdocking" || inputsProcesso.modalidade == "Re-Entrega" ? true : false}
                                            >Carregar NFe</button>
                                            <div hidden={inputsProcesso.modalidade == "Armazenagem" || inputsProcesso.modalidade == "Devolução" ? true : false}>
                                                <label htmlFor="ImportNfe" className="btn btn-danger mt-2">Importar NFe</label>
                                                <input id="ImportNfe" type="file" className="d-none" accept=".xml" multiple
                                                    onChange={function (e) {
                                                        importarXmlConverterJson(e.target.files)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                                    <li className="nav-item me-2" role="presentation">
                                        <button className="nav-link active border border-secondary" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#nfsdisp" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                            NF's Disponíveis para vincular
                                            <span className="badge bg-danger ms-1 fs-5">{listaDeNotas.length}</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link border border-secondary" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#nfsvinc" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                            NF's já vinculadas
                                            <span className="badge bg-danger ms-1 fs-5">{listaDeNotasVinculadas.length}</span>
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="nfsdisp" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <h4>NF's disponíveis para vincular ao processo</h4>
                                        <ClickableTable
                                            coluna={colunasNotas}
                                            itemsPerPage={itensPorPagina}
                                            data={listaDeNotas}
                                            usaRowClick={false}
                                            onRowClick={function () {

                                            }}
                                            acaoEditar={function (nota: any) {

                                                setShowModalConfirmacaoVincular(true)
                                                setNotaAexcluir(nota)
                                            }}
                                            acaoExcluir={function (nota: any) {
                                                setNotaAexcluir(nota)
                                                setShowModalConfirmacao(true)
                                            }}
                                            acaoVisualizar={function (nota: any) {
                                                CarregarItensDaNota(nota.id_filial, nota.chave_acesso)
                                            }}
                                            iconeEditar="bi bi-link-45deg"
                                            usaAcoes={true}
                                            usaEditar={true}
                                            usaExcluir={true}
                                            usaVisualizar={true}
                                            acessopesquisa='nf'
                                            labelpesquisa='Procurar por NFe'
                                        />
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-lg-2 m-auto text-center'>
                                                    <label>Itens por Página</label>
                                                </div>
                                                <div className='w-100'></div>
                                                <div className='col-lg-1 m-auto'>

                                                    <select value={itensPorPagina}
                                                        onChange={function (e: any) {
                                                            setItensPorPagina(e.target.value)
                                                        }}
                                                        className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={30}>30</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nfsvinc" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <h4>NF's já vinculadas ao processo</h4>
                                        <ClickableTable
                                            coluna={colunasNotas}
                                            itemsPerPage={itensPorPagina2}
                                            data={listaDeNotasVinculadas}
                                            usaRowClick={false}
                                            onRowClick={function () {

                                            }}
                                            acaoEditar={function (nota: any) {

                                                setShowModalConfirmacaoDesvincular(true)
                                                setNotaAexcluir(nota)
                                            }}
                                            acaoExcluir={function (nota: any) {
                                                setNotaAexcluir(nota)
                                                setShowModalConfirmacao(true)
                                            }}
                                            acaoVisualizar={function (nota: any) {
                                                CarregarItensDaNota(nota.id_filial, nota.chave_acesso)
                                            }}
                                            usaAcoes={true}
                                            iconeEditar="bi bi-unlock"
                                            usaEditar={inputsProcesso.modalidade == "Crossdocking" || inputsProcesso.modalidade == "Re-Entrega" ? false : params.acao != "visualizar" ? true : false}//desvincular nfe
                                            usaExcluir={inputsProcesso.modalidade == "Armazenagem" || inputsProcesso.modalidade == "Devolução" ? false : params.acao != "visualizar" ? true : false}//excluir nfe
                                            usaVisualizar={true}
                                            acessopesquisa='nf'
                                            labelpesquisa='Procurar por NFe'
                                        />
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-lg-2 m-auto text-center'>
                                                    <label>Itens por Página</label>
                                                </div>
                                                <div className='w-100'></div>
                                                <div className='col-lg-1 m-auto'>

                                                    <select value={itensPorPagina2}
                                                        onChange={function (e: any) {
                                                            setItensPorPagina2(e.target.value)
                                                        }}
                                                        className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={30}>30</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>

            <ModalCarregando mostrar={showModalCarregando} mensagem="Carregando..." />


            <Modal centered size="lg" show={showModalListaNF} onHide={function () {

                setListaNotasImportadas([])
                setListaNotasNaoImportadas([])
                setShowModalListaNF(false)
            }}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm col-md-12 col-lg-12'>
                                <h4 className='text-center bg-primary text-white p-1'>Importadas com sucesso.</h4>
                                <ClickableTable
                                    coluna={[
                                        {
                                            titulo: "NF", acesso: "nf"
                                        },
                                        {
                                            titulo: "Mensagem", acesso: "mensagem"
                                        }
                                    ]}
                                    itemsPerPage={10}
                                    data={ListaNotasImportadas}
                                    onRowClick={function () {

                                    }}
                                    acaoEditar={function () {

                                    }}
                                    acaoExcluir={function () {

                                    }}
                                    acaoVisualizar={function () {

                                    }}
                                    usaAcoes={false}
                                    usaEditar={false}
                                    usaExcluir={false}
                                    usaVisualizar={false}
                                    acessopesquisa='nf'
                                    labelpesquisa='Procurar por NF.'
                                />
                            </div>
                            <div className='col-sm col-md-12 col-lg-12'>
                                <h4 className='text-center bg-danger text-white p-1'>Notas não importadas ou com erro ao importar.</h4>
                                <ClickableTable
                                    coluna={[
                                        {
                                            titulo: "NF", acesso: "nf"
                                        },
                                        {
                                            titulo: "Mensagem", acesso: "mensagem"
                                        }
                                    ]}
                                    itemsPerPage={10}
                                    data={ListaNotasNaoImportadas}
                                    onRowClick={function () {

                                    }}
                                    acaoEditar={function () {

                                    }}
                                    acaoExcluir={function () {

                                    }}
                                    acaoVisualizar={function () {

                                    }}
                                    usaAcoes={false}
                                    usaEditar={false}
                                    usaExcluir={false}
                                    usaVisualizar={false}
                                    acessopesquisa='nf'
                                    labelpesquisa='Procurar por NF.'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm col-md col-lg text-end'>
                                <button
                                    onClick={function () {
                                        setListaNotasImportadas([])
                                        setListaNotasNaoImportadas([])
                                        setShowModalListaNF(false)

                                        const importacaoDeNota = document.querySelector("#impNFS") as HTMLInputElement
                                        importacaoDeNota.value = ""
                                    }}
                                    className='btn btn-danger'>Fechar</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>



            <Modal show={showModalProduto} centered size="xl" onHide={function () {
                setShowModalProduto(false)
                setListaDeItensNota([])
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes dos Protudos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClickableTable
                        coluna={inputsProcesso.modalidade == "Armazenagem" || inputsProcesso.modalidade == "Devolução" ? colunasItensNota : colunasItensNotaCross}
                        itemsPerPage={10}
                        data={ListaDeItensNota}
                        usaRowClick={false}
                        onRowClick={function () {


                        }}
                        acaoEditar={function () {

                        }}
                        acaoExcluir={function () {

                        }}
                        acaoVisualizar={function () {

                        }}
                        usaAcoes={false}
                        usaEditar={false}
                        usaExcluir={false}
                        usaVisualizar={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm col-md col-lg text-end">
                                <button className="btn btn-danger" onClick={function () {
                                    setShowModalProduto(false)
                                    setListaDeItensNota([])
                                }}>Fechar</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalConfirmacao
                msgPrincipal="Confirmar exclusão da NFe ?"
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                Acao1={function () {

                    setShowModalConfirmacao(false)
                }}
                Acao2={function () {

                    DeletarNF(id_filial, notaAExcluir.chave_acesso)
                }}
                mostrar={showModalConfirmacao}
            />

            <ModalConfirmacao
                msgPrincipal="Deseja vincular à NFe ao processo ?"
                msgAcao1="Cancelar"
                msgAcao2="Vincular"
                Acao1={function () {

                    setShowModalConfirmacaoVincular(false)
                }}
                Acao2={function () {

                    VincularNfeProcesso(id_filial, notaAExcluir.chave_acesso)
                }}
                mostrar={showModalConfirmacaoVincular}
            />

            <ModalConfirmacao
                msgPrincipal="Deseja desvincular à NFe do processo ?"
                msgAcao1="Cancelar"
                msgAcao2="Desvincular"
                Acao1={function () {

                    setShowModalConfirmacaoDesvincular(false)
                }}
                Acao2={function () {

                    DesvincularNfeProcesso(id_filial, notaAExcluir.chave_acesso)
                }}
                mostrar={showModalConfirmacaoDesvincular}
            />

            <Modal show={showModalCadFornecedor_produto} size="xl">
                <Modal.Header>
                    <h4>Produtos sem cadastro de fornecedor.</h4>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={function (e) {

                        e.preventDefault()
                        CriarFatorConversao(id_filial)
                    }}>
                        <div className="container">
                            <div className="row">
                                <h4>Depositante</h4>
                                <div className="col-sm col-md col-lg-3">
                                    <div className="form-floating">
                                        <input type="text"
                                            disabled
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            required
                                            value={MascaraCNPJ(inputsProdutoFornecedor.cnpj_depositante)} />
                                        <label>CNPJ Depositante</label>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <input type="text"
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            required
                                            disabled={inputsProdutoFornecedor.cnpj_depositante == "" ? true : false}
                                            onChange={function (e) {
                                                const codigo = e.target.value.split(" | ")[0]
                                                const descricao = e.target.value.split(" | ")[1]
                                                AtualizarInputsProdutoFornecedor(codigo, "codigo")
                                                AtualizarInputsProdutoFornecedor(descricao, "descricao")

                                                //pegando a und fiscal
                                                const produtoEscolhido: any = ListaProdutosDepositante.filter(function (produto: any) {

                                                    return produto.codigo == codigo
                                                })
                                                AtualizarInputsProdutoFornecedor(produtoEscolhido[0].unid_fiscal, "und_fiscal")
                                                AtualizarInputsProdutoFornecedor(produtoEscolhido[0].id_wms, "id_wms")
                                            }}
                                            value={inputsProdutoFornecedor.codigo}
                                            list="datalistProdutos"
                                        />
                                        <label>Código Produto</label>
                                        <datalist id="datalistProdutos">
                                            {
                                                ListaProdutosDepositante.map(function (produto: any) {

                                                    return <option value={produto.codigo + " | " + produto.descricao} />
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <div className="form-floating">
                                        <input type="text"
                                            disabled
                                            required
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            value={inputsProdutoFornecedor.descricao} />
                                        <label>Descrição</label>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <input type="text"
                                            disabled
                                            required
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            value={inputsProdutoFornecedor.und_fiscal} />
                                        <label>Unid.Fiscal</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="container">
                            <div className="row">
                                <h4>Fornecedor</h4>
                                <div className="col-sm col-md col-lg-3">
                                    <div className="form-floating">
                                        <input type="text"
                                            disabled
                                            required
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            value={MascaraCNPJ(inputsProdutoFornecedor.cnpj_fornecedor)} />
                                        <label>CNPJ Fornecedor</label>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <input type="text"
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            disabled
                                            required
                                            value={inputsProdutoFornecedor.cod_fornecedor} />
                                        <label>Código Produto</label>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <input type="text"
                                            className="form-control bordasInferiorInput text-black text-center"
                                            placeholder=" "
                                            disabled
                                            required
                                            value={inputsProdutoFornecedor.und_fiscal_fornecedor} />
                                        <label>Unid.Fiscal</label>
                                    </div>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <input type="text"
                                            className="form-control bordasInferiorInput text-black text-center"
                                            onChange={function (e) {
                                                FormatarFatorConversao(e.target.value)
                                            }}
                                            placeholder=" "
                                            required
                                            value={inputsProdutoFornecedor.fator_conversao} />
                                        <label>Fator Conversão</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="container text-end">
                            {/*
                            <BotaoCustomizado
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Fechar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {

                                    setshowModalCadFornecedor_produto(false)
                                    setListaProdutosCadFornecedorPendente([])
                                    setInputsProdutoFornecedor({
                                        nota: "",
                                        cnpj_depositante: "",
                                        cnpj_fornecedor: "",
                                        codigo: "",
                                        descricao: "",
                                        und_fiscal: "",
                                        id_wms: "",
                                        cod_fornecedor: "",
                                        und_fiscal_fornecedor: "",
                                        fator_conversao: ""
                                    })
                                }}
                            />
                           */}
                            <BotaoCustomizado
                                type="submit"
                                className="btn btn-light text-black bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </form>
                    <ClickableTable
                        coluna={[
                            {
                                titulo: "NFe", acesso: "nota"
                            },
                            {
                                titulo: "Código", acesso: "codigo_fornecedor"
                            },
                            {
                                titulo: "Descrição", acesso: "descricao"
                            },
                            {
                                titulo: "UND. Fiscal", acesso: "und_fiscal_fornecedor"
                            },
                            {
                                titulo: "CNPJ Depositante", acesso: "cnpj_depositante"
                            },
                            {
                                titulo: "CNPJ Fornecedor", acesso: "cnpj_fornecedor"
                            }
                        ]}
                        itemsPerPage={10}
                        data={ListaProdutosCadFornecedorPendente}
                        onRowClick={function () {

                        }}
                        acaoEditar={function (produto: any) {

                            setInputsProdutoFornecedor({
                                nota: produto.nota,
                                cnpj_depositante: produto.cnpj_depositante,
                                cnpj_fornecedor: produto.cnpj_fornecedor,
                                codigo: "",
                                descricao: "",
                                und_fiscal: "",
                                id_wms: "",
                                cod_fornecedor: produto.codigo_fornecedor,
                                und_fiscal_fornecedor: produto.und_fiscal_fornecedor,
                                fator_conversao: ""
                            })
                        }}
                        acaoExcluir={function () {


                        }}
                        acaoVisualizar={function () {

                        }}
                        usaAcoes={true}
                        usaEditar={true}
                        usaExcluir={false}
                        usaVisualizar={false}
                        acessopesquisa='codigo'
                        labelpesquisa='Procurar por codigo.'
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ProcessoEntradaForm