import { mdiCheckCircle } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Api } from "../../../../services/api";
import Icon from '@mdi/react';
import axios from "axios";

const EditarEndereco = (): JSX.Element => {
	//#region

	//#region => VARIÁVEIS
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const user = sessionStorage.getItem('IsPerfilFKL');
	const idUsuario = sessionStorage.getItem('IsIDFKL');
	const { endereco, filial } = useParams();
	const [informacoesEndereco, setInformacoesEndereco] = useState([]);
	const [tipoPosicao, setTipoPosicao] = useState('');
	const [tipoTemperatura, setTipoTemperatura] = useState('');
	const [armazem, setArmazem] = useState('');
	const [corredor, setCorredor] = useState('');
	const [box, setBox] = useState('');
	const [altura, setAltura] = useState('');
	const [profundidade, setProfundidade] = useState('');
	const [confQuantidade, setConfQuantidade] = useState('');
	const [carregando, setCarregando] = useState(false);
	const [mostrarModalErro, setMostrarModalErro] = useState(false);
	const [razaoSocial, setRazaoSocial] = useState('');
	const navigate = useNavigate();
	//#endregion

	//#region => BUSCAR e CARREGAR - Informações do endereço
	const carregarDadosDoEndereco = async () => {
		try {
			const resposta = await axios.get(Api + `/endereco/?auth=${token}&tag=${endereco}&filial=${filial}`);
			setInformacoesEndereco(resposta.data);
			setTipoPosicao(`${resposta.data[0].tipo}-${resposta.data[0].tipo_posicao}`);
			setTipoTemperatura(resposta.data[0].tipo_temperatura);
			setArmazem(resposta.data[0].armazem);
			setCorredor(resposta.data[0].corredor);
			setBox(resposta.data[0].box);
			setAltura(resposta.data[0].altura);
			setProfundidade(resposta.data[0].profundidade);
			setConfQuantidade(`${resposta.data[0].conf_qtde}`);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => BUSCAR e CARREGAR MENU - Tipos de Posição
	const [menuPosicoes, setMenuPosicoes] = useState([]);
	const carregarPosicoes = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPPOSICAO&auth=${token}`)
			setMenuPosicoes(resposta.data);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => BUSCAR e CARREGAR MENU - Tipos de Temperatura
	const [menuTemperaturas, setMenuTemperaturas] = useState([]);
	const carregarTemperaturas = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPTEMP&auth=${token}`)
			setMenuTemperaturas(resposta.data);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => ENVIAR dados para o back-end
	const enviarDados = async (event: any) => {
		event.preventDefault();

		const body = {
			tipoPosicao,
			tipoTemperatura,
			armazem,
			corredor,
			box,
			altura,
			profundidade,
			confQuantidade,
			alteradoPor: user
		}

		try {
			const resposta = await axios.post(Api + `/endereco/editar/?auth=${token}&tag=${endereco}&filial=${filial}`, body);
			console.log(resposta);
			navigate('/config/cadast/endereco');
		} catch (error) {
			console.log(error);
			setMostrarModalErro(true);
		}
	}
	//#endregion

	//#region => CARREGAR informações na tela
	useEffect(() => {
		setCarregando(true);
		carregarDadosDoEndereco();
		carregarPosicoes();
		carregarTemperaturas();
		setCarregando(false);
	}, []);
	//#endregion

	//#endregion
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Editar Endereço: {endereco} - {razaoSocial}</h4>
						</div>
					</div>
				</div>
				<form id="form-utilitarios" onSubmit={enviarDados}>
					<div className="card">
						<div className="card-body">
							{
								informacoesEndereco.map((info: any, index: number) => {
									return (
										<>
											<div className="row">
												<div className="col">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="tipoPosicao"
															placeholder=''
															name="tipoPosicao"
															title="Tabela: TIPPOSICAO"
															required
															defaultValue={tipoPosicao}
															value={tipoPosicao}
															onChange={e => { setTipoPosicao(e.target.value) }}
														>
															{
																menuPosicoes.map((posicao: any, index: number) => {
																	return (
																		<option key={index} value={`${posicao.codigo}-${posicao.descricao}`}>
																			{posicao.codigo} - {posicao.descricao}
																		</option>
																	)
																})
															}
														</select>
														<label className="form-label" htmlFor="tipoPosicao">
															Tipo de Posição
														</label>
													</div>
												</div>
												<div className="col">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="temperatura"
															name="tipoTemperatura"
															placeholder=''
															title="Tabela: TIPTEMP"
															required
															defaultValue={tipoTemperatura}
															value={tipoTemperatura}
															onChange={e => { setTipoTemperatura(e.target.value) }}
														>
															{
																menuTemperaturas.map((temperatura: any, index: number) => {
																	return (
																		<option key={index} value={temperatura.codigo}>
																			{temperatura.codigo}
																		</option>
																	)
																})
															}
														</select>
														<label className="form-label" htmlFor="temperatura">
															Temperatura
														</label>
													</div>
												</div>
											</div>
											<div className="row" key={index}>
												<div className="col form-floating">

													<input
														type="text"
														id="armazem"
														className="form-control bordasInferiorInput text-black text-capitalize"
														name="armazem"
														placeholder=''
														onChange={e => { setArmazem(e.target.value) }}
														defaultValue={info.armazem}
														required
													/>
													<label htmlFor="armazem" className="form-label">
														Armazém:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="text"
														id="corredor"
														className="form-control  bordasInferiorInput text-black text-capitalize"
														name="corredor"
														onChange={e => { setCorredor(e.target.value) }}
														defaultValue={info.corredor}
														required
													/>
													<label htmlFor="corredor" className="form-label">
														Corredor:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="number"
														id="boxIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														name="box"
														onChange={e => { setBox(e.target.value) }}
														defaultValue={info.box}
														required
													/>
													<label htmlFor="boxIni" className="form-label">
														Box:
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col form-floating">

													<input
														type="number"
														id="altIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														name="altura"
														onChange={e => { setAltura(e.target.value) }}
														defaultValue={info.altura}
														required
													/>
													<label htmlFor="altIni" className="form-label">
														Altura:
													</label>
												</div>
												<div className="col form-floating">

													<input
														type="number"
														id="profIni"
														className="form-control bordasInferiorInput text-black text-capitalize"
														name="profundidade"
														onChange={e => { setProfundidade(e.target.value) }}
														defaultValue={info.profundidade}
														required
													/>
													<label htmlFor="profIni" className="form-label">
														Profundidade:
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col-6">
													<div className="mb-1 form-floating">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="qtdePicking"
															title="Tabela: SIMNAO"
															required
															defaultValue={confQuantidade}
															value={confQuantidade}
															onChange={e => { setConfQuantidade(e.target.value) }}
														>
															<option value="0">0 - NÃO</option>
															<option value="1">1 - SIM</option>
														</select>
														<label className="form-label" htmlFor="qtdePicking">
															Conf. Qtde Durante Picking
														</label>
													</div>
												</div>

											</div>
										</>
									)
								})
							}
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-cancelar mb-2 me-1"
										type="button"
										onClick={() => navigate('/config/cadast/endereco')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-novo mb-2 me-1"
										type="submit"
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			{/*MODAL - CARREGANDO*/}
			<Modal show={carregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>
			</Modal>
			{/*MODAL - ERRO AO EDITAR ENDERECO*/}
			<Modal show={mostrarModalErro} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Erro ao criar endereços</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
					<BsExclamationCircleFill size={"100px"} color="#f58619" />
					<div className="row" style={{ textAlign: 'center' }}>
						<div className="col-sm" >
							<h3>A alteração que você está tentando criar já existe nesta filial.</h3>
						</div>
					</div>
					<hr />
					<div className="d-flex my-2 gap-1 justify-content-between">
						<Button type="button" onClick={() => setMostrarModalErro(false)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />OK</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export { EditarEndereco }