import { ITabela } from "../../../../../components/Table/TabelaInterface";

//Criação das colunas da tabela
export const colunasGrid: ITabela[] = [
	{ titulo: 'ID CFOP', acesso: 'id_cfop' },
	{ titulo: 'CFOP Entrada', acesso: 'cfop_ent' },
	{ titulo: 'CFOP Saída', acesso: 'cfop_sai' },
	{ titulo: 'Natureza de Operação', acesso: 'natureza_op' },
	{ titulo: 'Padrão', acesso: 'padrao' },
	{ titulo: 'Usuário', acesso: 'usuario' },
];