import { ITabela } from '../../../../../components/Table/TabelaInterface';

export const colunasProdutosDetalhe: ITabela[] = [
	{
		titulo: 'Item',
		acesso: 'nitem',
	},
	{
		titulo: 'Cfop',
		acesso: 'cfop',
	},
	{
		titulo: 'Ncm',
		acesso: 'ncm',
	},
	{
		titulo: 'Código',
		acesso: 'codigo',
	},
	{
		titulo: 'Gtin',
		acesso: 'gtin',
	},
	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'Qtde.Com',
		acesso: 'qtd_com',
	},
	{
		titulo: 'Unid.Com',
		acesso: 'und_com',
	},
	{
		titulo: 'Valor Unit.',
		acesso: 'vlr_unit',
	},
	{
		titulo: 'Valor Total -',
		acesso: 'vlr_prod',
	},
	{
		titulo: 'Gtin Tib',
		acesso: 'gtintrib',
	},
	{
		titulo: 'Unid.Trib',
		acesso: 'qtrib',
	},
	{
		titulo: 'Valor Unit.Trib',
		acesso: 'vuntrib',
	},	
	{
		titulo: 'Origem',
		acesso: 'orig',
	},
	{
		titulo: 'Inf. Prod.',
		acesso: 'infprod',
	},
	{
		titulo: 'Lote',
		acesso: 'lote',
	},
	{
		titulo: 'Qtde. Lote',
		acesso: 'qtd_lote',
	},
	{
		titulo: 'Produção',
		acesso: 'producao',
	},
	{
		titulo: 'Validade',
		acesso: 'validade',
	},
];
