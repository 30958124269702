import { useEffect, useState } from "react"
import { ITabela } from "../../../../components/Table/TabelaInterface"
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl"
import axios from "axios"
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao"
import { Modal } from "react-bootstrap"
function OSpendetenEmbarque() {
    //const basicas
    const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const token = sessionStorage.getItem('IsFKLTOKEN')!;
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")
    //modal carregando controle
    const [ShowModalCarregando, setShowModalCarregando] = useState(false)
    //carrega O.S's
    const [ListaOs, setListaOs] = useState([])
    const [itensPorPagina, setItensPorPagina] = useState(10)
    function carregarOSsPendentesEmbarque() {
        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/os/pendentes/filial/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaOs(resposta.data.oss)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    const colunasOs: ITabela[] = [
        { titulo: "O.S", acesso: "id_os" },
        { titulo: "Depositante", acesso: "fantasia" },
        { titulo: "Id Pedido", acesso: "id_pedido" },
        { titulo: "Id Ped.Nfe", acesso: "id_ped_fiscal" },
        { titulo: "NFe.Retorno", acesso: "nf_retorno" },
        { titulo: "Dt.Carga", acesso: "data_carga" },
        { titulo: "Roteiro", acesso: "roteiro" },
        { titulo: "Volumes", acesso: "qtde_volume" },
        { titulo: "Peso Líq", acesso: "pl" },
        { titulo: "Cadastro", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuário" }
    ]
    //visualizar detalhes da os
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [detalhesOs, setDetalhesOs] = useState([])
    function VisualizarDetalhesDaOS(dados: any) {
        setShowModalCarregando(true)
        const id_filial = idFilial
        const id_os = dados.id_os
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/detalhes/os/pendentes/filial/${id_filial}/${id_os}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setDetalhesOs(resposta.data.detalhes)
            setShowModalCarregando(false)
            setShowModalDetalhes(true)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    const colunasDetalhesOS: ITabela[] = [
        { titulo: "Id Tarefa", acesso: "id_tarefa" },
        { titulo: "U.A.", acesso: "id_ua" },
        { titulo: "WMS", acesso: "id_wms" },
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Id.Embal.", acesso: "id_emb" },
        { titulo: "Unid.", acesso: "und_emb" },
        { titulo: "Descrição", acesso: "" },
        { titulo: "Volume", acesso: "qtde_volume" },
        { titulo: "Qtde", acesso: "qtde" },
        { titulo: "Peso líq.", acesso: "pl" },
        { titulo: "Validade", acesso: "validade" },
        { titulo: "Lote", acesso: "lote" },
        { titulo: "Tipo Peso", acesso: "tipo_peso" },
        { titulo: "Cadastro", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuário" }
    ];

    function FecharModalDetalhes() {
        setShowModalDetalhes(false)
        setDetalhesOs([])
    }
    //controle de modal Cancelar
    const [mostrarModalCancelar, setMostrarModalCancelar] = useState(false)
    const [OSselecionadaCancelar, setOSselecionadaCancelar] = useState<any>({})
    function setShowModalCancelarOS(osSelecionada: any) {
        setOSselecionadaCancelar(osSelecionada)
        setMostrarModalCancelar(true)
    }
    function setFecharModalCancelarOS() {
        setMostrarModalCancelar(false)
    }
    //função para cancelar O.S
    function CancelarOS() {
        const id_os = OSselecionadaCancelar.id_os
        setShowModalCarregando(true)
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/cancelar/os/pendente/${idFilial}/${id_os}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setFecharModalCancelarOS()
            toast.success(resposta.data.message, {
                autoClose: 2000
            })
            carregarOSsPendentesEmbarque()
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    useEffect(function () {
        carregarOSsPendentesEmbarque()
    }, [])
    return (
        <div className="main">
            <div className='tituloDaPagina mt-2 ms-1'>
                <h4>O.S's pendentes de embarque</h4>
            </div>
            <div className="card mt-2 pb-3">
                <div className="card-body">
                    <ClickableTable
                        coluna={colunasOs}
                        data={ListaOs}
                        onRowClick={function () { }}
                        itemsPerPage={itensPorPagina}
                        acaoEditar={function () { }}
                        acaoExcluir={setShowModalCancelarOS}
                        acaoVisualizar={VisualizarDetalhesDaOS}
                        usaAcoes={true}
                        usaEditar={false}
                        usaExcluir={true}
                        usaVisualizar={true}
                        iconeExcluir="bi bi-ban text-danger"
                        acessopesquisa='id_os'
                        labelpesquisa='Procurar pela O.S'
                    />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-1 col-md-2 col-sm m-auto text-center'>
                                <label>Itens por Página</label>
                            </div>
                            <div className='w-100'></div>
                            <div className='col-lg-1 col-md-2 col-sm m-auto'>

                                <select value={itensPorPagina}
                                    onChange={function (e: any) {
                                        setItensPorPagina(e.target.value)
                                    }}
                                    className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCarregando mostrar={ShowModalCarregando} mensagem="Carregando..." />
            <Modal size="xl" show={showModalDetalhes} onHide={FecharModalDetalhes}>
                <Modal.Header closeButton>
                    <h4>Detalhes da O.S</h4>
                </Modal.Header>
                <Modal.Body>
                    <ClickableTable
                        coluna={colunasDetalhesOS}
                        data={detalhesOs}
                        onRowClick={function () { }}
                        itemsPerPage={10}
                        acaoEditar={function () { }}
                        acaoExcluir={function () { }}
                        acaoVisualizar={function () { }}
                        usaAcoes={false}
                        usaEditar={false}
                        usaExcluir={true}
                        usaVisualizar={false}
                        iconeExcluir="bi bi-ban text-danger"
                        acessopesquisa='codigo'
                        labelpesquisa='Procurar pelo Código'
                    />
                </Modal.Body>
            </Modal>
            <ModalConfirmacao mostrar={mostrarModalCancelar}
                msgAcao1="Cancelar" msgAcao2="Confirmar"
                msgPrincipal="Confirmar cancelamento da O.S ?"
                Acao1={setFecharModalCancelarOS}
                Acao2={CancelarOS}
            />
        </div>
    )
}

export default OSpendetenEmbarque