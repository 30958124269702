import Titulo from "../../../../components/componentes/formulario/ContainerTitulo"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import Icon from "@mdi/react"
import {
    mdiMagnify,
    mdiSquareEditOutline,
    mdiTrashCanOutline,
    mdiEye
} from '@mdi/js';
import ModalCarregando from "../../../../components/componentes/formulario/ModalCarregando";
import { useEffect, useState } from "react";
import { Api } from "../../../../services/api";
import axios from "axios";
import { toast } from 'react-toastify';
import ModalExclusao from "../../../../components/componentes/formulario/ModalExcuir";


function GruposTributarios() {

    const [showCarregando, setShowCarregando] = useState(false)
    const token = sessionStorage.getItem('IsFKLTOKEN');

    const [grupos, setGrupos] = useState([])

    async function CarregarGrupos() {
        setShowCarregando(true)
        const response = await axios.get(Api + `/all/gruposT/?auth=${token}`)

        if (response.data.message) {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
        else {
            setGrupos(response.data)
            setShowCarregando(false)
        }
    }


    useEffect(function () {
        CarregarGrupos()
    }, [])

    //processo para deletar grupo -- inicio
    const [showQuestionaExclusao, setShowQuestionaExclusao] = useState(false)
    const [idparaDeletar, setidparaDeletar] = useState("")
    

    function pegarIdparaDeletarGrupo(id: any){
        setidparaDeletar(id)
        setShowQuestionaExclusao(true)
    }

    async function deleteGrupoById(){

        setShowQuestionaExclusao(false)
        setShowCarregando(true)

        const response = await axios.delete(Api + `/del/grupo/?auth=${token}&id=${idparaDeletar}`)

        if(response.status == 400){
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }

        setShowCarregando(false)
        CarregarGrupos()
    }

    //processo para deletar grupo --fim

    return (
        <>
            <div className="container-fluid">
                <Titulo titulo="Listagem de Grupos Tributários" />
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-sm-8">
                                    <Link to={`/pco/contrFisc/gruposTributarios/criar`} className="btn btn-novo mb-2 rounded">
                                        <i className="mdi mdi-plus-circle me-2"></i>
                                        Novo Grupo
                                    </Link>{' '}
                                </div>

                                <div className="col-sm-4">
                                    <Button
                                        variant="success"
                                        //onClick={() => setShowPesquisar(true)}
                                        className="mb-2 rounded"
                                        style={{ float: 'right', color: 'white'}}
                                    >
                                        Pesquisar
                                        <Icon path={mdiMagnify} size={1} className="me-2" />
                                    </Button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
                                        <thead className="table-cabecalho">
                                            <tr>
                                                <th>ID Grupo Trib</th>
                                                <th>Desc</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>



                                        <tbody className="corpoTabela">
                                            {grupos.map(function (grupo: any) {
                                                return (
                                                    <tr>
                                                        <th><label>{grupo.id}</label></th>
                                                        <td><label>{grupo.grupo}</label></td>
                                                        <td className="d-flex flex-nowrap table-action">
                                                            <Link
                                                                to={`/pco/contrFisc/gruposTributarios/ver/${grupo.id}`}
                                                                className="action-icon"
                                                                title="Visualizar"
                                                            >
                                                                <Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
                                                            </Link>
                                                            <Link
                                                                to={`/pco/contrFisc/gruposTributarios/editar/${grupo.id}`}
                                                                className="action-icon"
                                                                title="Editar"
                                                            >
                                                                <Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
                                                            </Link>
                                                            <a className="action-icon" title="Excluir" onClick={function(){
                                                                pegarIdparaDeletarGrupo(grupo.id)
                                                            }}>
                                                                <Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            {/*paginação 
                                            <tr className="rodapeTabelaPaginacao">
                                                <td colSpan={14}>
                                                    <a onClick={primeiraPagina} title="Primeira Página">
                                                        <Icon path={mdiSkipBackward} size={1} />
                                                    </a>
                                                    <a onClick={paginaAnterior} title="Página Anterior">
                                                        <Icon path={mdiSkipPrevious} size={1} />
                                                    </a>
                                                    Página: {currentPage + 1} de {pages} | Registros Total: {cfopsEntradaXsaida.length}
                                                    <a onClick={proxPagina} title="Próxima Página">
                                                        <Icon path={mdiSkipNext} size={1} />
                                                    </a>
                                                    <a onClick={ultimaPagina} title="Última Página">
                                                        <Icon path={mdiSkipForward} size={1} />
                                                    </a>
                                                </td>
                                            </tr>
                                            aqui */}
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

 
			<ModalExclusao show={showQuestionaExclusao} onClose={() => setShowQuestionaExclusao(false)} onConfirm={deleteGrupoById} />



            <ModalCarregando show={showCarregando} />








            {/*MODAL
			<Modal show={showPesquisar} backdrop="static" size="xl" keyboard={false} centered>
				<Modal.Header>
					<Modal.Title>Pesquisar CFOP Entrada X Saída</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={pesquisarDados}>
						<div className="row">
							<div className="col-sm-2">
								<Inputs label="CFOP" type="text" name="cfop" classNameInput="form-control" 
								classNameLabel="form-label" value={cfop} onchange={setcfop} 
								maxLength={4}/>
							</div>

							<div className="col-sm-6">
								
								{/*gera as opções de pesqusia*
								
								<label htmlFor="natoper" className="form-label">
												Natureza de operação de saída
											</label>
											<DatalistInput
												placeholder="Digite 3 caracteres para filtrar..."
												label="Natureza de operação de saída"
												showLabel={false}
												id="natoper"
												onChange={function(event: any){
													natoperComplet(event.target.value)
												}}
												onSelect={function(event: any){
													setarValorSelecionadoNatoper(event.value)
												}}
												items={natopers}
												className="dataListInput"
												value={natoper}
											/>
							</div>
						</div>

						<hr />

                        

						<div className="d-flex my-2 gap-1 justify-content-between">
							<Button style={{float: 'left' }} className='rounded' onClick={fecharModalPesquisar}>
								<Icon path={mdiKeyboardReturn} size={1} className="me-2" /> Voltar{' '}
							</Button>
							<Button variant="success" className='rounded' type="submit">
								{' '}
								<Icon path={mdiMagnify} size={1} className="me-2" />
								Filtrar
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
            PESQUISAR */}
        </>
    )
}

export default GruposTributarios
