import { useNavigate } from 'react-router-dom';

const NovaAutorizacaoEntrada = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Autorização de Entrada</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tab-ger-proc-autorizEntradaNovo-processo"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Processo</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											href="#tab-ger-proc-autorizEntradaNovo-veiculo"
											data-bs-toggle="tab"
											aria-expanded="false"
											className="nav-link rounded-0"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Veículo</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											href="#tab-ger-proc-autorizEntradaNovo-nf"
											data-bs-toggle="tab"
											aria-expanded="false"
											className="nav-link rounded-0"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Notas Fiscais</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											href="#tab-ger-proc-autorizEntradaNovo-audit"
											data-bs-toggle="tab"
											aria-expanded="false"
											className="nav-link rounded-0"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Auditoria</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tab-ger-proc-autorizEntradaNovo-processo">
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="processo">Processo</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="data_chegada">Data Chegada</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="data_cadastro">Data Cadastro</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tipo_operacao">Tipo Operação</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="status">Status</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="modal_operacao">Modal Operação</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="depositante">Depositante</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="cnpj_principal">CNPJ Principal</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="criterio">Critério</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="observacoes">Observações</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane show" id="tab-ger-proc-autorizEntradaNovo-veiculo">
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="cavalo">Cavalo</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="carreta">Carreta</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tipo_veiculo">Tipo Veículo</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="motorista1">Motorista</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="rg1">RG</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="cnh1">CNH</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="motorista2">Motorista</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="rg2">RG</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="cnh2">CNH</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="telefone">Telefone</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="transportadora">Transportadora</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane show" id="tab-ger-proc-autorizEntradaNovo-nf">
										<div className="table-responsive">
											<table className="table table-centered w-100 dt-responsive nowrap">
												<thead className="table-light">
													<tr>
														<th>Nota Fiscal</th>
														<th>Série</th>
														<th>Data Emissão</th>
														<th>CNPJ Emit</th>
														<th>nome Emit</th>
														<th>Destinatário</th>
														<th>Chave DANFE</th>
														<th>Ações</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>123456</td>
														<td>1</td>
														<td>03/12/2021</td>
														<td>01123432000101</td>
														<td>Emitente Ltda</td>
														<td>Destinatário Ltda</td>
														<td>123121521212121212121212</td>
														<td className="d-flex flex-nowrap table-action">
															<a
																data-bs-toggle="modal"
																data-bs-target="#modal-ger-proc-autorizEntradaNovo-detNF"
																className="action-icon"
															>
																<i className="mdi mdi-file-find-outline"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="tab-pane show" id="tab-ger-proc-autorizEntradaNovo-audit">
										<div className="row">
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="conferido_por">Conferido por</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tempo_inicio">Tempo Início</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tempo_meio">Tempo Meio</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tempo_fim">Tempo Fim</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="lacre">Lacre</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="tipo_carga">Tipo Carga</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="liberado_por">Liberado por</label>
												</div>
												<div className="form-check mb-1 mt-1">
													<label className="form-check-label">
														<input type="checkbox" className="form-check-input" />
														Email Enviado
													</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="aprovado_por">Aprovado por</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="motivo_aprov_diverg">Motivo Aprov Diverg</label>
												</div>
												<div className="form-check mb-1 mt-1">
													<label className="form-check-label">
														<input type="checkbox" className="form-check-input" />
														Divergência?
													</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="peso_entrada">Peso Entrada</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="peso_saida">Peso Saída</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="peso">Peso</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="peso_teorico">Peso Téorico</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="diverg_peso">Diverg Peso</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="autorizado_por">Autorizado Por</label>
												</div>
												<div className="mb-1 form-floating">
													<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
													<label htmlFor="data_auto_entrada">Data Auto Entrada</label>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/gerenciamento/processos/autorizacaoEntrada')}
										>
											Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-ger-proc-autorizEntradaNovo-detNF"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe da Nota Fiscal
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap">
											<thead className="table-light">
												<tr>
													<th>NF</th>
													<th>Produto</th>
													<th>Descrição</th>
													<th>Qtd Comercial</th>
													<th>Un Medida</th>
													<th>Vlr Unitário</th>
													<th>Vlr Produto</th>
													<th>Cliente</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>0001</td>
													<td>MP0004</td>
													<td>MORANGO INTEIRO 15x35MM CONGELADO IQF</td>
													<td>1.200,00</td>
													<td>KG</td>
													<td>1.200,00</td>
													<td>1.200,00</td>
													<td>KPL</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovaAutorizacaoEntrada };
