import { useNavigate } from 'react-router-dom';

const NovasNotasFiscaisArmazenagem = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Nota Fiscal Armazenagem</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tabNotFiscArmazGeral"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
									<li className="nav-item">
										<a href="#tabNotFiscArmazDetalNF" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Detalhe Nota Fiscal</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tabNotFiscArmazGeral">
										<div className="row mb-1">
											<div className="row">
												<div className="col-sm-2 form-floating">
													<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
													<label className="form-label">Processo</label>
												</div>
												<div className="col-sm-2 form-floating">
													<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
													<label className="form-label">OS</label>
												</div>
												<div className="col-sm-2 form-floating">
													<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
													<label className="form-label">Pedido</label>
												</div>
												<div className="col-sm-2 form-floating">
													<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
													<label className="form-label">Depositante</label>
												</div>
											</div>

										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Nota Fiscal</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Série</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Data Emissão</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">CNPJ Emitente</label>
											</div>
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Emitente</label>
											</div>

										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">CNPJ Destinatário</label>
											</div>
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Destinatário</label>
											</div>

										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Volumes</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Bruto</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Liquido</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Valor Produto</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Nota Fiscal</label>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Volumes</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Bruto</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Liquido</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Valor Produto</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Peso Nota Fiscal</label>
											</div>

										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Municipio Dest</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">CEP Dest</label>
											</div>
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Lougadouro Dest</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">UF Dest</label>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Municipio Emit</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">CEP Emit</label>
											</div>
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Lougadouro Emit</label>
											</div>
											<div className="col-sm-2 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">UF Emit</label>
											</div>

										</div>
										<div className="row mb-1">
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Chave Acesso</label>
											</div>
											<div className="col-sm-4 form-floating">
												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Informações Complem.</label>
											</div>

										</div>
									</div>
									<div className="tab-pane show" id="tabNotFiscArmazDetalNF">
										<div className="table-responsive">
											<table className="table table-centered w-100 dt-responsive nowrap">
												<thead className="table-light">
													<tr>
														<th>NF</th>
														<th>Produto</th>
														<th>Descrição</th>
														<th>Qtde Comercial</th>
														<th>Un Medida</th>
														<th>Vlr Unitário</th>
														<th>Vl Produto</th>
														<th>Cliente</th>
														<th>Ações</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>0001</td>
														<td>MP0004</td>
														<td>MORANGO INTEIRO 15X35MM CONGELADO IQF</td>
														<td>1200,00</td>
														<td>KG</td>
														<td>1200,00</td>
														<td>1200,00</td>
														<td>KPL</td>
														<td className="d-flex flex-nowrap table-action">
															<a
																data-bs-toggle="modal"
																data-bs-target="#modal-pco-contrlFisc-contrNF-contrNFArmz-detItensNF"
																className="action-icon"
															>
																<i className="mdi mdi-square-edit-outline"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem')}
										>
											Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-pco-contrlFisc-contrNF-contrNFArmz-detItensNF"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe Item Nota Fiscal
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">Item NF</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Nota Fiscal</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">Série</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Cod Produto</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Descrição</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">NCM</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">Qtd Com</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">UND</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">Vlr Unit</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Vl Produto</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">CNPJ emitente</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">CFOP</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Barra</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">cEANTrib</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">uTrib</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">qTrib</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">vUnTrib</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">CST</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Orig</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">pRedBc</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">vBc</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">p/ICMS</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">v/ICMS</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Inv Prod</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">CST PIS</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">Base Calc PIS</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Aliq PIS</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">Vlr PIS</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">CST Cofins</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Base Calc Cofins</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-1">
											<label className="form-label">Aliq Cofins</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Vlr Cofins</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">cEnqIPI</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">CST IPI</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Base Calc IPI</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Aliq IPI</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Vlr IPI</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">CEST</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-5">
											<label className="form-label">Chave Acesso</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-2">
											<label className="form-label">Lote</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Qtd Lote</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Produção</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">Validade</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-sm-2">
											<label className="form-label">xPeD</label>
											<input type="text" className="form-control" />
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovasNotasFiscaisArmazenagem };
