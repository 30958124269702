import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import DatalistInput from 'react-datalist-input';
import InputDecimal from '../../../../components/componentes/formulario/InputDecimal';
import removeEspacoTextoMin from '../../../../components/componentes/types/removeEspacoTextoMin';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';
const NovoCodigosDoSistema = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');
	const nomePerfil = sessionStorage.getItem('NomePerfil')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const params = useParams();
	const id_codsis = params.id || '';
	const acao = params.acao;

	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarCodSistema) {
				toast.warning('Você não tem permissão para acessar Lista de Códigos do Sistema');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarCodSistema);
				setPermissaoCriar(permissoes.perfilPermissao.criarCodSistema);
				setPermissaoEditar(permissoes.perfilPermissao.editarCodSistema);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirCodSistema);
			}
		}
	}, [permissoes, erro, navigate]);

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do para adicionar o codigo do sistema
	const [showAdicionar, setShowAdicionar] = useState<boolean>(false);
	const [showEditar, setShowEditar] = useState<boolean>(false);
	const [acaoModal, setAcaoModal] = useState<string>('criar');

	// Confirmação de exclusao
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [id_filialDet, setIdFilialDet] = useState<string>('');
	const [id_codsisDet, setIdCodsisDet] = useState<string>('');
	const [descricaoDet, setDescricaoDet] = useState<string>('');

	//Dados do código do sistema - CABECALHO
	const [tabela, setTabela] = useState<string>('');
	const [descricao, setDescricao] = useState<string>('');
	const [situacao, setSituacao] = useState<boolean>(true);
	const [generico, setGenerico] = useState<boolean>(false);

	// Dados do codigo do sistema - DETALHE
	const [id_codsis_det, setId_Codsis_Det] = useState<string>('');
	const [descricao_det, setDescricao_Det] = useState<string>('');
	const [situacao_det, setSituacao_Det] = useState<boolean>(true);
	const [defUsr01, setDefUsr01] = useState<string>('');
	const [defUsr02, setDefUsr02] = useState<string>('');
	const [defUsr03, setDefUsr03] = useState<string>('');
	const [defUsr04, setDefUsr04] = useState<string>('');
	const [defUsr05, setDefUsr05] = useState<string>('');
	const [defUsr06, setDefUsr06] = useState<string>('');
	const [dadosTabelaDetalhe, setDadosTabelaDetalhe] = useState<any>([]);

	//#region ==> Função para buscar os dados do Código do Sistema
	async function carregarDados() {
		setShowCarregando(true);
		await axios
			.get(`${Api}/carrega/dados/codsis/edit/${id_codsis}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setDescricao(response.data.codsis[0].descricao);
				setTabela(response.data.codsis[0].tabela);
				setSituacao(response.data.codsis[0].situacao);
				setGenerico(response.data.codsis[0].id_filial === 0 ? true : false);
				setDadosTabelaDetalhe(response.data.detalhe);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//Carregar dados da tabela ao abrir a tela
	useEffect(() => {
		if (acao !== 'criar') {
			carregarDados();
		}
	}, []);

	const dadosEnvio = {
		tabela,
		descricao,
		situacao: situacao ? '1' : '0',
		usuarioLogado,
		detalhetabela: dadosTabelaDetalhe,
	};

	//#region  ==> Função para inserir código do sistema
	async function insereNovoCodigoDoSistema() {
		setShowCarregando(true);
		await axios
			.post(`${Api}/insere/codsis/${nomePerfil === 'Master' && generico ? '0' : id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para código do sistema
	async function editarCodigoDoSistema() {
		setShowCarregando(true);
		await axios
			.put(`${Api}/editar/codsis/${id_codsis}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	const adicionarDetalheTabela = () => {
		setId_Codsis_Det('Novo');
		setDescricao_Det('');
		setSituacao_Det(true);
		setDefUsr01('');
		setDefUsr02('');
		setDefUsr03('');
		setDefUsr04('');
		setDefUsr05('');
		setDefUsr06('');
		setAcaoModal('criar');
		setShowAdicionar(true);
	};

	const fecharModalAdicionar = () => {
		setId_Codsis_Det('Novo');
		setDescricao_Det('');
		setSituacao_Det(true);
		setDefUsr01('');
		setDefUsr02('');
		setDefUsr03('');
		setDefUsr04('');
		setDefUsr05('');
		setDefUsr06('');
		setShowAdicionar(false);
	};

	const fecharModalEditar = () => {
		setId_Codsis_Det('Novo');
		setDescricao_Det('');
		setSituacao_Det(true);
		setDefUsr01('');
		setDefUsr02('');
		setDefUsr03('');
		setDefUsr04('');
		setDefUsr05('');
		setDefUsr06('');
		setShowEditar(false);
	};

	//Criação das colunas da tabela detalhe
	const colunas: ITabela[] = [
		{
			titulo: 'ID. Tabela',
			acesso: 'id_codsis_det',
		},
		{
			titulo: 'Status',
			acesso: 'situacao',
		},

		{
			titulo: 'Descrição',
			acesso: 'descricao',
		},
		{
			titulo: 'Def. Usuário 1',
			acesso: 'defusr01',
		},
		{
			titulo: 'Def. Usuário 2',
			acesso: 'defusr02',
		},
		{
			titulo: 'Def. Usuário 3',
			acesso: 'defusr03',
		},
		{
			titulo: 'Def. Usuário 4',
			acesso: 'defusr04',
		},
		{
			titulo: 'Def. Usuário 5',
			acesso: 'defusr05',
		},
		{
			titulo: 'Def. Usuário 6',
			acesso: 'defusr06',
		},

		{
			titulo: 'Usuário',
			acesso: 'usuario',
		},
	];

	// dados tabela de detalhe

	const adicionarCodigoDoSistemaDetalhe = () => {

		//verificar se o registro ja existe na tabela de detalhe
		const detalhe = dadosTabelaDetalhe.filter((item: any) => item.descricao === descricao_det);
		if(detalhe.length > 0){
			toast.error('Registro já existe na tabela de detalhe');
			return;
		}

		const dadosEnvioDet = {
			id_codsis_det,
			tabela: tabela,
			descricao: descricao_det,
			situacao: situacao_det ? 1 : 0,
			defusr01: defUsr01,
			defusr02: defUsr02,
			defusr03: defUsr03,
			defusr04: defUsr04,
			defusr05: defUsr05,
			defusr06: defUsr06,
			usuario: usuarioLogado,
		};
		if (acao === 'criar') {
			setDadosTabelaDetalhe([...dadosTabelaDetalhe, dadosEnvioDet]);
			fecharModalAdicionar();
		} else {
			insereNovoCodigoDoSistemaDetalhe(dadosEnvioDet);
		}
	};
	//#region  ==> Função para inserir código do sistema - Detalhe
	async function insereNovoCodigoDoSistemaDetalhe(dados: any) {
		setShowCarregando(true);
		await axios
			.post(`${Api}/insere/codsis/detalhe/${nomePerfil === 'Master' && generico ? '0' : id_filial}/${id_codsis}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				carregarDados();
				fecharModalAdicionar();
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion
	const modificarCodSistemaDetalhe = () => {
		const dadosEnvioDet = {
			id_codsis_det,
			tabela: tabela,
			descricao: descricao_det,
			situacao: situacao_det ? 1 : 0,
			defusr01: defUsr01,
			defusr02: defUsr02,
			defusr03: defUsr03,
			defusr04: defUsr04,
			defusr05: defUsr05,
			defusr06: defUsr06,
			usuario: usuarioLogado,
		};
		if (acao === 'criar') {
			// verificar se o registro existe em dadosTabelaDetalhe e excluir do array usando como parametro os campos tabela e descricao
			const detalhe = dadosTabelaDetalhe.filter((item: any) => item.descricao === descricao_det);
			if (detalhe.length > 0) {
				const dadosDetalhe = dadosTabelaDetalhe.map((item: any) => {
					if (item.descricao === descricao_det) {
						return dadosEnvioDet;
					}
					return item;
				});

				setDadosTabelaDetalhe(dadosDetalhe);
				fecharModalEditar();
			} else {
				setDadosTabelaDetalhe([...dadosTabelaDetalhe, dadosEnvioDet]);
				fecharModalEditar();
			}

			// setDadosTabelaDetalhe([...dadosTabelaDetalhe, dadosEnvioDet]);
			// fecharModalAdicionar();
		} else {
			atualizarCodSistema(dadosEnvioDet);
		}
	};

	const editarCodigoDoSistemaDetalhe = (dados: any) => {
		setId_Codsis_Det(dados.id_codsis_det);
		setDescricao_Det(dados.descricao);
		setSituacao_Det(dados.situacao);
		setDefUsr01(dados.defusr01);
		setDefUsr02(dados.defusr02);
		setDefUsr03(dados.defusr03);
		setDefUsr04(dados.defusr04);
		setDefUsr05(dados.defusr05);
		setDefUsr06(dados.defusr06);
		setAcaoModal('editar');
		setShowEditar(true);
	};

	const excluirCodigoDoSistemaDetalhe = (dados: any) => {
		setMostraModalConfirm(true);
		setMsgPrincipalModal(`Deseja excluir o código do sistema detalhe: ${dados.descricao}?`);
		setMsgAcao1('Cancelar');
		setIdFilialDet(dados.id_filial);
		setIdCodsisDet(dados.id_codsis_det);
		setDescricaoDet(dados.descricao);
	};

	async function excluirCodSistemaDetalhe(id_filial: string, id_codsis: string, id_codsis_det: string, descricao: string) {
		if (acao === 'criar') {
			// verificar se o registro existe em dadosTabelaDetalhe e excluir do array usando como parametro os campos tabela e descricao
			const detalhe = dadosTabelaDetalhe.filter((item: any) => item.descricao !== descricao);
			setDadosTabelaDetalhe(detalhe);
			toast.success('Registro excluído com sucesso');
			setMostraModalConfirm(false);
			setMsgPrincipalModal('');
			setMsgAcao1('');
			setIdFilialDet('');
			setIdCodsisDet('');
			setDescricaoDet('');
		} else {
			setShowCarregando(true);
			await axios
				.delete(`${Api}/excluir/codsis/detalhe/${id_filial}/${id_codsis}/${id_codsis_det}`, {
					headers: {
						authorization: token,
					},
				})
				.then((response) => {
					setShowCarregando(false);
					toast.success(response.data.message);
					setMostraModalConfirm(false);
					setMsgPrincipalModal('');
					setMsgAcao1('');
					setIdFilialDet('');
					setIdCodsisDet('');
					setDescricaoDet('');
					carregarDados();
				})
				.catch((erro) => {
					setShowCarregando(false);
					const status = erro.response.status;
					if (status === 403 || status === 401) {
						toast.error('Acesso negado, faça seu login novamente');
						navigate('/login');
					} else {
						toast.error(erro.response.data.message);
					}
				});
		}
	}

	const visualizarCodigoDoSistemaDetalhe = (dados: any) => {
		setId_Codsis_Det(dados.id_codsis_det);
		setDescricao_Det(dados.descricao);
		setSituacao_Det(dados.situacao);
		setDefUsr01(dados.defusr01);
		setDefUsr02(dados.defusr02);
		setDefUsr03(dados.defusr03);
		setDefUsr04(dados.defusr04);
		setDefUsr05(dados.defusr05);
		setDefUsr06(dados.defusr06);
		setAcaoModal('visualizar');
		setShowEditar(true);
	};

	async function atualizarCodSistema(dados: any) {
		setShowCarregando(true);
		await axios
			.put(`${Api}/editar/codsis/detalhe/${id_codsis}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				fecharModalEditar();
				carregarDados();
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Código do Sistema'
									: acao === 'editar'
									? 'Editar Código do Sistema'
									: 'Visualizar Código do Sistema'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoCodigoDoSistema();
								} else if (acao === 'editar') {
									editarCodigoDoSistema();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_codsis"
															name="id_codsis"
															value={id_codsis}
															disabled
														/>
														<label className="form-label">ID Código do Sistema</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-lowercase"
															id="tabela"
															name="tabela"
															value={tabela}
															onChange={(e) => setTabela(removeEspacoTextoMin(e.target.value))}
															disabled={acao === 'criar' ? false : true}
														/>
														<label className="form-label">Tabela</label>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="descricao"
															name="descricao"
															value={descricao}
															onChange={(e) => setDescricao(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Descrição</label>
													</div>
												</div>
												<div className="col-lg-2 col-md-3 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={situacao}
															onClick={function () {
																setSituacao(!situacao);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input"
															style={{ width: '75px' }}
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Ativo</label>
													</div>
												</div>
												{nomePerfil === 'Master' && (
													<div className="col-lg-2 col-md-3 pt-3 col-sm">
														<div className="form-check form-switch">
															<input
																checked={generico}
																onClick={function () {
																	setGenerico(!generico);
																}}
																disabled={acao === 'criar' ? false : true}
																className="form-check-input"
																style={{ width: '75px' }}
																type="checkbox"
															/>
															<label className="ms-1 form-check-label">Genérico</label>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
									<hr />
									<div className="row">
										{permissaoCriar && <div className="col d-flex justify-content-start">
											<BotaoCustomizado
												type="button"
												className="btn btn-secondary mb-1"
												descricaoBotao="Adicionar Código"
												icone={mdiPlusCircle}
												onclick={adicionarDetalheTabela}
												disabled={
													acao === 'criar' && tabela.length >= 3
														? false
														: acao === 'editar' && tabela.length >= 3
														? false
														: true
												}
											/>
										</div>}
									</div>
									<div className="">
										<ClickableTable
											coluna={colunas}
											itemsPerPage={10}
											data={dadosTabelaDetalhe}
											onRowClick={() => {}}
											usaAcoes={acao === 'criar' ? true : acao === 'editar' ? true : false}
											acaoExcluir={excluirCodigoDoSistemaDetalhe}
											usaVisualizar
											usaEditar={(acao === 'criar' || acao === 'editar') && permissaoEditar ? true : false}
											usaExcluir={(acao === 'criar' || acao === 'editar') && permissaoExcluir ? true : false}
											acaoEditar={editarCodigoDoSistemaDetalhe}
											acaoVisualizar={visualizarCodigoDoSistemaDetalhe}
											acessopesquisa="descricao"
											labelpesquisa="Procurar por detalhe do codigo do sistema"
										/>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			{/* MODAL PARA ADICIOINAR O CODIGO DO SISTEMA DETALHE */}
			<Modal show={showAdicionar} backdrop="static" keyboard={false} centered size="xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Adicionar Código do Sistema Detalhe</h5>
					</div>
					<div className="modal-body">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								adicionarCodigoDoSistemaDetalhe();
							}}
						>
							<div className="row">
								<div className="col-sm-3">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="id_codsis_det"
											name="id_codsis_det"
											value={id_codsis_det}
											onChange={(e) => setId_Codsis_Det(e.target.value)}
											disabled
										/>
										<label className="form-label">ID Código do Sistema</label>
									</div>
								</div>

								<div className="col-sm-6">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="descricao_det"
											name="descricao_det"
											value={descricao_det}
											onChange={(e) => setDescricao_Det(e.target.value)}
											required
										/>
										<label className="form-label">Descrição</label>
									</div>
								</div>
								<div className="col-lg-2 col-md-3 pt-3 col-sm">
									<div className="form-check form-switch">
										<input
											checked={situacao_det}
											onClick={function () {
												setSituacao_Det(!situacao_det);
											}}
											className="form-check-input"
											style={{ width: '75px' }}
											type="checkbox"
										/>
										<label className="ms-1 form-check-label">Ativo</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr01"
											name="defUsr01"
											value={defUsr01}
											onChange={(e) => setDefUsr01(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 01</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr02"
											name="defUsr02"
											value={defUsr02}
											onChange={(e) => setDefUsr02(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 02</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr03"
											name="defUsr03"
											value={defUsr03}
											onChange={(e) => setDefUsr03(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 03</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr04"
											name="defUsr04"
											value={defUsr04}
											onChange={(e) => setDefUsr04(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 04</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr05"
											name="defUsr05"
											value={defUsr05}
											onChange={(e) => setDefUsr05(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 05</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr06"
											name="defUsr06"
											value={defUsr06}
											onChange={(e) => setDefUsr06(e.target.value)}
										/>
										<label className="form-label">Definição Usuário 06</label>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="col-sm">
									<div className="text-sm-end">
										<BotaoCustomizado
											type="button"
											className="btn btn-light text-danger bg-white border-0"
											descricaoBotao="Voltar"
											icone={mdiKeyboardReturn}
											onclick={fecharModalAdicionar}
										/>
										<BotaoCustomizado
											type="submit"
											className="btn btn-light text-info-emphasis bg-white border-0"
											descricaoBotao="Salvar"
											icone={mdiCheckCircle}
											disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal>

			{/* MODAL PARA EEDITAR O CODIGO DO SISTEMA DETALHE */}
			<Modal show={showEditar} backdrop="static" keyboard={false} centered size="xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{acaoModal === 'editar' ? 'Editar' : 'Visualizar'} Código do Sistema Detalhe</h5>
					</div>
					<div className="modal-body">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								modificarCodSistemaDetalhe();
							}}
						>
							<div className="row">
								<div className="col-sm-3">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="id_codsis_det"
											name="id_codsis_det"
											value={id_codsis_det}
											onChange={(e) => setId_Codsis_Det(e.target.value)}
											disabled
										/>
										<label className="form-label">ID Código do Sistema</label>
									</div>
								</div>

								<div className="col-sm-6">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="descricao_det"
											name="descricao_det"
											value={descricao_det}
											onChange={(e) => setDescricao_Det(e.target.value)}
											required
											disabled
										/>
										<label className="form-label">Descrição</label>
									</div>
								</div>
								<div className="col-lg-2 col-md-3 pt-3 col-sm">
									<div className="form-check form-switch">
										<input
											checked={situacao_det}
											onClick={function () {
												setSituacao_Det(!situacao_det);
											}}
											className="form-check-input"
											style={{ width: '75px' }}
											type="checkbox"
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="ms-1 form-check-label">Ativo</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr01"
											name="defUsr01"
											value={defUsr01}
											onChange={(e) => setDefUsr01(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 01</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr02"
											name="defUsr02"
											value={defUsr02}
											onChange={(e) => setDefUsr02(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 02</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr03"
											name="defUsr03"
											value={defUsr03}
											onChange={(e) => setDefUsr03(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 03</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr04"
											name="defUsr04"
											value={defUsr04}
											onChange={(e) => setDefUsr04(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 04</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr05"
											name="defUsr05"
											value={defUsr05}
											onChange={(e) => setDefUsr05(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 05</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-floating mb-1">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="defUsr06"
											name="defUsr06"
											value={defUsr06}
											onChange={(e) => setDefUsr06(e.target.value)}
											disabled={acaoModal === 'editar' ? false : true}
										/>
										<label className="form-label">Definição Usuário 06</label>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="col-sm">
									<div className="text-sm-end">
										<BotaoCustomizado
											type="button"
											className="btn btn-light text-danger bg-white border-0"
											descricaoBotao="Voltar"
											icone={mdiKeyboardReturn}
											onclick={fecharModalEditar}
										/>
										<BotaoCustomizado
											type="submit"
											className="btn btn-light text-info-emphasis bg-white border-0"
											descricaoBotao="Salvar"
											icone={mdiCheckCircle}
											disabled={acaoModal === 'editar' ? false : true}
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal>

			{/* MODAL CONFIRMACAO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => excluirCodSistemaDetalhe(id_filialDet, id_codsis, id_codsisDet, descricaoDet)}
			/>
		</>
	);
};

export { NovoCodigosDoSistema };
