import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { mdiReceiptTextPlusOutline } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';

const ExcecaoEndereco = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');

	const [data, setData] = useState([]);	
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	// dados
	const [permExcecaoEndereco, setPermExcecaoEndereco] = useState<boolean>(false);
	// dados do local
	const [armazem, setArmazem] = useState<string>('');
	const [corredor, setCorredor] = useState<string>('');
	const [box, setBox] = useState<string>('');
	const [altura, setAltura] = useState<string>('');
	const [profundidade, setProfundidade] = useState<string>('');

	// Validar Permissao excecao endereco
	function validarPermissao() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando permissão, aguarde...');
		axios
			.get(`${Api}/excecao/endereco/permissao/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setPermExcecaoEndereco(resposta.data.perm_endereco === 1 ? true : false);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region Inicio novo desenvolvimento de funções
	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Endereços, aguarde...');
		axios
			.get(`${Api}/excecao/endereco/listar/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.endereco);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		validarPermissao();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// dados de armazem
	const selecionaArmazem = (dados: any) => {
		const id_armazem = dados;
		setCorredor('');
		setDadosCorredor([]);
		setBox('');
		setDadosBox([]);
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);
		setArmazem(id_armazem);
	};

	//#region -> Função para buscar no cadastro de endereco o armazem para alimentar o select/option
	const [dadosArmazem, setDadosArmazem] = useState<any[]>([]);
	async function ListarArmazem() {
		axios
			.get(Api + `/excecao/endereco/armazem/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosArmazem;
				if (resposta.data.armazem.length === 0) {
					setDadosArmazem(dados);
					return;
				}
				setDadosArmazem(resposta.data.armazem);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados de corredor
	const selecionaCorredor = (dados: any) => {
		const id_corredor = dados;
		setBox('');
		setDadosBox([]);
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);
		setCorredor(id_corredor);
	};

	//#region -> Função para buscar no cadastro de endereco o armazem para alimentar o select/option
	const [dadosCorredor, setDadosCorredor] = useState<any[]>([]);
	async function ListarCorredor(armazem: string) {
		axios
			.get(Api + `/excecao/endereco/corredor/${id_filial}/${armazem}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosCorredor;
				if (resposta.data.corredor.length === 0) {
					setDadosCorredor(dados);
					return;
				}
				setDadosCorredor(resposta.data.corredor);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do box
	const selecionaBox = (dados: any) => {
		const id_box = dados;
		setAltura('');
		setDadosAltura([]);
		setProfundidade('');
		setDadosProfundidade([]);

		setBox(id_box);
	};

	//#region -> Função para buscar no cadastro de endereco o box para alimentar o select/option
	const [dadosBox, setDadosBox] = useState<any[]>([]);
	async function ListarBox(armazem: string, corredor: string) {
		axios
			.get(Api + `/excecao/endereco/box/${id_filial}/${armazem}/${corredor}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosBox;
				if (resposta.data.box.length === 0) {
					setDadosBox(dados);
					return;
				}
				setDadosBox(resposta.data.box);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do altura
	const selecionaAltura = (dados: any) => {
		const id_altura = dados;
		setProfundidade('');
		setDadosProfundidade([]);
		setAltura(id_altura);
	};

	//#region -> Função para buscar no cadastro de endereco o altura para alimentar o select/option
	const [dadosAltura, setDadosAltura] = useState<any[]>([]);
	async function ListarAltura(armazem: string, corredor: string, box: string) {
		axios
			.get(Api + `/excecao/endereco/altura/${id_filial}/${armazem}/${corredor}/${box}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosAltura;
				if (resposta.data.altura.length === 0) {
					setDadosAltura(dados);
					return;
				}
				setDadosAltura(resposta.data.altura);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados do profundidade
	const selecionaProfundidade = (dados: any) => {
		const id_profundidade = dados;
		setProfundidade(id_profundidade);
	};

	//#region -> Função para buscar no cadastro de endereco a profundidade para alimentar o select/option
	const [dadosProfundidade, setDadosProfundidade] = useState<any[]>([]);
	async function ListarProfundidade(armazem: string, corredor: string, box: string, altura: string) {
		axios
			.get(Api + `/excecao/endereco/profundidade/${id_filial}/${armazem}/${corredor}/${box}/${altura}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const dados = dadosProfundidade;
				if (resposta.data.profundidade.length === 0) {
					setDadosProfundidade(dados);
					return;
				}
				setDadosProfundidade(resposta.data.profundidade);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const dadosEnvio = {
		id_filial,
		amz: armazem,
		cor: corredor,
		box: box,
		alt: altura,
		prof: profundidade,
		usuario,
	};

	// Incluir Exceção Endereço
	const incluirExcecaoEndereço = (dados: any) => {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Incluindo Exceção Endereço');
		axios
			.post(`${Api}/excecao/endereco/incluir/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(response.data.message);
				setDadosArmazem([]);
				setDadosCorredor([]);
				setDadosBox([]);
				setDadosAltura([]);
				setDadosProfundidade([]);
				setArmazem('');
				setCorredor('');
				setBox('');
				setAltura('');
				setProfundidade('');

				carregarDados();
			})
			.catch((erro) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Exceção - Endereço</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<form
									onSubmit={function (e) {
										e.preventDefault();
										incluirExcecaoEndereço(dadosEnvio);
									}}
								>
									<div className="row">
										<div className="col-sm">
											<InputSelect
												label="Armazém"
												value={armazem}
												onChange={selecionaArmazem}
												onClick={() => ListarArmazem()}
												dados={dadosArmazem}
												disabled={false}
												required
											/>
										</div>
										<div className="col-sm">
											<InputSelect
												label="Corredor"
												value={corredor}
												onChange={selecionaCorredor}
												onClick={() => ListarCorredor(armazem)}
												dados={dadosCorredor}
												disabled={false}
												required
											/>
										</div>

										<div className="col-sm">
											<InputSelect
												label="Box"
												value={box}
												onChange={selecionaBox}
												onClick={() => ListarBox(armazem, corredor)}
												dados={dadosBox}
												disabled={false}
												required
											/>
										</div>
										<div className="col-sm">
											<InputSelect
												label="Altura"
												value={altura}
												onChange={selecionaAltura}
												onClick={() => ListarAltura(armazem, corredor, box)}
												dados={dadosAltura}
												disabled={false}
												required
											/>
										</div>
										<div className="col-sm">
											<InputSelect
												label="Profundidade"
												value={profundidade}
												onChange={selecionaProfundidade}
												onClick={() => ListarProfundidade(armazem, corredor, box, altura)}
												dados={dadosProfundidade}
												disabled={false}
												required
											/>
										</div>

										{permExcecaoEndereco && (
											<div className="col-sm col-md col-lg">
												<BotaoCustomizado
													type="submit"
													className="btn btn-success mt-2"
													descricaoBotao="Gravar"
													icone={mdiReceiptTextPlusOutline}
													onclick={() => {}}
													size={1}
												/>
											</div>
										)}
									</div>
								</form>
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={false}
										acaoEditar={() => {}}
										acaoExcluir={() => {}}
										acaoVisualizar={() => {}}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa="amz"
										labelpesquisa="Procurar por armazém"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />
		</>
	);
};

export { ExcecaoEndereco };
