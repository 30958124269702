import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';

interface ModalExclusaoProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalExclusao: React.FC<ModalExclusaoProps> = ({ show, onClose, onConfirm }) => {
  return (
    <Modal show={show} backdrop="static" size="lg" keyboard={false} centered onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Confirmar Exclusão?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
        <BsExclamationCircleFill size={"100px"} color="#f58619" />
        <div className="row" style={{ textAlign: 'center' }}>
          <div className="col-sm" >
            <h3>Tem certeza que deseja realizar essa operação?</h3>
          </div>
        </div>
        <hr />
        <div className="d-flex my-2 gap-1 justify-content-between">
          <Button variant="success" style={{ borderRadius: '20px' }} type="button" onClick={onConfirm} >
            <Icon path={mdiCheckCircle} size={1} className="me-2" />
            Sim
          </Button>
          <Button style={{ borderRadius: '20px', float: 'left' }} onClick={onClose}>
            <Icon path={mdiCloseCircle} size={1} className="me-2" />
            Não
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalExclusao;
