import axios from 'axios';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmacao from '../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { ITabela } from '../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import fileSaver from 'file-saver';
import exceljs from 'exceljs';
import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import MascaraValor from '../../../components/functions/mascaraValor';
import bufferParaBase64 from '../../../components/functions/BufferParaBase64';
import { useNavigate } from 'react-router-dom';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

function EstoqueDetalhado() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaogerarExcelDetalharPorUAEstoqueDetalhado, setPermissaoGerarExcelDetalharPorUAEstoqueDetalhado] = useState<boolean>(false);
	const [permissaogerarResumoValidadeEstoqueDetalhado, setPermissaoGerarResumoValidadeEstoqueDetalhado] = useState<boolean>(false);
	const [permissaogerarExcelResumoValidadeEstoqueDetalhado, setPermissaoGerarExcelResumoValidadeEstoqueDetalhado] = useState<boolean>(false);
	const [permissaogerarPDFResumoValidadeEstoqueDetalhado, setPermissaoGerarPDFResumoValidadeEstoqueDetalhado] = useState<boolean>(false);
	const [permissaoenviarEstoqueEmailEstoqueDetalhado, setPermissaoEnviarEstoqueEmailEstoqueDetalhado] = useState<boolean>(false);
	const [permissaobloquearDesbloquearItemEstoqueDetalhado, setPermissaoBloquearDesbloquearItemEstoqueDetalhado] = useState<boolean>(false);
	const [permissaovisualizarEmpenhoEstoqueDetalhado, setPermissaoVisualizarEmpenhoEstoqueDetalhado] = useState<boolean>(false);
	const [permissaovisualizarBarrasUAEstoqueDetalhado, setPermissaoVisualizarBarrasUAEstoqueDetalhado] = useState<boolean>(false);
	const [permissaoadicionarBarrasUAEstoqueDetalhado, setPermissaoAdicionarBarrasUAEstoqueDetalhado] = useState<boolean>(false);
	const [permissaoeditarBarrasUAEstoqueDetalhado, setPermissaoEditarBarrasUAEstoqueDetalhado] = useState<boolean>(false);
	const [permissaoexcluirBarrasUAEstoqueDetalhado, setPermissaoExcluirBarrasUAEstoqueDetalhado] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarEstoqueDetalhado) {
				toast.warning('Você não tem permissão para acessar Estoque Detalhado. ');
				navigate('/');
			} else {
				setPermissaoGerarExcelDetalharPorUAEstoqueDetalhado(permissoes.perfilPermissao.gerarExcelDetalharPorUAEstoqueDetalhado);
				setPermissaoGerarResumoValidadeEstoqueDetalhado(permissoes.perfilPermissao.gerarResumoValidadeEstoqueDetalhado);
				setPermissaoGerarExcelResumoValidadeEstoqueDetalhado(permissoes.perfilPermissao.gerarExcelResumoValidadeEstoqueDetalhado);
				setPermissaoGerarPDFResumoValidadeEstoqueDetalhado(permissoes.perfilPermissao.gerarPDFResumoValidadeEstoqueDetalhado);
				setPermissaoEnviarEstoqueEmailEstoqueDetalhado(permissoes.perfilPermissao.enviarEstoqueEmailEstoqueDetalhado);
				setPermissaoBloquearDesbloquearItemEstoqueDetalhado(permissoes.perfilPermissao.bloquearDesbloquearItemEstoqueDetalhado);
				setPermissaoVisualizarEmpenhoEstoqueDetalhado(permissoes.perfilPermissao.visualizarEmpenhoEstoqueDetalhado);
				setPermissaoVisualizarBarrasUAEstoqueDetalhado(permissoes.perfilPermissao.visualizarBarrasUAEstoqueDetalhado);
				setPermissaoAdicionarBarrasUAEstoqueDetalhado(permissoes.perfilPermissao.adicionarBarrasUAEstoqueDetalhado);
				setPermissaoEditarBarrasUAEstoqueDetalhado(permissoes.perfilPermissao.editarBarrasUAEstoqueDetalhado);
				setPermissaoExcluirBarrasUAEstoqueDetalhado(permissoes.perfilPermissao.excluirBarrasUAEstoqueDetalhado);
			}
		}
	}, [permissoes, erro, navigate]);
	const [itensPorPagina, setItensPorPagina] = useState(10);

	const [showCarregando, setShowCarregando] = useState(false);

	const [ListaDepositantes, setListaDepositantes] = useState([]);
	const [depositanteSelecionado, setDepositanteSelecionado] = useState('');
	const [depositanteFantasia, setDepositanteFantasia] = useState('');
	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/${id_filial}/${id_usuario}`, {
				headers: {
					authorization: token,
				},
			});
			setListaDepositantes(resposta.data.depositantes);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message);
		}
	}

	useEffect(function () {
		CarregarDepositantes(id_filial, id_usuario);
	}, []);

	const [EstoqueDepositante, setEstoqueDepositante] = useState([]);
	const colunasEstoque: ITabela[] = [
		{ titulo: 'Bloq', acesso: 'bloq' },
		{ titulo: 'Tipo', acesso: 'tipo_ua' },
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Processo', acesso: 'id_processo' },
		{ titulo: 'Id Wms', acesso: 'id_wms' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Unid.Controle', acesso: 'und_emb' },
		{ titulo: 'Qtde. Estoque', acesso: 'qtde' },
		{ titulo: 'Qtde. Reservado', acesso: 'qtde_reserva' },
		{ titulo: 'Qtde. Disponível', acesso: 'qtde_disp' },
		{ titulo: 'Volume Embal.1', acesso: 'volume_1' },
		{ titulo: 'Volume Embal.2', acesso: 'volume_2' },
		{ titulo: 'Peso Líq.', acesso: 'pl' },
		{ titulo: 'Validade', acesso: 'validade_menor_formatado' },
		{ titulo: 'Lote', acesso: 'lote' },
		{ titulo: 'Usuário', acesso: 'usuario' },
	];

	async function CarregarEstoqueDepositante(id_depositante: string, id_filial: string) {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/estoque/depositante/${id_depositante}/${id_filial}`, {
				headers: {
					authorization: token,
				},
			});
			setEstoqueDepositante(resposta.data.estoque);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	useEffect(
		function () {
			if (depositanteSelecionado == '') {
				setEstoqueDepositante([]);
			} else {
				CarregarEstoqueDepositante(depositanteSelecionado, id_filial);
			}
		},
		[depositanteSelecionado]
	);

	const [showModalAcoesUA, setShowModalAcoesUA] = useState(false);
	const [UAselecionada, setUASelecionada] = useState<any>({});

	const [modalShowBloqueioUA, setmodalShowBloqueioUA] = useState(false);
	const [mensagemBloqueio, setMensagemBloqueio] = useState('');
	const [motivoBloqueio, setMotivoBloqueio] = useState('');
	const [permissaoDesbloqueio, setPermissaoDesbloqueio] = useState(false);
	const [permissaoBloqueio, setPermissaoBloqueio] = useState(false);

	async function BloqueioDesbloqueioUA(id_filial: string, id_depositante: string) {
		setShowModalAcoesUA(false);
		setShowCarregando(true);
		try {
			const dados = {
				ua: UAselecionada.id_ua,
				id_perfil: id_perfil,
				id_usuario: id_usuario,
				usuario: usuarioLogado,
				bloq_fiscal: UAselecionada.bloq_fiscal,
				bloq_sif: UAselecionada.bloq_sif,
				bloq_padrao: UAselecionada.bloq,
				motivoBloqueio: motivoBloqueio,
				bloqueio: permissaoBloqueio,
				desbloqueio: permissaoDesbloqueio,
				nivelPerfil: nivelPerfil,
			};
			const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/bloquear/desbloquear/ua/${id_filial}/${id_depositante}`, dados, {
				headers: {
					authorization: token,
				},
			});
			if (resposta.status == 207) {
				setMensagemBloqueio(resposta.data.message);
				setMotivoBloqueio('');
				setmodalShowBloqueioUA(true);
				setPermissaoBloqueio(resposta.data.permissoes.bloqueio);
				setPermissaoDesbloqueio(resposta.data.permissoes.desbloqueio);
			} else if (resposta.status == 200) {
				toast.success(resposta.data.message, {
					autoClose: 900,
				});
				setUASelecionada({});
				setmodalShowBloqueioUA(false);
				setMensagemBloqueio('');
				setMotivoBloqueio('');
				setPermissaoBloqueio(false);
				setPermissaoDesbloqueio(false);
				CarregarEstoqueDepositante(depositanteSelecionado, id_filial);
			}
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			setUASelecionada({});
			setmodalShowBloqueioUA(false);
			setPermissaoBloqueio(false);
			setPermissaoDesbloqueio(false);
			setMotivoBloqueio('');
			setMensagemBloqueio('');
			toast.error(error.response.data.message || error.message, {
				autoClose: 2000,
			});
		}
	}

	const [showModalEmpenho, setShowModalEmpenho] = useState(false);
	const [empenhos, setEmpenhos] = useState([]);
	const colunasEmpenho: ITabela[] = [
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'O.S', acesso: 'id_os' },
		{ titulo: 'Id.Pedido', acesso: 'id_pedido' },
		{ titulo: 'Qtde', acesso: 'qtde' },
		{ titulo: 'Dt.Carga Prev.', acesso: 'data_carga' },
		{ titulo: 'Criação', acesso: 'cadastro' },
		{ titulo: 'Usuário', acesso: 'usuario' },
	];
	async function CarregarEmpenhos(id_filial: string, ua: number) {
		try {
			setShowModalAcoesUA(false);
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/empenhos/${id_filial}/${ua}`, {
				headers: {
					authorization: token,
				},
			});
			setEmpenhos(resposta.data.empenhos);
			setShowCarregando(false);
			setShowModalEmpenho(true);
		} catch (error: any) {
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
			setShowCarregando(false);
		}
	}

	// const [permissaoAcertoBarraUA, setpermissaoAcertoBarraUa] = useState(false);
	const [showModalBarras, setShowModalBarras] = useState(false);
	const [itensPorPaginaBarra, setItensPorPaginaBarra] = useState(10);
	const [barrasUA, setBarrasUA] = useState([]);
	const colunasBarrasUA: ITabela[] = [
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Id.Produto', acesso: 'id_wms' },
		{ titulo: 'Barra', acesso: 'barra' },
		{ titulo: 'Peso Br.', acesso: 'pb' },
		{ titulo: 'Peso Liq.', acesso: 'pl' },
		{ titulo: 'Produção', acesso: 'producao_formatado' },
		{ titulo: 'Validade', acesso: 'validade_formatado' },
		{ titulo: 'Lote', acesso: 'lote' },
		{ titulo: 'Criação', acesso: 'cadastro_formatado' },
		{ titulo: 'Usuário', acesso: 'usuario' },
	];
	async function carregarBarrasUa(id_filial: string, ua: number) {
		try {
			setShowModalAcoesUA(false);
			setShowCarregando(true);
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carregar/barras/ua/${id_filial}/${ua}/${nivelPerfil}/${id_perfil}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setBarrasUA(resposta.data.barrasUA);
			// setpermissaoAcertoBarraUa(resposta.data.permissao);
			setShowCarregando(false);
			setShowModalBarras(true);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	const [showModalDelete, setShowModalDelete] = useState(false);
	const [barraSelecionada, setBarraSelecionada] = useState<any>({});
	async function DeletarBarraUa(ua: number, id_filial: string, id_depositante: string, id_wms: number, barra: string) {
		try {
			setShowModalDelete(false);
			setShowCarregando(true);
			const resposta = await axios.delete(
				`${process.env.REACT_APP_API_BASE_URL}/remover/barra/ua/${ua}/${id_filial}/${id_depositante}/${id_wms}/${barra}/${usuarioLogado}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setShowCarregando(false);
			CarregarEstoqueDepositante(depositanteSelecionado, id_filial);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	const [inputsBarra, setInputsBarra] = useState({
		ua: '',
		id_produto: '',
		id_barra: 'Nova',
		barra: '',
		pb: '',
		pl: '',
		producao: '',
		validade: '',
		Lote: '',
	});
	const [showModalNovaBarra, setShowModalNovaBarra] = useState(false);
	async function criarNovaBarraUA(id_filial: string, id_depositante: string) {
		try {
			setShowCarregando(true);
			const dados = {
				inputsBarra,
				usuario: usuarioLogado,
			};
			const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/nova/barra/ua/${id_filial}/${id_depositante}`, dados, {
				headers: {
					authorization: token,
				},
			});
			setShowModalNovaBarra(false);
			setShowCarregando(false);
			CarregarEstoqueDepositante(depositanteSelecionado, id_filial);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	async function UpdateBarraUA(id_filial: string, id_depositante: string) {
		try {
			setShowCarregando(true);
			const dados = {
				inputsBarra,
				usuario: usuarioLogado,
			};
			const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/barra/estoque/${id_filial}/${id_depositante}`, dados, {
				headers: {
					authorization: token,
				},
			});
			setShowModalNovaBarra(false);
			setShowCarregando(false);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
		} catch (error: any) {
			setShowModalNovaBarra(false);
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	const [showModalDetalhado, setShowModalDetalhado] = useState(false);
	const colunasDetalhado: ITabela[] = [
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Filial', acesso: 'fantasia_filial' },
		{ titulo: 'Depositante', acesso: 'fantasia_depositante' },
		{ titulo: 'Tipo U.A', acesso: 'tipo_ua' },
		{ titulo: 'Processo', acesso: 'id_processo' },
		{ titulo: 'Id wms', acesso: 'id_wms' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'id_emb', acesso: 'id_emb' },
		{ titulo: 'Und emb', acesso: 'und_emb' },
		{ titulo: 'Volume 1', acesso: 'volume_1' },
		{ titulo: 'Volume 2', acesso: 'volume_2' },
		{ titulo: 'Qtde', acesso: 'qtde' },
		{ titulo: 'Qtde Reserva', acesso: 'qtde_reserva' },
		{ titulo: 'Qtde Disp', acesso: 'qtde_disp' },
		{ titulo: 'Peso Br.', acesso: 'pb' },
		{ titulo: 'Peso Líq.', acesso: 'pl' },
		{ titulo: 'Valor Unit', acesso: 'vlr_unit' },
		{ titulo: 'Menor Produção', acesso: 'producao_menor_formatado' },
		{ titulo: 'Menor Validade', acesso: 'validade_menor_formatado' },
		{ titulo: 'Lote', acesso: 'lote' },
		{ titulo: 'Armazem', acesso: 'amz' },
		{ titulo: 'Corredor', acesso: 'cor' },
		{ titulo: 'Altura', acesso: 'alt' },
		{ titulo: 'Profundidade', acesso: 'prof' },
		{ titulo: 'Box', acesso: 'box' },
		{ titulo: 'Bloqueado', acesso: 'bloq' },
		{ titulo: 'Data Bloqueio', acesso: 'dt_bloq_formatado' },
		{ titulo: 'Motivo', acesso: 'motivo_bloq' },
		{ titulo: 'Bloqueio Fiscal', acesso: 'bloq_fiscal' },
		{ titulo: 'Bloqueio SIF', acesso: 'bloq_sif' },
		{ titulo: 'SIF', acesso: 'sif' },
		{ titulo: 'Temperatura SIF', acesso: 'sif_temperatura' },
		{ titulo: 'Cadastro', acesso: 'cadastro_formatado' },
		{ titulo: 'Usuario', acesso: 'usuario' },
	];
	const [itensPorPaginaDetalhado, setItensPorPaginaDetalhado] = useState(10);
	async function GerarExcelDetalhado(ua: any) {
		try {
			setShowCarregando(true);
			const workbook = new exceljs.Workbook();
			const worksheet = workbook.addWorksheet('Detalhado por U.A');
			//#region criando cabecalho -- INICIO
			const alfabeto1 = [
				'A',
				'B',
				'C',
				'D',
				'E',
				'F',
				'G',
				'H',
				'I',
				'J',
				'K',
				'L',
				'M',
				'N',
				'O',
				'P',
				'Q',
				'R',
				'S',
				'T',
				'U',
				'V',
				'W',
				'X',
				'Y',
				'Z',
				'AA',
				'AB',
				'AC',
				'AD',
				'AE',
				'AF',
				'AG',
				'AH',
				'AI',
				'AJ',
			];
			for (let i = 0; i < colunasDetalhado.length; i = i + 1) {
				let letra = alfabeto1[i];
				const cell = worksheet.getCell(`${letra}1`);
				cell.value = colunasDetalhado[i].titulo;
				cell.font = {
					bold: true,
				};
				cell.border = {
					bottom: {
						style: 'thin',
					},
				};
				worksheet.getColumn(letra).width = 25;
			}
			//#region criando cabecalho -- FIM
			//#region preenchendo corpo do excel -- INICIO
			for (let i = 2; i - 2 < ua.length; i = i + 1) {
				for (let j = 0; j < colunasDetalhado.length; j = j + 1) {
					const acesso = colunasDetalhado[j].acesso;
					const letra = alfabeto1[j];
					const cell = worksheet.getCell(`${letra}${i}`);
					cell.value = ua[i - 2][acesso];
				}
			}
			//#region preenchendo corpo do excel -- FIM
			const buffer = await workbook.xlsx.writeBuffer();
			// Exemplo de como usar o buffer (por exemplo, criar um Blob e salvar um arquivo)
			const blob: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fileSaver.saveAs(blob, 'DetalhadoPorUa');
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error('Erro ao gerar .xlsx: ' + error.message);
		}
	}

	const [showModalResumido, setShowModalResumido] = useState(false);
	const colunasResumidoValidade: ITabela[] = [
		{ titulo: 'Id wms', acesso: 'id_wms' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Unid.Controle', acesso: 'und_emb' },
		{ titulo: 'Validade', acesso: 'validade_menor_formatado' },
		{ titulo: 'Lote', acesso: 'lote' },
		{ titulo: 'Qtde.Estoque', acesso: 'qtde' },
		{ titulo: 'Qtde.Reservado', acesso: 'qtde_reserva' },
		{ titulo: 'Qtde. Disponível', acesso: 'qtde_disp' },
		{ titulo: 'Peso Br', acesso: 'pb' },
		{ titulo: 'Peso Líq', acesso: 'pl' },
	];
	const [itensPorPaginaResumido, setItensPorPaginaResumido] = useState(10);
	const [dadosResumido, setDadosResumi] = useState([]);
	const [qtdeDisponivel, setqtdeDisponivel] = useState(0);
	const [pesoLiqDisponivel, setpesoLiqDisponivel] = useState(0);
	async function CarregarDadosResumido(id_filial: string, id_depositante: string) {
		if (id_depositante == '') {
			toast.error('Selecione um depositante para que seja possível gerar as informações.', {
				autoClose: 1200,
			});
		} else {
			try {
				setShowCarregando(true);
				const resposta = await axios.get(
					`${process.env.REACT_APP_API_BASE_URL}/carregar/estoque/resumido/validade/${id_filial}/${id_depositante}`,
					{
						headers: {
							authorization: token,
						},
					}
				);
				setDadosResumi(resposta.data.estoqueResumidoValidade);
				setqtdeDisponivel(resposta.data.qtdeDisponivel);
				setpesoLiqDisponivel(resposta.data.pesoLiqDisponivel);
				setShowCarregando(false);
				setShowModalResumido(true);
			} catch (error: any) {
				setShowCarregando(false);
				toast.error(error.response.data.message || error.message, {
					autoClose: 900,
				});
			}
		}
	}
	async function GerarExcelResumido(ua: any, isEnvio = 0) {
		try {
			setShowCarregando(true);
			const workbook = new exceljs.Workbook();
			const worksheet = workbook.addWorksheet('Detalhado por U.A');
			//#region criando cabecalho -- INICIO
			const alfabeto1 = [
				'A',
				'B',
				'C',
				'D',
				'E',
				'F',
				'G',
				'H',
				'I',
				'J',
				'K',
				'L',
				'M',
				'N',
				'O',
				'P',
				'Q',
				'R',
				'S',
				'T',
				'U',
				'V',
				'W',
				'X',
				'Y',
				'Z',
				'AA',
				'AB',
				'AC',
				'AD',
				'AE',
				'AF',
				'AG',
				'AH',
				'AI',
				'AJ',
			];
			for (let i = 0; i < colunasResumidoValidade.length; i = i + 1) {
				let letra = alfabeto1[i];
				const cell = worksheet.getCell(`${letra}1`);
				cell.value = colunasResumidoValidade[i].titulo;
				cell.font = {
					bold: true,
				};
				cell.border = {
					bottom: {
						style: 'thin',
					},
				};
				worksheet.getColumn(letra).width = 25;
			}
			//#region criando cabecalho -- FIM
			//#region preenchendo corpo do excel -- INICIO
			for (let i = 2; i - 2 < ua.length; i = i + 1) {
				for (let j = 0; j < colunasResumidoValidade.length; j = j + 1) {
					const acesso = colunasResumidoValidade[j].acesso;
					const letra = alfabeto1[j];
					const cell = worksheet.getCell(`${letra}${i}`);
					cell.value = ua[i - 2][acesso];
				}
			}
			//#region preenchendo corpo do excel -- FIM
			const buffer = await workbook.xlsx.writeBuffer();
			if (isEnvio == 0) {
				// Exemplo de como usar o buffer (por exemplo, criar um Blob e salvar um arquivo)
				const blob: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fileSaver.saveAs(blob, 'ResumidoValidade');
			} else {
				//retorna string da base64
				return bufferParaBase64(buffer);
			}
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error('Erro ao gerar .xlsx: ' + error.message);
		}
	}
	async function GerarPDFResumido(isEnvio = 0) {
		setShowCarregando(true);
		const pdfElement = document.getElementById('pdf-content');
		const divisor = document.getElementById('divisor')!;
		if (!pdfElement) {
			throw new Error('Elemento HTML não encontrado');
		} else {
			try {
				pdfElement.hidden = false;
				divisor.hidden = false;
				// Aumentar a escala da renderização do canvas para melhorar a qualidade da imagem
				const scale = 2;
				const canvas = await html2canvas(pdfElement, { scale });
				const imgData = canvas.toDataURL('image/png');
				// Dimensões da página A4 em pontos (1 ponto = 1/72 polegadas)
				const pageWidth = 600.0;
				const pageHeight = 841.89;
				// Criar um novo documento PDF
				const pdfDoc = await PDFDocument.create();
				const page = pdfDoc.addPage([pageWidth, pageHeight]);
				// Calcular as dimensões da imagem para caber na página A4
				const imgWidth = pageWidth;
				const imgHeight = (canvas.height * pageWidth) / canvas.width;
				// Adicionar a imagem gerada a partir do HTML ao PDF
				const image = await pdfDoc.embedPng(imgData);
				page.drawImage(image, {
					x: 0,
					y: pageHeight - imgHeight, // Ajuste para alinhar ao topo da página
					width: imgWidth,
					height: imgHeight,
				});
				// Serializar o documento PDF em bytes
				const pdfBytes = await pdfDoc.save();
				pdfElement.hidden = true;
				divisor.hidden = true;
				if (isEnvio == 0) {
					// Criar um Blob a partir dos bytes do PDF
					const blob = new Blob([pdfBytes], { type: 'application/pdf' });

					// Usar file-saver para baixar o arquivo
					fileSaver.saveAs(blob, 'ResumidoValidade.pdf');
					setShowCarregando(false);
				} else {
					return bufferParaBase64(pdfBytes);
				}
			} catch (error: any) {
				setShowCarregando(false);
				pdfElement.hidden = false;
				toast.error(error.message, {
					autoClose: 900,
				});
			}
		}
	}
	async function EnviarEmail() {
		try {
			setShowCarregando(true);
			if (depositanteSelecionado == '') {
				setShowCarregando(false);
				toast.error('Selecione um Depositante', {
					autoClose: 900,
				});
			} else {
				const base64Excel = await GerarExcelResumido(dadosResumido, 1);
				const base64PDF = await GerarPDFResumido(1);
				const dados = {
					base64Excel,
					base64PDF,
				};
				const resposta = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/enviar/arquivo/email/${id_filial}/${depositanteSelecionado}`,
					dados,
					{
						headers: {
							authorization: token,
						},
					}
				);
				toast.success(resposta.data.message, {
					autoClose: 900,
				});
				setShowCarregando(false);
			}
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}
	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Estoque Detalhado</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<div className="container p-0 m-0">
						<div className="row">
							<div className="col-sm col-md col-lg-3">
								<div className="form-floating">
									<select
										value={depositanteSelecionado + '|' + depositanteFantasia}
										onChange={function (e) {
											setDepositanteSelecionado(e.target.value.split('|')[0]);
											setDepositanteFantasia(e.target.value.split('|')[1]);
										}}
										className="form-select bordasInferiorInput text-black"
										id="floatingSelect"
										aria-label="Floating label select example"
									>
										<option value="">Selecione...</option>
										{ListaDepositantes.map(function (depositante: any) {
											return (
												<option value={depositante.id_cliente + '|' + depositante.fantasia}>{depositante.fantasia}</option>
											);
										})}
									</select>
									<label>Depositante</label>
								</div>
							</div>
							<div className="col-sm col-md col-lg d-flex">
								{
									<>
										<div className="dropdown me-1">
											<button
												className="btn btn-success dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<i className="bi bi-columns me-1"></i>Relatórios do Estoque
											</button>
											<div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
												{permissaogerarExcelDetalharPorUAEstoqueDetalhado && (
													<p
														style={{
															cursor: 'pointer',
														}}
														onClick={function () {
															setShowModalDetalhado(true);
														}}
														className="dropdown-item text-center text-black mb-0 ms-0 me-0 mt-1 p-0"
													>
														Detalhado por U.A
													</p>
												)}
												{permissaogerarResumoValidadeEstoqueDetalhado && (
													<p
														style={{
															cursor: 'pointer',
														}}
														onClick={function () {
															CarregarDadosResumido(id_filial, depositanteSelecionado);
														}}
														className="dropdown-item text-center text-black mb-0 ms-0 me-0 mt-1 p-0"
													>
														Resumido por Validade
													</p>
												)}
											</div>
										</div>
									</>
								}
								{permissaoenviarEstoqueEmailEstoqueDetalhado && (
									<div>
										<button type="button" className="btn btn-primary" onClick={EnviarEmail}>
											<i className="bi bi-send-plus me-1"></i>Enviar Estoque
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<ClickableTable
						coluna={colunasEstoque}
						itemsPerPage={itensPorPagina}
						data={EstoqueDepositante}
						usaRowClick={
							permissaobloquearDesbloquearItemEstoqueDetalhado ||
							permissaovisualizarEmpenhoEstoqueDetalhado ||
							permissaovisualizarBarrasUAEstoqueDetalhado
						}
						onRowClick={
							permissaobloquearDesbloquearItemEstoqueDetalhado ||
							permissaovisualizarEmpenhoEstoqueDetalhado ||
							permissaovisualizarBarrasUAEstoqueDetalhado
								? function (ua: any) {
										setShowModalAcoesUA(true);
										setUASelecionada(ua);
										setInputsBarra({
											...inputsBarra,
											id_produto: ua.id_wms,
											ua: ua.id_ua,
										});
								  }
								: () => {}
						}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={true}
						usaExcluir={true}
						usaVisualizar={false}
						acessopesquisa="codigo"
						labelpesquisa="Procurar por Código."
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mostrar={showCarregando} mensagem="Carregando" />

			{/*MODAL DE AÇÕES DA U.A */}
			<Modal
				show={showModalAcoesUA}
				size="sm"
				centered
				onHide={function () {
					setShowModalAcoesUA(false);
					setUASelecionada({});
				}}
			>
				<Modal.Header closeButton>
					<h4>Ações da U.A: {UAselecionada.id_ua}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row text-center">
							{permissaobloquearDesbloquearItemEstoqueDetalhado && (
								<div className="col-sm col-md col-lg">
									<button
										className="btn btn-primary w-100"
										onClick={function () {
											BloqueioDesbloqueioUA(id_filial, depositanteSelecionado);
										}}
									>
										1- Bloquear/Desbloquear
									</button>
								</div>
							)}
							<div className="w-100 py-1"></div>
							{permissaovisualizarEmpenhoEstoqueDetalhado && (
								<div className="col-sm col-md col-lg">
									<button
										className="btn btn-primary w-100"
										onClick={function () {
											CarregarEmpenhos(id_filial, UAselecionada.id_ua);
										}}
									>
										2- Visualizar empenhos
									</button>
								</div>
							)}
							<div className="w-100 py-1"></div>
							{permissaovisualizarBarrasUAEstoqueDetalhado && (
								<div className="col-sm col-md col-lg">
									<button
										className="btn btn-primary w-100"
										onClick={function () {
											carregarBarrasUa(id_filial, UAselecionada.id_ua);
										}}
									>
										3- Visualizar barras da U.A
									</button>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<BotaoCustomizado
						type="button"
						className="btn btn-light text-danger bg-white border-0"
						descricaoBotao="Fechar"
						onclick={function () {
							setShowModalAcoesUA(false);
							setUASelecionada({});
						}}
						icone={mdiKeyboardReturn}
					/>
				</Modal.Footer>
			</Modal>

			{/*MODAL DE DESBLOQUEIO DA U.A*/}
			<Modal
				show={modalShowBloqueioUA}
				size="lg"
				centered
				onHide={function () {
					setmodalShowBloqueioUA(false);
					setUASelecionada({});
				}}
			>
				<Modal.Header closeButton>
					<h4>{permissaoBloqueio == true ? 'Bloquear U.A' : permissaoDesbloqueio == true ? 'Desbloquear U.A' : ''}</h4>
				</Modal.Header>
				<form
					onSubmit={function (e) {
						e.preventDefault();
						BloqueioDesbloqueioUA(id_filial, depositanteSelecionado);
					}}
				>
					<Modal.Body>
						<ClickableTable
							coluna={[
								{ titulo: 'Bloq', acesso: 'bloq' },
								{ titulo: 'Tipo', acesso: 'tipo_ua' },
								{ titulo: 'U.A', acesso: 'id_ua' },
								{ titulo: 'Processo', acesso: 'id_processo' },
								{ titulo: 'Id Wms', acesso: 'id_wms' },
								{ titulo: 'Código', acesso: 'codigo' },
								{ titulo: 'Descrição', acesso: 'descricao' },
							]}
							itemsPerPage={1}
							data={[UAselecionada]}
							usaRowClick={false}
							onRowClick={function () {}}
							acaoEditar={function () {}}
							acaoExcluir={function () {}}
							acaoVisualizar={function () {}}
							usaAcoes={false}
							usaEditar={true}
							usaExcluir={true}
							usaVisualizar={true}
						/>
						<div className="container">
							<div className="row">
								<div className="col-sm col-md col-lg-12">
									<p className="text-black text-center">{mensagemBloqueio}</p>
								</div>

								<div className="col-sm col-md col-lg-12">
									<div className="form-floating">
										<input
											required
											value={motivoBloqueio}
											onChange={function (e) {
												setMotivoBloqueio(e.target.value);
											}}
											minLength={10}
											type="text"
											className="form-control bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Motivo {UAselecionada.bloq == true ? ' do desbloqueio' : ' do bloqueio'}</label>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<BotaoCustomizado
							type="button"
							className="btn btn-light text-danger bg-white border-0"
							descricaoBotao="Fechar"
							onclick={function () {
								setmodalShowBloqueioUA(false);
								setUASelecionada({});
							}}
							icone={mdiKeyboardReturn}
						/>
						{permissaoBloqueio == true || permissaoDesbloqueio == true ? (
							<BotaoCustomizado
								type="submit"
								className="btn btn-light text-black bg-white border-0"
								descricaoBotao={UAselecionada.bloq == true ? 'Desbloquear' : 'Bloquear'}
								icone={mdiCheckCircle}
							/>
						) : (
							<></>
						)}
					</Modal.Footer>
				</form>
			</Modal>

			{/*MODAL EMPENHOS */}
			<Modal
				show={showModalEmpenho}
				centered
				size="lg"
				onHide={function () {
					setEmpenhos([]);
					setShowModalEmpenho(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Empenho da U.A: {UAselecionada.id_ua}</h4>
				</Modal.Header>
				<Modal.Body>
					<ClickableTable
						coluna={colunasEmpenho}
						itemsPerPage={15}
						data={empenhos}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={true}
						usaExcluir={true}
						usaVisualizar={true}
					/>
				</Modal.Body>
				<Modal.Footer>
					<BotaoCustomizado
						type="button"
						className="btn btn-light text-danger bg-white border-0"
						descricaoBotao="Fechar"
						onclick={function () {
							setEmpenhos([]);
							setShowModalEmpenho(false);
						}}
						icone={mdiKeyboardReturn}
					/>
				</Modal.Footer>
			</Modal>

			{/* MODAL CARREGAR BARRAS PALLET */}
			<Modal
				show={showModalBarras}
				centered
				size="xl"
				onHide={function () {
					setBarrasUA([]);
					setShowModalBarras(false);
				}}
			>
				<Modal.Header>
					<h4>Barras da U.A: {UAselecionada.id_ua}</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container m-0 p-0">
						<div className="row">
							{permissaoadicionarBarrasUAEstoqueDetalhado ? (
								<div className="col-sm col-md col-lg">
									<button
										className="btn btn-primary"
										onClick={function () {
											if (barrasUA.length >= UAselecionada.volume_1) {
												toast.info(
													'Não é possível criar uma nova barra para essa UA. As qtde de barras na U.A é igual à quantidade de barras disponível',
													{
														autoClose: 3000,
													}
												);
											} else {
												setShowModalBarras(false);
												setShowModalNovaBarra(true);
											}
										}}
									>
										<i className="bi bi-plus-circle me-1"></i>
										Nova U.A
									</button>
								</div>
							) : (
								<div className="col-sm col-md col-lg"></div>
							)}
						</div>
					</div>
					<br />
					<ClickableTable
						coluna={colunasBarrasUA}
						itemsPerPage={itensPorPaginaBarra}
						data={barrasUA}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function (barra: any) {
							setInputsBarra({
								...inputsBarra,
								ua: barra.id_ua,
								id_produto: barra.id_wms,
								id_barra: barra.id_barra,
								barra: barra.barra,
								pb: barra.pb.toString().replace('.', ','),
								pl: barra.pl.toString().replace('.', ','),
								producao: barra.producao.split('T') ? barra.producao.split('T')[0] : '',
								validade: barra.validade.split('T') ? barra.validade.split('T')[0] : '',
								Lote: barra.lote,
							});
							setShowModalBarras(false);
							setShowModalNovaBarra(true);
						}}
						acaoExcluir={function (barra) {
							setShowModalDelete(true);
							setShowModalBarras(false);
							setBarraSelecionada(barra);
						}}
						acaoVisualizar={function () {}}
						usaAcoes={permissaoeditarBarrasUAEstoqueDetalhado || permissaoexcluirBarrasUAEstoqueDetalhado}
						usaEditar={permissaoeditarBarrasUAEstoqueDetalhado}
						usaExcluir={permissaoexcluirBarrasUAEstoqueDetalhado}
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-3 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-2 m-auto">
								<select
									value={itensPorPaginaBarra}
									onChange={function (e: any) {
										setItensPorPaginaBarra(e.target.value);
									}}
									className="form-select form-select-sm text-center"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<BotaoCustomizado
						type="button"
						className="btn btn-light text-danger bg-white border-0"
						descricaoBotao="Fechar"
						onclick={function () {
							setBarrasUA([]);
							setShowModalBarras(false);
						}}
						icone={mdiKeyboardReturn}
					/>
				</Modal.Footer>
			</Modal>

			{/*Modal de editar/criar nova barra */}
			<Modal
				size="lg"
				centered
				show={showModalNovaBarra}
				onHide={function () {
					setInputsBarra({
						ua: '',
						id_produto: '',
						id_barra: 'Nova',
						barra: '',
						pb: '',
						pl: '',
						producao: '',
						validade: '',
						Lote: '',
					});
					setShowModalNovaBarra(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>{inputsBarra.id_barra == 'Nova' ? 'Criar nova barra' : 'Editar Barra'}</h4>
				</Modal.Header>
				<form
					onSubmit={function (e) {
						e.preventDefault();
						if (inputsBarra.id_barra == 'Nova') {
							criarNovaBarraUA(id_filial, depositanteSelecionado);
						} else if (inputsBarra.id_barra != 'Nova') {
							UpdateBarraUA(id_filial, depositanteSelecionado);
						}
					}}
				>
					<Modal.Body>
						<div className="container">
							<div className="row">
								<div className="col-sm col-md col-lg-2">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.id_barra}
											disabled
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>ID Barra</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.id_produto}
											disabled
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>ID Produto</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.barra}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, barra: e.target.value.replace(/\D/g, '') });
											}}
											required
											disabled={inputsBarra.id_barra == 'Nova' ? false : true}
											maxLength={50}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Barra</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.pb}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, pb: e.target.value.replace(/[^0-9,]/g, '') });
											}}
											required
											maxLength={6}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Peso Br.</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.pl}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, pl: e.target.value.replace(/[^0-9,]/g, '') });
											}}
											required
											maxLength={6}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Peso Liq.</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="date"
											value={inputsBarra.producao}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, producao: e.target.value });
											}}
											maxLength={50}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Produção</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="date"
											value={inputsBarra.validade}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, validade: e.target.value });
											}}
											maxLength={50}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Validade</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											value={inputsBarra.Lote}
											onChange={function (e) {
												setInputsBarra({ ...inputsBarra, Lote: e.target.value });
											}}
											maxLength={50}
											className="form-control bordasInferiorInput text-black"
											placeholder=""
										/>
										<label>Lote</label>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<BotaoCustomizado
							type="button"
							className="btn btn-light text-danger bg-white border-0"
							descricaoBotao="Fechar"
							onclick={function () {
								setInputsBarra({
									ua: '',
									id_produto: '',
									id_barra: 'Nova',
									barra: '',
									pb: '',
									pl: '',
									producao: '',
									validade: '',
									Lote: '',
								});
								setShowModalNovaBarra(false);
							}}
							icone={mdiKeyboardReturn}
						/>
						<BotaoCustomizado
							type="submit"
							className="btn btn-light text-dark bg-white border-0"
							descricaoBotao="Salvar"
							icone={mdiCheckCircle}
						/>
					</Modal.Footer>
				</form>
			</Modal>

			{/*MODAL DETALHADO POR U.A */}
			<Modal
				show={showModalDetalhado}
				size="xl"
				centered
				onHide={function () {
					setShowModalDetalhado(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Detalhado por U.A</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container m-0 p-0">
						{permissaogerarExcelDetalharPorUAEstoqueDetalhado && (
							<div className="row">
								<div className="col-sm col-md col-lg">
									<button
										onClick={function () {
											GerarExcelDetalhado(EstoqueDepositante);
										}}
										className="btn btn-success"
									>
										<i className="bi bi-filetype-xls me-1"></i>
										Gerar .xls
									</button>
								</div>
							</div>
						)}
					</div>
					<br />
					<ClickableTable
						coluna={colunasDetalhado}
						itemsPerPage={itensPorPaginaDetalhado}
						data={EstoqueDepositante}
						usaRowClick={false}
						onRowClick={function (ua) {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar={false}
					/>
				</Modal.Body>
			</Modal>

			{/*MODAL RESUMIDO */}
			<Modal
				show={showModalResumido}
				size="xl"
				centered
				onHide={function () {
					setShowModalResumido(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Resumido Validade</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="container m-0 p-0">
						<div className="row">
							<div className="col-sm col-md col-lg d-flex">
								{permissaogerarExcelResumoValidadeEstoqueDetalhado && (
									<button
										onClick={function () {
											GerarExcelResumido(dadosResumido);
										}}
										className="btn btn-success me-1"
									>
										<i className="bi bi-filetype-xls me-1"></i>
										Gerar .xls
									</button>
								)}
								{permissaogerarPDFResumoValidadeEstoqueDetalhado && (
									<button
										onClick={function () {
											GerarPDFResumido();
										}}
										className="btn btn-danger"
									>
										<i className="bi bi-file-earmark-pdf me-1"></i>
										Gerar .PDF
									</button>
								)}
							</div>
						</div>
					</div>
					<br />
					<ClickableTable
						coluna={colunasResumidoValidade}
						itemsPerPage={itensPorPaginaResumido}
						data={dadosResumido}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-3 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-2 m-auto">
								<select
									value={itensPorPaginaResumido}
									onChange={function (e: any) {
										setItensPorPaginaResumido(e.target.value);
									}}
									className="form-select form-select-sm text-center"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<ModalConfirmacao
				mostrar={showModalDelete}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal="Confirmar exclusão da barra ? "
				Acao1={function () {
					setShowModalDelete(false);
					setBarraSelecionada({});
				}}
				Acao2={function () {
					DeletarBarraUa(UAselecionada.id_ua, id_filial, depositanteSelecionado, UAselecionada.id_wms, barraSelecionada.barra);
				}}
			/>

			<div id="divisor" hidden>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
			<div id="pdf-content" className="bg-white p-2" hidden>
				<div className="container">
					<div className="row">
						<div className="col-sm col-md col-lg-12">
							<h3>Relátorio de Saldo por validade</h3>
						</div>
						<div className="col-sm col-md col-lg-12">
							<p>
								<b>Depositante: {depositanteFantasia}</b>
							</p>
						</div>
						<div className="col-sm col-md col-lg-12 d-flex">
							<p>
								Gerado por: {sessionStorage.getItem('IsUserFKL')!} - {new Date().toLocaleString()}
							</p>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row border-bottom border-dark px-2">
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Código</p>
						</div>
						<div className="col-sm col-md col-lg-3">
							<p className="mb-0 pb-0">Descrição</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">UN.Fiscal</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">UN.Manuseio</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Lote</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Validade</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Emb. Disponível</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Peso Liq. Disponível</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Bloq</p>
						</div>
					</div>
					{dadosResumido.map(function (dado: any) {
						return (
							<div className="row border-bottom border-dark p-1">
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.codigo}</p>
								</div>
								<div className="col-sm col-md col-lg-3">
									<p className="mb-0 pb-0">{dado.descricao}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.unid_fiscal}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.und_emb}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.lote}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.validade_menor_formatado}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.qtde_disp}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{MascaraValor(dado.pl.toString())}</p>
								</div>
								<div className="col-sm col-md col-lg-1">
									<p className="mb-0 pb-0">{dado.bloq == true ? 'S' : 'N'}</p>
								</div>
							</div>
						);
					})}
					<div className="row">
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0"></p>
						</div>
						<div className="col-sm col-md col-lg-3">
							<p className="mb-0 pb-0"></p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0"></p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0"></p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0"></p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">Total Geral:</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">{qtdeDisponivel}</p>
						</div>
						<div className="col-sm col-md col-lg-1">
							<p className="mb-0 pb-0">{MascaraValor(pesoLiqDisponivel.toString())}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EstoqueDetalhado;
