import { Link } from 'react-router-dom';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import Icon from '@mdi/react';
import {
	mdiArrowDownBoldBoxOutline,
	mdiArrowUpBoldBoxOutline,
	mdiEye,
	mdiFileExport,
	mdiFileImport,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
import ModalExclusao from '../../../../components/componentes/formulario/ModalExcuir';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as FileSaver from 'file-saver';
import { ModeloTabelaCfop64 } from '../../../../util/modeloTabelaCfop';
import Inputs from '../../../../components/componentes/formulario/Inputs';

export function CadastroCFOP() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';

	const [cfop, setCfop] = useState('');
	const [natoper, setNatoper] = useState('');
	const [tipoOp, setTipoOp] = useState('T');
	const [ativo, setAtivo] = useState('T');

	const [data, setData] = useState([]);

	//#region Requisição de dados na api
	const carregarDados = async () => {
		const response = await axios.get(Api + `/cfop/json/?&auth=${token}`);
		setData(response.data);
		setShowCarregando(false);
		setShowPesquisar(false);
	};
	useEffect(() => {
		setShowCarregando(true);
		carregarDados();
	}, []);

	//#endregion

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal PESQUISAR EMPRESA
	const [showPesquisar, setShowPesquisar] = useState(false);
	const fecharModalPesquisar = () => setShowPesquisar(false);
	//#endregion

	//#region Paginação,
	const [itensPerPage, setItensPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pages = Math.ceil(data.length / itensPerPage);

	const startIndex = currentPage * itensPerPage;
	const endIndex = startIndex + itensPerPage;
	const itensSelecionados = data.slice(startIndex, endIndex);
	//#endregion
	//#region Modifica pagina da lista de relatorios
	const primeiraPagina = () => {
		if (currentPage > 0) {
			setCurrentPage(0);
		}
	};
	const paginaAnterior = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(pages - 1);
		}
	};
	//#endregion

	//#region Modifica o status do Modal EXCLUIR EMPRESA
	const [showQuestionaExclusao, setShowQuestionaExclusao] = useState(false);
	//#endregion

	//#region Requisição para EXCLUIR uma CFOP
	const [cfopExclusao, setCfopExclusao] = useState('');
	const excluir = () => {
		axios.delete(Api + `/cfop/${cfopExclusao}/?auth=${token}`);
		setCfopExclusao('');
		setShowQuestionaExclusao(false);
		setShowCarregando(true);
		setTimeout(() => carregarDados(), 500);
	};
	//#endregion
	//#endregion
	const excluirCFOP = (cfop: string) => {
		setCfopExclusao(cfop);
		setShowQuestionaExclusao(true);
	};

	const readFileAndConvertToJson = (file: File, callback: (jsonData: any) => void) => {
		const reader = new FileReader();

		reader.onload = (event: any) => {
			const data = new Uint8Array(event.target.result as ArrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[0]; // Assuming you have only one sheet

			const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
			callback(jsonData);
		};

		reader.readAsArrayBuffer(file);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setShowCarregando(true);
			readFileAndConvertToJson(file, (dados) => {
				incluirCFOPEmMssa(dados); // You can do whatever you want with the JSON data
			});
		}
	};

	const incluirCFOPEmMssa = async (_dados: any) => {
		const qtd = _dados.length;
		for (let i = 0; i < qtd; i++) {
			if (
				_dados[i].CFOP !== undefined ||
				_dados[i].CFOP !== null ||
				_dados[i].CFOP !== '' ||
				_dados[i].NATUREZA_OPERACAO !== undefined ||
				_dados[i].NATUREZA_OPERACAO !== null ||
				_dados[i].NATUREZA_OPERACAO !== '' ||
				_dados[i].TIPO_OPERACAO !== undefined ||
				_dados[i].TIPO_OPERACAO !== null ||
				_dados[i].TIPO_OPERACAO !== ''
			) {
				const dados = {
					cfop: _dados[i].CFOP,
					natoper: _dados[i].NATUREZA_OPERACAO,
					tipo_cfop: _dados[i].TIPO_OPERACAO,
					ativo: '1',
					usuario: usuario,
				};
				axios
					.post(Api + `/cfop/?&auth=${token}`, dados)
					.then((response) => {
						if (i === qtd - 1) {
							setShowCarregando(false);
							toast.success(`Importação concluída com sucesso!`, {
								position: 'top-right',
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: false,
								draggable: true,
								progress: undefined,
								theme: 'dark',
							});
							setTimeout(() => {
								setShowCarregando(true);
								carregarDados();
							}, 500);
						} else {
							setShowCarregando(false);
							setTimeout(() => {
								setShowCarregando(true);
								carregarDados();
							}, 500);
						}
					})
					.catch((erro) => {
						setShowCarregando(false);
						toast.error(`${erro.response.data.message}`, {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: 'dark',
						});
						setTimeout(() => {
							setShowCarregando(true);
							carregarDados();
						}, 500);
					});
			} else {
				setShowCarregando(false);
				toast.error(`Falha ao importar tabela de CFOP. Motivo: tabela com dados inconsistentes.`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			}
		}
	};
	const downloadExcelFromBase64 = (base64String: string, fileName: string) => {
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

		FileSaver.saveAs(blob, fileName);
	};
	const handleDownload = () => {
		const base64String = ModeloTabelaCfop64; // Replace with your base64 string
		const fileName = 'modelo_tabela_cfop.xlsx';
		downloadExcelFromBase64(base64String, fileName);
	};

	//#region PESQUISAR DADOS
	const pesquisar = async (cfop: string, natoper: string, tipo_cfop: string, ativo: string) => {
		setShowCarregando(true);
		const dados = {
			cfop,
			natoper,
			tipo_cfop,
			ativo,
		};
		axios
			.post(Api + `/cfop/pesquisar/?&auth=${token}`, dados)
			.then((response) => {
				setData(response.data);
				setShowPesquisar(false);
				setShowCarregando(false);
			})
			.catch((erro) => {
				setShowCarregando(false);
				toast.error(`${erro.response.data.message}`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			});
	};

	const pesquisarDados = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setShowCarregando(true);
		if (cfop === '' && natoper === '' && tipoOp === 'T' && ativo === 'T') {
			carregarDados();
		} else {
			pesquisar(cfop, natoper, tipoOp, ativo);
		}
	};
	//#endregion
	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Listagem de CFOP's" />
			</div>
			<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-8">
									<Link to={`/pco/contrFisc/contrNotFisc/cfop/novo`} className="btn btn-novo mb-2 rounded">
										<i className="mdi mdi-plus-circle me-2"></i>
										Novo CFOP
									</Link>{' '}
									<label htmlFor="impExcel" className="btn btn-success rounded">
										<Icon path={mdiFileImport} size={0.75} className="me-2" /> Importar Tabela de CFOP [Excel]
									</label>
									<input id="impExcel" type="file" style={{ display: 'none' }} accept=".xls,.xlsx" onChange={handleFileChange} />{' '}
									<button type="button" onClick={handleDownload} className="btn btn-info rounded">
										<Icon path={mdiFileExport} size={0.75} className="me-2" /> Baixar Modelo
									</button>
								</div>

								<div className="col-sm-4">
									<Button
										variant="success"
										onClick={() => setShowPesquisar(true)}
										className="mb-2 rounded"
										style={{ float: 'right', color: 'white'}}
									>
										Pesquisar
										<Icon path={mdiMagnify} size={1} className="me-2" />
									</Button>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
									<thead className="table-cabecalho">
										<tr>
											<th>CFOP</th>
											<th>Natureza de Operação</th>
											<th style={{ width: '120px' }}>Tipo</th>
											<th>Status</th>
											<th style={{ width: '85px' }}>Ações</th>
										</tr>
									</thead>
									<tbody className="corpoTabela">
										{itensSelecionados.map(function (dados: any) {
											return (
												<tr>
													<th scope="row">{dados.cfop.replace(/(\d{1})(\d{3})$/, '$1.$2')}</th>
													<td>{dados.natoper}</td>
													<td>
														{dados.tipo_cfop === 'Entrada' ? (
															<Icon
																path={mdiArrowUpBoldBoxOutline}
																size={0.75}
																style={{ color: '#005800' }}
																className="me-2"
															/>
														) : (
															<Icon
																path={mdiArrowDownBoldBoxOutline}
																size={0.75}
																style={{ color: '#8d0101' }}
																className="me-2"
															/>
														)}
														{dados.tipo_cfop}
													</td>
													<td>
														<label
															style={{
																background: dados.corstatus,
																color: 'white',
																width: '80px',
																textAlign: 'center',
																borderRadius: '20px',
															}}
														>
															{dados.ativo}
														</label>
													</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/pco/contrFisc/contrNotFisc/cfop/visu/${dados.cfop}`}
															className="action-icon"
															title="Visualizar"
														>
															<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
														</Link>
														<Link
															to={`/pco/contrFisc/contrNotFisc/cfop/editar/${dados.cfop}`}
															className="action-icon"
															title="Editar"
														>
															<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
														</Link>
														<a onClick={() => excluirCFOP(dados.cfop)} className="action-icon" title="Excluir">
															<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
														</a>
													</td>
												</tr>
											);
										})}
									</tbody>
									<tfoot>
										<tr className="rodapeTabelaPaginacao">
											<td colSpan={14}>
												<a onClick={primeiraPagina} title="Primeira Página">
													<Icon path={mdiSkipBackward} size={1} />
												</a>
												<a onClick={paginaAnterior} title="Página Anterior">
													<Icon path={mdiSkipPrevious} size={1} />
												</a>
												Página: {currentPage + 1} de {pages} | Registros Total: {data.length}
												<a onClick={proxPagina} title="Próxima Página">
													<Icon path={mdiSkipNext} size={1} />
												</a>
												<a onClick={ultimaPagina} title="Última Página">
													<Icon path={mdiSkipForward} size={1} />
												</a>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer newestOnTop />
			</div>
			<ModalCarregando show={showCarregando} />
			<ModalExclusao show={showQuestionaExclusao} onClose={() => setShowQuestionaExclusao(false)} onConfirm={excluir} />
			{/*MODAL PESQUISAR */}
			<Modal show={showPesquisar} backdrop="static" size="xl" keyboard={false} centered>
				<Modal.Header>
					<Modal.Title>Pesquisar CFOP</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={pesquisarDados}>
						<div className="row">
							<div className="col-sm-2">
								<Inputs label="CFOP" type="text" name="cfop" classNameInput="form-control" 
								classNameLabel="form-label" value={cfop} onchange={setCfop} 
								maxLength={4}/>
							</div>

							<div className="col-sm-6">
								<Inputs
									label="Natureza de Operação"
									type="text"
									name="natOp"
									classNameInput="form-control"
									classNameLabel="form-label"
									value={natoper}
									onchange={setNatoper}
								/>
							</div>
							<div className="col-sm-2">
								<label className="form-label" htmlFor="tipoOp">
									Tipo Operação
								</label>
								<select
									className="form-select"
									id="tipoOp"
									name="tipoOp"
									value={tipoOp}
									onChange={(e) => setTipoOp(e.target.value)}
									required
								>
									<option value="T">Todos</option>
									<option value="E">Entrada</option>
									<option value="S">Saída</option>
								</select>
							</div>
							<div className="col-sm-2">
								<div className="mb-1">
									<label className="form-label" htmlFor="statuscfop">
										Status
									</label>
									<select
										className="form-select"
										id="statuscfop"
										name="statuscfop"
										value={ativo}
										onChange={(e) => setAtivo(e.target.value)}
										required
									>
										<option value="T">Todos</option>
										<option value="1">Ativo</option>
										<option value="0">Inativo</option>
									</select>
								</div>
							</div>
						</div>

						<hr />

						<div className="d-flex my-2 gap-1 justify-content-between">
							<Button style={{float: 'left' }} className='rounded' onClick={fecharModalPesquisar}>
								<Icon path={mdiKeyboardReturn} size={1} className="me-2" /> Voltar{' '}
							</Button>
							<Button variant="success" className='rounded' type="submit">
								{' '}
								<Icon path={mdiMagnify} size={1} className="me-2" />
								Filtrar
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}
