import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { url } from 'inspector';
import { Api } from '../../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiKeyboardReturn, mdiMagnify, mdiPlusCircle, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';

const Filial = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const usuarioLogado = sessionStorage.getItem("IsUserFKL")
	const idFilialLogado = sessionStorage.getItem("IsIDFilialPadraoFKL")
	const idUsuario = sessionStorage.getItem("IsIDUserFKL")
	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
	const [idFilial, setIdFilial] = useState()
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false)
	const [CNPJ, setCNPJ] = useState<string>()

	const navigate = useNavigate()

	const editarFilial = (dados: any) => {
		navigate(`/config/cadast/filial/${dados.id_filial}/editar`)
	};
	const excluirFilial = (dados: any) => {
		setCNPJ(dados.cnpj)
		setIdFilial(dados.id_filial)
		setMostraModalConfirm(true)
	};
	const visualizarFilial = (dados: any) => {
		navigate(`/config/cadast/filial/${dados.id_filial}/visualizar`)
	};


	//Criação das colunas da tabela
	const colunas: ITabela[] = [
		{ titulo: "", acesso: `avatar` },
		{ titulo: "Id", acesso: "id_filial" },
		{ titulo: "Status", acesso: "ativo" },
		{ titulo: "Filial", acesso: "fantasia" },
		{ titulo: "Endereço", acesso: "logradouro" },
		{ titulo: "Cidade", acesso: "cidade" },
		{ titulo: "Usuário", acesso: "usuario" }
	]

	const handleRowClick = () => { }

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/filial/${idUsuario}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregar(false)
			setData(resposta.data.data)
		}).catch(function (erro) {
			setMostraModalCarregar(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data)
			}
		})
	}

	//#region -> Excluir
	function deleteFilial() {
		setMostraModalCarregar(true)
		setMostraModalConfirm(false)
		axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/usuario/${idFilial}/${usuarioLogado}/${CNPJ}/${idFilialLogado}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregar(false)
			toast.success(resposta.data.message)
			carregarDados()
		}).catch(function (erro) {
			setMostraModalCarregar(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	useEffect(() => {
		carregarDados()
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem das Filiais</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body" >
								<div className="row">
									<div className="col d-flex justify-content-start">
										<Link className='btn btn-secondary' to={`/config/cadast/filial/novafilial/criar`}>
											<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
											Nova Filial
										</Link>
									</div>
								</div>
								<div className="">

									<ClickableTable
										coluna={colunas}
										itemsPerPage={10}
										data={data}
										onRowClick={handleRowClick}
										usaAcoes={true}
										acaoEditar={editarFilial}
										acaoExcluir={excluirFilial}
										acaoVisualizar={visualizarFilial}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa='fantasia'
										labelpesquisa='Procurar por fantasia'
									/>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal
				// show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1='Cancelar'
				msgAcao2='Confirmar'
				msgPrincipal='Deseja excluir a filial'
				Acao1={function () { setMostraModalConfirm(false) }}
				Acao2={deleteFilial}
			/>

			<ModalCarregando
				mostrar={mostraModalCarregar}
				mensagem='Processando exclusão'
			/>

		</>
	);
};

export { Filial };
