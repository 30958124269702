import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import ClickableTable from '../../../ClickableTable/ClickableTabl';
import { BotaoCustomizado } from '../BotaoCustomizado';
import { mdiKeyboardReturn } from '@mdi/js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalProps {
	visible: boolean;
	colunas: any[];
	onClose: () => void;
	fetchUrl: string;
	onSelect: (dadosSelecionados: any) => void;
	filtro?: string;
	textoFiltro?: string;
}

const ModalPesquisa: React.FC<ModalProps> = ({ visible, colunas, onClose, fetchUrl, onSelect, filtro, textoFiltro }) => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const [data, setData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (visible) {
			buscarDados();
		}
	}, [visible]);

	const buscarDados = async () => {
		setLoading(true);
		try {
			const response = await axios.get(fetchUrl, {
				headers: {
					authorization: token,
				},
			});
			if (response.data.length === 0) {
				return toast.error('A pesquisa não obteve resultados.');
			}
			setData(response.data);
		} catch (error: any) {
			const erro = error.response?.data.erro !== undefined ? error.response.data.erro : error;
			const msg = error.response?.data.message !== undefined ? error.response.data.message : error.message;
			toast.error(`Falha ao consultar dados. Motivo: ${msg} - ${erro}`);
		} finally {
			setLoading(false);
		}
	};

	const handleRowClick = (dados: any) => {
		onSelect(dados);
	};

	return (
		<Modal show={visible} size="xl" backdrop="static" keyboard={false} >
			<Modal.Header>
				<Modal.Title>Selecione um item</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<div>Loading...</div>
				) : (
					<ClickableTable
						coluna={colunas}
						itemsPerPage={10}
						data={data}
						onRowClick={() => {}}
						usaAcoes={true}
						acaoEditar={handleRowClick}
						acaoExcluir={() => {}}
						acaoVisualizar={() => {}}
						usaVisualizar={false}
						usaEditar={true}
						usaExcluir={false}
						acessopesquisa={filtro}
						iconeEditar="bi bi-check2"
						labelpesquisa={textoFiltro}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<BotaoCustomizado
					type="button"
					className="btn btn-light text-danger bg-white border-0"
					onclick={onClose}
					descricaoBotao="Voltar"
					icone={mdiKeyboardReturn}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalPesquisa;
