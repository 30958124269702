export const QtdeDiasNF = [
	{
		value: '15',
		label: '15 Dias',
	},
	{
		value: '30',
		label: '30 Dias',
	},
	{
		value: '45',
		label: '45 Dias',
	},
	{
		value: '60',
		label: '60 Dias',
	},
	{
		value: '75',
		label: '75 Dias',
	},
	{
		value: '90',
		label: '90 Dias',
	},
	{
		value: '120',
		label: '120 Dias',
	},
	{
		value: '150',
		label: '150 Dias',
	},
	{
		value: '180',
		label: '180 Dias',
	},
	
	
];