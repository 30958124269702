function ConverterEmBase64(file: Blob) {

    return new Promise(function (resolve, reject) {

        try {
            const reader = new FileReader()
            reader.onload = function () {

                resolve(reader.result)
            }

            reader.readAsDataURL(file)
        } catch (error) {

            reject(error)
        }
    })
}

export default ConverterEmBase64