import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../services/api';
import { mdiBarcode, mdiCheckCircle, mdiFileDocumentPlusOutline, mdiKeyboardReturn, mdiMicrosoftExcel, mdiPrinterPosCheckOutline } from '@mdi/js';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import { toast, ToastContainer } from 'react-toastify';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import InputSemZeroEsquerda from '../../../components/componentes/formulario/InputSemZeroEsquerda';
import InputSelect from '../../../components/componentes/formulario/InputSelect';
import Icon from '@mdi/react';
import { Modal } from 'react-bootstrap';
import InputDecimal from '../../../components/componentes/formulario/InputDecimal';
import ModalConfirmacao from '../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { ExportarExcel } from '../../../components/componentes/types/exportarExcel/gerarExcel';
import { colunasExcel } from './components/colunasExcel';
import { PdfDocument } from './components/gerarEtiquetaUA';
import JsBarcode from 'jsbarcode';
import { PdfDocumentParams } from './components/gerarEtiquetaUA/interface';
import logoPadrao from '../../../assets/img/logo3.png';
import imageToBase64 from 'image-to-base64/browser';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

const RomaneioBarrasAtivas = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);

	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoImprimirTodos, setPermissaoImprimirTodos] = useState(false);
	const [permissaoExportar, setPermissaoExportar] = useState(false);
	const [permissaoVerTodas, setPermissaoVerTodas] = useState(false);
	const [permissaoGerarExcelDetalhes, setPermissaoGerarExcelDetalhes] = useState(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);
	const [permissaoImprimirEtqItem, setPermissaoImprimirEtqItem] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarRomaneioBarrasAtivas) {
				toast.warning('Você não tem permissão para acessar Romaneio Barras Ativas.');
				navigate('/');
			} else {
				setPermissaoCriar(permissoes.perfilPermissao.criarRomaneioBarrasAtivas);
				setPermissaoImprimirTodos(permissoes.perfilPermissao.imprimirTodasBarrasRomaneioBarrasAtivas);
				setPermissaoExportar(permissoes.perfilPermissao.gerarExcelRomaneioBarrasAtivas);
				setPermissaoVerTodas(permissoes.perfilPermissao.verTodasRomaneioBarrasAtivas);
				setPermissaoGerarExcelDetalhes(permissoes.perfilPermissao.gerarExcelDetalhesRomaneioBarrasAtivas);
				setPermissaoEditar(permissoes.perfilPermissao.editarRomaneioBarrasAtivas);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirRomaneioBarrasAtivas);
				setPermissaoImprimirEtqItem(permissoes.perfilPermissao.imprimirItemRomaneioBarrasAtivas);
			}
		}
	}, [permissoes, erro, navigate]);

	const [data, setData] = useState([]);

	// dados para a etiqueta
	const [dadosEtiqueta, setDadosEtiqueta] = useState<any[]>([]);
	const [tipoEtiqueta, setTipoEtiqueta] = useState<string>('');
	const [alturaEtiqueta, setAlturaEtiqueta] = useState<number>(0);
	const [larguraEtiqueta, setLarguraEtiqueta] = useState<number>(0);
	const [orientacaoEtiqueta, setOrientacaoEtiqueta] = useState<string>('retrato');

	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	// modal adicionar barras
	const [mostraModalAdicionar, setMostraModalAdicionar] = useState<boolean>(false);

	// modal editar barras
	const [mostraModalEditarBarras, setMostraModalEditarBarra] = useState<boolean>(false);
	const [id_barra_editar, setIdBarraEditar] = useState<string>('');

	// modal ver todas as barras
	const [mostraModalVerTodasBarras, setMostraModalVerTodasBarras] = useState<boolean>(false);
	const [dadosVerTodasBarras, setDadosVerTodasBarras] = useState<any[]>([]);

	// dados modal adicionar barras
	const [barra, setBarra] = useState<string>('');
	const [pb, setPB] = useState<number>(0); //Peso Bruto
	const [pl, setPL] = useState<number>(0); //Peso Liquido
	const [producao, setProducao] = useState<string>('');
	const [validade, setValidade] = useState<string>('');
	const [lote, setLote] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);

	// dados para o modal de confirmação
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [idBarraExcluir, setIdBarraExcluir] = useState<string>('');
	const [barrasExcluir, setBarrasExcluir] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');

	// dados
	const [depositante, setDepositante] = useState<string>('');
	const [nomeDepositante, setNomeDepositante] = useState<string>('');
	const [codigo, setCodigo] = useState<string>('');
	const [idwms, setIdWMS] = useState<string>('');
	const [ua, setUA] = useState<string>('');

	// Carregar Listagem de Depositante
	const [dadosDepositante, setDadosDepositante] = useState<any[]>([]);

	// Carregar Listagem de Codigo
	const [dadosCodigo, setDadosCodigo] = useState<any[]>([]);

	// Carregar Listagem de UA
	const [dadosUA, setDadosUA] = useState<any[]>([]);

	//#region Buscar depositantes da filial
	function ListarDepositante() {
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/buscar/depositante/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDepositante(resposta.data.depositante);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	//#region Selecionar o Depositante
	const SelecionaDepositante = (depositante: string) => {
		setDepositante(depositante);

		// ao selecionar um depositante fazer um filtro em dadosdepositante e pegar o nome do depositante
		if (depositante) {
			const filtro = dadosDepositante.filter((item) => item.value === parseInt(depositante));
			setNomeDepositante(filtro[0].label);
		} else {
			setNomeDepositante('');
		}
		setCodigo('');
		setUA('');
	};

	//#endregion

	//#region Buscar codigos da filial apos selecionar o depositante
	function ListarCodigo() {
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/buscar/codigo/${id_filial}/${depositante}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosCodigo(resposta.data.codigo);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	//#region Selecionar o Código
	const SelecionaCodigo = (codigo: string) => {
		setCodigo(codigo);
		setUA('');
		// ao selecionr um codigo fazer um filtro em dadoscodigo e pegar o idwms
		if (codigo) {
			const filtro = dadosCodigo.filter((item) => item.value === codigo);
			setIdWMS(filtro[0].id_wms);
		} else {
			setIdWMS('');
		}
	};

	//#endregion

	//#region Buscar os id u.a. da filial apos selecionar o depositante e o codigo
	function ListarIdUA() {
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/buscar/id_ua/${id_filial}/${depositante}/${codigo}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosUA(resposta.data.id_ua);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	//#region Selecionar o id U.A
	const SelecionaIdUA = (id_ua: string) => {
		setUA(id_ua);
	};

	//#endregion

	//#region Inicio novo desenvolvimento de funções

	function carregarDados(id_filial: string, id_cliente: string, codigo: string, ua: string) {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Romaneio de Barras Ativas, aguarde...');
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/listar/${id_filial}/${id_cliente}/${codigo}/${ua}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.romaneio_barras_ativas);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	useEffect(() => {
		if (depositante && codigo && ua) {
			carregarDados(id_filial, depositante, codigo, ua);
		} else {
			setData([]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depositante, codigo, ua]);
	function validarUA(id_filial: string, id_cliente: string, codigo: string, ua: string) {
		if (!depositante || !codigo || !ua) {
			toast.error('Selecione o Depositante, o Código e a U.A.');
			return;
		}
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Romaneio de Barras Ativas, aguarde...');
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/validar/${id_filial}/${id_cliente}/${codigo}/${ua}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setMostraModalAdicionar(true);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	const dadosEnvioBarra = {
		id_wms: idwms,
		barra: barra,
		pb: pb,
		pl: pl,
		producao: producao,
		validade: validade,
		lote: lote,
		usuario: usuario,
	};

	// Incluir Barra no Romaneio de Barras Ativas
	function incluirBarra(dadosEnvioBarra: any) {
		axios
			.post(`${Api}/estoque/romaneiobarrasativas/incluir/barras/${id_filial}/${depositante}/${codigo}/${ua}`, dadosEnvioBarra, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);
				setBarra('');
				setPB(0);
				setPL(0);
				setProducao('');
				setValidade('');
				setLote('');
				setMostraModalAdicionar(false);
				carregarDados(id_filial, depositante, codigo, ua);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	// Editar Barra no Romaneio de Barras Ativas
	function editarBarrasAtivas(dadosEnvioBarra: any) {
		axios
			.put(
				`${Api}/estoque/romaneiobarrasativas/editar/barras/${id_filial}/${depositante}/${codigo}/${ua}/${id_barra_editar}`,
				dadosEnvioBarra,
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				toast.success(resposta.data.message);
				setBarra('');
				setIdBarraEditar('');
				setPB(0);
				setPL(0);
				setProducao('');
				setValidade('');
				setLote('');
				setMostraModalEditarBarra(false);
				carregarDados(id_filial, depositante, codigo, ua);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	const editarBarra = (dados: any) => {
		setIdBarraEditar(dados.id_barra);
		setBarra(dados.barra);
		setPB(dados.pb);
		setPL(dados.pl);
		setProducao(dados.producao.split('T')[0]);
		setValidade(dados.validade.split('T')[0]);
		setLote(dados.lote);

		setMostraModalEditarBarra(true);
	};

	const excluirBarra = (dados: any) => {
		setIdBarraExcluir(dados.id_barra);
		setBarrasExcluir(dados.barra);
		setMsgPrincipalModal(`Deseja excluir a barra ${dados.barra} ?`);
		setMsgAcao1('Cancelar');
		setMostraModalConfirm(true);
	};

	// Excluir Barra do Romaneio de Barras Ativas
	const deleteBarras = () => {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setMsgModalCarregar('Excluindo Barras, Aguarde...');
		axios
			.delete(
				Api +
					`/estoque/romaneiobarrasativas/excluir/barras/${id_filial}/${depositante}/${codigo}/${ua}/${idBarraExcluir}/${barrasExcluir}/${usuario}`,
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(resposta.data.message);
				setIdBarraExcluir('');
				setBarrasExcluir('');
				carregarDados(id_filial, depositante, codigo, ua);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setIdBarraExcluir('');
				setBarrasExcluir('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	//#region Modal Carregar Todas as Barras
	function carregarTodasBarras(id_filial: string, id_cliente: string) {
		if (id_cliente === '') {
			toast.error('Selecione o Depositante');
			return;
		}
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Todas as Barras do Depositante, aguarde...');
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/listar/todas/${id_filial}/${id_cliente}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setDadosVerTodasBarras(resposta.data.romaneio_barras_depositante);
				setMostraModalVerTodasBarras(true);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	//#region Exportar Barras Ativas em .xlsx
	const exportarBarrasUA = async (id_filial: string, id_cliente: string, codigo: string, ua: string) => {
		if (!id_cliente || !codigo || !ua) {
			toast.error('Selecione o Depositante, Código e U.A.');
			return;
		}
		setMostraModalCarregar(true);
		setMsgModalCarregar('Exportando Barras Ativas, aguarde...');
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/exportar/barras/${id_filial}/${id_cliente}/${codigo}/${ua}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				ExportarExcel(resposta.data.romaneio_barras_ativas, colunasExcel, 'Romaneio Barras Ativas.xlsx', 'Barras');
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message || erro.message);
				}
			});
	};
	//#endregion

	//#region Carregar Todas as Barras do Depositante - Para Excel
	function carregarTodasBarrasExcel(id_filial: string, id_cliente: string) {
		if (id_cliente === '') {
			toast.error('Selecione o Depositante');
			return;
		}
		setMostraModalCarregar(true);
		setMsgModalCarregar('Exportando Todas as Barras do Depositante, aguarde...');
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/todas/excel/${id_filial}/${id_cliente}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				ExportarExcel(resposta.data.romaneio_barras_depositante, colunasExcel, 'Romaneio Barras Ativas Depositante.xlsx', 'Barras');
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	//#region Carregar Dimensoes da Etiqueta
	function carregarEtiqueta() {
		axios
			.get(`${Api}/estoque/romaneiobarrasativas/listar/etiqueta/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosEtiqueta(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	const selecionarEtiqueta = (tipo: string) => {
		setTipoEtiqueta(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosEtiqueta.filter((item) => item.value === tipo);
			setAlturaEtiqueta(parseFloat(filtro[0].defusr01));
			setLarguraEtiqueta(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiqueta(filtro[0].defusr03);
		}
	};

	const converterTextoParaImagemBase64 = (barcode: string): string => {
		const canvas = document.createElement('canvas');
		JsBarcode(canvas, barcode, {
			format: 'CODE128',
			displayValue: false,
		});
		const dataURL = canvas.toDataURL('image/png');
		const base64 = dataURL;
		return base64;
	};

	const imprimirTodos = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(item.barra);
			const dados: PdfDocumentParams = {
				tamanho: {
					altura: orientacaoEtiqueta?.toLowerCase() !== 'retrato' ? larguraEtiqueta : alturaEtiqueta,
					largura: orientacaoEtiqueta?.toLowerCase() !== 'retrato' ? alturaEtiqueta : larguraEtiqueta,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				nomeDepositante: nomeDepositante,
				codigoUA: ua,
				codigoBarras: barcode,
				textoCodigoBarras: item.barra,
				descricao: item.descricao,
				codigo: item.codigo,
				pesoliquido: item.pl,
				datavalidade: item.datavalidade,
				orientacao: orientacaoEtiqueta?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiqueta = PdfDocument({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiqueta();
	};

	// Uso da função
	const gerarEtiquetaUA = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}
		if (tipoEtiqueta === '') {
			toast.error('Selecione o tipo de etiqueta');
			return;
		}
		imprimirTodos(dados); // Passa os dados para a função imprimirTodos
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Romaneio Barras Ativas</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<form
									onSubmit={(e) => {
										e.preventDefault();
									}}
								>
									<div className="row">
										<div className="col-sm col-md col-lg">
											<InputSelect
												label="Depositante"
												name="depositante"
												value={depositante}
												onChange={SelecionaDepositante}
												dados={dadosDepositante}
												required={true}
												onClick={ListarDepositante} //carregarDepositante
												disabled={false}
												campoValue="label"
											/>
										</div>
										<div className="col-sm col-md col-lg">
											<InputSelect
												label="Código"
												name="codigo"
												value={codigo}
												onChange={SelecionaCodigo}
												dados={dadosCodigo}
												required={true}
												onClick={ListarCodigo} //carregarCodigo
												disabled={!depositante}
											/>
										</div>
										<div className="col-sm col-md col-lg">
											<InputSelect
												label="U.A."
												name="ua"
												value={ua}
												onChange={SelecionaIdUA}
												dados={dadosUA}
												required={true}
												onClick={ListarIdUA} //carregarUA
												disabled={!codigo}
												// campoValue='value'
											/>
										</div>
										<div className="col-sm col-md col-lg">
											<InputSelect
												label="Medida Etiqueta"
												name="etiqueta"
												value={tipoEtiqueta}
												onChange={selecionarEtiqueta}
												dados={dadosEtiqueta}
												required={true}
												onClick={carregarEtiqueta}
												disabled={false}
												campoValue="value"
											/>
										</div>
									</div>
									<div className="row mt-2">
										<div className="col-12 d-flex flex-wrap">
											{permissaoCriar && (
												<button
													type="button"
													className="btn btn-primary me-1 flex-fill mb-2"
													onClick={function () {
														validarUA(id_filial, depositante, codigo, ua);
													}}
												>
													<Icon path={mdiFileDocumentPlusOutline} size={1} className="fs-4 me-1" /> Inserir Barra
												</button>
											)}

											{permissaoImprimirTodos && (
												<button
													className="btn btn-danger me-1 flex-fill mb-2"
													type="button"
													onClick={() => gerarEtiquetaUA(data)}
												>
													<Icon path={mdiPrinterPosCheckOutline} size={1} className="fs-4 me-1" /> Imprimir Todas
												</button>
											)}

											{permissaoExportar && (
												<button
													className="btn btn-success me-1 flex-fill mb-2"
													type="button"
													onClick={() => {
														exportarBarrasUA(id_filial, depositante, codigo, ua);
													}}
												>
													<Icon path={mdiMicrosoftExcel} size={1} className="fs-4 me-1" /> Exportar .xlsx
												</button>
											)}

											{permissaoVerTodas && (
												<button
													className="btn btn-info me-1 flex-fill mb-2"
													type="button"
													onClick={() => carregarTodasBarras(id_filial, depositante)}
												>
													<Icon path={mdiBarcode} size={1} className="fs-4 me-1" /> Ver Todas Barras
												</button>
											)}
										</div>
									</div>
								</form>
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes
										acaoEditar={editarBarra}
										acaoExcluir={excluirBarra}
										acaoVisualizar={gerarEtiquetaUA}
										iconeVisualizar="mdi mdi-printer"
										usaVisualizar={permissaoImprimirEtqItem}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="barra"
										labelpesquisa="Procurar por Barras Ativas"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />

			{/* MODAL ADICIONAR BARRAS */}
			<Modal
				show={mostraModalAdicionar}
				// onHide={() => {
				// 	setMostraModalAdicionar(false);
				// }}
				size="lg"
				keyboard={false}
				backdrop="static"
				centered
			>
				<Modal.Header>
					<Modal.Title>Adicionar Barra</Modal.Title>
				</Modal.Header>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						incluirBarra(dadosEnvioBarra);
					}}
				>
					<Modal.Body>
						<div className="row">
							<div className="col-sm-12">
								<InputSemZeroEsquerda
									label="Barra"
									id="barra"
									name="barra"
									value={barra}
									onChange={setBarra}
									required={true}
									disabled={false}
									maxLength={50}
								/>
							</div>
							<div className="col-sm-6">
								<InputDecimal label="Peso Bruto" name="pb" id="pb" value={pb} onChange={setPB} required={true} disabled={false} />
							</div>
							<div className="col-sm-6">
								<InputDecimal label="Peso Líquido" name="pl" id="pl" value={pl} onChange={setPL} required={true} disabled={false} />
							</div>
							<div className="col-sm-6">
								<div className="form-floating mb-1">
									<input
										type="date"
										className="form-control bordasInferiorInput text-black"
										id="producao"
										name="producao"
										value={producao}
										onChange={(e) => setProducao(e.target.value)}
										required
									/>
									<label className="form-label">Produção</label>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-floating mb-1">
									<input
										type="date"
										className="form-control bordasInferiorInput text-black"
										id="validade"
										name="validade"
										value={validade}
										onChange={(e) => setValidade(e.target.value)}
										required
									/>
									<label className="form-label">Validade</label>
								</div>
							</div>
							<div className="col-sm">
								<InputSemZeroEsquerda
									label="Lote"
									name="lote"
									id="lote"
									type="text"
									value={lote}
									onChange={setLote}
									required={true}
									disabled={false}
									maxLength={20}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={() => {
										setBarra('');
										setPB(0);
										setPL(0);
										setProducao('');
										setValidade('');
										setLote('');
										setMostraModalAdicionar(false);
									}}
								/>
								{permissaoCriar && (
									<BotaoCustomizado
										type="submit"
										className="btn btn-light text-info-emphasis bg-white border-0"
										descricaoBotao="Salvar"
										icone={mdiCheckCircle}
									/>
								)}
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>

			{/* MODAL EDITAR BARRAS */}
			<Modal show={mostraModalEditarBarras} size="lg" keyboard={false} backdrop="static" centered>
				<Modal.Header>
					<Modal.Title>Editar Barra</Modal.Title>
				</Modal.Header>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						editarBarrasAtivas(dadosEnvioBarra);
					}}
				>
					<Modal.Body>
						<div className="row">
							<div className="col-sm-12">
								<InputSemZeroEsquerda
									label="Barra"
									id="barra"
									name="barra"
									value={barra}
									onChange={setBarra}
									required={true}
									disabled
									maxLength={50}
								/>
							</div>
							<div className="col-sm-6">
								<InputDecimal label="Peso Bruto" name="pb" id="pb" value={pb} onChange={setPB} required={true} disabled={false} />
							</div>
							<div className="col-sm-6">
								<InputDecimal label="Peso Líquido" name="pl" id="pl" value={pl} onChange={setPL} required={true} disabled={false} />
							</div>
							<div className="col-sm-6">
								<div className="form-floating mb-1">
									<input
										type="date"
										className="form-control bordasInferiorInput text-black"
										id="producao"
										name="producao"
										value={producao}
										onChange={(e) => setProducao(e.target.value)}
										required
									/>
									<label className="form-label">Produção</label>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-floating mb-1">
									<input
										type="date"
										className="form-control bordasInferiorInput text-black"
										id="validade"
										name="validade"
										value={validade}
										onChange={(e) => setValidade(e.target.value)}
										required
									/>
									<label className="form-label">Validade</label>
								</div>
							</div>
							<div className="col-sm">
								<InputSemZeroEsquerda
									label="Lote"
									name="lote"
									id="lote"
									type="text"
									value={lote}
									onChange={setLote}
									required={true}
									disabled={false}
									maxLength={20}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={() => {
										setBarra('');
										setPB(0);
										setPL(0);
										setProducao('');
										setValidade('');
										setLote('');
										setMostraModalEditarBarra(false);
									}}
								/>
								{permissaoEditar && (
									<BotaoCustomizado
										type="submit"
										className="btn btn-light text-info-emphasis bg-white border-0"
										descricaoBotao="Salvar"
										icone={mdiCheckCircle}
									/>
								)}
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteBarras}
			/>

			{/* MODAL VER TODAS AS BARRAS - Ira abrir o grid com as barras e acima do grid terá o botão exportar para excel */}
			<Modal
				show={mostraModalVerTodasBarras}
				onHide={() => {
					setMostraModalVerTodasBarras(false);
				}}
				size="xl"
				keyboard={false}
				backdrop="static"
				centered
			>
				<Modal.Header>
					<Modal.Title>Ver Todas as Barras Ativas do Depositante</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							{permissaoGerarExcelDetalhes && (
								<div className="col d-flex justify-content-start">
									<button
										className="btn btn-success"
										onClick={() => {
											carregarTodasBarrasExcel(id_filial, depositante);
										}}
									>
										<Icon path={mdiMicrosoftExcel} size={1} className="me-2 rounded" />
										Exportar .xlsx
									</button>
								</div>
							)}
							<div className="col-lg-12">
								<ClickableTable
									coluna={colunasGrid}
									itemsPerPage={itensPorPagina}
									data={dadosVerTodasBarras}
									onRowClick={() => {}}
									usaAcoes={false}
									acaoEditar={() => {}}
									acaoExcluir={() => {}}
									acaoVisualizar={() => {}}
									iconeVisualizar="mdi mdi-printer"
									usaVisualizar={true}
									usaEditar={true}
									usaExcluir={true}
									acessopesquisa="barra"
									labelpesquisa="Procurar por Barras Ativas"
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="col-sm">
						<div className="text-sm-end">
							<BotaoCustomizado
								type="button"
								className="btn btn-light text-danger bg-white border-0"
								descricaoBotao="Voltar"
								icone={mdiKeyboardReturn}
								onclick={() => {
									setDadosVerTodasBarras([]);
									setMostraModalVerTodasBarras(false);
								}}
							/>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { RomaneioBarrasAtivas };
