// InputPlacaVeiculo.tsx
import React from 'react';

interface InputPlacaVeiculoProps {
	value: string;
	onChange: (value: string) => void;
	readOnly: boolean;
	id?: string;
	name?: string;
	placeholder?: string;
	required?: boolean;
}

const InputPlacaVeiculo: React.FC<InputPlacaVeiculoProps> = ({ value, onChange, readOnly,
	id, name, placeholder, required
}) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue = event.target.value.toUpperCase();

		// Remover caracteres não permitidos
		inputValue = inputValue.replace(/[^A-Z0-9]/g, '');

		// Adicionar hífen no lugar correto
		if (inputValue.length > 3) {
			inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3, 7)}`;
		}

		onChange(inputValue);
	};

	return (
		<input
			type="text"
			className="form-control bordasInferiorInput text-black text-capitalize"
			placeholder={placeholder}
			id={id}
			name={name}
			value={value}
			onChange={handleInputChange}
			required={required}
			readOnly={readOnly}
			maxLength={8} // AAA-9999
		/>
	);
};

export default InputPlacaVeiculo;
