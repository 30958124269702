import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const AtividadeUsuarios = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarAtividadesUsuario) {
				toast.warning('Você não tem permissão para acessar a lista de Atividades dos Usuários');
				navigate('/');
			}
		}
	}, [permissoes, erro, navigate]);

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);

	//#endregion
	const colunas: ITabela[] = [
		{
			titulo: 'ID Usuario',
			acesso: 'id_usuario',
		},
		{
			titulo: 'Nome',
			acesso: 'nome',
		},
		{
			titulo: 'Email',
			acesso: 'email',
		},
		{
			titulo: 'IP',
			acesso: 'ip',
		},
		{
			titulo: 'Data Login',
			acesso: 'dt_login',
		},
		{
			titulo: 'Data Logout',
			acesso: 'dt_logout',
		},
	];

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const [ListaDeUsuarios, setListaDeUsuarios] = useState([]);

	function CarregarAtividadeDeUsuariosFilial(id_filial: string) {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/atvfilial/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setListaDeUsuarios(resposta.data.atividades);
			})
			.catch(function (erro) {
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	useEffect(function () {
		CarregarAtividadeDeUsuariosFilial(id_filial);
	}, []);
	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Histórico de atividades dos Usuários.</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<ClickableTable
						coluna={colunas}
						itemsPerPage={itensPorPagina}
						data={ListaDeUsuarios}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						acessopesquisa="nome"
						labelpesquisa="Procurar por nome de usuario."
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { AtividadeUsuarios };
