import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../services/api';
import {
	mdiFileRefreshOutline,
	mdiMagnify,
	mdiMapMarker,
	mdiNewspaperPlus,
	mdiOrderBoolAscendingVariant,
	mdiPrinter,
	mdiPrinterPosCheckOutline,
	mdiPrinterPosRefreshOutline,
} from '@mdi/js';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import InputSemZeroEsquerda from '../../../components/componentes/formulario/InputSemZeroEsquerda';
import InputSelect from '../../../components/componentes/formulario/InputSelect';
import Icon from '@mdi/react';
import InputMultSelect from '../../../components/componentes/formulario/InputMultSelect';
import JsBarcode from 'jsbarcode';
import logoPadrao from '../../../assets/img/logo3.png';
import imageToBase64 from 'image-to-base64/browser';
import { PdfDocumentEndereco } from './components/gerarEtiquetaEndereco';
import { PdfDocumentEnderecoParams } from './components/gerarEtiquetaEndereco/interface';
import { PdfDocumentProdutoPicking } from './components/gerarEtiquetaProdutoPicking';
import { PdfDocumentProdutoPickingParams } from './components/gerarEtiquetaProdutoPicking/interface';
import { PdfDocumentEtiquetaUAParams } from './components/gerarEtiquetaUA/interface';
import { PdfDocumentEtiquetaUA } from './components/gerarEtiquetaUA';
import { PdfDocumentUAVaziaSaidaParams } from './components/gerarEtiquetaUAVaziaSaida/interface';
import { PdfDocumentUAVaziaSaida } from './components/gerarEtiquetaUAVaziaSaida';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

const ImpressaoEtiqueta = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');

	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoReimpressaoUA, setPermissaoReimpressaoUA] = useState<boolean>(false);
	const [permissaoGerarUAVazioSaida, setPermissaoGerarUAVazioSaida] = useState<boolean>(false);
	const [permissaoEndereco, setPermissaoEndereco] = useState<boolean>(false);
	const [permissaoProdutoPicking, setPermissaoProdutoPicking] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (
				!permissoes.perfilPermissao.visualizarImpressaoEtiquetas &&
				!permissoes.perfilPermissao.visualizarImpEtqReimpressaoUA &&
				!permissoes.perfilPermissao.visualizarImpEtqGerarUAVazioSaida &&
				!permissoes.perfilPermissao.visualizarImpEtqImpressaoEndereco &&
				!permissoes.perfilPermissao.visualizarImpEtqImpressaoProdutoPicking
			) {
				toast.warning('Você não tem permissão para acessar Reimpressão de Etiquetas');
				navigate('/');
			} else {
				setPermissaoReimpressaoUA(permissoes.perfilPermissao.visualizarImpEtqReimpressaoUA);
				setPermissaoGerarUAVazioSaida(permissoes.perfilPermissao.visualizarImpEtqGerarUAVazioSaida);
				setPermissaoEndereco(permissoes.perfilPermissao.visualizarImpEtqImpressaoEndereco);
				setPermissaoProdutoPicking(permissoes.perfilPermissao.visualizarImpEtqImpressaoProdutoPicking);
			}
		}
	}, [permissoes, erro, navigate]);

	// dados gruia reimpressao
	// dados para a reimpressao etiqueta de ua
	const [dadosEtiqueta, setDadosEtiqueta] = useState<any[]>([]);
	const [tipoEtiqueta, setTipoEtiqueta] = useState<string>('');
	const [alturaEtiqueta, setAlturaEtiqueta] = useState<number>(0);
	const [larguraEtiqueta, setLarguraEtiqueta] = useState<number>(0);
	const [orientacaoEtiqueta, setOrientacaoEtiqueta] = useState<string>('retrato');

	const [id_ua_reimpressao, setIdUaReimpressao] = useState<string>('');
	const iduaRef = useRef<HTMLInputElement | null>(null);
	const [id_cliente_reimpressao, setIdClienteReimpressao] = useState<string>('');
	const [codigo_reimpressao, setCodigoReimpressao] = useState<string>('');
	const [depositante_reimpressao, setDepositanteReimpressao] = useState<string>('');
	const [qtde_reimpressao, setQtdeReimpressao] = useState<string>('');

	// selecionar etiqueta ao clicar
	const selecionarEtiqueta = (tipo: string) => {
		setTipoEtiqueta(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosEtiqueta.filter((item) => item.value === tipo);
			setAlturaEtiqueta(parseFloat(filtro[0].defusr01));
			setLarguraEtiqueta(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiqueta(filtro[0].defusr03);
		}
	};

	// função para focar no campo id ua
	const focarIdUA = () => {
		setTimeout(() => {
			setIdUaReimpressao('');
			setCodigoReimpressao('');
			setIdClienteReimpressao('');
			setDepositanteReimpressao('');
			setQtdeReimpressao('');
			setDadosEtiqueta([]);
			setLarguraEtiqueta(0);
			setAlturaEtiqueta(0);
			setTipoEtiqueta('');
			setOrientacaoEtiqueta('retrato');

			iduaRef.current?.focus();
		}, 300);
	};
	const LimparDadosGuiaReimpressao = () => {
		setTimeout(() => {
			setIdUaReimpressao('');
			setCodigoReimpressao('');
			setIdClienteReimpressao('');
			setDepositanteReimpressao('');
			setQtdeReimpressao('');
			setDadosEtiqueta([]);
			setLarguraEtiqueta(0);
			setAlturaEtiqueta(0);
			setTipoEtiqueta('');
			setOrientacaoEtiqueta('retrato');
		}, 300);
	};

	//#region Carregar Dimensoes da Etiqueta
	function carregarEtiqueta() {
		axios
			.get(`${Api}/oper/imprimir/etiquetas/reimprimirua/dimensaoetiueta/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosEtiqueta(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion

	// dados guia gerar ua vazio/saida
	const [depositante_gerar_ua, setDepositanteGerarUa] = useState<string>('');
	const [dadosDepositanteGerarUa, setDadosDepositanteGerarUa] = useState<any[]>([]);
	const [qtde_ua_gerar_ua, setQtdeUaGerarUa] = useState<string>('');
	const [tipo_ua_gerar_ua, setTipoUaGerarUa] = useState<string>('');

	// dados para a reimpressao etiqueta de ua vazio/saida
	const [dadosEtiquetaUAVazioSaida, setDadosEtiquetaUAVazioSaida] = useState<any[]>([]);
	const [tipoEtiquetaUAVazioSaida, setTipoEtiquetaUAVazioSaida] = useState<string>('');
	const [alturaEtiquetaUAVazioSaida, setAlturaEtiquetaUAVazioSaida] = useState<number>(0);
	const [larguraEtiquetaUAVazioSaida, setLarguraEtiquetaUAVazioSaida] = useState<number>(0);
	const [orientacaoEtiquetaUAVazioSaida, setOrientacaoEtiquetaUAVazioSaida] = useState<string>('retrato');

	const limparDadosGuiaGerarUa = () => {
		setDepositanteGerarUa('');
		setQtdeUaGerarUa('');
		setTipoUaGerarUa('');
		setDadosDepositanteGerarUa([]);
	};

	const dadosTipoUAGerarUa = [
		{ value: 'vazio', label: 'U.A. Vazia' },
		{ value: 'saida', label: 'U.A. Saída' },
	];

	//#region Carregar Dimensoes da Etiqueta
	function carregarDadosDepositante() {
		axios
			.get(`${Api}/oper/imprimir/etiquetas/geraruavaziasaida/depositante/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDepositanteGerarUa(resposta.data.depositante);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion
	const dadosEnvioGeraUAVaziaSaida = {
		id_cliente: depositante_gerar_ua,
		qtde: qtde_ua_gerar_ua,
		tipo: tipo_ua_gerar_ua,
		usuario,
	};

	//#region Gerar U.A. Vazia/Saída
	function gerarUAVaziaSaida(dados: any) {
		setMostraModalCarregar(true);
		setMsgModalCarregar(`Gerando U.A. ${tipo_ua_gerar_ua === 'vazio' ? 'Vazia' : 'Saída'}, aguarde...`);
		axios
			.post(`${Api}/oper/imprimir/etiquetas/geraruavaziasaida/gerar/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(resposta.data.message);
				setDepositanteGerarUa('');
				setQtdeUaGerarUa('');
				setTipoUaGerarUa('');
				setDadosDepositanteGerarUa([]);
				gerarEtiquetaUAVazioSaida(resposta.data.dadosUAVaziaSaida);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	// dados guia endereco
	const [amz_endereco, setAmzEndereco] = useState<any[]>([]);
	const [corredor_endereco, setCorredorEndereco] = useState<any[]>([]);
	const [box_endereco, setBoxEndereco] = useState<any[]>([]);
	const [altura_endereco, setAlturaEndereco] = useState<any[]>([]);
	const [profundidade_endereco, setProfundidadeEndereco] = useState<any[]>([]);

	// limpar guia endereço
	const limparDadosGuiaEndereco = () => {
		setAmzEndereco([]);
		setCorredorEndereco([]);
		setBoxEndereco([]);
		setAlturaEndereco([]);
		setProfundidadeEndereco([]);
		setDadosEtiquetaEndereco([]);
		setTipoEtiquetaEndereco('');
		setAlturaEtiquetaEndereco(0);
		setLarguraEtiquetaEndereco(0);
		setOrientacaoEtiquetaEndereco('retrato');
	};

	// dados para impressao de etiqueta de endereco
	const [dadosEtiquetaEndereco, setDadosEtiquetaEndereco] = useState<any[]>([]);
	const [tipoEtiquetaEndereco, setTipoEtiquetaEndereco] = useState<string>('');
	const [alturaEtiquetaEndereco, setAlturaEtiquetaEndereco] = useState<number>(0);
	const [larguraEtiquetaEndereco, setLarguraEtiquetaEndereco] = useState<number>(0);
	const [orientacaoEtiquetaEndereco, setOrientacaoEtiquetaEndereco] = useState<string>('retrato');

	// dados guia produto picking
	const [amz_produto, setAmzProduto] = useState<any[]>([]);
	const [corredor_produto, setCorredorProduto] = useState<any[]>([]);
	const [box_produto, setBoxProduto] = useState<any[]>([]);
	const [altura_produto, setAlturaProduto] = useState<any[]>([]);
	const [profundidade_produto, setProfundidadeProduto] = useState<any[]>([]);

	// dados para impressao de etiqueta de produto de picking
	const [dadosEtiquetaProdutoPicking, setDadosEtiquetaProdutoPicking] = useState<any[]>([]);
	const [tipoEtiquetaProdutoPicking, setTipoEtiquetaProdutoPicking] = useState<string>('');
	const [alturaEtiquetaProdutoPicking, setAlturaEtiquetaProdutoPicking] = useState<number>(0);
	const [larguraEtiquetaProdutoPicking, setLarguraEtiquetaProdutoPicking] = useState<number>(0);
	const [orientacaoEtiquetaProdutoPicking, setOrientacaoEtiquetaProdutoPicking] = useState<string>('retrato');
	//#region Inicio novo desenvolvimento de funções

	//#region => Guia Reimpressão funcionalidades
	function ValidarIDUA() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando ID U.A, aguarde...');
		axios
			.get(`${Api}/oper/imprimir/etiquetas/reimprimirua/validar/${id_filial}/${id_ua_reimpressao}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');

				const dadosApi = resposta.data.dados_ua[0];
				setIdClienteReimpressao(dadosApi.id_cliente);
				setCodigoReimpressao(dadosApi.codigo);
				setDepositanteReimpressao(dadosApi.fantasia);
				setQtdeReimpressao(dadosApi.qtde);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
					focarIdUA();
				}
			});
	}
	//#endregion
	//#region => Guia Reimpressão funcionalidades
	function reimprimirUA() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando ID U.A, aguarde...');
		axios
			.get(
				`${Api}/oper/imprimir/etiquetas/reimprimirua/reimprimir/${id_filial}/${id_cliente_reimpressao}/${codigo_reimpressao}/${id_ua_reimpressao}`,
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');

				const dadosApi = resposta.data.reimpressao_ua;
				gerarEtiquetaUA(dadosApi);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
					focarIdUA();
				}
			});
	}
	//#endregion

	const converterTextoParaImagemBase64 = (barcode: string): string => {
		const canvas = document.createElement('canvas');
		JsBarcode(canvas, barcode, {
			format: 'CODE128',
			displayValue: false,
		});
		const dataURL = canvas.toDataURL('image/png');
		const base64 = dataURL;
		return base64;
	};
	const imprimirTodos = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(id_ua_reimpressao);
			const dados: PdfDocumentEtiquetaUAParams = {
				tamanho: {
					altura: orientacaoEtiqueta?.toLowerCase() !== 'retrato' ? larguraEtiqueta : alturaEtiqueta,
					largura: orientacaoEtiqueta?.toLowerCase() !== 'retrato' ? alturaEtiqueta : larguraEtiqueta,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				nomeDepositante: depositante_reimpressao,
				processo: item.id_processo,
				codigoUA: id_ua_reimpressao,
				codigoBarras: barcode,
				textoCodigoBarras: item.barra,
				descricao: item.descricao,
				codigo: item.codigo,
				pesoliquido: item.pl,
				datavalidade: item.validade_menor,
				lote: item.lote,
				qtde: item.volume_1,
				embalagem: item.und_emb,
				pesobruto: item.pb,
				siftemp: item.sif_temperatura,
				orientacao: orientacaoEtiqueta?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const geraEtiquetaUA = PdfDocumentEtiquetaUA({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		geraEtiquetaUA();
	};

	// Uso da função
	const gerarEtiquetaUA = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}
		if (tipoEtiqueta === '') {
			toast.error('Selecione o tipo de etiqueta');
			return;
		}
		imprimirTodos(dados); // Passa os dados para a função imprimirTodos
	};

	// Guia Endereço funcionalidades
	//#region Carregar Dimensoes da Etiqueta
	function carregarDimensaoEtiquetaEndereco() {
		axios
			.get(`${Api}/oper/imprimir/etiquetas/endereco/dimensao/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosEtiquetaEndereco(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion

	// selecionar armazem
	const selecionarArmazemEndereco = (armazem: any) => {
		setAmzEndereco(armazem);
		setCorredorEndereco([]);
		setBoxEndereco([]);
		setAlturaEndereco([]);
		setProfundidadeEndereco([]);
	};

	// selecionar corredor
	const selecionarCorredorEndereco = (corredor: any) => {
		setCorredorEndereco(corredor);
		setBoxEndereco([]);
		setAlturaEndereco([]);
		setProfundidadeEndereco([]);
	};

	// selecionar box
	const selecionarBoxEndereco = (box: any) => {
		setBoxEndereco(box);
		setAlturaEndereco([]);
		setProfundidadeEndereco([]);
	};

	// selecionar altura
	const selecionarAlturaEndereco = (altura: any) => {
		setAlturaEndereco(altura);
		setProfundidadeEndereco([]);
	};

	// selecionar profundidade
	const selecionarProfundidadeEndereco = (profundidade: any) => {
		setProfundidadeEndereco(profundidade);
	};

	// Função para buscar dados de endereço para gerar etiquetas
	const dadosEndereco = {
		armazem: amz_endereco.map((amz) => `'${amz.value}'`).join(','),
		corredor: corredor_endereco.map((cor) => `'${cor.value}'`).join(','),
		box: box_endereco.map((box) => `'${box.value}'`).join(','),
		altura: altura_endereco.map((alt) => `'${alt.value}'`).join(','),
		profundidade: profundidade_endereco.map((prof) => `'${prof.value}'`).join(','),
	};

	const buscarDadosEndereco = async (dados: any) => {
		try {
			setMostraModalCarregar(true);
			setMsgModalCarregar('Buscando dados de endereço, aguarde...');
			const response = await axios.post(`${Api}/oper/imprimir/etiquetas/dadosimpressao/endereco/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			});
			setMostraModalCarregar(false);
			setMsgModalCarregar('');
			gerarEtiquetaEndereco(response.data.enderecos);
		} catch (error: any) {
			setMostraModalCarregar(false);
			setMsgModalCarregar('');
			const msg = error.response?.data?.message || error.message;
			toast.error(`Falha ao buscar dados de endereço. Motivo: ${msg}`);
		}
	};

	const selecionarEtiquetaEndereco = (tipo: string) => {
		setTipoEtiquetaEndereco(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosEtiquetaEndereco.filter((item) => item.value === tipo);
			setAlturaEtiquetaEndereco(parseFloat(filtro[0].defusr01));
			setLarguraEtiquetaEndereco(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiquetaEndereco(filtro[0].defusr03);
		}
	};

	const imprimirEtiquetaEndereco = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const endereco = `${item.amz}${item.cor}${item.box}${item.alt}${item.prof}`;
			const barcode = converterTextoParaImagemBase64(endereco);
			const dados: PdfDocumentEnderecoParams = {
				tamanho: {
					altura: orientacaoEtiquetaEndereco?.toLowerCase() !== 'retrato' ? larguraEtiquetaEndereco : alturaEtiquetaEndereco,
					largura: orientacaoEtiquetaEndereco?.toLowerCase() !== 'retrato' ? alturaEtiquetaEndereco : larguraEtiquetaEndereco,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				codigoBarras: barcode,
				armazem: item.amz,
				corredor: item.cor,
				box: item.box,
				altura: item.alt,
				profundidade: item.prof,
				orientacao: orientacaoEtiquetaEndereco?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiquetaEndereco = PdfDocumentEndereco({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiquetaEndereco();
	};

	// Uso da função
	const gerarEtiquetaEndereco = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}
		if (tipoEtiquetaEndereco === '') {
			toast.error('Selecione o tipo de etiqueta');
			return;
		}
		imprimirEtiquetaEndereco(dados); // Passa os dados para a função imprimirTodos
	};

	// Guia Produto Picking funcionalidades

	// selecionar armazem
	const selecionarArmazemProduto = (armazem: any) => {
		setAmzProduto(armazem);
		setCorredorProduto([]);
		setBoxProduto([]);
		setAlturaProduto([]);
		setProfundidadeProduto([]);
	};

	// selecionar corredor
	const selecionarCorredorProduto = (corredor: any) => {
		setCorredorProduto(corredor);
		setBoxProduto([]);
		setAlturaProduto([]);
		setProfundidadeProduto([]);
	};

	// selecionar box
	const selecionarBoxProduto = (box: any) => {
		setBoxProduto(box);
		setAlturaProduto([]);
		setProfundidadeProduto([]);
	};

	// selecionar altura
	const selecionarAlturaProduto = (altura: any) => {
		setAlturaProduto(altura);
		setProfundidadeProduto([]);
	};

	// selecionar profundidade
	const selecionarProfundidadeProduto = (profundidade: any) => {
		setProfundidadeProduto(profundidade);
	};

	// Função para buscar dados de endereço para gerar etiquetas
	const dadosProdutoPicking = {
		armazem: amz_produto.map((amz) => `'${amz.value}'`).join(','),
		corredor: corredor_produto.map((cor) => `'${cor.value}'`).join(','),
		box: box_produto.map((box) => `'${box.value}'`).join(','),
		altura: altura_produto.map((alt) => `'${alt.value}'`).join(','),
		profundidade: profundidade_produto.map((prof) => `'${prof.value}'`).join(','),
	};

	const buscarDadosProdutoPicking = async (dados: any) => {
		try {
			setMostraModalCarregar(true);
			setMsgModalCarregar('Buscando dados do produto de picking, aguarde...');
			const response = await axios.post(`${Api}/oper/imprimir/etiquetas/produto/gerarimpressao/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			});
			setMostraModalCarregar(false);
			setMsgModalCarregar('');
			if (response.data.produtos.length === 0) {
				toast.error('Nenhum dado encontrado para impressão');
				return;
			}
			gerarEtiquetaProdutoPicking(response.data.produtos);
			// gerarEtiquetaProduto(response.data.enderecos);
		} catch (error: any) {
			setMostraModalCarregar(false);
			setMsgModalCarregar('');
			const msg = error.response?.data?.message || error.message;
			toast.error(`Falha ao buscar dados de endereço. Motivo: ${msg}`);
		}
	};

	//#region Carregar Dimensoes da Etiqueta
	function carregarDimensaoEtiquetaProdutoPicking() {
		axios
			.get(`${Api}/oper/imprimir/etiquetas/produtopicking/dimensao/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosEtiquetaProdutoPicking(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion

	const selecionarEtiquetaProdutoPicking = (tipo: string) => {
		setTipoEtiquetaProdutoPicking(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosEtiquetaProdutoPicking.filter((item) => item.value === tipo);
			setAlturaEtiquetaProdutoPicking(parseFloat(filtro[0].defusr01));
			setLarguraEtiquetaProdutoPicking(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiquetaProdutoPicking(filtro[0].defusr03);
		}
	};

	const imprimirEtiquetaProdutoPicking = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(item.localBarras);
			const dados: PdfDocumentProdutoPickingParams = {
				tamanho: {
					altura:
						orientacaoEtiquetaProdutoPicking?.toLowerCase() !== 'retrato' ? larguraEtiquetaProdutoPicking : alturaEtiquetaProdutoPicking,
					largura:
						orientacaoEtiquetaProdutoPicking?.toLowerCase() !== 'retrato' ? alturaEtiquetaProdutoPicking : larguraEtiquetaProdutoPicking,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				codigoBarras: barcode,
				local: item.local,
				depositante: item.fantasia,
				produto: item.codigo,
				descricao: item.descricao,
				orientacao: orientacaoEtiquetaProdutoPicking?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiquetaProdutoPicking = PdfDocumentProdutoPicking({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiquetaProdutoPicking();
	};

	// Uso da função
	const gerarEtiquetaProdutoPicking = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}
		if (tipoEtiquetaProdutoPicking === '') {
			toast.error('Selecione o tipo de etiqueta');
			return;
		}
		imprimirEtiquetaProdutoPicking(dados); // Passa os dados para a função imprimirTodos
	};

	//#region Carregar Dimensoes da Etiqueta U.A Vazio/Saída
	function carregarEtiquetaUAVazioSaida() {
		axios
			.get(`${Api}/oper/imprimir/etiquetas/uavaziosaida/dimensao/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosEtiquetaUAVazioSaida(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion

	// selecionar etiqueta ao clicar - U.A. Vazio/Saída
	const selecionarEtiquetaUAVazioSaida = (tipo: string) => {
		setTipoEtiquetaUAVazioSaida(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosEtiquetaUAVazioSaida.filter((item) => item.value === tipo);
			setAlturaEtiquetaUAVazioSaida(parseFloat(filtro[0].defusr01));
			setLarguraEtiquetaUAVazioSaida(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiquetaUAVazioSaida(filtro[0].defusr03);
		}
	};

	const imprimirEtiquetaUAVazioSaida = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(item.id_ua);
			const dados: PdfDocumentUAVaziaSaidaParams = {
				tamanho: {
					altura: orientacaoEtiquetaUAVazioSaida?.toLowerCase() !== 'retrato' ? larguraEtiquetaUAVazioSaida : alturaEtiquetaUAVazioSaida,
					largura: orientacaoEtiquetaUAVazioSaida?.toLowerCase() !== 'retrato' ? alturaEtiquetaUAVazioSaida : larguraEtiquetaUAVazioSaida,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				codigoBarras: barcode,
				numeroUA: item.id_ua,
				tipoUA: item.tipo_ua,
				depositante: item.fantasia,
				orientacao: orientacaoEtiquetaUAVazioSaida?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiquetaUAVazioSaida = PdfDocumentUAVaziaSaida({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiquetaUAVazioSaida();
	};

	// Uso da função
	const gerarEtiquetaUAVazioSaida = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}
		if (tipoEtiquetaUAVazioSaida === '') {
			toast.error('Selecione o tipo de etiqueta');
			return;
		}
		imprimirEtiquetaUAVazioSaida(dados); // Passa os dados para a função imprimirTodos
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Impressão de Etiquetas</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="card">
									<div className="card-body">
										<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
											{permissaoReimpressaoUA && (
												<li className="nav-item">
													<a
														href="#impEtqReimpressao"
														data-bs-toggle="tab"
														aria-expanded="true"
														className={permissaoReimpressaoUA ? `nav-link rounded-0 active` : `nav-link rounded-0`}
														onClick={() => {
															limparDadosGuiaGerarUa();
															limparDadosGuiaEndereco();
														}}
													>
														<Icon path={mdiPrinterPosRefreshOutline} size={1} className="d-md-none d-block" />
														<span className="d-none d-md-block">Reimpressão</span>
													</a>
												</li>
											)}
											{permissaoGerarUAVazioSaida && (
												<li className="nav-item">
													<a
														href="#impEtqGerarUAVazioSaida"
														data-bs-toggle="tab"
														aria-expanded="false"
														className={!permissaoReimpressaoUA && permissaoGerarUAVazioSaida ? `nav-link rounded-0 active` : `nav-link rounded-0`}
														onClick={() => {
															LimparDadosGuiaReimpressao();
															limparDadosGuiaEndereco();
														}}
													>
														<Icon path={mdiFileRefreshOutline} size={1} className="d-md-none d-block" />
														<span className="d-none d-md-block">Gerar U.A Vazio/Saída</span>
													</a>
												</li>
											)}
											{permissaoEndereco && (
												<li className="nav-item">
													<a
														href="#impEtqEndereco"
														data-bs-toggle="tab"
														aria-expanded="false"
														className={(!permissaoReimpressaoUA && !permissaoGerarUAVazioSaida) && permissaoEndereco ? `nav-link rounded-0 active` : `nav-link rounded-0`}
														onClick={() => {
															LimparDadosGuiaReimpressao();
															limparDadosGuiaGerarUa();
														}}
													>
														<Icon path={mdiMapMarker} size={1} className="d-md-none d-block" />
														<span className="d-none d-md-block">Endereço</span>
													</a>
												</li>
											)}
											{permissaoProdutoPicking && (
												<li className="nav-item">
													<a
														href="#impEtqProdutoPicking"
														data-bs-toggle="tab"
														aria-expanded="false"
														className={(!permissaoReimpressaoUA && !permissaoGerarUAVazioSaida && !permissaoEndereco) && permissaoProdutoPicking ? `nav-link rounded-0 active` : `nav-link rounded-0`}
														onClick={() => {
															LimparDadosGuiaReimpressao();
															limparDadosGuiaGerarUa();
															limparDadosGuiaEndereco();
														}}
													>
														<Icon path={mdiOrderBoolAscendingVariant} size={1} className="d-md-none d-block" />
														<span className="d-none d-md-block">Produto Picking</span>
													</a>
												</li>
											)}
										</ul>
										<div className="tab-content">
											<div className={permissaoReimpressaoUA ? `tab-pane show active` : `tab-pane`} id="impEtqReimpressao">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														ValidarIDUA();
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-sm col-md col-lg-2">
																<InputSemZeroEsquerda
																	label="U.A."
																	id="id_ua_reimpressao"
																	name="id_ua_reimpressao"
																	value={id_ua_reimpressao}
																	onChange={setIdUaReimpressao}
																	required={true}
																	disabled={false}
																	useRef={iduaRef}
																/>
															</div>

															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-primary mt-3"
																	descricaoBotao="Buscar dados"
																	icone={mdiMagnify}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
													</div>
												</form>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														reimprimirUA();
													}}
												>
													<div className="card-body">
														{codigo_reimpressao && (
															<>
																<div className="row">
																	<div className="col-sm col-md col-lg-1">
																		<InputSemZeroEsquerda
																			label="Código"
																			id="codigo_reimpressao"
																			name="codigo_reimpressao"
																			value={codigo_reimpressao}
																			onChange={setCodigoReimpressao}
																			required={true}
																			disabled
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-4">
																		<InputSemZeroEsquerda
																			label="Depositante"
																			id="depositante_reimpressao"
																			name="depositante_reimpressao"
																			value={depositante_reimpressao}
																			onChange={setDepositanteReimpressao}
																			required={true}
																			disabled
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-2">
																		<InputSemZeroEsquerda
																			label="Quantidade"
																			id="qtde_reimpressao"
																			name="qtde_reimpressao"
																			value={parseFloat(qtde_reimpressao).toLocaleString('pt-BR', {
																				style: 'decimal',
																				minimumFractionDigits: 2,
																				maximumFractionDigits: 2,
																			})}
																			onChange={setQtdeReimpressao}
																			required={true}
																			disabled
																		/>
																	</div>
																	<div className="col-sm col-md col-lg">
																		<InputSelect
																			label="Medida Etiqueta"
																			name="etiqueta"
																			value={tipoEtiqueta}
																			onChange={selecionarEtiqueta}
																			dados={dadosEtiqueta}
																			required={true}
																			onClick={carregarEtiqueta}
																			disabled={false}
																			campoValue="value"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg">
																		<BotaoCustomizado
																			type="submit"
																			className="btn btn-info mt-3"
																			descricaoBotao="Imprimir Etiqueta de U.A."
																			icone={mdiPrinterPosCheckOutline}
																			onclick={() => {}}
																			size={1}
																		/>
																	</div>
																</div>
															</>
														)}
													</div>
												</form>
											</div>

											<div
												className={!permissaoReimpressaoUA && permissaoGerarUAVazioSaida ? `tab-pane show active` : `tab-pane`}
												id="impEtqGerarUAVazioSaida"
											>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														gerarUAVaziaSaida(dadosEnvioGeraUAVaziaSaida);
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-sm col-md col-lg-4">
																<InputSelect
																	label="Depositante"
																	name="depositante_gerar_ua"
																	value={depositante_gerar_ua}
																	onChange={setDepositanteGerarUa}
																	required={true}
																	disabled={false}
																	dados={dadosDepositanteGerarUa}
																	onClick={carregarDadosDepositante}
																	campoValue="label"
																/>
															</div>
															<div className="col-sm col-md col-lg-2">
																<InputSemZeroEsquerda
																	label="Qtde U.A."
																	id="qtde_ua_gerar_ua"
																	name="qtde_ua_gerar_ua"
																	value={qtde_ua_gerar_ua}
																	onChange={setQtdeUaGerarUa}
																	required={true}
																	disabled={false}
																/>
															</div>
															<div className="col-sm col-md col-lg-3">
																<InputSelect
																	label="Tipo"
																	name="tipo_ua_gerar_ua"
																	value={tipo_ua_gerar_ua}
																	onChange={setTipoUaGerarUa}
																	required={true}
																	disabled={false}
																	dados={dadosTipoUAGerarUa}
																	onClick={() => {}}
																	campoValue="label"
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputSelect
																	label="Medida Etiqueta"
																	name="tipoEtiquetaUAVazioSaida"
																	value={tipoEtiquetaUAVazioSaida}
																	onChange={selecionarEtiquetaUAVazioSaida}
																	dados={dadosEtiquetaUAVazioSaida}
																	required={true}
																	onClick={carregarEtiquetaUAVazioSaida}
																	disabled={false}
																	campoValue="value"
																/>
															</div>

															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-primary mt-3"
																	descricaoBotao="Gerar U.A."
																	icone={mdiNewspaperPlus}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
													</div>
												</form>
											</div>
											<div
												className={
													(!permissaoReimpressaoUA && !permissaoGerarUAVazioSaida) &&  permissaoEndereco ? `tab-pane show active` : `tab-pane`
												}
												id="impEtqEndereco"
											>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														buscarDadosEndereco(dadosEndereco);
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Armazém"
																	name="amz_endereco"
																	value={amz_endereco}
																	onChange={selecionarArmazemEndereco}
																	required={true}
																	disabled={false}
																	apiEndpoint={`/oper/imprimir/etiquetas/armazem/endereco/${id_filial}`}
																	isMulti
																	placeholder="Selecione o Armazém..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Corredor"
																	name="corredor_endereco"
																	value={corredor_endereco}
																	onChange={selecionarCorredorEndereco}
																	required={true}
																	disabled={amz_endereco.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/corredor/endereco/${id_filial}/${amz_endereco
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}`}
																	isMulti
																	placeholder="Selecione o Corredor..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Box"
																	name="box_endereco"
																	value={box_endereco}
																	onChange={selecionarBoxEndereco}
																	required={true}
																	disabled={corredor_endereco.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/box/endereco/${id_filial}/${amz_endereco
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}/${corredor_endereco.map((cor) => `'${cor.value}'`).join(',')}`}
																	isMulti
																	placeholder="Selecione o Box..."
																/>
															</div>
														</div>
														<div className="row">
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Altura"
																	name="altura_endereco"
																	value={altura_endereco}
																	onChange={selecionarAlturaEndereco}
																	required={true}
																	disabled={box_endereco.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/altura/endereco/${id_filial}/${amz_endereco
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}/${corredor_endereco
																		.map((cor) => `'${cor.value}'`)
																		.join(',')}/${box_endereco.map((box) => `'${box.value}'`).join(',')}`}
																	isMulti
																	placeholder="Selecione a Altura..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Profundidade"
																	name="profundidade_endereco"
																	value={profundidade_endereco}
																	onChange={selecionarProfundidadeEndereco}
																	required={true}
																	disabled={altura_endereco.length === 0}
																	isMulti
																	placeholder="Selecione a Profundidade..."
																	apiEndpoint={`/oper/imprimir/etiquetas/profundidade/endereco/${id_filial}/${amz_endereco
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}/${corredor_endereco
																		.map((cor) => `'${cor.value}'`)
																		.join(',')}/${box_endereco
																		.map((box) => `'${box.value}'`)
																		.join(',')}/${altura_endereco.map((alt) => `'${alt.value}'`).join(',')}`}
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputSelect
																	label="Medida Etiqueta"
																	name="tipoEtiquetaEndereco"
																	value={tipoEtiquetaEndereco}
																	onChange={selecionarEtiquetaEndereco}
																	dados={dadosEtiquetaEndereco}
																	required={true}
																	onClick={carregarDimensaoEtiquetaEndereco}
																	disabled={false}
																	campoValue="value"
																/>
															</div>

															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-primary mt-3"
																	descricaoBotao="Imprimir Etiqueta de  Endereço"
																	icone={mdiPrinter}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
													</div>
												</form>
											</div>
											<div
												className={
													!permissaoReimpressaoUA && !permissaoGerarUAVazioSaida && !permissaoEndereco
														? `tab-pane show active`
														: `tab-pane`
												}
												id="impEtqProdutoPicking"
											>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														buscarDadosProdutoPicking(dadosProdutoPicking);
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Armazém"
																	name="amz_produto"
																	value={amz_produto}
																	onChange={selecionarArmazemProduto}
																	required={true}
																	disabled={false}
																	apiEndpoint={`/oper/imprimir/etiquetas/armazem/produto/${id_filial}`}
																	isMulti
																	placeholder="Selecione o Armazém..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Corredor"
																	name="corredor_produto"
																	value={corredor_produto}
																	onChange={selecionarCorredorProduto}
																	required={true}
																	disabled={amz_produto.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/corredor/produto/${id_filial}/${amz_produto
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}`}
																	isMulti
																	placeholder="Selecione o Corredor..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Box"
																	name="box_produto"
																	value={box_produto}
																	onChange={selecionarBoxProduto}
																	required={true}
																	disabled={corredor_produto.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/box/produto/${id_filial}/${amz_produto.map(
																		(amz) => `'${amz.value}'`
																	)}/${corredor_produto.map((cor) => `'${cor.value}'`).join(',')}`}
																	isMulti
																	placeholder="Selecione o Box..."
																/>
															</div>
														</div>
														<div className="row">
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Altura"
																	name="altura_produto"
																	value={altura_produto}
																	onChange={selecionarAlturaProduto}
																	required={true}
																	disabled={box_produto.length === 0}
																	apiEndpoint={`/oper/imprimir/etiquetas/altura/produto/${id_filial}/${amz_produto
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}/${corredor_produto
																		.map((cor) => `'${cor.value}'`)
																		.join(',')}/${box_produto.map((box) => `'${box.value}'`).join(',')}`}
																	isMulti
																	placeholder="Selecione a Altura..."
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputMultSelect
																	label="Profundidade"
																	name="profundidade_produto"
																	value={profundidade_produto}
																	onChange={selecionarProfundidadeProduto}
																	required={true}
																	disabled={altura_produto.length === 0}
																	isMulti
																	placeholder="Selecione a Profundidade..."
																	apiEndpoint={`/oper/imprimir/etiquetas/profundidade/produto/${id_filial}/${amz_produto
																		.map((amz) => `'${amz.value}'`)
																		.join(',')}/${corredor_produto
																		.map((cor) => `'${cor.value}'`)
																		.join(',')}/${box_produto
																		.map((box) => `'${box.value}'`)
																		.join(',')}/${altura_produto.map((alt) => `'${alt.value}'`).join(',')}`}
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<InputSelect
																	label="Medida Etiqueta"
																	name="tipoEtiquetaProdutoPicking"
																	value={tipoEtiquetaProdutoPicking}
																	onChange={selecionarEtiquetaProdutoPicking}
																	dados={dadosEtiquetaProdutoPicking}
																	required={true}
																	onClick={carregarDimensaoEtiquetaProdutoPicking}
																	disabled={false}
																	campoValue="value"
																/>
															</div>

															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-primary mt-3"
																	descricaoBotao="Imprimir Etiqueta de Produto de Picking"
																	icone={mdiPrinter}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />
		</>
	);
};

export { ImpressaoEtiqueta };
