import { useEffect, useState } from "react"
import { ITabela } from "../../../../components/Table/TabelaInterface"
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl"
import axios from "axios"
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao"
import { Modal } from "react-bootstrap"

function TarefasList() {
    //const basicas
    const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const token = sessionStorage.getItem('IsFKLTOKEN')!;
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")
    //modal carregando controle
    const [ShowModalCarregando, setShowModalCarregando] = useState(false)
    //carrega tarefas
    const [ListaTarefas, setListaTarefas] = useState([])
    function CarregarTarefasFilial() {
        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/tarefas/filial/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaTarefas(resposta.data.tarefas)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    //grid principal
    const colunas: ITabela[] = [
        { titulo: "Id.Tarefa", acesso: "id_tarefa" },
        { titulo: "Depositante", acesso: "fantasia" },
        { titulo: "Id.Pedido", acesso: "id_pedido" },
        { titulo: "Roteiro", acesso: "roteiro" },
        { titulo: "Volumes", acesso: "volume" },
        { titulo: "Peso Líq", acesso: "pl" },
        { titulo: "Tipo Quebra", acesso: "tipo_quebra" },
        { titulo: "Cadastro", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ]
    //grid visualizar detalhes
    const [ListaDetalhesTarefas, setListaDetalhesTarefas] = useState([])
    const colunas2: ITabela[] = [
        { titulo: "Id.Tarefa", acesso: "id_tarefa" },
        { titulo: "U.A Origem", acesso: "id_ua_origem" },
        { titulo: "U.A Saída", acesso: "id_ua_saida" },
        { titulo: "Wms", acesso: "id_wms" },
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Id.Embal", acesso: "id_emb" },
        { titulo: "Unid", acesso: "und_emb" },
        { titulo: "Descrição", acesso: "descricao" },
        { titulo: "Volume", acesso: "volume" },
        { titulo: "Qtde", acesso: "qtde" },
        { titulo: "Peso Br.", acesso: "pb" },
        { titulo: "Peso Líq", acesso: "pl" },
        { titulo: "Validade", acesso: "validade_menor" },
        { titulo: "Tipo Peso", acesso: "tipo_peso" },
        { titulo: "Cadastro", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ]
    const [itensPorPagina, setItensPorPagina] = useState(10)
    //ações do grid principal
    const [showModalDetalhesTarefa, setShowModalDetalhesTarefa] = useState(false)
    const [showModalConfirmacaoCancelarTarefa, setshowModalConfirmacaoCancelarTarefa] = useState(false)
    const [TarefaSelecionada, setTarefaSelecionada] = useState<any>({})
    function AbrirModalCancelarTarefa(dado: any) {
        setTarefaSelecionada(dado)
        setshowModalConfirmacaoCancelarTarefa(true)
    }
    function CancelarTarefa() {
        setshowModalConfirmacaoCancelarTarefa(false)
        setShowModalCarregando(true)
        const id_depositante = TarefaSelecionada.id_cliente
        const id_tarefa = TarefaSelecionada.id_tarefa
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/cancelar/tarefa/completa/${id_tarefa}/${id_depositante}/${idFilial}/${usuarioLogado}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message, {
                autoClose: 2000
            })
            CarregarTarefasFilial()
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    function VisualizarTarefa(dados: any) {
        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/detalhes/tarefas/${idFilial}/${dados.id_tarefa}/${dados.id_cliente}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaDetalhesTarefas(resposta.data.detalhes)
            setShowModalDetalhesTarefa(true)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //cancelar item da tarefa
    const [LinhaTarefaSelecionado, setLinhaTarefaSeleciodo] = useState<any>()
    const [showModalConfirmacaoCancelarTarefaLinha, setshowModalConfirmacaoCancelarTarefaLinha] = useState(false)
    function AbrirModalCancelarLinha(dado: any) {
        setLinhaTarefaSeleciodo(dado)
        setshowModalConfirmacaoCancelarTarefaLinha(true)
    }
    function CancelarItemTarefa() {
        setShowModalCarregando(true)
        const id = LinhaTarefaSelecionado.id
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/cancelar/item/tarefa/${id}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setShowModalCarregando(false)
            toast.success(resposta.data.message, {
                autoClose: 2000
            })
            setShowModalDetalhesTarefa(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //useEffect Principal
    useEffect(function () {
        CarregarTarefasFilial()
    }, [])
    function fecharModalDetalhesTarefa() {
        setShowModalDetalhesTarefa(false)
    }
    return (
        <div className="main">
            <div className='tituloDaPagina mt-2 ms-1'>
                <h4>Pedidos Pendentes</h4>
            </div>
            <div className="card mt-2 pb-3">
                <div className="card-body">
                    <ClickableTable
                        coluna={colunas}
                        data={ListaTarefas}
                        onRowClick={function () { }}
                        itemsPerPage={itensPorPagina}
                        acaoEditar={function () { }}
                        acaoExcluir={AbrirModalCancelarTarefa}
                        acaoVisualizar={VisualizarTarefa}
                        usaAcoes={true}
                        usaEditar={false}
                        usaExcluir={true}
                        usaVisualizar={true}
                        iconeExcluir="bi bi-ban text-danger"
                        acessopesquisa='id_pedido'
                        labelpesquisa='Procurar pelo Id.Pedido'
                    />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-1 col-md-2 col-sm m-auto text-center'>
                                <label>Itens por Página</label>
                            </div>
                            <div className='w-100'></div>
                            <div className='col-lg-1 col-md-2 col-sm m-auto'>

                                <select value={itensPorPagina}
                                    onChange={function (e: any) {
                                        setItensPorPagina(e.target.value)
                                    }}
                                    className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCarregando mostrar={ShowModalCarregando} mensagem="Carregando..." />
            <ModalConfirmacao mostrar={showModalConfirmacaoCancelarTarefa}
                msgAcao1="Cancelar" msgAcao2="Confirmar"
                msgPrincipal="Ao cancelar essa tarefa, os saldos serão devolvidos para as U.A de Origem.Você já imprimiu essa Tarefa? Não será possível visualizar os dados de origem em tela após cancelar."
                Acao1={function () {
                    setshowModalConfirmacaoCancelarTarefa(false)
                }}
                Acao2={CancelarTarefa}
            />
            <ModalConfirmacao mostrar={showModalConfirmacaoCancelarTarefaLinha}
                msgAcao1="Cancelar" msgAcao2="Confirmar"
                msgPrincipal="Confirmar cancelamento do item da tarefa?"
                Acao1={function () {
                    setshowModalConfirmacaoCancelarTarefaLinha(false)
                }}
                Acao2={CancelarItemTarefa}
            />
            <Modal show={showModalDetalhesTarefa} size="xl" onHide={fecharModalDetalhesTarefa}>
                <Modal.Header closeButton>
                    <h4>Detalhes da Tarefa</h4>
                </Modal.Header>
                <Modal.Body>
                    <ClickableTable
                        coluna={colunas2}
                        data={ListaDetalhesTarefas}
                        onRowClick={function () { }}
                        itemsPerPage={10}
                        acaoEditar={function () { }}
                        acaoExcluir={AbrirModalCancelarLinha}
                        acaoVisualizar={function () { }}
                        usaAcoes={true}
                        usaEditar={false}
                        usaExcluir={true}
                        usaVisualizar={false}
                        iconeExcluir="bi bi-ban text-danger"
                        acessopesquisa='codigo'
                        labelpesquisa='Procurar pelo Código'
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TarefasList