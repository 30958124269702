import { Link } from 'react-router-dom';

const DesfragmentacaoEstoque = (): JSX.Element => {
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Desfragmentação Estoques</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2">
									<div className="col-sm-12 mb-2">
										<a
											data-bs-toggle="modal"
											data-bs-target="#modal-oper-desfragmentacaoEstoque-novaDesfragEstoq"
											className="btn  rounded me-2"
											style={{ backgroundColor: "#1c4b9b", color: "white" }}
										>
											<i className="mdi mdi-plus-circle me-2"></i>
											Novo
										</a>
										<a href="#" className="btn  rounded" style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<i className="mdi mdi-plus-circle me-2"></i>
											Desfragmentar
										</a>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>#</th>
												<th>Pallet Origem</th>
												<th>Cliente</th>
												<th>WMS</th>
												<th>Pallet Destino</th>
												<th>Cliente Destino</th>
												<th>WMS</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<input type="checkbox" />
												</td>
												<td>61</td>
												<td>Cliente 1</td>
												<td>1234</td>
												<td>161</td>
												<td>Cliente 1</td>
												<td>1234</td>
												<td className="table-action">
													<Link to={`/operacao/desfragmentacaoEstoque/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
													<a href="javascript:void(0);" className="action-icon">
														<i className="mdi mdi-delete"></i>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-oper-desfragmentacaoEstoque-novaDesfragEstoq"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Nova Desfragmentação Estoque
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="tab-pane show active" id="tab-oper-desfragmentacaoEstoqueNovo-geral">
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="pallet_origem"
														placeholder=""
													/>
													<label htmlFor="pallet_origem">Pallet Origem</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="qtde_retira"
														placeholder=""
													/>
													<label htmlFor="qtde_retira">Qtde Retira</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="wms"
														placeholder=""
													/>
													<label htmlFor="wms">WMS</label>
												</div>
											</div>
										</div>

										<div className="row mb-1 align-items-center">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="cliente"
														placeholder=""
													/>
													<label htmlFor="cliente">Cliente</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="bloqueado" />
													<label className="form-check-label" htmlFor="bloqueado">Bloq.</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="validade"
														placeholder=""
													/>
													<label htmlFor="validade">Validade</label>
												</div>
											</div>
											<div className="col-sm-4 form-floating">

												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Quantidade</label>
											</div>
											<div className="col-sm-4 form-floating">

												<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
												<label className="form-label">Prog</label>
											</div>
										</div>
										<hr />
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="pallet_destino"
														placeholder=""
													/>
													<label htmlFor="pallet_destino">Pallet Destino</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="pallet_novo"
														placeholder=""
													/>
													<label htmlFor="pallet_novo">Pallet Novo</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="wms_novo"
														placeholder=""
													/>
													<label htmlFor="wms_novo">WMS</label>
												</div>
											</div>
										</div>
										<div className="row mb-1 align-items-center">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="cliente_novo"
														placeholder=""
													/>
													<label htmlFor="cliente_novo">Cliente</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="bloqueado_novo" />
													<label className="form-check-label" htmlFor="bloqueado_novo">Bloq.</label>
												</div>

											</div>
											<div className='col-sm-4'>
												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="rom_novo" />
													<label className="form-check-label" htmlFor="rom_novo">Rom.</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="validade_novo"
														placeholder=""
													/>
													<label htmlFor="validade_novo">Validade</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { DesfragmentacaoEstoque };
