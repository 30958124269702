import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../services/api';

export function VisualizarColaborador() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const idusuario = sessionStorage.getItem('IsIDFKL');

	const navigate = useNavigate();
	const [options, setOptions] = useState([])

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  

	const [inputs, setInputs] = useState({
		matricula: '',
		nome: '',
		funcao: '',
		turno: '',
		custo_hh: '',
		situacao: '',
		confere: '',
		transporte: '',
		picking: '',
		desfragmentacao: '',
		opera_empilhadaira: '',
		reposicao_primaria: '',
		bloqueio: '',
		motivo_Bloq: '',
		data_adicao: '',
		data_alteracao: '',
		codfilial: ''
	});
	//#region Carregar/Selecionar Filial
	const [filial, setFilial] = useState([])
	const [selecionafilial, setSelecionaFilial] = useState(0);
	const capturaFilial = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaFilial(value);
	};

	//#region Requisição de dados na api - CARREGAR filial
	const carregarDadosFilial = async () => {
		const response = await axios.get(Api + `/equipe/filial/json/?&auth=${token}&usuario=${idusuario}`);
		setFilial(response.data);
	};
	const BuscarFilial = () => {
		carregarDadosFilial();
	};
	//#endregion
	//#endregion	
	const [checkConfere, setCheckConfere] = useState(false);
	const [checkTransporta, setCheckTransporta] = useState(false);
	const [checkPicking, setCheckPicking] = useState(false);
	const [checkEmpilha, setCheckEmpilha] = useState(false);
	const [checkDesfrag, setCheckDesfrag] = useState(false);
	const [checkRessupri, setCheckRessupri] = useState(false);
	const [checkBloqueio, setCheckBloqueio] = useState(false);
	//const [check, setCheck] = useState(false);
	const [selected, setSelected] = useState(0);

	const handleCheckedConfere = (e: any) => {
		setCheckConfere(e.target.checked);
	}
	const handleCheckedPicking = (e: any) => {
		setCheckPicking(e.target.checked);
	}
	const handleCheckedEmpilha = (e: any) => {
		setCheckEmpilha(e.target.checked);
	}
	const handleCheckedDesfrag = (e: any) => {
		setCheckDesfrag(e.target.checked);
	}
	const handleCheckedRessupri = (e: any) => {
		setCheckRessupri(e.target.checked);
	}
	const handleCheckedBloqueio = (e: any) => {
		setCheckBloqueio(e.target.checked);
	}
	const handleCheckedTransporta = (e: any) => {
		setCheckTransporta(e.target.checked);
	}

	const handleChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	};
	const [status, setStatus] = useState('1');
	const selectedStatus = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setStatus(value)
	}

	//#region Carregar/Selecionar Turno
	const [turno, setTurno] = useState([])
	const [selecionaTurno, setSelecionaTurno] = useState(0);
	const capturaTurno = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaTurno(value);
	};

	//#region Requisição de dados na api - CARREGAR FAMILIA
	const carregarDadosTurno = async () => {
		const response = await axios.get(Api + `/equipe/turno/json/?tabela=COLABTURNO&auth=${token}`);
		setTurno(response.data);
	};
	const BuscarTurno = () => {
		carregarDadosTurno();
	}
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Função
	const [funcao, setFuncao] = useState([])
	const [selecionaFuncao, setSelecionaFuncao] = useState(0);
	const capturaFuncao = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaFuncao(value);
	};

	//#region Requisição de dados na api - CARREGAR FAMILIA
	const carregarDadosFuncao = async () => {
		const response = await axios.get(Api + `/equipe/funcao/json/?tabela=COLABFUNC&auth=${token}`);
		setFuncao(response.data);
	};
	const BuscarFuncao = () => {
		carregarDadosFuncao();
	}
	//#endregion
	//#endregion	

	const _id = useParams();

	//#region Carregar DADOS - USEEFFECT

	useEffect(() => {
		async function buscarDadosEquipe() {
			if (_id.id !== 'novo') {
				setShowCarregando(true)
				const response = await axios.get(Api + `/equipe/json/id/?&id=${_id.id}&auth=${token}`);

				const dataFormatada = {
					matricula: response.data[0].matricula,
					nome: response.data[0].nome,
					funcao: response.data[0].funcao,
					idturno: response.data[0].idturno,
					turno: response.data[0].turno,
					custo_hh: response.data[0].custo_hh,
					situacao: response.data[0].situacao,
					confere: response.data[0].confere,
					transporte: response.data[0].transporte,
					picking: response.data[0].picking,
					desfragmentacao: response.data[0].desfragmentacao,
					opera_empilhadaira: response.data[0].opera_empilhadaira,
					reposicao_primaria: response.data[0].reposicao_primaria,
					bloqueio: response.data[0].bloqueio,
					motivo_Bloq: response.data[0].motivo_Bloq,
					data_adicao: response.data[0].data_adicao,
					data_alteracao: response.data[0].data_alteracao,
					codfilial: response.data[0].codfilial,
					fantasia_filial: response.data[0].fantasia_filial

				}
				if (response.data[0].confere == 0) {
					setCheckConfere(false);
				}
				else if (response.data[0].confere == 1) {
					setCheckConfere(true);
				}
				if (response.data[0].transporte == 0) {
					setCheckTransporta(false);
				}
				else if (response.data[0].transporte == 1) {
					setCheckTransporta(true);
				}
				if (response.data[0].picking == 0) {
					setCheckPicking(false);
				}
				else if (response.data[0].picking == 1) {
					setCheckPicking(true);
				}
				if (response.data[0].opera_empilhadaira == 0) {
					setCheckEmpilha(false);
				}
				else if (response.data[0].opera_empilhadaira == 1) {
					setCheckEmpilha(true);
				}
				if (response.data[0].desfragmentacao == 0) {
					setCheckDesfrag(false);
				}
				else if (response.data[0].desfragmentacao == 1) {
					setCheckDesfrag(true);
				}
				if (response.data[0].reposicao_primaria == 0) {
					setCheckRessupri(false);
				}
				else if (response.data[0].reposicao_primaria == 1) {
					setCheckRessupri(true);
				}
				if (response.data[0].bloqueio == 0) {
					setCheckBloqueio(false);
				}
				else if (response.data[0].bloqueio == 1) {
					setCheckBloqueio(true);
				}
				//setSelected(data[0].coddepositante);
				setStatus(dataFormatada.situacao)

				setFuncao(response.data)
				setSelecionaFuncao(dataFormatada.idturno)

				setTurno(response.data)
				setSelecionaTurno(dataFormatada.idturno)

				setFilial(response.data)
				setSelecionaFilial(dataFormatada.codfilial)

				setInputs(dataFormatada);
				setShowCarregando(false)
			}
		}
		if (_id.id) buscarDadosEquipe();
	}, [_id.id]);
	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Visualizar Colaborador</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form>
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralColaborador" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralColaborador">
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="matricula"
															name="matricula"
															value={inputs.matricula || ""}
															onChange={handleChange}
															required />
														<label className="form-label" htmlFor="matricula">
															Matrícula
														</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="nome"
															name="nome"
															value={inputs.nome || ""}
															onChange={handleChange}
															required />
														<label className="form-label" htmlFor="nome">
															Nome
														</label>
													</div>
												</div>
											</div>
											<div className="row">

												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" disabled id="funcao" title='Tabela: COLABFUNC'
															value={selecionaFuncao} onChange={capturaFuncao} onClick={BuscarFuncao}>
															{
																funcao.map(function (dados: any) {
																	return (
																		<option value={dados.idfuncao}>{dados.idfuncao} - {dados.funcao}</option>
																	)
																})
															}
														</select>
														<label className="form-label" htmlFor="funcao" >
															Função
														</label>
													</div>
												</div>

												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" disabled id="turno" title='Tabela: COLABTURNO'
															value={selecionaTurno} onChange={capturaTurno} onClick={BuscarTurno}>
															{
																turno.map(function (dados: any) {
																	return (
																		<option value={dados.idturno}>{dados.idturno} - {dados.turno}</option>
																	)
																})
															}

														</select>
														<label className="form-label" htmlFor="turno">
															Turno
														</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<input type="text" disabled className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' id="custo_hh"
															name="custo_hh"
															value={inputs.custo_hh || ""}
															onChange={handleChange} />
														<label className="form-label" htmlFor="custoHr">
															Custo Hr.
														</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="mb-1 form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize" disabled id="ativo"
															value={status} onChange={selectedStatus}>
															<option value="0">Inativo</option>
															<option value="1" selected>Ativo</option>
														</select>
														<label className="form-label" htmlFor="ie">
															Status
														</label>
													</div>
												</div>
											</div>
											<div className="row" style={{ textAlign: 'center' }}>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="confere">
															Confere
														</label>
														<input id="c-tptw-1" type="checkbox" disabled checked={checkConfere}
															onChange={handleCheckedConfere} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Transporta
														</label>
														<input id="c-tptw-12" type="checkbox" disabled checked={checkTransporta}
															onChange={handleCheckedTransporta} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Picking
														</label>
														<input id="c-tpt-14" type="checkbox" disabled checked={checkPicking}
															onChange={handleCheckedPicking} />
													</div>
												</div>
												<div className="col-sm-2 form-floating">
													<div className="mb-1">
														<label className="form-label" htmlFor="valdiaed">
															Empilhadeira
														</label>
														<input id="c-tpt-16" type="checkbox" disabled checked={checkEmpilha}
															onChange={handleCheckedEmpilha} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Desfragmenta
														</label>
														<input id="c-tpt-17" type="checkbox" disabled checked={checkDesfrag}
															onChange={handleCheckedDesfrag} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="valdiaed">
															Ressuprimento
														</label>
														<input id="c-tpt-18" type="checkbox" disabled checked={checkRessupri}
															onChange={handleCheckedRessupri} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button
												className="btn btn-cancelar mb-2 me-1"
												onClick={() => navigate(-1)}
												type="button"
											>
												Cancelar
											</button>
											<button
												className="btn btn-novo mb-2 me-1" type="submit"
												disabled>Salvar</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>
		</>
	);
};
