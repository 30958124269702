import { ITabela } from "../../../../components/Table/TabelaInterface";


export const colunasGrid: ITabela[] = [
	// id, id_filial, id_cliente, rota, msg, cadastro, secret_key, codigo 
	{
		titulo: 'ID',
		acesso: 'id',
	},	
	{
		titulo: 'ID Cliente',
		acesso: 'id_cliente',
	},
	{
		titulo: 'Rota',
		acesso: 'rota',
	},
	{
		titulo: 'Mensagem',
		acesso: 'msg',
	},
	{
		titulo: 'Secret Key',
		acesso: 'secret_key',
	},
	{
		titulo: 'Código',
		acesso: 'codigo_retorno',
	},
	{
		titulo: 'Cadastro',
		acesso: 'datacadastro',
	},
];