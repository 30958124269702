import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import UA from './interfaces/interfaceUA';
import { Modal } from 'react-bootstrap';
import MascaraValor from '../../../../components/functions/mascaraValor';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle } from '@mdi/js';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import CarregarPermissoes from '../../../../hooks/carregaPermissoesUsuario/carregaPermissoes';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';
import { useNavigate } from 'react-router-dom';
function AjusteEstoque() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoDetalhar, setPermissaoDetalhar] = useState(false);
	const [permissaoEspecialGravar, setPermissaoEspecialGravar] = useState(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarAjusteEstoque) {
				toast.warning('Você não tem permissão para acessar Ajuste de Estoque.');
				navigate('/');
			} else {
				setPermissaoDetalhar(permissoes.perfilPermissao.detalharAjusteEstoque);
				setPermissaoEspecialGravar(permissoes.perfilPermissao.permEspAjusteEstoque);
			}
		}
	}, [permissoes, erro, navigate]);

	const [idDepositanteSelecionado, setIdDepositanteSelecioando] = useState('');
	const [ListaDepositantes, setListaDepositantes] = useState([]);
	const [showCarregando, setShowCarregando] = useState(false);
	const [operacaoFiscal, setOperacaoFiscal] = useState(false);
	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/operacao/${id_filial}/${id_usuario}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaDepositantes(resposta.data.depositantes);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message);
		}
	}
	useEffect(function () {
		CarregarDepositantes(id_filial, id_usuario);
	}, []);
	const [itensPorPagina, setitensPorPagina] = useState(10);
	const [listaUas, setListaUas] = useState<UA[]>([]);
	const [UAselecionada, setUASelecionada] = useState<UA>();
	const colunasUA: ITabela[] = [
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Processo', acesso: 'id_processo' },
		{ titulo: 'Id wms', acesso: 'id_wms' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Unid.Controle', acesso: 'und_emb' },
		{ titulo: 'Tipo', acesso: 'tipo_ua' },
		{ titulo: 'Validade', acesso: 'validade_menor_formatado' },
		{ titulo: 'Produção', acesso: 'producao_menor_formatado' },
		{ titulo: 'Lote', acesso: 'lote' },
		{ titulo: 'Qtde.Estoque', acesso: 'qtde' },
		{ titulo: 'Qtde.Reservado', acesso: 'qtde_reserva' },
		{ titulo: 'Qtde. Disponível', acesso: 'qtde_disp' },
		{ titulo: 'Volume', acesso: 'volume_1' },
		{ titulo: 'Peso Br', acesso: 'pb' },
		{ titulo: 'Peso Líq', acesso: 'pl' },
	];
	async function CarregarEstoqueDepositante(id_depositante: string, id_filial: string) {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/estoque/depositante/${id_depositante}/${id_filial}`, {
				headers: {
					authorization: token,
				},
			});
			setListaUas(resposta.data.estoque);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}
	useEffect(
		function () {
			if (idDepositanteSelecionado != '') {
				CarregarEstoqueDepositante(idDepositanteSelecionado, id_filial);
				//setar operação fiscal
				const fiscal: any = ListaDepositantes.find(function (depositante: any) {
					return depositante.id_cliente == idDepositanteSelecionado;
				});
				setOperacaoFiscal(fiscal.operacao_fiscal);
			} else {
				setListaUas([]);
				setOperacaoFiscal(false);
			}
		},
		[idDepositanteSelecionado]
	);
	const [showModalUA, setshowModalUA] = useState<boolean>(false);
	async function AtualizarUA() {
		try {
			const dados = {
				ua: UAselecionada,
				usuario: usuarioLogado,
			};
			setShowCarregando(true);
			const resposta = await axios.put(
				`${process.env.REACT_APP_API_BASE_URL}/atualizar/ua/estoque/${id_filial}/${idDepositanteSelecionado}`,
				dados,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setShowCarregando(false);
			setshowModalUA(false);
			toast.success(resposta.data.message, {
				autoClose: 900,
			});
			CarregarEstoqueDepositante(idDepositanteSelecionado, id_filial);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}

	return (
		<div className="main">
			<h4 className="tituloDaPagina mt-2 ms-1">Ajuste de Estoque</h4>
			<div className="card">
				<div className="card-body">
					<div className="container p-0 m-0">
						<div className="row">
							<div className="col-sm col-md-6 col-lg-3">
								<div className="form-floating me-1 ">
									<select
										value={idDepositanteSelecionado}
										onChange={function (e) {
											setIdDepositanteSelecioando(e.target.value);
										}}
										className="form-select bordasInferiorInput text-black"
										id="floatingSelect"
										aria-label="Floating label select example"
									>
										<option value="">Selecione...</option>
										{ListaDepositantes.map(function (depositante: any) {
											return <option value={depositante.id_cliente}>{depositante.fantasia}</option>;
										})}
									</select>
									<label>Depositante</label>
								</div>
							</div>
						</div>
					</div>
					<ClickableTable
						coluna={colunasUA}
						itemsPerPage={itensPorPagina}
						data={listaUas}
						usaRowClick={permissaoDetalhar}
						onRowClick={
							permissaoDetalhar
								? function (ua: UA) {
										setUASelecionada({
											id_ua: ua.id_ua,
											tipo_ua: ua.tipo_ua,
											id_processo: ua.id_processo,
											id_wms: ua.id_wms,
											peso_embal: ua.peso_embal,
											codigo: ua.codigo,
											descricao: ua.descricao,
											und_emb: ua.und_emb,
											validade: ua.validade_menor?.split('T')[0] || '',
											producao: ua.producao_menor?.split('T')[0] || '',
											volume_1: ua.volume_1,
											lote: ua.lote,
											qtde: ua.qtde,
											qtde_reserva: ua.qtde_reserva,
											qtde_original: ua.qtde,
											qtde_disp: ua.qtde_disp,
											pb: ua.pb,
											pl: ua.pl,
										});
										setshowModalUA(true);
								  }
								: () => {}
						}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={true}
						usaExcluir={true}
						usaVisualizar={false}
						acessopesquisa="id_ua"
						labelpesquisa="Procurar por U.A"
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-2 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setitensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={showModalUA}
				centered
				size="xl"
				onShow={function () {
					if (!operacaoFiscal) {
						toast.error(
							`Depositante com operação Fiscal,
                        não é permitido ajuste de quantidade, apenas os demais itens`,
							{
								autoClose: 3000,
							}
						);
					}
				}}
				onHide={function () {
					setshowModalUA(false);
				}}
			>
				<Modal.Header closeButton>
					<h4>Ajustar U.A</h4>
				</Modal.Header>
				<Modal.Body>
					<form
						onSubmit={function (e) {
							e.preventDefault();
							AtualizarUA();
						}}
					>
						<div className="container">
							<div className="row">
								<div className="col-sm col-md col-lg-12 d-flex">
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.id_ua}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>U.A</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.codigo}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Código</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.id_processo}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Processo</label>
									</div>
									<div className="form-floating me-1 w-50">
										<input
											type="text"
											readOnly
											value={UAselecionada?.descricao}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Descrição</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.und_emb}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Unid. Embalagem</label>
									</div>
								</div>
								<h4 className="mt-3">Dados Editáveis</h4>
								<div className="col-sm col-md col-lg-12 d-flex">
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.qtde}
											required
											readOnly={operacaoFiscal == false ? false : true}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, qtde: MascaraValor(e.target.value) });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Qtde. Controle</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.volume_1}
											required
											readOnly={operacaoFiscal == false ? false : true}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, volume_1: MascaraValor(e.target.value) });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Qtde. Volume</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.pl}
											required
											readOnly={UAselecionada?.tipo_ua == 'Padrão' || operacaoFiscal == false ? false : true}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, pl: MascaraValor(e.target.value) });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Peso Líq.</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="date"
											value={UAselecionada?.producao}
											required
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, producao: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Produção</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="date"
											value={UAselecionada?.validade}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, validade: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Validade</label>
									</div>
									<div className="form-floating me-3">
										<input
											type="text"
											value={UAselecionada?.lote}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, lote: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Lote</label>
									</div>
									{permissaoEspecialGravar && (
										<div className="form-floating">
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-black bg-white border-0 mt-2"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>

			<ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
		</div>
	);
}

export default AjusteEstoque;
