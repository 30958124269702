import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn, mdiMagnify } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import DatalistInput from 'react-datalist-input';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';
import { colunasGridPesquisaProduto } from './components/colunasGridPesquisaNCM';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
import InputDecimal from '../../../../components/componentes/formulario/InputDecimal';
const NovoProdutoDeFornecedor = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_prod_fornecedor = params.id || '';
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Embalagem

	const [abrirPesquisaProduto, setAbrirPesquisaProduto] = useState(false);
	const [focoDepositante, setFocoDepositante] = useState(false);
	const [focoFornecedor, setFocoFornecedor] = useState(false);
	//#endregion

	// dados do depositante
	const [cnpjDepositante, setCnpjDepositante] = useState<string>('');
	const [id_depositante, setIdDepositante] = useState<string>('');
	const [nomedepositante, setNomeDepositante] = useState<string>('');
	const [codigo, setCodigo] = useState<string>('');
	const [descricao, setDescricao] = useState<string>('');
	const [unidfiscal, setUnidFiscal] = useState<string>('');
	const [id_wms, setIdWms] = useState<string>('');

	//dados do fornecedor
	const [cnpjFornecedor, setCnpjFornecedor] = useState<string>('');
	const [nomeFornecedor, setNomeFornecedor] = useState<string>('');
	const [id_fornecedor, setIdFornecedor] = useState<string>('');
	const [unidfiscalforn, setUnidFiscalForn] = useState<string>('');
	const [codprodforn, setCodProdForn] = useState<string>('');
	const [fator, setFator] = useState<number>(0);

	const selecionaUnidadeFiscal = (dados: any) => {
		const idunidfiscal = dados;

		if (idunidfiscal.toUpperCase() === unidfiscal.toUpperCase()) {
			setFator(1);
		} else {
			setFator(0);
		}
		setUnidFiscalForn(idunidfiscal.toUpperCase());
	};

	//#region ==> Buscar dados do depositante ao informar pelo menos 3 caracteres e alimentar o datalist
	const [dadosDepositante, setDadosDepositante] = useState([]);
	async function buscarDepositante(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;
		setShowCarregando(true);
		const dados = {
			filtro: _filtro,
		};
		axios
			.post(Api + `/lista/prodforn/depositante/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosDepositante(resposta.data.depositante);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region ==> Buscar dados do fornecedor ao informar pelo menos 3 caracteres e alimentar o datalist
	const [dadosFornecedor, setDadosFornecedor] = useState([]);
	async function buscarFornecedor(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;
		setShowCarregando(true);
		const dados = {
			filtro: _filtro,
		};
		axios
			.post(Api + `/lista/prodforn/fornecedor/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosFornecedor(resposta.data.fornecedor);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de unidade fiscal do produto para alimentar o select/option
	const [dadosUnidadeFiscal, setDadosUnidadeFiscal] = useState([]);
	async function ListarUnidadeFiscal() {
		axios
			.get(Api + `/lista/prodforn/unidade_fiscal/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosUnidadeFiscal(resposta.data.unidade_fiscal);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const dadosDeEnvio = {
		id_depositante,
		cnpjDepositante,
		cnpjFornecedor,
		id_wms,
		codigo,
		codprodforn,
		unidfiscal,
		unidfiscalforn,
		fator: !fator ? 0 : fator,
		usuario: usuarioLogado,
	};

	//#region -> Função para inserir um novo produto
	function insereNovoproduto() {
		if (codigo === '') {
			toast.error('Informe o código do produto');
			return;
		}
		if (codprodforn === '') {
			toast.error('Informe o código do produto do fornecedor');
			return;
		}
		if (fator === 0) {
			toast.error('Informe o fator do produto');
			return;
		}
		setShowCarregando(true);
		axios
			.post(Api + `/insere/produtofornecedor/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/produtoforn/edit/${id_filial}/${id_prod_fornecedor}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);

				ListarUnidadeFiscal();
				setIdDepositante(resposta.data.produto[0].id_cliente);
				setNomeDepositante(resposta.data.produto[0].depositante);
				setCnpjDepositante(resposta.data.produto[0].cnpj_cliente);
				setCnpjFornecedor(resposta.data.produto[0].cnpj_fornecedor);
				setIdFornecedor(resposta.data.produto[0].id_fornecedor);
				setNomeFornecedor(resposta.data.produto[0].fornecedor);
				setFator(resposta.data.produto[0].fator);
				setCodigo(resposta.data.produto[0].codigo_cliente);
				setCodProdForn(resposta.data.produto[0].codigo_fornecedor);
				setDescricao(resposta.data.produto[0].descricao);
				setIdWms(resposta.data.produto[0].id_wms);
				setUnidFiscal(resposta.data.produto[0].unid_cliente);
				setUnidFiscalForn(resposta.data.produto[0].unid_fornecedor);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarproduto() {
		if (codigo === '') {
			toast.error('Informe o código do produto');
			return;
		}
		if (codprodforn === '') {
			toast.error('Informe o código do produto do fornecedor');
			return;
		}
		if (fator === 0) {
			toast.error('Informe o fator do produto');
			return;
		}
		axios
			.put(Api + `/editar/produtoforn/${id_filial}/${id_prod_fornecedor}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	const selecionaProduto = (dados: any) => {
		// console.log('dados: ', dados);
		setCodigo(dados.codigo);
		setDescricao(dados.descricao);
		setUnidFiscal(dados.unid_fiscal);
		setIdWms(dados.id_wms);
		setAbrirPesquisaProduto(false);
	};
	const fecharPesquisaProduto = () => {
		setAbrirPesquisaProduto(false);
	};
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Produto de Fornecedor'
									: acao === 'editar'
									? 'Editar Produto de Fornecedor'
									: 'Visualizar Produto de Fornecedor'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoproduto();
								} else if (acao === 'editar') {
									editarproduto();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a
												href="#prodDepositante"
												data-bs-toggle="tab"
												aria-expanded="true"
												className="nav-link rounded-0 active"
											>
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Depositante</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#prodFornecedor" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Fornecedor</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="prodDepositante">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="cnpjDepositante"
															name="cnpjDepositante"
															value={cnpjDepositante}
															disabled
														/>
														<label className="form-label">CNPJ</label>
													</div>
												</div>
												<div className="col-sm-10">
													<div className="form-floating mb-1">
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="Depositante"
															showLabel={true}
															onSelect={(item) => {
																setIdDepositante(item.id);
																setNomeDepositante(item.value);
																setCnpjDepositante(item.cnpj);
															}}
															items={dadosDepositante}
															onFocus={() => setFocoDepositante(true)}
															onBlur={() => setFocoDepositante(false)}
															onChange={(item) => buscarDepositante(item)}
															className="mt-1"
															value={nomedepositante}
															inputProps={{
																disabled: acao === 'criar' ? false : true,
																required: true,
																style: {
																	border: 'none',
																	borderRadius: '0px',
																	outline: 'none',
																	borderBottom: focoDepositante ? '2px solid #3165f5' : '1px solid #ccc',
																	boxShadow: focoDepositante ? '0 0 0 #ccc' : 'none',
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3 position-relative">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="codigo"
															name="codigo"
															value={codigo}
															onChange={(e) => setCodigo(e.target.value)}
															disabled
														/>
														<label className="form-label">Código</label>
														<BotaoCustomizado
															type="button"
															className={
																cnpjDepositante !== '' && (acao === 'criar' || acao === 'editar')
																	? `btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y`
																	: `btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y disabled`
															}
															descricaoBotao=""
															icone={mdiMagnify}
															onclick={() => setAbrirPesquisaProduto(true)}
														/>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="descricao"
															name="descricao"
															value={descricao}
															onChange={(e) => setDescricao(e.target.value)}
															disabled
														/>
														<label className="form-label">Descrição</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="unidfiscal"
															name="unidfiscal"
															value={unidfiscal}
															onChange={(e) => setUnidFiscal(e.target.value)}
															disabled
														/>
														<label className="form-label">Unid. Fiscal</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_wms"
															name="id_wms"
															value={id_wms}
															onChange={(e) => setIdWms(e.target.value)}
															disabled
														/>
														<label className="form-label">ID WMS</label>
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane" id="prodFornecedor">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="cnpjFornecedor"
															name="cnpjFornecedor"
															value={cnpjFornecedor}
															disabled
														/>
														<label className="form-label">CNPJ</label>
													</div>
												</div>
												<div className="col-sm-8">
													<div className="form-floating mb-1">
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="Fornecedor"
															showLabel={true}
															onSelect={(item) => {
																setIdFornecedor(item.id);
																setNomeFornecedor(item.value);
																setCnpjFornecedor(item.cnpj);
															}}
															items={dadosFornecedor}
															onFocus={() => setFocoFornecedor(true)}
															onBlur={() => setFocoFornecedor(false)}
															onChange={(item) => buscarFornecedor(item)}
															className="mt-1"
															value={nomeFornecedor}
															inputProps={{
																disabled: acao === 'criar' ? false : true,
																required: true,
																style: {
																	border: 'none',
																	borderRadius: '0px',
																	outline: 'none',
																	borderBottom: focoFornecedor ? '2px solid #3165f5' : '1px solid #ccc',
																	boxShadow: focoFornecedor ? '0 0 0 #ccc' : 'none',
																},
															}}
														/>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_fornecedor"
															name="id_fornecedor"
															value={id_fornecedor}
															onChange={(e) => setIdFornecedor(e.target.value)}
															disabled
														/>
														<label className="form-label">ID. Fornecedor</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<InputSemZeroEsquerda
															label="Cód. Produto Fornecedor"
															id="codprodforn"
															name="codprodforn"
															value={codprodforn}
															onChange={setCodProdForn}
															required
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
													</div>
												</div>
												<div className="col-sm-6">
													<InputSelect
														label="Unid. Fiscal"
														name="unidfiscalforn"
														value={unidfiscalforn}
														required
														onClick={ListarUnidadeFiscal}
														onChange={selecionaUnidadeFiscal}
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														dados={dadosUnidadeFiscal}
													/>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<InputDecimal
															label="Fator"
															id="fator"
															name="fator"
															value={fator}
															required
															onChange={setFator}
															disabled={
																unidfiscalforn.toUpperCase() === unidfiscal.toUpperCase()
																	? true
																	: acao === 'criar' || acao === 'editar'
																	? false
																	: true
															}
														/>
														{/* <input
															type="number"
															step={1}
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="fator"
															name="fator"
															value={fator}
															required
															onChange={(e) => setFator(parseInt(e.target.value))}
															disabled={
																unidfiscalforn.toUpperCase() === unidfiscal.toUpperCase()
																	? true
																	: acao === 'criar' || acao === 'editar'
																	? false
																	: true
															}
														/>
														<label className="form-label">Fator</label> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando show={showCarregando} mensagem="Carregando dados..." />

			{/* MODAL PARA PESQUISA DE PRODUTO */}
			<ModalPesquisa
				colunas={colunasGridPesquisaProduto}
				fetchUrl={Api + `/lista/prodforn/produto/${id_depositante}/${id_filial}`}
				filtro="descricao"
				textoFiltro="Pesquisar Produto"
				visible={abrirPesquisaProduto}
				onSelect={selecionaProduto}
				onClose={fecharPesquisaProduto}
			/>
		</>
	);
};

export { NovoProdutoDeFornecedor };
