import React from 'react';

interface InputSelectProps {
	value: string;
	onClick: () => void;
	onChange: (value: string) => void;
	disabled: boolean;
	label?: string;
	dados: { value: string; label: string }[];
	name?: string;
	required?: boolean;
	textoSelecao?: string;
	valueTextoSelecao?: string;
	campoValue?: 'value' | 'label';
}

const InputSelect: React.FC<InputSelectProps> = ({
	value,
	onClick,
	onChange,
	disabled,
	label,
	dados,
	name,
	required,
	textoSelecao = 'Selecione',
	valueTextoSelecao = '',
	campoValue = 'value',
}) => {
	return (
		<div className="form-floating mb-1">
			<select
				className="form-select bordasInferiorInput text-black text-capitalize"
				id={name}
				name={name}
				value={value}
				onClick={onClick}
				onChange={(e) => onChange(e.target.value)}
				disabled={disabled}
				required={required}
			>
				<option value={valueTextoSelecao}>{textoSelecao}</option>
				{campoValue === 'value'
					? dados.map((item) => (
							<option key={item.value} value={item.value}>
								{item.value}
							</option>
					  ))
					: dados.map((item) => (
							<option key={item.value} value={item.value}>
								{item.label}
							</option>
					  ))}
			</select>
			{label && (
				<label className="form-label" htmlFor={name}>
					{label}
				</label>
			)}
		</div>
	);
};

export default InputSelect;
