import { Outlet } from 'react-router-dom';
import { FooterBar } from './footerBar';
import { LeftBar } from './leftBar';
import { NavBar } from './navBar';

const MainPage = (): JSX.Element => {
	
	return (
		<>
			<NavBar />
			<LeftBar />
			<div className="content-page">
				<div className="content">
					<div className="container-fluid">

						<Outlet />
					</div>
				</div>
			</div>
			<FooterBar />
		</>
	);
};

export { MainPage };
