import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import MascaraCNPJ from "../../../../components/functions/mascaraCNPJ"
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';

function VerDetalhesNota() {

    const params = useParams()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const chave = queryParams.get("chave")

    const token = sessionStorage.getItem("IsFKLTOKEN")!

    const [inputsCabecalho, setInputsCabecalho] = useState({
        nf: "",
        serie: "",
        emisso: "",
        chave: "",
        id_erp: "",
        natop: "",
        transportadora: "",
        infocompl: ""
    })

    const [inputsEmitente, setInputsEmitente] = useState({
        cnpj: "",
        ie: "",
        razaoSocial: "",
        logradouro: "",
        compl: "",
        numero: "",
        bairro: "",
        cidade: "",
        cep: "",
        uf: "",
        pais: ""
    })

    const [inputsDestinatario, setInputsDestinatario] = useState({
        cnpj: "",
        ie: "",
        razaoSocial: "",
        logradouro: "",
        compl: "",
        numero: "",
        bairro: "",
        cidade: "",
        cep: "",
        uf: "",
        pais: ""
    })

    const [inputsTotais, setInputsTotais] = useState({
        volume: "",
        pb: "",
        pl: "",
        vbc: "",
        vicms: "",
        totalProduto: "",
        vipi: "",
        vpis: "",
        vcofins: "",
        totalNf: ""
    })

    const [ProdutoSelecionado, setProdutoSelecionado] = useState<any>([])

    //função para atualizar valores no objeto cabecalho
    function AtualizarInputCabecalho(value: any, propriedade: string) {

        setInputsCabecalho(function (CabecalhoAtual) {

            return {
                ...CabecalhoAtual,
                [propriedade]: value
            }
        })
    }

    //funcção para atualizar inputs do emitente
    function AtualzarInputEmitente(value: any, propriedade: string) {

        setInputsEmitente(function (EmitenteAtual) {

            return {
                ...EmitenteAtual,
                [propriedade]: value
            }
        })
    }

    //funcção para atualizar inputs do Destinatario
    function AtualzarInputDestinatario(value: any, propriedade: string) {

        setInputsDestinatario(function (DestinatarioAtual) {

            return {
                ...DestinatarioAtual,
                [propriedade]: value
            }
        })
    }

    //função para atualizar inputs de toatis
    function AtualizarInputsTotais(value: any, propriedade: string) {

        setInputsTotais(function (totaisAtuais) {
            return {
                ...totaisAtuais,
                [propriedade]: value
            }
        })
    }

    const navigate = useNavigate()

    const colunasProdutos: ITabela[] = [
        {
            titulo: "Item", acesso: "nitem"
        },
        {
            titulo: "Cfop", acesso: "cfop"
        },
        {
            titulo: "Ncm", acesso: "ncm"
        },
        {
            titulo: "Origem", acesso: "orih"
        },
        {
            titulo: "Cst Icms", acesso: "cst_icms"
        },
        {
            titulo: "Código", acesso: "codigo"
        },
        {
            titulo: "Gtin", acesso: "gtin"
        },
        {
            titulo: "Descrição", acesso: "descricao"
        },
        {
            titulo: "Qtde.Com", acesso: "qtd_com"
        },
        {
            titulo: "Unid.Com", acesso: "und_com"
        },
        {
            titulo: "Valor Unit", acesso: "vlr_unit"
        }
    ]
    const [ListaProdutos, setListaProdutos] = useState([])

    const [itensPorPagina, setItensPorPagina] = useState(10)

    const [showModalCarregando, setShowModalCarregando] = useState(false)

    function CarregarNF(id_filial: string, chave: string) {

        setShowModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/nf/unica/${chave}/${id_filial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowModalCarregando(false)
            setListaProdutos(resposta.data.produtos)

            //setando NF-e
            const nf = resposta.data.nf
            setInputsCabecalho({
                nf: nf.nf,
                serie: nf.serie,
                emisso: nf.dt_emissao.split("T")[0],
                chave: nf.chave_acesso,
                id_erp: nf.id_erp,
                natop: nf.natureza_op,
                transportadora: nf.transportadora,
                infocompl: nf.infcpl
            })

            //setando Emitente
            setInputsEmitente({
                cnpj: nf.cnpj_emit,
                ie: nf.ie_emit,
                razaoSocial: nf.razao_emit,
                logradouro: nf.logr_emit,
                compl: nf.comp_emit,
                numero: nf.num_emit,
                bairro: nf.bairro_emit,
                cidade: nf.mun_emit,
                cep: nf.cep_emit,
                uf: nf.uf_emit,
                pais: nf.pais_emit
            })

            setInputsDestinatario({
                cnpj: nf.cnpj_dest,
                ie: nf.ie_dest,
                razaoSocial: nf.razao_dest,
                logradouro: nf.logr_dest,
                compl: nf.comp_dest,
                numero: nf.num_dest,
                bairro: nf.bairro_dest,
                cidade: nf.mun_dest,
                cep: nf.cep_dest,
                uf: nf.uf_dest,
                pais: nf.pais_dest
            })

            setInputsTotais({
                volume: nf.vol,
                pb: nf.pb,
                pl: nf.pl,
                vbc: nf.vbc,
                vicms: nf.vicms,
                totalProduto: nf.vprod,
                vipi: nf.vipi,
                vpis: nf.vpis,
                vcofins: nf.vcofins,
                totalNf: nf.vnf
            })

        }).catch(function (erro) {

            setShowModalCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [showModalProduto, setShowModalProduto] = useState(false)

    useEffect(function () {

        CarregarNF(params.id!, chave!)
    }, [])

    return (
        <div className='main'>
            <div className="card mt-2 pb-3">
                <div className="card-body">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#nfe" type="button" role="tab" aria-controls="pills-home" aria-selected="true">NF-e</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#emitente" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Emitente</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#destinatario" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Destinatário</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#totais" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Totais</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#produtos" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Produtos</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="nfe" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='container p-0 m-0'>
                                <h4>NF-e</h4>
                                <div className="row">
                                    <div className="col-sm col-md col-lg-2">

                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "nf")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.nf} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>N° NFe</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "serie")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.serie} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Serie</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "emissao")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.emisso} type="date" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Emissão</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-6">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "chave")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.chave} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Chave</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "id_erp")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.id_erp} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>ID Erp</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-4">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "natop")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.natop} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Natureza Op.</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-6">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputCabecalho(e.target.value, "transportadora")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.transportadora} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Transportadora</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-6">
                                        <div className="form-floating">
                                            <textarea
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsCabecalho.infocompl}
                                                style={{ height: "150px" }}
                                                className="form-control bordasInferiorInput text-black" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                                            <label >Informações Compl.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="emitente" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='container p-0 m-0'>
                                <h4>Emitente</h4>
                                <div className="row">
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(MascaraCNPJ(e.target.value), "cnpj")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.cnpj} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>CNPJ</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "ie")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.ie} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Inscrição Estadual</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-6">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "razaoSocial")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.razaoSocial} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Razão Social</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "logradouro")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.logradouro} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Logradouro</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "compl")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.compl} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Complemento</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "numero")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.numero} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Número</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "bairro")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.bairro} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Bairro</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "cidade")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.cidade} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Cidade</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "cep")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.cep} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>CEP</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "uf")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.uf} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>UF</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputEmitente(e.target.value, "pais")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsEmitente.pais} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>País</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="destinatario" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='container p-0 m-0'>
                                <h4>Destinatário</h4>
                                <div className="row">
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(MascaraCNPJ(e.target.value), "cnpj")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.cnpj} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>CNPJ</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "ie")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.ie} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Inscrição Estadual</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-6">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "razaoSocial")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.razaoSocial} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Razão Social</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "logradouro")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.logradouro} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Logradouro</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "compl")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.compl} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Complemento</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "numero")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.numero} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Número</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "bairro")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.bairro} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Bairro</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "cidade")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.cidade} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Cidade</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "cep")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.cep} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>CEP</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "uf")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.uf} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>UF</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-3">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualzarInputDestinatario(e.target.value, "pais")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsDestinatario.uf} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>País</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="totais" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "volume")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.volume} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Volume</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "pb")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.pb} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Peso Br.</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "pl")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.pl} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Peso Líq.</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "vbc")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.vbc} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Base Icms</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "vicms")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.vicms} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Valor Icms</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "totalProduto")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.totalProduto} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Total Produto</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "vipi")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.vipi} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Valor IPI</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "vpis")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.vpis} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Valor Pis</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "vcofins")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.vcofins} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Valor Cofins</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md col-lg-2">
                                        <div className="form-floating">
                                            <input onChange={function (e) {

                                                AtualizarInputsTotais(e.target.value, "totalNf")
                                            }}
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={inputsTotais.totalNf} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder=" " />
                                            <label>Total NFe</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="produtos" role="tabpanel" aria-labelledby="pills-home-tab">
                            <ClickableTable
                                coluna={colunasProdutos}
                                itemsPerPage={itensPorPagina}
                                data={ListaProdutos}
                                usaRowClick={true}
                                onRowClick={function (produto: any) {

                                    setShowModalProduto(true)
                                    setProdutoSelecionado([produto])
                                }}
                                acaoEditar={function () {

                                }}
                                acaoExcluir={function () {

                                }}
                                acaoVisualizar={function () {

                                }}
                                usaAcoes={false}
                                usaEditar={false}
                                usaExcluir={false}
                                usaVisualizar={false}
                                acessopesquisa='codigo'
                                labelpesquisa='Procurar por Código'
                            />
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-2 m-auto text-center'>
                                        <label>Itens por Página</label>
                                    </div>
                                    <div className='w-100'></div>
                                    <div className='col-lg-1 m-auto'>

                                        <select value={itensPorPagina}
                                            onChange={function (e: any) {
                                                setItensPorPagina(e.target.value)
                                            }}
                                            className="form-select form-select-sm" aria-label=".form-select-sm example">
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-3">
                        <BotaoCustomizado
                            type="button"
                            className="btn btn-light text-danger bg-white border-0"
                            descricaoBotao="Voltar"
                            icone={mdiKeyboardReturn}
                            onclick={() => {
                                navigate(-1)
                            }}
                        />
                    </div>
                </div>
            </div>
            <ModalCarregando mensagem="Carregando..." mostrar={showModalCarregando} />


            <Modal show={showModalProduto} centered size="xl" onHide={function () {
                setShowModalProduto(false)
                setProdutoSelecionado([])
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhe do Protudo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClickableTable
                        coluna={[
                            {
                                titulo: "Item", acesso: "nitem"
                            },
                            {
                                titulo: "Cfop", acesso: "cfop"
                            },
                            {
                                titulo: "Ncm", acesso: "ncm"
                            },
                            {
                                titulo: "Código", acesso: "codigo"
                            },
                            {
                                titulo: "Gtin", acesso: "gtin"
                            },
                            {
                                titulo: "Descrição", acesso: "descricao"
                            },
                            {
                                titulo: "Qtde.Com", acesso: "qtd_com"
                            },
                            {
                                titulo: "Unid.Com", acesso: "und_com"
                            },
                            {
                                titulo: "Valor Unit.", acesso: "vlr_unit"
                            },
                            {
                                titulo: "Valor Total -", acesso: "vlr_prod"
                            },
                            {
                                titulo: "Cest", acesso: "cest"
                            },
                            {
                                titulo: "Gtin Tib", acesso: "gtintrib"
                            },
                            {
                                titulo: "Unid.Trib", acesso: "qtrib"
                            },
                            {
                                titulo: "Valor Unit.Trib", acesso: "vuntrib"
                            },
                            {
                                titulo: "Cst Icms", acesso: "cst_icms"
                            },
                            {
                                titulo: "Base Icms", acesso: "vbc_icms"
                            },
                            {
                                titulo: "%Icms", acesso: "p_icms"
                            },
                            {
                                titulo: "Valor Icms", acesso: "v_icms"
                            },
                            {
                                titulo: "Cód.Enq.Ipi", acesso: "cenq_ipi"
                            },
                            {
                                titulo: "Cst Ipi", acesso: "cst_ipi"
                            },
                            {
                                titulo: "Base Ipi", acesso: "vbc_ipi"
                            },
                            {
                                titulo: "% Ipi", acesso: "p_ipi"
                            },
                            {
                                titulo: "Valor Ipi", acesso: "v_ipi"
                            },
                            {
                                titulo: "Cst Pis", acesso: "vbc_pis"
                            },
                            {
                                titulo: "Base Pis", acesso: "vbc_pis"
                            },
                            {
                                titulo: "% Pis", acesso: "p_pis"
                            },
                            {
                                titulo: "Valor Pis", acesso: "v_pis"
                            },
                            {
                                titulo: "Cst Cofins", acesso: "cst_cofins"
                            },
                            {
                                titulo: "Base Cofins", acesso: "vbc_cofins"
                            },
                            {
                                titulo: "% Cofins", acesso: "p_cofins"
                            },
                            {
                                titulo: "Valor Cofins", acesso: "v_cofins"
                            }
                        ]}
                        itemsPerPage={1}
                        data={ProdutoSelecionado}
                        usaRowClick={false}
                        onRowClick={function () {


                        }}
                        acaoEditar={function () {

                        }}
                        acaoExcluir={function () {

                        }}
                        acaoVisualizar={function () {

                        }}
                        usaAcoes={false}
                        usaEditar={false}
                        usaExcluir={false}
                        usaVisualizar={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm col-md col-lg text-end">

                                <BotaoCustomizado
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Fechar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        setShowModalProduto(false)
                                        setProdutoSelecionado([])
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default VerDetalhesNota