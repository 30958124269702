import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { apenasNumeros } from '../../../../components/componentes/types/apenasNumeros';

interface TabelaSchemaInterface {
	tabela: string;
	esquema: string;
}
const NovoControleDeNumeracao = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;

	const params = useParams();
	const id_nome = params.id;
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion

	//dados do contrle de numeração
	const [schemaSelecionado, setSchemaSelecionado] = useState<string>(''); //schema
	const [tabelaSelecionada, setTabelaSelecionada] = useState<string>(''); //tabela
	const [colunaSelecionada, setColunaSelecionada] = useState<string>(''); //coluna
	const [nome_id, setNomeId] = useState<string>(''); //nome_id
	const [numero, setNumero] = useState<string>('0'); //numero

	//dados da tabela
	const [dadosTabela, setDadosTabela] = useState<TabelaSchemaInterface[]>([]);
	async function carregarDados() {
		setShowCarregando(true);
		await axios
			.get(`${Api}/lista/controlenumeracao/tabelas`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setDadosTabela(response.data.tabelas);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	//#region ==> Carregar dados do controle de embalagem de acordo com a rota /carrega/dados/controlenumeracao/edit/:id_filial/:tabela
	async function carregarDadosControleNumeracao() {
		setShowCarregando(true);
		await axios
			.get(`${Api}/carrega/dados/controlenumeracao/edit/${id_filial}/${id_nome}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				const dados = response.data.controlenumeracao[0];

				setNomeId(dados.nome_id);
				setNumero(dados.numero);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	//#endregion

	//Carregar dados da tabela ao abrir a tela
	useEffect(() => {
		if (acao === 'criar') {
			carregarDados();
		} else {
			carregarDadosControleNumeracao();
		}
	}, []);

	// Filtra os schemas únicos
	const schemas = Array.from(new Set(dadosTabela.map((item) => item.esquema)));

	// Filtra as tabelas com base no schema selecionado
	const tables = dadosTabela.filter((item) => item.esquema === schemaSelecionado);

	// buscar as colunas da tabela selecionada na rota /lista/controlenumeracao/colunas/:schema/:tabela
	const [colunas, setColunas] = useState([]);

	async function buscarColunas(esquema: string, tabela: string) {
		if (esquema === '' || tabela === '') {
			return toast.error('Selecione um schema e uma tabela');
		}
		await axios
			.get(`${Api}/lista/controlenumeracao/colunas/${esquema}/${tabela}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setColunas(response.data.campos);
			})
			.catch((erro) => {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	const dadosEnvio = {
		tabela: acao === 'criar' ? schemaSelecionado + '.' + tabelaSelecionada : id_nome,
		nome_id: acao === 'criar' ? colunaSelecionada : nome_id,
		numero: numero,
	};

	//#region  ==> Função para inserir novo controle de numeração
	async function insereNovoControleNumeracao() {
		setShowCarregando(true);
		await axios
			.post(`${Api}/insere/controlenumeracao/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para editar controle de numeração
	async function editarControleNumeracao() {
		setShowCarregando(true);
		await axios
			.put(`${Api}/editar/controlenumeracao/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Controle de Numeração'
									: acao === 'editar'
									? 'Editar Controle de Numeração'
									: 'Visualizar Controle de Numeração'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoControleNumeracao();
								} else if (acao === 'editar') {
									editarControleNumeracao();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									{acao === 'criar' && (
										<div className="row">
											<div className="col-sm-3">
												<div className="form-floating mb-1">
													<select
														className="form-select bordasInferiorInput text-black"
														value={schemaSelecionado}
														name="tipoveiculo"
														onChange={(e) => {
															setSchemaSelecionado(e.target.value);
															setTabelaSelecionada(''); // Reseta a tabela selecionada quando o schema muda
															setColunas([]); // Reseta as colunas quando o schema muda
															setTabelaSelecionada('');
															setColunaSelecionada('');
														}}
														required
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
													>
														<option value="">Selecione o Schema</option>
														{schemas.map((schema) => {
															return (
																<option key={schema} value={schema}>
																	{schema.toUpperCase()}
																</option>
															);
														})}
													</select>
													<label className="form-label" htmlFor="cfopEntrada">
														Schema
													</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating mb-1">
													<select
														className="form-select bordasInferiorInput text-black"
														value={tabelaSelecionada}
														name="tipoveiculo"
														onChange={(event) => {
															setTabelaSelecionada(event.target.value);
															setColunas([]); // Reseta as colunas quando a tabela muda
															setColunaSelecionada('');
														}}
														required
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
													>
														<option value="">Selecione a Tabela</option>
														{tables.map((Tabela) => {
															return (
																<option key={Tabela.tabela} value={Tabela.tabela}>
																	{Tabela.tabela.toUpperCase()}
																</option>
															);
														})}
													</select>
													<label className="form-label" htmlFor="cfopEntrada">
														Tabela
													</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating mb-1">
													<select
														className="form-select bordasInferiorInput text-black"
														value={colunaSelecionada}
														name="tipoveiculo"
														onChange={(event) => setColunaSelecionada(event.target.value)}
														onClick={() => buscarColunas(schemaSelecionado, tabelaSelecionada)}
														required
														disabled={
															acao === 'criar'
																? false
																: acao === 'editar'
																? false
																: tabelaSelecionada !== ''
																? false
																: true
														}
													>
														<option value="">Selecione a Coluna</option>
														{colunas.map((item: any) => {
															return (
																<option key={item.coluna} value={item.coluna}>
																	{item.coluna.toUpperCase()}
																</option>
															);
														})}
													</select>
													<label className="form-label" htmlFor="cfopEntrada">
														Nome ID
													</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder="Codigo da filial"
														id="numero"
														name="numero"
														value={numero}
														required
														onChange={(e) => setNumero(apenasNumeros(e.target.value))}
													/>
													<label className="form-label">Sequencial</label>
												</div>
											</div>
										</div>
									)}

									{acao !== 'criar' && (
										<div className="row">
											<div className="col-sm-4">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-uppercase"
														placeholder="Codigo da filial"
														id="id_nome"
														name="id_nome"
														value={id_nome}
														disabled
													/>
													<label className="form-label">Tabela</label>
												</div>
											</div>

											<div className="col-sm-4">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-uppercase"
														placeholder="Codigo da filial"
														id="nome_id"
														name="nome_id"
														value={nome_id}
														disabled
													/>
													<label className="form-label">Nome ID</label>
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder="Codigo da filial"
														id="numero"
														name="numero"
														value={numero}
														onChange={(e) => setNumero(apenasNumeros(e.target.value))}
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
													/>
													<label className="form-label">Sequencial</label>
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>
		</>
	);
};

export { NovoControleDeNumeracao };
