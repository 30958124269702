import { ITabela } from "../../../../../components/Table/TabelaInterface";

//Criação das colunas da tabela
export const colunasGridTelaProdutosDePicking: ITabela[] = [
	{
		titulo: 'ID WMS',
		acesso: 'id_wms',
	},

	{
		titulo: 'Código',
		acesso: 'codigo',
	},
	{
		titulo: 'Sequência',
		acesso: 'seq_sep',
	},
	{
		titulo: 'Local',
		acesso: 'local',
	},	
	{
		titulo: 'Usuário',
		acesso: 'usuario',
	},
];
