import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiKeyboardReturn, mdiMagnify, mdiPlusCircle, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner, Button, Card } from 'react-bootstrap';
import { BsExclamationCircleFill } from "react-icons/bs";
import Icon from '@mdi/react';

const Endereco = (): JSX.Element => {
	//#region
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const perfil = sessionStorage.getItem('IsIdPerfilFKL');
	const filial = sessionStorage.getItem('IsIDFilialPadraoFKL');

	const [carregando, setCarregando] = useState(true);
	const navigate = useNavigate();

	//#region => LISTAR endereços
	const [enderecos, setEnderecos] = useState([]);
	const [enderecosBackUp, setEnderecosBackUp] = useState([]);
	const buscarEnderecos = async () => {

		try {
			const resposta = await axios.get(Api + `/enderecos/?auth=${token}&codfilial=${filial}`);
			setEnderecos(resposta.data);
			setEnderecosBackUp(resposta.data);
			setCarregando(false);
		} catch (error) {
			console.log(error);
		}
	}
	//#endregion

	//#region => VISUALIZAR endereco
	const visualizarEndereco = async (tag: string, filial: string) => {
		navigate(`/config/cadast/endereco/visu/${tag}/${filial}`);
	}
	//#endregion

	//#region => EDITAR endereco
	const editarEndereco = (tag: string, filial: string) => {
		navigate(`/config/cadast/editar-endereco/${tag}/${filial}`);
	}
	//#endregion

	//#region => DELETAR endereco
	const [enderecoDelecao, setEnderecoDelecao] = useState('');
	const [filialDoEndereco, setFilialDoEndereco] = useState('');
	const [mostrarModalDelecao, setMostrarModalDelecao] = useState(false);
	const deletarEndereco = async (tag: string, filial: string) => {
		setMostrarModalDelecao(true);
		setEnderecoDelecao(tag);
		setFilialDoEndereco(filial);
	}
	const confirmarDelecao = async (tag: string, filial: string) => {
		try {
			await axios.delete(Api + `/endereco/deletar/?auth=${token}&tag=${tag}&filial=${filial}`);

			setMostrarModalDelecao(false);
			setCarregando(true);
			buscarEnderecos();


		} catch (error) {
			alert(`Não foi possível deletar o endereço.`)
			console.log(error)
			setCarregando(false);
		}
	}
	//#endregion

	//#region => PESQUISAR na lista de endereços
	const [mostrarModalPesquisa, setMostrarModalPesquisa] = useState(false);
	const [pesquisarPor, setPesquisarPor] = useState('Filial');
	const [termoPesquisa, setTermoPesquisa] = useState('');
	const pesquisarEndereco = (event: any) => {
		event.preventDefault()
		const pesquisa = filtrarEnderecos(pesquisarPor, termoPesquisa);
		setEnderecos(pesquisa);
		setMostrarModalPesquisa(false);
	}

	const filtrarEnderecos = (pesquisarPor: string, termoPesquisa: string) => {

		if (pesquisarPor === 'Filial') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.filial.includes(termoPesquisa)
			)
			return resposta;
		}

		if (pesquisarPor === 'Endereço') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.endereco.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Tipo') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.tipo_posicao.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Temperatura') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.tipo_temperatura.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Armazém') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.armazem.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Corredor') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => endereco.corredor.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Box') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => `${endereco.box}`.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Altura') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => `${endereco.altura}`.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		if (pesquisarPor === 'Profundidade') {
			const resposta = enderecosBackUp.filter(
				(endereco: any) => `${endereco.profundidade}`.toUpperCase().includes(termoPesquisa.toUpperCase())
			)
			return resposta;
		}

		return [];

	}
	//#endregion

	//#region => PAGINAÇÃO
	const [paginaAtual, setPaginaAtual] = useState(0);
	const itensPorPagina = 10;
	const totalDePaginas = Math.ceil(enderecos.length / itensPorPagina);

	const indiceInicial = paginaAtual * itensPorPagina;
	const indiceFinal = indiceInicial + itensPorPagina;
	const itensSelecionados = enderecos.slice(indiceInicial, indiceFinal);

	const primeiraPagina = () => {
		if (paginaAtual > 0) setPaginaAtual(0);
	}

	const ultimaPagina = () => {
		const ultPagina = paginaAtual + 1;
		if (ultPagina < totalDePaginas) setPaginaAtual(totalDePaginas - 1);
	}

	const paginaAnterior = () => {
		if (paginaAtual > 0) setPaginaAtual(paginaAtual - 1);
	}

	const proximaPagina = () => {
		const proxPagina = paginaAtual + 1
		if (proxPagina < totalDePaginas) setPaginaAtual(proxPagina);
	}
	//#endregion

	useEffect(() => { buscarEnderecos() }, []);

	//#endregion

	const [inputs, setInputs] = useState({
		idperfil: '',
		descricao: '',
		perm_visu: 0,
		perm_incluir: 0,
		perm_editar: 0,
		perm_excluir: 0,
	});
	useEffect(() => {

		async function buscarDadosPerfil() {
			if (perfil !== '') {
				const { data } = await axios.get(Api + `/perfil/json/id/?id=${perfil}&auth=${token}`);
				const dataFormatada = {
					idperfil: data[0].id_perfil,
					descricao: data[0].nom_perfil,
					perm_visu: data[0].conf_cad_endereco_visu,
					perm_incluir: data[0].conf_cad_endereco_incluir,
					perm_editar: data[0].conf_cad_endereco_editar,
					perm_excluir: data[0].conf_cad_endereco_excluir,
				}
				setInputs(dataFormatada);
			}
		}
		if (perfil) buscarDadosPerfil();
	}, [perfil]);

	//#region VALIDAR se o operador tem permissao para incluir empresa
	const [buttonIncluir, setbuttonIncluir] = useState("btn btn-novo mb-2 disabled")
	useEffect(() => {
		if (inputs.perm_incluir == 1) {
			setbuttonIncluir("btn btn-novo mb-2");
		}
		else if (inputs.perm_incluir == 0) {
			setbuttonIncluir("btn btn-novo mb-2 disabled");
		}
	})
	//#endregion

	//#region VALIDAR se o operador tem permissao para Incluir empresa
	const [buttonVisualizar, setbuttonVisualizar] = useState(false)
	useEffect(() => {
		if (inputs.perm_visu == 1) {
			setbuttonVisualizar(false);
		}
		else if (inputs.perm_visu == 0) {
			setbuttonVisualizar(true);
		}
	})
	//#endregion

	//#region VALIDAR se o operador tem permissao para Incluir empresa
	const [buttonEditar, setbuttonEditar] = useState(false)
	useEffect(() => {
		if (inputs.perm_editar == 1) {
			setbuttonEditar(false);
		}
		else if (inputs.perm_editar == 0) {
			setbuttonEditar(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Excluir empresa
	const [buttonExcluir, setbuttonExcluir] = useState(false)
	useEffect(() => {

		if (inputs.perm_excluir == 1) {
			setbuttonExcluir(false);
		}
		else if (inputs.perm_excluir == 0) {
			setbuttonExcluir(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Editar ou Excluir uma empresa caso nao tenha nenhuma das duas a coluna fica oculta
	const [validar, setValidar] = useState(false)
	useEffect(() => {

		if (!buttonVisualizar || !buttonEditar || !buttonExcluir) {
			setValidar(false);
		}
		else if (buttonVisualizar && buttonEditar && buttonExcluir) {
			setValidar(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Editar ou Excluir uma empresa caso nao tenha nenhuma das duas a coluna fica oculta
	const [validaAcao, setValidaAcao] = useState("d-flex flex-nowrap table-action visually-hidden")
	useEffect(() => {

		if (!buttonVisualizar || !buttonEditar || !buttonExcluir) {
			setValidaAcao("d-flex flex-nowrap table-action");
		}
		else if (buttonVisualizar && buttonEditar && buttonExcluir) {
			setValidaAcao("d-flex flex-nowrap table-action visually-hidden");
		}
	})
	//#endregion
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem dos Endereços</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<Card>
							<Card.Body>
								<div className="row">
									<div className="col-sm-6">
										<Link to={`/config/cadast/endereco/${0}`} className={`${buttonIncluir} rounded`}>
											<Icon path={mdiPlusCircle} size={1} className="me-2" />
											Gerar Endereços
										</Link>
									</div>
									<div className="col-sm-6">
										<Button variant="success" className="mb-2 rounded" onClick={() => { setMostrarModalPesquisa(true) }} style={{ float: 'right', color: 'white'}}>
											Pesquisar
											<Icon path={mdiMagnify} size={1} className="me-2" />
										</Button>
									</div>
								</div>
								<div className="table-responsive" >
									<table className="table table-fixed table-centered table-striped table-hover w-100 dt-responsive nowrap">
										<thead className="table-cabecalho">
											<tr  >
												<th>Filial</th>
												<th>Endereço</th>
												<th>Tipo</th>
												<th>Temperatura</th>
												<th>Armazém</th>
												<th>Corredor</th>
												<th>BOX</th>
												<th>Altura</th>
												<th>Profundidade</th>
												<th>Seq. Rota</th>
												<th hidden={validar}>Ações</th>
											</tr>
										</thead>
										<tbody className='corpoTabela'>
											{
												itensSelecionados.map((endereco: any, index: number) => {
													return (
														<>
															<tr key={index}>
																<td>{endereco.filial}</td>
																<td>{endereco.endereco}</td>
																<td>{endereco.tipo_posicao}</td>
																<td>{endereco.tipo_temperatura}</td>
																<td>{endereco.armazem}</td>
																<td>{endereco.corredor}</td>
																<td>{endereco.box}</td>
																<td>{endereco.altura}</td>
																<td>{endereco.profundidade}</td>
																<td>10</td>
																<td className={validaAcao}>
																	<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { visualizarEndereco(endereco.endereco, endereco.filial) }} hidden={buttonVisualizar}>
																		<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
																	</button>
																	<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { editarEndereco(endereco.endereco, endereco.filial) }} hidden={buttonEditar}>
																		<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
																	</button>
																	<button type="button" className="action-icon" style={{ border: "none", background: "none" }} onClick={() => { deletarEndereco(endereco.endereco, endereco.filial) }} hidden={buttonExcluir}>
																		<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
																	</button>
																</td>
															</tr>
														</>
													)
												})
											}
										</tbody>
										<tfoot>
											<tr className="rodapeTabelaPaginacao">
												<td colSpan={14}>
													<button title="primeira-pagina" onClick={primeiraPagina} style={{ border: "none", background: "none" }}>
														<Icon path={mdiSkipBackward} size={1} />
													</button>
													<button title="pagina-anterior" onClick={paginaAnterior} style={{ border: "none", background: "none" }}>
														<Icon path={mdiSkipPrevious} size={1} />
													</button>

													Página: {paginaAtual + 1} de {totalDePaginas} | Total de Registros: {enderecos.length}

													<button title="proxima-pagina" onClick={proximaPagina} style={{ border: "none", background: "none" }}>
														<Icon path={mdiSkipNext} size={1} />
													</button>
													<button title="ultima-pagina" onClick={ultimaPagina} style={{ border: "none", background: "none" }}>
														<Icon path={mdiSkipForward} size={1} />
													</button>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO*/}
			<Modal show={carregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm-sm" >
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>
			</Modal>
			{/*MODAL DELEÇÃO*/}
			<Modal show={mostrarModalDelecao} onHide={() => setMostrarModalDelecao(false)} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Confirmar Exclusão</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
					<BsExclamationCircleFill size={"100px"} color="#f58619" />
					<div className="row" style={{ textAlign: 'center' }}>
						<div className="col-sm-sm" >
							<h3>Tem certeza que deseja deletar o endereço {enderecoDelecao} da filial {filialDoEndereco}?</h3>
						</div>
					</div>
					<hr />
					<div className="d-flex my-2 gap-1 justify-content-between">
						<Button type="button" onClick={() => confirmarDelecao(enderecoDelecao, filialDoEndereco)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />Sim</Button>
						<Button type="button" onClick={() => setMostrarModalDelecao(false)} style={{ borderRadius: '20px', float: 'left' }}><Icon path={mdiCloseCircle} size={1} className="me-2" />Não</Button>
					</div>
				</Modal.Body>
			</Modal>
			{/*MODAL PESQUISAR */}
			<Modal show={mostrarModalPesquisa} onHide={() => setMostrarModalPesquisa(false)} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Pesquisar Endereço</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={pesquisarEndereco}>
						<div className="row">
							<div className="col-sm-sm-6 form-floating">

								<select
									placeholder=''
									className="form-select bordasInferiorInput text-black text-capitalize"
									id="pesquisa-endereco"
									name="pesquisa-endereco"
									required
									defaultValue="Filial"
									value={pesquisarPor}
									onChange={event => { setPesquisarPor(event.target.value) }}
								>
									<option>Filial</option>
									<option>Endereço</option>
									<option>Tipo</option>
									<option>Temperatura</option>
									<option>Armazém</option>
									<option>Corredor</option>
									<option>Box</option>
									<option>Altura</option>
									<option>Profundidade</option>
								</select>
								<label className="form-label">Pesquisar por:</label>
							</div>
							<div className="col-sm-sm-6 form-floating">

								<input
									placeholder=''
									className="form-control bordasInferiorInput text-black text-capitalize"
									type="text"
									id="descricao"
									name="descricao"
									value={termoPesquisa}
									onChange={event => { setTermoPesquisa(event.target.value) }}
								/>
								<label className="form-label">Descrição</label>
							</div>
						</div>

						<hr />

						<div className="d-flex my-2 gap-1 justify-content-between">
							<Button type="submit" className='rounded' onClick={() => { setMostrarModalPesquisa(false) }} style={{float: 'left' }}><Icon path={mdiKeyboardReturn} size={1} className="me-2" /> Voltar </Button>
							<Button type="submit" className='rounded' variant="success"> <Icon path={mdiMagnify} size={1} className="me-2" />Filtrar</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export { Endereco };
