import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { Modal } from 'react-bootstrap';
import MascaraValor from '../../../../components/functions/mascaraValor';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle } from '@mdi/js';
import CarregarPermissoes from '../../../../hooks/carregaPermissoesUsuario/carregaPermissoes';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import UA from '../ajusteDeEstoque/interfaces/interfaceUA';
import { useNavigate } from 'react-router-dom';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';
function AjusteDeMovimento() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoDetalhar, setPermissaoDetalhar] = useState(false);
	const [permissaoEspecialCriar, setPermissaoEspecialCriar] = useState(false);
	const [permissaoExcluirItem, setPermissaoExcluirItem] = useState(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarAjusteMovimento) {
				toast.warning('Você não tem permissão para acessar Ajuste de Movimento.');
				navigate('/');
			} else {
				setPermissaoDetalhar(permissoes.perfilPermissao.detalharAjusteMovimento);
				setPermissaoExcluirItem(permissoes.perfilPermissao.excluirItemAjusteMovimento);
				setPermissaoEspecialCriar(permissoes.perfilPermissao.permEspAjusteMovimentacao);
			}
		}
	}, [permissoes, erro, navigate]);

	const [ListaDepositantes, setListaDepositantes] = useState([]);
	const [idDepositanteSelecionado, setIdDepositanteSelecioando] = useState('');
	const [tipoAjuste, setTipoAjuste] = useState('');
	const [showCarregando, setShowCarregando] = useState(false);
	const [operacaoFiscal, setOperacaoFiscal] = useState(false);
	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/operacao/${id_filial}/${id_usuario}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaDepositantes(resposta.data.depositantes);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message);
		}
	}

	useEffect(function () {
		CarregarDepositantes(id_filial, id_usuario);
	}, []);
	const [ListaMovimentacoes, setListaMovimentacoes] = useState([]);
	async function CarregarMovimentacoes(id_depositante: string, id_filial: string) {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/movs/estoque/${id_filial}/${id_depositante}`, {
				headers: {
					authorization: token,
				},
			});
			setShowCarregando(false);
			setListaMovimentacoes(resposta.data.movimentos);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}
	const [itensPorPagina, setitensPorPagina] = useState(10);
	const ColunasMov: ITabela[] = [
		{
			titulo: 'Id',
			acesso: 'id_ajuste',
		},
		{
			titulo: 'Depositante',
			acesso: 'fantasia',
		},
		{
			titulo: 'Descrição',
			acesso: 'titulo',
		},
		{
			titulo: 'Data',
			acesso: 'cadastro',
		},
		{
			titulo: 'Usuário',
			acesso: 'usuario',
		},
		{
			titulo: 'Integrado',
			acesso: 'consumido_integracao',
		},
	];
	useEffect(
		function () {
			if (idDepositanteSelecionado !== '') {
				CarregarMovimentacoes(idDepositanteSelecionado, id_filial);
			}
		},
		[idDepositanteSelecionado]
	);
	const [showModalCriarMov, setShowModalCriarMov] = useState(false);
	const [cabecalhoNovaMov, setCabecalhoNovaMov] = useState({
		id_mov: 'Novo',
		id_filial: id_filial,
		id_depositante: idDepositanteSelecionado,
		titulo: '',
		usuario: usuarioLogado,
	});
	/*const [itemMov, setItemMov] = useState({
        id_ua: "",
        id_ajuste: "Novo",
        id_filial: id_filial,
        id_cliente: idDepositanteSelecionado,
        id_processo: null,
        tipo: tipoAjuste,
        id_wms: "",
        codigo: "",
        descricao: "",
        volume_1: 0,
        qtde: 0,
        pb: 0,
        pl: 0,
        vlr_unit: 0,
        producao_menor: "",
        validade_menor: "",
        lote: "",
        usuario: usuarioLogado
    })*/
	const [ListaItensMov, setListaItensMov] = useState<any>([]);
	const colunasItensMov: ITabela[] = [
		{ titulo: 'Tipo Mov.', acesso: 'tipo' },
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Volume', acesso: 'volume_1' },
		{ titulo: 'Qtde', acesso: 'qtde' },
		{ titulo: 'Peso Lq.', acesso: 'pl' },
		{ titulo: 'Produção', acesso: 'producao' },
		{ titulo: 'Validade', acesso: 'validade' },
	];
	const [ListaDeUas, setListaDeUas] = useState([]);
	async function CarregarEstoqueUAS() {
		try {
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carregar/uas/estoque/${id_filial}/${cabecalhoNovaMov.id_depositante}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaDeUas(resposta.data.estoque);
		} catch (error: any) {
			toast.error(error.response.data.message || error.message, {
				autoClose: 3000,
			});
		}
	}
	useEffect(
		function () {
			if (cabecalhoNovaMov.id_depositante !== '') {
				CarregarEstoqueUAS();
				const fiscal: any = ListaDepositantes.find(function (depositante: any) {
					return depositante.id_cliente == cabecalhoNovaMov.id_depositante;
				});
				setOperacaoFiscal(fiscal.operacao_fiscal);
			} else {
				setListaDeUas([]);
				setOperacaoFiscal(false);
			}
		},
		[cabecalhoNovaMov.id_depositante]
	);
	const [UAselecionada, setUASelecionada] = useState<UA>({
		id_ua: 0,
		tipo_ua: '',
		id_processo: 0,
		id_wms: 0,
		peso_embal: '',
		codigo: '',
		descricao: '',
		und_emb: '',
		validade: '',
		producao: '',
		volume_1: '',
		lote: '',
		qtde: '',
		qtde_reserva: '',
		qtde_original: '',
		qtde_disp: '',
		pb: '',
		pl: '',
		id_emb: '',
	});
	function CriarNovoAjusteMov() {
		if (UAselecionada!.id_ua == 0 && UAselecionada!.tipo == 'S') {
			toast.error('U.A não existe, não é possível prosseguir com o a movimentação.', {
				autoClose: 2000,
			});
		} else if (UAselecionada!.tipo === '') {
			toast.info('Selecione o tipo da movimentação.');
		} else {
			if (UAselecionada.validade != '') {
				let validadeFormataBR = UAselecionada.validade?.replaceAll('-', '/').split('/');
				UAselecionada.validade = validadeFormataBR![2] + '/' + validadeFormataBR![1] + '/' + validadeFormataBR![0];
			}
			if (UAselecionada.producao != '') {
				let producaoFormataBR = UAselecionada.producao?.replaceAll('-', '/').split('/');
				UAselecionada.producao = producaoFormataBR![2] + '/' + producaoFormataBR![1] + '/' + producaoFormataBR![0];
			}
			UAselecionada.qtde = UAselecionada?.qtde?.replaceAll('.', '').replaceAll(',', '.');
			UAselecionada.volume_1 = UAselecionada.volume_1 = UAselecionada?.volume_1?.replaceAll('.', '').replaceAll(',', '.');
			UAselecionada.valor_unit = UAselecionada.valor_unit = UAselecionada?.valor_unit?.replaceAll('.', '').replaceAll(',', '.');
			/*const uaSelect = {
                "id_ua": 3,
                "tipo_ua": "estoque",
                "id_processo": 1,
                "id_wms": 1,
                "codigo": "907",
                "descricao": "Teste Produto Completo",
                "und_emb": "KG",
                "validade": "2002-12-31",
                "producao": "2002-02-02",
                "volume_1": 9,
                "tipo": "S",
                "lote": "444",
                "qtde": "0,15",
                "qtde_reserva": 2,
                "qtde_original": -2,
                "qtde_disp": 4,
                "pb": 47.99,
                "pl": 50.15
            }*/
			setListaItensMov([...ListaItensMov, UAselecionada]);
			setUASelecionada({
				id_ua: 0,
				tipo_ua: '',
				id_processo: 0,
				id_wms: 0,
				peso_embal: '',
				tipo: '',
				codigo: '',
				descricao: '',
				und_emb: '',
				validade: '',
				producao: '',
				volume_1: '',
				lote: '',
				qtde: '',
				qtde_reserva: '',
				qtde_original: '',
				qtde_disp: '',
				pb: '',
				pl: '',
			});
		}
	}
	async function EnviarNovoAjusteMov() {
		try {
			setShowCarregando(true);
			const dados = {
				uas: ListaItensMov,
				usuario: usuarioLogado,
				cabecalho: cabecalhoNovaMov,
			};
			if (cabecalhoNovaMov.titulo === '') {
				toast.info('Preencha o titulo da movimentação.');
			} else {
				const resposta = await axios.post(
					`${process.env.REACT_APP_API_BASE_URL}/ajuste/movimentacao/estoque/${id_filial}/${cabecalhoNovaMov.id_depositante}`,
					dados,
					{
						headers: {
							authorization: token,
						},
					}
				);
				setShowCarregando(false);
				setShowModalCriarMov(false);
				setCabecalhoNovaMov({
					id_mov: 'Novo',
					id_filial: id_filial,
					id_depositante: idDepositanteSelecionado,
					titulo: '',
					usuario: usuarioLogado,
				});
				setListaItensMov([]);
				toast.success(resposta.data.message);
				CarregarMovimentacoes(idDepositanteSelecionado, id_filial);
			}
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 3000,
			});
		}
	}
	const [ListaProdutos, setListaProdutos] = useState([]);
	async function CarregarProdutosDepositante() {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/lista/prodarm/filial/${id_filial}/${cabecalhoNovaMov.id_depositante}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaProdutos(resposta.data.produtos);
			setShowCarregando(false);
		} catch (error) {
			setShowCarregando(false);
		}
	}
	useEffect(
		function () {
			if (UAselecionada.id_ua == 'novo') {
				CarregarProdutosDepositante();
			} else {
				setListaProdutos([]);
			}
		},
		[UAselecionada.id_ua == 'novo']
	);
	const [ListaEmbalagens, setListaEmbalagens] = useState([]);
	async function CarregarEmbalagensProduto() {
		try {
			setShowCarregando(true);
			const resposta = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/produtoarm/edit/${id_filial}/${UAselecionada.id_wms}`,
				{
					headers: {
						authorization: token,
					},
				}
			);
			setListaEmbalagens(resposta.data.embalagem);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message, {
				autoClose: 900,
			});
		}
	}
	useEffect(
		function () {
			if (UAselecionada.id_wms != 0) {
				CarregarEmbalagensProduto();
			} else {
				setListaEmbalagens([]);
			}
		},
		[UAselecionada.id_wms]
	);
	const [ListaMovimentacaoDetalhe, setMovimentacaoDetalhe] = useState([]);
	const [showModalListaDetalhes, setShowModalListaDetalhes] = useState(false);
	const colunasDetalheMov: ITabela[] = [
		{ titulo: 'Id.Ajuste', acesso: 'id_ajuste' },
		{ titulo: 'U.A', acesso: 'id_ua' },
		{ titulo: 'Processo', acesso: 'id_processo' },
		{ titulo: 'Id.WMS', acesso: 'id_wms' },
		{ titulo: 'Volume', acesso: 'volume_1' },
		{ titulo: 'Volume Novo', acesso: 'volume_1_novo' },
		{ titulo: 'Qtde', acesso: 'qtde_novo' },
		{ titulo: 'Qtde Novo', acesso: 'qtde' },
		{ titulo: 'PB', acesso: 'pb_novo' },
		{ titulo: 'PB Novo', acesso: 'pb' },
		{ titulo: 'PL', acesso: 'pl_novo' },
		{ titulo: 'PL Novo', acesso: 'pl' },
		{ titulo: 'Valor unit.', acesso: 'vlr_unit' },
		{ titulo: 'Produção', acesso: 'producao_menor_formatado' },
		{ titulo: 'Produção Novo', acesso: 'producao_menor_novo_formatado' },
		{ titulo: 'Validade', acesso: 'validade_menor_formatado' },
		{ titulo: 'Validade Novo', acesso: 'validade_menor__novo_formatado' },
		{ titulo: 'SIF', acesso: 'sif' },
		{ titulo: 'SIF novo', acesso: 'sif_novo' },
		{ titulo: 'Temperatura', acesso: 'sif_temperatura' },
		{ titulo: 'Temperatura Novo', acesso: 'sif_temperatura_novo' },
		{ titulo: 'Cadastro', acesso: 'cadastro_formatado' },
		{ titulo: 'Usuario', acesso: 'usuario' },
	];
	function CarregarDetalhesMov(id_ajuste: any, id_depositante: any, id_filial: any) {
		setShowCarregando(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/detalhes/movs/estoque/${id_filial}/${id_depositante}/${id_ajuste}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMovimentacaoDetalhe(resposta.data.detalhes);
				setShowModalListaDetalhes(true);
				setShowCarregando(false);
			})
			.catch(function (erro) {
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 2000,
				});
				setShowCarregando(false);
			});
	}
	return (
		<div className="main">
			<ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
			<h4 className="tituloDaPagina mt-2 ms-1">Ajuste de Movimentação</h4>
			<div className="card">
				<div className="card-body">
					<div className="container p-0 m-0">
						<div className="row">
							<div className="col-sm col-md-6 col-lg-3">
								<div className="form-floating">
									<select
										value={idDepositanteSelecionado}
										onChange={function (e) {
											setIdDepositanteSelecioando(e.target.value);
										}}
										className="form-select bordasInferiorInput text-black"
										id="floatingSelect"
										aria-label="Floating label select example"
									>
										<option value="">Selecione...</option>
										{ListaDepositantes.map(function (depositante: any) {
											return <option value={depositante.id_cliente}>{depositante.fantasia}</option>;
										})}
									</select>
									<label>Depositante</label>
								</div>
							</div>
							{/*
                            <div className="col-sm col-md col-lg-3">
                                <div className="form-floating">
                                    <select value={tipoAjuste}
                                        onChange={function (e) {
                                            setTipoAjuste(e.target.value)
                                        }}
                                        className="form-select bordasInferiorInput text-black" id="floatingSelecteW" aria-label="Floating label select example">
                                        <option value="">Selecione...</option>
                                        <option value="E">Entrada</option>
                                        <option value="S">Saída</option>
                                    </select>
                                    <label>Tipo de Ajuste</label>
                                </div>
                            </div>
                            */}
							{permissaoEspecialCriar && (
								<div className="col-sm col-md col-lg-2 pt-2">
									<button
										onClick={function () {
											setShowModalCriarMov(true);
										}}
										className="btn btn-primary w-100"
									>
										<i className="bi bi-plus-circle me-1"></i>
										Novo
									</button>
								</div>
							)}
						</div>
					</div>
					<br />
					<ClickableTable
						coluna={ColunasMov}
						itemsPerPage={itensPorPagina}
						data={ListaMovimentacoes}
						usaRowClick={permissaoDetalhar}
						onRowClick={
							permissaoDetalhar
								? function (dados) {
										CarregarDetalhesMov(dados.id_ajuste, dados.id_cliente, dados.id_filial);
								  }
								: () => {}
						}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setitensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={showModalCriarMov}
				size="xl"
				onHide={function () {
					setShowModalCriarMov(false);
					setUASelecionada({
						id_ua: 0,
						tipo_ua: '',
						id_processo: 0,
						id_wms: 0,
						peso_embal: '',
						codigo: '',
						descricao: '',
						und_emb: '',
						validade: '',
						producao: '',
						volume_1: '',
						lote: '',
						qtde: '',
						qtde_reserva: '',
						qtde_original: '',
						qtde_disp: '',
						pb: '',
						pl: '',
					});
					setListaItensMov([]);
				}}
			>
				<Modal.Header closeButton>
					<h4>Criar novo ajuste de Movimentação</h4>
				</Modal.Header>
				<Modal.Body>
					<form
						onSubmit={function (e) {
							e.preventDefault();
							EnviarNovoAjusteMov();
						}}
					>
						<div className="container p-0 m-0">
							<div className="row">
								<div className="col-sm col-md-6 col-lg-3">
									<div className="form-floating">
										<select
											value={cabecalhoNovaMov.id_depositante}
											onChange={function (e) {
												setCabecalhoNovaMov({ ...cabecalhoNovaMov, id_depositante: e.target.value });
											}}
											className="form-select bordasInferiorInput text-black"
											id="floatingSelect"
											aria-label="Floating label select example"
										>
											<option value="">Selecione...</option>
											{ListaDepositantes.map(function (depositante: any) {
												return <option value={depositante.id_cliente}>{depositante.fantasia}</option>;
											})}
										</select>
										<label>Depositante</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-2">
									<div className="form-floating">
										<select
											value={UAselecionada!.tipo}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, tipo: e.target.value });
											}}
											className="form-select bordasInferiorInput text-black"
											id="floatingSelecteW"
											aria-label="Floating label select example"
										>
											<option value="">Selecione...</option>
											<option value="E">Entrada</option>
											<option value="S">Saída</option>
										</select>
										<label>Tipo de Ajuste</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-2">
									<div className="form-floating">
										<input
											type="text"
											value={UAselecionada.id_ua}
											onBlur={function () {
												if (UAselecionada!.id_ua !== '') {
													const ua: any = ListaDeUas.find(function (ua: any) {
														return ua.id_ua == UAselecionada!.id_ua;
													});
													if (ua) {
														setUASelecionada({
															id_ua: ua.id_ua,
															tipo_ua: ua.tipo_ua,
															id_processo: ua.id_processo,
															id_wms: ua.id_wms,
															peso_embal: ua.peso_embal,
															id_emb: ua.id_emb,
															codigo: ua.codigo,
															descricao: ua.descricao,
															und_emb: ua.und_emb,
															validade: ua.validade_menor?.split('T')[0] || '',
															producao: ua.producao_menor?.split('T')[0] || '',
															volume_1: ua.volume_1,
															tipo: UAselecionada!.tipo,
															lote: ua.lote,
															qtde: ua.qtde,
															qtde_reserva: ua.qtde_reserva,
															qtde_original: ua.qtde,
															qtde_disp: ua.qtde_disp,
															pb: ua.pb,
															pl: ua.pl,
															sif: ua.sif,
															sif_temperatura: ua.sif_temperatura,
															valor_unit: ua.vlr_unit,
														});
													} else {
														if (UAselecionada.tipo == 'S') {
															setUASelecionada({
																id_ua: 0,
																tipo_ua: '',
																id_processo: 0,
																id_wms: 0,
																peso_embal: '',
																codigo: '',
																descricao: '',
																und_emb: '',
																validade: '',
																tipo: '',
																producao: '',
																volume_1: '',
																lote: '',
																qtde: '',
																qtde_reserva: '',
																qtde_original: '',
																qtde_disp: '',
																pb: '',
																pl: '',
																sif: '',
																sif_temperatura: '',
																valor_unit: '',
															});
														} else {
															setUASelecionada({
																id_ua: 'novo',
																tipo_ua: '',
																id_processo: 0,
																id_wms: 0,
																peso_embal: '',
																codigo: '',
																descricao: '',
																und_emb: '',
																validade: '',
																tipo: 'E',
																producao: '',
																volume_1: '',
																lote: '',
																qtde: '',
																qtde_reserva: '',
																qtde_original: '',
																qtde_disp: '',
																pb: '',
																pl: '',
																sif: '',
																sif_temperatura: '',
																valor_unit: '',
																amz: '',
																cor: '',
																box: '',
																alt: '',
																prof: '',
															});
															toast.info(
																'U.A não existe para esse depositante, será criado uma nova U.A para essa movimentação.'
															);
														}
													}
												}
											}}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, id_ua: e.target.value });
											}}
											className="form-control bordasInferiorInput text-black"
											list="listaUAS"
										/>
										<label>U.A</label>
										<datalist id="listaUAS">
											{ListaDeUas.map(function (ua: any) {
												return <option value={ua.id_ua} />;
											})}
										</datalist>
									</div>
								</div>
								<div className="col-sm col-md col-lg-3">
									<div className="form-floating">
										<input
											type="text"
											onChange={function (e) {
												setCabecalhoNovaMov({ ...cabecalhoNovaMov, titulo: e.target.value });
											}}
											value={cabecalhoNovaMov.titulo}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Título Movimento</label>
									</div>
								</div>
								<div className="col-sm col-md col-lg-2">
									<div className="form-floating" hidden={ListaItensMov.length > 0 ? false : true}>
										<BotaoCustomizado
											type="submit"
											className="btn btn-light text-black bg-white border-0 mt-2"
											descricaoBotao="Finalizar movimentação"
											icone={mdiCheckCircle}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-sm col-md col-lg-12 d-flex">
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.id_ua}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>U.A</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											onChange={function (e) {
												// Encontrar o option correspondente
												const option = Array.from(document.querySelectorAll('#ListaProdutos option')).find(
													(opt: any) => opt.value === e.target.value
												);
												// Obter o itemID
												const itemID = option ? option.getAttribute('itemID') : null;
												if (itemID != null && itemID != undefined) {
													setUASelecionada({
														...UAselecionada,
														codigo: e.target.value.split('|')[0],
														id_wms: parseFloat(itemID),
														descricao: e.target.value.split('|')[1],
													});
												}
											}}
											list="ListaProdutos"
											readOnly={UAselecionada.id_ua == 'novo' ? false : true}
											value={UAselecionada?.codigo}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Código</label>
										<datalist id="ListaProdutos">
											{ListaProdutos.map(function (produto: any) {
												return <option value={produto.codigo + '|' + produto.descricao} itemID={produto.id_wms}></option>;
											})}
										</datalist>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly
											value={UAselecionada?.id_processo}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Processo</label>
									</div>
									<div className="form-floating me-1 w-50">
										<input
											type="text"
											readOnly
											value={UAselecionada?.descricao}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Descrição</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											readOnly={UAselecionada.id_ua == 'novo' ? false : true}
											list="ListaEmbalagens"
											onChange={function (e) {
												setUASelecionada({
													...UAselecionada,
													id_emb: e.target.value.split(' ')[0],
													und_emb: e.target.value.split(' ')[1],
												});
											}}
											value={UAselecionada?.und_emb}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Unid. Embalagem</label>
										<datalist id="ListaEmbalagens">
											{ListaEmbalagens.map(function (embalagem: any) {
												return <option value={embalagem.id_emb + ' ' + embalagem.unid_manuseio}></option>;
											})}
										</datalist>
									</div>
								</div>
								<h4 className="mt-3">Dados Editáveis</h4>
								<div className="col-sm col-md col-lg-12 d-flex">
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.qtde}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, qtde: MascaraValor(e.target.value) });
											}}
											onBlur={function () {
												if (UAselecionada!.tipo == 'S' && UAselecionada!.qtde! < UAselecionada!.qtde_disp!) {
												}
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Qtde. Controle</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.volume_1}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, volume_1: MascaraValor(e.target.value) });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Qtde. Volume</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="text"
											value={UAselecionada?.pl}
											readOnly={UAselecionada?.tipo_ua == 'Padrão' || operacaoFiscal == false ? false : true}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, pl: MascaraValor(e.target.value) });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Peso Líq.</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="date"
											value={UAselecionada?.producao}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, producao: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Produção</label>
									</div>
									<div className="form-floating me-1">
										<input
											type="date"
											value={UAselecionada?.validade}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, validade: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Validade</label>
									</div>
									<div className="form-floating me-3">
										<input
											type="text"
											value={UAselecionada?.lote}
											onChange={function (e) {
												setUASelecionada({ ...UAselecionada, lote: e.target.value });
											}}
											className="form-control  bordasInferiorInput text-black"
											placeholder=" "
										/>
										<label>Lote</label>
									</div>
									{permissaoEspecialCriar && (
										<div className="form-floating">
											<BotaoCustomizado
												type="button"
												onclick={function () {
													//adicona U.A na lista de ItensMov
													CriarNovoAjusteMov();
												}}
												className="btn btn-light text-black bg-white border-0 mt-2"
												descricaoBotao="Adicionar"
												icone={mdiCheckCircle}
											/>
										</div>
									)}
								</div>
								<div className="col-sm col-md col-lg-12">
									{UAselecionada.id_ua == 'novo' ? (
										<div className="d-flex">
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.sif}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, sif: e.target.value });
													}}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Sif</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.sif_temperatura}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, sif_temperatura: e.target.value });
													}}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Sif Temperatura</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.amz}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, amz: e.target.value });
													}}
													maxLength={5}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Armazem</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.cor}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, cor: e.target.value });
													}}
													maxLength={5}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Corredor</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.box}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, box: e.target.value });
													}}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Box</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.alt}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, alt: e.target.value });
													}}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Altitude</label>
											</div>
											<div className="form-floating me-1">
												<input
													type="text"
													value={UAselecionada?.prof}
													onChange={function (e) {
														setUASelecionada({ ...UAselecionada, prof: e.target.value });
													}}
													className="form-control bordasInferiorInput text-black"
													placeholder=" "
												/>
												<label>Profundidade</label>
											</div>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						<ClickableTable
							coluna={colunasItensMov}
							itemsPerPage={10}
							data={ListaItensMov}
							usaRowClick={false}
							onRowClick={function () {}}
							acaoEditar={function () {}}
							acaoExcluir={function (item) {
								const novaListaItems = ListaItensMov.filter(function (produto: any) {
									return produto.id_ua != item.id_ua;
								});
								setListaItensMov(novaListaItems);
							}}
							acaoVisualizar={function () {}}
							usaAcoes={permissaoExcluirItem}
							usaEditar={false}
							usaExcluir={permissaoExcluirItem}
							usaVisualizar={false}
							labelpesquisa="Procurar por Código"
							acessopesquisa="codigo"
						/>
					</form>
				</Modal.Body>
			</Modal>
			{/* Modal visualizar MOV*/}
			<Modal
				show={showModalListaDetalhes}
				size="xl"
				onHide={function () {
					setShowModalListaDetalhes(false);
				}}
			>
				<Modal.Header closeButton>Visualizar Movimentação</Modal.Header>
				<Modal.Body>
					<ClickableTable
						coluna={colunasDetalheMov}
						itemsPerPage={10}
						data={ListaMovimentacaoDetalhe}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function (dados) {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar={false}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default AjusteDeMovimento;
