import { ITabela } from '../../../../../components/Table/TabelaInterface';
// PARA PESQUISA DE PRODUTO
export const colunasGridPesquisaProduto: ITabela[] = [
	{
		titulo: 'ID Produto',
		acesso: 'id_wms',
	},
	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'Código',
		acesso: 'codigo',
	},
	{
		titulo: 'Unid. Fiscal',
		acesso: 'unid_fiscal',
	},
];
