import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConverterEmBase64 from '../../../../components/functions/converterEmBase64';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';

const NovaFilial = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;

	const navigate = useNavigate();

	//#region Novo desenvolvimento
	const [idFilial, setIdFilial] = useState<number>()
	const [CNPJ, setCNPJ] = useState<string>()
	const [razaoSocial, setRazaoSocial] = useState<string>()
	const [nomeFantasia, setNomeFantasia] = useState<string>()
	const [inscricaoEstadual, setInscricaoEstadual] = useState<string>()
	const [pais, setPais] = useState<string>()
	const [logradouro, setLogradouro] = useState<string>()
	const [contato, setContato] = useState<string>()
	const [numero, setNumero] = useState<number>()
	const [complemento, setComplemento] = useState<string>()
	const [bairro, setBairro] = useState<string>()
	const [cidade, setCidade] = useState<string>()
	const [estado, setEstado] = useState<string>()
	const [telContato, setTelContato] = useState<string>()
	const [cep, setCep] = useState<string>()
	const [servidorSMTP, setServidorSMTP] = useState<string>()
	const [email, setEmail] = useState<string>()
	const [porta, setPorta] = useState<number>()
	const [usuarioAut, setUsuarioAut] = useState<string>()
	const [senhaAut, setSenhaAut] = useState<string>()
	const [msgEmail, setMsgEmail] = useState<string>()
	const [modoOp, setModoOp] = useState<any>()
	const [nfInicial, setNfInicial] = useState<number>()
	const [serieNF, setSerieNF] = useState<number>()
	const [regimeFiscal, setRegimeFiscal] = useState<any>()
	const [senhaCertificado, setSenhaCertificado] = useState<string>()
	const [avatar, setAvatar] = useState<any>()
	const [certificado, setCertificado] = useState<any>()
	const [webServiceSefaz, setWebServiceSefaz] = useState<any>()
	const [cnpjPagador, setCnpjPagador] = useState<string>()
	const [razaoSocialPagador, setRazaoSocialPagador] = useState<string>()
	const [emailPagador, setEmailPagador] = useState<string>()
	const [indContribuinte, setIndContribuinte] = useState<any>()
	const [pesagemVeiculo, setPesagemVeiculo] = useState<boolean>()

	const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

	const usuarioLogado = sessionStorage.getItem("IsUserFKL")
	const idFilialLogado = sessionStorage.getItem("IsIDFilialPadraoFKL")
	const params = useParams()
	const acao = params.acao
	const idFilialRecebida = params.id

	const dadosDeEnvio = {
		idFilial,
		CNPJ,
		inscricaoEstadual,
		razaoSocial,
		nomeFantasia,
		pais,
		logradouro,
		contato,
		numero,
		complemento,
		bairro,
		cidade,
		estado,
		telContato,
		cep,
		servidorSMTP,
		email,
		porta,
		usuarioAut,
		senhaAut,
		msgEmail,
		modoOp,
		nfInicial,
		serieNF,
		regimeFiscal,
		senhaCertificado,
		certificado,
		avatar,
		usuarioLogado,
		cnpjPagador,
		razaoSocialPagador,
		emailPagador,
		indContribuinte,
		webServiceSefaz,
		pesagemVeiculo
	}

	//#region -> Criar
	function insereNovaFilial() {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/insere/filial`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}).then(function (resposta) {
				toast.success(resposta.data.message)
				setTimeout(() => {
					navigate("/config/cadast/filial")
				}, 2000);
			}).catch(function (erro) {
				const status = erro.response.status
				if (status == 403 || status == 401) {
					toast.error("Acesso negado, faça seu login novamente")
					navigate("/login")
				} else {
					toast.error(erro.response.data.message)
				}
			})
	}

	async function carregarDadosCNPJ(CNPJ: any) {
		setMostraModalCarregando(true)
		if (CNPJ.length == 14) {
			axios.get(`https://publica.cnpj.ws/cnpj/${CNPJ}`).then(function (response) {
				setMostraModalCarregando(false)
				setCNPJ(response.data.estabelecimento.cnpj)
				setNomeFantasia(response.data.estabelecimento.nome_fantasia)
				setLogradouro(response.data.estabelecimento.logradouro)
				setNumero(response.data.estabelecimento.numero)
				setComplemento(response.data.estabelecimento.complemento)
				setBairro(response.data.estabelecimento.bairro)
				setCidade(response.data.estabelecimento.cidade.nome)
				setCep(response.data.estabelecimento.cep)
				setEstado(response.data.estabelecimento.estado.sigla)
				setPais(response.data.estabelecimento.pais.nome)
				setRazaoSocial(response.data.razao_social)
				setInscricaoEstadual(response.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual)
				setCnpjPagador(response.data.estabelecimento.cnpj)
				setRazaoSocialPagador(response.data.razao_social)
			}).catch(function (erro) {
				setMostraModalCarregando(false)
				const status = erro.response.status
				if (status == 403 || status == 401) {
					toast.error("Acesso negado, faça seu login novamente")
					navigate("/login")
				} else {
					toast.error("CNPJ não encontrado")
					setCNPJ("")
					setNomeFantasia("")
					setLogradouro("")
					setNumero(0)
					setComplemento("")
					setBairro("")
					setCidade("")
					setCep("")
					setEstado("")
					setPais("")
					setRazaoSocial("")
					setInscricaoEstadual("")
					setCnpjPagador("")
					setRazaoSocialPagador("")
				}
			})
		}
	}

	//#region -> Editar
	function carregaDados() {
		setMostraModalCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/filial/edit/${idFilialRecebida}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregando(false)
			setIdFilial(resposta.data.data[0].id_filial)
			setCNPJ(resposta.data.data[0].cnpj)
			setRazaoSocial(resposta.data.data[0].razao_social)
			setNomeFantasia(resposta.data.data[0].fantasia)
			setInscricaoEstadual(resposta.data.data[0].ie)
			setPais(resposta.data.data[0].pais)
			setLogradouro(resposta.data.data[0].logradouro)
			setNumero(resposta.data.data[0].numero)
			setComplemento(resposta.data.data[0].complemento)
			setBairro(resposta.data.data[0].bairro)
			setCidade(resposta.data.data[0].cidade)
			setEstado(resposta.data.data[0].uf)
			setContato(resposta.data.data[0].contato)
			setTelContato(resposta.data.data[0].telcelular)
			setCep(resposta.data.data[0].cep)
			setCnpjPagador(resposta.data.data[0].cnpj_pagador)
			setRazaoSocialPagador(resposta.data.data[0].razao_social_pagador)
			setEmailPagador(resposta.data.data[0].email_pagador)
			setAvatar(resposta.data.data[0].avatar)
			setServidorSMTP(resposta.data.data[0].servidor_smtp)
			setEmail(resposta.data.data[0].email_smtp)
			setPorta(resposta.data.data[0].porta_smtp)
			setUsuarioAut(resposta.data.data[0].usuario_smtp)
			setSenhaAut(resposta.data.data[0].senha_smtp)
			setServidorSMTP(resposta.data.servidor)
			setEmail(resposta.data.email)
			setPorta(resposta.data.porta)
			setUsuarioAut(resposta.data.usuario)
			setSenhaAut(resposta.data.senha)
			setModoOp(resposta.data.data[0].mod_operacao)
			setNfInicial(resposta.data.data[0].ult_nf)
			setSerieNF(resposta.data.data[0].serie)
			setRegimeFiscal(resposta.data.data[0].crt)
			setIndContribuinte(resposta.data.data[0].indiedest)
			setWebServiceSefaz(resposta.data.data[0].ambiente_operacao)
			setCertificado(resposta.data.data[0].certificado)
			setSenhaCertificado(resposta.data.data[0].senhacertificado)

			const pesagemVeic = resposta.data.data[0].pesagem_veiculo
			if (pesagemVeic === 1) {
				setPesagemVeiculo(true)
			} else {
				setPesagemVeiculo(false)
			}
		}).catch(function (erro) {
			setMostraModalCarregando(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				console.info(erro.response.data.message)
			}
		})
	}

	function editarUsuario() {
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/editar/usuario/${idFilialLogado}`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}).then(function (resposta) {
				toast.success(resposta.data.message)

				setTimeout(() => {
					navigate("/config/cadast/filial")
				}, 2000);
			}).catch(function (erro) {
				const status = erro.response.status
				if (status == 403 || status == 401) {
					toast.error("Acesso negado, faça seu login novamente")
					navigate("/login")
				} else {
					toast.error(erro.response.data.message)
				}
			})
	}

	useEffect(() => {
		if (acao != 'criar') {
			carregaDados()
		}
	}, [])

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">{acao == 'criar' ? 'Nova Filial' : acao == 'editar' ? 'Editar Filial' : 'Visualizar Filial'}</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form onSubmit={function (e) {
							e.preventDefault()
							if (acao == 'criar') {
								insereNovaFilial()
							} else if (acao == 'editar') {
								editarUsuario()
							}
						}}>
							<div className="card">

								<div className="card-body">

									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralFilial" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#endFilial" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Envio de e-mail</span>
											</a>
										</li>
										{/* <li className="nav-item">
											<a href="#divFilial" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-settings-outline d-md-none d-block"></i>
												<span className="d-none d-md-block">Diversos</span>
											</a>
										</li> */}
										<li className="nav-item">
											<a href="#parametroFiscal" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-receipt d-md-none d-block"></i>
												<span className="d-none d-md-block">Parametro Fiscal</span>
											</a>
										</li>
										{/* <li className="nav-item">
											<a href="#smtpconfig" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-settings-outline d-md-none d-block"></i>
												<span className="d-none d-md-block">Configuração</span>
											</a>
										</li> */}
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralFilial">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Codigo da filial" id="codfilial"
															name="codfilial"
															value={idFilial}
															disabled
														/>
														<label className="form-label">Filial</label>
													</div>
												</div>
												{/* <div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="coderp"
															name="coderp"
															value={inputs.coderp || ""}
															placeholder=''
															onChange={handleChange} />
														<label className="form-label">Identificador no ERP</label>
													</div>
												</div> */}
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="cnpj"
															name="cnpj"
															value={CNPJ}
															placeholder=''
															onBlur={(event) => carregarDadosCNPJ(event.target.value)}
															onChange={(event) => {
																setCNPJ((event.target.value).replace(/[.\-\/]/g, ''))
															}}
															required />
														<label className="form-label">CNPJ ou CPF</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="razao_social"
															name="razao_social"
															placeholder=''
															value={razaoSocial}
															onChange={(event) => setRazaoSocial(event.target.value)}
															required
														/>
														<label className="form-label">Razão Social</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															value={nomeFantasia}
															placeholder=''
															onChange={(event) => setNomeFantasia(event.target.value)}
														/>
														<label className="form-label">Nome Fantasia</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="IE"
															name="IE"
															value={inscricaoEstadual}
															placeholder=''
															onChange={(event) => setInscricaoEstadual(event.target.value)}
															required
														/>
														<label className="form-label">IE</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={pais}
															placeholder=''
															onChange={(event) => setPais(event.target.value)}
														/>
														<label className="form-label">País</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className='col-sm-3'>
													<div className="form-floating mb-1">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="logradouro"
															value={logradouro}
															placeholder=''
															onChange={(event) => setLogradouro(event.target.value)}
														/>
														<label className="form-label">Logradouro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input
															type="number"
															className="form-control bordasInferiorInput text-black " id="contato"
															name="contato"
															placeholder=''
															value={numero}
															onChange={(event) => setNumero(parseInt(event.target.value))}
														/>
														<label className="form-label">Numero</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={complemento}
															onChange={(event) => setComplemento(event.target.value)}
														/>
														<label className="form-label">Complemento</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={bairro}
															onChange={(event) => setBairro(event.target.value)}
														/>
														<label className="form-label">Bairro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="cidade"
															placeholder=''
															value={cidade}
															onChange={(event) => setCidade(event.target.value)}
														/>
														<label className="form-label">Cidade</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={estado}
															onChange={(event) => setEstado(event.target.value)}
														/>
														<label className="form-label">Estado</label>
													</div>
												</div>

											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={contato}
															required
															onChange={(event) => setContato(event.target.value)}
														/>
														<label className="form-label">Contato</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={telContato}
															required
															onChange={(event) => setTelContato(event.target.value)}
														/>
														<label className="form-label">Tel. Contato</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={cep}
															onChange={(event) => setCep(event.target.value)}
														/>
														<label className="form-label">CEP</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															// name="cnpjPagador"
															placeholder=''
															value={cnpjPagador}
															onChange={(event) => setCnpjPagador(event.target.value)}
														/>
														<label className="form-label">CNPJ Pagador</label>
													</div>
												</div>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															// name="razaoSocialPagador"
															placeholder=''
															value={razaoSocialPagador}
															onChange={(event) => setRazaoSocialPagador(event.target.value)}
														/>
														<label className="form-label">Razão social pagador</label>
													</div>
												</div>

												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															// name="emailPagador"
															placeholder=''
															value={emailPagador}
															onChange={(event) => setEmailPagador(event.target.value)}
														/>
														<label className="form-label">E-mail pagador</label>
													</div>
												</div>
											</div>

											<div className='row'>

												<div className="col-8 col-md-6 mt-2">
													<div className="mb-1">
														<label className="form-label" htmlFor="cfopEntrada">Imagem de perfil</label>
														<input
															type="file"
															className="form-control-file"
															onChange={async function (event) {

																const files = event.target.files
																if (files && files.length > 0) {

																	const file = files[0]
																	const base64: any = await ConverterEmBase64(file)
																	setAvatar(base64)
																}

															}}
														/>
													</div>
												</div>

												<div className='m-2 col-4 mt-4 form-check form-switch'>
													<input
														className="me-2 form-check-input"
														type="checkbox"
														checked={pesagemVeiculo}
														id="tempProcessoCheck"
														onChange={(e) => setPesagemVeiculo(e.target.checked)}
														style={{
															width: '35px'
														}}
													/>
													<label className="form-check-label mt-1 col" htmlFor="tempProcessoCheck">
														Exige Pesagem Veículo
													</label>
												</div>
											</div>

										</div>
										<div className="tab-pane" id="endFilial">
											<div className='row'>
												<div className='col-sm-5'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={servidorSMTP}
															required
															onChange={(event) => setServidorSMTP(event.target.value)}
														/>
														<label className="form-label">Servidor SMTP</label>
													</div>
												</div>
												<div className='col-sm-5'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={email}
															required
															onChange={(event) => setEmail(event.target.value)}
														/>
														<label className="form-label">E-mail</label>
													</div>
												</div>
												<div className='col-sm-2'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={porta}
															required
															onChange={(event) => setPorta(parseInt(event.target.value))}
														/>
														<label className="form-label">Porta</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															required
															value={usuarioAut}
															onChange={(event) => setUsuarioAut(event.target.value)}
														/>
														<label className="form-label">Usuário (Autenticação)</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type='password' className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={senhaAut}
															required
															onChange={(event) => setSenhaAut(event.target.value)}
														/>
														<label className="form-label">Senha (Autenticação)</label>
													</div>
												</div>
											</div>
											<div className="form-group mt-2">
												<label htmlFor="contato" className="form-label">Mensagem no e-mail para redefinição de senha</label>
												<textarea
													className="form-control text-black"
													id="contato"
													name="contato"
													rows={5}
													value={msgEmail}
													onChange={(event) => setMsgEmail(event.target.value)}
												/>
											</div>
										</div>

										<div className="tab-pane" id="parametroFiscal">
											<div className="row">
												<div className="col-12 col-md-4 mt-2">
													<div className="form-floating">

														<select className="form-select bordasInferiorInput text-black text-capitalize"
															id="cfopEntrada" name="modo_operacao" value={modoOp} onChange={(event) => setModoOp(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="0">NORMAL</option>
															<option value="1">CONTINGÊNCIA</option>
														</select>
														<label className="form-label" htmlFor="cfopEntrada">Modo Operação</label>
													</div>
												</div>
												<div className="col-12 col-sm-6 col-md-4 mt-1">
													<div className="form-floating mt-1">

														<input className='form-control bordasInferiorInput text-black text-capitalize'
															type="number"
															name="nf_inicial"
															placeholder=''
															value={nfInicial}
															onChange={(event => setNfInicial(parseInt(event.target.value)))} />
														<label className="form-label" htmlFor="cfopEntrada">NF Inicial</label>
													</div>
												</div>
												<div className="col-12 col-sm-6 col-md-4 mt-1">
													<div className="form-floating mt-1">
														<input className='form-control bordasInferiorInput text-black text-capitalize'
															placeholder=''
															type="number" name="serie_nf" value={serieNF} onChange={(event) => setSerieNF(parseInt(event.target.value))} />
														<label className="form-label" htmlFor="cfopEntrada">Série NF</label>
													</div>
												</div>
											</div>

											<div className='row mt-2'>
												<div className="col-12 col-md-4 mt-1">
													<div className="form-floating mb-1">

														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															name="regime_fiscal"
															value={regimeFiscal}
															onChange={(event) => setRegimeFiscal(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="1">Simples Nacional</option>
															<option value="2">Simples Nacional, excesso sublimite de receita bruta</option>
															<option value="3">Regime Normal</option>
															<option value="4">MEI</option>
														</select>
														<label className="form-label" htmlFor="cfopEntrada">Regime Fiscal</label>
													</div>
												</div>
												<div className="col-12 col-md-4 mt-1">
													<div className="form-floating mb-1">

														<select className="form-select bordasInferiorInput text-black text-capitalize" value={indContribuinte} name="regime_fiscal" onChange={(event) => setIndContribuinte(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="1">Contribuinte</option>
															<option value="2">Não contribuinte</option>
															<option value="3">Isento</option>
														</select>
														<label className="form-label" htmlFor="cfopEntrada">Ind. Contribuinte</label>
													</div>
												</div>
												<div className="col-12 col-md-4 mt-1">
													<div className="form-floating mb-1">

														<select className="form-select bordasInferiorInput text-black text-capitalize" value={webServiceSefaz} name="webServiceSefaz" onChange={(event) => setWebServiceSefaz(event.target.value)}>
															<option value="">Selecione...</option>
															<option value="2">Homologação</option>
															<option value="1">Produção</option>
														</select>
														<label className="form-label">Web Service Sefaz</label>
													</div>
												</div>
												<div className="w-100"></div>
											</div>

											<div className='row mt-2'>

												<div className="col-12 col-md-6">
													<div className="mb-1">
														<label className="form-label" htmlFor="cfopEntrada">Certificado</label>
														<input
															type="file"
															className="form-control-file"
															onChange={async function (event) {

																const files = event.target.files
																if (files && files.length > 0) {

																	const file = files[0]
																	const base64: any = await ConverterEmBase64(file)
																	setCertificado(base64)
																}

															}}
														/>
													</div>
												</div>

												<div className="col-12 col-md-6">
													<div className="form-floating mb-1 mt-2">

														<input type="password" name='senha_certificado'
															value={senhaCertificado}
															placeholder=''
															className='form-control bordasInferiorInput text-black'
															onChange={(event) => setSenhaCertificado(event.target.value)}
														/>
														<label className="form-label" htmlFor="cfopEntrada">Senha do certificado</label>
													</div>
												</div>

											</div>
										</div>


									</div>
								</div>

								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<ToastContainer newestOnTop />

				<ModalCarregando
					mostrar={mostraModalCarregando}
					mensagem='Carregando...'
				/>

			</div>

		</>
	);
};

export { NovaFilial };
