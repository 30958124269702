import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { Api } from '../../../../services/api';
import { colunasListaNfe } from './components/colunasGrid';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiMagnify } from '@mdi/js';
import { QtdeDiasNF } from './components/diasNF';
import removeEspacoTextoMin from '../../../../components/componentes/types/removeEspacoTextoMin';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

export function AdmOperacionalFiscalEntradaNFePedido() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoDetalhar, setPermissaoDetalhar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarNfePedido) {
				toast.warning('Você não tem permissão para acessar lista de NF-e de pedido.');
				navigate('/');
			} else {
				setPermissaoDetalhar(permissoes.perfilPermissao.detalharNfePedido);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirNfePedido);
			}
		}
	}, [permissoes, erro, navigate]);

	const [showCarregando, setShowCarregando] = useState(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [chaveNfe, setChaveNFe] = useState<string>('');
	const [numNF, setNumNF] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');

	// dados filtro
	const [nf, setNf] = useState('');
	const [diasNF, setDiasNF] = useState('');

	const [ListaDeNfs, setListaDeNfs] = useState([]);

	const dadosFiltro = {
		nf,
		diasNF,
	};
	function CarregarNFsFilial(id_filial: string) {
		setShowCarregando(true);
		setMsgModalCarregar('Carregando NFs, aguarde...');
		axios
			.post(Api + `/admoper/fiscalentrada/nfepedido/listar/${id_filial}`, dadosFiltro, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setMsgModalCarregar('');
				setListaDeNfs(resposta.data.nfepedido);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				setMsgModalCarregar('');
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const detalharNF = (dados: any) => {
		const chave = dados.chave_acesso;
		navigate(`/pco/contrFisc/contrNotFisc/notasFiscaisPedido/${chave}/detalhar`);
	};

	const excluirNF = (dados: any) => {
		const id_pedido = dados.id_pedido;
		if (id_pedido !== 0) {
			toast.error('NF já vinculada a um pedido, não pode ser excluída');
			return;
		}

		const chave = dados.chave_acesso;
		const nf = dados.nf;
		setChaveNFe(chave);
		setNumNF(nf);
		setMsgPrincipalModal(`Deseja excluir a NF ${nf}`);
		setMsgAcao1('Cancelar');
		setMostraModalConfirm(true);
	};

	//#region -> Excluir
	function deleteNFePedido() {
		setShowCarregando(true);
		setMostraModalConfirm(false);
		setMsgModalCarregar('Aguarde... Excluindo NFe Pedido');
		axios
			.delete(Api + `/admoper/fiscalentrada/nfepedido/excluir/${id_filial}/${numNF}/${chaveNfe}/${usuario}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setMsgModalCarregar('');
				toast.success(resposta.data.message);
				setChaveNFe('');
				setNumNF('');
				CarregarNFsFilial(id_filial);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				setMsgModalCarregar('');
				setChaveNFe('');
				setNumNF('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>NFe Pedido</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							CarregarNFsFilial(id_filial);
						}}
					>
						<div className="row">
							<div className="col-lg-4 col-sm">
								<div className="form-floating">
									<input
										type="text"
										value={nf}
										onChange={(e) => setNf(removeEspacoTextoMin(e.target.value))}
										className="form-control bordasInferiorInput text-black"
										id="nf"
										name="nf"
									/>
									<label>Núm. NF</label>
								</div>
							</div>

							<div className="col-lg-3 col-sm">
								<InputSelect
									label="Dias NF"
									name="tipoEtiquetaUAVazio"
									value={diasNF}
									onChange={setDiasNF}
									dados={QtdeDiasNF}
									required={!nf}
									onClick={() => {}}
									disabled={false}
									campoValue="value"
								/>
							</div>
							<div className="col-sm col-md col-lg">
								<BotaoCustomizado
									type="submit"
									className="btn btn-success mt-3 mb-2"
									descricaoBotao="Pesquisar"
									icone={mdiMagnify}
									onclick={() => {}}
									size={1}
								/>
							</div>
						</div>
					</form>

					<ClickableTable
						coluna={colunasListaNfe}
						itemsPerPage={itensPorPagina}
						data={ListaDeNfs}
						usaRowClick={permissaoDetalhar}
						onRowClick={permissaoDetalhar ? detalharNF : () => {}} // Se não tiver permissão, a função não faz nada ao clicar
						acaoEditar={function () {}}
						acaoExcluir={excluirNF}
						acaoVisualizar={function () {}}
						usaAcoes={permissaoExcluir}
						usaEditar={false}
						usaExcluir={permissaoExcluir}
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mostrar={showCarregando} mensagem={msgModalCarregar} />

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteNFePedido}
			/>
		</div>
	);
}
