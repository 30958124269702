import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputPlacaVeiculo from '../../../../components/componentes/formulario/InputPlacaVeiculo';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import DatalistInput from 'react-datalist-input';
const NovoVeiculo = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_veiculo = params.id;
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Pesquisa
	const [abrirModalPesquisa, setAbrirModalPesquisa] = useState(false);
	//#endregion
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [id_motorista, setIdMotorista] = useState<string>('');

	//dados do veiculo
	const [placa1, setPlaca1] = useState<string>('');
	const [placa2, setPlaca2] = useState<string>('');
	const [tipoVeiculo, setTipoVeiculo] = useState<string>('');
	const [situacao, setSituacao] = useState<boolean>(true);
	const [id_transportadora, setIdTransportadora] = useState<string>('');
	const [nomeTransportadora, setNomeTransportadora] = useState<string>('');

	const colunas: ITabela[] = [
		{
			titulo: 'ID Motorista',
			acesso: 'id_motorista',
		},
		{
			titulo: 'Ativo',
			acesso: 'ativo',
		},
		{
			titulo: 'Bloqueio',
			acesso: 'bloqueio',
		},
		{
			titulo: 'Nome',
			acesso: 'nome',
		},
		{
			titulo: 'Telefone',
			acesso: 'telefone',
		},
	];

	const [dadosMotorista, setDadosMotorista] = useState<any[]>([]);

	const excluirMotorista = (dados: any) => {
		setMsgPrincipalModal(`Deseja excluir o motorista ${dados.nome}`);
		setMsgAcao1('Cancelar');
		setIdMotorista(dados.id_motorista);
		setMostraModalConfirm(true);
	};
	function excluir() {
		if (acao === 'editar') {
			deletarMotorista();
		} else {
			const motorista = dadosMotorista.filter((item) => item.id_motorista !== id_motorista);
			setDadosMotorista(motorista);
			setMsgAcao1('');
			setMsgPrincipalModal('');
			setMostraModalConfirm(false);
			setIdMotorista('');
		}
	}
	const selecionarMotorista = (dados: any) => {
		if (acao === 'criar') {
			const motorista = dadosMotorista.filter((item) => item.id_motorista === dados.id_motorista);
			if (motorista.length > 0) {
				toast.error('Motorista já vinculado ao veículo.');
				return;
			} else {
				setDadosMotorista([...dadosMotorista, dados]);
				setAbrirModalPesquisa(false);
			}
		} else {
			const dadosMotorista = {
				id_motorista: dados.id_motorista,
				id_veiculo,
				usuarioLogado,
			};

			insereNovoMotoristaVeiculo(dadosMotorista);
		}
	};

	const dadosDeEnvio = {
		placa1,
		placa2,
		situacao: situacao ? 1 : 0,
		tipoVeiculo,
		usuarioLogado,
		id_transportadora,
		motoristas: dadosMotorista,
	};

	//#region -> Filtrar Transportadora
	const [dadosTransportadora, setDadosTransportadora] = useState([]);
	function buscarTransportadora(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;
		setShowCarregando(true);
		const dados = {
			filtro: _filtro,
		};
		axios
			.post(Api + `/lista/veiculos/transportadora/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosTransportadora(resposta.data);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#region -> Criar
	function insereNovoMotoristaVeiculo(dados: any) {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/veiculo/motorista/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setAbrirModalPesquisa(false);
				carregaDados();
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	function insereNovoVeiculo() {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/veiculo/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setTimeout(() => {
					navigate('/cadast/veiculo/veiculo');
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/veiculo/edit/${id_filial}/${id_veiculo}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setPlaca1(resposta.data.veiculo[0].placa_1);
				setPlaca2(resposta.data.veiculo[0].placa_2);
				setSituacao(resposta.data.veiculo[0].ativo);
				setTipoVeiculo(resposta.data.veiculo[0].tipo_veiculo);
				setDadosMotorista(resposta.data.motorista);
				setIdTransportadora(resposta.data.veiculo[0].transportadora);
				setNomeTransportadora(resposta.data.veiculo[0].nome_transportadora);
				ListarTipoVeiculo();
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	const [dadosTipoVeiculo, setDadosTipoVeiculo] = useState([]);
	function ListarTipoVeiculo() {
		// setShowCarregando(true)
		axios
			.get(Api + `/lista/veiculos/tipoveiculo/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosTipoVeiculo(resposta.data.tipoveiculo);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarVeiculo() {
		axios
			.put(Api + `/editar/veiculo/${id_filial}/${id_veiculo}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate('/cadast/veiculo/veiculo');
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	function deletarMotorista() {
		setShowCarregando(true);
		setMostraModalConfirm(false);
		axios
			.delete(Api + `/excluir/veiculo/motorista/${id_filial}/${id_veiculo}/${id_motorista}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				carregaDados();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar' ? 'Novo Veículo' : acao === 'editar' ? 'Editar Veículo' : 'Visualizar Veículo'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoVeiculo();
								} else if (acao === 'editar') {
									editarVeiculo();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#veicMotoristas" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Motoristas</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="Codigo da filial"
															id="id_veiculo"
															name="id_veiculo"
															value={id_veiculo}
															disabled
														/>
														<label className="form-label">ID Veículo</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<InputPlacaVeiculo
															value={placa1}
															onChange={setPlaca1}
															required
															readOnly={acao === 'criar' ? false : true}
														/>
														<label className="form-label">Placa 1</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<InputPlacaVeiculo
															value={placa2}
															onChange={setPlaca2}
															readOnly={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Placa 2</label>
													</div>
												</div>

												<div className="col-lg-2 col-md-3 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={situacao}
															onClick={function () {
																setSituacao(!situacao);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input w-25"
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Ativo</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<div className="form-floating mb-1">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															value={tipoVeiculo}
															name="tipoveiculo"
															onChange={(event) => setTipoVeiculo(event.target.value)}
															required
															onClick={ListarTipoVeiculo}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="">Selecione</option>
															{dadosTipoVeiculo.map((item: any) => {
																return (
																	<option key={item.id_codsis_det} value={item.id_codsis_det}>
																		{item.descricao}
																	</option>
																);
															})}
														</select>
														<label className="form-label" htmlFor="cfopEntrada">
															Tipo Veículo
														</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="form-floating mb-1">
														{/* <label className="form-label" htmlFor="cfopEntrada">
															Transportadora
														</label> */}
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="Transportadora"
															onSelect={(item) => {
																setIdTransportadora(item.id);
																setNomeTransportadora(item.value);
															}}
															items={dadosTransportadora}
															onChange={(item) => buscarTransportadora(item)}
															className="mt-2"
															value={nomeTransportadora}
															inputProps={{
																disabled: acao === 'criar' ? false : acao === 'editar' ? false : true,
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane" id="veicMotoristas">
											<div className="card-body">
												<div className="row">
													<div className="col d-flex justify-content-start">
														<BotaoCustomizado
															type="button"
															className="btn btn-secondary mb-1"
															descricaoBotao="Pesquisar Motorista"
															icone={mdiPlusCircle}
															onclick={() => {
																setAbrirModalPesquisa(true);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
													</div>
												</div>
												<div className="">
													<ClickableTable
														coluna={colunas}
														itemsPerPage={10}
														data={dadosMotorista}
														onRowClick={() => {}}
														usaAcoes={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoExcluir={excluirMotorista}
														usaVisualizar={false}
														usaEditar={false}
														usaExcluir={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoEditar={() => {}}
														acaoVisualizar={() => {}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			{/*MODAL PESQUISA*/}
			<ModalPesquisa
				visible={abrirModalPesquisa}
				onClose={() => setAbrirModalPesquisa(false)}
				fetchUrl={Api + `/lista/veiculos/motorista/${id_filial}/${id_veiculo === 'novo' ? 0 : id_veiculo}`}
				onSelect={selecionarMotorista}
				colunas={colunas}
				filtro="nome"
				textoFiltro="Digite o nome do motorista"
			/>

			{/* MODAL CONFIRMACAO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => excluir()}
			/>
		</>
	);
};

export { NovoVeiculo };
