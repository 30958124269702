import { Link } from 'react-router-dom';

const PalletSemEnderecamento = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Pallet sem Endereçamento</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap">
										<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
											<tr>
												<th>Processo</th>
												<th>Cliente</th>
												<th>Dt Processo</th>
												<th>Dt Chegada</th>
												<th>Tipo Operação</th>
												<th>Critério</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>61</td>
												<td>FKL Services</td>
												<td>26/11/21 16:00:00</td>
												<td>26/11/21 16:00:00</td>
												<td>ENTRADA</td>
												<td>1273187</td>
												<td className="d-flex flex-nowrap table-action">
													<Link
														to={`/gerenciamento/enderecamento/palletSemEnderecamento/${0}`}
														className="action-icon"
													>
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { PalletSemEnderecamento };
