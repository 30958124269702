/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiKeyboardReturn } from '@mdi/js';
import { Api } from '../../../../services/api';
import { colunasProdutos } from './components/colunasGridProdutos';
import { colunasProdutosDetalhe } from './components/colunasGridProdutosDetalhe';

function AdmOperacionalFiscalEntradaNFePedidoItem() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;

	const params = useParams();
	const chave = params.id || '';

	//dados cabeçalho da NF-e
	const [nf, setNf] = useState<any>({});
	const [serie, setSerie] = useState('');
	const [emissao, setEmissao] = useState('');
	const [chaveNF, setChaveNF] = useState('');	
	const [natop, setNatop] = useState('');	

	// dados cabeçalho - emitente
	const [cnpjEmitente, setCnpjEmitente] = useState('');
	const [ieEmitente, setIeEmitente] = useState('');
	const [razaoSocialEmitente, setRazaoSocialEmitente] = useState('');
	const [logradouroEmitente, setLogradouroEmitente] = useState('');
	const [complEmitente, setComplEmitente] = useState('');
	const [numeroEmitente, setNumeroEmitente] = useState('');
	const [bairroEmitente, setBairroEmitente] = useState('');
	const [cidadeEmitente, setCidadeEmitente] = useState('');
	const [cepEmitente, setCepEmitente] = useState('');
	const [ufEmitente, setUfEmitente] = useState('');
	const [paisEmitente, setPaisEmitente] = useState('');

	// dados cabeçalho - destinatario
	const [cnjpDestinatario, setCnpjDestinatario] = useState('');
	const [ieDestinatario, setIeDestinatario] = useState('');
	const [razaoSocialDestinatario, setRazaoSocialDestinatario] = useState('');
	const [logradouroDestinatario, setLogradouroDestinatario] = useState('');
	const [complDestinatario, setComplDestinatario] = useState('');
	const [numeroDestinatario, setNumeroDestinatario] = useState('');
	const [bairroDestinatario, setBairroDestinatario] = useState('');
	const [cidadeDestinatario, setCidadeDestinatario] = useState('');
	const [cepDestinatario, setCepDestinatario] = useState('');
	const [ufDestinatario, setUfDestinatario] = useState('');
	const [paisDestinatario, setPaisDestinatario] = useState('');

	// dados cabeçalho - totais
	const [volume, setVolume] = useState('');
	const [pb, setPb] = useState('');
	const [pl, setPl] = useState('');
	const [vbc, setVbc] = useState('');
	const [vicms, setVicms] = useState('');
	const [totalProduto, setTotalProduto] = useState('');
	const [totalNf, setTotalNf] = useState('');

	const [ProdutoSelecionado, setProdutoSelecionado] = useState<any>([]);

	const navigate = useNavigate();

	const [ListaProdutos, setListaProdutos] = useState([]);

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const [showModalCarregando, setShowModalCarregando] = useState(false);

	// Carregar NF-e
	function CarregarNF(id_filial: string, chave: string) {
		setShowModalCarregando(true);
		axios
			.get(Api + `/admoper/fiscalentrada/nfepedido/detalhar/${id_filial}/${chave}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowModalCarregando(false);
				setListaProdutos(resposta.data.produtos);

				const nf = resposta.data.nf;
				// dados cabeçalho da NF-e
				setNf(nf.nf);
				setSerie(nf.serie);
				setEmissao(nf.dt_emissao.split('T')[0]);
				setChaveNF(nf.chave_acesso);				
				setNatop(nf.natureza_op);			

				// dados cabeçalho - emitente
				setCnpjEmitente(nf.cnpj_emit);
				setIeEmitente(nf.ie_emit);
				setRazaoSocialEmitente(nf.razao_emit);
				setLogradouroEmitente(nf.logr_emit);
				setComplEmitente(nf.comp_emit);
				setNumeroEmitente(nf.num_emit);
				setBairroEmitente(nf.bairro_emit);
				setCidadeEmitente(nf.mun_emit);
				setCepEmitente(nf.cep_emit);
				setUfEmitente(nf.uf_emit);
				setPaisEmitente(nf.pais_emit);

				// dados cabeçalho - destinatario
				setCnpjDestinatario(nf.cnpj_dest);
				setIeDestinatario(nf.ie_dest);
				setRazaoSocialDestinatario(nf.razao_dest);
				setLogradouroDestinatario(nf.logr_dest);
				setComplDestinatario(nf.comp_dest);
				setNumeroDestinatario(nf.num_dest);
				setBairroDestinatario(nf.bairro_dest);
				setCidadeDestinatario(nf.mun_dest);
				setCepDestinatario(nf.cep_dest);
				setUfDestinatario(nf.uf_dest);
				setPaisDestinatario(nf.pais_dest);

				// dados cabeçalho - totais
				setVolume(nf.vol);
				setPb(nf.pb);
				setPl(nf.pl);
				setVbc(nf.vbc);
				setVicms(nf.vicms);
				setTotalProduto(nf.vprod);
				setTotalNf(nf.vnf);
			})
			.catch(function (erro) {
				setShowModalCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	const [showModalProduto, setShowModalProduto] = useState(false);

	useEffect(function () {
		CarregarNF(id_filial, chave);
	}, []);

	return (
		<div className="main">
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
						<li className="nav-item" role="presentation">
							<button
								className="nav-link active"
								id="pills-home-tab"
								data-bs-toggle="pill"
								data-bs-target="#nfe"
								type="button"
								role="tab"
								aria-controls="pills-home"
								aria-selected="true"
							>
								NF-e
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-profile-tab"
								data-bs-toggle="pill"
								data-bs-target="#emitente"
								type="button"
								role="tab"
								aria-controls="pills-profile"
								aria-selected="false"
							>
								Emitente
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-contact-tab"
								data-bs-toggle="pill"
								data-bs-target="#destinatario"
								type="button"
								role="tab"
								aria-controls="pills-contact"
								aria-selected="false"
							>
								Destinatário
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-contact-tab"
								data-bs-toggle="pill"
								data-bs-target="#totais"
								type="button"
								role="tab"
								aria-controls="pills-contact"
								aria-selected="false"
							>
								Totais
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-contact-tab"
								data-bs-toggle="pill"
								data-bs-target="#produtos"
								type="button"
								role="tab"
								aria-controls="pills-contact"
								aria-selected="false"
							>
								Produtos
							</button>
						</li>
					</ul>
					<div className="tab-content" id="pills-tabContent">
						<div className="tab-pane fade show active" id="nfe" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="row p-0 m-0">
								<h4>NF-e</h4>
								<div className="row">
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												value={nf}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>N° NFe</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												value={serie}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Serie</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-2">
										<div className="form-floating">
											<input
												value={emissao}
												type="date"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Emissão</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-6">
										<div className="form-floating">
											<input
												value={chaveNF}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Chave</label>
										</div>
									</div>									
								</div>
								<div className="row">
									<div className="col-sm col-md col-lg">
										<div className="form-floating">
											<input
												value={natop}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Natureza Op.</label>
										</div>
									</div>									
								</div>								
							</div>
						</div>
						<div className="tab-pane fade" id="emitente" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="row p-0 m-0">
								<h4>Emitente</h4>
								<div className="row">
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cnpjEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>CNPJ</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={ieEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Inscrição Estadual</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-6">
										<div className="form-floating">
											<input
												value={razaoSocialEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Razão Social</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={logradouroEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Logradouro</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={complEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Complemento</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={numeroEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Número</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={bairroEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Bairro</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cidadeEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Cidade</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cepEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>CEP</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={ufEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>UF</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={paisEmitente}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>País</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="destinatario" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="row p-0 m-0">
								<h4>Destinatário</h4>
								<div className="row">
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cnjpDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>CNPJ</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={ieDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Inscrição Estadual</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-6">
										<div className="form-floating">
											<input
												value={razaoSocialDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Razão Social</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={logradouroDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Logradouro</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={complDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Complemento</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={numeroDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Número</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={bairroDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Bairro</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cidadeDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Cidade</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={cepDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>CEP</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={ufDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>UF</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={paisDestinatario}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>País</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="totais" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="row m-0 p-0">
								<div className="row">
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={volume}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Volume</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={pb}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Peso Br.</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={pl}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Peso Líq.</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-3">
										<div className="form-floating">
											<input
												value={vbc}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Base Icms</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm col-md col-lg-4">
										<div className="form-floating">
											<input
												value={vicms}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Valor Icms</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-4">
										<div className="form-floating">
											<input
												value={totalProduto}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Total Produto</label>
										</div>
									</div>
									<div className="col-sm col-md col-lg-4">
										<div className="form-floating">
											<input
												value={totalNf}
												type="text"
												className="form-control bordasInferiorInput text-black text-center"
												placeholder=" "
											/>
											<label>Total NFe</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="produtos" role="tabpanel" aria-labelledby="pills-home-tab">
							<ClickableTable
								coluna={colunasProdutos}
								itemsPerPage={itensPorPagina}
								data={ListaProdutos}
								usaRowClick={true}
								onRowClick={function (produto: any) {
									setShowModalProduto(true);
									setProdutoSelecionado([produto]);
								}}
								acaoEditar={function () {}}
								acaoExcluir={function () {}}
								acaoVisualizar={function () {}}
								usaAcoes={false}
								usaEditar={false}
								usaExcluir={false}
								usaVisualizar={false}
								acessopesquisa="codigo"
								labelpesquisa="Procurar por Código"
							/>
							<div className="container">
								<div className="row">
									<div className="col-lg-2 m-auto text-center">
										<label>Itens por Página</label>
									</div>
									<div className="w-100"></div>
									<div className="col-lg-1 m-auto">
										<select
											value={itensPorPagina}
											onChange={function (e: any) {
												setItensPorPagina(e.target.value);
											}}
											className="form-select form-select-sm"
											aria-label=".form-select-sm example"
										>
											<option value={10}>10</option>
											<option value={20}>20</option>
											<option value={30}>30</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="text-end mt-3">
						<BotaoCustomizado
							type="button"
							className="btn btn-light text-danger bg-white border-0"
							descricaoBotao="Voltar"
							icone={mdiKeyboardReturn}
							onclick={() => {
								navigate(-1);
							}}
						/>
					</div>
				</div>
			</div>
			<ModalCarregando mensagem="Carregando..." mostrar={showModalCarregando} />

			<Modal
				show={showModalProduto}
				centered
				size="xl"
				onHide={function () {
					setShowModalProduto(false);
					setProdutoSelecionado([]);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Detalhe do Protudo</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ClickableTable
						coluna={colunasProdutosDetalhe}
						itemsPerPage={1}
						data={ProdutoSelecionado}
						usaRowClick={false}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar={false}
					/>
				</Modal.Body>
				<Modal.Footer>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md col-lg text-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Fechar"
									icone={mdiKeyboardReturn}
									onclick={() => {
										setShowModalProduto(false);
										setProdutoSelecionado([]);
									}}
								/>
							</div>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default AdmOperacionalFiscalEntradaNFePedidoItem;
