const RomaneioSaida = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Romaneio Saída</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="row d-flex align-items-end">
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												placeholder=""
											/>
											<label htmlFor="depositante">Depositante</label>
										</div>
									</div>

									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<input
												type="date"
												className="form-control bordasInferiorInput text-black text-capitalize"
												placeholder=""
											/>
											<label htmlFor="data_carga">Data da Carga</label>
										</div>
									</div>

									<div className="col-sm-1">
										<button className="btn btn-light">
											<span className="iconify" data-icon="mdi:magnify"></span>
										</button>
									</div>
									<div className="offset-sm-1 col-sm-2">
										<label htmlFor="btn-romSaidExportEXcel" className="btn btn-success">
											<span className="iconify me-2" data-icon="mdi:file-excel"></span>
											Exportar
										</label>
										<input type="file" style={{ display: 'none' }} className="form-control-file" id="btn-romSaidExportEXcel" />
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
									<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<tr>
											<th>
												<input type="checkbox" />
											</th>
											<th>Data Carga</th>
											<th>Pedido</th>
											<th>Pallet</th>
											<th>Barra</th>
											<th>Barra Sistema</th>
											<th>Cod Produto</th>
											<th>Descrição</th>
											<th>Peso Bruto</th>
											<th>Peso Liquído</th>
											<th>Dt. Produção</th>
											<th>Dt. Validade</th>
											<th>RG Caixa</th>
											<th>OS</th>
											<th>Cliente</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>29/11/2021 16:00:00</td>
											<td>Pedidos HRR/1234</td>
											<td>123</td>
											<td></td>
											<td></td>
											<td>001119-3</td>
											<td>QUEIJO MUSSARELA FATIADO POLENGHI</td>
											<td>2,74</td>
											<td>2,70</td>
											<td>10/03/2022</td>
											<td>10/03/2022</td>
											<td></td>
											<td>45</td>
											<td>32916263000212</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { RomaneioSaida };
