import { ITabela } from "../../../../../components/Table/TabelaInterface";

//Criação das colunas da tabela
export const colunasGridTelaProdutosDeFornecedor: ITabela[] = [
	{
		titulo: 'ID Fornecedor',
		acesso: 'id_prod_fornecedor',
	},

	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'ID WMS',
		acesso: 'id_wms',
	},
	{
		titulo: 'Código Depositante',
		acesso: 'codigo_cliente',
	},
	{
		titulo: 'CNPJ Fornecedor',
		acesso: 'cnpj_fornecedor',
	},
	{
		titulo: 'Cód. Fornecedor',
		acesso: 'codigo_fornecedor',
	},
	{
		titulo: 'Fator',
		acesso: 'fator',
	},
	{
		titulo: 'Usuário',
		acesso: 'usuario',
	},
];
