import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import InputDecimal from '../../../../components/componentes/formulario/InputDecimal';
const NovoEquipamento = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_equipamento = params.id;
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Pesquisa
	

	// Dados do Equipamento
	const [descricao, setDescricao] = useState<string>('');
	const [peso, setPeso] = useState<number>(0);
	const [valor, setValor] = useState<number>(0);

	//#region ==> Função para buscar os dados do Equipamento
	async function carregarDados() {
		setShowCarregando(true);
		await axios
			.get(`${Api}/carrega/dados/equipamento/edit/${id_filial}/${id_equipamento}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setDescricao(response.data.equipamento[0].descricao);
				setPeso(response.data.equipamento[0].peso);
				setValor(response.data.equipamento[0].valor);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//Carregar dados da tabela ao abrir a tela
	useEffect(() => {
		if (acao !== 'criar') {
			carregarDados();
		}
	}, []);

	const dadosEnvio = {
		descricao,
		peso,
		valor,
		usuarioLogado
	};

	//#region  ==> Função para inserir novo equipamento
	async function insereNovoEquipamento() {
		setShowCarregando(true);
		await axios
			.post(`${Api}/insere/equipamento/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para editar equipamento
	async function editarEquipamento() {
		setShowCarregando(true);
		await axios
			.put(`${Api}/editar/equipamento/${id_filial}/${id_equipamento}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar' ? 'Novo Equipamento' : acao === 'editar' ? 'Editar Equipamento' : 'Visualizar Equipamento'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoEquipamento();
								} else if (acao === 'editar') {
									editarEquipamento();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_equipamento"
															name="id_equipamento"
															value={id_equipamento}
															disabled
														/>
														<label className="form-label">ID Equipamento</label>
													</div>
												</div>
												<div className="col-sm-5">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="descricao"
															name="descricao"
															value={descricao}
															onChange={(e) => setDescricao(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Descrição</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<InputDecimal
															id="peso"
															name="peso"
															value={peso}
															onChange={setPeso}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															label="Peso"
														/>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<InputDecimal
															id="valor"
															name="valor"
															value={valor}
															onChange={setValor}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															label="Valor"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			{/* MODAL CONFIRMACAO */}
			{/* <ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => excluir()}
			/> */}
		</>
	);
};

export { NovoEquipamento };
