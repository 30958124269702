import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import DatalistInput from 'react-datalist-input';
import InputDecimal from '../../../../components/componentes/formulario/InputDecimal';
import { apenasNumeros } from '../../../../components/componentes/types/apenasNumeros';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
const NovoProdutosdeArmazenagem = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_wms = params.id || '';
	const acao = params.acao;
	const [acaoEmb, setAcaoEmb] = useState<string>('criar');

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Embalagem
	const [abrirModalEmbalagem, setAbrirModalEmbalagem] = useState(false);
	const [abrirModalEditarEmbalagem, setAbrirModalEditarEmbalagem] = useState(false);
	const [showPesquisaNCM, setShowPesquisaNCM] = useState(false);
	//#endregion
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [id_embalagem, setIdEmbalagem] = useState<string>('');

	//dados do produto
	const [id_depositante, setIdDepositante] = useState<string>('');
	const [cnpjDepositante, setCnpjDepositante] = useState<string>('');
	const [depositante, setDepositante] = useState<string>('');
	const [geraFatura, setGeraFatura] = useState<boolean>(true);
	const [codigo, setCodigo] = useState<string>('');
	const [nome, setNome] = useState<string>('');
	const [temperatura, setTemperatura] = useState<string>('');
	const [tipo_peso, setTipoPeso] = useState<string>('');
	const [prazoValidade, setPrazoValidade] = useState<string>('0');
	const [id_familia, setIdFamilia] = useState<string>('');
	const [id_unidfiscal, setIdUnidFiscal] = useState<string>('');
	const [desc_unidfiscal, setDescUnidFiscal] = useState<string>('');

	//dados de embalagem
	const [id_clienteEmb, setIdClienteEmb] = useState<string>('');
	const [id_wmsEmb, setIdWmsEmb] = useState<string>('');
	const [id_emb, setIdEmb] = useState<number>(0);
	const [emb_controleEmb, setEmbControleEmb] = useState<boolean>(false);
	const [gtinEmb, setGtinEmb] = useState<string>('');
	const [codigoEmb, setCodigoEmb] = useState<string>('');
	const [apresentacaoEmb, setApresentacaoEmb] = useState<string>('');
	const [unid_fiscalEmb, setUnidFiscalEmb] = useState<string>('');
	const [unid_manuseioEmb, setUnidManuseioEmb] = useState<string>('');
	const [nivel_emb, setNivelEmb] = useState<string>('');
	const [fatorEmb, setFatorEmb] = useState<number>(0);
	const [pbEmb, setPbEmb] = useState<number>(0);
	const [plEmb, setPlEmb] = useState<number>(0);
	const [peso_embal, setPesoEmbal] = useState<number>(0);
	const [comprimentoEmb, setComprimentoEmb] = useState<number>(0);
	const [larguraEmb, setLarguraEmb] = useState<number>(0);
	const [alturaEmb, setAlturaEmb] = useState<number>(0);
	const [lastroEmb, setLastroEmb] = useState<number>(0);
	const [camadaEmb, setCamadaEmb] = useState<number>(0);
	const [m3Emb, setM3Emb] = useState<number>(0);
	const [descUnidEmbalagem, setDescUnidEmbalagem] = useState<string>('');

	const selecionaUnidadeFiscal = (dados: any) => {
		const idunidfiscal = dados.target.value;

		// const desc_unidfiscal = dados.target.selectedOptions[0].label;
		setIdUnidFiscal(idunidfiscal);
		if (idunidfiscal !== '') {
			setDescUnidFiscal(idunidfiscal.toUpperCase());
		}
	};
	const [ncm, setNcm] = useState<string>('');
	const [controla_validade, setControlaValidade] = useState<boolean>(false);
	const [controla_lote, setControlaLote] = useState<boolean>(false);
	const [controla_peso_padrao_rg, setControlaPesoPadraoRG] = useState<boolean>(false);
	const [limite_prazo_vencer_dias, setLimitePrazoVencerDias] = useState<string>('0');
	const [limite_prazo_vencer_percentual, setLimitePrazoVencerPercentual] = useState<number>(0);
	const [dias_vencer_bloqueio, setDiasVencerBloqueio] = useState<string>('0');
	const [dias_vencer_data_critica, setDiasVencerDataCritica] = useState<string>('0');
	const [quebra_fefo, setQuebraFefo] = useState<string>('0');
	const [id_gruposif, setIdGrupoSif] = useState<string>('0');

	//#region ==> Buscar dados do depositante ao informar pelo menos 3 caracteres e alimentar o datalist
	const [dadosDepositante, setDadosDepositante] = useState([]);
	async function buscarDepositante(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;
		setShowCarregando(true);
		const dados = {
			filtro: _filtro,
		};
		axios
			.post(Api + `/lista/prodarm/depositante/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosDepositante(resposta.data.depositante);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de temperatura do produto para alimentar o select/option
	const [dadosTemperatura, setDadosTemperatura] = useState([]);
	async function ListarTemperatura() {
		axios
			.get(Api + `/lista/prodarm/temperatura/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTemperatura(resposta.data.temperatura);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de familia do produto para alimentar o select/option
	const [dadosFamiliaProduto, setDadosFamiliaProduto] = useState([]);
	async function ListarFamiliaProduto() {
		axios
			.get(Api + `/lista/prodarm/familia/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosFamiliaProduto(resposta.data.familia_produto);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de unidade fiscal do produto para alimentar o select/option
	const [dadosUnidadeFiscal, setDadosUnidadeFiscal] = useState([]);
	async function ListarUnidadeFiscal() {
		axios
			.get(Api + `/lista/prodarm/unidade_fiscal/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosUnidadeFiscal(resposta.data.unidade_fiscal);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de grupo sif do produto para alimentar o select/option
	const [dadosGrupoSif, setDadosGrupoSif] = useState([]);
	async function ListarGrupoSif() {
		axios
			.get(Api + `/lista/prodarm/grupo_sif/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosGrupoSif(resposta.data.grupo_sif);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de tipo peso para alimentar o select/option
	const [dadosTipoPeso, setDadosTipoPeso] = useState([]);
	async function ListarTipoPeso() {
		axios
			.get(Api + `/lista/prodarm/tipo_peso/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTipoPeso(resposta.data.tipo_peso);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de unidade de manuseio da embalagem para alimentar o select/option
	const [dadosUnidManuseio, setDadosUnidManuseio] = useState([]);
	async function ListarUnidEmbalagem() {
		axios
			.get(Api + `/lista/prodarm/unid_embal/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosUnidManuseio(resposta.data.unid_embal);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const colunas: ITabela[] = [
		{
			titulo: 'ID Embalagem',
			acesso: 'id_emb',
		},
		{
			titulo: 'Embalagem Controle',
			acesso: 'emb_controle',
		},
		{
			titulo: 'Unidade Fiscal',
			acesso: 'unid_fiscal',
		},
		{
			titulo: 'Unidade Embalagem',
			acesso: 'unid_manuseio',
		},
		{
			titulo: 'Nível',
			acesso: 'nivel_emb',
		},
		{
			titulo: 'Fator',
			acesso: 'fator',
		},
		{
			titulo: 'Data Cadastro',
			acesso: 'cadastro',
		},
		{
			titulo: 'Usuário',
			acesso: 'usuario',
		},
	];

	const [dadosEmbalagem, setDadosEmbalagem] = useState<any[]>([]);

	const excluirEmbalagem = (dados: any) => {
		setMsgPrincipalModal(`Deseja excluir a embalagem ${dados.unid_fiscal}`);
		setMsgAcao1('Cancelar');
		setIdEmbalagem(dados.id_emb);
		setMostraModalConfirm(true);
	};

	const editarEmbalagem = (dados: any) => {
		setIdClienteEmb(dados.id_cliente);
		setIdWmsEmb(dados.id_wms);
		setIdEmb(dados.id_emb);
		setEmbControleEmb(dados.emb_controle);
		setGtinEmb(dados.gtin);
		setCodigoEmb(dados.codigo);
		setApresentacaoEmb(dados.apresentacao);
		setUnidFiscalEmb(dados.unid_fiscal);
		setUnidManuseioEmb(dados.unid_manuseio);
		setNivelEmb(dados.nivel_emb);
		setFatorEmb(dados.fator);
		setPbEmb(dados.pb);
		setPlEmb(dados.pl);
		setComprimentoEmb(dados.comprimento);
		setLarguraEmb(dados.largura);
		setAlturaEmb(dados.altura);
		setLastroEmb(dados.lastro);
		setCamadaEmb(dados.camada);
		setDescUnidEmbalagem(dados.unid_fiscal);
		ListarUnidEmbalagem();
		setAbrirModalEditarEmbalagem(true);
		setAcaoEmb('editar');
	};

	const visualizarEmbalagem = (dados: any) => {
		setIdClienteEmb(dados.id_cliente);
		setIdWmsEmb(dados.id_wms);
		setIdEmb(dados.id_emb);
		setEmbControleEmb(dados.emb_controle);
		setGtinEmb(dados.gtin);
		setCodigoEmb(dados.codigo);
		setApresentacaoEmb(dados.apresentacao);
		setUnidFiscalEmb(dados.unid_fiscal);
		setUnidManuseioEmb(dados.unid_manuseio);
		setNivelEmb(dados.nivel_emb);
		setFatorEmb(dados.fator);
		setPbEmb(dados.pb);
		setPlEmb(dados.pl);
		setComprimentoEmb(dados.comprimento);
		setLarguraEmb(dados.largura);
		setAlturaEmb(dados.altura);
		setLastroEmb(dados.lastro);
		setCamadaEmb(dados.camada);
		setDescUnidEmbalagem(dados.unid_fiscal);
		ListarUnidEmbalagem();
		setAbrirModalEditarEmbalagem(true);
		setAcaoEmb('visualizar');
	};
	function excluir() {
		if (acao === 'editar') {
			deletarEmbalagem();
		} else {
			const embalagem = dadosEmbalagem.filter((item) => item.id_emb !== id_embalagem);
			setDadosEmbalagem(embalagem);
			setMsgAcao1('');
			setMsgPrincipalModal('');
			setMostraModalConfirm(false);
			setIdEmbalagem('');
		}
	}

	useEffect(() => {
		const peso_embal = Math.round((pbEmb - plEmb) * 1000) / 1000;
		const m3 = Math.round((comprimentoEmb / 100) * (larguraEmb / 100) * (alturaEmb / 100) * 100000) / 100000;
		setPesoEmbal(peso_embal);
		setM3Emb(m3);
	}, [pbEmb, plEmb, comprimentoEmb, larguraEmb, alturaEmb]);

	const selecionaEmbalagem = (dados: any) => {
		if (acao === 'criar') {
			const embalagem = dadosEmbalagem.filter((item) => item.id_emb === dados.id_emb);
			if (embalagem.length > 0) {
				toast.error('ID. Embalagem já vinculada ao produto.');
				return;
			} else {
				const dadosEmbalagemFormatados = [...dadosEmbalagem, dados];
				setDadosEmbalagem(dadosEmbalagemFormatados);

				fecharModalEmbalagem();
			}
		} else {
			insereNovaEmbalagemProduto(dados);
		}
	};
	const selecionaEdicaoEmbalagem = (dados: any) => {
		if (acao === 'criar') {
			// ao editar uma embalagem do produto ira verificar se o Produto e id de embalagem ja existe em dadosEmbalagem se sim ira atualizar as informações da embalagem
			const embalagem = dadosEmbalagem.filter((item) => item.id_emb === dados.id_emb);
			if (embalagem.length > 0) {
				// com base no id da embalagem ira atualizar os dados da embalagem
				const dadosEmbalagemFormatados = dadosEmbalagem.map((item) => {
					if (item.id_emb === dados.id_emb) {
						return dados;
					}
					return item;
				});
				setDadosEmbalagem(dadosEmbalagemFormatados);
				fecharModalEditarEmbalagem();
			} else {
				// se não existir ira adicionar uma nova embalagem ao produto
				const dadosEmbalagemFormatados = [...dadosEmbalagem, dados];
				setDadosEmbalagem(dadosEmbalagemFormatados);
				setAbrirModalEditarEmbalagem(false);
			}
		} else {
			editarEmbalagemProduto(dados);
		}
	};

	const dadosDeEnvio = {
		id_depositante,
		cnpjDepositante,
		geraFatura: geraFatura ? 1 : 0,
		codigo,
		nome,
		temperatura,
		tipo_peso,
		prazoValidade: prazoValidade === '' ? 0 : parseInt(prazoValidade),
		id_familia,
		id_unidfiscal,
		ncm,
		controla_validade: controla_validade ? 1 : 0,
		controla_lote: controla_lote ? 1 : 0,
		controla_peso_padrao_rg: controla_peso_padrao_rg ? 1 : 0,
		limite_prazo_vencer_dias: limite_prazo_vencer_dias === '' ? 0 : parseInt(limite_prazo_vencer_dias),
		limite_prazo_vencer_percentual,
		dias_vencer_bloqueio: dias_vencer_bloqueio === '' ? 0 : parseInt(dias_vencer_bloqueio),
		dias_vencer_data_critica: dias_vencer_data_critica === '' ? 0 : parseInt(dias_vencer_data_critica),
		quebra_fefo: quebra_fefo === '' ? 0 : parseInt(quebra_fefo),
		id_gruposif,
		usuarioLogado,
		Embalagem: dadosEmbalagem,
	};
	//#region -> Função para inserir uma nova embalagem ao produto
	function insereNovaEmbalagemProduto(dados: any) {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/prodarm/embalagem/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				fecharModalEmbalagem();
				carregaDados();
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Função para inserir um novo produto
	function insereNovoproduto() {
		if (dadosEmbalagem.length === 0) {
			toast.error('É necessário adicionar uma embalagem ao produto.');
			return;
		}
		setShowCarregando(true);
		axios
			.post(Api + `/insere/produto/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setTimeout(() => {
					navigate('/cadast/produto/prodarm');
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/produtoarm/edit/${id_filial}/${id_wms}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);

				ListarFamiliaProduto();
				ListarUnidadeFiscal();
				ListarTipoPeso();
				ListarGrupoSif();
				setIdDepositante(resposta.data.produto[0].id_cliente);
				setDepositante(resposta.data.produto[0].depositante);
				setCnpjDepositante(resposta.data.produto[0].cnpj);
				setGeraFatura(resposta.data.produto[0].gera_fatura);
				setCodigo(resposta.data.produto[0].codigo);
				setNome(resposta.data.produto[0].descricao);
				setTemperatura(resposta.data.produto[0].temperatura);
				setTipoPeso(resposta.data.produto[0].tipo_peso);
				setPrazoValidade(resposta.data.produto[0].prazo_val_dias);
				setIdFamilia(resposta.data.produto[0].familia);
				setIdUnidFiscal(resposta.data.produto[0].unid_fiscal);
				setNcm(resposta.data.produto[0].ncm);
				setControlaValidade(resposta.data.produto[0].controla_validade);
				setControlaLote(resposta.data.produto[0].controla_lote);
				setControlaPesoPadraoRG(resposta.data.produto[0].rg_peso_padrao);
				setLimitePrazoVencerDias(resposta.data.produto[0].min_val_dias);
				setLimitePrazoVencerPercentual(resposta.data.produto[0].min_val_perc);
				setDiasVencerBloqueio(resposta.data.produto[0].bloq_val_dias);
				setDiasVencerDataCritica(resposta.data.produto[0].dias_data_critica);
				setQuebraFefo(resposta.data.produto[0].toleranca_quebra_fefo);
				setIdGrupoSif(resposta.data.produto[0].grupo_sif);
				setDescUnidFiscal(resposta.data.produto[0].unid_fiscal);
				setDadosEmbalagem(resposta.data.embalagem);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarproduto() {
		if (dadosEmbalagem.length === 0) {
			toast.error('É necessário adicionar uma embalagem ao produto.');
			return;
		}
		axios
			.put(Api + `/editar/produto/${id_filial}/${id_wms}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate('/cadast/produto/prodarm');
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	function editarEmbalagemProduto(dados: any) {
		axios
			.put(Api + `/editar/prodarm/embalagem/${id_filial}/${id_wms}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				fecharModalEditarEmbalagem();

				carregaDados();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	function deletarEmbalagem() {
		setShowCarregando(true);
		setMostraModalConfirm(false);
		axios
			.delete(Api + `/excluir/prodarm/embalagem/${id_filial}/${id_wms}/${id_embalagem}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				carregaDados();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	const gravarEmbalagem = (e: any) => {
		e.preventDefault();
		// data atual no formato DD/MM/YYYY HH:MM:SS
		const dataAtual = new Date();
		const dia = dataAtual.getDate().toString().padStart(2, '0');
		const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
		const ano = dataAtual.getFullYear();
		const hora = dataAtual.getHours().toString().padStart(2, '0');
		const minuto = dataAtual.getMinutes().toString().padStart(2, '0');
		const segundo = dataAtual.getSeconds().toString().padStart(2, '0');

		const data_cadastro = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
		const dadosEmbalagemFormatados = {
			id_cliente: id_clienteEmb,
			id_wms: id_wmsEmb,
			id_emb: id_emb,
			emb_controle: emb_controleEmb ? 1 : 0,
			gtin: gtinEmb,
			codigo: codigoEmb,
			apresentacao: apresentacaoEmb,
			unid_fiscal: unid_fiscalEmb,
			unid_manuseio: unid_manuseioEmb.toLocaleUpperCase(),
			nivel_emb: nivel_emb,
			fator: fatorEmb,
			pb: pbEmb,
			pl: plEmb,
			peso_embal: peso_embal,
			comprimento: comprimentoEmb,
			largura: larguraEmb,
			altura: alturaEmb,
			lastro: lastroEmb,
			camada: camadaEmb,
			m3: m3Emb,
			desc_unid_fiscal: desc_unidfiscal,
			desc_unid_embalagem: descUnidEmbalagem,
			cadastro: data_cadastro,
			usuario: usuarioLogado,
		};

		selecionaEmbalagem(dadosEmbalagemFormatados);
	};
	const gravarEdicaoEmbalagem = (e: any) => {
		e.preventDefault();
		// data atual no formato DD/MM/YYYY HH:MM:SS
		const dataAtual = new Date();
		const dia = dataAtual.getDate().toString().padStart(2, '0');
		const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
		const ano = dataAtual.getFullYear();
		const hora = dataAtual.getHours().toString().padStart(2, '0');
		const minuto = dataAtual.getMinutes().toString().padStart(2, '0');
		const segundo = dataAtual.getSeconds().toString().padStart(2, '0');

		const data_cadastro = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
		const dadosEmbalagemFormatados = {
			id_cliente: id_clienteEmb,
			id_wms: id_wmsEmb,
			id_emb: id_emb,
			emb_controle: emb_controleEmb ? 1 : 0,
			gtin: gtinEmb,
			codigo: codigoEmb,
			apresentacao: apresentacaoEmb,
			unid_fiscal: unid_fiscalEmb,
			unid_manuseio: unid_manuseioEmb.toLocaleUpperCase(),
			nivel_emb: nivel_emb,
			fator: fatorEmb,
			pb: pbEmb,
			pl: plEmb,
			peso_embal: peso_embal,
			comprimento: comprimentoEmb,
			largura: larguraEmb,
			altura: alturaEmb,
			lastro: lastroEmb,
			camada: camadaEmb,
			m3: m3Emb,
			desc_unid_fiscal: desc_unidfiscal,
			desc_unid_embalagem: descUnidEmbalagem,
			cadastro: data_cadastro,
			usuario: usuarioLogado,
		};
		selecionaEdicaoEmbalagem(dadosEmbalagemFormatados);
	};

	const fecharModalEmbalagem = () => {
		setAbrirModalEmbalagem(false);

		setIdClienteEmb('');
		setIdWmsEmb('');
		setIdEmb(0);
		setEmbControleEmb(false);
		setGtinEmb('');
		setCodigoEmb('');
		setApresentacaoEmb('');
		setUnidFiscalEmb('');
		setUnidManuseioEmb('');
		setNivelEmb('');
		setFatorEmb(0);
		setPbEmb(0);
		setPlEmb(0);
		setPesoEmbal(0);
		setComprimentoEmb(0);
		setLarguraEmb(0);
		setAlturaEmb(0);
		setLastroEmb(0);
		setCamadaEmb(0);
		setM3Emb(0);
		setDescUnidEmbalagem('');
	};
	const fecharModalEditarEmbalagem = () => {
		setAbrirModalEditarEmbalagem(false);

		setIdClienteEmb('');
		setIdWmsEmb('');
		setIdEmb(0);
		setEmbControleEmb(false);
		setGtinEmb('');
		setCodigoEmb('');
		setApresentacaoEmb('');
		setUnidFiscalEmb('');
		setUnidManuseioEmb('');
		setNivelEmb('');
		setFatorEmb(0);
		setPbEmb(0);
		setPlEmb(0);
		setPesoEmbal(0);
		setComprimentoEmb(0);
		setLarguraEmb(0);
		setAlturaEmb(0);
		setLastroEmb(0);
		setCamadaEmb(0);
		setM3Emb(0);
		setDescUnidEmbalagem('');
	};

	const iniciarModalEmbalagem = () => {
		setAbrirModalEmbalagem(true);
		const peso_embal = Math.round((pbEmb - plEmb) * 1000) / 1000;
		const m3 = Math.round((comprimentoEmb / 100) * (larguraEmb / 100) * (alturaEmb / 100) * 100000) / 100000;
		setPesoEmbal(peso_embal);
		setM3Emb(m3);
		setIdClienteEmb(id_depositante);
		const _desc_unidfiscal = desc_unidfiscal.toUpperCase();
		setUnidFiscalEmb(_desc_unidfiscal);
		setIdWmsEmb(id_wms);
	};

	const selecionarEmbalagem = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setUnidManuseioEmb(e.target.value);

		// ao selecionar uma embalagem fazer um filtro em dadosUnidManuseio para pegar o valor da unidade de manuseio
		const _dadosUnidManuseio: any = dadosUnidManuseio.find((item: any) => item.value === e.target.value);
		setNivelEmb(_dadosUnidManuseio.defusr01 || '');

		const _descUnidEmbalagem = _dadosUnidManuseio.value.toUpperCase();

		if (_descUnidEmbalagem === unid_fiscalEmb) {
			setFatorEmb(1);
			setDescUnidEmbalagem(_descUnidEmbalagem);
			setEmbControleEmb(true);
		} else {
			setDescUnidEmbalagem(_descUnidEmbalagem);
		}
	};

	const adicionarEmbalagem = () => {
		if (id_depositante === '') {
			toast.warning('Para adicionar embalagem é necessário informar o depositante.');
			return;
		}
		if (id_unidfiscal === '') {
			toast.warning('Para adicionar embalagem é necessário informar a unidade fiscal.');
			return;
		}

		if (dadosEmbalagem.length >= 10) {
			toast.warning('Limite de 10 embalagens atingido para o produto.');
			return;
		}
		iniciarModalEmbalagem();
	};

	// PARA PESQUISA DE NCM
	const colunasNCM: ITabela[] = [
		{
			titulo: 'NCM',
			acesso: 'id',
		},
		{
			titulo: 'Descrição',
			acesso: 'value',
		},
	];

	const selecionaNCM = (dados: any) => {
		setNcm(dados.id);
		setShowPesquisaNCM(false);
	};
	const fecharPesquisaNCM = () => {
		setShowPesquisaNCM(false);
	};
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Produto para Armazenagem'
									: acao === 'editar'
									? 'Editar Produto para Armazenagem'
									: 'Visualizar Produto para Armazenagem'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoproduto();
								} else if (acao === 'editar') {
									editarproduto();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#prodEmbalagem" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Embalagem</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="ID Produto"
															id="id_wms"
															name="id_wms"
															value={id_wms}
															disabled
														/>
														<label className="form-label">ID WMS</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_depositante"
															name="id_depositante"
															value={id_depositante}
															disabled
														/>
														<label className="form-label">ID Depositante</label>
													</div>
												</div>
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="cnpjDepositante"
															name="cnpjDepositante"
															value={cnpjDepositante}
															disabled
														/>
														<label className="form-label">CNPJ Depositante</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="Depositante"
															onSelect={(item) => {
																setIdDepositante(item.id);
																setDepositante(item.value);
																setCnpjDepositante(item.cnpj);
															}}
															items={dadosDepositante}
															onChange={(item) => buscarDepositante(item)}
															className="mt-2"
															value={depositante}
															inputProps={{
																disabled: acao === 'criar' ? false : acao === 'editar' ? false : true,
																required: true,
															}}
														/>
													</div>
												</div>

												<div className="col-lg-2 col-md-3 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={geraFatura}
															onClick={function () {
																setGeraFatura(!geraFatura);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input"
															style={{ width: '75px' }}
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Gera Fatura</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<InputSemZeroEsquerda
														value={codigo}
														onChange={setCodigo}
														required
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														id="codigo"
														name="codigo"
														label="Código"
														maxLength={30}
													/>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="nome"
															name="nome"
															value={nome}
															onChange={(e) => setNome(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															maxLength={150}
														/>
														<label className="form-label">Nome</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="temperatura"
															name="temperatura"
															value={temperatura}
															onClick={ListarTemperatura}
															onChange={(e) => setTemperatura(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="">Selecione</option>
															{dadosTemperatura.map((item: any) => {
																return (
																	<option key={item.value} value={item.value}>
																		{item.value}
																	</option>
																);
															})}
														</select>
														<label className="form-label">Temperatura</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="tipo_peso"
															name="tipo_peso"
															value={tipo_peso}
															onClick={ListarTipoPeso}
															onChange={(e) => setTipoPeso(e.target.value)}
															required
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="">Selecione</option>
															{dadosTipoPeso.map((item: any) => {
																return (
																	<option key={item.value} value={item.value}>
																		{item.value}
																	</option>
																);
															})}
														</select>
														<label className="form-label">Tipo Peso</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="string"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="prazoValidade"
															name="prazoValidade"
															value={prazoValidade}
															onChange={(e) => setPrazoValidade(apenasNumeros(e.target.value))}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Prazo Validade</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="id_familia"
															name="id_familia"
															value={id_familia}
															onClick={ListarFamiliaProduto}
															onChange={(e) => setIdFamilia(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="">Selecione</option>
															{dadosFamiliaProduto.map((item: any) => {
																return (
																	<option key={item.value} value={item.value}>
																		{item.value}
																	</option>
																);
															})}
														</select>
														<label className="form-label">Família</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<select
															className={
																id_unidfiscal === '0' || id_unidfiscal === ''
																	? `form-select bordasInferiorInput text-black text-capitalize`
																	: `form-select bordasInferiorInput text-black text-uppercase`
															}
															id="id_unidfiscal"
															name="id_unidfiscal"
															value={id_unidfiscal}
															onClick={ListarUnidadeFiscal}
															onChange={selecionaUnidadeFiscal}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="" className="text-black text-capitalize">
																Selecione
															</option>
															{dadosUnidadeFiscal.map((item: any) => {
																return (
																	<option key={item.value} value={item.value} className="text-black text-uppercase">
																		{item.value}
																	</option>
																);
															})}
														</select>
														<label className="form-label">Unid. Fiscal</label>
													</div>
												</div>
												<div className="col-sm-3 position-relative">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="ncm"
															name="ncm"
															value={ncm}
															onChange={(e) => setNcm(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">NCM</label>
														<button
															type="button"
															className="btn btn-outline-secondary btn-sm position-absolute top-50 end-0 translate-middle-y"
															onClick={() => setShowPesquisaNCM(true)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															title="Clique aqui para selecionar o NCM."
														>
															<i className="bi bi-search"></i>
														</button>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-4 col-md-4 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={controla_validade}
															onClick={function () {
																setControlaValidade(!controla_validade);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input"
															style={{ width: '75px' }}
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Controla Validade</label>
													</div>
												</div>
												<div className="col-lg-4 col-md-4 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={controla_lote}
															onClick={function () {
																setControlaLote(!controla_lote);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input"
															style={{ width: '75px' }}
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Controla Lote</label>
													</div>
												</div>
												<div className="col-lg-4 col-md-4 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={controla_peso_padrao_rg}
															onClick={function () {
																setControlaPesoPadraoRG(!controla_peso_padrao_rg);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input"
															style={{ width: '75px' }}
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Controla Peso Padrão por RG</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm">
													<fieldset className="border p-2">
														<legend className="text-sm float-none w-auto p-2">
															Limite de Prazo a vencer para Recebimento:
														</legend>
														<div className="row">
															<div className="col-sm">
																<div className="form-floating mb-1">
																	<input
																		type="text"
																		className="form-control bordasInferiorInput text-black text-capitalize"
																		id="limite_prazo_vencer_dias"
																		name="limite_prazo_vencer_dias"
																		value={limite_prazo_vencer_dias}
																		onChange={(e) => setLimitePrazoVencerDias(apenasNumeros(e.target.value))}
																		disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																	/>
																	<label className="form-label">Dias</label>
																</div>
															</div>
															<div className="col-sm">
																<InputDecimal
																	label="Percentual"
																	value={limite_prazo_vencer_percentual}
																	onChange={setLimitePrazoVencerPercentual}
																	id="limite_prazo_vencer_percentual"
																	name="limite_prazo_vencer_percentual"
																/>
															</div>
														</div>
													</fieldset>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="dias_vencer_bloqueio"
															name="dias_vencer_bloqueio"
															value={dias_vencer_bloqueio}
															onChange={(e) => setDiasVencerBloqueio(apenasNumeros(e.target.value))}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Dias a Vencer, Bloqueio</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="dias_vencer_data_critica"
															name="dias_vencer_data_critica"
															value={dias_vencer_data_critica}
															onChange={(e) => setDiasVencerDataCritica(apenasNumeros(e.target.value))}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="ms-1 form-check-label">Dias a Vencer, Data Crítica</label>
													</div>
												</div>

												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="quebra_fefo"
															name="quebra_fefo"
															value={quebra_fefo}
															onChange={(e) => setQuebraFefo(apenasNumeros(e.target.value))}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="ms-1 form-check-label">Quebra FEFO Picking, Tolerância em dia</label>
													</div>
												</div>

												<div className="col-sm">
													<div className="form-floating mb-1">
														<select
															className="form-select bordasInferiorInput text-black text-capitalize"
															id="id_gruposif"
															name="id_gruposif"
															value={id_gruposif}
															onClick={ListarGrupoSif}
															onChange={(e) => setIdGrupoSif(e.target.value)}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														>
															<option value="">Selecione</option>
															{dadosGrupoSif.map((item: any) => {
																return (
																	<option key={item.value} value={item.value}>
																		{item.value}
																	</option>
																);
															})}
														</select>
														<label className="form-label">Grupo S.I.F.</label>
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane" id="prodEmbalagem">
											<div className="card-body">
												<div className="row">
													<div className="col d-flex justify-content-start">
														<BotaoCustomizado
															type="button"
															className="btn btn-secondary mb-1"
															descricaoBotao="Adicionar Embalagem"
															icone={mdiPlusCircle}
															onclick={adicionarEmbalagem}
															disabled={
																acao === 'criar'
																	? false
																	: acao === 'editar'
																	? false
																	: id_depositante !== ''
																	? false
																	: true
															}
														/>
													</div>
												</div>
												<div className="">
													<ClickableTable
														coluna={colunas}
														itemsPerPage={10}
														data={dadosEmbalagem}
														onRowClick={() => {}}
														usaAcoes={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoExcluir={excluirEmbalagem}
														usaVisualizar
														usaEditar
														usaExcluir={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoEditar={editarEmbalagem}
														acaoVisualizar={visualizarEmbalagem}
														acessopesquisa="unid_manuseio"
														labelpesquisa="Procurar por embalagem do produto"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			{/* MODAL CONFIRMACAO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => excluir()}
			/>

			{/* MODAL CADASTRO DE EMBALAGEM - EDITAR*/}

			<Modal
				show={abrirModalEditarEmbalagem}
				backdrop="static"
				keyboard={false}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title>Cadastro de Embalagem - {acaoEmb === 'editar' ? `Editar` : `Visualizar`}</Modal.Title>
				</Modal.Header>
				<form onSubmit={gravarEdicaoEmbalagem}>
					<Modal.Body>
						<div className="row">
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="id_clienteEmb"
										name="id_clienteEmb"
										value={id_clienteEmb}
										onChange={(e) => setIdClienteEmb(e.target.value)}
										readOnly
									/>
									<label className="form-label">Id.Cliente</label>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="id_wmsEmb"
										name="id_wmsEmb"
										value={id_wmsEmb}
										onChange={(e) => setIdWmsEmb(e.target.value)}
										readOnly
									/>
									<label className="form-label">Id Produto</label>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<select
										className="form-select bordasInferiorInput text-black text-capitalize"
										id="id_emb"
										name="id_emb"
										value={id_emb}
										onChange={(e) => setIdEmb(Number(e.target.value))}
										disabled
									>
										{Array.from(Array(10).keys()).map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
									<label className="form-label">Id Embalagem</label>
								</div>
							</div>
							<div className="col-lg-3 col-md-4 pt-3 col-sm">
								<div className="form-check form-switch">
									<input
										checked={emb_controleEmb}
										onClick={function () {
											setEmbControleEmb(!emb_controleEmb);
										}}
										className="form-check-input w-25"
										type="checkbox"
										disabled={descUnidEmbalagem === unid_manuseioEmb ? true : acaoEmb === 'visualizar' ? true : false}
									/>
									<label className="ms-1 form-check-label">Controle Estoque</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="gtinEmb"
										name="gtinEmb"
										value={gtinEmb}
										maxLength={20}
										onChange={(e) => setGtinEmb(e.target.value)}
										required
										disabled={acaoEmb === 'visualizar' ? true : false}
									/>
									<label className="form-label">Gtin</label>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<InputSemZeroEsquerda
										value={codigoEmb}
										onChange={setCodigoEmb}
										id="codigoEmb"
										name="codigoEmb"
										label="Código Embalagem"
										maxLength={30}
										disabled
										required
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="apresentacaoEmb"
										name="apresentacaoEmb"
										value={apresentacaoEmb}
										maxLength={30}
										onChange={(e) => setApresentacaoEmb(e.target.value)}
										disabled={acaoEmb === 'visualizar' ? true : false}
									/>
									<label className="form-label">Apresentação Embal.</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-uppercase"
										id="unid_fiscalEmb"
										name="unid_fiscalEmb"
										value={unid_fiscalEmb}
										readOnly
									/>
									<label className="form-label">Unid. Fiscal</label>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<select
										className={
											unid_manuseioEmb
												? `form-select bordasInferiorInput text-black text-uppercase`
												: `form-select bordasInferiorInput text-black text-capitalize`
										}
										id="unid_manuseioEmb"
										name="unid_manuseioEmb"
										value={unid_manuseioEmb}
										onChange={selecionarEmbalagem}
										onClick={ListarUnidEmbalagem}
										disabled={acaoEmb === 'visualizar' ? true : false}
										required
									>
										<option value="" className="text-black text-capitalize">
											Selecione
										</option>
										{dadosUnidManuseio.map((item: any) => (
											<option key={item.value} value={item.value} className="text-black text-uppercase">
												{item.value}
											</option>
										))}
									</select>
									<label className="form-label">Unid. Embalagem</label>
								</div>
							</div>
							<div className="col-sm-2">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="nivel_emb"
										name="nivel_emb"
										value={nivel_emb}
										readOnly
										disabled={acaoEmb === 'visualizar' ? true : false}
									/>
									<label className="form-label">Nível</label>
								</div>
							</div>
							<div className="col-sm-2">
								<InputDecimal
									label="Fator"
									value={fatorEmb}
									onChange={setFatorEmb}
									id="fatorEmb"
									name="fatorEmb"
									disabled={descUnidEmbalagem === unid_manuseioEmb ? true : acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<InputDecimal
									label="Peso Bruto"
									value={pbEmb}
									onChange={setPbEmb}
									id="pbEmb"
									name="pbEmb"
									disabled={acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
							<div className="col-sm-4">
								<InputDecimal
									label="Peso Líquido"
									value={plEmb}
									onChange={setPlEmb}
									id="plEmb"
									name="plEmb"
									disabled={acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
							<div className="col-sm-4">
								<InputDecimal
									label="Peso Embalagem"
									value={peso_embal}
									onChange={setPesoEmbal}
									id="peso_embal"
									name="peso_embal"
									disabled
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<InputDecimal
									label="Lastro"
									value={lastroEmb}
									onChange={setLastroEmb}
									id="lastroEmb"
									name="lastroEmb"
									disabled={acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
							<div className="col-sm-4">
								<InputDecimal
									label="Camada"
									value={camadaEmb}
									onChange={setCamadaEmb}
									id="camadaEmb"
									name="camadaEmb"
									disabled={acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
							<div className="col-sm-4">
								<InputDecimal
									label="M³"
									value={m3Emb}
									onChange={setM3Emb}
									id="m3Emb"
									name="m3Emb"
									disabled={acaoEmb === 'visualizar' ? true : false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm">
								<fieldset className="border p-2">
									<legend className="text-sm float-none w-auto p-2">Medidas em Centímetros</legend>
									<div className="row">
										<div className="col-sm-4">
											<InputDecimal
												label="Comprimento"
												value={comprimentoEmb}
												onChange={setComprimentoEmb}
												id="comprimentoEmb"
												name="comprimentoEmb"
												disabled={acaoEmb === 'visualizar' ? true : false}
											/>
										</div>
										<div className="col-sm-4">
											<InputDecimal
												label="Largura"
												value={larguraEmb}
												onChange={setLarguraEmb}
												id="larguraEmb"
												name="larguraEmb"
												disabled={acaoEmb === 'visualizar' ? true : false}
											/>
										</div>
										<div className="col-sm-4">
											<InputDecimal
												label="Altura"
												value={alturaEmb}
												onChange={setAlturaEmb}
												id="alturaEmb"
												name="alturaEmb"
												disabled={acaoEmb === 'visualizar' ? true : false}
											/>
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={fecharModalEditarEmbalagem}
								/>
								<BotaoCustomizado
									type="submit"
									className="btn btn-light text-info-emphasis bg-white border-0"
									descricaoBotao="Salvar"
									icone={mdiCheckCircle}
								/>
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>
			{/* MODAL CADASTRO DE EMBALAGEM - INCLUIR*/}

			<Modal show={abrirModalEmbalagem} backdrop="static" keyboard={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header>
					<Modal.Title>Cadastro de Embalagem - Incluir</Modal.Title>
				</Modal.Header>
				<form onSubmit={gravarEmbalagem}>
					<Modal.Body>
						<div className="row">
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="id_clienteEmb"
										name="id_clienteEmb"
										value={id_clienteEmb}
										onChange={(e) => setIdClienteEmb(e.target.value)}
										readOnly
									/>
									<label className="form-label">Id.Cliente</label>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="id_wmsEmb"
										name="id_wmsEmb"
										value={id_wmsEmb}
										onChange={(e) => setIdWmsEmb(e.target.value)}
										readOnly
									/>
									<label className="form-label">Id Produto</label>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="form-floating mb-1">
									<select
										className="form-select bordasInferiorInput text-black text-capitalize"
										id="id_emb"
										name="id_emb"
										value={id_emb}
										onChange={(e) => setIdEmb(Number(e.target.value))}
									>
										{Array.from(Array(10).keys()).map((i) => (
											<option key={i} value={i}>
												{i}
											</option>
										))}
									</select>
									<label className="form-label">Id Embalagem</label>
								</div>
							</div>
							<div className="col-lg-3 col-md-4 pt-3 col-sm">
								<div className="form-check form-switch">
									<input
										checked={emb_controleEmb}
										onClick={function () {
											setEmbControleEmb(!emb_controleEmb);
										}}
										className="form-check-input w-25"
										type="checkbox"
										disabled={descUnidEmbalagem === unid_fiscalEmb ? true : false}
									/>
									<label className="ms-1 form-check-label">Controle Estoque</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="gtinEmb"
										name="gtinEmb"
										value={gtinEmb}
										maxLength={20}
										onChange={(e) => setGtinEmb(e.target.value)}
										required
									/>
									<label className="form-label">Gtin</label>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<InputSemZeroEsquerda
										value={codigoEmb}
										onChange={setCodigoEmb}
										id="codigoEmb"
										name="codigoEmb"
										label="Código Embalagem"
										maxLength={30}
										required
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="apresentacaoEmb"
										name="apresentacaoEmb"
										value={apresentacaoEmb}
										maxLength={30}
										onChange={(e) => setApresentacaoEmb(e.target.value)}
									/>
									<label className="form-label">Apresentação Embal.</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-uppercase"
										id="unid_fiscalEmb"
										name="unid_fiscalEmb"
										value={unid_fiscalEmb}
										readOnly
									/>
									<label className="form-label">Unid. Fiscal</label>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="form-floating mb-1">
									<select
										className={
											unid_manuseioEmb
												? `form-select bordasInferiorInput text-black text-uppercase`
												: `form-select bordasInferiorInput text-black text-capitalize`
										}
										id="unid_manuseioEmb"
										name="unid_manuseioEmb"
										value={unid_manuseioEmb}
										onChange={selecionarEmbalagem}
										onClick={ListarUnidEmbalagem}
										required
									>
										<option value="" className="text-black text-capitalize">
											Selecione
										</option>
										{dadosUnidManuseio.map((item: any) => (
											<option key={item.value} value={item.value} className="text-black text-uppercase">
												{item.value}
											</option>
										))}
									</select>
									<label className="form-label">Unid. Embalagem</label>
								</div>
							</div>
							<div className="col-sm-2">
								<div className="form-floating mb-1">
									<input
										type="text"
										className="form-control bordasInferiorInput text-black text-capitalize"
										id="nivel_emb"
										name="nivel_emb"
										value={nivel_emb}
										readOnly
									/>
									<label className="form-label">Nível</label>
								</div>
							</div>
							<div className="col-sm-2">
								<InputDecimal
									label="Fator"
									value={fatorEmb}
									onChange={setFatorEmb}
									id="fatorEmb"
									name="fatorEmb"
									disabled={descUnidEmbalagem === unid_fiscalEmb ? true : false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<InputDecimal label="Peso Bruto" value={pbEmb} onChange={setPbEmb} id="pbEmb" name="pbEmb" />
							</div>
							<div className="col-sm-4">
								<InputDecimal label="Peso Líquido" value={plEmb} onChange={setPlEmb} id="plEmb" name="plEmb" />
							</div>
							<div className="col-sm-4">
								<InputDecimal
									label="Peso Embalagem"
									value={peso_embal}
									onChange={setPesoEmbal}
									id="peso_embal"
									name="peso_embal"
									disabled
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<InputDecimal label="Lastro" value={lastroEmb} onChange={setLastroEmb} id="lastroEmb" name="lastroEmb" />
							</div>
							<div className="col-sm-4">
								<InputDecimal label="Camada" value={camadaEmb} onChange={setCamadaEmb} id="camadaEmb" name="camadaEmb" />
							</div>
							<div className="col-sm-4">
								<InputDecimal label="M³" value={m3Emb} onChange={setM3Emb} id="m3Emb" name="m3Emb" />
							</div>
						</div>
						<div className="row">
							<div className="col-sm">
								<fieldset className="border p-2">
									<legend className="text-sm float-none w-auto p-2">Medidas em Centímetros</legend>
									<div className="row">
										<div className="col-sm-4">
											<InputDecimal
												label="Comprimento"
												value={comprimentoEmb}
												onChange={setComprimentoEmb}
												id="comprimentoEmb"
												name="comprimentoEmb"
											/>
										</div>
										<div className="col-sm-4">
											<InputDecimal
												label="Largura"
												value={larguraEmb}
												onChange={setLarguraEmb}
												id="larguraEmb"
												name="larguraEmb"
											/>
										</div>
										<div className="col-sm-4">
											<InputDecimal label="Altura" value={alturaEmb} onChange={setAlturaEmb} id="alturaEmb" name="alturaEmb" />
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="col-sm">
							<div className="text-sm-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Voltar"
									icone={mdiKeyboardReturn}
									onclick={fecharModalEmbalagem}
								/>
								<BotaoCustomizado
									type="submit"
									className="btn btn-light text-info-emphasis bg-white border-0"
									descricaoBotao="Salvar"
									icone={mdiCheckCircle}
								/>
							</div>
						</div>
					</Modal.Footer>
				</form>
			</Modal>

			{/* MODAL PARA PESQUISA DE NCM */}
			<ModalPesquisa
				colunas={colunasNCM}
				fetchUrl={Api + `/lista/prodarm/ncm`}
				filtro="value"
				textoFiltro="Digite o NCM"
				visible={showPesquisaNCM}
				onSelect={selecionaNCM}
				onClose={fecharPesquisaNCM}
			/>
		</>
	);
};

export { NovoProdutosdeArmazenagem };
