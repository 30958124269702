const ConsultaCodigoBarras = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Consulta Cod.Barras/Caixas</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-1">
								<div className="col-sm-3">
									<div className="input-group flex-nowrap">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="codigo_barras"
												aria-describedby="basic-addon1"
												placeholder=""
											/>
											<label htmlFor="codigo_barras">Código Barras</label>
										</div>
									</div>

								</div>
								<div className="col-sm-3">
									<div className="input-group flex-nowrap">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="produto"
												aria-describedby="basic-addon1"
												placeholder=""
											/>
											<label htmlFor="produto">Produto</label>
										</div>
									</div>

								</div>
								<div className="col-sm-3">
									<div className="input-group flex-nowrap">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="pallet"
												aria-describedby="basic-addon1"
												placeholder=""
											/>
											<label htmlFor="pallet">Pallet</label>
										</div>
									</div>

								</div>
								<div className="col-sm-3">
									<div className="input-group flex-nowrap">
										<div className="mb-1 form-floating">
											<input
												type="text"
												className="form-control bordasInferiorInput text-black text-capitalize"
												id="wms"
												aria-describedby="basic-addon1"
												placeholder=""
											/>
											<label htmlFor="wms">WMS</label>
										</div>
									</div>

								</div>
							</div>
							<div className="row mb-3">
								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="cliente"
											aria-describedby="basic-addon1"
											placeholder=""
										/>
										<label htmlFor="cliente">Cliente</label>
									</div>
								</div>

								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="rg"
											aria-describedby="basic-addon1"
											placeholder=""
										/>
										<label htmlFor="rg">RG</label>
									</div>
								</div>

								<div className="col-sm-1">
									<button className="btn rounded" style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<i className="mdi mdi-magnify"></i>
									</button>
								</div>
							</div>
							<div className="row">
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>Código Barras</th>
												<th>Pallet</th>
												<th>Processo</th>
												<th>Cliente</th>
												<th>Produto</th>
												<th>Descrição</th>
												<th>WMS</th>
												<th>Peso Liq.</th>
												<th>Validade</th>
												<th>RG</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>1234567890</td>
												<td>121</td>
												<td>55</td>
												<td>FKL Services</td>
												<td>21.02.00342</td>
												<td>TOUCINHO CONG SUÍNO C/PELE</td>
												<td>1166</td>
												<td>120.35</td>
												<td>01/10/2021 09:00</td>
												<td>54321</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ConsultaCodigoBarras };
