import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
	mdiEye,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiPlusCircle,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
} from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const Transportadora = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN') || '';
	const perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const filial = sessionStorage.getItem('IsIDFilialPadraoFKL');
	const idFilial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(idFilial, perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoVincularDepositante, setPermissaoVincularDepositante] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarTransportadora) {
				toast.warning('Você não tem permissão para acessar Lista de Transportadoras');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarTransportadora);
				setPermissaoVincularDepositante(permissoes.perfilPermissao.vincularTransportadoraDepositante);
			}
		}
	}, [permissoes, erro, navigate]);

	const [data, setData] = useState([]);
	const [depositante, setDepositante] = useState<any>([]);
	const [transportadora, setTransportadora] = useState<any>([]);
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [idEntidade, setIdEntidade] = useState<string>('');
	const [idCliente, setIdCliente] = useState();
	const [idTransportadora, setIdTransportadora] = useState();
	const [transportadoraSelecionada, setTransportadoraSelecionada] = useState<string>();
	const [depositanteSelecionado, setDepositanteSelecionado] = useState<string>();

	const editarTransportadora = (dados: any) => {};
	const excluirTransportadora = (dados: any) => {
		setIdTransportadora(dados.id_transportador);
		setMostraModalConfirm(true);
	};
	const visualizarTransportadora = (dados: any) => {};

	const colunas: ITabela[] = [
		{ titulo: 'Depositante', acesso: 'fantasia' },
		{ titulo: 'Transportadora', acesso: 'fantasiaTransp' },
		{ titulo: 'Cadastro', acesso: 'cadastro' },
		{ titulo: 'Usuário', acesso: 'usuario' },
	];

	function carregaDados(idEntidade: string) {
		setMostraModalCarregar(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/${idFilial}/${idEntidade}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setData(resposta.data.data);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
					setData([]);
				}
			});
	}

	function carregaDepositantes() {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/depositantes/${idFilial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				// console.log(resposta.data)
				setDepositante(resposta.data.data);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	function carregaTransportadoras() {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/transportadora/${idFilial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setTransportadora(resposta.data.data);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	function vincularDepositante() {
		if (depositanteSelecionado == 'Selecione' || transportadoraSelecionada == 'Selecione') {
			toast.info('Selecione depositante e transportadora para prosseguir');
			return;
		}
		setMostraModalCarregar(true);
		axios
			.post(
				`${process.env.REACT_APP_API_BASE_URL}/vincular/depositante/transportadora`,
				{
					idFilial,
					idEntidade,
					idCliente,
					usuarioLogado,
				},
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				setMostraModalCarregar(false);
				toast.success(resposta.data.message);
				carregaDados(idEntidade);
				setTransportadoraSelecionada('Selecione');
				setDepositanteSelecionado('Selecione');
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	function deleteTransportadora() {
		setMostraModalConfirm(false);
		setMostraModalCarregar(false);
		axios
			.delete(
				`${process.env.REACT_APP_API_BASE_URL}/delete/transportadora/${idTransportadora}/${idEntidade}/${idCliente}/${usuarioLogado}/${idFilial}`,
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				setMostraModalCarregar(false);
				toast.success(resposta.data.message);
				carregaDados(idEntidade);
			})
			.catch(function (erro) {
				console.log(erro);
				setMostraModalCarregar(false);
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregaTransportadoras();
		carregaDepositantes();
		// carregaDados()
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem das Transportadoras</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row d-flex justify-content-between align-items-center">
									<div className="col-6">
										<div className="form-floating">
											<select
												autoFocus
												className="form-select bordasInferiorInput text-black text-capitalize"
												onChange={(event) => {
													setTransportadoraSelecionada(event.target.value);
													const entidade = transportadora.find((i: any) => i.fantasia == event.target.value);
													setIdEntidade(entidade ? entidade.id_entidade : '');
													const idEntidade = entidade ? entidade.id_entidade : '';
													carregaDados(idEntidade);
												}}
												value={transportadoraSelecionada}
											>
												<option>Selecione</option>

												{transportadora.map((transp: any) => (
													<option key={transp.id_entidade}>{transp.fantasia}</option>
												))}
											</select>
											<label className="form-label">TRANSPORTADORA</label>
										</div>
									</div>

									<div className="col-6">
										<div className="form-floating">
											<select
												autoFocus
												className="form-select bordasInferiorInput text-black text-capitalize"
												onChange={(event) => {
													setDepositanteSelecionado(event.target.value);
													const cliente = depositante.find((i: any) => i.fantasia == event.target.value);

													setIdCliente(cliente ? cliente.id_cliente : '');
												}}
												value={depositanteSelecionado}
											>
												<option>Selecione</option>

												{depositante.map((depositante: any) => (
													<option key={depositante.id_cliente}>{depositante.fantasia}</option>
												))}
											</select>
											<label className="form-label">DEPOSITANTE</label>
										</div>
									</div>
								</div>
								{permissaoVincularDepositante && (
									<div className="row mt-3 mb-2">
										<div className="col-4">
											<button className="btn btn-cancelar" onClick={() => vincularDepositante()}>
												<i className="me-2 bi bi-link fs-4 rounded"></i>
												Vincular depositante
											</button>
										</div>
									</div>
								)}
								<div>
									<ClickableTable
										coluna={colunas}
										itemsPerPage={10}
										data={data}
										onRowClick={function () {}}
										usaAcoes={true}
										acaoEditar={editarTransportadora}
										acaoExcluir={excluirTransportadora}
										acaoVisualizar={visualizarTransportadora}
										usaVisualizar={false}
										usaEditar={false}
										usaExcluir={true}
										acessopesquisa="fantasia"
										labelpesquisa="Procurar pelo depositante"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalCarregando mostrar={mostraModalCarregar} mensagem="Carregando..." />

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal="Deseja deletar o vinculo?"
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => deleteTransportadora()}
			/>
		</>
	);
};

export { Transportadora };
