import React from 'react';
import { Modal, Spinner } from 'react-bootstrap'; // Certifique-se de instalar o pacote react-bootstrap

interface ModalCarregandoProps {
	show: boolean;
	mensagem?: string;
}

const ModalCarregando: React.FC<ModalCarregandoProps> = ({ show, mensagem }) => {
	return (
		<Modal show={show} backdrop="static" size="sm" keyboard={false} centered>
			<div className="row" style={{ textAlign: 'center' }}>
				<div className="col-sm">
					<Spinner animation="border" role="status" variant="dark" size="sm" />
					<Spinner animation="border" role="status" variant="dark" />
				</div>
			</div>
			{mensagem && (
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							{mensagem}
						</label>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default ModalCarregando;
