import { IColunaExcel } from "../../../../components/componentes/types/IColunasExcel";

//exportar o array de colunas id_barra, id_filial, id_cliente, id_ua, id_wms, codigo, barra, qtde, pb, pl, producao, validade, lote, cadastro, usuario
export const colunasExcel: IColunaExcel[] = [
	{ titulo: 'ID', acesso: 'id_barra' },
	{ titulo: 'Filial', acesso: 'id_filial' },
	{ titulo: 'Cliente', acesso: 'id_cliente' },
	{ titulo: 'U.A', acesso: 'id_ua' },
	{ titulo: 'WMS', acesso: 'id_wms' },
	{ titulo: 'Código', acesso: 'codigo' },
	{ titulo: 'Barra', acesso: 'barra' },
	{ titulo: 'Quantidade', acesso: 'qtde' },
	{ titulo: 'Peso Bruto', acesso: 'pb' },
	{ titulo: 'Peso Líquido', acesso: 'pl' },
	{ titulo: 'Produção', acesso: 'producao' },
	{ titulo: 'Validade', acesso: 'validade' },
	{ titulo: 'Lote', acesso: 'lote' },
	{ titulo: 'Cadastro', acesso: 'cadastro' },
	{ titulo: 'Usuário', acesso: 'usuario' },
];
