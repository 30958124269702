import { ITabela } from "../../../../../components/Table/TabelaInterface";

//Criação das colunas da tabela
export const colunasGrid: ITabela[] = [
	{ titulo: 'Armazém', acesso: 'amz' },
	{ titulo: 'Corredor', acesso: 'cor' },	
	{ titulo: 'Box', acesso: 'box' },
	{ titulo: 'Altura', acesso: 'alt' },
	{ titulo: 'Profundidade', acesso: 'prof' },
	{ titulo: 'Executado', acesso: 'executado' },
	{ titulo: 'Cadastro', acesso: 'cadastro' },
	{ titulo: 'Usuário', acesso: 'usuario' },
];