import React, { useState } from 'react';

interface InputSemZeroEsquerdaProps {
	value: string;
	onChange: (value: string) => void;
	onBlur?: (value: any) => void;
	disabled?: boolean;
	useRef?: React.Ref<HTMLInputElement>;
	id: string;
	name: string;
	label: string;
	maxLength?: number;
	required?: boolean;
	type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' | 'search' | 'date' | 'time' | 'datetime-local' | 'month' | 'week';
}

const InputSemZeroEsquerda: React.FC<InputSemZeroEsquerdaProps> = ({
	value,
	onChange,
	disabled = false,
	id,
	name,
	label,
	maxLength,
	required,
	type = 'text',
	onBlur,
	useRef
}) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.toUpperCase();
		if (!/^0/.test(newValue)) {
			onChange(newValue);
		}
	};

	return (
		<div className="form-floating mb-1">
			<input
				type={type}
				className="form-control bordasInferiorInput text-black text-uppercase"
				id={id}
				name={name}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				maxLength={maxLength}
				required={required}
				onBlur={onBlur}
				ref={useRef}
			/>
			<label className="form-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
};

export default InputSemZeroEsquerda;
