import { Link } from 'react-router-dom';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import Icon from '@mdi/react';
import {
	mdiArrowDownBoldBoxOutline,
	mdiArrowUpBoldBoxOutline,
	mdiEye,
	mdiFileExport,
	mdiFileImport,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
import ModalExclusao from '../../../../components/componentes/formulario/ModalExcuir';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as FileSaver from 'file-saver';
import { ModeloTabelaCfop64 } from '../../../../util/modeloTabelaCfop';
import Inputs from '../../../../components/componentes/formulario/Inputs';
import DatalistInput from "react-datalist-input";


export function CadastroCFOPEntradaXsaida() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';



	const [cfopsEntradaXsaida, setCfopsEntradaXsaida] = useState([])

	//#region Paginação,
	const [itensPerPage, setItensPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pages = Math.ceil(cfopsEntradaXsaida.length / itensPerPage);

	const startIndex = currentPage * itensPerPage;
	const endIndex = startIndex + itensPerPage;
	const itensSelecionados = cfopsEntradaXsaida.slice(startIndex, endIndex);
	//#endregion
	//#region Modifica pagina da lista de relatorios
	const primeiraPagina = () => {
		if (currentPage > 0) {
			setCurrentPage(0);
		}
	};
	const paginaAnterior = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(pages - 1);
		}
	};
	//#endregion

	//listagem dos cfops
	async function pegarCfopsEntradaXsaida() {
		setShowCarregando(true)
		const cfops = await axios.get(Api + `/allCfopXsaida/?auth=${token}`)

		setShowCarregando(false)

		setCfopsEntradaXsaida(cfops.data)

	}
	//pegarCfopsEntradaXsaida(paginaInicial)
	useEffect(function () {
		pegarCfopsEntradaXsaida()
	}, [])


	//deletar um cfop com base no ID
	const [cfopParaDeletar, setcfopParaDeletar] = useState("")

	function pegarCfopParaDeletar(cfop: any) {
		setcfopParaDeletar(cfop)
		setShowQuestionaExclusao(true)
	}

	async function deleteCfopXsaida() {
		await axios.delete(Api + `/del/cfopXsaida/?auth=${token}&idCfop=${cfopParaDeletar}`)
		pegarCfopsEntradaXsaida()
		setShowQuestionaExclusao(false)
	}

	//#region Modifica o status do Modal PESQUISAR EMPRESA
	const [showPesquisar, setShowPesquisar] = useState(false);
	const fecharModalPesquisar = () => setShowPesquisar(false);
	//#endregion


	//dados para PESQUISA -- inicio
	const [cfop, setcfop] = useState("")
	const [natoper, setNatoper] = useState("")

	async function pesquisarDados(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		if (cfop == "" && natoper == "") {
			pegarCfopsEntradaXsaida()
			fecharModalPesquisar()
		}
		else {
			const data = {
				cfop: cfop,
				natoper: natoper
			}

			const resposta = await axios.post(Api + `/pesquisa/cfopxsaida?auth=${token}`, data)

			if (resposta.data.message == "CFOP encontrado") {
				setCfopsEntradaXsaida(resposta.data.pesquisado)
				fecharModalPesquisar()
				setNatoper("")
				setcfop("")
			}
			else {
				alert(resposta.data.message)
			}
		}
	}


	//dados para PESQUISA -- fim

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion

	//gera as opções de natoper --inicio
	const [natopers, setNatopers] = useState<any>([])

	async function natoperComplet(event: any) {
		setNatoper(event)

		if (natoper.length >= 3) {

			const natopers_Pesquisa = await axios.get(Api + `/select/natopers/?auth=${token}&natoper=${natoper}`)

			if (natopers_Pesquisa.data.message) {
				alert(natopers_Pesquisa.data.message)
			}
			else {
				for (let i = 0; i < natopers_Pesquisa.data.length; i = i + 1) {
					//formatando
					setNatopers([...natopers, { value: `${natopers_Pesquisa.data[i].natoper}` }])
				}
			}
		}
	}

	function setarValorSelecionadoNatoper(event: any) {
		setNatoper(event)
	}

	//gera as opções de natoper --fim

	//modal -exclusão - inicio
	const [showQuestionaExclusao, setShowQuestionaExclusao] = useState(false);


	//modal exclusão - fim


	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Listagem de CFOP's entrada X saída" />
			</div>

			<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">

								<div className="row mb-2">
									<div className="col-sm-8">
										<Link to={`/pco/contrFisc/contrNotFisc/cfopEntradaXsaida/novo`} className="btn btn-novo mb-2 rounded">
											<i className="mdi mdi-plus-circle me-2"></i>
											Novo CFOP
										</Link>{' '}
									</div>

									<div className="col-sm-4">
										<Button
											variant="success"
											onClick={() => setShowPesquisar(true)}
											className="mb-2 rounded"
											style={{ float: 'right', color: 'white'}}
										>
											Pesquisar
											<Icon path={mdiMagnify} size={1} className="me-2" />
										</Button>
									</div>

									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
											<thead className="table-cabecalho">
												<tr>
													<th>CFOP Entrada</th>
													<th>CFOP Saída</th>
													<th>Natureza de Operação de Saída</th>
													<th style={{ width: '85px' }}>Ações</th>
												</tr>
											</thead>
											<tbody className="corpoTabela">
												{/*corpo da tabela aqui*/}
												{/*MONTANDO TABELA HTML E CSS APENAS
                                                <tr>
													<th scope="row"><label>teste0</label></th>
													<td><label>teste1</label></td>
													<td><label>teste2</label></td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/pco/contrFisc/contrNotFisc/cfop/visu`}
															className="action-icon"
															title="Visualizar"
														>
															<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
														</Link>
														<Link
															to={`/pco/contrFisc/contrNotFisc/cfop/editar`}
															className="action-icon"
															title="Editar"
														>
															<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
														</Link>
														<a className="action-icon" title="Excluir">
															<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
														</a>
													</td>
												</tr>
                                                */}


												{itensSelecionados.map(function (dados: any) {
													return (
														<tr>
															<td>{dados.cfopent}</td>
															<td>{dados.cfopsai}</td>
															<td>{dados.natoper}</td>

															<td className="d-flex flex-nowrap table-action">
																<Link
																	to={`/pco/contrFisc/contrNotFisc/cfopEntradaXsaida/visualizar/${dados.id}`}
																	className="action-icon"
																	title="Visualizar"
																>
																	<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
																</Link>
																<Link
																	to={`/pco/contrFisc/contrNotFisc/cfopEntradaXsaida/editar/${dados.id}`}
																	className="action-icon"
																	title="Editar"
																>
																	<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
																</Link>
																<a className="action-icon" title="Excluir" onClick={function () {
																	pegarCfopParaDeletar(dados.id)
																}}>
																	<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
																</a>
															</td>

														</tr>
													)
												})}


											</tbody>
											<tfoot>
												{/*paginação aqui*/}
												<tr className="rodapeTabelaPaginacao">
													<td colSpan={14}>
														<a onClick={primeiraPagina} title="Primeira Página">
															<Icon path={mdiSkipBackward} size={1} />
														</a>
														<a onClick={paginaAnterior} title="Página Anterior">
															<Icon path={mdiSkipPrevious} size={1} />
														</a>
														Página: {currentPage + 1} de {pages} | Registros Total: {cfopsEntradaXsaida.length}
														<a onClick={proxPagina} title="Próxima Página">
															<Icon path={mdiSkipNext} size={1} />
														</a>
														<a onClick={ultimaPagina} title="Última Página">
															<Icon path={mdiSkipForward} size={1} />
														</a>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/*MODAL DE CARREGANDO */}
			<ModalCarregando show={showCarregando} />

			{/*Modal exclusão*/}
			<ModalExclusao show={showQuestionaExclusao} onClose={() => setShowQuestionaExclusao(false)} onConfirm={deleteCfopXsaida} />

			{/*MODAL PESQUISAR */}
			<Modal show={showPesquisar} backdrop="static" size="xl" keyboard={false} centered>
				<Modal.Header>
					<Modal.Title>Pesquisar CFOP Entrada X Saída</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={pesquisarDados}>
						<div className="row">
							<div className="col-sm-2 form-floating">
								<Inputs label="CFOP" type="text" name="cfop" classNameInput="form-control"
									classNameLabel="form-control bordasInferiorInput text-black text-capitalize" value={cfop} onchange={setcfop}
									maxLength={4} />
							</div>

							<div className="col-sm-6 form-floating">

								{/*gera as opções de pesqusia*/}

								<DatalistInput
									placeholder="Digite 3 caracteres para filtrar..."
									label="Natureza de operação de saída"
									showLabel={false}
									id="natoper"
									onChange={function (event: any) {
										natoperComplet(event.target.value)
									}}
									onSelect={function (event: any) {
										setarValorSelecionadoNatoper(event.value)
									}}
									items={natopers}
									className="dataListInput bordasInferiorInput text-black text-capitalize"
									value={natoper}
								/>
							</div>
						</div>

						<hr />



						<div className="d-flex my-2 gap-1 justify-content-between">
							<Button style={{float: 'left' }} className='rounded' onClick={fecharModalPesquisar}>
								<Icon path={mdiKeyboardReturn} size={1} className="me-2" /> Voltar{' '}
							</Button>
							<Button variant="success" className='rounded'type="submit">
								{' '}
								<Icon path={mdiMagnify} size={1} className="me-2" />
								Filtrar
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}