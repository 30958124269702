export const calcularQuantidadeEnderecos = (
	boxinicio: number,
	boxfim: number,
	alturainicio: number,
	alturafim: number,
	profundidadeinicio: number,
	profundidadefim: number
) => {
	const quantidadeBox = boxfim - boxinicio + 1;
	const quantidadeAltura = alturafim - alturainicio + 1;
	const quantidadeProfundidade = profundidadefim - profundidadeinicio + 1;

	return quantidadeBox * quantidadeAltura * quantidadeProfundidade;
};
