import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
	mdiArrowDownBoldBoxOutline,
	mdiArrowUpBoldBoxOutline,
	mdiEye,
	mdiFileExport,
	mdiFileImport,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';

const ConferenciaEntrada = (): JSX.Element => {

	const [processos, set_processos] = useState([])
	function carregarProcessosEntrada() {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/consult/confentrada/processos?auth=${sessionStorage.getItem("IsFKLTOKEN")}&codfilial=${sessionStorage.getItem("IsCodFilialPadraoFKL")}`)
			.then(function (resposta) {

				if (resposta.data.codigo != 200) {

					alert(resposta.data.message)
				}
				else {

					set_processos(resposta.data.processos)
				}
			}).catch(function (erro: any) {

				alert(erro)
			})
	}


	useEffect(function () {
		carregarProcessosEntrada()
	}, [])



	//-----PAGINAÇÃO-------//
	//#region Paginação,
	const [itensPerPage, setItensPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pages = Math.ceil(processos.length / itensPerPage);

	const startIndex = currentPage * itensPerPage;
	const endIndex = startIndex + itensPerPage;
	const itensSelecionados = processos.slice(startIndex, endIndex);
	//#endregion
	//#region Modifica pagina da lista de relatorios
	const primeiraPagina = () => {
		if (currentPage > 0) {
			setCurrentPage(0);
		}
	};
	const paginaAnterior = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage;
		if (pagina < pages) {
			setCurrentPage(pages - 1);
		}
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Conferência Entrada</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
									<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<tr>
											<th>Doca</th>
											<th>Processos</th>
											<th>Cliente</th>
											<th>Dt. Processo</th>
											<th>Dt. Chegada</th>
											<th>Tipo Operação</th>
											<th>Modalidade</th>
											<th>Cavalo</th>
											<th>Carreta</th>
											<th>Motorista</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										{processos.map(function (processo: any) {

											return (
												<>
													<tr>
														<td>{processo.doca || "---"}</td>
														<td>{processo.num_processo || "---"}</td>
														<td>{processo.fantasia || "---"}</td>
														<td>{processo.data_processo_formatada || "---"}</td>
														<td>{processo.data_chegada_formatada || "---"}</td>
														<td>{processo.descricao_operacao || "---"}</td>
														<td>{processo.descricao_modalidade || "---"}</td>
														<td>{processo.cavalo || "---"}</td>
														<td>{processo.carreta || "---"}</td>
														<td>{processo.nome_motorista || "---"}</td>
														<td className="table-action">
															<Link to={`/operacao/confEntrada/confEntrada/${processo.num_processo}`} className="action-icon">
																<i className="mdi mdi-square-edit-outline"></i>
															</Link>
														</td>
													</tr>
												</>
											)
										})}



									</tbody>
									<tfoot>
										{/*paginação aqui*/}
										<tr className="rodapeTabelaPaginacao">
											<td colSpan={14}>
												<a onClick={primeiraPagina} title="Primeira Página">
													<Icon path={mdiSkipBackward} size={1} />
												</a>
												<a onClick={paginaAnterior} title="Página Anterior">
													<Icon path={mdiSkipPrevious} size={1} />
												</a>
												Página: {currentPage + 1} de {pages} | Registros Total: {processos.length}
												<a onClick={proxPagina} title="Próxima Página">
													<Icon path={mdiSkipNext} size={1} />
												</a>
												<a onClick={ultimaPagina} title="Última Página">
													<Icon path={mdiSkipForward} size={1} />
												</a>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ConferenciaEntrada };
