const Enderecamento = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Endereçamento</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-12 mb-2">
									<a href="#" className="btn rounded" style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<i className="mdi mdi-plus-circle me-2"></i>
										Executar
									</a>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
									<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<tr>
											<th>#</th>
											<th>Pallet</th>
											<th>Cliente</th>
											<th>Camara</th>
											<th>Corredor</th>
											<th>Box</th>
											<th>Altura</th>
											<th>Profundidade</th>
											<th>Produto</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>61</td>
											<td>FKL Service</td>
											<td>C03</td>
											<td>A</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>21.02.00342</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Enderecamento };
