function bufferParaBase64(buffer: any) {
    let binary = ''
    let bytes = new Uint8Array(buffer)
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export default bufferParaBase64