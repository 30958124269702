import { useNavigate } from 'react-router-dom';

const NovasOcorrencias = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Nova Ocorrência</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-5 form-floating">
									<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder="">
										<option value="">cliente</option>
									</select>
									<label className="form-label">Cliente</label>
								</div>

								<div className="col-2 form-floating">
									<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder="">
										<option value="">processo</option>
									</select>
									<label className="form-label">Processo</label>
								</div>

								<div className="col-3 form-floating">
									<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder="">
										<option value="">natureza</option>
									</select>
									<label className="form-label">Natureza</label>
								</div>

							</div>
							<div className="row mb-4">
								<div className="col-4 form-floating">

									<select placeholder='' className="form-select bordasInferiorInput text-black text-capitalize">
										<option>canal</option>
									</select>
									<label className="form-label">Canal</label>
								</div>
								<div className="col-5 form-floating">
									<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
									<label className="form-label">Atendente Operacional</label>
								</div>

								<div className="col-3 form-floating">
									<input type="date" className="form-control bordasInferiorInput text-black text-capitalize" />
									<label className="form-label">Data Ocorrência</label>
								</div>

							</div>
							<div className="row mb-2">
								<div className="col form-floating">

									<textarea className="form-control bordasInferiorInput text-black text-capitalize"placeholder='' rows={5}></textarea>
									<label className="form-label">Descrição da Ocorrência</label>
								</div>
								<div className="col form-floating">

									<textarea className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' rows={5}></textarea>
									<label className="form-label">Ação Preliminar</label>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-danger mb-2 me-1"
										type="button"
										onClick={() => navigate('/pco/contrFisc/procEVeiculos/ocorrencia')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-info mb-2 me-1"
										type="button"
										onClick={() => navigate('/pco/contrFisc/procEVeiculos/ocorrencia')}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NovasOcorrencias };
