import axios from 'axios';
import { FormEvent, Ref, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';

const NovoDepositante = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN') || '';
	const idFilial = sessionStorage.getItem('IsIDFilialPadraoFKL')
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')

	const navigate = useNavigate();
	const params = useParams()
	const acao = params.acao
	const idDepositante = params.id

	const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

	const [tipoTolBalanca, setTipoTolBalanca] = useState<string>()
	const [viewBtnCadastro, setViewBtnCadastro] = useState<boolean>(true)
	const [viewInputValor, setViewInputValor] = useState<boolean>(true)

	const [CNPJ, setCNPJ] = useState<string>()
	const [razaoSocial, setRazaoSocial] = useState<string>()
	const [nomeFantasia, setNomeFantasia] = useState<string>()
	const [inscricaoEstadual, setInscricaoEstadual] = useState<string>()
	const [pais, setPais] = useState<string>()
	const [logradouro, setLogradouro] = useState<string>()
	const [contatoOperacional, setContatoOperacional] = useState<string>()
	const [numero, setNumero] = useState<number>()
	const [complemento, setComplemento] = useState<string>()
	const [bairro, setBairro] = useState<string>()
	const [cidade, setCidade] = useState<string>()
	const [estado, setEstado] = useState<string>()
	const [telOperacional, setTelOperacional] = useState<string>()
	const [cep, setCep] = useState<string>()
	const [emailOP, setEmailOP] = useState<string>()
	const [telFinanceiro, setTelFinanceiro] = useState<string>()
	const [contatoFinanceiro, setContatoFinanceiro] = useState<string>()
	const [emailFinanceiro, setEmailFinanceiro] = useState<string>()
	const [emailEnvioNF, setEmailEnvioNF] = useState<string>()
	const [indContribuinte, setIndContribuinte] = useState<any>()
	const [regimeFiscal, setRegimeFiscal] = useState<any>()

	const [enviaXml, setEnviaXml] = useState<boolean>(false);
	const [enviaPdf, setEnviaPdf] = useState<boolean>();
	const [ativo, setAtivo] = useState<boolean>(true)
	const [armazenagem, setArmazenagem] = useState<boolean>();
	const [crossdocking, setCrossdocking] = useState<boolean>();
	const [temOperacaoFiscal, setTemOperacaoFiscal] = useState<boolean>();
	const [gravaNFeRef, setGravaNFeRef] = useState<boolean>();
	const [usaNFeFornecedor, setUsaNFeFornecedor] = useState<boolean>();
	const [permiteConfVirtual, setPermiteConfVirtual] = useState<boolean>();
	const [exigeDocSIF, setExigeDocSIF] = useState<boolean>();
	const [permiteArqDadosEntrada, setPermiteArqDadosEntrada] = useState<boolean>();
	const [usarDadosArqConf, setUsarDadosArqConf] = useState<boolean>();
	const [exigeTempProcesso, setExigeTempProcesso] = useState<boolean>();
	const [exigeCheckListProcesso, setExigeCheckListProcesso] = useState<boolean>();
	const [barraEntradaConverter, setBarraEntradaConverter] = useState<boolean>();
	const [exigeCadastroProdutoCrossdocking, setExigeCadastroProdutoCrossdocking] = useState<boolean>();
	const [fazPicking, setFazPicking] = useState<boolean>();
	const [quebraPedidoTemp, setQuebraPedidoTemp] = useState<boolean>();
	const [quebraPedidoPeso, setQuebraPedidoPeso] = useState<boolean>();
	const [exibePesagem, setExibePesagem] = useState<boolean>();
	const [fatorCorrecaoPeso, setFatorCorrecaoPeso] = useState<boolean>();
	const [exigeLeituraRG, setExigeLeituraRG] = useState<boolean>();
	const [converterBarraCliente, setConverterBarraCliente] = useState<boolean>();
	const [exigeInformarSIF, setExigeInformarSIF] = useState<boolean>();
	const [cnpjAFaturar, setCnpjAFaturar] = useState<string>();
	const [mostraRgOs, setMostraRgOs] = useState<boolean>(true);
	const [mostraGtinOs, setMostraGtinOs] = useState<boolean>(true);
	const [exigeRgOs, setExigeRgOs] = useState<boolean>();
	const [exigeLeituraGtin, setExigeLeituraGtin] = useState<boolean>();
	const [exigeGtinOs, setExigeGtinOs] = useState<boolean>();
	const [valorPorcentagem, setValorPorcentagem] = useState<number>();
	const [exibirQtdeNF, setExibirQtdeNF] = useState<boolean>()
	const [permiteReceberMaior, setPermiteReceberMaior] = useState<boolean>()
	const [exigeLeituraGtinConfEntrada, setExigeLeituraGtinConfEntrada] = useState<boolean>()
	const [exigeLeituraTodasEmb, setExigeLeituraTodasEmb] = useState<boolean>()
	const [mostraLeituraEmb, setMostraLeituraEmb] = useState<boolean>(true)

	const [familia, setFamilia] = useState<string>()
	const [temperatura, setTemperatura] = useState<string>()
	const [tipoPeso, setTipoPeso] = useState<string>()
	const [toleranciaDias, setToleranciaDias] = useState<number>(0)
	const [diasAVencerBloq, setDiasAVencerBloq] = useState<number>(0)
	const [diasAVencer, setDiasAVencer] = useState<number>(0)
	const [controlaVal, setControlaVal] = useState<boolean>()
	const [controlaLote, setControlaLote] = useState<boolean>()
	const [controlaPesoPadrao, setControlaPesoPadrao] = useState<boolean>()
	const [minValDias, setMinValDias] = useState<number>(0)
	const [minValPercentual, setMinValPercentual] = useState<number>(0)

	const [infosTextBox, setInfosTextBox] = useState<any>([])
	const [idCliente, setIdCliente] = useState<number>()

	const dadosDeEnvio = {
		idDepositante,
		CNPJ,
		razaoSocial,
		nomeFantasia,
		inscricaoEstadual,
		pais,
		logradouro,
		contatoOperacional,
		numero,
		complemento,
		bairro,
		cidade,
		estado,
		telOperacional,
		cep,
		emailOP,
		telFinanceiro,
		contatoFinanceiro,
		emailFinanceiro,
		emailEnvioNF,
		enviaXml,
		enviaPdf,
		armazenagem,
		crossdocking,
		temOperacaoFiscal,
		gravaNFeRef,
		usaNFeFornecedor,
		permiteConfVirtual,
		exigeDocSIF,
		permiteArqDadosEntrada,
		usarDadosArqConf,
		exigeTempProcesso,
		exigeCheckListProcesso,
		barraEntradaConverter,
		exigeCadastroProdutoCrossdocking,
		fazPicking,
		quebraPedidoTemp,
		quebraPedidoPeso,
		exibePesagem,
		fatorCorrecaoPeso,
		exigeLeituraRG,
		converterBarraCliente,
		exigeInformarSIF,
		familia,
		temperatura,
		toleranciaDias,
		diasAVencerBloq,
		diasAVencer,
		controlaVal,
		controlaLote,
		controlaPesoPadrao,
		minValDias,
		minValPercentual,
		idFilial,
		usuarioLogado,
		cnpjAFaturar,
		indContribuinte,
		regimeFiscal,
		exigeRgOs,
		exigeLeituraGtin,
		exigeGtinOs,
		tipoPeso,
		valorPorcentagem,
		tipoTolBalanca,
		exibirQtdeNF,
		permiteReceberMaior,
		ativo,
		exigeLeituraGtinConfEntrada,
		exigeLeituraTodasEmb
	};

	async function carregarDadosCNPJ(CNPJ: any) {
		setMostraModalCarregando(true)
		if (CNPJ.length == 14) {
			axios.get(`https://publica.cnpj.ws/cnpj/${CNPJ}`).then(function (response) {
				setMostraModalCarregando(false)
				setCNPJ(response.data.estabelecimento.cnpj)
				setNomeFantasia(response.data.estabelecimento.nome_fantasia)
				setLogradouro(response.data.estabelecimento.logradouro)
				setNumero(response.data.estabelecimento.numero)
				setComplemento(response.data.estabelecimento.complemento !== null ? response.data.estabelecimento.complemento : '')
				setBairro(response.data.estabelecimento.bairro)
				setCidade(response.data.estabelecimento.cidade.nome)
				setCep(response.data.estabelecimento.cep)
				setEstado(response.data.estabelecimento.estado.sigla)
				setPais(response.data.estabelecimento.pais.nome)
				setRazaoSocial(response.data.razao_social)
				setInscricaoEstadual(response.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual)
			}).catch(function (erro) {
				setMostraModalCarregando(false)
				const status = erro.response.status
				if (status == 403 || status == 401) {
					toast.error("Acesso negado, faça seu login novamente")
					navigate("/login")
				} else {
					toast.error("CNPJ não encontrado")
					setCNPJ("")
					setNomeFantasia("")
					setLogradouro("")
					setNumero(0)
					setComplemento("")
					setBairro("")
					setCidade("")
					setCep("")
					setEstado("")
					setPais("")
					setRazaoSocial("")
					setInscricaoEstadual("")
				}
			})
		}
	}

	//#Region -> Criar
	async function insereNovoDepositante() {

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/insere/depositante`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}
		).then(function (resposta) {
			toast.success(resposta.data.message)

			setTimeout(() => {
				navigate("/config/cadast/depositante")
			}, 2000);
		}).catch(function (erro) {
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	function carregaInfosTextBox() {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/textBox`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setInfosTextBox(resposta.data.data)
		}).catch(function (erro) {
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	//#region -> Editar e Visualizar
	function carregaDadosEditView() {
		setMostraModalCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/dados/depositante/${idDepositante}/${idFilial}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setMostraModalCarregando(false)
			setIdCliente(resposta.data.data[0].id_cliente)
			setCNPJ(resposta.data.data[0].cnpj)
			setRazaoSocial(resposta.data.data[0].razao_social)
			setNomeFantasia(resposta.data.data[0].fantasia)
			setInscricaoEstadual(resposta.data.data[0].ie)
			setPais(resposta.data.data[0].pais)
			setLogradouro(resposta.data.data[0].logradouro)
			setNumero(resposta.data.data[0].numero)
			setComplemento(resposta.data.data[0].complemento)
			setBairro(resposta.data.data[0].bairro)
			setCidade(resposta.data.data[0].cidade)
			setEstado(resposta.data.data[0].uf)
			setCep(resposta.data.data[0].cep)
			setContatoOperacional(resposta.data.data[0].contato_operacional)
			setTelOperacional(resposta.data.data[0].telefone_operacional)
			setEmailOP(resposta.data.data[0].email_operacional)
			setTelFinanceiro(resposta.data.data[0].telefone_financeiro)
			setContatoFinanceiro(resposta.data.data[0].contato_financeiro)
			setEmailFinanceiro(resposta.data.data[0].email_financeiro)
			setEmailEnvioNF(resposta.data.data[0].email_nf)
			setCnpjAFaturar(resposta.data.data[0].cnpj_fatura)
			setIndContribuinte(resposta.data.data[0].indiedest)
			setRegimeFiscal(resposta.data.data[0].crt)
			setEnviaXml(resposta.data.data[0].envio_nfe)
			setEnviaPdf(resposta.data.data[0].envio_pdf_nfe)
			setTemOperacaoFiscal(resposta.data.data[0].operacao_fiscal)

			setArmazenagem(resposta.data.dataRegras[0].armazenagem)
			setCrossdocking(resposta.data.dataRegras[0].crossdoking)
			setUsaNFeFornecedor(resposta.data.dataRegras[0].nf_fornecedor)
			setPermiteConfVirtual(resposta.data.dataRegras[0].conferencia_virtual)
			setExigeDocSIF(resposta.data.dataRegras[0].doc_sif)
			setPermiteArqDadosEntrada(resposta.data.dataRegras[0].arquivo_integracao)
			setUsarDadosArqConf(resposta.data.dataRegras[0].conferencia_arquivo)
			setExigeTempProcesso(resposta.data.dataRegras[0].temperatura_processo)
			setExigeCheckListProcesso(resposta.data.dataRegras[0].check_list_processo)
			setBarraEntradaConverter(resposta.data.dataRegras[0].convert_barra_duplicidade)
			setExigeCadastroProdutoCrossdocking(resposta.data.dataRegras[0].cadprodcross)
			setFazPicking(resposta.data.dataRegras[0].picking)
			setQuebraPedidoTemp(resposta.data.dataRegras[0].quebra_pedido_temperatura)
			setQuebraPedidoPeso(resposta.data.dataRegras[0].quebra_pedido_tipo_peso)
			setExibePesagem(resposta.data.dataRegras[0].pesagem_os)
			setFatorCorrecaoPeso(resposta.data.dataRegras[0].percentual_correcao_pb_cross)
			setExibirQtdeNF(resposta.data.dataRegras[0].confere_aberto)
			setPermiteReceberMaior(resposta.data.dataRegras[0].permite_receber_maior_nf)
			setGravaNFeRef(resposta.data.dataRegras[0].informa_nfref)
			setExigeLeituraGtinConfEntrada(resposta.data.dataRegras[0].gtin_entrada)
			//Verifica se gtinConfEntrada é true para exibir leituraTodasEmb
			if (resposta.data.dataRegras[0].gtin_entrada) {
				setMostraLeituraEmb(false)
			}
			setExigeLeituraTodasEmb(resposta.data.dataRegras[0].gtin_entrada_todos)

			setTipoTolBalanca(resposta.data.dataRegras[0].tipo_tolerancia_balanca)
			//Verifica tipo de tolerancia cadastrada nas regras
			if (resposta.data.dataRegras[0].tipo_tolerancia_balanca == 'Porcento') {
				setViewBtnCadastro(true)
				setViewInputValor(false)
				setValorPorcentagem(resposta.data.dataRegras[0].perc_tolerancia_balanca)
			} else if (resposta.data.dataRegras[0].tipo_tolerancia_balanca == 'Faixa') {
				setViewInputValor(true)
				setViewBtnCadastro(false)
			}

			if (resposta.data.dataRegras[0].gtin_saida_por_os || resposta.data.dataRegras[0].gtin_saida) {
				setMostraGtinOs(false)
			}

			if (resposta.data.dataRegras[0].rg_volume_por_os || resposta.data.dataRegras[0].rg_volume) {
				setMostraRgOs(false)
			}

			setExigeLeituraGtin(resposta.data.dataRegras[0].gtin_saida)
			setExigeGtinOs(resposta.data.dataRegras[0].gtin_saida_por_os)
			setExigeLeituraRG(resposta.data.dataRegras[0].rg_volume)
			setExigeRgOs(resposta.data.dataRegras[0].rg_volume_por_os)
			setConverterBarraCliente(resposta.data.dataRegras[0].convert_barra)
			setExigeInformarSIF(resposta.data.dataRegras[0].controla_sif)

			setFamilia(resposta.data.dataRegrasProd[0].familia)
			setTemperatura(resposta.data.dataRegrasProd[0].temperatura)
			setTipoPeso(resposta.data.dataRegrasProd[0].tipo_peso)
			setDiasAVencerBloq(resposta.data.dataRegrasProd[0].bloq_val_dias)
			setDiasAVencer(resposta.data.dataRegrasProd[0].dias_data_critica)
			setToleranciaDias(resposta.data.dataRegrasProd[0].toleranca_quebra_fefo)
			setControlaVal(resposta.data.dataRegrasProd[0].controla_validade)
			setControlaLote(resposta.data.dataRegrasProd[0].controla_lote)
			setControlaPesoPadrao(resposta.data.dataRegrasProd[0].rg_peso_padrao)
			setMinValDias(resposta.data.dataRegrasProd[0].min_val_dias)
			setMinValPercentual(resposta.data.dataRegrasProd[0].min_val_perc)
		}).catch(function (erro) {
			setMostraModalCarregando(false)
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	function editarDepositante() {
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/editar/depositante`,
			dadosDeEnvio,
			{
				headers: {
					authorization: token
				}
			}
		).then(function (resposta) {
			toast.success(resposta.data.message)

			setTimeout(() => {
				navigate("/config/cadast/depositante")
			}, 2000);
		}).catch(function (erro) {
			const status = erro.response.status
			if (status == 403 || status == 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	useEffect(() => {
		carregaInfosTextBox()
		if (acao !== 'criar') {
			carregaDadosEditView()
		} else {
			setControlaVal(true)
			setControlaLote(true)
		}
	}, [])


	//#region Setando foco caso campo obrigatório não esteja preenchido

	// Define o tipo dos nomes dos campos
	type FieldNames =
		| 'CNPJ'
		| 'razaoSocial'
		| 'nomeFantasia'
		| 'inscricaoEstadual'
		| 'pais'
		| 'logradouro'
		| 'numero'
		| 'bairro'
		| 'cidade'
		| 'estado'
		| 'cep'
		| 'contatoOperacional'
		| 'telOperacional'
		| 'emailOP'
		| 'telFinanceiro'
		| 'contatoFinanceiro'
		| 'emailFinanceiro'
		| 'emailEnvioNF'
		| 'cnpjAFaturar'
		| 'indContribuinte'
		| 'regimeFiscal';

	// Criação das referências
	const CNPJRef = useRef<HTMLInputElement | null>(null);
	const razaoSocialRef = useRef<HTMLInputElement | null>(null);
	const nomeFantasiaRef = useRef<HTMLInputElement | null>(null);
	const inscricaoEstadualRef = useRef<HTMLInputElement | null>(null);
	const paisRef = useRef<HTMLInputElement | null>(null);
	const logradouroRef = useRef<HTMLInputElement | null>(null);
	const numeroRef = useRef<HTMLInputElement | null>(null);
	const bairroRef = useRef<HTMLInputElement | null>(null);
	const cidadeRef = useRef<HTMLInputElement | null>(null);
	const estadoRef = useRef<HTMLInputElement | null>(null);
	const cepRef = useRef<HTMLInputElement | null>(null);
	const contatoOperacionalRef = useRef<HTMLInputElement | null>(null);
	const telOperacionalRef = useRef<HTMLInputElement | null>(null);
	const emailOPRef = useRef<HTMLInputElement | null>(null);
	const telFinanceiroRef = useRef<HTMLInputElement | null>(null);
	const contatoFinanceiroRef = useRef<HTMLInputElement | null>(null);
	const emailFinanceiroRef = useRef<HTMLInputElement | null>(null);
	const emailEnvioNFRef = useRef<HTMLInputElement | null>(null);
	const cnpjAFaturarRef = useRef<HTMLInputElement | null>(null);
	const indContribuinteRef = useRef<HTMLSelectElement | null>(null);
	const regimeFiscalRef = useRef<HTMLSelectElement | null>(null);

	// Mapeamento dos nomes dos campos para suas referências
	const refs: Record<FieldNames, React.RefObject<any>> = {
		CNPJ: CNPJRef,
		razaoSocial: razaoSocialRef,
		nomeFantasia: nomeFantasiaRef,
		inscricaoEstadual: inscricaoEstadualRef,
		pais: paisRef,
		logradouro: logradouroRef,
		numero: numeroRef,
		bairro: bairroRef,
		cidade: cidadeRef,
		estado: estadoRef,
		cep: cepRef,
		contatoOperacional: contatoOperacionalRef,
		telOperacional: telOperacionalRef,
		emailOP: emailOPRef,
		telFinanceiro: telFinanceiroRef,
		contatoFinanceiro: contatoFinanceiroRef,
		emailFinanceiro: emailFinanceiroRef,
		emailEnvioNF: emailEnvioNFRef,
		cnpjAFaturar: cnpjAFaturarRef,
		indContribuinte: indContribuinteRef,
		regimeFiscal: regimeFiscalRef // Atualização aqui
	};

	const arrayRequired: FieldNames[] = [
		'CNPJ',
		'razaoSocial',
		'nomeFantasia',
		'inscricaoEstadual',
		'pais',
		'logradouro',
		'numero',
		'bairro',
		'cidade',
		'estado',
		'cep',
		'contatoOperacional',
		'telOperacional',
		'emailOP',
		'telFinanceiro',
		'contatoFinanceiro',
		'emailFinanceiro',
		'emailEnvioNF',
		'cnpjAFaturar',
		'indContribuinte',
		'regimeFiscal'
	];

	function verificaRequired() {
		for (const item of arrayRequired) {
			const ref = refs[item];

			if (!ref.current?.value) { // Verifica se o valor do input é vazio
				toast.info(`Por favor, preencha o campo obrigatório ${item}`);
				console.log("Campo obrigatório não preenchido:", item);

				const aba = document.querySelector("#abaGeral") as HTMLElement;
				if (aba) {
					aba.click(); // Simula o clique na aba
				}

				ref.current?.focus(); // Define o foco no campo correspondente
				return; // Interrompe a execução após encontrar um campo inválido
			}
		}

		insereNovoDepositante()
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Novo Depositante</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form onSubmit={function (e) {
							e.preventDefault()
							if (acao == 'criar') {
								verificaRequired()
							} else if (acao == 'editar') {
								editarDepositante()
							}
						}}>
							<div className="card">

								<div className="card-body">

									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a id="abaGeral" href="#geralDepositante" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#regraNegocioDepositante" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Regras de Negócio</span>
											</a>
										</li>
										{/* <li className="nav-item">
											<a href="#divFilial" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-settings-outline d-md-none d-block"></i>
												<span className="d-none d-md-block">Diversos</span>
											</a>
										</li> */}
										<li className="nav-item">
											<a href="#preCadastroProduto" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-receipt d-md-none d-block"></i>
												<span className="d-none d-md-block">Pré-Cadastro Produto</span>
											</a>
										</li>
										{/* <li className="nav-item">
											<a href="#smtpconfig" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-settings-outline d-md-none d-block"></i>
												<span className="d-none d-md-block">Configuração</span>
											</a>
										</li> */}
									</ul>


									<div className="tab-content">
										<div className="tab-pane show active" id="geralDepositante">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Codigo da filial" id="codfilial"
															name="codfilial"
															value={idCliente}
															disabled
														/>
														<label className="form-label">Depositante</label>
													</div>
												</div>
												{/* <div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="coderp"
															name="coderp"
															value={inputs.coderp || ""}
															placeholder=''
															onChange={handleChange} />
														<label className="form-label">Identificador no ERP</label>
													</div>
												</div> */}
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="cnpj"
															name="cnpj"
															value={CNPJ}
															placeholder=''
															onBlur={(event) => carregarDadosCNPJ(event.target.value)}
															onChange={(event) => {
																setCNPJ((event.target.value).replace(/[.\-\/]/g, ''))
															}}
															// required 
															ref={CNPJRef}
														/>
														<label className="form-label">CNPJ</label>
													</div>
												</div>
												<div className="col-sm-6">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="razao_social"
															name="razao_social"
															placeholder=''
															value={razaoSocial}
															onChange={(event) => setRazaoSocial(event.target.value)}
															ref={razaoSocialRef}
														/>
														<label className="form-label">Razão Social</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															value={nomeFantasia}
															placeholder=''
															onChange={(event) => setNomeFantasia(event.target.value)}
															ref={nomeFantasiaRef}
														/>
														<label className="form-label">Nome Fantasia</label>
													</div>
												</div>
												<div className="col-sm-3">
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="IE"
															name="IE"
															value={inscricaoEstadual}
															placeholder=''
															onChange={(event) => setInscricaoEstadual(event.target.value)}
															// required
															ref={inscricaoEstadualRef}
														/>
														<label className="form-label">IE</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={pais}
															placeholder=''
															onChange={(event) => setPais(event.target.value)}
															ref={paisRef}
														/>
														<label className="form-label">País</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className='col-sm-3'>
													<div className="form-floating mb-1">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="logradouro"
															value={logradouro}
															placeholder=''
															onChange={(event) => setLogradouro(event.target.value)}
															// required
															ref={logradouroRef}
														/>
														<label className="form-label">Logradouro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input
															type="number"
															className="form-control bordasInferiorInput text-black " id="contato"
															name="contato"
															placeholder=''
															value={numero}
															onChange={(event) => setNumero(parseInt(event.target.value))}
															// required
															ref={numeroRef}
														/>
														<label className="form-label">Numero</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={complemento}
															onChange={(event) => setComplemento(event.target.value)}
														/>
														<label className="form-label">Complemento</label>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={bairro}
															onChange={(event) => setBairro(event.target.value)}
															// required
															ref={bairroRef}
														/>
														<label className="form-label">Bairro</label>
													</div>
												</div>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="cidade"
															placeholder=''
															value={cidade}
															onChange={(event) => setCidade(event.target.value)}
															// required
															ref={cidadeRef}
														/>
														<label className="form-label">Cidade</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="contato"
															name="contato"
															placeholder=''
															value={estado}
															onChange={(event) => setEstado(event.target.value)}
															// required
															ref={estadoRef}
														/>
														<label className="form-label">Estado</label>
													</div>
												</div>

											</div>
											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={cep}
															onChange={(event) => setCep(event.target.value)}
															// required
															ref={cepRef}
														/>
														<label className="form-label">CEP</label>
													</div>
												</div>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={contatoOperacional}
															// required
															onChange={(event) => setContatoOperacional(event.target.value)}
															ref={contatoOperacionalRef}
														/>
														<label className="form-label">Contato Operacional</label>
													</div>
												</div>
												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contato"
															name="contato"
															placeholder=''
															value={telOperacional}
															// required
															onChange={(event) => setTelOperacional(event.target.value)}
															ref={telOperacionalRef}
														/>
														<label className="form-label">Tel. Operacional</label>
													</div>
												</div>
											</div>

											<div className='row'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="emailOP"
															name="emailOP"
															placeholder=''
															value={emailOP}
															// required
															onChange={(event) => setEmailOP(event.target.value)}
															ref={emailOPRef}
														/>
														<label className="form-label">Email Operacional</label>
													</div>
												</div>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="telFinanceiro"
															name="telFinanceiro"
															placeholder=''
															value={telFinanceiro}
															// required
															onChange={(event) => setTelFinanceiro(event.target.value)}
															ref={telFinanceiroRef}
														/>
														<label className="form-label">Telefone Financeiro</label>
													</div>
												</div>

												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contatoFinanceiro"
															name="contatoFinanceiro"
															placeholder=''
															value={contatoFinanceiro}
															// required
															onChange={(event) => setContatoFinanceiro(event.target.value)}
															ref={contatoFinanceiroRef}
														/>
														<label className="form-label">Contato Financeiro</label>
													</div>
												</div>
											</div>

											<div className='row mb-3'>
												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="contatoOP"
															name="contatoOP"
															placeholder=''
															value={emailFinanceiro}
															// required
															onChange={(event) => setEmailFinanceiro(event.target.value)}
															ref={emailFinanceiroRef}
														/>
														<label className="form-label">Email Financeiro</label>
													</div>
												</div>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="emailEnvioNF"
															name="emailEnvioNF"
															placeholder=''
															value={emailEnvioNF}
															// required
															onChange={(event) => setEmailEnvioNF(event.target.value)}
															ref={emailEnvioNFRef}
														/>
														<label className="form-label">Email Envio de NF</label>
													</div>
												</div>

												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="text" className="form-control bordasInferiorInput text-black" id="emailEnvioNF"
															name="emailEnvioNF"
															placeholder=''
															value={cnpjAFaturar}
															// required
															onChange={(event) => setCnpjAFaturar(event.target.value)}
															ref={cnpjAFaturarRef}
														/>
														<label className="form-label">CNPJ a faturar</label>
													</div>
												</div>

												<div className='row'>

													<div className="col-sm-3">
														<div className="form-floating mb-1">

															<select
																className="form-select bordasInferiorInput text-black text-capitalize"
																value={indContribuinte}
																name="regime_fiscal"
																onChange={(event) => setIndContribuinte(event.target.value)}
																ref={indContribuinteRef}
															>
																<option value="">Selecione...</option>
																<option value="1">Contribuinte</option>
																<option value="2">Não contribuinte</option>
																<option value="3">Isento</option>
															</select>
															<label className="form-label" htmlFor="cfopEntrada">Ind. Contribuinte</label>
														</div>
													</div>

													<div className="col-sm-3">
														<div className="form-floating mb-1">

															<select
																className="form-select bordasInferiorInput text-black text-capitalize"
																name="regime_fiscal"
																value={regimeFiscal}
																onChange={(event) => setRegimeFiscal(event.target.value)}
																// required
																ref={regimeFiscalRef}
															>
																<option value="">Selecione...</option>
																<option value="1">Simples Nacional</option>
																<option value="2">Simples Nacional, excesso sublimite de receita bruta</option>
																<option value="3">Regime Normal</option>
																<option value="4">MEI</option>
															</select>
															<label className="form-label" htmlFor="cfopEntrada">Regime Fiscal</label>
														</div>
													</div>

													<div className='col-sm-2 mt-3 form-check form-switch'>
														<input
															className="form-check-input"
															type="checkbox"
															checked={enviaXml}
															id="enviaXmlCheck"
															onChange={(e) => setEnviaXml(e.target.checked)}
															style={{
																width: '35px'
															}}
														/>
														<label className="ms-2 form-check-label mt-1 col" htmlFor="enviaXmlCheck">
															Envia .xml
														</label>
													</div>

													<div className='col-sm-3 mt-3 form-check form-switch'>
														<input
															className="form-check-input ms-2"
															type="checkbox"
															checked={enviaPdf}
															id="enviaPdfCheck"
															onChange={(e) => setEnviaPdf(e.target.checked)}
															style={{
																width: '35px'
															}}
														/>
														<label className="ms-2 form-check-label mt-1 col" htmlFor="enviaPdfCheck">
															Envia .pdf
														</label>
													</div>

													<div className='col-sm-3 mt-3 form-check form-switch'>
														<input
															className="form-check-input ms-2"
															type="checkbox"
															checked={ativo}
															id="enviaPdfCheck"
															onChange={(e) => setAtivo(e.target.checked)}
															style={{
																width: '35px'
															}}
														/>
														<label className="ms-2 form-check-label mt-1 col" htmlFor="enviaPdfCheck">
															Ativo
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane" id="regraNegocioDepositante">
											<div className="row">

												<div className="card col-sm-6">
													<div className="card-body border border-secondary">
														<h5 className="card-title m-auto p-1 w-50 text-center bg-primary text-white">Tipo Operação</h5>

														<div className='row form-check form-switch mt-1'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={armazenagem}
																id="armCheck"
																onChange={(e) => setArmazenagem(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="armCheck">
																Armazenagem
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={crossdocking}
																id="crossCheck"
																onChange={(e) => setCrossdocking(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="crossCheck">
																Crossdocking
															</label>
														</div>
													</div>
												</div>

												<div className="card col-sm-6">
													<div className="card-body border border-secondary">
														<h5 className="card-title m-auto p-1 w-50 text-center bg-primary text-white">Fiscal</h5>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={temOperacaoFiscal}
																id="opCheck"
																onChange={(e) => setTemOperacaoFiscal(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="opCheck">
																Tem Operação Fiscal
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={gravaNFeRef}
																id="nfeRefCheck"
																onChange={(e) => setGravaNFeRef(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="nfeRefCheck">
																Grava N° NFe de Ref. na linha do Produto
															</label>
														</div>
													</div>
												</div>
											</div>



											<div className='row'>
												<div className="card col-sm-6">
													<div className="card-body border border-secondary">
														<h5 className="card-title m-auto p-1 w-50 text-center bg-primary text-white">Regras de Processo</h5>

														<div className='row form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={usaNFeFornecedor}
																id="nfeFornecedorCheck"
																onChange={(e) => setUsaNFeFornecedor(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="nfeFornecedorCheck">
																Usa NFe de Fornecedor
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exibirQtdeNF}
																id="qtdeNfConfCheck"
																onChange={(e) => setExibirQtdeNF(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1" htmlFor="qtdeNfConfCheck">
																Exibir Qtde. Nfe na Conferência
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={permiteReceberMaior}
																id="permiteReceberMaiorCheck"
																onChange={(e) => setPermiteReceberMaior(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="permiteReceberMaiorCheck">
																Não Permite Receber a Maior que NFe
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={permiteConfVirtual}
																id="confVirtualCheck"
																onChange={(e) => setPermiteConfVirtual(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="confVirtualCheck">
																Permite Conferência Virtual
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeDocSIF}
																id="DocSifCheck"
																onChange={(e) => setExigeDocSIF(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="DocSifCheck">
																Exige Documento S.I.F
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={permiteArqDadosEntrada}
																id="arqDadosCheck"
																onChange={(e) => setPermiteArqDadosEntrada(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="arqDadosCheck">
																Permite Uso Arquivo de Dados-Entrada
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={usarDadosArqConf}
																id="dadosConfCheck"
																onChange={(e) => setUsarDadosArqConf(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="dadosConfCheck">
																Usar Dados do Arquivo para Conferência
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeTempProcesso}
																id="tempProcessoCheck"
																onChange={(e) => setExigeTempProcesso(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="tempProcessoCheck">
																Exige Temperatura Processo
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeCheckListProcesso}
																id="checkListCheck"
																onChange={(e) => setExigeCheckListProcesso(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="checkListCheck">
																Exige Check-List Processo
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={barraEntradaConverter}
																id="checkListCheck"
																onChange={(e) => setBarraEntradaConverter(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="checkListCheck">
																Se Barra Entrada já Existir, Converter para Barra do Sistema
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeCadastroProdutoCrossdocking}
																id="cadProdCheck"
																onChange={(e) => setExigeCadastroProdutoCrossdocking(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="cadProdCheck">
																Exige cadastro Produto Crossdocking
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={fazPicking}
																id="pickingCheck"
																onChange={(e) => setFazPicking(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="pickingCheck">
																Faz Picking
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={quebraPedidoTemp}
																id="quebraPedTempCheck"
																onChange={(e) => setQuebraPedidoTemp(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="quebraPedTempCheck">
																Quebra Pedido Temperatura
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={quebraPedidoPeso}
																id="quebraPedPadraVarCheck"
																onChange={(e) => setQuebraPedidoPeso(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="quebraPedPadraVarCheck">
																Quebra Pedido Peso Padrão X Variado
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exibePesagem}
																id="exibePesagemCheck"
																onChange={(e) => setExibePesagem(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="exibePesagemCheck">
																Exibe Pesagem de O.S
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={fatorCorrecaoPeso}
																id="fatorCorrecaoCheck"
																onChange={(e) => setFatorCorrecaoPeso(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="fatorCorrecaoCheck">
																Fator Correção Peso Br. NF Cross – Campo Texto para colocar %
															</label>
														</div>

														<div className="form-floating mt-1">

															<select className="form-select bordasInferiorInput text-black text-capitalize"
																id="cfopEntrada" name="modo_operacao"
																value={tipoTolBalanca}
																onChange={(event) => {
																	setTipoTolBalanca(event.target.value)
																	const selecionado = event.target.value

																	if (selecionado === 'Faixa') {
																		setViewBtnCadastro(false);
																		setViewInputValor(true);
																	} else if (selecionado === 'Porcento') {
																		setViewInputValor(false);
																		setViewBtnCadastro(true);
																	} else {
																		setViewBtnCadastro(true);
																		setViewInputValor(true);
																	}

																}}
															>
																<option value="">Selecione...</option>
																<option>Faixa</option>
																<option>Porcento</option>
															</select>
															<label className="form-label" htmlFor="cfopEntrada">Tipo de Tolerância Balança</label>
														</div>

														<div className='row mt-2 w-100 d-flex justify-content-center'>
															<button
																hidden={viewBtnCadastro}
																type="button"
																className="btn btn-novo text-center w-50"
																onClick={() => {
																	// desfragmentar(UAOrigemRef) 
																}}
															>
																<span className='me-1'>Cadastro</span><i className="bi bi-plus-circle"></i>
															</button>
														</div>

														<div className="form-floating mt-1" hidden={viewInputValor}>

															<input className='form-control bordasInferiorInput text-black text-capitalize'
																type="number"
																name="nf_inicial"
																placeholder=''
																value={valorPorcentagem}
																onChange={(event => setValorPorcentagem(parseInt(event.target.value)))}
															/>
															<label className="form-label" htmlFor="cfopEntrada">Insira o valor</label>
														</div>
													</div>
												</div>

												<div className="card col-sm-6">
													<div className="card-body border border-secondary">
														<h5 className="card-title m-auto p-1 w-50 text-center bg-primary text-white">Regras de Conferência</h5>

														<div className='row form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeLeituraRG}
																id="leituraRgCheck"
																onChange={(e) => {
																	setExigeLeituraRG(e.target.checked)
																	if (e.target.checked == true) {
																		setMostraRgOs(false)
																	} else {
																		setMostraRgOs(true)
																	}

																	if (e.target.checked === false) {
																		setExigeRgOs(false)
																	}
																}}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="leituraRgCheck">
																Exige Leitura de RG Volume Carga
															</label>
														</div>

														<div className='row form-check form-switch' hidden={mostraRgOs}>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeRgOs}
																id="leituaPorOS"
																onChange={(e) => setExigeRgOs(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="leituaPorOS">
																Exige Leitura de RG por OS
															</label>
														</div>

														<div className='row form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeLeituraGtin}
																id="leituraGtinCheck"
																onChange={(e) => {
																	setExigeLeituraGtin(e.target.checked)
																	if (e.target.checked == true) {
																		setMostraGtinOs(false)
																	} else {
																		setMostraGtinOs(true)
																	}

																	if (e.target.checked === false) {
																		setExigeGtinOs(false)
																	}
																}}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="leituraGtinCheck">
																Exige Leitura de Gtin - Carga
															</label>
														</div>

														<div className='row form-check form-switch' hidden={mostraGtinOs}>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeGtinOs}
																id="gtinPorOs"
																onChange={(e) => {
																	setExigeGtinOs(e.target.checked)
																}}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="gtinPorOs">
																Exige Leitura de Gtin por OS
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={converterBarraCliente}
																id="barraClienteCheck"
																onChange={(e) => setConverterBarraCliente(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="barraClienteCheck">
																Converte Barra Cliente para Barra do Sistema
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeInformarSIF}
																id="sifUACheck"
																onChange={(e) => setExigeInformarSIF(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="sifUACheck">
																Exige informar S.I.F na U.A.
															</label>
														</div>

														<div className='row mt-1 form-check form-switch'>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeLeituraGtinConfEntrada}
																id="sifUACheck"
																onChange={(e) => {
																	setExigeLeituraGtinConfEntrada(e.target.checked)

																	if (e.target.checked === true) {
																		setMostraLeituraEmb(false)
																	} else {
																		setMostraLeituraEmb(true)
																	}

																	if (e.target.checked === false) {
																		setExigeLeituraTodasEmb(false)
																	}
																}}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="sifUACheck">
																Conf. Entrada - Exige Leitura de Gtin para peso Padrão
															</label>
														</div>

														<div className='row mt-1 form-check form-switch' hidden={mostraLeituraEmb}>
															<input
																className="form-check-input"
																type="checkbox"
																checked={exigeLeituraTodasEmb}
																id="sifUACheck"
																onChange={(e) => setExigeLeituraTodasEmb(e.target.checked)}
																style={{
																	width: '35px'
																}}
															/>
															<label className="form-check-label mt-1 col" htmlFor="sifUACheck">
																Exige Leitura do Gtin de todas as embalagens
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane" id="preCadastroProduto">

											<div className='row'>
												<div className="col-sm-3 form-floating mt-1">

													<select className="form-select bordasInferiorInput text-black text-capitalize"
														id="cfopEntrada"
														name="modo_operacao"
														value={familia}
														onChange={(event) => {
															setFamilia(event.target.value)
														}}
													>
														<option value="">Selecione</option>

														{infosTextBox
															.filter((familiaProd: any) => familiaProd.id_codsis == 2)
															.map((familiaProd: any, index: any) => (
																<option key={index} value={familiaProd.descricao}>
																	{familiaProd.descricao}
																</option>
															))
														}

													</select>
													<label className="form-label" htmlFor="cfopEntrada">Família</label>
												</div>

												<div className="col-sm-3 form-floating mt-1">

													<select className="form-select bordasInferiorInput text-black text-capitalize"
														id="cfopEntrada"
														name="modo_operacao"
														value={temperatura}
														onChange={(event) => {
															setTemperatura(event.target.value)
														}}
													>
														<option value="">Selecione</option>
														{infosTextBox
															.filter((tempProd: any) => tempProd.id_codsis == 6)
															.map((tempProd: any, index: any) => (
																<option key={index} value={tempProd.descricao}>
																	{tempProd.descricao}
																</option>
															))
														}
													</select>
													<label className="form-label" htmlFor="cfopEntrada">Temperatura</label>
												</div>

												<div className="col-sm-6 form-floating mt-1">

													<select className="form-select bordasInferiorInput text-black text-capitalize"
														id="cfopEntrada"
														name="modo_operacao"
														value={tipoPeso}
														onChange={(event) => {
															setTipoPeso(event.target.value)
														}}
													>
														<option value="">Selecione</option>

														{infosTextBox
															.filter((tipoPesoProd: any) => tipoPesoProd.id_codsis == 5)
															.map((tipoPesoProd: any, index: any) => (
																<option key={index} value={tipoPesoProd.descricao}>
																	{tipoPesoProd.descricao}
																</option>
															))
														}
													</select>
													<label className="form-label" htmlFor="cfopEntrada">Tipo de peso</label>
												</div>
											</div>

											<div className='row mt-2'>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={diasAVencerBloq}
															placeholder=''
															onChange={(event) => setDiasAVencerBloq(parseInt(event.target.value))}
														/>
														<label className="form-label">Dias a Vencer para Bloqueio</label>
													</div>
												</div>

												<div className='col-sm-3'>
													<div className="form-floating mb-1">

														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={diasAVencer}
															placeholder=''
															onChange={(event) => setDiasAVencer(parseInt(event.target.value))}
														/>
														<label className="form-label">Dias a Vencer</label>
													</div>
												</div>

												<div className='col-sm-6'>
													<div className="form-floating mb-1">

														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" id="pais"
															name="pais"
															value={toleranciaDias}
															placeholder=''
															onChange={(event) => setToleranciaDias(parseInt(event.target.value))}
														/>
														<label className="form-label">Tolerancia em dias de Quebra da Fefo</label>
													</div>
												</div>
											</div>

											<div className='row'>
												<div className='col-sm-3 mt-4 form-check form-switch'>
													<input
														className="form-check-input ms-2"
														type="checkbox"
														checked={controlaVal}
														id="controlValCheck"
														onChange={(e) => setControlaVal(e.target.checked)}
														style={{
															width: '35px'
														}}
													/>
													<label className="ms-2 form-check-label mt-1 col" htmlFor="controlValCheck">
														Controla Validade
													</label>
												</div>

												<div className='col-sm-3 mt-4 form-check form-switch'>
													<input
														className="form-check-input ms-2"
														type="checkbox"
														checked={controlaLote}
														id="controlLoteCheck"
														onChange={(e) => setControlaLote(e.target.checked)}
														style={{
															width: '35px'
														}}
													/>
													<label className="ms-2 form-check-label mt-1 col" htmlFor="controlLoteCheck">
														Controla Lote
													</label>
												</div>

												<div className='col-sm-6 mt-4 form-check form-switch'>
													<input
														className="form-check-input ms-2"
														type="checkbox"
														checked={controlaPesoPadrao}
														id="controlPesoCheck"
														onChange={(e) => setControlaPesoPadrao(e.target.checked)}
														style={{
															width: '35px'
														}}
													/>
													<label className="ms-2 form-check-label mt-1 col" htmlFor="controlPesoCheck">
														Controla Peso Padrão por RG
													</label>
												</div>
											</div>

											<div className='row mt-4 mb-3'>
												<div className='col-sm-6'>
													<div className='row'>
														<div className='d-flex align-items-center text-center'>
															<span className="ms-2 text-dark">Mínimo Validade a vencer aceito no recebimento</span>
														</div>
														<div className='form-floating col-sm-5 me-2'>
															<input
																className="form-control bordasInferiorInput text-black"
																type="number"
																id="dias"
																value={minValDias}
																onChange={(e) => setMinValDias(parseInt(e.target.value))}
															/>
															<label className="form-label" htmlFor='dias'>Dias</label>
														</div>
														<div className='form-floating col-sm-5'>
															<input
																className="form-control bordasInferiorInput text-black"
																type="number"
																id="percentual"
																value={minValPercentual}
																onChange={(e) => setMinValPercentual(parseInt(e.target.value))}
															/>
															<label className="form-label" htmlFor='percentual'>Percentual</label>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
									<div className="card-footer mt-1">
										<div className="col-sm">
											<div className="text-sm-end">
												<BotaoCustomizado
													type="button"
													className="btn btn-light text-danger bg-white border-0"
													descricaoBotao="Voltar"
													icone={mdiKeyboardReturn}
													onclick={() => {
														navigate(-1);
													}}
												/>
												<BotaoCustomizado
													type="submit"
													className="btn btn-light text-info-emphasis bg-white border-0"
													descricaoBotao="Salvar"
													icone={mdiCheckCircle}
													disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div >
				</div >
			</div >

			<ModalCarregando
				mostrar={mostraModalCarregando}
				mensagem='Carregando...'
			/>

		</>
	);
};

export { NovoDepositante };
