import { ITabela } from "../../../../../components/Table/TabelaInterface";

export const colunasProdutos: ITabela[] = [
	{
		titulo: 'Item',
		acesso: 'nitem',
	},
	{
		titulo: 'Cfop',
		acesso: 'cfop',
	},
	{
		titulo: 'Ncm',
		acesso: 'ncm',
	},
	{
		titulo: 'Origem',
		acesso: 'orih',
	},	
	{
		titulo: 'Código',
		acesso: 'codigo',
	},
	{
		titulo: 'Gtin',
		acesso: 'gtin',
	},
	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'Qtde.Com',
		acesso: 'qtd_com',
	},
	{
		titulo: 'Unid.Com',
		acesso: 'und_com',
	},
	{
		titulo: 'Valor Unit',
		acesso: 'vlr_unit',
	},
];