const HistoricoEnderecamento = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Histórico Endereçamento</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-1">
								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="pallet"
											placeholder=""
										/>
										<label htmlFor="pallet">Pallet</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="cliente"
											placeholder=""
										/>
										<label htmlFor="cliente">Cliente</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="processo"
											placeholder=""
										/>
										<label htmlFor="processo">Processo</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="mb-1 form-floating">
										<input
											type="date"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="data"
											placeholder=""
										/>
										<label htmlFor="data">Data</label>
									</div>
								</div>

							</div>
							<div className="row mb-3">
								<div className="col-sm-4">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="endereco_ant"
											placeholder=""
										/>
										<label htmlFor="endereco_ant">Endereço Ant</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="endereco_atual"
											placeholder=""
										/>
										<label htmlFor="endereco_atual">Endereço Atual</label>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="mb-1 form-floating">
										<input
											type="text"
											className="form-control bordasInferiorInput text-black text-capitalize"
											id="produto"
											placeholder=""
										/>
										<label htmlFor="produto">Produto</label>
									</div>
								</div>

								<div className="col-sm-1">
									<button className="btn rounded" style={{ backgroundColor: "#1c4b9b", color: "white" }}>
										<i className="mdi mdi-magnify"></i>
									</button>
								</div>
							</div>
							<div className="row">
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>Pallet</th>
												<th>Processo</th>
												<th>Cliente</th>
												<th>Endereço Anterior</th>
												<th>Endereço Atual</th>
												<th>Data Movimentação</th>
												<th>Produto</th>
												<th>Qtde Embalagens</th>
												<th>Usuários</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>121</td>
												<td>55</td>
												<td>FKL Services</td>
												<td>C1A11</td>
												<td>C1A11</td>
												<td>26/11/21 16:00:00</td>
												<td>1092</td>
												<td>40</td>
												<td>HSILVA</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { HistoricoEnderecamento };
