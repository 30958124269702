import { mdiCheckCircle } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Api } from '../../../../services/api';
import Icon from '@mdi/react';
import axios from 'axios';

const NovoEndereco = (): JSX.Element => {
	//#region

	//#region => VARIÁVEIS
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const user = sessionStorage.getItem('IsPerfilFKL');
	const idUsuario = sessionStorage.getItem('IsIDFKL');
	const cod_filial = sessionStorage.getItem('IsCodFilialPadraoFKL');
	const [tipoPosicao, setTipoPosicao] = useState('');
	const [tipoTemperatura, setTipoTemperatura] = useState('');
	const [confQuantidade, setConfQuantidade] = useState('');
	const [inputs, setInputs] = useState({})
	const [mostrarModalErro, setMostrarModalErro] = useState(false);
	const navigate = useNavigate();
	//#endregion

	//#region => BUSCAR e CARREGAR MENU - Tipos de Posição
	const [menuPosicoes, setMenuPosicoes] = useState([]);
	const carregarPosicoes = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPPOSICAO&auth=${token}`)
			setMenuPosicoes(resposta.data);
			setTipoPosicao(`${resposta.data[0].codigo}-${resposta.data[0].descricao}`);

		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => BUSCAR e CARREGAR MENU - Tipos de Temperatura
	const [menuTemperaturas, setMenuTemperaturas] = useState([]);
	const carregarTemperaturas = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPTEMP&auth=${token}`)
			setMenuTemperaturas(resposta.data);
			setTipoTemperatura(`${resposta.data[0].codigo}`)
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion


	//#region => ARMAZENAR valores dos INPUTS & MENUS de seleção
	const armazenarInputsDoUsuario = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }));
	}

	const armazenarTipoPosicao = (event: any) => {
		const value = event.target.value;
		setTipoPosicao(value);
	}

	const armazenarTipoTemperatura = (event: any) => {
		const value = event.target.value;
		setTipoTemperatura(value);
	}

	const armazenarConfQuantidade = (event: any) => {
		const value = event.target.value;
		setConfQuantidade(value);
	}


	//#endregion

	//#region => ENVIAR DADOS para o BACK-END
	const enviarDados = async (event: any) => {
		event.preventDefault();

		const body = {
			tipoPosicao,
			tipoTemperatura,
			...inputs,
			confQuantidade,
			codFilial: cod_filial,
			criadoPor: user,
		}

		try {
			const resposta = await axios.post(Api + `/enderecos/?auth=${token}`, body);
			// console.log(resposta);
			navigate(-1);
		} catch (error) {
			console.log(error);
			setMostrarModalErro(true);
		}
	}
	//#endregion

	//#region => CARREGAR informações na tela
	useEffect(() => {
		carregarPosicoes();
		carregarTemperaturas();
	}, [])
	//#endregion

	//#endregion
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Gerar Endereço</h4>
						</div>
					</div>
				</div>
				<form id="form-utilitarios" onSubmit={enviarDados}>
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<div className="mb-1 form-floating">

										<select
											className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''
											id="tipoPosicao"
											name="tipoPosicao"
											defaultValue={tipoPosicao}
											value={tipoPosicao}
											onChange={armazenarTipoPosicao}
											title="Tabela: TIPPOSICAO"
											required
										>
											{
												menuPosicoes.map((posicao: any, index: number) => {
													return (
														<option key={index} value={`${posicao.codigo}-${posicao.descricao}`}>{posicao.codigo} - {posicao.descricao}</option>
													)
												})
											}
										</select>
										<label className="form-label" htmlFor="tipoPosicao">
											Tipo de Posição
										</label>
									</div>
								</div>
								<div className="col">
									<div className="mb-1 form-floating">

										<select
											className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''
											id="temperatura"
											name="tipoTemperatura"
											defaultValue={tipoTemperatura}
											value={tipoTemperatura}
											onChange={armazenarTipoTemperatura}
											title="Tabela: TIPTEMP"
											required
										>
											{
												menuTemperaturas.map((temperatura: any, index: number) => {
													return (
														<option key={index} value={temperatura.codigo}>{index + 1} - {temperatura.codigo}</option>
													)
												})
											}
										</select>
										<label className="form-label" htmlFor="temperatura">
											Temperatura
										</label>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col form-floating">

									<input type="text" id="armazem" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="armazem" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="armazem" className="form-label">
										Armazém:
									</label>
								</div>
								<div className="col form-floating">

									<input type="text" id="corredor" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="corredor" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="corredor" className="form-label">
										Corredor:
									</label>
								</div>
								<div className="col form-floating">

									<input type="number" id="boxIni" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="boxInicial" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="boxIni" className="form-label">
										Box Inicial:
									</label>
								</div>
								<div className="col form-floating">

									<input type="number" id="boxFim" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="boxFinal" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="boxFim" className="form-label">
										Box Final:
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col form-floating">

									<input type="number" id="altIni" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="alturaInicial" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="altIni" className="form-label">
										Altura Inicial:
									</label>
								</div>
								<div className="col form-floating">

									<input type="number" id="altFim" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="alturaFinal" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="altFim" className="form-label">
										Altura Final:
									</label>
								</div>
								<div className="form-floating col">

									<input type="number" id="profIni" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="profundidadeInicial" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="profIni" className="form-label">
										Profundidade Inicial:
									</label>
								</div>
								<div className="form-floating col">

									<input type="number" id="profFim" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' name="profundidadeFinal" onChange={armazenarInputsDoUsuario} required />
									<label htmlFor="profFim" className="form-label">
										Profundidade Final:
									</label>
								</div>
							</div>
							<div className="row">
								<div className="form-floating col-6">
									<div className="mb-1 form-floating">

										<select
											className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''
											id="qtdePicking"
											defaultValue={confQuantidade}
											value={confQuantidade}
											onChange={armazenarConfQuantidade}
											title="Tabela: SIMNAO"
											required
										>
											<option value="0">0 - NÃO</option>
											<option value="1">1 - SIM</option>
										</select>
										<label className="form-label" htmlFor="qtdePicking">
											Conf. Qtde Durante Picking
										</label>
									</div>
								</div>

							</div>
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-cancelar mb-2 me-1"
										type="button"
										onClick={() => navigate('/config/cadast/endereco')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-novo mb-2 me-1"
										type="submit"
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			{/*MODAL - ERRO AO CRIAR ENDERECOS*/}
			<Modal show={mostrarModalErro} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Erro ao criar endereços</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
					<BsExclamationCircleFill size={"100px"} color="#f58619" />
					<div className="row" style={{ textAlign: 'center' }}>
						<div className="col-sm" >
							<h3>Um ou mais dos endereços que você está tentando criar já existem para esta filial.</h3>
						</div>
					</div>
					<hr />
					<div className="d-flex my-2 gap-1 justify-content-between">
						<Button type="button" onClick={() => setMostrarModalErro(false)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />OK</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export { NovoEndereco };
