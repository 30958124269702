//NAO RECOMENDO USAR PARA DINHEIRO
function MascaraValor(value: string) {

    // Remove tudo que não é número
    let valorFormatado = value.replace(/\D/g, '')

    // Formata o valor
    valorFormatado = (parseFloat(valorFormatado) / 100).toFixed(2) + ''
    valorFormatado = valorFormatado.replace(".", ",")
    valorFormatado = valorFormatado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")

    // Atualiza o valor do input
    return valorFormatado
}

export default MascaraValor