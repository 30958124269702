import { ITabela } from "../../../../../components/Table/TabelaInterface";

//Criação das colunas da tabela
export const colunasGrid: ITabela[] = [
	{ titulo: 'ID CFOP', acesso: 'id' },
	{ titulo: 'Ativo', acesso: 'ativo' },
	{ titulo: 'CFOP', acesso: 'cfop' },
	{ titulo: 'Natureza de Operação', acesso: 'natureza_op' },
	{ titulo: 'Tipo', acesso: 'tipo_cfop' },
	{ titulo: 'Usuário', acesso: 'usuario' },
];