import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalPesquisa from '../../../../components/componentes/formulario/ModalPesquisa';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import DatalistInput from 'react-datalist-input';
import mascaraCPF from '../../../../components/functions/mascaraCPF';
import mascaraCelular from '../../../../components/functions/mascaraCelular';
const NovoMotorista = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_motorista = params.id;
	const acao = params.acao;

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	//#region Modifica o status do Modal Pesquisa
	const [abrirModalPesquisa, setAbrirModalPesquisa] = useState(false);
	//#endregion
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [id_veiculo, setIdVeiculo] = useState<string>('');

	//dados do motorista
	const [nome, setNome] = useState<string>('');
	const [cpf, setCpf] = useState<string>('');
	const [telefone, setTelefone] = useState<string>('');
	const [bloqueio, setBloqueio] = useState<boolean>(false);
	const [motivoBloqueio, setMotivoBloqueio] = useState<string>('');
	const [situacao, setSituacao] = useState<boolean>(true);
	const [id_veiculoCad, setIdVeiculoCad] = useState<string>('');
	const [nomeVeiculoCad, setNomeVeiculoCad] = useState<string>('');

	const colunas: ITabela[] = [
		{
			titulo: 'ID Veículo',
			acesso: 'id_veiculo',
		},
		{
			titulo: 'Status',
			acesso: 'ativo',
		},
		{
			titulo: 'Placa 1',
			acesso: 'placa_1',
		},
		{
			titulo: 'Placa 2',
			acesso: 'placa_2',
		},
		{
			titulo: 'Tipo Veículo',
			acesso: 'tipo_veiculo',
		},
		{
			titulo: 'Transportadora',
			acesso: 'transportadora',
		},
	];

	const [dadosVeiculoFiltro, setDadosVeiculoFiltro] = useState<any[]>([]);

	const excluirVeiculo = (dados: any) => {
		setMsgPrincipalModal(`Deseja excluir o veículo ${dados.placa_1}`);
		setMsgAcao1('Cancelar');
		setIdVeiculo(dados.id_veiculo);
		setMostraModalConfirm(true);
	};
	function excluir() {
		if (acao === 'editar') {
			deletarMotorista();
		} else {
			const veiculo = dadosVeiculoFiltro.filter((item) => item.id_veiculo !== id_veiculo);
			setDadosVeiculoFiltro(veiculo);
			setMsgAcao1('');
			setMsgPrincipalModal('');
			setMostraModalConfirm(false);
			setIdVeiculo('');
		}
	}
	const selecionaVeiculo = (dados: any) => {
		if (acao === 'criar') {
			const veiculo = dadosVeiculoFiltro.filter((item) => item.id_veiculo === dados.id_veiculo);
			if (veiculo.length > 0) {
				toast.error('Veículo já vinculado ao motorista.');
				return;
			} else {
				setDadosVeiculoFiltro([...dadosVeiculoFiltro, dados]);
				setAbrirModalPesquisa(false);
			}
		} else {
			const dadosVeiculoFiltro = {
				id_motorista,				
				id_veiculo: dados.id_veiculo,
				usuarioLogado,
			};

			insereNovoVeiculoMotorista(dadosVeiculoFiltro);
		}
	};

	const dadosDeEnvio = {
		id_veiculo: id_veiculoCad,
		situacao: situacao ? 1 : 0,
		cpf,
		nome,
		telefone,
		bloqueio: bloqueio ? 1 : 0,
		motivoBloqueio,
		usuarioLogado,
		veiculos: dadosVeiculoFiltro,
	};

	//#region -> Filtrar Veiculo
	const [dadosVeiculo, setDadosVeiculo] = useState([]);
	function buscarVeiculo(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;
		setShowCarregando(true);
		const dados = {
			filtro: _filtro,
		};
		axios
			.post(Api + `/lista/motorista/veiculo/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDadosVeiculo(resposta.data);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#region -> Criar
	function insereNovoVeiculoMotorista(dados: any) {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/motorista/veiculo/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setAbrirModalPesquisa(false);
				carregaDados();
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	function insereNovoMotorista() {
		setShowCarregando(true);
		axios
			.post(Api + `/insere/motorista/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				setTimeout(() => {
					navigate('/cadast/veiculo/motorista');
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/motorista/edit/${id_filial}/${id_motorista}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setNome(resposta.data.motorista[0].nome);
				setCpf(resposta.data.motorista[0].cpf);
				setSituacao(resposta.data.motorista[0].ativo);
				setTelefone(resposta.data.motorista[0].telefone);
				setMotivoBloqueio(resposta.data.motorista[0].motivo_bloq);
				setBloqueio(resposta.data.motorista[0].bloqueio);
				setDadosVeiculoFiltro(resposta.data.veiculo);
				setIdVeiculoCad(resposta.data.motorista[0].id_veiculo);
				setNomeVeiculoCad(resposta.data.motorista[0].placa);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarMotorista() {
		axios
			.put(Api + `/editar/motorista/${id_filial}/${id_motorista}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate('/cadast/veiculo/motorista');
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	function deletarMotorista() {
		setShowCarregando(true);
		setMostraModalConfirm(false);
		axios
			.delete(Api + `/excluir/motorista/veiculo/${id_filial}/${id_veiculo}/${id_motorista}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message);
				carregaDados();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar' ? 'Novo Motorista' : acao === 'editar' ? 'Editar Motorista' : 'Visualizar Motorista'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (bloqueio && motivoBloqueio === '') {
									toast.error('Informe o motivo do bloqueio');
									return;
								}
								if (acao === 'criar') {
									insereNovoMotorista();
								} else if (acao === 'editar') {
									editarMotorista();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#motoVeiculo" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="bi bi-envelope-at d-md-none d-block"></i>
												<span className="d-none d-md-block">Veículos</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-3">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="Codigo da filial"
															id="id_motorista"
															name="id_motorista"
															value={id_motorista}
															disabled
														/>
														<label className="form-label">ID Motorista</label>
													</div>
												</div>
												<div className="col-sm-5">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="Codigo da filial"
															id="nome"
															name="nome"
															value={nome}
															required
															onChange={(event) => setNome(event.target.value)}
															readOnly={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Nome</label>
													</div>
												</div>

												<div className="col-lg-2 col-md-3 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={situacao}
															onClick={function () {
																setSituacao(!situacao);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input w-25"
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Ativo</label>
													</div>
												</div>
												<div className="col-lg-2 col-md-3 pt-3 col-sm">
													<div className="form-check form-switch">
														<input
															checked={bloqueio}
															onClick={function () {
																if (bloqueio === !false) {
																	setMotivoBloqueio('');
																}

																setBloqueio(!bloqueio);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															className="form-check-input w-25"
															type="checkbox"
														/>
														<label className="ms-1 form-check-label">Bloqueio</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-4 col-md-4 col-sm">
													<div className="form-floating">
														<input
															onChange={function (e) {
																setCpf(e.target.value);
															}}
															readOnly={acao === 'criar' ? false : true}
															onBlur={function () {
																setCpf(mascaraCPF(cpf));
															}}
															required
															value={cpf}
															type="text"
															className="form-control bordasInferiorInput text-black"
															placeholder="XXX.XXX.XXX-XX"
														/>
														<label>CPF</label>
													</div>
												</div>
												<div className="col-lg-4 col-md-4 col-sm">
													<div className="form-floating">
														<input
															onChange={function (e) {
																setTelefone(e.target.value);
															}}
															readOnly={acao === 'criar' ? false : acao === 'editar' ? false : true}
															onBlur={function () {
																setTelefone(mascaraCelular(telefone));
															}}
															value={telefone}
															type="text"
															className="form-control bordasInferiorInput text-black"
														/>
														<label>Telefone</label>
													</div>
												</div>
												<div className="col-lg-4 col-md-4 col-sm">
													<div className="form-floating mb-1">
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="Veículo"
															onSelect={(item) => {
																setIdVeiculoCad(item.id);
																setNomeVeiculoCad(item.value);
															}}
															items={dadosVeiculo}
															onChange={(item) => buscarVeiculo(item)}
															className="mt-2"
															value={nomeVeiculoCad}
															inputProps={{
																disabled: acao === 'criar' ? false : acao === 'editar' ? false : true,
															}}
														/>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm">
													<div className="form-floating mb-1">
														<textarea
															maxLength={100}
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="Motivo Bloqueio"
															id="motivoBloqueio"
															name="motivoBloqueio"
															value={motivoBloqueio}
															disabled={bloqueio ? false : true}
															onChange={(event) => setMotivoBloqueio(event.target.value)}
															readOnly={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
														<label className="form-label">Motivo Bloqueio</label>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane" id="motoVeiculo">
											<div className="card-body">
												<div className="row">
													<div className="col d-flex justify-content-start">
														<BotaoCustomizado
															type="button"
															className="btn btn-secondary mb-1"
															descricaoBotao="Pesquisar Veículo"
															icone={mdiPlusCircle}
															onclick={() => {
																setAbrirModalPesquisa(true);
															}}
															disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														/>
													</div>
												</div>
												<div className="">
													<ClickableTable
														coluna={colunas}
														itemsPerPage={10}
														data={dadosVeiculoFiltro}
														onRowClick={() => {}}
														usaAcoes={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoExcluir={excluirVeiculo}
														usaVisualizar={false}
														usaEditar={false}
														usaExcluir={acao === 'criar' ? true : acao === 'editar' ? true : false}
														acaoEditar={() => {}}
														acaoVisualizar={() => {}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal show={showCarregando} backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			{/*MODAL PESQUISA*/}
			<ModalPesquisa
				visible={abrirModalPesquisa}
				onClose={() => setAbrirModalPesquisa(false)}
				fetchUrl={Api + `/lista/motorista/veiculos/${id_filial}/${id_motorista === 'novo' ? 0 : id_motorista}`}
				onSelect={selecionaVeiculo}
				colunas={colunas}
				filtro="placa_1"
				textoFiltro="Digite a placa do veículo"
			/>

			{/* MODAL CONFIRMACAO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => excluir()}
			/>
		</>
	);
};

export { NovoMotorista };
