import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from '../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import {
	mdiCheckCircle,
	mdiCloseCircle,
	mdiEye,
	mdiKeyboardReturn,
	mdiMagnify,
	mdiPlusCircle,
	mdiSkipBackward,
	mdiSkipForward,
	mdiSkipNext,
	mdiSkipPrevious,
	mdiSquareEditOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import { BsExclamationCircleFill } from 'react-icons/bs';
import Icon from '@mdi/react';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

const Depositante = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN') || '';
	const perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const idFilial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(idFilial, perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarDepositante) {
				toast.warning('Você não tem permissão para acessar Lista de Depositantes');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarDepositante);
				setPermissaoCriar(permissoes.perfilPermissao.criarDepositante);
				setPermissaoEditar(permissoes.perfilPermissao.editarDepositante);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirDepositante);
			}
		}
	}, [permissoes, erro, navigate]);

	const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false);

	//#Region -> Novo desenvolvimento
	const colunas: ITabela[] = [
		{ titulo: '', acesso: `avatar` },
		{ titulo: 'Id', acesso: 'id_cliente' },
		{ titulo: 'Ativo', acesso: 'ativo' },
		{ titulo: 'CNPJ', acesso: 'cnpj' },
		{ titulo: 'Fantasia', acesso: 'fantasia' },
		{ titulo: 'Razão Social', acesso: 'razao_social' },
		{ titulo: 'Cidade', acesso: 'cidade' },
		{ titulo: 'UF', acesso: 'uf' },
	];

	const editarDepositante = (dados: any) => {
		navigate(`/config/cadast/depositante/${dados.id_cliente}/editar`);
	};
	const excluirDepositante = (dados: any) => {
		// setIdEntidade(dados.id_entidade)
		// setMostraModalConfirm(true)
	};
	const visualizarDepositante = (dados: any) => {
		navigate(`/config/cadast/depositante/${dados.id_cliente}/visualizar`);
	};

	const [data, setData] = useState([]);

	function carregaDadosDepositante() {
		setMostraModalCarregando(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/depositante/${idFilial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregando(false);
				setData(resposta.data.data);
			})
			.catch(function (erro) {
				setMostraModalCarregando(false);
				const status = erro.response.status;
				if (status == 403 || status == 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.log(erro);
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregaDadosDepositante();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem dos Depositantes</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								{permissaoCriar && (
									<div className="row mb-2">
										<div className="col">
											<Link className="btn btn-secondary" to={`/config/cadast/depositante/novoDepositante/criar`}>
												<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
												Novo Depositante
											</Link>
										</div>
									</div>
								)}
								<div className="table-responsive">
									<ClickableTable
										coluna={colunas}
										itemsPerPage={10}
										data={data}
										onRowClick={function () {}}
										usaAcoes={true}
										acaoEditar={editarDepositante}
										acaoExcluir={excluirDepositante}
										acaoVisualizar={visualizarDepositante}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="fantasia"
										labelpesquisa="Procurar por fantasia"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalCarregando mostrar={mostraModalCarregando} mensagem="Carregando" />
		</>
	);
};

export { Depositante };
