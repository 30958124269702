import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NovaEmbalagem = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL');
	const idusuario = sessionStorage.getItem('IsIDFKL');
	const codfilial = sessionStorage.getItem('IsCodFilialPadraoFKL');

	const navigate = useNavigate();
	const idembalagem = useParams();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  

	const [data, setData] = useState([]);
	const [inputs, setInputs] = useState({
		codembalagem: '',
		descricao: '',
		codunidademedida1: '',
		qtdunidmedida1: '',
		codunidademedida2: '',
		qtdunidmedida2: '',
		codunidademedida3: '',
		qtdunidmedida3: '',
		codunidademedida4: '',
		qtdunidmedida4: '',
		regra_unidmed1: '',
		regra_unidmed2: '',
		regra_unidmed3: '',
		regra_unidmed4: '',
		regra_idtemperatura: '',
		regra_idunidmanuseio: '',
		regra_idtipopeso: '',
		regra_fatorfiscal: '',
		regra_comprimento: '',
		regra_altura: '',
		regra_largura: '',
		regra_pesoemb: '',
		regra_prazovalidade: '',
		regra_pesoliquido: '',
		regra_lastro: '',
		regra_camada: '',
		dimensao_unidmedida1: '',
		dimensao_unidmedida2: '',
		dimensao_unidmedida3: '',
		dimensao_unidmedida4: '',
		dimensao_unidmedida_comprimento1: '',
		dimensao_unidmedida_largura1: '',
		dimensao_unidmedida_altura1: '',
		dimensao_unidmedida_comprimento2: '',
		dimensao_unidmedida_largura2: '',
		dimensao_unidmedida_altura2: '',
		dimensao_unidmedida_comprimento3: '',
		dimensao_unidmedida_largura3: '',
		dimensao_unidmedida_altura3: '',
		dimensao_unidmedida_comprimento4: '',
		dimensao_unidmedida_largura4: '',
		dimensao_unidmedida_altura4: '',
		peso_unidmedida1: '',
		peso_unidmedida2: '',
		peso_unidmedida3: '',
		peso_unidmedida4: '',
		peso_unidmedida_pesoliqpadrao1: '',
		peso_unidmedida_pesobrutopadrao1: '',
		peso_unidmedida_pesoliqpadrao2: '',
		peso_unidmedida_pesobrutopadrao2: '',
		peso_unidmedida_pesoliqpadrao3: '',
		peso_unidmedida_pesobrutopadrao3: '',
		peso_unidmedida_pesoliqpadrao4: '',
		peso_unidmedida_pesobrutopadrao4: '',
		codfilial: ''
	});

	const CapturarDados = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	};
	const embMaiusculo = (event: any) => {
		event.preventDefault();
		setInputs(state => {
			return {
				...state,
				codembalagem: event.target.value.toUpperCase()
			}
		});
	};

	const descrMaiusculo = (event: any) => {
		event.preventDefault();
		setInputs(state => {
			return {
				...state,
				descricao: event.target.value.toUpperCase()
			}
		});
	};


	//#region Carregar/Selecionar Unidade de Medida 1
	const [unidademedida1, setUnidademedida1] = useState([])
	const [selecionaUnidademedida1, setSelecionaUnidademedida1] = useState(0);
	const capturaUnidademedida1 = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;

		const descUnidMedida1 = event.target.selectedOptions[0].innerText;
		setInputs(states => {
			return {
				...states,
				dimensao_unidmedida1: descUnidMedida1,
				peso_unidmedida1: descUnidMedida1
			}
		})
		setSelecionaUnidademedida1(value);

	};


	//#region Requisição de dados na api - CARREGAR UNIDADE DE MEDIDA
	const carregarDadosUnidMedida1 = async () => {
		const response = await axios.get(Api + `/embalagem/unidademedida1/json/?tabela=UNIMED&auth=${token}`);
		setUnidademedida1(response.data);
	};
	const BuscarUnidademedida1 = () => {
		carregarDadosUnidMedida1();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Unidade de Medida 2
	const [unidademedida2, setUnidademedida2] = useState([])
	const [selecionaUnidademedida2, setSelecionaUnidademedida2] = useState(0);
	const capturaUnidademedida2 = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		const descUnidMedida2 = event.target.selectedOptions[0].innerText;
		setInputs(states => {
			return {
				...states,
				dimensao_unidmedida2: descUnidMedida2,
				peso_unidmedida2: descUnidMedida2
			}
		})
		setSelecionaUnidademedida2(value);
	};

	//#region Requisição de dados na api - CARREGAR UNIDADE DE MEDIDA
	const carregarDadosUnidMedida2 = async () => {
		const response = await axios.get(Api + `/embalagem/unidademedida2/json/?tabela=UNIMED&auth=${token}`);
		setUnidademedida2(response.data);
	};
	const BuscarUnidademedida2 = () => {
		carregarDadosUnidMedida2();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Unidade de Medida 3
	const [unidademedida3, setUnidademedida3] = useState([])
	const [selecionaUnidademedida3, setSelecionaUnidademedida3] = useState(0);
	const capturaUnidademedida3 = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		const descUnidMedida3 = event.target.selectedOptions[0].innerText;
		setInputs(states => {
			return {
				...states,
				dimensao_unidmedida3: descUnidMedida3,
				peso_unidmedida3: descUnidMedida3
			}
		})
		setSelecionaUnidademedida3(value);
	};

	//#region Requisição de dados na api - CARREGAR UNIDADE DE MEDIDA
	const carregarDadosUnidMedida3 = async () => {
		const response = await axios.get(Api + `/embalagem/unidademedida3/json/?tabela=UNIMED&auth=${token}`);
		setUnidademedida3(response.data);
	};
	const BuscarUnidademedida3 = () => {
		carregarDadosUnidMedida3();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Unidade de Medida 4
	const [unidademedida4, setUnidademedida4] = useState([])
	const [selecionaUnidademedida4, setSelecionaUnidademedida4] = useState(0);
	const capturaUnidademedida4 = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		const descUnidMedida4 = event.target.selectedOptions[0].innerText;
		setInputs(states => {
			return {
				...states,
				dimensao_unidmedida4: descUnidMedida4,
				peso_unidmedida4: descUnidMedida4
			}
		})
		setSelecionaUnidademedida4(value);
	};

	//#region Requisição de dados na api - CARREGAR UNIDADE DE MEDIDA
	const carregarDadosUnidMedida4 = async () => {
		const response = await axios.get(Api + `/embalagem/unidademedida4/json/?tabela=UNIMED&auth=${token}`);
		setUnidademedida4(response.data);
	};
	const BuscarUnidademedida4 = () => {
		carregarDadosUnidMedida4();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Temperatura
	const [temperatura, setTemperatura] = useState([])
	const [selecionaTemperatura, setSelecionaTemperatura] = useState(0);
	const capturaTemperatura = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaTemperatura(value);
	};

	//#region Requisição de dados na api - CARREGAR TEMPERATURA
	const carregarDadosTemperatura = async () => {
		const response = await axios.get(Api + `/embalagem/temperatura/json/?tabela=TEMP&auth=${token}`);
		setTemperatura(response.data);
	};
	const BuscarTemperatura = () => {
		carregarDadosTemperatura();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Unidmanuseio
	const [unidmanuseio, setUnidmanuseio] = useState([])
	const [selecionaUnidmanuseio, setSelecionaUnidmanuseio] = useState(0);
	const capturaUnidmanuseio = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaUnidmanuseio(value);
	};

	//#region Requisição de dados na api - CARREGAR UNIDMANUSEIO
	const carregarDadosUnidmanuseio = async () => {
		const response = await axios.get(Api + `/embalagem/unidmanuseio/json/?tabela=UNIDMAN&auth=${token}`);
		setUnidmanuseio(response.data);
	};
	const BuscarUnidmanuseio = () => {
		carregarDadosUnidmanuseio();
	};
	//#endregion
	//#endregion

	//#region Carregar/Selecionar Tipopeso
	const [tipopeso, setTipopeso] = useState([])
	const [selecionaTipopeso, setSelecionaTipopeso] = useState(0);
	const capturaTipopeso = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setSelecionaTipopeso(value);
	};

	//#region Requisição de dados na api - CARREGAR TIPOPESO
	const carregarDadosTipopeso = async () => {
		const response = await axios.get(Api + `/embalagem/tipopeso/json/?tabela=TIPOPESO&auth=${token}`);
		setTipopeso(response.data);
	};
	const BuscarTipopeso = () => {
		carregarDadosTipopeso();
	};
	//#endregion
	//#endregion

	//#region ENVIAR - POST - INSERT Embalagens
	const postDados = (e: any) => {
		try {
			setShowCarregando(true)
			axios.post(Api + `/embalagem/?auth=${token}`, {
				codembalagem: inputs.codembalagem.toUpperCase(),
				descricao: inputs.descricao.toUpperCase(),
				codunidademedida1: selecionaUnidademedida1,
				qtdunidmedida1: inputs.qtdunidmedida1,
				codunidademedida2: selecionaUnidademedida2,
				qtdunidmedida2: inputs.qtdunidmedida2,
				codunidademedida3: selecionaUnidademedida3,
				qtdunidmedida3: inputs.qtdunidmedida3,
				codunidademedida4: selecionaUnidademedida4,
				qtdunidmedida4: inputs.qtdunidmedida4,
				regra_idtemperatura: selecionaTemperatura,
				regra_idunidmanuseio: selecionaUnidmanuseio,
				regra_idtipopeso: selecionaTipopeso,
				regra_fatorfiscal: inputs.regra_fatorfiscal,
				regra_comprimento: inputs.regra_comprimento,
				regra_altura: inputs.regra_altura,
				regra_largura: inputs.regra_largura,
				regra_pesoemb: inputs.regra_pesoemb,
				regra_prazovalidade: inputs.regra_prazovalidade,
				regra_pesoliquido: inputs.regra_pesoliquido,
				regra_lastro: inputs.regra_lastro,
				regra_camada: inputs.regra_camada,
				dimensao_unidmedida_comprimento1: inputs.dimensao_unidmedida_comprimento1,
				dimensao_unidmedida_largura1: inputs.dimensao_unidmedida_largura1,
				dimensao_unidmedida_altura1: inputs.dimensao_unidmedida_altura1,
				dimensao_unidmedida_comprimento2: inputs.dimensao_unidmedida_comprimento2,
				dimensao_unidmedida_largura2: inputs.dimensao_unidmedida_largura2,
				dimensao_unidmedida_altura2: inputs.dimensao_unidmedida_altura2,
				dimensao_unidmedida_comprimento3: inputs.dimensao_unidmedida_comprimento3,
				dimensao_unidmedida_largura3: inputs.dimensao_unidmedida_largura3,
				dimensao_unidmedida_altura3: inputs.dimensao_unidmedida_altura3,
				dimensao_unidmedida_comprimento4: inputs.dimensao_unidmedida_comprimento4,
				dimensao_unidmedida_largura4: inputs.dimensao_unidmedida_largura4,
				dimensao_unidmedida_altura4: inputs.dimensao_unidmedida_altura4,
				peso_unidmedida_pesoliqpadrao1: inputs.peso_unidmedida_pesoliqpadrao1,
				peso_unidmedida_pesobrutopadrao1: inputs.peso_unidmedida_pesobrutopadrao1,
				peso_unidmedida_pesoliqpadrao2: inputs.peso_unidmedida_pesoliqpadrao2,
				peso_unidmedida_pesobrutopadrao2: inputs.peso_unidmedida_pesobrutopadrao2,
				peso_unidmedida_pesoliqpadrao3: inputs.peso_unidmedida_pesoliqpadrao3,
				peso_unidmedida_pesobrutopadrao3: inputs.peso_unidmedida_pesobrutopadrao3,
				peso_unidmedida_pesoliqpadrao4: inputs.peso_unidmedida_pesoliqpadrao4,
				peso_unidmedida_pesobrutopadrao4: inputs.peso_unidmedida_pesobrutopadrao4,
				usuario: usuario,
				codfilial: codfilial

			}).then((response) => {
				return response.data
			}).then(data => {
				if (data.status == 201) {
					setTimeout(() => navigate(-1), 500);
					setShowCarregando(false)
				}
				else {
					setShowCarregando(false)
					toast.error(data.message, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "dark",
					})
					//alert(data.message)
				}
			}).catch(err => {
				setShowCarregando(false)
				toast.error(err, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "dark",
				})
			})
		} catch (error) {
			setShowCarregando(false);
			toast.error(`${error}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
		}
	};
	//#endregion


	const EnviarDados = (event: any) => {
		event.preventDefault();

		if (idembalagem.id === 'novo') {
			postDados(inputs);
		}
	}


	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Nova Embalagem</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form onSubmit={EnviarDados}>
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralEmb" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#regrasEmb" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0">
												<i className="mdi mdi-check-all d-md-none d-block"></i>
												<span className="d-none d-md-block">Regras</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#dimEmb" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-move-resize-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Dimensões</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="#pesEmb" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
												<i className="mdi mdi-weight-kilogram d-md-none d-block"></i>
												<span className="d-none d-md-block">Pesos</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralEmb">
											<div className="row">
												<div className="col-sm">
													<div className="mb-1 form-floating">

														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize bordasInferiorInput text-black text-capitalize"
															placeholder="Nome da embalagem"
															id="codembalagem"
															name="codembalagem"
															value={inputs.codembalagem}
															onChange={CapturarDados}
															onBlur={embMaiusculo}
															required
														/>
														<label className="form-label" htmlFor="embalagem">
															Embalagem
														</label>
													</div>
													<div className="mb-1 form-floating">
														<label className="form-label" htmlFor="descricao">
															Descrição
														</label>
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															placeholder="Informe a descrição"
															id="descricao"
															name="descricao"
															value={inputs.descricao}
															onChange={CapturarDados}
															onBlur={descrMaiusculo}
															required
														/>

													</div>

												</div>
												<div className="col-sm">

													<div className="mb-1">
														<label className="form-label">Unid. de medida</label>
													</div>

													<div className="mb-1 form-group form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarUnidademedida1} title={"Tabela: UNIMED\n* Qtde. Unid. Principal"} id="unidademedida_1"
															value={selecionaUnidademedida1} onChange={capturaUnidademedida1}
															required>
															{
																unidademedida1.map(function (dados: any) {
																	return (
																		<option value={dados.idunidademedida1}>{dados.unidademedida1}</option>
																	)
																})
															}
														</select>

													</div>
													<div className="mb-1 form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarUnidademedida2} title={"Tabela: UNIMED\n* Qtde. Embalagem Interna"} id="unidademedida_2"
															value={selecionaUnidademedida2} onChange={capturaUnidademedida2}
															required>
															{
																unidademedida2.map(function (dados: any) {
																	return (
																		<option value={dados.idunidademedida2}>{dados.unidademedida2}</option>
																	)
																})
															}

														</select>
													</div>
													<div className="mb-1 form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarUnidademedida3} title={"Tabela: UNIMED\n* Qtde. por Caixa"} id="unidademedida_3"
															value={selecionaUnidademedida3} onChange={capturaUnidademedida3}
															required>
															{
																unidademedida3.map(function (dados: any) {
																	return (
																		<option value={dados.idunidademedida3}>{dados.unidademedida3}</option>
																	)
																})
															}

														</select>
													</div>
													<div className="mb-1 form-floating">
														<select className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarUnidademedida4} title={"Tabela: UNIMED\n* Qtde. por Palete"} id="unidademedida_4"
															value={selecionaUnidademedida4} onChange={capturaUnidademedida4}
															required>
															{
																unidademedida4.map(function (dados: any) {
																	return (
																		<option value={dados.idunidademedida4}>{dados.unidademedida4}</option>
																	)
																})
															}

														</select>
													</div>
												</div>
												<div className="col-sm">
													<div className="mb-1">
														<label className="form-label" htmlFor="embalagem">
															Quantidade por Unidade por Medida
														</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="qtdunidmedida1"
															name="qtdunidmedida1"
															value={inputs.qtdunidmedida1}
															onChange={CapturarDados}
															required />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="qtdunidmedida2"
															name="qtdunidmedida2"
															value={inputs.qtdunidmedida2}
															onChange={CapturarDados}
															required />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="qtdunidmedida3"
															name="qtdunidmedida3"
															value={inputs.qtdunidmedida3}
															onChange={CapturarDados}
															required />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="qtdunidmedida4"
															name="qtdunidmedida4"
															value={inputs.qtdunidmedida4}
															onChange={CapturarDados}
															required />
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane" id="regrasEmb">
											<div className="row mb-1">
												<div className="col-sm-3 form-floating">

													<select placeholder='' className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarTemperatura} title="Tabela: TEMP" id="temperatura"
														value={selecionaTemperatura} onChange={capturaTemperatura}>
														{
															temperatura.map(function (dados: any) {
																return (
																	<option value={dados.idtemperatura}>{dados.temperatura}</option>
																)
															})
														}

													</select>
													<label className="form-label">Temperatura</label>
												</div>
												<div className="col-sm-3 form-floating">

													<select placeholder='' className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarUnidmanuseio} title="Tabela: UNIDMAN" id="temperatura"
														value={selecionaUnidmanuseio} onChange={capturaUnidmanuseio}>
														{
															unidmanuseio.map(function (dados: any) {
																return (
																	<option value={dados.idunidmanuseio}>{dados.unidmanuseio}</option>
																)
															})
														}
													</select>
													<label className="form-label">Unid. Manuseio</label>
												</div>
												<div className="col-sm-3 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_pesoemb"
														name="regra_pesoemb"
														placeholder=''
														value={inputs.regra_pesoemb}
														onChange={CapturarDados} />
													<label className="form-label">Peso Embalagem</label>
												</div>
												<div className="col-sm-3 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize"
														id="regra_prazovalidade"
														name="regra_prazovalidade"
														placeholder=''
														value={inputs.regra_prazovalidade}
														onChange={CapturarDados} />
													<label className="form-label">Prazo Validade</label>
												</div>
											</div>
											<div className="row mb-1">
												<div className="col-sm-3 form-floating">

													<select placeholder='' className="form-select bordasInferiorInput text-black text-capitalize" onClick={BuscarTipopeso} title="Tabela: TIPOPESO" id="temperatura"
														value={selecionaTipopeso} onChange={capturaTipopeso}>
														{
															tipopeso.map(function (dados: any) {
																return (
																	<option value={dados.idtipopeso}>{dados.tipopeso}</option>
																)
															})
														}
													</select>
													<label className="form-label">Tipo Peso</label>
												</div>
												<div className="col-sm-3 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_fatorfiscal"
														name="regra_fatorfiscal"
														placeholder=''
														value={inputs.regra_fatorfiscal}
														onChange={CapturarDados} />
													<label className="form-label">Fator Fiscal</label>
												</div>
												<div className="col-sm-3 form-floating">


													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_pesoliquido"
														name="regra_pesoliquido"
														placeholder=''
														value={inputs.regra_pesoliquido}
														onChange={CapturarDados} />
													<label className="form-label">Peso Liquido</label>
												</div>
											</div>
											<div className="row mb-1">
												<div className="col-sm-2 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_comprimento"
														name="regra_comprimento"
														placeholder=''
														value={inputs.regra_comprimento}
														onChange={CapturarDados} />
													<label className="form-label">Comprimento</label>
												</div>
												<div className="col-sm-2 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_altura"
														name="regra_altura"
														value={inputs.regra_altura}
														placeholder=''
														onChange={CapturarDados} />
													<label className="form-label">Altura</label>
												</div>
												<div className="col-sm-2 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_largura"
														name="regra_largura"
														value={inputs.regra_largura}
														placeholder=''
														onChange={CapturarDados} />
													<label className="form-label">Largura</label>
												</div>
												<div className="col-sm-2 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_lastro"
														name="regra_lastro"
														value={inputs.regra_lastro}
														placeholder=''
														onChange={CapturarDados} />
													<label className="form-label">Lastro</label>
												</div>
												<div className="col-sm-2 form-floating">

													<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
														id="regra_camada"
														name="regra_camada"
														value={inputs.regra_camada}
														placeholder=''
														onChange={CapturarDados} />
													<label className="form-label">Camada</label>
												</div>
											</div>
										</div>
										<div className="tab-pane" id="dimEmb">
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1">
														<label className="form-label">Unidade por Medida</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="dimensao_unidmedida1"
															name="dimensao_unidmedida1"
															value={inputs.dimensao_unidmedida1}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="dimensao_unidmedida2"
															name="dimensao_unidmedida2"
															value={inputs.dimensao_unidmedida2}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="dimensao_unidmedida3"
															name="dimensao_unidmedida3"
															value={inputs.dimensao_unidmedida3}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="dimensao_unidmedida4"
															name="dimensao_unidmedida4"
															value={inputs.dimensao_unidmedida4}
															disabled />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1">
														<label className="form-label">Comprimento</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_comprimento1"
															name="dimensao_unidmedida_comprimento1"
															value={inputs.dimensao_unidmedida_comprimento1}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_comprimento2"
															name="dimensao_unidmedida_comprimento2"
															value={inputs.dimensao_unidmedida_comprimento2}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_comprimento3"
															name="dimensao_unidmedida_comprimento3"
															value={inputs.dimensao_unidmedida_comprimento3}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_comprimento4"
															name="dimensao_unidmedida_comprimento4"
															value={inputs.dimensao_unidmedida_comprimento4}
															onChange={CapturarDados} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1">
														<label className="form-label">Largura</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_largura1"
															name="dimensao_unidmedida_largura1"
															value={inputs.dimensao_unidmedida_largura1}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_largura2"
															name="dimensao_unidmedida_largura2"
															value={inputs.dimensao_unidmedida_largura2}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_largura3"
															name="dimensao_unidmedida_largura3"
															value={inputs.dimensao_unidmedida_largura3}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_largura4"
															name="dimensao_unidmedida_largura4"
															value={inputs.dimensao_unidmedida_largura4}
															onChange={CapturarDados} />
													</div>
												</div>
												<div className="col-sm-2">
													<div className="mb-1 ">
														<label className="form-label">Altura</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_altura1"
															name="dimensao_unidmedida_altura1"
															value={inputs.dimensao_unidmedida_altura1}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_altura2"
															name="dimensao_unidmedida_altura2"
															value={inputs.dimensao_unidmedida_altura2}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_altura3"
															name="dimensao_unidmedida_altura3"
															value={inputs.dimensao_unidmedida_altura3}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="dimensao_unidmedida_altura4"
															name="dimensao_unidmedida_altura4"
															value={inputs.dimensao_unidmedida_altura4}
															onChange={CapturarDados} />
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane" id="pesEmb">
											<div className="row">
												<div className="col-sm-6">
													<div className="mb-1">
														<label className="form-label">Unidade por Medida</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="peso_unidmedida1"
															name="peso_unidmedida1"
															value={inputs.peso_unidmedida1}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="peso_unidmedida2"
															name="peso_unidmedida2"
															value={inputs.peso_unidmedida2}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="peso_unidmedida3"
															name="peso_unidmedida3"
															value={inputs.peso_unidmedida3}
															disabled />
													</div>
													<div className="mb-1 form-floating">
														<input type="text" className="form-control bordasInferiorInput text-black text-capitalize"
															id="peso_unidmedida4"
															name="peso_unidmedida4"
															value={inputs.peso_unidmedida4}
															disabled />
													</div>
												</div>
												<div className="col-sm-3">
													<div className="mb-1 ">
														<label className="form-label">Peso Liq Padrão</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesoliqpadrao1"
															name="peso_unidmedida_pesoliqpadrao1"
															value={inputs.peso_unidmedida_pesoliqpadrao1}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesoliqpadrao2"
															name="peso_unidmedida_pesoliqpadrao2"
															value={inputs.peso_unidmedida_pesoliqpadrao2}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesoliqpadrao3"
															name="peso_unidmedida_pesoliqpadrao3"
															value={inputs.peso_unidmedida_pesoliqpadrao3}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesoliqpadrao4"
															name="peso_unidmedida_pesoliqpadrao4"
															value={inputs.peso_unidmedida_pesoliqpadrao4}
															onChange={CapturarDados} />
													</div>
												</div>
												<div className="col-sm-3">
													<div className="mb-1">
														<label className="form-label">Peso Bruto Padrão</label>
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesobrutopadrao1"
															name="peso_unidmedida_pesobrutopadrao1"
															value={inputs.peso_unidmedida_pesobrutopadrao1}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesobrutopadrao2"
															name="peso_unidmedida_pesobrutopadrao2"
															value={inputs.peso_unidmedida_pesobrutopadrao2}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesobrutopadrao3"
															name="peso_unidmedida_pesobrutopadrao3"
															value={inputs.peso_unidmedida_pesobrutopadrao3}
															onChange={CapturarDados} />
													</div>
													<div className="mb-1 form-floating">
														<input type="number" className="form-control bordasInferiorInput text-black text-capitalize" step="0.01"
															id="peso_unidmedida_pesobrutopadrao4"
															name="peso_unidmedida_pesobrutopadrao4"
															value={inputs.peso_unidmedida_pesobrutopadrao4}
															onChange={CapturarDados} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer mt-2">
									<div className="col-sm">
										<div className="text-sm-end">
											<button
												className="btn btn-cancelar mb-2 me-1"
												type="button"
												onClick={() => navigate('/config/cadast/embalagem')}
											>
												Cancelar
											</button>
											<button
												className="btn btn-novo mb-2 me-1"
												type="submit"
											>
												Salvar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<ToastContainer newestOnTop />
			</div>
			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>
		</>
	);
};

export { NovaEmbalagem };
