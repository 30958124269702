import { useNavigate } from 'react-router-dom';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

function ListaFiliais() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;	
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarUsuariosFilial) {
				toast.warning('Você não tem permissão para acessar a lista de Usuários por Filial');
				navigate('/');
			}
		}
	}, [permissoes, erro, navigate]);

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);

	const colunas: ITabela[] = [
		{
			titulo: '',
			acesso: 'avatar',
		},
		{
			titulo: 'ID Filial',
			acesso: 'id_filial',
		},
		{
			titulo: 'Fantasia',
			acesso: 'fantasia',
		},
		{
			titulo: 'CNPJ',
			acesso: 'cnpj',
		},
		{
			titulo: 'Total Usuário',
			acesso: 'total_usuarios',
		},
		{
			titulo: 'Usuarios Ativos',
			acesso: 'usuarios_ativos',
		},
	];

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const [ListaFiliais, setListaFiliais] = useState([]);

	function CarregarFiliais(NivelPerfil: string) {
		setShowCarregando(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/load/filais/usuers/${NivelPerfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setListaFiliais(resposta.data.filais);
				setShowCarregando(false);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	useEffect(function () {
		CarregarFiliais(sessionStorage.getItem('NivelPerfil')!);
	}, []);
	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Lista de Filiais</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<ClickableTable
						coluna={colunas}
						itemsPerPage={itensPorPagina}
						data={ListaFiliais}
						onRowClick={function () {}}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function (dado: any) {
							navigate(`/config/segur/usuarios/filial/${dado.id_filial}`);
						}}
						usaAcoes
						usaEditar={false}
						usaExcluir={false}
						usaVisualizar
						acessopesquisa="fantasia"
						labelpesquisa="Procurar por fantasia."
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mensagem="Carregando..." mostrar={showCarregando} />
		</div>
	);
}

export default ListaFiliais;
