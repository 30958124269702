import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NovoCadastroTarifas = (): JSX.Element => {
	const navigate = useNavigate();

	const [ativo, setAtivo] = useState(true);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Novo Cliente</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
								<li className="nav-item">
									<a href="#geralTArifa" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">Geral</span>
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane show active" id="geralTArifa">
									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="clienteTarifa">
													Cliente
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="clienteTarifa">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="familia">
													Família Serv.
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="familia">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="servicoTarifa">
													Serviço
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="servicoTarifa">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="unCob">
													UN. Cobrança
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="unCob">
													<option value="">---</option>
												</select>
											</div>
										</div>
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="perioCob">
													Periodic. Cobrança
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="perioCob">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="id2">
													ID
												</label>
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="id2" readOnly />
											</div>
										</div>
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="incidencia">
													Incidencia
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="incidencia">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="impres">
													Seq. Impressão
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="impres">
													<option value="">---</option>
												</select>
											</div>
										</div>
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="tarifa2">
													Tarifa
												</label>
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="tarifa2">
													<option value="">---</option>
												</select>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="mb-1">
												<label className="form-label" htmlFor="franquiaAr">
													Franquia Armaz.
												</label>
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="franquiaAr" value="0" readOnly />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<br />
											<div className="mb-1">
												<label className="form-label" htmlFor="cke1">
													CROSS por Data Emissão
												</label>
												<input id="cke1" type="checkbox" disabled />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<br />
											<div className="mb-1">
												<label className="form-label" htmlFor="cke2">
													Para Excedente Somar Volume de CROSS
												</label>
												<input id="cke2" type="checkbox" disabled />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<div className="col">
												<br />
												<div className="mb-1">
													<label className="form-label" htmlFor="cke3">
														Ativo
													</label>
													<input id="cke3" type="checkbox" checked={ativo} onClick={() => setAtivo(!ativo)} />
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-danger mb-2 me-1"
										type="button"
										onClick={() => navigate('/faturamento/manuntencao/cadastroTarifas')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-info mb-2 me-1"
										type="button"
										onClick={() => navigate('/faturamento/manuntencao/cadastroTarifas')}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NovoCadastroTarifas };
