import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmacao from '../../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import CarregarPermissoes from '../../../../../hooks/carregaPermissoesUsuario/carregaPermissoes';
import ClickableTable from '../../../../../components/ClickableTable/ClickableTabl';
import ModalCarregando from '../../../../../components/ModalCarregando/ModalCarregando';
import { ITabela } from '../../../../../components/Table/TabelaInterface';
import { BuscarPermissoesUsuario } from '../../../../../hooks/buscarPermissoesUsuario';
function ProcessosDeEntrada() {

    const token = sessionStorage.getItem('IsFKLTOKEN')!;
    const id_filial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const id_perfil = sessionStorage.getItem("IsIdPerfilFKL")!
    const nivelPerfil = sessionStorage.getItem("NivelPerfil")!
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")!

    const navigate = useNavigate()

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoCancelar, setPermissaoCancelar] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarProcEntrada) {
				toast.warning('Você não tem permissão para acessar Lista de Processos de Entrada.');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarProcEntrada);
				setPermissaoCriar(permissoes.perfilPermissao.criarProcEntrada);
				setPermissaoEditar(permissoes.perfilPermissao.editarProcEntrada);
				setPermissaoCancelar(permissoes.perfilPermissao.cancelarProcEntrada);
			}
		}
	}, [permissoes, erro, navigate]);

    const [showCarregando, setShowCarregando] = useState(false)

    const [ListaDeProcessos, setListaDeProcessos] = useState([])
    const [itensPorPagina, setItensPorPagina] = useState(10)
    const ColunasProcessos: ITabela[] = [
        {
            titulo: "Processo", acesso: "id_processo"
        },
        {
            titulo: "Depositante", acesso: "fantasia"
        },
        {
            titulo: "Tipo", acesso: "tipo_operacao"
        },
        {
            titulo: "Chegada", acesso: "dt_chegada"
        },
        {
            titulo: "Modalidade", acesso: "modalidade"
        },
        {
            titulo: "Doca", acesso: "doca"
        },
        {
            titulo: "Status", acesso: "status"
        }
    ]

    function CarregarProcessos(id_filial: string) {

        setShowCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/processos/entrada/${id_filial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {

            setShowCarregando(false)
            setListaDeProcessos(resposta.data.processos)
        }).catch(function (erro) {

            setShowCarregando(false)
            toast.error(erro.response.data.message || erro.message, {
                autoClose: 900
            })
        })
    }

    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)

    useEffect(function () {

        CarregarProcessos(id_filial)
    }, [])

    const [processoCancelar, setProcessoCancelar] = useState<any>()
    function CancelarProcesso(id_filial: string, id_processo: string, id_cliente: string) {

        setShowModalConfirmacao(false)

        if (processoCancelar.status != "Cancelado") {

            setShowCarregando(true)
            axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/processo/${id_filial}/${id_processo}/${id_cliente}`, {
                headers: {
                    authorization: token
                }
            }).then(function (resposta) {

                setShowCarregando(false)
                CarregarProcessos(id_filial)
                toast.success(resposta.data.message, {
                    autoClose: 900
                })
            }).catch(function (erro) {

                setShowCarregando(false)
                toast.error(erro.response.data.message || erro.message, {
                    autoClose: 900
                })
            })
        }
        else {

            toast.info("Processo já cancelado.", {
                autoClose: 900
            })
        }
    }

    return (
        <div className="main">
            <div className='tituloDaPagina mt-2 ms-1'>
                <h4>Processos de Entrada</h4>
            </div>
            <div className="card mt-2 pb-3">
                <div className="card-body">
                    <div className='container p-0 m-0'>
                        <div className='row'>
                            <div className="col-sm col-md col-lg-4 d-flex">
                                {permissaoCriar && <button onClick={function () {
                                    navigate("/controle/processo/entrada/criar/novo")
                                }} className="btn btn-primary me-2">
                                    <i className="bi bi-plus-circle me-2"></i>
                                    Novo Processo
                                </button>}
                                <div className="dropdown show">

                                    <a className="btn btn-danger dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="bi bi-view-list me-2"></i>
                                        Mais Funções
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ClickableTable
                        coluna={ColunasProcessos}
                        itemsPerPage={itensPorPagina}
                        data={ListaDeProcessos}
                        usaRowClick={false}
                        onRowClick={function () {

                        }}
                        acaoEditar={function (processo: any) {
                            if (processo.status == "Cancelado") {
                                toast.info("Não é possível editar processos já cancelados", {
                                    autoClose: 2000
                                })
                            }
                            else {
                                navigate(`/controle/processo/entrada/editar/${processo.id_processo}`)
                            }
                        }}
                        acaoExcluir={function (processo: any) {

                            setShowModalConfirmacao(true)
                            setProcessoCancelar(processo)
                        }}
                        acaoVisualizar={function (processo: any) {

                            navigate(`/controle/processo/entrada/visualizar/${processo.id_processo}`)
                        }}
                        usaAcoes={true}
                        usaEditar={permissaoEditar}
                        usaExcluir={permissaoCancelar}
                        usaVisualizar={permissaoVisualizar}
                        corIconeExcluir='bi bi-ban text-danger'
                        acessopesquisa='id_processo'
                        labelpesquisa='Procurar por processo.'
                    />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2 m-auto text-center'>
                                <label>Itens por Página</label>
                            </div>
                            <div className='w-100'></div>
                            <div className='col-lg-1 m-auto'>

                                <select value={itensPorPagina}
                                    onChange={function (e: any) {
                                        setItensPorPagina(e.target.value)
                                    }}
                                    className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ModalCarregando mostrar={showCarregando} mensagem='Carregando...' />

            <ModalConfirmacao
                mostrar={showModalConfirmacao}
                msgAcao1='Cancelar'
                msgAcao2='Confirmar'
                msgPrincipal='Confirmar cancelamento do processo ?'
                Acao1={function () {
                    setShowModalConfirmacao(false)
                    setProcessoCancelar([])
                }}
                Acao2={function () {
                    CancelarProcesso(processoCancelar.id_filial, processoCancelar.id_processo, processoCancelar.id_cliente)
                }}
            />
        </div>
    )
}


export default ProcessosDeEntrada