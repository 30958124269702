import { FC } from 'react';

interface Titulo {
	titulo: string;
}

const Titulo: FC<Titulo> = ({ titulo }) => {
	return (
		<>
			<div className="row">
				<div className="col-sm-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">{titulo}</h4>
					</div>
				</div>
			</div>
		</>
	);
};
export default Titulo;
