import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';
import CheckBox from '../../../../components/componentes/formulario/Checkbox';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
import { calcularQuantidadeEnderecos } from './components/verificaQtdEndereco';
import removeEspacoTextoMin from '../../../../components/componentes/types/removeEspacoTextoMin';
const NovoEnderecoArmazem = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_local = params.id || '';
	const acao = params.acao || '';

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	const [msgCarregando, setMsgCarregando] = useState('');
	//#endregion

	//#endregion
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');

	// dados do local
	const [tipoLocal, setTipoLocal] = useState<string>('');
	const [temperatura, setTemperatura] = useState<string>('');
	const [situacao, setSituacao] = useState<boolean>(true);
	const [usaMultiploUA, setUsaMultiploUA] = useState<boolean>(false);
	const [armazem, setArmazem] = useState<string>('');
	const [corredor, setCorredor] = useState<string>('');
	const [boxinicio, setBoxInicio] = useState<string>('');
	const [boxfim, setBoxFim] = useState<string>('');
	const [alturainicio, setAlturaInicio] = useState<string>('');
	const [alturafim, setAlturaFim] = useState<string>('');
	const [profundidadeinicio, setProfundidadeInicio] = useState<string>('');
	const [profundidadefim, setProfundidadeFim] = useState<string>('');
	const [seq_sep, setSeqSep] = useState<string>('');

	const selecionaTipoLocal = (dados: any) => {
		const idtipolocal = dados;

		setTipoLocal(idtipolocal);
		if (idtipolocal === 'Picking') {
			setUsaMultiploUA(true);
		}
	};

	//#region -> Função para buscar o cadastro de tipo de local para alimentar o select/option
	const [dadosTipoLocal, setDadosTipoLocal] = useState([]);
	async function ListarTipoLocal() {
		axios
			.get(Api + `/lista/enderecoarm/tipolocal/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTipoLocal(resposta.data.tipo_local);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region -> Função para buscar o cadastro de temperatura  para alimentar o select/option
	const [dadosTemperatura, setDadosTemperatura] = useState([]);
	async function ListarTemperatura() {
		axios
			.get(Api + `/lista/enderecoarm/temperatura/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTemperatura(resposta.data.temperatura);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	const dadosDeEnvio = {
		tipoLocal,
		temperatura,
		usaMultiploUA: usaMultiploUA ? 1 : 0,
		situacao: situacao ? 1 : 0,
		armazem,
		corredor,
		boxinicio,
		boxfim,
		alturainicio,
		alturafim,
		profundidadeinicio,
		profundidadefim,
		seq_sep,
		usuario: usuarioLogado,
	};

	//#region -> Função para inserir um novo endereço
	function insereNovoEndereco() {
		setMsgAcao1('');
		setMostraModalConfirm(false);
		setShowCarregando(true);
		setMsgCarregando('Aguarde, Inserindo novos endereços...');
		axios
			.post(Api + `/insere/endereco/${id_filial}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setMsgCarregando('');
				toast.success(resposta.data.message);
				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				setMsgCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#region -> Editar
	function carregaDados() {
		setShowCarregando(true);
		axios
			.get(Api + `/carrega/dados/endereco/edit/${id_filial}/${id_local}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);

				setTipoLocal(resposta.data.endereco[0].tipo);
				setTemperatura(resposta.data.endereco[0].temperatura);
				setSituacao(resposta.data.endereco[0].ativo);
				setUsaMultiploUA(resposta.data.endereco[0].multi);
				setArmazem(resposta.data.endereco[0].amz);
				setCorredor(resposta.data.endereco[0].cor);
				setBoxInicio(resposta.data.endereco[0].box);
				setBoxFim(resposta.data.endereco[0].box);
				setAlturaInicio(resposta.data.endereco[0].alt);
				setAlturaFim(resposta.data.endereco[0].alt);
				setProfundidadeInicio(resposta.data.endereco[0].prof);
				setProfundidadeFim(resposta.data.endereco[0].prof);
				setSeqSep(resposta.data.endereco[0].seq_sep);
				ListarTemperatura();
				ListarTipoLocal();
			})
			.catch(function (erro) {
				setShowCarregando(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function editarEndereco() {
		axios
			.put(Api + `/editar/endereco/${id_filial}/${id_local}`, dadosDeEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);

				setTimeout(() => {
					navigate(-1);
				}, 2000);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		if (acao !== 'criar') {
			carregaDados();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acao]);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo Endereço de Armazém'
									: acao === 'editar'
									? 'Editar Endereço de Armazém'
									: 'Visualizar Endereço de Armazém'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									const qtde = calcularQuantidadeEnderecos(
										parseInt(boxinicio),
										parseInt(boxfim),
										parseInt(alturainicio),
										parseInt(alturafim),
										parseInt(profundidadeinicio),
										parseInt(profundidadefim)
									);
									if (qtde > 1000) {
										setMostraModalConfirm(true);
										setMsgPrincipalModal(
											`Serão criados ${qtde} novos endereços, deseja continuar? Essa ação poderá demorar um pouco.`
										);
										setMsgAcao1('Cancelar');
									} else {
										insereNovoEndereco();
									}
								} else if (acao === 'editar') {
									editarEndereco();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_local"
															name="id_local"
															value={id_local}
															disabled
														/>
														<label className="form-label">ID Local</label>
													</div>
												</div>
												<div className="col-sm-2">
													<InputSelect
														label="Tipo de Local"
														value={tipoLocal}
														onChange={selecionaTipoLocal}
														onClick={ListarTipoLocal}
														dados={dadosTipoLocal}
														disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
														required
													/>
												</div>
												<div className="col-sm-3">
													<InputSelect
														label="Temperatura"
														value={temperatura}
														onChange={setTemperatura}
														onClick={ListarTemperatura}
														dados={dadosTemperatura}
														disabled={acao === 'criar' ? false : true}
														required
													/>
												</div>
												<div className="col-sm-3 pt-3">
													<div className="form-floating mb-1">
														<CheckBox
															label="Endereçar Múltiplas U.A."
															name="enderecar_multiplas_ua"
															checked={usaMultiploUA}
															onChange={setUsaMultiploUA}
															disabled={acao}
														/>
													</div>
												</div>
												<div className="col-sm-2 pt-3">
													<div className="form-floating mb-1">
														<CheckBox
															label="Ativo"
															name="ativo"
															checked={situacao}
															onChange={setSituacao}
															disabled={acao}
														/>
													</div>
												</div>
											</div>
											{acao === 'criar' ? (
												<>
													<div className="row">
														<div className="col-sm-3">
															<InputSemZeroEsquerda
																label="Armazém"
																id="armazem"
																name="armazem"
																maxLength={10}
																value={armazem}
																onChange={setArmazem}
																required
																disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
															/>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Corredor"
																	id="corredor"
																	name="corredor"
																	maxLength={10}
																	value={corredor}
																	onChange={setCorredor}
																	required
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																/>
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Box Início"
																	id="boxinicio"
																	name="boxinicio"
																	type="number"
																	value={boxinicio}
																	onChange={setBoxInicio}
																	required
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																/>
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Box Fim"
																	id="boxfim"
																	type="number"
																	name="boxfim"
																	value={boxfim}
																	onChange={setBoxFim}
																	required
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																/>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Altura Início"
																	id="alturainicio"
																	name="alturainicio"
																	type="number"
																	value={alturainicio}
																	onChange={setAlturaInicio}
																	required
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																/>
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Altura Fim"
																	id="alturafim"
																	name="alturafim"
																	value={alturafim}
																	type="number"
																	onChange={setAlturaFim}
																	required
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																/>
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<input
																	type="number"
																	className="form-control bordasInferiorInput text-black text-capitalize"
																	id="codigo"
																	name="codigo"
																	value={profundidadeinicio}
																	onChange={(e) => setProfundidadeInicio(removeEspacoTextoMin(e.target.value))}
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																	required
																/>
																<label className="form-label">Profundidade Início</label>
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-floating mb-1">
																<input
																	type="number"
																	className="form-control bordasInferiorInput text-black text-capitalize"
																	id="codigo"
																	name="codigo"
																	value={profundidadefim}
																	onChange={(e) => setProfundidadeFim(removeEspacoTextoMin(e.target.value))}
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
																	required
																/>
																<label className="form-label">Profundidade Fim</label>
															</div>
														</div>
													</div>
												</>
											) : (
												<>
													<div className="row">
														<div className="col-sm-2">
															<InputSemZeroEsquerda
																label="Armazém"
																id="armazem"
																name="armazem"
																maxLength={10}
																value={armazem}
																onChange={setArmazem}
																disabled
															/>
														</div>
														<div className="col-sm-2">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Corredor"
																	id="corredor"
																	name="corredor"
																	maxLength={10}
																	value={corredor}
																	onChange={setCorredor}
																	disabled
																/>
															</div>
														</div>
														<div className="col-sm-2">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Box"
																	id="box"
																	name="box"
																	type="number"
																	value={boxinicio}
																	onChange={setBoxInicio}
																	disabled
																/>
															</div>
														</div>
														<div className="col-sm-2">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Altura"
																	id="altura"
																	type="number"
																	name="altura"
																	value={alturainicio}
																	onChange={setAlturaInicio}
																	disabled
																/>
															</div>
														</div>
														<div className="col-sm-2">
															<div className="form-floating mb-1">
																<InputSemZeroEsquerda
																	label="Profundidade"
																	id="profundidade"
																	type="number"
																	name="profundidade"
																	value={profundidadeinicio}
																	onChange={setProfundidadeInicio}
																	disabled
																/>
															</div>
														</div>
														<div className="col-sm-2">
															<div className="form-floating mb-1">
																<input
																	type="text"
																	className="form-control bordasInferiorInput text-black text-capitalize"
																	id="seq_sep"
																	name="seq_sep"
																	value={seq_sep}
																	onChange={(e) => setSeqSep(removeEspacoTextoMin(e.target.value))}
																	disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}	
																/>
																<label className="form-label">Seq. Separação</label>
															</div>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando show={showCarregando} mensagem={msgCarregando} />

			{/* MODAL CONFIRMACAO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => insereNovoEndereco()}
			/>
		</>
	);
};

export { NovoEnderecoArmazem };
