import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Api } from '../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import CheckBox from '../../../../components/componentes/formulario/Checkbox';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';
import DatalistInput from 'react-datalist-input';
const NovoCFOPEntradaxSaida = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL');

	const params = useParams();
	const id_cfop = params.id;
	const acao = params.acao || '';

	const navigate = useNavigate();

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	const [msgShowCarregando, setMsgShowCarregando] = useState('');
	//#endregion
	//#region Modifica o status do Modal Pesquisa

	//DADOS DO CFOP Entrada x Saída
	const [cfopEntrada, setCFOPEntrada] = useState<string>('');
	const [natureza_opEntrada, setNaturezaOpEntrada] = useState<string>('');
	const [natureza_opEntradaValor, setNaturezaOpEntradaValor] = useState<string>('');
	const [padrao, setPadrao] = useState<boolean>(false);
	const [cfopSaida, setCFOPSaida] = useState<string>('');
	const [natureza_opSaida, setNaturezaOpSaida] = useState<string>('');
	const [natureza_opSaidaValor, setNaturezaOpSaidaValor] = useState<string>('');

	const [focoCFOP_Entrada, setFocoCFOP_Entrada] = useState(false);
	const [focoCFOP_Saida, setFocoCFOP_Saida] = useState(false);

	//#region ==> Função para buscar os dados do CFOP
	async function carregarDados() {
		setShowCarregando(true);
		setMsgShowCarregando('Carregando dados do CFOP Entrada x Saída');
		await axios
			.get(`${Api}/carrega/cfopentsai/cfop/edit/${id_filial}/${id_cfop}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				setCFOPEntrada(response.data.cfop_entsai[0].cfop_ent);
				setCFOPSaida(response.data.cfop_entsai[0].cfop_sai);
				setPadrao(response.data.cfop_entsai[0].padrao);
				setNaturezaOpEntrada(response.data.cfop_entsai[0].natureza_op_ent);
				setNaturezaOpSaida(response.data.cfop_entsai[0].natureza_op_sai);
				setNaturezaOpEntradaValor(response.data.cfop_entsai[0].natureza_op_ent_valor);
				setNaturezaOpSaidaValor(response.data.cfop_entsai[0].natureza_op_sai_valor);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//Carregar dados da tabela ao abrir a tela
	useEffect(() => {
		if (acao !== 'criar') {
			carregarDados();
		}
	}, []);

	const dadosEnvio = {
		cfopEntrada,
		cfopSaida,		
		natureza_opSaida,
		padrao: padrao ? 1 : 0,
		usuario: usuarioLogado,
	};

	//#region  ==> Função para inserir novo cfop
	async function insereNovoCFOPEntradaxSaida() {
		setShowCarregando(true);
		setMsgShowCarregando('Inserindo novo CFOP Entrada x Saída');
		await axios
			.post(`${Api}/insere/cfopentsai/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para editar cfop
	async function editarCFOPEntradaSaida() {
		setShowCarregando(true);
		setMsgShowCarregando('Editando CFOP Entrada x Saída');
		await axios
			.put(`${Api}/editar/cfopentsai/${id_filial}/${id_cfop}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				toast.success(response.data.message);
				navigate(-1);
			})
			.catch((erro) => {
				setShowCarregando(false);
				setMsgShowCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	//#endregion

	//#region ==> Função para buscar os dados do CFOP de cfop entrada
	const [dadosCFOPEntrada, setDadosCFOPEntrada] = useState([]);
	async function carregarCfopEntrada(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;

		await axios
			.get(`${Api}/buscar/cfopentsai/entrada/${_filtro}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setDadosCFOPEntrada(response.data.cfop_entrada);
			})
			.catch((erro) => {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region ==> Função para buscar os dados do CFOP de cfop saida
	const [dadosCFOPSaida, setDadosCFOPSaida] = useState([]);
	async function carregarCfopSaida(filtro: any) {
		const _filtro = filtro.target.value;
		if (_filtro.length < 3) return;

		await axios
			.get(`${Api}/buscar/cfopentsai/saida/${_filtro}`, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setDadosCFOPSaida(response.data.cfop_saida);
			})
			.catch((erro) => {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}
	//#endregion

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">
								{acao === 'criar'
									? 'Novo CFOP Entrada x Saída'
									: acao === 'editar'
									? 'Editar CFOP Entrada x Saída'
									: 'Visualizar CFOP Entrada x Saída'}
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<form
							onSubmit={function (e) {
								e.preventDefault();
								if (acao === 'criar') {
									insereNovoCFOPEntradaxSaida();
								} else if (acao === 'editar') {
									editarCFOPEntradaSaida();
								}
							}}
						>
							<div className="card">
								<div className="card-body">
									<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
										<li className="nav-item">
											<a href="#geralVericulo" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
												<i className="mdi mdi-home-variant d-md-none d-block"></i>
												<span className="d-none d-md-block">Geral</span>
											</a>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane show active" id="geralVericulo">
											<div className="row">
												<div className="col-sm-2">
													<div className="form-floating mb-1">
														<input
															type="text"
															className="form-control bordasInferiorInput text-black text-capitalize"
															id="id_cfop"
															name="id_cfop"
															value={id_cfop}
															disabled
														/>
														<label className="form-label">ID CFOP</label>
													</div>
												</div>

												<div className="col-sm-8">
													<div className="form-floating mb-1">
														<DatalistInput
															placeholder="Informe pelo menos 3 caracteres..."
															label="CFOP Entrada"
															showLabel={true}
															onSelect={(item) => {
																setCFOPEntrada(item.id);
																setNaturezaOpEntrada(item.natureza_op);
																setNaturezaOpEntradaValor(item.value);
															}}
															items={dadosCFOPEntrada}
															onFocus={() => setFocoCFOP_Entrada(true)}
															onBlur={() => setFocoCFOP_Entrada(false)}
															onChange={(item) => carregarCfopEntrada(item)}
															className="mt-1"
															value={natureza_opEntradaValor}
															inputProps={{
																disabled: acao === 'criar' ? false : true,
																required: true,
																style: {
																	border: 'none',
																	borderRadius: '0px',
																	outline: 'none',
																	borderBottom: focoCFOP_Entrada ? '2px solid #3165f5' : '1px solid #ccc',
																	boxShadow: focoCFOP_Entrada ? '0 0 0 #ccc' : 'none',
																},
															}}
														/>
													</div>
												</div>

												<div className="col-sm-2 pt-3">
													<div className="form-floating mb-1">
														<CheckBox
															label="Padrão"
															name="padrao"
															checked={padrao}
															onChange={setPadrao}
															disabled={acao}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="natureza_opEntrada"
														name="natureza_opEntrada"
														value={natureza_opEntrada}
														disabled
													/>
													<label className="form-label">Natureza de Operação Entrada</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm">
												<div className="form-floating mb-1">
													<DatalistInput
														placeholder="Informe pelo menos 3 caracteres..."
														label="CFOP Saída"
														showLabel={true}
														onSelect={(item) => {
															setCFOPSaida(item.id);
															setNaturezaOpSaida(item.natureza_op);
															setNaturezaOpSaidaValor(item.value);
														}}
														items={dadosCFOPSaida}
														onFocus={() => setFocoCFOP_Saida(true)}
														onBlur={() => setFocoCFOP_Saida(false)}
														onChange={(item) => carregarCfopSaida(item)}
														className="mt-1"
														value={natureza_opSaidaValor}
														inputProps={{
															disabled: acao === 'criar' ?  false : true,
															required: true,
															style: {
																border: 'none',
																borderRadius: '0px',
																outline: 'none',
																borderBottom: focoCFOP_Saida ? '2px solid #3165f5' : '1px solid #ccc',
																boxShadow: focoCFOP_Saida ? '0 0 0 #ccc' : 'none',
															},
														}}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm">
												<div className="form-floating mb-1">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="natureza_opSaida"
														name="natureza_opSaida"
														value={natureza_opSaida}
														disabled
													/>
													<label className="form-label">Natureza de Operação Saída</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={acao === 'criar' ? false : acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando show={showCarregando} mensagem={msgShowCarregando} />
		</>
	);
};

export { NovoCFOPEntradaxSaida };
