import { ITabela } from '../../../../../components/Table/TabelaInterface';

export const colunasListaNfe: ITabela[] = [
	{
		titulo: 'ID',
		acesso: 'id',
	},
	{
		titulo: 'Id depositante',
		acesso: 'id_cliente',
	},
	{
		titulo: 'Pedido',
		acesso: 'id_pedido',
	},
	{
		titulo: 'O.S.',
		acesso: 'id_os',
	},
	{
		titulo: 'N° Nfe',
		acesso: 'nf',
	},
	{
		titulo: 'Série',
		acesso: 'serie',
	},
	{
		titulo: 'CNPJ Emit.',
		acesso: 'cnpj_emit',
	},
	{
		titulo: 'Emitente',
		acesso: 'razao_emit',
	},
	{
		titulo: 'CNPJ Dest.',
		acesso: 'cnpj_dest',
	},
	{
		titulo: 'Dest',
		acesso: 'razao_dest',
	},
	{
		titulo: 'Dt.Emissão',
		acesso: 'dt_emissao_formatada',
	},
	{
		titulo: 'Usuário',
		acesso: 'usuario',
	},
];
