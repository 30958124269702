import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Titulo from '../../../../components/componentes/formulario/ContainerTitulo';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Inputs from '../../../../components/componentes/formulario/Inputs';
import axios from 'axios';
import { Api } from '../../../../services/api';
import ModalCarregando from '../../../../components/componentes/formulario/ModalCarregando';

export function CadastroCFOPVisualizar() {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const usuario = sessionStorage.getItem('IsIDUserFKL') || '';
	const navigate = useNavigate();

	const [cfop, setCfop] = useState('');
	const [natOp, setNatOp] = useState('');
	const [tipoOp, setTipoOp] = useState('');
	const [ativo, setAtivo] = useState('1');

	const [showCarregando, setShowCarregando] = useState(false);
	const _cfop = useParams();

	const carregarCFOP = async (cfop: string) => {
		await axios
			.get(Api + `/cfop/json/${cfop}/?&auth=${token}`)
			.then((response) => {
				setCfop(response.data[0].cfop);
				setNatOp(response.data[0].natoper);
				setTipoOp(response.data[0].tipo_cfop);
				setAtivo(response.data[0].ativo);
				setShowCarregando(false);
			})
			.catch((erro) => {
				setShowCarregando(false);
				toast.error(`${erro.response.data.message}`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			});
	};
	useEffect(() => {
		if (_cfop.id !== undefined) {
			setShowCarregando(true);
			carregarCFOP(_cfop.id);
		}
	}, [_cfop.id]);

	// const enviar = (event: React.FormEvent<HTMLFormElement>) => {
	// 	event.preventDefault();
	// 	setShowCarregando(true);
	// 	//incluirCFOP(cfop.replace('.', ''), natOp, tipoOp, ativo, usuario);
	// };
	return (
		<>
			<div className="container-fluid">
				<Titulo titulo="Visualizar CFOP" />
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<form //onSubmit={enviar}
							>
								<div className="card-body">
									<div className="row">
										<div className="col-sm-2">
											<div className="mb-1">
												<Inputs
													label="CFOP"
													placeholder="0.000"
													usaMascara={true}
													maxLength={5}
													name="cfop"
													onchange={setCfop}
													classNameInput="form-control"
													classNameLabel="form-label"
													readOnly={true}
													value={cfop}
												/>
											</div>
										</div>
										<div className="col-sm-10">
											<div className="mb-1">
												<Inputs
													label="Natureza de Operação"
													maxLength={250}
													name="natOp"
													onchange={setNatOp}
													classNameInput="form-control"
													classNameLabel="form-label"
													readOnly={true}
													value={natOp}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<div className="mb-1">
												<label className="form-label" htmlFor="tipoOp">
													Tipo Operação
												</label>
												<select
													className="form-select"
													id="tipoOp"
													name="tipoOp"
													value={tipoOp}
													onChange={(e) => setTipoOp(e.target.value)}
													disabled
												>
													<option value="">Selecione...</option>
													<option value="E">Entrada</option>
													<option value="S">Saída</option>
												</select>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="mb-1">
												<label className="form-label" htmlFor="statuscfop">
													Status
												</label>
												<select
													className="form-select"
													id="statuscfop"
													name="statuscfop"
													value={ativo}
													onChange={(e) => setAtivo(e.target.value)}
													disabled
												>
													<option value="1">Ativo</option>
													<option value="0">Inativo</option>
												</select>
											</div>
										</div>
									</div>

									<ToastContainer newestOnTop />
								</div>
								<div className="card-footer">
									<div className="col">
										<div className="text-sm-end">
											<button className="btn btn-cancelar mb-2 me-1" onClick={() => navigate(-1)} type="button">
												Cancelar
											</button>
											<button className="btn btn-novo mb-2 me-1 disabled" type="submit">
												Salvar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando show={showCarregando} />
		</>
	);
}
