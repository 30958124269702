import { useNavigate } from 'react-router-dom';

const NovaDesfragmentacaoEstoque = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Desfragmentação Estoques</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
								<li className="nav-item">
									<a
										href="#tab-oper-desfragmentacaoEstoqueNovo-geral"
										data-bs-toggle="tab"
										aria-expanded="true"
										className="nav-link rounded-0 active"
									>
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">Geral</span>
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane show active" id="tab-oper-desfragmentacaoEstoqueNovo-geral">
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="pallet_origem"
													placeholder=""
												/>
												<label htmlFor="pallet_origem">Pallet Origem</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="qtde_retira"
													placeholder=""
												/>
												<label htmlFor="qtde_retira">Qtde Retira</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="wms"
													placeholder=""
												/>
												<label htmlFor="wms">WMS</label>
											</div>
										</div>
									</div>

									<div className="row mb-1 align-items-center">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="cliente"
													placeholder=""
												/>
												<label htmlFor="cliente">Cliente</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="bloqueado" />
												<label className="form-check-label" htmlFor="bloqueado">Bloq.</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="validade"
													placeholder=""
												/>
												<label htmlFor="validade">Validade</label>
											</div>
										</div>
										<div className="col-sm-4 form-floating">

											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Quantidade</label>
										</div>
										<div className="col-sm-4 form-floating">

											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Prog</label>
										</div>
									</div>
									<hr />
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="pallet_destino"
													placeholder=""
												/>
												<label htmlFor="pallet_destino">Pallet Destino</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="pallet_novo"
													placeholder=""
												/>
												<label htmlFor="pallet_novo">Pallet Novo</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="wms_novo"
													placeholder=""
												/>
												<label htmlFor="wms_novo">WMS</label>
											</div>
										</div>
									</div>
									<div className="row mb-1 align-items-center">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="cliente_novo"
													placeholder=""
												/>
												<label htmlFor="cliente_novo">Cliente</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="bloqueado_novo" />
												<label className="form-check-label" htmlFor="bloqueado_novo">Bloq.</label>
											</div>

										</div>
										<div className='col-sm-4'>
											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="rom_novo" />
												<label className="form-check-label" htmlFor="rom_novo">Rom.</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input
													type="text"
													className="form-control bordasInferiorInput text-black text-capitalize"
													id="validade_novo"
													placeholder=""
												/>
												<label htmlFor="validade_novo">Validade</label>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end">
									<button
										className="btn btn-danger mb-2 me-1"
										type="button"
										onClick={() => navigate('/operacao/desfragmentacaoEstoque')}
									>
										Cancelar
									</button>
									<button
										className="btn btn-info mb-2 me-1"
										type="button"
										onClick={() => navigate('/operacao/desfragmentacaoEstoque')}
									>
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NovaDesfragmentacaoEstoque };
