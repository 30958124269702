import Logo3 from '../../assets/img/logo3.png';
import imgLoging from '../../assets/img/img_login.jpg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Api } from '../../services/api';
import { Modal } from "react-bootstrap";
import mascaraCPF from '../../components/functions/mascaraCPF';
import { toast, ToastContainer } from 'react-toastify';
import ModalCarregando from '../../components/ModalCarregando/ModalCarregando';

const Login = (): JSX.Element => {
	const navigate = useNavigate();

	const initialState = {
		usuario: "",
		senha: ""
	};

	const [modalCarregando, setModalCarregando] = useState(false)

	const [inputs, setInputs] = useState({
		usuario: '',
		senha: ''
	})

	const handleChange = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }));
	}

	async function efetuarLogin() {
		const _user = 'user=' + inputs.usuario;
		const _pass = 'pass=' + inputs.senha;

		try {
			const response = await axios.get(Api + `/usuario/login/json/?${_user}&${_pass}`);
			if (response.status === 200) {

				//sessionStorage.setItem('IsIDFKL', response.data.user.id);
				sessionStorage.setItem('IsIDFilialPadraoFKL', response.data.user.id_filial);
				sessionStorage.setItem('IsIDUserFKL', response.data.user.id_usuario);
				sessionStorage.setItem('IsUserFKL', response.data.user.nome);
				sessionStorage.setItem('IsIdPerfilFKL', response.data.user.id_perfil);
				sessionStorage.setItem('IsFKLTOKEN', response.data.token);
				sessionStorage.setItem('NomePerfil', response.data.user.nome_perfil);
				sessionStorage.setItem('CPFusuario', response.data.user.cpf);
				sessionStorage.setItem("AtividadeCriada", response.data.atividade)
				sessionStorage.setItem("NivelPerfil", response.data.user.nivel)
				sessionStorage.setItem("identidadeUsuario", response.data.user.cpf)
				navigate('/');
			}
			else {

				alert(response.data.message)
			}
		} catch (error: any) {
			alert(error.response.data.message || error.message)
		}
	}
	const AcessoUsuario = (event: any) => {
		event.preventDefault();
		if (inputs.usuario !== '' && inputs.senha !== '') {
			efetuarLogin();
		}
	}

	//PARTE DE REDEFINIÇÃO DE SENHA///

	//#region REC SENHA -- INICIO
	const [ModalEnviarEmail, setModalEnviarEmail] = useState(false)
	const [cpf, setcpf] = useState("")
	function abrirModalEnviarEmail() {

		setModalEnviarEmail(!ModalEnviarEmail)
		setCodigo("")
		setcpf("")
	}

	const [mensagemEmail, setMensagemEmail] = useState("")
	function EnviarEmail() {

		const dados = {
			cpf: cpf.replaceAll(".", "").replaceAll("-", "")
		}
		setModalCarregando(true)
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/email/recsenha`, dados)
			.then(function (resposta) {

				sessionStorage.setItem("tokenRecSenha", resposta.data.token)
				toast.success(resposta.data.message, {
					autoClose: 900
				})
				setMensagemEmail(resposta.data.message)
				setModalEnviarEmail(false)
				setModalCodigo(true)
				setModalCarregando(false)
			}).catch(function (erro) {

				setModalCarregando(false)
				console.log(erro.response.data.message)
				toast.error(erro.response.data.message, {
					autoClose: 900
				})
			})
	}

	const [ModalCodigo, setModalCodigo] = useState(false)
	const [Codigo, setCodigo] = useState("")

	function ValidarCodigo() {

		const dados = {
			token: sessionStorage.getItem("tokenRecSenha"),
			codigoRecebido: Codigo,
			cpf: cpf.replaceAll(".", "").replaceAll("-", "")
		}

		setModalCarregando(true)
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/validar/cod/recsenha`, dados)
			.then(function (resposta) {

				toast.success(resposta.data.message, {
					autoClose: 900
				})
				setModalCodigo(false)
				setModalSetarSenha(true)
				setModalCarregando(false)
			}).catch(function (erro) {

				setModalCarregando(false)
				toast.error(erro.response.data.message, {
					autoClose: 900
				})
			})
	}

	const [ModalSetarSenha, setModalSetarSenha] = useState(false)
	const [novaSenha, setNovaSenha] = useState("")
	const [confirmarSenha, setConfirmarSenha] = useState("")
	function alterarSenha() {

		const dados = {
			token: sessionStorage.getItem("tokenRecSenha"),
			codigoRecebido: Codigo,
			cpf: cpf.replaceAll(".", "").replaceAll("-", "")
		}

		setModalCarregando(true)
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/rec/senha/update`, dados)
			.then(function (resposta) {

				toast.success(resposta.data.message, {
					autoClose: 900
				})

				setModalSetarSenha(false)
				setModalCarregando(false)
			}).catch(function (erro) {

				setModalCarregando(false)
				toast.error(erro.response.data.message, {
					autoClose: 900
				})
			})
	}

	return (
		<div className="container">
			<table >
				<tr>
					<td align="center">
						<div className="container-fluid px-0 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
							<div className="card card0">
								<div className="row d-flex">
									<div className="col-sm-6">
										<div className="card1">
											<div className="row">
												<img alt="logo" src={Logo3} className="logoLogin" />
											</div>
											<div className="row px-3 justify-content-center mt-4 mb-5 border-line" style={{ marginLeft: '60px' }}>
												<img alt="img de producao" src={imgLoging} className="image" />
											</div>
										</div>
									</div>
									<div className="col-sm-6">
										<form onSubmit={AcessoUsuario}>
											<div className="card2 card  px-4 py-4" style={{ top: '110px', height: '417px' }}>

												<div className="row px-3">
													<label className="mb-1">
														<h3 className="mb-0 text-lg">Controle de Acesso</h3>
													</label>
												</div>
												<div className="row">
													<hr />
												</div>
												<div className="row form-floating" style={{ textAlign: 'left' }}>


													<input className="form-control bordasInferiorInput text-black" type="email" placeholder="Informe seu e-mail de acesso"
														id="usuario"
														name="usuario"
														value={inputs.usuario || ""}
														onChangeCapture={handleChange}
														required />
													<label className="mb-1">
														E-mail
													</label>

												</div>

												<div className="row form-floating" style={{ textAlign: 'left' }}>

													<input className="form-control bordasInferiorInput text-black" type="password" placeholder="Informe a senha"
														id="senha"
														name="senha"
														value={inputs.senha || ""}
														onChangeCapture={handleChange}
														required />
													<label className="mb-1">
														Senha
													</label>
												</div>

												<button type="button" className="btn btn-link"
													style={{ display: "block", margin: "auto" }}
													onClick={abrirModalEnviarEmail}>Esqueci minha senha - redefinir senha</button>

												<br />

												<div className="row mb-3 px-3" style={{ margin: "auto" }}>
													<div className="col-sm" style={{ textAlign: 'center' }}>
														<button
															type="submit"
															className="btn btn-blue text-center"
															style={{ cursor: 'pointer' }}
														>
															Acessar
														</button>
													</div>
												</div>
												<div className="row mb-4 px-3">
													<small className="font-weight-bold" />
													&nbsp;
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="bg-blue py-4">
									<div className="row">
										<div className="col-sm" style={{ textAlign: 'end', marginRight: '30px' }}>
											<small className="ml-4 ml-sm-5 mb-2">Copyright &copy; {new Date().getFullYear()}. All rights reserved.</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</table>


			{/*modal de enviar email */}
			<Modal show={ModalEnviarEmail} size='lg' centered>
				<Modal.Header>
					<Modal.Title>Recuperação de senha</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={function (e) {
						e.preventDefault()
						EnviarEmail()
					}}>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-6 col-md-5 col-sm m-auto'>
									<div className='form-floating'>
										<input value={mascaraCPF(cpf)} onChange={function (e) {

											setcpf(e.target.value)
										}} type="text" required className="form-control bordasInferiorInput text-black" id="floatingInput" placeholder="name@example.com" />
										<label>CPF</label>
									</div>
								</div>
							</div>
						</div>
						<br />
						<div className='container'>
							<div className='row'>
								<div className='col-lg col-md col-sm text-end'>
									<button type="button" onClick={abrirModalEnviarEmail} className='btn btn-danger me-2'>Cancelar</button>
									<button type="submit" className='btn btn-primary'>Enviar E-mail</button>
								</div>
							</div>
						</div>
					</form>

				</Modal.Body>
			</Modal>

			{/*MODAL PARA DGITAR O CÓDIG */}
			<Modal show={ModalCodigo} size='lg' centered>
				<Modal.Header>
					<Modal.Title>Recuperação de senha</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={function (e) {
						e.preventDefault()
						ValidarCodigo()
					}}>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-10 col-md-5 col-sm m-auto'>
									<p>{mensagemEmail}</p>
									<p className='text-black mb-0 pb-0'>Informe o código recebido no e-mail.</p>
									<div className='form-floating'>


										<input value={Codigo} onChange={function (e) {

											setCodigo(e.target.value)
										}} type="text" required className="form-control w-50 bordasInferiorInput text-black" id="floatingInput" placeholder=" " />
										<label>Código recebido</label>
									</div>
								</div>
							</div>
						</div>
						<br />
						<div className='container'>
							<div className='row'>
								<div className='col-lg col-md col-sm text-end'>
									<button type="button" onClick={function () {
										setModalCodigo(false)
									}} className='btn btn-danger me-2'>Cancelar</button>
									<button type="submit" className='btn btn-primary'>Verificar Código</button>
								</div>
							</div>
						</div>
					</form>

				</Modal.Body>
			</Modal>

			{/*MODAL SETAR NOVA SENHA */}
			<Modal show={ModalSetarSenha} size='lg' centered>
				<Modal.Header>
					<Modal.Title>Recuperação de senha</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={function (e) {
						e.preventDefault()
						alterarSenha()
					}}>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-10 col-md-5 col-sm m-auto'>
									<p className='text-black'>Defina a nova senha.</p>
									<div className='form-floating'>

										<input value={novaSenha} onChange={function (e) {

											setNovaSenha(e.target.value)
										}} type="password" required className="form-control w-50 bordasInferiorInput text-black" id="floatingInput" placeholder=" " />
										<label>Nova senha</label>
									</div>
									<div className='form-floating'>
										<input value={confirmarSenha} onChange={function (e) {

											setConfirmarSenha(e.target.value)
										}} type="password" required className="form-control w-50 bordasInferiorInput text-black" id="floatingInput" placeholder=" " />
										<label>Confirmar senha</label>
									</div>
								</div>
							</div>
						</div>
						<br />
						<div className='container'>
							<div className='row'>
								<div className='col-lg col-md col-sm text-end'>
									<button type="button" onClick={function () {
										setModalSetarSenha(false)
									}} className='btn btn-danger me-2'>Cancelar</button>
									<button type="submit" className='btn btn-primary'>Alterar senha</button>
								</div>
							</div>
						</div>
					</form>

				</Modal.Body>
			</Modal>

			<ModalCarregando mostrar={modalCarregando} mensagem='Carregando...' />
			<ToastContainer newestOnTop />
		</div>

	);
};

export { Login };
