//função responsavel por ler arquivo xml e dar em string seu conteudo

function LerXml(xml: Blob) {

    return new Promise(function (resolve, reject) {

        try {

            const reader = new FileReader()
            reader.onload = function () {

                resolve(reader.result)
            }

            reader.readAsText(xml)
        } catch (error) {

            reject(error)
        }
    })
}

export default LerXml