import { Link, Navigate } from 'react-router-dom';

const ProcessoSaida = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Listagem dos Processos Saída</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-12">
									<a className="btn rounded me-1" data-bs-toggle="modal" data-bs-target="#modal-processoSaida"
									 style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<i className="mdi mdi-plus-circle me-2"></i>
										Novo Processo
									</a>
									<a className="btn rounded me-1" style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<i className="mdi mdi-plus-circle me-2"></i>
										Liberar Veículos
									</a>
									<a
										className="btn btn-warning rounded dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span className="iconify me-2" data-icon="dashicons:printer"></span>
										Funções
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item">Equipamentos</a>
										<a className="dropdown-item">Termo de Liberação</a>
										<a className="dropdown-item">Check-List</a>
										<a className="dropdown-item">Aviso</a>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
									<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<tr>
											<th>#</th>
											<th>Processos</th>
											<th>Depositante</th>
											<th>Chegada</th>
											<th>Tipo Operação</th>
											<th>Modalidade</th>
											<th>Status</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>61</td>
											<td>Depositante 1</td>
											<td>29/11/021 16:00:00</td>
											<td>ENTRADA</td>
											<td>CROSSDOCKING</td>
											<td>ABERTO</td>
											<td className="d-flex flex-nowrap table-action">
												<Link to={`/pco/contrFisc/procEVeiculos/processoSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>60</td>
											<td>Depositante 2</td>
											<td>29/11/021 15:00:00</td>
											<td>ENTRADA</td>
											<td>ARMAZENAGEM</td>
											<td>CARGA/DESCARGA</td>
											<td className="d-flex flex-nowrap table-action">
												<Link to={`/pco/contrFisc/procEVeiculos/processoSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>59</td>
											<td>Depositante 3</td>
											<td>28/11/021 12:00:00</td>
											<td>ENTRADA</td>
											<td>ARMAZENAGEM</td>
											<td>AUTORIZAÇÃO DE ENTRADA</td>
											<td className="d-flex flex-nowrap table-action">
												<Link to={`/pco/contrFisc/procEVeiculos/processoSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>58</td>
											<td>Depositante 4</td>
											<td>27/11/021 14:00:00</td>
											<td>ENTRADA</td>
											<td>DEVOLUÇÕES</td>
											<td>LIBERADO</td>
											<td className="d-flex flex-nowrap table-action">
												<Link to={`/pco/contrFisc/procEVeiculos/processoSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
										<tr>
											<td>
												<input type="checkbox" />
											</td>
											<td>57</td>
											<td>Depositante 5</td>
											<td>26/11/021 16:30:00</td>
											<td>ENTRADA</td>
											<td>RE-ENTRADA</td>
											<td>CANCELADO</td>
											<td className="d-flex flex-nowrap table-action">
												<Link to={`/pco/contrFisc/procEVeiculos/processoSaida/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { ProcessoSaida };
