// src/hooks/BuscarPermissoesUsuario.ts

import { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from '../../services/api';

// Definindo o tipo das permissões do usuário
interface Permissoes {
	perfilPermissao: {
		nomePerfil: string;
		nivel: number;
		situacao: boolean;
		isMaster: boolean;
		usuarioCadastro: string;
		// cadastro de usuarios
		visualizarUsuario: boolean;
		criarUsuario: boolean;
		editarUsuario: boolean;
		excluirUsuario: boolean;
		// perfil/permissoes
		visualizarPerfilPermissao: boolean;
		criarPerfilPermissao: boolean;
		editarPerfilPermissao: boolean;
		excluirPerfilPermissao: boolean;
		// usuarios filial
		visualizarUsuariosFilial: boolean;
		// atividades usuario
		visualizarAtividadesUsuario: boolean;
		// cod sistema
		visualizarCodSistema: boolean;
		criarCodSistema: boolean;
		editarCodSistema: boolean;
		excluirCodSistema: boolean;
		// cadastro de filial
		visualizarCadastroFilial: boolean;
		criarCadastroFilial: boolean;
		editarCadastroFilial: boolean;
		excluirCadastroFilial: boolean;
		// controle de numeracao
		visualizarControleNumeracao: boolean;
		criarControleNumeracao: boolean;
		editarControleNumeracao: boolean;
		excluirControleNumeracao: boolean;
		// entidade
		visualizarEntidade: boolean;
		criarEntidade: boolean;
		editarEntidade: boolean;
		excluirEntidade: boolean;
		// vincular transportadora x depositante
		visualizarTransportadora: boolean;
		vincularTransportadoraDepositante: boolean;
		// depositante
		visualizarDepositante: boolean;
		criarDepositante: boolean;
		editarDepositante: boolean;
		excluirDepositante: boolean;
		// produtos armazenagem
		visualizarProdutosArmazenagem: boolean;
		criarProdutosArmazenagem: boolean;
		editarProdutosArmazenagem: boolean;
		excluirProdutosArmazenagem: boolean;
		// produtos fornecedor
		visualizarProdutosFornecedor: boolean;
		criarProdutosFornecedor: boolean;
		editarProdutosFornecedor: boolean;
		excluirProdutosFornecedor: boolean;
		// produtos cross
		visualizarProdutosCross: boolean;
		criarProdutosCross: boolean;
		editarProdutosCross: boolean;
		excluirProdutosCross: boolean;
		// produtos picking
		visualizarProdutosPicking: boolean;
		criarProdutosPicking: boolean;
		editarProdutosPicking: boolean;
		excluirProdutosPicking: boolean;
		// endereco armazenagem
		visualizarEnderecosArmz: boolean;
		criarEnderecosArmz: boolean;
		editarEnderecosArmz: boolean;
		editarTempEnderecosArmz: boolean;
		excluirEnderecosArmz: boolean;
		// veiculos
		visualizarVeiculos: boolean;
		criarVeiculos: boolean;
		editarVeiculos: boolean;
		excluirVeiculos: boolean;
		// motoristas
		visualizarMotoristas: boolean;
		criarMotoristas: boolean;
		editarMotoristas: boolean;
		excluirMotoristas: boolean;
		// equipamentos
		visualizarEquipamento: boolean;
		criarEquipamento: boolean;
		editarEquipamento: boolean;
		excluirEquipamento: boolean;
		// colaborado: boolean
		visualizarColaborador: boolean;
		criarColaborador: boolean;
		editarColaborador: boolean;
		excluirColaborador: boolean;
		// cfop
		visualizarCFOP: boolean;
		importarArqCFOP: boolean;
		baixarModeloCFOP: boolean;
		criarCFOP: boolean;
		editarCFOP: boolean;
		excluirCFOP: boolean;
		// cfop entrada x saida
		visualizarCFOPEntradaSaida: boolean;
		criarCFOPEntradaSaida: boolean;
		editarCFOPEntradaSaida: boolean;
		excluirCFOPEntradaSaida: boolean;
		// grupo tributario
		visualizarGrupoTributario: boolean;
		criarGrupoTributario: boolean;
		editarGrupoTributario: boolean;
		excluirGrupoTributario: boolean;
		// nfe entradas armazenagem
		visualizarNfeEntradasArmz: boolean;
		detalharNfeEntradaArmz: boolean;
		importarNfeEntradasArmz: boolean;
		excluirNfeEntradasArmz: boolean;
		// nfe entrada cross docking
		visualizarNfeEntradaCrossDocking: boolean;
		detalharNfeEntradaCrossDocking: boolean;
		// nfe pedido
		visualizarNfePedido: boolean;
		detalharNfePedido: boolean;
		excluirNfePedido: boolean;
		// saldo fiscal
		visualizarSaldoFiscal: boolean;
		// nfe emitida
		visualizarNfeEmitido: boolean;
		detalharNfeEmitido: boolean;
		// emissao nfe
		visualizarEmissaoNfe: boolean;
		criarEmissaoNfe: boolean;
		editarEmissaoNfe: boolean;
		excluirEmissaoNfe: boolean;
		// manifestar nfe
		visualizarManifestarNfe: boolean;
		manifestarNfe: boolean;
		// processo de entrada
		visualizarProcEntrada: boolean;
		criarProcEntrada: boolean;
		editarProcEntrada: boolean;
		cancelarProcEntrada: boolean;
		// processo de saida
		visualizarProcSaida: boolean;
		criarProcSaida: boolean;
		editarProcSaida: boolean;
		cancelarProcSaida: boolean;
		// criacao de pedidos - importacao de arquivo
		visualizarImportacaoArquivo: boolean;
		importarArquivo: boolean;
		// criacao de pedidos - visualizacao de nfe
		visualizarNfeParaPedido: boolean;
		importarNfeParaPedido: boolean;
		// criacao de pedidos - criacao manual
		visualizarPedidoManual: boolean;
		adicionarItemPedidoManual: boolean;
		excluirItemPedidoManual: boolean;
		criarPedidoManual: boolean;
		// pedidos pendentes
		visualizarPedidosPendentes: boolean;
		quebrarPedidoTarefas: boolean;
		liberarPedidoParaPicking: boolean;
		edtiarItemPedidoPendente: boolean;
		excluirItemPedidoPendente: boolean;
		editarPedidoPendente: boolean;
		excluirPedidoPendente: boolean;
		// pedidos em separacao
		visualizarPedidosSep: boolean;
		//inventario
		visualizarInventario: boolean;
		// ajustes em estoque
		visualizarAjusteEstoque: boolean;
		detalharAjusteEstoque: boolean;
		//ajustes em recebimento
		visualizarAjusteRecebimento: boolean;
		// ajuste de movimentacao
		visualizarAjusteMovimento: boolean;
		detalharAjusteMovimento: boolean;		
		excluirItemAjusteMovimento: boolean;
		//Estoque Detalhado
		visualizarEstoqueDetalhado: boolean;
		detalharPorUAEstoqueDetalhado: boolean;
		gerarExcelDetalharPorUAEstoqueDetalhado: boolean;
		gerarResumoValidadeEstoqueDetalhado: boolean;
		gerarExcelResumoValidadeEstoqueDetalhado: boolean;
		gerarPDFResumoValidadeEstoqueDetalhado: boolean;
		enviarEstoqueEmailEstoqueDetalhado: boolean;
		bloquearDesbloquearItemEstoqueDetalhado: boolean;
		visualizarEmpenhoEstoqueDetalhado: boolean;
		visualizarBarrasUAEstoqueDetalhado: boolean;
		adicionarBarrasUAEstoqueDetalhado: boolean;
		editarBarrasUAEstoqueDetalhado: boolean;
		excluirBarrasUAEstoqueDetalhado: boolean;		
		//Estoque Sintético
		visualizarEstoqueSintetico: boolean;
		gerarExcelEstoqueSintetico: boolean;
		visualizarRomaneioBarrasAtivas: boolean;
		//Romaneio de Barras Ativas
		criarRomaneioBarrasAtivas: boolean;
		imprimirTodasBarrasRomaneioBarrasAtivas: boolean;
		gerarExcelRomaneioBarrasAtivas: boolean;
		verTodasRomaneioBarrasAtivas: boolean;
		gerarExcelDetalhesRomaneioBarrasAtivas: boolean;
		editarRomaneioBarrasAtivas: boolean;
		excluirRomaneioBarrasAtivas: boolean;
		imprimirItemRomaneioBarrasAtivas: boolean;
		visualizarUAVazioSaidaSemUsoVazio: boolean;
		excluirUAVazioSaidaSemUsoVazio: boolean;
		imprimirUAVazioSaidaSemUsoVazio: boolean;
		visualizarUAVazioSaidaSemUsoSaida: boolean;
		excluirUAVazioSaidaSemUsoSaida: boolean;
		imprimirUAVazioSaidaSemUsoSaida: boolean;
		// Conferir Recebimento
		visualizarConferirRecebimento: boolean;
		// Conferir Carregamento
		visualizarConferirCarregamento: boolean;
		// Enderecar UA
		visualizarEnderecarUA: boolean;
		// Desfragmentar UA
		visualizarDesfragmentarUA: boolean;
		// Analise de Otimizacao
		visualizarAnaliseOtimizacao: boolean;
		// Reposicao Primaria
		visualizarReposicaoPrimaria: boolean;
		// Reposicao Complementar
		visualizarReposicaoComplementar: boolean;
		// Impressao de Etiquetas
		visualizarImpressaoEtiquetas: boolean;
		visualizarImpEtqReimpressaoUA: boolean;
		visualizarImpEtqGerarUAVazioSaida: boolean;
		visualizarImpEtqImpressaoEndereco: boolean;
		visualizarImpEtqImpressaoProdutoPicking: boolean;
		//Permissoes Especiais - Quebra-FEFO
		visualizarExcecaoQuebraFefo: boolean;
		//Permissoes Especiais - Ler GTIN
		visualizarExcecaoLerGtin: boolean;
		//Permisoess Especiais - Ler Volume
		visualizarExcecaoLerVolume: boolean;
		//Permissoes Especiais - Endereço
		visualizarExcecaoEndereco: boolean;
		//Permissoes Especiais - Zona de Endereçamento
		visualizarExcecaoZonaEnderecamento: boolean;
		// Relatorios -> Rasreabilidade de Produto
		visualizarRelRastreabilidadeProduto: boolean;
		// Relatorios -> Romaneio de Carga
		visualizarRelRomaneioCarga: boolean;
		// Relatorios -> Movimentacao Periodo
		visualizarRelMovimentacaoPeriodo: boolean;
		// Relatorios -> Curva ABC
		visualizarRelCurvaABC: boolean;
		// Relatorios -> Historico de Endereçamento
		visualizarRelHistoricoEnderecamento: boolean;
		// Faturamento de Serviços -> Cadastrar Tarifas
		visualizarCadastrarTarifas: boolean;
		criarCadastrarTarifas: boolean;
		editarCadastrarTarifas: boolean;
		excluirCadastrarTarifas: boolean;
		// Faturamento de Serviços -> Gerar Corte
		visualizarGerarCorte: boolean;
		// Fatuamento de Serviços -> Estornar Corte
		visualizarEstornarCorte: boolean;
		// Faturamento de Serviços -> Emissao de Fatura
		visualizarEmissaoFatura: boolean;
		// Faturamento de Serviços -> Lançar Serviço Extra
		visualizarLancarServicoExtra: boolean;
		// Faturamento de Serviços -> Bonificar Faturamento
		visualizarBonificarFaturamento: boolean;
		// Indicadores -> Movimentacao
		visualizarIndicadoresMovimentacao: boolean;
		// Indicadores -> Produtividade
		visualizarIndicadoresProdutividade: boolean;
		// Indicadores -> Acuracidade
		visualizarIndicadoresAcuracidade: boolean;
		// Log -> Ação de Usuário
		visualizarLogAcaoUsuario: boolean;
		// Log -> API de Pedidos
		visualizarLogApiPedidos: boolean;
		// Log -> Integração
		visualizarLogIntegracao: boolean;

		//Permisoes especiais
		procEntradaAprovaDivQtdeReceb: boolean;
		endExcecaoEndereco: boolean;
		endExcecaoZonaEnderecamento: boolean;
		pedidoManutencaoPedido: boolean;
		pedidoAutorizaQuebraFefoPicking: boolean;
		confCargaExcLeituraGtin: boolean;
		confCargaExcLeituraVolume: boolean;
		pesagemAprovaDivBalanRodviaria: boolean;
		pesagemAprovaDivBalancaOS: boolean;
		estoqueRealizaAjusteQualitativo: boolean;
		estoqueRealizaAjusteQuantitativo: boolean;
		estoqueRealizaDesfragEstoque: boolean;
		estoqueAlteraDadosBarra: boolean;
		inventarioDefineToleranciaQuebraInv: boolean;
		inventarioAprovaDivergenciaInventarioAcimaTolerancia: boolean;
		permEspAjusteEstoque: boolean;
		permEspAjusteEntrada: boolean;
		permEspAjusteMovimentacao: boolean;
	};
}

// Definindo o tipo do retorno do hook
interface BuscarPermissoesUsuarioResultado {
	permissoes: Permissoes | null;
	carregando: boolean;
	erro: string | null;
}

// Hook customizado que busca as permissões de usuário
export const BuscarPermissoesUsuario = (id_filial: string, id_perfil_permissao: string): BuscarPermissoesUsuarioResultado => {
	const [permissoes, setPermissoes] = useState<Permissoes | null>(null);
	const [carregando, setCarregando] = useState<boolean>(true);
	const [erro, setErro] = useState<string | null>(null);

	useEffect(() => {
		// Construindo a URL fixa com base nos parâmetros
		const url = Api + `/carregar/perfil/criado/${id_filial}/${id_perfil_permissao}`;

		const carregarPermissoes = async () => {
			try {
				const response = await axios.get<Permissoes>(url, {
					headers: {
						authorization: sessionStorage.getItem('IsFKLTOKEN')!,
					},
				});
				setPermissoes(response.data); // Armazena as permissões no estado
			} catch (err: any) {
				setErro(err.message); // Armazena o erro no estado
			} finally {
				setCarregando(false); // Define o estado de carregamento como falso
			}
		};

		carregarPermissoes(); // Chama a função de busca de permissões
	}, [id_filial, id_perfil_permissao]);

	// Retorna os dados de permissões, o estado de carregamento e o erro, se houver
	return { permissoes, carregando, erro };
};
