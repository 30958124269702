import Titulo from "../../../../components/componentes/formulario/ContainerTitulo"
import Inputs from "../../../../components/componentes/formulario/Inputs"
import { useNavigate, useParams } from 'react-router-dom';
import { BsCardChecklist } from 'react-icons/bs';
import { Modal, Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { useEffect, useState } from 'react';
import axios from "axios";
import { Api } from "../../../../services/api";
import { toast } from 'react-toastify';
import ModalCarregando from "../../../../components/componentes/formulario/ModalCarregando";

function EditarGrupo() {
    const Navigate = useNavigate()
    const params = useParams()
    const token = sessionStorage.getItem('IsFKLTOKEN')
    const user = sessionStorage.getItem('IsIDUserFKL')


    const [showCarregando, setShowCarregando] = useState(false)

    //pega a info do produto com base no id
    async function carregarGrupo() {

        setShowCarregando(true)
        const response = await axios.get(Api + `/pegar/grupoId/?auth=${token}&id=${params.id}`)

        if (response.data.message) {
            setShowCarregando(false)
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
        else {
            setGrupo(response.data)
            setShowCarregando(false)
 
            //seto informações do grupo nos forms
            setDescricao(response.data[0].grupo)
            setOrigem(response.data[0].icms_origem)
            setCSTicms(response.data[0].icms_cst)
            setBaseCalc(response.data[0].icms_percredcal)
            setPorcemICMS(response.data[0].icms_percentual)
            setCod_enquadramento(response.data[0].ipi_enquadramento)
            setCSTipi(response.data[0].ipi_cst)
            setPorcemIPI(response.data[0].ipi_percentual)
            setCSTpis(response.data[0].pis_cst)
            setPorcemPIS(response.data[0].pis_percentual)
            setCSTcofins(response.data[0].cofins_cst)
            setPorcemcofins(response.data[0].cofins_percentual)
        }
    }

    //infos do Grupo --inicio
    const [descricao, setDescricao] = useState("")

    //infos do Grupo - ICMS
    const [origem, setOrigem] = useState("")
    const [CSTicms, setCSTicms] = useState("")
    const [baseCalc, setBaseCalc] = useState("")
    const [porcemICMS, setPorcemICMS] = useState("")

    //infos do grupo - IPI
    const [cod_enquadramento, setCod_enquadramento] = useState("")
    const [CSTipi, setCSTipi] = useState("")
    const [porcemIPI, setPorcemIPI] = useState("")

    //infos do grupo - PIS
    const [CSTpis, setCSTpis] = useState("")
    const [porcemPIS, setPorcemPIS] = useState("")

    //infos do grupo - PIS
    const [CSTcofins, setCSTcofins] = useState("")
    const [porcemcofins, setPorcemcofins] = useState("")


    //infos do Grupo --fim

    //grupo carregado
    const [grupo, setGrupo] = useState([])

    useEffect(function () {
        carregarGrupo()
    }, [])

    const [ModalConfirmarSalvarBanco, setModalConfirmarSalvarBanco] = useState(false)


    async function salvarNoBanco() {
        setShowCarregando(true)

        const editarGrupo = {
            descricao: descricao,
            origem: origem,
            CSTicms: CSTicms,
            baseCalc: baseCalc,
            porcemICMS: porcemICMS,
            cod_enquadramento: cod_enquadramento,
            CSTipi: CSTipi,
            porcemIPI: porcemIPI,
            CSTpis: CSTpis,
            porcemPIS: porcemPIS,
            CSTcofins: CSTcofins,
            porcemcofins: porcemcofins,
            usuario_edicao: user
        }

        const response = await axios.put(Api + `/att/grupo/byId/?auth=${token}&id=${params.id}`, editarGrupo)

        if (response.data.status == 200) {

            setShowCarregando(false)
            setModalConfirmarSalvarBanco(true)
        }
        else {

            setShowCarregando(false)
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }

    }


    return (
        <div className="container-fluid">
            <Titulo titulo="Cadastrar novo Grupo Tributário" />
            <div className="row">

                <div className="card">
                    <div className="card-body">

                        

                            {grupo.map(function (info: any) {
                                return (
                                    <>
                                        <div className="container">
                                            <div className="col-1">
                                                <Inputs
                                                    label="ID"
                                                    maxLength={4}
                                                    name="ID grupo"
                                                    classNameInput="form-control"
                                                    classNameLabel="form-label"
                                                    required={true}
                                                    value={info.id || ""}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-5">
                                                <Inputs
                                                    label="Descrição"
                                                    maxLength={160}
                                                    name="Descrição grupo"
                                                    classNameInput="form-control"
                                                    classNameLabel="form-label"
                                                    required={true}
                                                    onchange={function (e: any) {
                                                        setDescricao(e)
                                                    }}
                                                    value={descricao}
                                                />
                                            </div>
                                        </div>

                                        <br />
                                        <br />

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm">
                                                    <b>ICMS</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>IPI</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>PIS</b>
                                                </div>
                                                <div className="col-sm">
                                                    <b>COFINS</b>
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="Origem"
                                                            maxLength={15}
                                                            name="Origem"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setOrigem(e)
                                                            }}
                                                            value={origem}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST icms"
                                                            maxLength={15}
                                                            name="CST icms"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setCSTicms(e)
                                                            }}
                                                            value={CSTicms}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%Red.Base Cálculo"
                                                            maxLength={70}
                                                            name="%Red.Base Cálculo"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setBaseCalc(e)
                                                            }}
                                                            value={baseCalc}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%ICMS"
                                                            maxLength={70}
                                                            name="%ICMS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setPorcemICMS(e)
                                                            }}
                                                            value={porcemICMS}

                                                        />
                                                    </div>
                                                </div>




                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="Cod.Enquadramento"
                                                            maxLength={15}
                                                            name="Cod.Enquadramento"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setCod_enquadramento(e)
                                                            }}
                                                            value={cod_enquadramento}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST IPI"
                                                            maxLength={15}
                                                            name="CST IPI"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setCSTipi(e)
                                                            }}
                                                            value={CSTipi}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%IPI"
                                                            maxLength={70}
                                                            name="%IPI"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setPorcemIPI(e)
                                                            }}
                                                            value={porcemIPI}
                                                        />
                                                    </div>
                                                </div>



                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST PIS"
                                                            maxLength={70}
                                                            name="CST PIS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setCSTpis(e)
                                                            }}
                                                            value={CSTpis}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%PIS"
                                                            maxLength={70}
                                                            name="%PIS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setPorcemPIS(e)
                                                            }}
                                                            value={porcemPIS}
                                                        />
                                                    </div>
                                                </div>



                                                <div className="col-sm">
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="CST Cofins"
                                                            maxLength={70}
                                                            name="CST Cofins"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setCSTcofins(e)
                                                            }}
                                                            value={CSTcofins}
                                                        />
                                                    </div>
                                                    <div className="col-10">
                                                        <Inputs
                                                            label="%COFINS"
                                                            maxLength={70}
                                                            name="%COFINS"
                                                            classNameInput="form-control"
                                                            classNameLabel="form-label"
                                                            required={true}
                                                            onchange={function (e: any) {
                                                                setPorcemcofins(e)
                                                            }}
                                                            value={porcemcofins}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}


                            <br />

                            <div className="card-footer">
                                <div className="col">
                                    <div className="text-sm-end">
                                        <button className="btn btn-cancelar mb-2 me-1" onClick={function () {
                                            Navigate(-1)
                                        }} type="button">
                                            Voltar
                                        </button>
                                        <button className="btn btn-novo mb-2 me-1" onClick={salvarNoBanco}>
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>


            </div>
            <ModalCarregando show={showCarregando} />

            {/*MODAL QUE CONFIRMA A EDIÇÃO*/}
            <Modal show={ModalConfirmarSalvarBanco} backdrop="static" size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
                    <BsCardChecklist size={"100px"} color="#f58619" />
                    <div className="row" style={{ textAlign: 'center' }}>
                        <div className="col-sm" >
                            <h3>Grupo editado com sucesso!</h3>
                        </div>
                    </div>
                    <hr />

                    <div className="d-flex my-2 gap-1 justify-content-between">
                        <Button variant="success" style={{ borderRadius: '20px' }} type="button" onClick={function () {
                            Navigate(-1)
                        }} >
                            <Icon path={mdiCheckCircle} size={1} className="me-2" />
                            Ok
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default EditarGrupo