import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import { Api } from '../../../services/api';
import InputSelect from '../../../components/componentes/formulario/InputSelect';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import { mdiMagnify } from '@mdi/js';
import { colunasGrid } from './components/colunasGrid';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';
import { useNavigate } from 'react-router-dom';

export function LogSistemaAcaoUsuario() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil_permissao = sessionStorage.getItem('IsIdPerfilFKL')!;
	const navigate = useNavigate();

	const [showCarregando, setShowCarregando] = useState(false);

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil_permissao);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarLogAcaoUsuario) {
				toast.warning('Você não tem permissão para acessar os logs de ação do usuário');
				navigate('/');
			}
		}
	}, [permissoes, erro, navigate]);

	// dados filtro
	const [acao, setAcao] = useState('');
	const [tabela, setTabela] = useState('');
	const [descricao, setDescricao] = useState('');

	const [dados, setDados] = useState([]);
	const [dadosAcao, setDadosAcao] = useState([]);
	const [dadosTabela, setDadosTabela] = useState([]);

	const dadosFiltro = {
		acao,
		tabela,
		descricao,
	};
	function CarregarDados(id_filial: string) {
		setShowCarregando(true);
		axios
			.post(Api + `/logssistema/acaousuario/listar/${id_filial}`, dadosFiltro, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setDados(resposta.data.log_acao);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	const [itensPorPagina, setItensPorPagina] = useState(10);

	// Carregar Tabela
	function CarregarDadosTabela(id_filial: string) {
		axios
			.get(Api + `/logssistema/acaousuario/listar/tabela/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosTabela(resposta.data);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	// seleciona a tabela
	const selecionarTabela = (tabela: string) => {
		setTabela(tabela);
		setDadosAcao([]);
	};

	// Carregar Ação
	function CarregarDadosAcao(id_filial: string, tabela: string) {
		axios
			.get(Api + `/logssistema/acaousuario/listar/acao/${id_filial}/${tabela}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosAcao(resposta.data);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Log's de Ação do Usuário</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							CarregarDados(id_filial);
						}}
					>
						<div className="row">
							<div className="col-lg-3 col-sm">
								<InputSelect
									label="Tabela"
									name="tabela"
									value={tabela}
									onChange={selecionarTabela}
									dados={dadosTabela}
									onClick={() => CarregarDadosTabela(id_filial)}
									disabled={false}
									campoValue="value"
								/>
							</div>
							<div className="col-lg-3 col-sm">
								<InputSelect
									label="Ação"
									name="acao"
									value={acao}
									onChange={setAcao}
									dados={dadosAcao}
									onClick={() => CarregarDadosAcao(id_filial, tabela)}
									disabled={!tabela}
									campoValue="value"
								/>
							</div>
							<div className="col-lg-3 col-sm">
								<div className="form-floating">
									<input
										type="text"
										value={descricao}
										onChange={(e) => setDescricao(e.target.value)}
										className="form-control bordasInferiorInput text-black"
										id="nf"
										name="nf"
									/>
									<label>Descrição</label>
								</div>
							</div>
							<div className="col-sm col-md col-lg">
								<BotaoCustomizado
									type="submit"
									className="btn btn-success mt-3 mb-2"
									descricaoBotao="Pesquisar"
									icone={mdiMagnify}
									onclick={() => {}}
									size={1}
								/>
							</div>
						</div>
					</form>

					<ClickableTable
						coluna={colunasGrid}
						itemsPerPage={itensPorPagina}
						data={dados}
						usaRowClick={false}
						onRowClick={() => {}}
						acaoEditar={function () {}}
						acaoExcluir={() => {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
		</div>
	);
}
