import { mdiEye } from '@mdi/js';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from '../../../../services/api';
import Icon from '@mdi/react';

const VisualizarPedido = (): JSX.Element => {
	//#region
	//#region => VARIÁVEIS - PEDIDO
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const { id } = useParams();
	const [dadosDoPedido, setDadosDoPedido] = useState({
		id: '',
		id_pedido: '',
		data_carga: '',
		depositante: '',
		codfilial: '',
		pedido_sistema: '',
		pedido_cliente: '',
		min_validade: '',
		destinatario: '',
		unid_pedido: '',
		pb_pedido: null,
		pl_pedido: null,
		qtd_pedido: null,
		rota: null,
		status: null,
		data_programacao: null,
		os: null,
		data_importacao: null,
		data_adicao: '',
		usuario_adicao: '',
		data_alteracao: null,
		usuario_alteracao: null,
		cnpj_depositante: '',
		nome_filial: null,
		listaDeItens: []
	});
	const [menuUnidPedidos, setMenuUnidPedidos] = useState([]);
	const [menuDepositante, setMenuDepositante] = useState([]);
	const navigate = useNavigate();
	//#endregion

	//#region => VARIÁVEIS - LISTA DE ITENS
	const [itemClicado, setItemClicado] = useState({
		cod_produto: '',
		criterio: null,
		data_adicao: '',
		data_alteracao: null,
		data_importacao: null,
		data_programacao: null,
		data_quebrafefo: null,
		data_validade: null,
		descricao: '',
		id: 0,
		id_chave: null,
		id_pedido: 0,
		id_processo: null,
		os: null,
		pb_pedido: 0,
		pb_separado: null,
		pick_concluido: null,
		pl_pedido: 0,
		pl_separado: null,
		produto_wms: 0,
		qtd_caixas: 0,
		qtd_fiscal_separada: null,
		qtd_original: 0,
		qtd_referencia: null,
		qtd_separada: null,
		quebra_fefo: null,
		romaneado: null,
		rota: null,
		roteiro: null,
		status: null,
		tipo_peso: '',
		tipo_temperatura: '',
		unid_referencia: null,
		unid_separacao: null,
		usuario_adicao: '',
		usuario_alteracao: null,
		usuario_quebrafefo: null
	});
	//#endregion

	//#region => BUSCAR DADOS DO PEDIDO
	const buscarDadosDoPedido = async () => {
		const resposta = await axios.get(Api + `/pedido/?auth=${token}&id=${id}`);
		console.log(resposta.data);
		setDadosDoPedido(resposta.data);
	}
	//#endregion

	//#region => CARREGAR MENU - UNIDADE PEDIDO
	const carregarMenuUnidPedido = async () => {

		try {
			const response = await axios.get(Api + `/menu-unid-pedidos/?auth=${token}`);
			setMenuUnidPedidos(response.data);

		} catch (error) {
			//inserir arregamento de modal
			console.log(error);
		}
	}
	//#endregion

	//#region => CARREGAR MENU - DEPOSITANTE
	const carregarMenuDepositante = async () => {
		const resposta = await axios.get(Api + `/depositantes/?auth=${token}`);
		setMenuDepositante(resposta.data);
	}
	//#endregion

	//#region => VISUALIZAR ITEM DA LISTA
	const visualizarItem = (id: number) => {
		const item = dadosDoPedido.listaDeItens.filter((itemDaLista: any) => itemDaLista.id === id);
		setItemClicado(item[0]);
	}
	//#endregion

	//#region => CARREGAR DADOS NA TELA
	useEffect(() => {		
		carregarMenuUnidPedido();
		carregarMenuDepositante();
		buscarDadosDoPedido();
	}, [])
	//#endregion

	//#endregion
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Visualizar Pedido: {dadosDoPedido.id_pedido}</h4>
						</div>
					</div>
				</div>
				<form id="form-utilitarios">
					<div className="card">

						<div className="card-body">
							<ul className="nav nav-pills bg-nav-pills nav-justified mb-2">
								<li className="nav-item">
									<a
										href="#tab-porcEnt-digNotFisc-Geral"
										data-bs-toggle="tab"
										aria-expanded="true"
										className="nav-link rounded-0 active"
									>
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">GERAL</span>
									</a>
								</li>
							</ul>
							
							<div className="tab-content">
								<div className="tab-pane show active" id="tab-porcEnt-digNotFisc-Geral">
									<div className="row mb-1">
										<div className="col-sm-3">
											<label className="form-label">ID Pedido</label>
											<input type="text" className="form-control" value={id} disabled />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Data Carga</label>
											<input type="date" className="form-control" value={dadosDoPedido.data_carga.split('T').join(' ').slice(0, 10)} disabled/>
										</div>
										<div className="col-sm-3">
											<label className="form-label">Pedido Sistema</label>
											<input type="text" className="form-control" value={dadosDoPedido.pedido_sistema} disabled/>
										</div>
										<div className="col-sm-3">
											<label className="form-label">Pedido Cliente</label>
											<input type="text" className="form-control" value={dadosDoPedido.pedido_cliente} disabled/>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<label className="form-label">Depositante</label>
											<select className="form-select" value={dadosDoPedido.depositante} disabled>
												{menuDepositante.map((opcao: any, index: number) => {
													return (
														<option key={index} value={opcao.coddepositante}>{opcao.coddepositante} - {opcao.fantasia}</option>
													)
												})}
											</select>
										</div>
										<div className="col-sm-4">
											<label className="form-label">CNPJ</label>
											<input type="text" className="form-control" value={dadosDoPedido.cnpj_depositante} disabled />
										</div>
										<div className="col-sm-4">
											<label className="form-label">Validade Min Aceita</label>
											<input type="date" className="form-control" value={dadosDoPedido.min_validade.split('T').join(' ').slice(0, 10)} disabled/>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-6">
											<label className="form-label">Unid. Pedidos</label>
											<select className="form-select" value={dadosDoPedido.unid_pedido} disabled>
												{menuUnidPedidos.map((opcao: any, index: number) => {
													return (
														<option key={index} value={opcao.codigo}>{opcao.codigo} - {opcao.descricao}</option>
													)
												})}
											</select>
										</div>
										<div className="col-sm-6">
											<label className="form-label">Destinatário</label>
											<input type="text" className="form-control" value={dadosDoPedido.destinatario} disabled/>
										</div>
									</div>

									<hr className="mt-3 mb-3" />

									{/**tabela mostrando os itens adicionados ao pedido */}
									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
											<thead className="table-light">
												<tr>
													<th>Item</th>
													<th>Código Produto</th>
													<th>Descrição</th>
													<th>Qtde Original</th>
													<th>Qtde Cx</th>
													<th>Status</th>
													<th>Ações</th>
												</tr>
											</thead>
											<tbody>
												{dadosDoPedido.listaDeItens.map((item: any, index: number) => {
													return (
														<tr key={index}>
															<td>{item.numitem}</td>
															<td>{item.produto_wms} - {item.cod_produto}</td>
															<td>{item.descricao}</td>
															<td>{item.qtd_original}</td>
															<td>{item.qtd_caixas}</td>
															<td>PENDENTE</td>
															<td className="table-action">
																<button type="button" className="action-icon" onClick={() => visualizarItem(item.id)} style={{ border: "none", background: "none" }} data-bs-toggle="modal" data-bs-target="#modal-gestPedidos-ListPed-visItemPed">
																	<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
																</button>
															</td>
														</tr>

													)
												})}
											</tbody>
										</table>
									</div>
									{/**modal para visualização de item*/}
									<div className="modal fade" id="modal-gestPedidos-ListPed-visItemPed" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
										<div className="modal-dialog modal-full-width modal-dialog-centered">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="staticBackdropLabel">
														Visualização de Item do Pedido
													</h5>
													<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
												</div>
												<div className="modal-body">
													<div className="card">
														<div className="card-body">
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">ID Pedido</label>
																	<input type="text" className="form-control" value={dadosDoPedido.id_pedido} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Sistema</label>
																	<input type="text" className="form-control" value={dadosDoPedido.pedido_sistema} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Cliente</label>
																	<input type="text" className="form-control" value={dadosDoPedido.pedido_cliente} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Item Pedido</label>
																	<input type="text" className="form-control" value={itemClicado.id} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Código Produto WMS</label>
																	<select className="form-select" value={`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`} disabled>
																		<option value={`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`}>{`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`}</option>

																	</select>
																</div>
																<div className="col-sm-6">
																	<label className="form-label">Descrição</label>
																	<input type="text" className="form-control" value={itemClicado.descricao} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Tipo Peso</label>
																	<select className="form-select" value={itemClicado.tipo_peso} disabled>
																		<option value={itemClicado.tipo_peso}>{itemClicado.tipo_peso}</option>
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Tipo Temperatura</label>
																	<select className="form-select" value={itemClicado.tipo_temperatura} disabled>
																		<option value={itemClicado.tipo_temperatura}>{itemClicado.tipo_temperatura}</option>
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Bruto</label>
																	<input type="number" className="form-control" value={itemClicado.pb_pedido} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Líquido</label>
																	<input type="number" className="form-control" value={itemClicado.pl_pedido} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Quantidade Original</label>
																	<input type="number" className="form-control" value={itemClicado.qtd_original} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Qtde CxS</label>
																	<input type="number" className="form-control" value={itemClicado.qtd_caixas} disabled />
																</div>
															</div>
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
																Voltar
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end mb-2">
									<button
										className="btn btn-danger me-1"
										type="button"
										onClick={() => navigate('/pco/contrFisc/gestPedidos/listagemDosPedidos')}
									>
										Voltar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export { VisualizarPedido };
