import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { mdiReceiptTextPlusOutline } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';

const ExecaoQuebraFEFO = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');

	const [data, setData] = useState([]);
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	//Limpar pedido, aguardar 1 segundo e focar no idpedido
	const focarIdPedido = () => {
		setIdPedido('');
		setTimeout(() => {
			setIdPedido('');
			idPedidoRef.current?.focus();
		}, 1000);
	};

	// dados
	const [permExcecaoQuebraFefo, setPermExcecaoQuebraFefo] = useState<boolean>(false);
	const [idPedido, setIdPedido] = useState<string>('');
	const idPedidoRef = useRef<HTMLInputElement | null>(null);
	const [qtdPedido, setQtdPedido] = useState<number>(0);
	const [codigo, setCodigo] = useState<string>('');
	const [id_wms, setIdWMS] = useState<string>('');
	const [motivo, setMotivo] = useState<string>('');
	const [dadosCodigo, setDadosCodigo] = useState<any[]>([]);

	function validarPermissao() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando permissão, aguarde...');
		axios
			.get(`${Api}/excecao/quebrafefo/permissao/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setPermExcecaoQuebraFefo(resposta.data.perm_quebra_fefo === 1 ? true : false);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Quebra FEFO, aguarde...');
		axios
			.get(`${Api}/excecao/quebrafefo/listar/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.quebra_fefo);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		validarPermissao();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//#region  --> Validar ID do Pedido
	function validarIDPedido() {
		if (!idPedido) return;
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando ID do Pedido');
		axios
			.get(`${Api}/excecao/quebrafefo/validarpedido/${id_filial}/${idPedido}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				if (resposta.data.pedido === 0) {
					toast.error('Pedido não encontrado');
					setDadosCodigo([]);
					setCodigo('0');
					setQtdPedido(0);
					focarIdPedido();
					return;
				}
				setDadosCodigo([]);
				setCodigo('0');
				setQtdPedido(resposta.data.pedido);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region  --> Carregar Codigos para o input select
	function CarregarCodigos() {
		axios
			.get(`${Api}/excecao/quebrafefo/carregarcodigo/${id_filial}/${idPedido}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setDadosCodigo(resposta.data.pedido);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	const dadosEnvio = {
		id_filial,
		id_pedido: idPedido,
		id_wms,
		codigo,
		motivo,
		usuario,
	};

	// Incluir Exceção Quebra FEFO
	const incluirExcecaoQuebraFefo = (dados: any) => {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Incluindo Exceção Quebra FEFO');
		axios
			.post(`${Api}/excecao/quebrafefo/incluir/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(response.data.message);
				setIdPedido('');
				setCodigo('0');
				setMotivo('');
				setIdWMS('');
				setQtdPedido(0);
				carregarDados();
			})
			.catch((erro) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	// Capturar IDWMS ao selecionar codigo
	const selecionarCodigo = (codigo: string) => {
		setCodigo(codigo);

		// ao selecionar um codigo, capturar o idwms  em dadosCodigo
		const _idwms: any = dadosCodigo.find((item: any) => item.value === codigo);

		if (_idwms) {
			setIdWMS(_idwms.id_wms);
		} else {
			setIdWMS('');
		}
	};
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Exceção - Quebra FEFO Picking</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<form
									onSubmit={function (e) {
										e.preventDefault();
										incluirExcecaoQuebraFefo(dadosEnvio);
									}}
								>
									<div className="row">
										<div className="col-sm col-md col-lg-3">
											<InputSemZeroEsquerda
												id="idPedido"
												name="idPedido"
												label="ID. Pedido"
												type="text"
												value={idPedido}
												onChange={setIdPedido}
												onBlur={validarIDPedido}
												useRef={idPedidoRef}
												required
											/>
										</div>
										<div className="col-sm col-md col-lg-3">
											<InputSelect
												dados={dadosCodigo}
												value={codigo}
												onChange={selecionarCodigo}
												label="Código"
												onClick={CarregarCodigos}
												name="codigo"
												disabled={qtdPedido === 0 ? true : false}
												textoSelecao="Todos"
												required
												valueTextoSelecao="0"
											/>
										</div>
										<div className={!permExcecaoQuebraFefo ? `col-sm col-md col-lg-6` : `col-sm col-md col-lg-5`}>
											<div className="form-floating">
												<input
													required
													value={motivo}
													onChange={(e) => setMotivo(e.target.value)}
													className="form-control bordasInferiorInput text-black text-uppercase"
													placeholder=" "
													//ao rececebr o foco verificar se o idPedido está preenchido senao focar no idPedido
													onFocus={function () {
														if (idPedido === '') {
															focarIdPedido();
														}
													}}
												/>
												<label>Motivo</label>
											</div>
										</div>

										{permExcecaoQuebraFefo && (
											<div className="col-sm col-md col-lg">
												<BotaoCustomizado
													type="submit"
													className="btn btn-success mt-3"
													descricaoBotao="Gravar"
													icone={mdiReceiptTextPlusOutline}
													onclick={() => {}}
													size={1}
												/>
											</div>
										)}
									</div>
								</form>
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={false}
										acaoEditar={() => {}}
										acaoExcluir={() => {}}
										acaoVisualizar={() => {}}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa="id_pedido"
										labelpesquisa="Procurar por id. pedido"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />
		</>
	);
};

export { ExecaoQuebraFEFO };
