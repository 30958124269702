import { Link } from 'react-router-dom';

const Utilitarios = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Listagem de Unidades e Utilitários</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="col-sm-4">
								<Link to={`/config/cadast/utilitarios/${0}`} className="btn btn-info rounded-pill mb-2">
									<i className="mdi mdi-plus-circle me-2"></i>
									Novo Utilitário
								</Link>
							</div>
							<div className="table-responsive">
							<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
									<thead className="table-cabecalho">
										<tr>
											<th>Tabela</th>
											<th>Nome</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>ABCCODE</td>
											<td>Codigos ABC</td>
											<td className="table-action">
												<Link to={`/config/cadast/utilitarios/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
										<tr>
											<td>CATPROD</td>
											<td>Codigos ABC</td>
											<td className="table-action">
												<Link to={`/config/cadast/utilitarios/${0}`} className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</Link>
												<a href="javascript:void(0);" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Utilitarios };
