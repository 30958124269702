function MascaraCNPJ(cnpj: string) {

    return cnpj
        .replace(/\D/g, '') 
        .replace(/^(\d{2})(\d)/, '$1.$2') 
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') 
        .replace(/\.(\d{3})(\d)/, '.$1/$2') 
        .replace(/(\d{4})(\d)/, '$1-$2') 
        .replace(/(-\d{2})\d+?$/, '$1'); 
}


export default MascaraCNPJ