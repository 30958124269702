import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiKeyboardReturn, mdiMagnify, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';

const Embalagem = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const [collapsed, setCollapsed] = useState(true);
	const perfil = sessionStorage.getItem('IsIdPerfilFKL');
	const filial = sessionStorage.getItem('IsIDFilialPadraoFKL');
	const [data, setData] = useState([]);

	//#region Requisição de dados na api
	const carregarDados = async () => {
		const response = await axios.get(Api + `/embalagem/json/?&auth=${token}&codfilial=${filial}`);
		setData(response.data);
		setShowCarregando(false)
	};
	useEffect(() => {
		setShowCarregando(true)
		carregarDados()
	}, []);

	//#endregion

	//#region Requisição para EXCLUIR um Usuario
	const excluir = () => {
		const id = sessionStorage.getItem("FKLIDReg")
		axios.delete(Api + `/embalagem/json/?id=${id}&auth=${token}`);
		sessionStorage.removeItem("FKLIDReg")
		setShowQuestionaExclusao(false)
		setShowCarregando(true)
		setTimeout(() => carregarDados(), 500);

	}
	//#endregion
	//#endregion
	const excluirEmbalagem = (id: any) => {
		sessionStorage.setItem("FKLIDReg", id)
		setShowQuestionaExclusao(true)
	}

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  
	//#region Modifica o status do Modal PESQUISAR EMPRESA
	const [showPesquisar, setShowPesquisar] = useState(false);
	const fecharModalPesquisar = () => setShowPesquisar(false);
	//#endregion 

	//#region Modifica o status do Modal EXCLUIR EMPRESA
	const [showQuestionaExclusao, setShowQuestionaExclusao] = useState(false);
	const fecharModalQuestionaExclusao = () => setShowQuestionaExclusao(false);
	//#endregion 


	//#region Paginação,
	const [itensPerPage, setItensPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const pages = Math.ceil(data.length / itensPerPage);

	const startIndex = currentPage * itensPerPage;
	const endIndex = startIndex + itensPerPage;
	const itensSelecionados = data.slice(startIndex, endIndex);
	//#endregion
	//#region Modifica pagina da lista de relatorios
	const primeiraPagina = () => {
		if (currentPage > 0) {
			setCurrentPage(0)
		}
	}
	const paginaAnterior = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1)
		}
	}
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage
		if (pagina < pages) {
			setCurrentPage(currentPage + 1)
		}
	}
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + currentPage
		if (pagina < pages) {
			setCurrentPage(pages - 1)
		}
	}
	//#endregion
	const [inputsPesquisa, setInputsPesquisa] = useState({
		id: '',
		codigo: '',
		descricao: ''
	})
	const CapturarDados = (event: any) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputsPesquisa(values => ({ ...values, [name]: value }))
	};
	//#region Requisição de dados na api
	const filtrarDados = async () => {
		const response = await axios.get(Api + `/embalagem/pesquisar/json/?&auth=${token}&codfilial=${filial}&id=${inputsPesquisa.id}&codigo=${inputsPesquisa.codigo}&descricao=${inputsPesquisa.descricao}`);
		setData(response.data);
		setShowPesquisar(false)
		setShowCarregando(false)

	};

	//#endregion
	const pesquisarDados = (event: any) => {
		event.preventDefault();
		if (inputsPesquisa.id || inputsPesquisa.codigo || inputsPesquisa.descricao)
			filtrarDados()
		else if (!inputsPesquisa.id && !inputsPesquisa.codigo && !inputsPesquisa.descricao) {
			setShowPesquisar(false);
			setShowCarregando(true);
			carregarDados();
		}
	}

	const [inputs, setInputs] = useState({
		idperfil: '',
		descricao: '',
		perm_visu: 0,
		perm_incluir: 0,
		perm_editar: 0,
		perm_excluir: 0,
	});
	useEffect(() => {

		async function buscarDadosPerfil() {
			if (perfil !== '') {
				const { data } = await axios.get(Api + `/perfil/json/id/?id=${perfil}&auth=${token}`);
				const dataFormatada = {
					idperfil: data[0].id_perfil,
					descricao: data[0].nom_perfil,
					perm_visu: data[0].conf_cad_embalagem_visu,
					perm_incluir: data[0].conf_cad_embalagem_incluir,
					perm_editar: data[0].conf_cad_embalagem_editar,
					perm_excluir: data[0].conf_cad_embalagem_excluir,
				}
				setInputs(dataFormatada);
			}
		}
		if (perfil) buscarDadosPerfil();
	}, [perfil]);

	//#region VALIDAR se o operador tem permissao para incluir empresa
	const [buttonIncluir, setbuttonIncluir] = useState("btn btn-novo mb-2 disabled")
	useEffect(() => {
		if (inputs.perm_incluir == 1) {
			setbuttonIncluir("btn btn-novo mb-2");
		}
		else if (inputs.perm_incluir == 0) {
			setbuttonIncluir("btn btn-novo mb-2 disabled");
		}
	})
	//#endregion

	//#region VALIDAR se o operador tem permissao para Incluir empresa
	const [buttonVisualizar, setbuttonVisualizar] = useState(false)
	useEffect(() => {
		if (inputs.perm_visu == 1) {
			setbuttonVisualizar(false);
		}
		else if (inputs.perm_visu == 0) {
			setbuttonVisualizar(true);
		}
	})
	//#endregion

	//#region VALIDAR se o operador tem permissao para Incluir empresa
	const [buttonEditar, setbuttonEditar] = useState(false)
	useEffect(() => {
		if (inputs.perm_editar == 1) {
			setbuttonEditar(false);
		}
		else if (inputs.perm_editar == 0) {
			setbuttonEditar(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Excluir empresa
	const [buttonExcluir, setbuttonExcluir] = useState(false)
	useEffect(() => {

		if (inputs.perm_excluir == 1) {
			setbuttonExcluir(false);
		}
		else if (inputs.perm_excluir == 0) {
			setbuttonExcluir(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Editar ou Excluir uma empresa caso nao tenha nenhuma das duas a coluna fica oculta
	const [validar, setValidar] = useState(false)
	useEffect(() => {

		if (!buttonVisualizar || !buttonEditar || !buttonExcluir) {
			setValidar(false);
		}
		else if (buttonVisualizar && buttonEditar && buttonExcluir) {
			setValidar(true);
		}
	})
	//#endregion
	//#region VALIDAR se o operador tem permissao para Editar ou Excluir uma empresa caso nao tenha nenhuma das duas a coluna fica oculta
	const [validaAcao, setValidaAcao] = useState("d-flex flex-nowrap table-action visually-hidden")
	useEffect(() => {

		if (!buttonVisualizar || !buttonEditar || !buttonExcluir) {
			setValidaAcao("d-flex flex-nowrap table-action");
		}
		else if (buttonVisualizar && buttonEditar && buttonExcluir) {
			setValidaAcao("d-flex flex-nowrap table-action visually-hidden");
		}
	})
	//#endregion
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem das Embalagens</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2">
									<div className="col-sm-6">
										<Link to={`/config/cadast/embalagem/novo`} className={`${buttonIncluir} rounded`}>
											<i className="mdi mdi-plus-circle me-2"></i>
											Nova Embalagem
										</Link>
									</div>
									<div className="col-sm-6">
										<Button variant="success" onClick={() => setShowPesquisar(true)}
											className="mb-2 rounded" style={{ float: 'right', color: 'white'}}>
											Pesquisar
											<Icon path={mdiMagnify} size={1} className="me-2" />
										</Button>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
										<thead className="table-cabecalho">
											<tr>
												<th>ID. Embalagem</th>
												<th>Cód. Embalagem</th>
												<th>Descrição</th>
												<th>Qtde. Unid. Principal</th>
												<th>Qtde. Embalagem Interna</th>
												<th>Qtde. por Caixa</th>
												<th>Qtde. por Palete</th>
												<th hidden={validar} style={{ width: '85px' }}>Ações</th>
											</tr>
										</thead>
										<tbody className='corpoTabela'>
											{
												data.map(function (dados: any) {
													return (
														<tr>
															<td>{dados.id}</td>
															<th scope="row">{dados.codembalagem}</th>
															<td>{dados.descricao}</td>
															<td>{dados.qtd_und_medida1.toLocaleString("pt-BR", {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2
															})}</td>
															<td>{dados.qtd_und_medida2.toLocaleString("pt-BR", {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2
															})}</td>
															<td>{dados.qtd_und_medida3.toLocaleString("pt-BR", {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2
															})}</td>
															<td>{dados.qtd_und_medida4.toLocaleString("pt-BR", {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2
															})}</td>
															<td className={validaAcao}>
																<Link to={`/config/cadast/embalagem/visu/${dados.id}`} className="action-icon" title="Visualizar" hidden={buttonVisualizar}>
																	<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
																</Link>
																<Link to={`/config/cadast/embalagem/editar/${dados.id}`} className="action-icon" title="Editar" hidden={buttonEditar}>
																	<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
																</Link>
																<a href="javascript:void(0);" className="action-icon" onClick={() => excluirEmbalagem(dados.id)} title="Excluir" hidden={buttonExcluir}>
																	<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
																</a>
															</td>
														</tr>
													)
												})
											}

										</tbody>
										<tfoot>
											<tr className="rodapeTabelaPaginacao">
												<td colSpan={14}>
													<a onClick={primeiraPagina} title="Primeira Página">
														<Icon path={mdiSkipBackward} size={1} />
													</a>
													<a onClick={paginaAnterior} title="Página Anterior">
														<Icon path={mdiSkipPrevious} size={1} />
													</a>
													Página: {currentPage + 1} de {pages} | Registros Total: {data.length}
													<a onClick={proxPagina} title="Próxima Página">
														<Icon path={mdiSkipNext} size={1} />
													</a>
													<a onClick={ultimaPagina} title="Última Página">
														<Icon path={mdiSkipForward} size={1} />
													</a>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>
			{/*MODAL QUESTIONA EXCLUSAO */}
			<Modal
				show={showQuestionaExclusao}
				backdrop="static"
				size="lg"
				keyboard={false}
				centered
			>
				<Modal.Header>
					<Modal.Title>Confirmar Exclusão?</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
					<BsExclamationCircleFill size={"100px"} color="#f58619" />
					<div className="row" style={{ textAlign: 'center' }}>
						<div className="col-sm" >
							<h3>Tem certeza que deseja realizar essa operação?</h3>
						</div>
					</div>
					<hr />
					<div className="d-flex my-2 gap-1 justify-content-between">
						<Button variant="success" style={{ borderRadius: '20px' }} type="button" onClick={excluir} > <Icon path={mdiCheckCircle} size={1} className="me-2" />Sim</Button>
						<Button style={{ borderRadius: '20px', float: 'left' }} onClick={() => setShowQuestionaExclusao(false)}><Icon path={mdiCloseCircle} size={1} className="me-2" /> Não </Button>
					</div>
				</Modal.Body>
			</Modal>
			{/*MODAL PESQUISAR */}
			<Modal
				show={showPesquisar}
				backdrop="static"
				size="xl"
				keyboard={false}
				centered
			>
				<Modal.Header>
					<Modal.Title>Pesquisar Embalagem</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={pesquisarDados}>

						<div className="row">
							<div className="col-sm-2 form-floating">

								<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="id"
									name="id"
									placeholder=''
									value={inputsPesquisa.id || ""}
									onChange={CapturarDados}
								/>
								<label className="form-label">ID. Embalagem</label>
							</div>
							<div className="col-sm-2 form-floating">

								<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="codigo"
									name="codigo"
									value={inputsPesquisa.codigo || ""}
									onChange={CapturarDados}
									placeholder=''
								/>
								<label className="form-label">Cód. Embalagem</label>
							</div>
							<div className="col-sm-8 form-floating">

								<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="descricao"
									name="descricao"
									placeholder=''
									value={inputsPesquisa.descricao || ""}
									onChange={CapturarDados}
								/>
								<label className="form-label">Descrição</label>
							</div>

						</div>

						<hr />

						<div className="d-flex my-2 gap-1 justify-content-between">
							<Button style={{float: 'left' }} className='rounded' onClick={fecharModalPesquisar}><Icon path={mdiKeyboardReturn} size={1} className="me-2" /> Voltar </Button>
							<Button variant="success" type="submit" className='rounded'> <Icon path={mdiMagnify} size={1} className="me-2" />Filtrar</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export { Embalagem };
