import { Link } from 'react-router-dom';

const AutorizacaoEntrada = (): JSX.Element => {
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Autorização de Entrada</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2">
									<div className="col-sm-12 mb-2">
										<a
											className="btn ounded-pill"
											data-bs-toggle="modal"
											data-bs-target="#modal-ger-proc-autorizEntrada-autEntrada"
											style={{ backgroundColor: "#1c4b9b", color: "white" }}
										>
											<i className="mdi mdi-plus-circle me-2"></i>
											Autorizar
										</a>
									</div>
									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap">
											<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
												<tr>
													<th>#</th>
													<th>Processo</th>
													<th>Depositante</th>
													<th>Chegada</th>
													<th>Tipo Operação</th>
													<th>Modalidade</th>
													<th>Status</th>
													<th>Ações</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>61</td>
													<td>Depositante 1</td>
													<td>29/11/21 16:00:00</td>
													<td>ENTRADA</td>
													<td>CROSSDOCKING</td>
													<td>ABERTO</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/gerenciamento/processos/autorizacaoEntrada/${0}`}
															className="action-icon"
														>
															<i className="mdi mdi-square-edit-outline"></i>
														</Link>
													</td>
												</tr>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>60</td>
													<td>Depositante 2</td>
													<td>29/11/21 15:00:00</td>
													<td>ENTRADA</td>
													<td>ARMAZENAGEM</td>
													<td>CARGA/DESCARGA</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/gerenciamento/processos/autorizacaoEntrada/${0}`}
															className="action-icon"
														>
															<i className="mdi mdi-square-edit-outline"></i>
														</Link>
													</td>
												</tr>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>59</td>
													<td>Depositante 3</td>
													<td>28/11/21 12:00:00</td>
													<td>ENTRADA</td>
													<td>ARMAZENAGEM</td>
													<td>AUTORIZAÇÃO DE ENTRADA</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/gerenciamento/processos/autorizacaoEntrada/${0}`}
															className="action-icon"
														>
															<i className="mdi mdi-square-edit-outline"></i>
														</Link>
													</td>
												</tr>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>58</td>
													<td>Depositante 4</td>
													<td>27/11/21 14:00:00</td>
													<td>ENTRADA</td>
													<td>DEVOLUÇÕES</td>
													<td>LIBERADO</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/gerenciamento/processos/autorizacaoEntrada/${0}`}
															className="action-icon"
														>
															<i className="mdi mdi-square-edit-outline"></i>
														</Link>
													</td>
												</tr>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>57</td>
													<td>Depositante 5</td>
													<td>26/11/21 16:30:00</td>
													<td>ENTRADA</td>
													<td>RE-ENTRADA</td>
													<td>CANCELADO</td>
													<td className="d-flex flex-nowrap table-action">
														<Link
															to={`/gerenciamento/processos/autorizacaoEntrada/${0}`}
															className="action-icon"
														>
															<i className="mdi mdi-square-edit-outline"></i>
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-ger-proc-autorizEntrada-autEntrada"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Autorização Entrada
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card-body">
								<div className="row mb-1">
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<select className="form-select bordasInferiorInput text-black text-capitalize" aria-label="Doca">
												<option value="" selected>---</option>
											</select>
											<label htmlFor="doca">Doca</label>
										</div>
									</div>
								</div>
								<div className="row mb-1">
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<select className="form-select bordasInferiorInput text-black text-capitalize" aria-label="Conferente">
												<option value="" selected>---</option>
											</select>
											<label htmlFor="conferente">Conferente</label>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<select className="form-select bordasInferiorInput text-black text-capitalize" aria-label="Transportador">
												<option value="" selected>---</option>
											</select>
											<label htmlFor="transportador">Transportador</label>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<select className="form-select bordasInferiorInput text-black text-capitalize" aria-label="Qtde Auxiliares">
												<option value="" selected>---</option>
											</select>
											<label htmlFor="qtde_auxiliares">Qtde Auxiliares</label>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="mb-1 form-floating">
											<select className="form-select bordasInferiorInput text-black text-capitalize" aria-label="Operador Empilhadeira">
												<option value="" selected>---</option>
											</select>
											<label htmlFor="operador_empilhadeira">Operador Empilhadeira</label>
										</div>
									</div>
								</div>
							</div>

							<div className="card">
								<div className="modal-footer">
									<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { AutorizacaoEntrada };
