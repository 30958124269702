import { ITabela } from "../../../../components/Table/TabelaInterface";


export const colunasGrid: ITabela[] = [
	// id, id_filial, tabela, acao, descricao, cadastro, usuario 
	{
		titulo: 'ID',
		acesso: 'id',
	},	
	{
		titulo: 'Tabela',
		acesso: 'tabela',
	},
	{
		titulo: 'Ação',
		acesso: 'acao',
	},
	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'Cadastro',
		acesso: 'datacadastro',
	},
	{
		titulo: 'Usuário',
		acesso: 'usuario',
	},
];