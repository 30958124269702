import { ITabela } from '../../../../components/Table/TabelaInterface';

//Criação das colunas da tabela
export const colunasGridUASaida: ITabela[] = [
	{ titulo: 'ID U.A.', acesso: 'id_ua' },
	{ titulo: 'ID Depositante', acesso: 'id_cliente' },
	{ titulo: 'Depositante', acesso: 'fantasia' },
	{ titulo: 'Tipo', acesso: 'tipo_ua' },	
	{ titulo: 'Usuário', acesso: 'usuario' },
];
