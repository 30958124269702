import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';

const ControleNumeracao = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [tabela, setTabela] = useState<string>('');
	const [nome_id, setNome_id] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregando, setMsgModalCarregando] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	const editarControleNumeracao = (dados: any) => {
		navigate(`/config/cadast/controlenumeracao/${dados.tabela}/editar`);
	};
	const excluirControleNumeracao = (dados: any) => {
		setTabela(dados.tabela);
		setNome_id(dados.nome_id);
		setMsgPrincipalModal(`Deseja excluir o controle de numeração ${dados.tabela} - ${dados.nome_id} ?`);
		setMsgAcao1('Cancelar');

		setMostraModalConfirm(true);
	};
	const visualizarControleNumeracao = (dados: any) => {
		navigate(`/config/cadast/controlenumeracao/${dados.tabela}/visualizar`);
	};

	//Criação das colunas da tabela
	const colunas: ITabela[] = [
		{
			titulo: 'Tabela',
			acesso: 'tabela',
		},
		{
			titulo: 'Código',
			acesso: 'nome_id',
		},
		{
			titulo: 'Número',
			acesso: 'numero',
		},
	];

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregando('Aguarde, carregando dados...');
		axios
			.get(Api + `/lista/controlenumeracao/filial/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				setData(resposta.data.controlenumercao);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region -> Excluir
	function deleteVeiculo() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setMsgModalCarregando('Aguarde, excluindo registro...');
		axios
			.delete(Api + `/excluir/veiculo/${id_filial}/${tabela}/${nome_id}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				toast.success(resposta.data.message);
				setNome_id('');
				setTabela('');
				carregarDados();
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Controle de Numeração</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col d-flex justify-content-start">
										<Link className="btn btn-secondary" to={`/config/cadast/controlenumeracao/novo/criar`}>
											<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
											Novo Controle Numeração
										</Link>
									</div>
								</div>
								<div className="">
									<ClickableTable
										coluna={colunas}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={true}
										acaoEditar={editarControleNumeracao}
										acaoExcluir={excluirControleNumeracao}
										acaoVisualizar={visualizarControleNumeracao}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa="tabela"
										labelpesquisa="Procurar controle de numeração"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteVeiculo}
			/>

			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregando} />
		</>
	);
};

export { ControleNumeracao };
