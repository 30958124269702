import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { mdiFileImport, mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { ModeloTabelaCfop64 } from '../../../../util/modeloTabelaCfop';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const CFOPs = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [id_cfop, setIdCFOP] = useState<string>('');
	const [cfopNum, setCFOPNum] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);
	const [permissaoImportar, setPermissaoImportar] = useState<boolean>(false);
	const [permissaoExportar, setPermissaoExportar] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarCFOP) {
				toast.warning('Você não tem permissão para acessar Lista de CFOPs');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarCFOP);
				setPermissaoCriar(permissoes.perfilPermissao.criarCFOP);
				setPermissaoEditar(permissoes.perfilPermissao.editarCFOP);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirCFOP);
				setPermissaoImportar(permissoes.perfilPermissao.importarArqCFOP);
				setPermissaoExportar(permissoes.perfilPermissao.baixarModeloCFOP);
			}
		}
	}, [permissoes, erro, navigate]);

	const editarCFOP = (dados: any) => {
		navigate(`/cadast/fiscal/cfop/${dados.id}/editar`);
	};
	const excluirCFOP = (dados: any) => {
		setIdCFOP(dados.id);
		setCFOPNum(dados.cfop);
		const cfop = dados.cfop + ' - ' + dados.natureza_op;
		setMsgPrincipalModal(`Deseja excluir o CFOP ${cfop}`);
		setMsgAcao1('Cancelar');

		setMostraModalConfirm(true);
	};
	const visualizarCFOP = (dados: any) => {
		navigate(`/cadast/fiscal/cfop/${dados.id}/visualizar`);
	};

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando CFOPs');
		axios
			.get(`${Api}/lista/cfop`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.cfop);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region -> Excluir
	function deleteCFOP() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setMsgModalCarregar('Aguarde... excluindo CFOP');
		axios
			.delete(Api + `/excluir/cfop/${id_filial}/${id_cfop}/${cfopNum}/${usuario}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(resposta.data.message);
				setCFOPNum('');
				carregarDados();
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setCFOPNum('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Ler arquivo excel e converter para json
	const lerArquivoConverteParaJSON = (file: File, callback: (jsonData: any) => void) => {
		const reader = new FileReader();

		reader.onload = (event: any) => {
			const data = new Uint8Array(event.target.result as ArrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[0]; // Assuming you have only one sheet

			const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
			callback(jsonData);
		};

		reader.readAsArrayBuffer(file);
	};

	//Incluir CFOP em massa
	const incluirCFOPEmMssa = (dados: any) => {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Inserindo CFOPs');
		axios
			.post(
				`${Api}/insere/cfop`,
				{ dados, usuario },
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then((response) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(response.data.message);
				carregarDados();
			})
			.catch((erro) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	//Selecionar planilha de CFOP para importação
	const selecionarPlanilha = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			lerArquivoConverteParaJSON(file, (dados) => {
				incluirCFOPEmMssa(dados); // You can do whatever you want with the JSON data
			});
		}
	};
	//Converter base64 para excel e fazer download
	const downloadExcelFromBase64 = (base64String: string, fileName: string) => {
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

		FileSaver.saveAs(blob, fileName);
	};
	// Baixar modelo de tabela de CFOP
	const baixarModelo = () => {
		const base64String = ModeloTabelaCfop64; // Replace with your base64 string
		const fileName = 'modelo_tabela_cfop.xlsx';
		downloadExcelFromBase64(base64String, fileName);
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de CFOP's</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								{(permissaoCriar || permissaoImportar || permissaoExportar) && (
									<div className="row">
										<div className="col d-flex justify-content-start">
											{permissaoCriar && (
												<Link className="btn btn-secondary m-1" to={`/cadast/fiscal/cfop/novo/criar`}>
													<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
													Novo CFOP
												</Link>
											)}
											{permissaoImportar && (
												<>
													<label htmlFor="impExcel" className="btn btn-success m-1">
														<Icon path={mdiFileImport} size={0.75} className="me-2" /> Importar Tabela de CFOP [Excel]
													</label>
													<input
														id="impExcel"
														type="file"
														style={{ display: 'none' }}
														accept=".xls,.xlsx"
														onChange={selecionarPlanilha}
													/>
												</>
											)}
											{permissaoExportar && (
												<button className="btn btn-info m-1" onClick={baixarModelo}>
													<Icon path={mdiFileImport} size={1} className="me-2 rounded" />
													Baixar Modelo
												</button>
											)}
										</div>
									</div>
								)}
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={true}
										acaoEditar={editarCFOP}
										acaoExcluir={excluirCFOP}
										acaoVisualizar={visualizarCFOP}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="natureza_op"
										labelpesquisa="Procurar por cfop"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteCFOP}
			/>
		</>
	);
};

export { CFOPs };
