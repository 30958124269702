import { useEffect, useState } from 'react';
import { BotaoCustomizado } from '../../../components/componentes/formulario/BotaoCustomizado';
import { mdiFileRefreshOutline, mdiMagnify, mdiPrinterPosRefreshOutline } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import { colunasGridUAVazio } from './components/colunasGridUAVazio';
import { colunasGridUASaida } from './components/colunasGridUASaida';
import InputSelect from '../../../components/componentes/formulario/InputSelect';
import axios from 'axios';
import { Api } from '../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import removeEspacoTextoMin from '../../../components/componentes/types/removeEspacoTextoMin';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import { PdfDocumentUAVaziaSaida } from '../../operacional/impressaoEtiqueta/components/gerarEtiquetaUAVaziaSaida';
import imageToBase64 from 'image-to-base64/browser';
import JsBarcode from 'jsbarcode';
import { PdfDocumentUAVaziaSaidaParams } from '../../operacional/impressaoEtiqueta/components/gerarEtiquetaUAVaziaSaida/interface';
import logoPadrao from '../../../assets/img/logo3.png';
import ModalConfirmacao from '../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

const UASemUso = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizarVazio, setPermissaoVisualizarVazio] = useState(false);
	const [permissaoVisualizarSaida, setPermissaoVisualizarSaida] = useState(false);
	const [permissaoExcluirVazio, setPermissaoExcluirVazio] = useState<boolean>(false);
	const [permissaoExcluirSaida, setPermissaoExcluirSaida] = useState<boolean>(false);
	const [permissaoImprimirVazio, setPermissaoImprimirVazio] = useState<boolean>(false);
	const [permissaoImprimirSaida, setPermissaoImprimirSaida] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarUAVazioSaidaSemUsoVazio && !permissoes.perfilPermissao.visualizarUAVazioSaidaSemUsoSaida) {
				toast.warning('Você não tem permissão para acessar U.A. sem uso');
				navigate('/');
			} else {
				setPermissaoVisualizarVazio(permissoes.perfilPermissao.visualizarUAVazioSaidaSemUsoVazio);
				setPermissaoVisualizarSaida(permissoes.perfilPermissao.visualizarUAVazioSaidaSemUsoSaida);
				setPermissaoExcluirVazio(permissoes.perfilPermissao.excluirUAVazioSaidaSemUsoVazio);
				setPermissaoExcluirSaida(permissoes.perfilPermissao.excluirUAVazioSaidaSemUsoSaida);
				setPermissaoImprimirVazio(permissoes.perfilPermissao.imprimirUAVazioSaidaSemUsoVazio);
				setPermissaoImprimirSaida(permissoes.perfilPermissao.imprimirUAVazioSaidaSemUsoSaida);
			}
		}
	}, [permissoes, erro, navigate]);

	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [id_uaDelete, setIdUaDelete] = useState<string>('');
	const [tipoUADelete, setTipoUADelete] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	// Função para converter texto em imagem base64
	const converterTextoParaImagemBase64 = (barcode: string): string => {
		const canvas = document.createElement('canvas');
		JsBarcode(canvas, barcode, {
			format: 'CODE128',
			displayValue: false,
		});
		const dataURL = canvas.toDataURL('image/png');
		const base64 = dataURL;
		return base64;
	};

	// dados para gerar U.A. vazia
	const [id_uaVazio, setIdUaVazio] = useState('');
	const [id_cliente_vazio, setIdClienteVazio] = useState('');
	const [dadosDepositanteUaVazio, setDadosDepositanteUaVazio] = useState([]);
	const [dadosDimensaoEtiquetaUaVazio, setDadosDimensaoEtiquetaUaVazio] = useState<any[]>([]);
	const [dataUAVazio, setDataUAVazio] = useState([]);
	const [itensPorPaginaUAVazio, setItensPorPaginaUAVazio] = useState(10);

	const [tipoEtiquetaUAVazio, setTipoEtiquetaUAVazio] = useState<string>('');

	const [alturaEtiquetaUAVazio, setAlturaEtiquetaUAVazio] = useState<number>(0);
	const [larguraEtiquetaUAVazio, setLarguraEtiquetaUAVazio] = useState<number>(0);
	const [orientacaoEtiquetaUAVazio, setOrientacaoEtiquetaUAVazio] = useState<string>('retrato');

	//#region Buscar U.A. Vazia
	const filtroUAVazio = {
		id_ua: id_uaVazio,
		id_cliente: id_cliente_vazio,
	};
	function ListarUAVazia(dados: any) {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Buscando U.A. vazia');
		axios
			.post(`${Api}/estoque/uasemuso/listar/uavazia/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setDataUAVazio(resposta.data.uavazia);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	//#endregion

	// Listar depositante U.A. Vazio
	function ListarDepositanteUAVazio() {
		axios
			.get(`${Api}/estoque/uasemuso/listar/depositante/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDepositanteUaVazio(resposta.data.depositante);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}
	// Listar dimensao etiqueta U.A. Vazio
	function ListarDimensaoEtiquetaUAVazio() {
		axios
			.get(`${Api}/estoque/uasemuso/listar/dimensao/etiqueta/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDimensaoEtiquetaUaVazio(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	// selecionar etiqueta ao clicar - U.A. Vazio
	const selecionarEtiquetaUAVazio = (tipo: string) => {
		setTipoEtiquetaUAVazio(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosDimensaoEtiquetaUaVazio.filter((item) => item.value === tipo);
			setAlturaEtiquetaUAVazio(parseFloat(filtro[0].defusr01));
			setLarguraEtiquetaUAVazio(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiquetaUAVazio(filtro[0].defusr03);
		}
	};

	const limparDadosUaVazio = () => {
		setIdUaVazio('');
		setIdClienteVazio('');
		setDataUAVazio([]);
		setDadosDepositanteUaVazio([]);
		setTipoEtiquetaUAVazio('');
		setAlturaEtiquetaUAVazio(0);
		setLarguraEtiquetaUAVazio(0);
		setOrientacaoEtiquetaUAVazio('retrato');
		setDadosDimensaoEtiquetaUaVazio([]);
	};

	// Imprimir etiqueta U.A. Vazio
	const imprimirUAVazio = (dados: any) => {
		if (tipoEtiquetaUAVazio === '') {
			toast.error('Selecione a medida da etiqueta.');
			return;
		}
		gerarEtiquetaUAVazio(dados);
	};

	const imprimirEtiquetaUAVazio = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(item.id_ua);
			const dados: PdfDocumentUAVaziaSaidaParams = {
				tamanho: {
					altura: orientacaoEtiquetaUAVazio?.toLowerCase() !== 'retrato' ? larguraEtiquetaUAVazio : alturaEtiquetaUAVazio,
					largura: orientacaoEtiquetaUAVazio?.toLowerCase() !== 'retrato' ? alturaEtiquetaUAVazio : larguraEtiquetaUAVazio,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				codigoBarras: barcode,
				numeroUA: item.id_ua,
				tipoUA: item.tipo_ua,
				depositante: item.fantasia,
				orientacao: orientacaoEtiquetaUAVazio?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiquetaUAVazioSaida = PdfDocumentUAVaziaSaida({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiquetaUAVazioSaida();
	};

	// Uso da função
	const gerarEtiquetaUAVazio = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}

		imprimirEtiquetaUAVazio(dados); // Passa os dados para a função imprimirTodos
	};

	// dados para gerar U.A. saída
	const [id_uaSaida, setIdUaSaida] = useState('');
	const [id_cliente_saida, setIdClienteSaida] = useState('');
	const [dadosDepositanteUaSaida, setDadosDepositanteUaSaida] = useState([]);
	const [itensPorPaginaUASaida, setItensPorPaginaUASaida] = useState(10);
	const [dataUASaida, setDataUASaida] = useState([]);
	const [dadosDimensaoEtiquetaUaSaida, setDadosDimensaoEtiquetaUaSaida] = useState<any[]>([]);

	const [tipoEtiquetaUASaida, setTipoEtiquetaUASaida] = useState<string>('');

	const [alturaEtiquetaUASaida, setAlturaEtiquetaUASaida] = useState<number>(0);
	const [larguraEtiquetaUASaida, setLarguraEtiquetaUASaida] = useState<number>(0);
	const [orientacaoEtiquetaUASaida, setOrientacaoEtiquetaUASaida] = useState<string>('retrato');

	//#region Buscar U.A. Saida
	const filtroUASaida = {
		id_ua: id_uaSaida,
		id_cliente: id_cliente_saida,
	};
	function ListarUASaida(dados: any) {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Buscando U.A. Saída');
		axios
			.post(`${Api}/estoque/uasemuso/listar/uasaida/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setDataUASaida(resposta.data.uasaida);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	// Listar depositante U.A. Saída
	function ListarDepositanteUASaida() {
		axios
			.get(`${Api}/estoque/uasemuso/listar/depositante/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDepositanteUaSaida(resposta.data.depositante);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#endregion

	// Listar dimensao etiqueta U.A. Saida
	function ListarDimensaoEtiquetaUASaida() {
		axios
			.get(`${Api}/estoque/uasemuso/listar/dimensao/etiqueta/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDimensaoEtiquetaUaSaida(resposta.data.dadosEtiqueta);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	// selecionar etiqueta ao clicar - U.A. Saida
	const selecionarEtiquetaUASaida = (tipo: string) => {
		setTipoEtiquetaUASaida(tipo);

		//filtrar o tipo de etiqueta ao selecionar o tipo
		if (tipo) {
			const filtro = dadosDimensaoEtiquetaUaSaida.filter((item) => item.value === tipo);
			setAlturaEtiquetaUASaida(parseFloat(filtro[0].defusr01));
			setLarguraEtiquetaUASaida(parseFloat(filtro[0].defusr02));
			setOrientacaoEtiquetaUASaida(filtro[0].defusr03);
		}
	};

	const limparDadosUaSaida = () => {
		setIdUaSaida('');
		setIdClienteSaida('');
		setDataUASaida([]);
		setDadosDepositanteUaSaida([]);
		setTipoEtiquetaUASaida('');
		setAlturaEtiquetaUASaida(0);
		setLarguraEtiquetaUASaida(0);
		setOrientacaoEtiquetaUASaida('retrato');
		setDadosDimensaoEtiquetaUaSaida([]);
	};

	// Imprimir etiqueta U.A. Saida
	const imprimirUASaida = (dados: any) => {
		if (tipoEtiquetaUASaida === '') {
			toast.error('Selecione a medida da etiqueta.');
			return;
		}
		gerarEtiquetaUASaida(dados);
	};

	const imprimirEtiquetaUASaida = async (dados: any[]) => {
		if (!dados || dados.length === 0) {
			toast.error('Nenhum dado fornecido para impressão');
			return;
		}

		// Chama a função para converter a imagem
		const logobase64 = await imageToBase64(logoPadrao);

		// Converte os dados em formato esperado por PdfDocument
		const dadosItensEtiqueta = dados.map((item: any) => {
			const barcode = converterTextoParaImagemBase64(item.id_ua);
			const dados: PdfDocumentUAVaziaSaidaParams = {
				tamanho: {
					altura: orientacaoEtiquetaUASaida?.toLowerCase() !== 'retrato' ? larguraEtiquetaUASaida : alturaEtiquetaUASaida,
					largura: orientacaoEtiquetaUASaida?.toLowerCase() !== 'retrato' ? alturaEtiquetaUASaida : larguraEtiquetaUASaida,
				}, // altura e largura em cm
				logo: item.avatar ? item.avatar : `data:image/jpeg;base64,${logobase64}`,
				codigoBarras: barcode,
				numeroUA: item.id_ua,
				tipoUA: item.tipo_ua,
				depositante: item.fantasia,
				orientacao: orientacaoEtiquetaUASaida?.toLowerCase() === 'retrato' ? 'retrato' : 'paisagem', // 'retrato' | 'paisagem'
			};
			return dados;
		});

		// Gera as etiquetas utilizando o componente PdfDocument
		const gerarEtiquetaUASaida = PdfDocumentUAVaziaSaida({ documentos: dadosItensEtiqueta });

		// Chama a função gerada para criar o PDF
		gerarEtiquetaUASaida();
	};

	// Uso da função
	const gerarEtiquetaUASaida = (dados: any) => {
		// verificar se dados é um array se nao for transformar em array
		if (!Array.isArray(dados)) {
			dados = [dados];
		}

		imprimirEtiquetaUASaida(dados); // Passa os dados para a função imprimirTodos
	};

	// Função para excluir ua vazia/saida
	const excluiUA = (dados: any) => {
		setIdUaDelete(dados.id_ua);
		setTipoUADelete(dados.tipo_ua);
		setMsgPrincipalModal(`Deseja excluir a U.A ${dados.tipo_ua === 'vazio' ? 'Vazio' : 'Saída'} - ${dados.id_ua} ?`);
		setMsgAcao1('Cancelar');
		setMostraModalConfirm(true);
	};
	// Excluir U.A Vazia/Saída
	const deleteUA = () => {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setMsgModalCarregar(`Excluindo U.A ${tipoUADelete === 'vazio' ? 'Vazia' : 'Saída'}, Aguarde...`);
		axios
			.delete(Api + `/estoque/uasemuso/deletar/${id_filial}/${id_uaDelete}/${tipoUADelete}/${usuario}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(resposta.data.message);
				if (tipoUADelete === 'vazio') {
					ListarUAVazia(filtroUAVazio);
				} else {
					ListarUASaida(filtroUASaida);
				}
				setIdUaDelete('');
				setTipoUADelete('');
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setIdUaDelete('');
				setTipoUADelete('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">U.A sem Uso</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									{permissaoVisualizarVazio && (
										<li className="nav-item">
											<a
												href="#UASemUSoVazio"
												data-bs-toggle="tab"
												aria-expanded="true"
												className={permissaoVisualizarVazio ? `nav-link rounded-0 active` : `nav-link rounded-0`}
												onClick={() => {
													limparDadosUaSaida();
												}}
											>
												<Icon path={mdiPrinterPosRefreshOutline} size={1} className="d-md-none d-block" />
												<span className="d-none d-md-block">U.A. Vazio</span>
											</a>
										</li>
									)}
									{permissaoVisualizarSaida && (
										<li className="nav-item">
											<a
												href="#UASemUSoSaida"
												data-bs-toggle="tab"
												aria-expanded="false"
												className={!permissaoVisualizarVazio && permissaoVisualizarSaida ? `nav-link rounded-0 active` : `nav-link rounded-0`}
												onClick={() => {
													limparDadosUaVazio();
												}}
											>
												<Icon path={mdiFileRefreshOutline} size={1} className="d-md-none d-block" />
												<span className="d-none d-md-block">U.A Saída</span>
											</a>
										</li>
									)}
								</ul>
								<div className="tab-content">
									{permissaoVisualizarVazio && (
										<>
											<div className="tab-pane show active" id="UASemUSoVazio">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														ListarUAVazia(filtroUAVazio);
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-lg-3 col-sm">
																<div className="form-floating">
																	<input
																		type="text"
																		value={id_uaVazio}
																		onChange={(e) => setIdUaVazio(removeEspacoTextoMin(e.target.value))}
																		className="form-control bordasInferiorInput text-black"
																		aria-describedby="emailHelp"
																		placeholder="Procurar por nome de usuários"
																	/>
																	<label>Pesquisar Por ID U.A.</label>
																</div>
															</div>
															<div className="col-lg-5 col-sm">
																<InputSelect
																	label="Depositante"
																	name="id_cliente_vazio"
																	value={id_cliente_vazio}
																	onChange={setIdClienteVazio}
																	dados={dadosDepositanteUaVazio}
																	required={false}
																	onClick={ListarDepositanteUAVazio}
																	disabled={false}
																	campoValue="label"
																/>
															</div>
															<div className="col-lg-2 col-sm">
																<InputSelect
																	label="Medida Etiqueta"
																	name="tipoEtiquetaUAVazio"
																	value={tipoEtiquetaUAVazio}
																	onChange={selecionarEtiquetaUAVazio}
																	dados={dadosDimensaoEtiquetaUaVazio}
																	required={false}
																	onClick={ListarDimensaoEtiquetaUAVazio}
																	disabled={false}
																	campoValue="value"
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-success mt-3 mb-2"
																	descricaoBotao="Pesquisar"
																	icone={mdiMagnify}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
														<div className="">
															<ClickableTable
																coluna={colunasGridUAVazio}
																itemsPerPage={itensPorPaginaUAVazio}
																data={dataUAVazio}
																onRowClick={() => {}}
																usaAcoes={permissaoImprimirVazio || permissaoExcluirVazio}
																acaoEditar={() => {}}
																acaoExcluir={excluiUA}
																acaoVisualizar={imprimirUAVazio}
																usaVisualizar={permissaoImprimirVazio}
																usaEditar={false}
																iconeVisualizar="mdi mdi-printer"
																usaExcluir={permissaoExcluirVazio}
															/>
														</div>
														<div className="container">
															<div className="row">
																<div className="col-lg-2 m-auto text-center">
																	<label>Itens por Página</label>
																</div>
																<div className="w-100"></div>
																<div className="col-lg-1 m-auto">
																	<select
																		value={itensPorPaginaUAVazio}
																		onChange={function (e: any) {
																			setItensPorPaginaUAVazio(e.target.value);
																		}}
																		className="form-select form-select-sm"
																		aria-label=".form-select-sm example"
																	>
																		<option value={10} selected>
																			10
																		</option>
																		<option value={20}>20</option>
																		<option value={30}>30</option>
																	</select>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</>
									)}

									{permissaoVisualizarSaida && (
										<>
											<div className={!permissaoVisualizarVazio ? `tab-pane show active`:`tab-pane`} id="UASemUSoSaida">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														ListarUASaida(filtroUASaida);
													}}
												>
													<div className="card-body">
														<div className="row">
															<div className="col-lg-4 col-sm">
																<div className="form-floating">
																	<input
																		type="text"
																		value={id_uaSaida}
																		onChange={(e) => setIdUaSaida(removeEspacoTextoMin(e.target.value))}
																		className="form-control bordasInferiorInput text-black"
																		aria-describedby="emailHelp"
																		placeholder="Procurar por nome de usuários"
																	/>
																	<label>Pesquisar Por ID U.A.</label>
																</div>
															</div>
															<div className="col-lg-4 col-sm">
																<InputSelect
																	label="Depositante"
																	name="id_cliente_saida"
																	value={id_cliente_saida}
																	onChange={setIdClienteSaida}
																	dados={dadosDepositanteUaSaida}
																	required={false}
																	onClick={ListarDepositanteUASaida}
																	disabled={false}
																	campoValue="label"
																/>
															</div>
															<div className="col-lg-2 col-sm">
																<InputSelect
																	label="Medida Etiqueta"
																	name="tipoEtiquetaUASaida"
																	value={tipoEtiquetaUASaida}
																	onChange={selecionarEtiquetaUASaida}
																	dados={dadosDimensaoEtiquetaUaSaida}
																	required={false}
																	onClick={ListarDimensaoEtiquetaUASaida}
																	disabled={false}
																	campoValue="value"
																/>
															</div>
															<div className="col-sm col-md col-lg">
																<BotaoCustomizado
																	type="submit"
																	className="btn btn-danger mt-3 mb-2"
																	descricaoBotao="Pesquisar"
																	icone={mdiMagnify}
																	onclick={() => {}}
																	size={1}
																/>
															</div>
														</div>
														<div className="">
															<ClickableTable
																coluna={colunasGridUASaida}
																itemsPerPage={itensPorPaginaUASaida}
																data={dataUASaida}
																onRowClick={() => {}}
																usaAcoes={permissaoImprimirSaida || permissaoExcluirSaida}
																acaoEditar={() => {}}
																acaoExcluir={excluiUA}
																acaoVisualizar={imprimirUASaida}
																usaVisualizar={permissaoImprimirSaida}
																iconeVisualizar="mdi mdi-printer"
																usaEditar={false}
																usaExcluir={permissaoExcluirSaida}
															/>
														</div>
														<div className="container">
															<div className="row">
																<div className="col-lg-2 m-auto text-center">
																	<label>Itens por Página</label>
																</div>
																<div className="w-100"></div>
																<div className="col-lg-1 m-auto">
																	<select
																		value={itensPorPaginaUASaida}
																		onChange={function (e: any) {
																			setItensPorPaginaUASaida(e.target.value);
																		}}
																		className="form-select form-select-sm"
																		aria-label=".form-select-sm example"
																	>
																		<option value={10} selected>
																			10
																		</option>
																		<option value={20}>20</option>
																		<option value={30}>30</option>
																	</select>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />

			{/*MODAL DE CONFIRMAÇÃO */}
			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteUA}
			/>
		</>
	);
};

export { UASemUso };
