import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

interface InputDecimalProps {
	label: string;
	value: number | string; // Aceita número ou string para flexibilidade
	onChange: (value: number) => void;
	id: string;
	name: string;
	disabled?: boolean;
	required?: boolean;
}

const InputDecimal: FC<InputDecimalProps> = ({ label, value, onChange, id, name, disabled,required }) => {
	const [formattedValue, setFormattedValue] = useState<string>(() => {
		if (typeof value === 'number') {
			return value.toFixed(2);
		} else if (typeof value === 'string') {
			const parsedValue = parseFloat(value);
			return isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);
		}
		return '0.00';
	});

	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (typeof value === 'number') {
			setFormattedValue(value.toFixed(2));
		} else if (typeof value === 'string') {
			const parsedValue = parseFloat(value);
			setFormattedValue(isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2));
		}
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setFormattedValue(newValue);
	};

	const handleBlur = () => {
		const parsedValue = parseFloat(formattedValue.replace(',', '.'));
		onChange(isNaN(parsedValue) ? 0 : parseFloat(parsedValue.toFixed(2)));

		setFormattedValue(isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2));
	};

	return (
		<div className="form-floating mb-1">
			<input
				ref={inputRef}
				type="text"
				className="form-control bordasInferiorInput text-black text-capitalize"
				id={id}
				name={name}
				value={formattedValue}
				onChange={handleChange}
				onBlur={handleBlur}
				disabled={disabled}
				required={required}
			/>
			<label className="form-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
};

export default InputDecimal;
