import { useNavigate } from 'react-router-dom';

const NovaEmbalagemSemTexto = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Embalagem s/TxT</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tabConfEntrEmbalagemSTxtNovoGeral"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tabConfEntrEmbalagemSTxtNovoGeral">
										<div className="row">
											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="processo"
														placeholder=""
													/>
													<label htmlFor="processo">Processo</label>
												</div>
											</div>

											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="depositante"
														placeholder=""
													/>
													<label htmlFor="depositante">Depositante</label>
												</div>
											</div>

											<div className="col-sm-4">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														id="razao_social"
														placeholder=""
													/>
													<label htmlFor="razao_social">Razão Social</label>
												</div>
											</div>

										</div>
										<hr className="mt-2 mb-2" />
										<div className="row mt-1">
											<div className="col-sm-3 mb-2">
												<a
													data-bs-toggle="modal"
													data-bs-target="#modal-confEntrada-embalagemSTxt-AdcEmb"
													className="btn btn-info rounded-pill mb-2"
												>
													<i className="mdi mdi-plus-circle me-2"></i>
													Adicionar Embalagem
												</a>
											</div>
											<div className="row">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
														<thead className="table-light">
															<tr>
																<th>Cod Produto</th>
																<th>Descrição</th>
																<th>RG Embalagem</th>
																<th>Cod Barras</th>
																<th>Data Produção</th>
																<th>Data Validade</th>
																<th>Peso Bruto</th>
																<th>Peso Liquido</th>
																<th>Ações</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>015210-1</td>
																<td>Batata Palito 500gr</td>
																<td>10</td>
																<td>10</td>
																<td>03/01/2022</td>
																<td>03/01/2022</td>
																<td>100,00</td>
																<td>98,00</td>
																<td className="d-flex flex-nowrap table-action">
																	<a
																		data-bs-toggle="modal"
																		data-bs-target="#modal-confEntrada-embalagemSTxt-AdcEmb"
																		className="action-icon"
																	>
																		<i className="mdi mdi-square-edit-outline"></i>
																	</a>
																	<a href="javascript:void(0);" className="action-icon">
																		<i className="mdi mdi-delete"></i>
																	</a>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/operacao/confEntrada/embalagemSemTexto')}
										>
											Cancelar
										</button>
										<button
											className="btn btn-info mb-2 me-1"
											type="button"
											onClick={() => navigate('/operacao/confEntrada/embalagemSemTexto')}
										>
											Salvar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-confEntrada-embalagemSTxt-AdcEmb"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Nova Embalagem
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="processo" aria-describedby="processoHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="processo">Processo</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="depositante" aria-describedby="depositanteHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="depositante">Depositante</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<select className="form-select bordasInferiorInput text-black text-capitalize" id="codigo_produto" aria-describedby="codigoProdutoHelp" placeholder="">
													<option value="">----</option>
												</select>
												<label htmlFor="codigo_produto">Código produto</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="rg_embalagem" aria-describedby="rgEmbalagemHelp" placeholder="" />
												<label htmlFor="rg_embalagem">RG Embalagem</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="codigo_barras" aria-describedby="codigoBarrasHelp" placeholder="" />
												<label htmlFor="codigo_barras">Código Barras</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="peso_bruto" aria-describedby="pesoBrutoHelp" placeholder="" />
												<label htmlFor="peso_bruto">Peso Bruto</label>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="peso_liquido" aria-describedby="pesoLiquidoHelp" placeholder="" />
												<label htmlFor="peso_liquido">Peso Líquido</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="data_producao" aria-describedby="dataProducaoHelp" placeholder="" />
												<label htmlFor="data_producao">Data Produção</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" id="data_validade" aria-describedby="dataValidadeHelp" placeholder="" />
												<label htmlFor="data_validade">Data Validade</label>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export { NovaEmbalagemSemTexto };
