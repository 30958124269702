import Titulo from "../../../../components/componentes/formulario/ContainerTitulo"
import Inputs from "../../../../components/componentes/formulario/Inputs"
import { useNavigate } from 'react-router-dom';
import { BsCardChecklist } from 'react-icons/bs';
import { Modal, Button} from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { useEffect, useState } from 'react';
import axios from "axios";
import { Api } from "../../../../services/api";
import ModalCarregando from "../../../../components/componentes/formulario/ModalCarregando";
import { toast } from 'react-toastify';

function NovoGrupo() {
    const Navigate = useNavigate()
    const token = sessionStorage.getItem('IsFKLTOKEN');
    const user = sessionStorage.getItem('IsIDUserFKL');


    //infos do Grupo --inicio
    const [descricao, setDescricao] = useState("")

        //infos do Grupo - ICMS
    const [origem, setOrigem] = useState("")
    const [CSTicms, setCSTicms] = useState("")
    const [baseCalc, setBaseCalc] = useState("")
    const [porcemICMS, setPorcemICMS] = useState("")

        //infos do grupo - IPI
    const [cod_enquadramento, setCod_enquadramento] = useState("")
    const [CSTipi, setCSTipi] = useState("")
    const [porcemIPI, setPorcemIPI] = useState("")
    
        //infos do grupo - PIS
    const [CSTpis, setCSTpis] = useState("")
    const [porcemPIS, setPorcemPIS] = useState("")

        //infos do grupo - PIS
    const [CSTcofins, setCSTcofins] = useState("")
    const [porcemcofins, setPorcemcofins] = useState("")

    
    //infos do Grupo --fim

    //mostrar modal carregando
    const [showCarregando, setShowCarregando] = useState(false)


    //salvar no banco
    const [ModalConfirmarSalvarBanco, setModalConfirmarSalvarBanco] = useState(false)

    async function salvarNoBanco() {

        setShowCarregando(true)

        const novoGrupo = {
            descricao: descricao,
            origem: origem,
            CSTicms: CSTicms,
            baseCalc: baseCalc,
            porcemICMS: porcemICMS,
            cod_enquadramento: cod_enquadramento,
            CSTipi: CSTipi,
            porcemIPI: porcemIPI,
            CSTpis: CSTpis,
            porcemPIS: porcemPIS,
            CSTcofins: CSTcofins,
            porcemcofins: porcemcofins,
            usuario_adicao: user
        }
        
        const response = await axios.post(Api + `/create/newgrupo/?auth=${token}`, novoGrupo)

        setShowCarregando(false)
        if(response.data.status == 400){
			toast.error(response.data.message, {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
        }
        else if(response.data.status == 200){
            setModalConfirmarSalvarBanco(true)
        }
        else{
            toast.error(response.data.message, {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
        }
    }


    return (
        <div className="container-fluid">
            <Titulo titulo="Cadastrar novo Grupo Tributário" />
            <div className="row">

                <div className="card">
                    <div className="card-body">

                            <div className="container">
                                <div className="col-1 form-floating">
                                    <Inputs
                                        placeholder=""
                                        label="ID"
                                        maxLength={4}
                                        name="ID grupo"
                                        classNameInput="form-control bordasInferiorInput text-black text-capitalize bordasInferiorInput text-black text-capitalize"
                                        classNameLabel="form-label"
                                        required={true}
                                        value={"0000"}
                                        disabled
                                    />
                                </div>
                                <div className="col-5 form-floating">
                                    <Inputs
                                        placeholder=""
                                        label="Descrição"
                                        maxLength={160}
                                        name="Descrição grupo"
                                        classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                        classNameLabel="form-label"
                                        required={true}
                                        onchange={setDescricao}
                                        value={descricao}
                                    />
                                </div>
                            </div>

                            <br />
                            <br />

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm">
                                        <b>ICMS</b>
                                    </div>
                                    <div className="col-sm">
                                        <b>IPI</b>
                                    </div>
                                    <div className="col-sm">
                                        <b>PIS</b>
                                    </div>
                                    <div className="col-sm">
                                        <b>COFINS</b>
                                    </div>
                                </div>
                                <br />
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="Origem"
                                                maxLength={15}
                                                name="Origem"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setOrigem}
                                                value={origem}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="CST icms"
                                                maxLength={15}
                                                name="CST icms"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setCSTicms}
                                                value={CSTicms}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="%Red.Base Cálculo"
                                                maxLength={70}
                                                name="%Red.Base Cálculo"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setBaseCalc}
                                                value={baseCalc}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="%ICMS"
                                                maxLength={70}
                                                name="%ICMS"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setPorcemICMS}
                                                value={porcemICMS}
                                            />
                                        </div>
                                    </div>




                                    <div className="col-sm">
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="Cod.Enquadramento"
                                                maxLength={15}
                                                name="Cod.Enquadramento"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setCod_enquadramento}
                                                value={cod_enquadramento}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="CST IPI"
                                                maxLength={15}
                                                name="CST IPI"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setCSTipi}
                                                value={CSTipi}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="%IPI"
                                                maxLength={70}
                                                name="%IPI"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setPorcemIPI}
                                                value={porcemIPI}
                                            />
                                        </div>
                                    </div>



                                    <div className="col-sm">
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="CST PIS"
                                                maxLength={70}
                                                name="CST PIS"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setCSTpis}
                                                value={CSTpis}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="%PIS"
                                                maxLength={70}
                                                name="%PIS"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setPorcemPIS}
                                                value={porcemPIS}
                                            />
                                        </div>
                                    </div>



                                    <div className="col-sm">
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="CST Cofins"
                                                maxLength={70}
                                                name="CST Cofins"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setCSTcofins}
                                                value={CSTcofins}
                                            />
                                        </div>
                                        <div className="col-10 form-floating">
                                            <Inputs
                                                placeholder=""
                                                label="%COFINS"
                                                maxLength={70}
                                                name="%COFINS"
                                                classNameInput="form-control bordasInferiorInput text-black text-capitalize"
                                                classNameLabel="form-label"
                                                required={true}
                                                onchange={setPorcemcofins}
                                                value={porcemcofins}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className="card-footer">
                                <div className="col">
                                    <div className="text-sm-end">
                                        <button className="btn btn-cancelar mb-2 me-1" onClick={function () {
                                            Navigate(-1)
                                        }} type="button">
                                            Cancelar
                                        </button>
                                        <button className="btn btn-novo mb-2 me-1" onClick={salvarNoBanco}>
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        





                    </div>
                </div>


            </div>

            {/*MODAL QUE CONFIRMA A CRIAÇÃO*/}
            <Modal show={ModalConfirmarSalvarBanco} backdrop="static" size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
                    <BsCardChecklist size={"100px"} color="#f58619" />
                    <div className="row" style={{ textAlign: 'center' }}>
                        <div className="col-sm" >
                            <h3>Novo grupo criado com sucesso</h3>
                        </div>
                    </div>
                    <hr />

                    <div className="d-flex my-2 gap-1 justify-content-between">
                        <Button variant="success" style={{ borderRadius: '20px' }} type="button" onClick={function(){
                            Navigate(-1)
                        }} >
                            <Icon path={mdiCheckCircle} size={1} className="me-2" />
                            Ok
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>


            
            {/*Modal CARREGANDO*/}			
			<ModalCarregando show={showCarregando} />

        </div>

    )
}

export default NovoGrupo