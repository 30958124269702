import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { Api } from '../../../../services/api';
import { colunasListaNfe } from './components/colunasGrid';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiMagnify } from '@mdi/js';
import { QtdeDiasNF } from './components/diasNF';
import removeEspacoTextoMin from '../../../../components/componentes/types/removeEspacoTextoMin';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

export function AdmOperacionalFiscalEntradaNFeEntradaCrossDocking() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoDetalhar, setPermissaoDetalhar] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarNfeEntradaCrossDocking) {
				toast.warning('Você não tem permissão para acessar lista de NF-e de entrada CrossDocking.');
				navigate('/');
			} else {
				setPermissaoDetalhar(permissoes.perfilPermissao.detalharNfeEntradaCrossDocking);
			}
		}
	}, [permissoes, erro, navigate]);

	const [showCarregando, setShowCarregando] = useState(false);

	// dados filtro
	const [nf, setNf] = useState('');
	const [diasNF, setDiasNF] = useState('');

	const [ListaDeNfs, setListaDeNfs] = useState([]);

	const dadosFiltro = {
		nf,
		diasNF,
	};
	function CarregarNFsFilial(id_filial: string) {
		setShowCarregando(true);
		axios
			.post(Api + `/admoper/fiscalentrada/nfeentcrossdocking/listar/${id_filial}`, dadosFiltro, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setListaDeNfs(resposta.data.nfecrossdocking);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const detalharNF = (dados: any) => {
		const chave = dados.chave_acesso;
		navigate(`/pco/contrFisc/contrNotFisc/notasFiscaisCrossDocking/${chave}/detalhar`);
	};

	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>NFe Entrada CrossDocking</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							CarregarNFsFilial(id_filial);
						}}
					>
						<div className="row">
							<div className="col-lg-4 col-sm">
								<div className="form-floating">
									<input
										type="text"
										value={nf}
										onChange={(e) => setNf(removeEspacoTextoMin(e.target.value))}
										className="form-control bordasInferiorInput text-black"
										id="nf"
										name="nf"
									/>
									<label>Núm. NF</label>
								</div>
							</div>

							<div className="col-lg-3 col-sm">
								<InputSelect
									label="Dias NF"
									name="tipoEtiquetaUAVazio"
									value={diasNF}
									onChange={setDiasNF}
									dados={QtdeDiasNF}
									required={!nf}
									onClick={() => {}}
									disabled={false}
									campoValue="value"
								/>
							</div>
							<div className="col-sm col-md col-lg">
								<BotaoCustomizado
									type="submit"
									className="btn btn-success mt-3 mb-2"
									descricaoBotao="Pesquisar"
									icone={mdiMagnify}
									onclick={() => {}}
									size={1}
								/>
							</div>
						</div>
					</form>

					<ClickableTable
						coluna={colunasListaNfe}
						itemsPerPage={itensPorPagina}
						data={ListaDeNfs}
						usaRowClick={permissaoDetalhar}
						onRowClick={permissaoDetalhar ? detalharNF : () => {}} // Se tiver permissão, chama a função de detalharNF, senão, não faz nada
						acaoEditar={function () {}}
						acaoExcluir={() => {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={false}
						usaExcluir
						usaVisualizar={false}
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
		</div>
	);
}
