import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { mdiReceiptTextPlusOutline } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';

const ExcecaoZona = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');

	const [data, setData] = useState([]);
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();
	// dados
	const [permExcecaoZonaEnderecamento, setPermExcecaoZonaEnderecamento] = useState<boolean>(false);
	const [idua, setIdUA] = useState<string>('');
	const iduaRef = useRef<HTMLInputElement | null>(null);

	// Validar Permissao zona de endereçamento
	function validarPermissao() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando permissão, aguarde...');
		axios
			.get(`${Api}/excecao/zonaend/permissao/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setPermExcecaoZonaEnderecamento(resposta.data.perm_zona_end === 1 ? true : false);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando Zona de Endereçamento, aguarde...');
		axios
			.get(`${Api}/excecao/zonaend/listar/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.zona_end);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		validarPermissao();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dadosEnvio = {
		id_filial,
		id_ua: idua,
		usuario,
	};

	// Incluir Exceção Zona de Endereçamento;
	const incluirZonaEnderecamento = (dados: any) => {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Incluindo Exceção Zona de Endereçamento, aguarde...');
		axios
			.post(`${Api}/excecao/zonaend/incluir/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(response.data.message);

				carregarDados();
			})
			.catch((erro) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
					setTimeout(() => {
						setIdUA('');
						iduaRef.current?.focus();
					}
					, 1000);
				}
			});
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Exceção - Zona de Endereçamento</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<form
									onSubmit={(e) => {
										e.preventDefault();
										incluirZonaEnderecamento(dadosEnvio);
									}}
								>
									<div className="row">
										<div className="col-sm col-md col-lg">
											<InputSemZeroEsquerda
												id="idUA"
												name="idUA"
												label="ID. U.A."
												type="text"
												value={idua}
												useRef={iduaRef}
												onChange={setIdUA}
												required
											/>
										</div>

										{permExcecaoZonaEnderecamento && (
											<div className="col-sm col-md col-lg">
												<BotaoCustomizado
													type="submit"
													className="btn btn-success mt-3"
													descricaoBotao="Gravar"
													icone={mdiReceiptTextPlusOutline}
													onclick={() => {}}
													size={1}
												/>
											</div>
										)}
									</div>
								</form>
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={false}
										acaoEditar={() => {}}
										acaoExcluir={() => {}}
										acaoVisualizar={() => {}}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa="id_ua"
										labelpesquisa="Procurar por ID. U.A."
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />
		</>
	);
};

export { ExcecaoZona };
