import React from 'react';

interface CheckBoxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: 'criar' | 'editar' | string; // Adicione outros valores de ação conforme necessário
  label: string;
  name?: string;
  className?: string;
  classNameDiv?: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange, disabled, label, name, className='form-check-input w-50', classNameDiv='form-check form-switch' }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
	  <>
    <div className={classNameDiv}>
      <input
        checked={checked}
        onChange={handleChange}
        disabled={disabled === 'criar' || disabled === 'editar' ? false : true}
        className={className}
        style={{ width: '75px' }}
		name={name}
		id={name}
        type="checkbox"
		/>
      <label className="ms-1 form-check-label">{label}</label>
     </div>
		</>
  );
};

export default CheckBox;
