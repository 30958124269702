import { ITabela } from '../../../../../components/Table/TabelaInterface';
// PARA PESQUISA DE PRODUTO
export const colunasGridPesquisaProduto: ITabela[] = [
	
	{
		titulo: 'Descrição',
		acesso: 'descricao',
	},
	{
		titulo: 'Código',
		acesso: 'codigo',
	},
	{
		titulo: 'Temperatura',
		acesso: 'temperatura',
	},
];
