import fileSaver from 'file-saver';
import exceljs from 'exceljs';
import gerarAlfabetoCabecalho from './components/gerarAlfabeto';
import { toast } from 'react-toastify';
import { IColunaExcel } from '../IColunasExcel';

export const ExportarExcel = async (dados: any[], colunas: IColunaExcel[], nomeArquivo: string, guia: string) => {
	try {
		const workbook = new exceljs.Workbook();
		const worksheet = workbook.addWorksheet(guia);

		const columnLetters = gerarAlfabetoCabecalho(colunas.length);

		// Criando cabeçalho
		colunas.forEach((col, i) => {
			const cell = worksheet.getCell(`${columnLetters[i]}1`);
			cell.value = col.titulo;
			cell.font = { bold: true };
			cell.border = { bottom: { style: 'thin' } };
			worksheet.getColumn(columnLetters[i]).width = 25;
		});

		// Preenchendo corpo do Excel
		dados.forEach((item, rowIndex) => {
			colunas.forEach((col, colIndex) => {
				const cell = worksheet.getCell(`${columnLetters[colIndex]}${rowIndex + 2}`);
				cell.value = item[col.acesso];
			});
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blob: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fileSaver.saveAs(blob, nomeArquivo);
	} catch (error: any) {
		toast.error('Erro ao gerar .xlsx: ', error);
	}
};
