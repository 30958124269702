import { useEffect, useState } from "react"
import { ITabela } from "../../../../components/Table/TabelaInterface"
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl"
import axios from "axios"
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao"
import ModalEditarPed from "./components/ModalEditar"
import ModalQuebraTarefa from "./components/ModalQuebraTarefa"
import { BuscarPermissoesUsuario } from "../../../../hooks/buscarPermissoesUsuario"

function PedidosPendentes() {
    const [data, setData] = useState([])
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false)
    const [mostraModalEditar, setMostraModalEditar] = useState<boolean>(false)
    const [idPedido, setIdPedido] = useState<number>(0)
    const [descricao, setDescricao] = useState<string>('')
    const [dataCarga, setDataCarga] = useState<string>('')
    const [destinatario, setDestinatario] = useState<string>('')
    const [arrayDadosCheck, setArrayDadosCheck] = useState<any>([])
    const [resetKey, setResetKey] = useState(0);
    const [mostraModalQuebraTarefa, setMostraModalQuebraTarefa] = useState<boolean>(false)

    const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
    const token = sessionStorage.getItem('IsFKLTOKEN')!;
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")

    const navigate = useNavigate()

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(idFilial, id_perfil);	
	const [permissaoQuebrarPedido, setPermissaoQuebrarPedido] = useState(false);
	const [permissaoLiberarPedido, setPermissaoLiberarPedido] = useState(false);
	const [permissaoEditarPedido, setPermissaoEditarPedido] = useState(false);
	const [permissaoExcluirPedido, setPermissaoExcluirPedido] = useState(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarPedidosPendentes) {
				toast.warning('Você não tem permissão para acessar Pedidos Pendentes.');
				navigate('/');
			} else {				
				setPermissaoQuebrarPedido(permissoes.perfilPermissao.quebrarPedidoTarefas);
				setPermissaoLiberarPedido(permissoes.perfilPermissao.liberarPedidoParaPicking);
				setPermissaoEditarPedido(permissoes.perfilPermissao.editarPedidoPendente);
				setPermissaoExcluirPedido(permissoes.perfilPermissao.excluirPedidoPendente);
				
			}
		}
	}, [permissoes, erro, navigate]);


    const excluirPedPendente = (dados: any) => {
        setIdPedido(dados.id_pedido)
        setMostraModalConfirm(true)
    };

    const editarPedPendente = (dados: any) => {
        setIdPedido(dados.id_pedido)
        setDescricao(dados.pedido)
        setDataCarga(dados.data_carga)
        setDestinatario(dados.destinatario)

        setMostraModalEditar(true)
    }

    const colunas: ITabela[] = [
        { titulo: "Depositante", acesso: "fantasia" },
        { titulo: "Id", acesso: "id_pedido" },
        { titulo: "Pedido", acesso: "pedido" },
        { titulo: "Dt. Carga", acesso: "data_carga" },
        { titulo: "Qtde", acesso: "qtde" },
        { titulo: "Destinatário", acesso: "destinatario" },
        { titulo: "Criação", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ]

    function carregaGrid() {
        setMostraModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/ped/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setMostraModalCarregando(false)
            setData(resposta.data.data)
        }).catch(function (erro) {
            setMostraModalCarregando(false)
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.info(erro.response.data.message)
                setData([])
            }
        })
    }

    function excluirPedido() {
        setMostraModalConfirm(false)
        setMostraModalCarregando(true)
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deleta/pedido/${idPedido}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setMostraModalCarregando(false)
            toast.success(resposta.data.message)
            carregaGrid()
        }).catch(function (erro) {
            setMostraModalCarregando(false)
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    function dadosCheck(dados: any, checked: boolean) {

        if (checked) {
            // Se o checkbox foi marcado, adiciona o objeto dados ao array
            setArrayDadosCheck((prevArray: any) => [...prevArray, dados])
        } else {
            // Se o checkbox foi desmarcado, remove o objeto dados do array
            //prevArray representa o valor anterior do estado
            setArrayDadosCheck((prevArray: any) =>
                prevArray.filter((item: any) => item.id_pedido !== dados.id_pedido)
            )
        }

        setIdPedido(dados.id_pedido)
        setDataCarga(dados.data_carga)
    }

    async function liberarPedidoPicking() {
        if (arrayDadosCheck.length === 0) {
            return toast.info("Selecione um pedido para liberação")
        }
        setMostraModalCarregando(true)
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/liberar/pedido/picking`, {
            idFilial,
            arrayDadosCheck,
            dataCarga,
            usuarioLogado,
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setMostraModalCarregando(false)
            toast.success(resposta.data.message)
            carregaGrid()
            setResetKey(prevKey => prevKey + 1)
            setArrayDadosCheck([])
        }).catch(function (erro) {
            setMostraModalCarregando(false)
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setResetKey(prevKey => prevKey + 1)
                carregaGrid()
                setArrayDadosCheck([])
            }
        })
    }

    function verificaSaldoTarefaParaQuebra() {
        if (arrayDadosCheck.length === 0) {
            return toast.info("Selecione um pedido para quebrar em tarefas")
        }

        setMostraModalCarregando(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/consulta/saldo/tarefa`, {
            idFilial,
            arrayDadosCheck
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setMostraModalCarregando(false)
            setMostraModalQuebraTarefa(true)
        }).catch(function (erro) {
            setMostraModalCarregando(false)
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setMostraModalQuebraTarefa(false)
                toast.error(erro.response.data.message)
                setResetKey(prevKey => prevKey + 1)
                setArrayDadosCheck([])
            }
        })
    }

    useEffect(() => {
        carregaGrid()
    }, [])

    return (
        <>
            <div className="main">
                <div className='tituloDaPagina mt-2 ms-1'>
                    <h4>Pedidos Pendentes</h4>
                </div>
                <div className="card mt-2 pb-3">
                    <div className="card-body">

                        <div className="row">

                            <div className="col-4">
                                <button type="button" className='w-100 rounded btn btn-primary'>
                                    <i className="bi bi-cast me-2"></i>Programação de O.S.
                                </button>
                            </div>

                            {permissaoQuebrarPedido && <div className="col-4">
                                <button
                                    type="button"
                                    className='w-100 rounded btn btn-primary'
                                    onClick={verificaSaldoTarefaParaQuebra}
                                >
                                    <i className="me-2 bi bi-layout-wtf"></i>Quebrar pedido em Tarefas
                                </button>
                            </div>}

                            {permissaoLiberarPedido && <div className="col-4">
                                <button
                                    type="button"
                                    className='w-100 rounded btn btn-primary'
                                    onClick={liberarPedidoPicking}
                                >
                                    <i className="bi bi-printer-fill me-1"></i>Liberar pedido para Picking
                                </button>
                            </div>}
                        </div>

                        <div className="row mt-3">
                            <ClickableTable
                                coluna={colunas}
                                data={data}
                                onRowClick={function () { }}
                                itemsPerPage={10}
                                acaoEditar={editarPedPendente}
                                acaoExcluir={excluirPedPendente}
                                acaoVisualizar={function () { }}
                                usaAcoes={permissaoEditarPedido || permissaoExcluirPedido}
                                usaEditar={permissaoEditarPedido}
                                usaExcluir={permissaoExcluirPedido}
                                usaVisualizar={false}
                                acessopesquisa='depositante'
                                labelpesquisa='Procurar pelo Depositante'
                                usaCheckBox={true}
                                acaoCheckBox={dadosCheck}
                                resetKey={resetKey}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalCarregando
                mostrar={mostraModalCarregando}
                mensagem='Carregando...'
            />

            <ModalConfirmacao
                mostrar={mostraModalConfirm}
                msgAcao1="Cancelar"
                msgAcao2="Confirmar"
                msgPrincipal="Tem certeza que deseja excluir pedido?"
                Acao1={() => setMostraModalConfirm(false)}
                Acao2={excluirPedido}
            />

            <ModalEditarPed
                isOpen={mostraModalEditar}
                fecharModal={() => {
                    setMostraModalEditar(false)
                    carregaGrid()
                }}
                token={token}
                idFilial={idFilial}
                dataCarga={dataCarga}
                destinatario={destinatario}
                descricao={descricao}
                idPedido={idPedido}
            />

            <ModalQuebraTarefa
                isOpen={mostraModalQuebraTarefa}
                fecharModal={() => {
                    setMostraModalQuebraTarefa(false)
                    liberarPedidoPicking()
                    setResetKey(prevKey => prevKey + 1)
                    setArrayDadosCheck([])
                }}
                token={token}
                idFilial={idFilial}
                arrayDadosCheck={arrayDadosCheck}
            />
        </>
    )
}

export default PedidosPendentes