import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiKeyboardReturn, mdiMagnify, mdiPlusCircle, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import Icon from '@mdi/react';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { toast } from 'react-toastify';
import CarregarPermissoes from '../../../../hooks/carregaPermissoesUsuario/carregaPermissoes';

const UsuariosFilial = (): JSX.Element => {

	const params = useParams()
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
	const id_perfil = sessionStorage.getItem("IsIdPerfilFKL")!
	const nivelPerfil = sessionStorage.getItem("NivelPerfil")!

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion  
	const colunas: ITabela[] = [
		{
			titulo: "ID Usuario",
			acesso: "id_usuario"
		},
		{
			titulo: "Nome",
			acesso: "nome"
		},
		{
			titulo: "Email",
			acesso: "email"
		},
		{
			titulo: "Perfil",
			acesso: "nome_perfil"
		},
		{
			titulo: "Status",
			acesso: "situacao"
		}
	]

	const [itensPorPagina, setItensPorPagina] = useState(10)
	const navigate = useNavigate()
	const [ListaDeUsuarios, setListaDeUsuarios] = useState([])
	function carregarUsuarios(id_filial: any) {

		setShowCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/load/usuarios/sem/master/filial/${id_filial}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {

			setShowCarregando(false)
			setListaDeUsuarios(resposta.data.usuarios)
		}).catch(function (erro) {

			setShowCarregando(false)
			alert(erro.response.data.message || erro.message)
		})
	}


	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false)

	useEffect(function () {

		CarregarPermissoes(id_perfil, id_filial, nivelPerfil).then(function (perfil: any) {

			if (perfil["visualizarUsuariosFilial"] == false) {

				toast.error("Sem permissão para visualizar essa página.")
				navigate("/")
			}
			else {

				setPermissaoVisualizar(perfil["visualizarUsuariosFilial"])
			}
		}).catch(function (erro) {

			toast.error(erro, {
				autoClose: 900
			})
		})
		carregarUsuarios(params.id_filial!)
	}, [])
	return (
		<>
			<div className='main'>
				<div className='tituloDaPagina mt-2 ms-1'>
					<h4>Usuários</h4>
				</div>
				<div className="card mt-2 pb-3">
					<div className="card-body">
						<ClickableTable
							coluna={colunas}
							itemsPerPage={itensPorPagina}
							data={ListaDeUsuarios}
							onRowClick={function () {

							}}
							acaoEditar={function () {

							}}
							acaoExcluir={function () {

							}}
							acaoVisualizar={function (dado: any) {

								navigate(`/config/segur/usuarios/visualizar/${dado.id_usuario}`)
							}}
							usaAcoes={permissaoVisualizar}
							usaEditar={false}
							usaExcluir={false}
							usaVisualizar={permissaoVisualizar}
							acessopesquisa='nome'
							labelpesquisa='Procurar por nome de usuario.'
						/>
					</div>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-2 m-auto text-center'>
								<label>Itens por Página</label>
							</div>
							<div className='w-100'></div>
							<div className='col-lg-1 m-auto'>

								<select value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value)
									}}
									className="form-select form-select-sm" aria-label=".form-select-sm example">
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>
			</Modal>
		</>
	);
};

export { UsuariosFilial };
