import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  dados: any[]; // Substitua 'any[]' pelo tipo correto dos seus dados
}

const Paginacao: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
  dados,
}) => {

  // Estado para armazenar se o dispositivo é móvel ou não
  const [isMobile, setIsMobile] = useState(false);

  // Efeito para monitorar a largura da janela e atualizar o estado isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    //Atualiza o estado de isMobile quando a janela do navegador for redimensionada
    window.addEventListener('resize', handleResize);
    //Remove o eventListener quando o componente não esta em uso para garantir que não haja vazamento de memória
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-3 gap-2 text-center">
      <Pagination className="center">
        <Pagination.First
          onClick={() => {
            setCurrentPage(1);
          }}
        />
        <Pagination.Prev
          onClick={() => {
            if (currentPage > 1) setCurrentPage(currentPage - 1);
          }}
        />
        {!isMobile && (
          <>
            <Pagination.Item>Página</Pagination.Item>
            <Pagination.Item>{currentPage}</Pagination.Item>
            <Pagination.Item>de</Pagination.Item>
            <Pagination.Item>{totalPages}</Pagination.Item>
            <Pagination.Item>|</Pagination.Item>
            <Pagination.Item>Qtde Registros</Pagination.Item>
            <Pagination.Item>{dados.length}</Pagination.Item>
            <Pagination.Next
              onClick={() => {
                if (currentPage < totalPages) setCurrentPage(currentPage + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                if (currentPage < totalPages) setCurrentPage(totalPages);
              }}
            />
          </>
        )}
        {isMobile && (
          <>
            <Pagination.Next
              onClick={() => {
                if (currentPage < totalPages) setCurrentPage(currentPage + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                if (currentPage < totalPages) setCurrentPage(totalPages);
              }}
            />
          </>
        )}
      </Pagination>
    </div>
  );
};

export default Paginacao;
  