import { Link } from 'react-router-dom';

const NotasFiscaisArmazenagem = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Nota Fiscal Armazenagem</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-12 mb-2">
									<a
										className="btn btn-warning rounded dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Funções
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item">Duplicar NF</a>
										<a className="dropdown-item">Duplicar NF p/ Pedido</a>
										<a className="dropdown-item">Trocar NF</a>
										<a className="dropdown-item">Vincular NF ao Depositante</a>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap">
										<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
											<tr>
												<th>Proc. Entrada</th>
												<th>Proc. Saída</th>
												<th>OS</th>
												<th>Pedido</th>
												<th>Nota Fiscal</th>
												<th>Série</th>
												<th>Data Emissão</th>
												<th>CNPJ Emit</th>
												<th>Nome Emit</th>
												<th>Destinatário</th>
												<th>Chave DANFE</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>0</td>
												<td>0</td>
												<td>0</td>
												<td>0</td>
												<td>123456</td>
												<td>1</td>
												<td>03/12/2021</td>
												<td>01123432000101</td>
												<td>Emitente Ltda</td>
												<td>Destinatário Ltda</td>
												<td>123121521212121212121212121212</td>
												<td className="d-flex flex-nowrap table-action">
													<Link
														to={`/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem/${0}`}
														className="action-icon"
													>
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
													<a href="javascript:void(0);" className="action-icon">
														<i className="mdi mdi-delete"></i>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { NotasFiscaisArmazenagem };
