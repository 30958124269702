import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { mdiReceiptTextPlusOutline } from '@mdi/js';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGrid } from './components/colunasGrid';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import InputSemZeroEsquerda from '../../../../components/componentes/formulario/InputSemZeroEsquerda';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const ExcecaoLerVolumeCarga = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL');

	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [msgModalCarregar, setMsgModalCarregar] = useState<string>('');

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	// dados
	const [permExcecaoLerVolume, setPermExcecaoLerVolume] = useState<boolean>(false);
	const [idDados, setIdDados] = useState<string>('');
	const idDadosRef = useRef<HTMLInputElement | null>(null);
	const [tipoDados, setTipoDados] = useState<string>('0');
	const [motivo, setMotivo] = useState<string>('');
	const motivoRef = useRef<HTMLInputElement | null>(null);
	function validarPermissao() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Validando permissão, aguarde...');
		axios
			.get(`${Api}/excecao/lervolume/permissao/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setPermExcecaoLerVolume(resposta.data.perm_ler_volume === 1 ? true : false);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region Inicio novo desenvolvimento de funções

	function carregarDados() {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Carregando listagem de Exceção Ler Volume Carga');
		axios
			.get(`${Api}/excecao/lervolume/listar/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				setData(resposta.data.volume_carga);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	useEffect(() => {
		carregarDados();
		validarPermissao();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//#region  --> Validar ID do Processo / O.S.
	function validarIDOSProcesso() {
		if (!idDados) return;
		setMostraModalCarregar(true);
		setMsgModalCarregar(tipoDados === '0' ? 'Validando ID da O.S.' : 'Validando ID do Processo');
		axios
			.get(`${Api}/excecao/lervolume/validarid/${id_filial}/${idDados}/${tipoDados}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				if (resposta.data.id === 0) {
					toast.error(tipoDados === '0' ? 'ID da O.S. inválido ou não localizado' : 'ID do Processo inválido ou não localizado');
					setTimeout(() => {
						setIdDados('');
						idDadosRef.current?.focus();
					}, 1000);
					return;
				}
				setTimeout(() => {
					motivoRef.current?.focus();
				}, 1000);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	const dadosEnvio = {
		id_filial,
		id: idDados,
		tipo: tipoDados,
		motivo,
		usuario,
	};

	// Incluir Exceção Ler GTIN Carga
	const incluirExcecaoLerGtin = (dados: any) => {
		setMostraModalCarregar(true);
		setMsgModalCarregar('Inserindo Exceção');
		axios
			.post(`${Api}/excecao/lervolume/incluir/${id_filial}`, dados, {
				headers: {
					authorization: token,
				},
			})
			.then((response) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				toast.success(response.data.message);
				carregarDados();
				setIdDados('');
				setMotivo('');
				setMostraModalConfirm(false);
				setTipoDados('0');
				setMsgPrincipalModal('');
				setMsgAcao1('');
				setTimeout(() => {
					idDadosRef.current?.focus();
				}, 1000);
			})
			.catch((erro) => {
				setMostraModalCarregar(false);
				setMsgModalCarregar('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Exceção - Ler Volume Carga</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<form
									onSubmit={function (e) {
										e.preventDefault();
										setMostraModalConfirm(true);
										setMsgPrincipalModal(
											`Será incluída uma exeção para ${
												tipoDados === '0' ? `a ID da O.S. nº: ${idDados}` : `o ID do Processo de Carga nº: ${idDados}`
											} .Confirma a inclusão da exceção?`
										);
										setMsgAcao1('Cancelar');
									}}
								>
									<div className="row">
										<div className="col-sm col-md col-lg-2">
											<InputSemZeroEsquerda
												id="idPedido"
												name="idPedido"
												label="ID"
												type="text"
												value={idDados}
												onChange={setIdDados}
												useRef={idDadosRef}
												onBlur={validarIDOSProcesso}
												required
											/>
										</div>
										<div className="col-sm-3" onBlur={validarIDOSProcesso}>
											<RadioGroup row value={tipoDados} onChange={(e) => setTipoDados(e.target.value)} className="mt-3">
												<FormControlLabel value="0" control={<Radio />} label="O.S." />
												<FormControlLabel value="1" control={<Radio />} label="Processo" />
											</RadioGroup>
										</div>

										<div className={!permExcecaoLerVolume ? `col-sm col-md col-lg-7` : `col-sm col-md col-lg-6`}>
											<div className="form-floating">
												<input
													required
													value={motivo}
													onChange={(e) => setMotivo(e.target.value)}
													className="form-control bordasInferiorInput text-black text-capitalize"
													placeholder=" "
													//ao rececebr o foco verificar se o idDados está preenchido senao focar no idDados
													onFocus={() => {
														if (!idDados) {
															idDadosRef.current?.focus();
														}
													}}
													ref={motivoRef}
												/>
												<label>Motivo</label>
											</div>
										</div>

										{permExcecaoLerVolume && (
											<div className="col-sm col-md col-lg">
												<BotaoCustomizado
													type="submit"
													className="btn btn-success mt-3"
													descricaoBotao="Gravar"
													icone={mdiReceiptTextPlusOutline}
													onclick={() => {}}
													size={1}
												/>
											</div>
										)}
									</div>
								</form>
								<div className="">
									<ClickableTable
										coluna={colunasGrid}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={false}
										acaoEditar={() => {}}
										acaoExcluir={() => {}}
										acaoVisualizar={() => {}}
										usaVisualizar={true}
										usaEditar={true}
										usaExcluir={true}
										acessopesquisa="id_os"
										labelpesquisa="Procurar por nº da O.S."
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<ModalCarregando mostrar={mostraModalCarregar} mensagem={msgModalCarregar} />

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={() => incluirExcecaoLerGtin(dadosEnvio)}
			/>
		</>
	);
};

export { ExcecaoLerVolumeCarga };
