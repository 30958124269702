/**
 * Função que remove espaços e converte o texto para minúsculas.
 * @param texto - O texto de entrada.
 * @returns O texto sem espaços e em minúsculas.
 */
const removeEspacoTextoMin = (texto: string): string => {
	return texto.replace(/\s+/g, '').toLowerCase();
  };
  
  export default removeEspacoTextoMin;
  