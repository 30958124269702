import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Api } from '../../../../services/api';
import { Modal, Spinner } from 'react-bootstrap';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { colunasGridTelaProdutosDePicking } from './components/colunasGridTelaPrincipal';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const ProdutosDePicking = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const usuario = sessionStorage.getItem('IsUserFKL') || '';
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [msgPrincipalModal, setMsgPrincipalModal] = useState<string>('');
	const [id_wms, setIDWMS] = useState<string>('');
	const [id_local, setIDLocal] = useState<string>('');
	const [msgAcao1, setMsgAcao1] = useState<string>('');
	const [mostraModalCarregar, setMostraModalCarregar] = useState<boolean>(false);
	const [textoModalCarregando, setTextoModalCarregando] = useState<string>('');
	const [itensPorPagina, setItensPorPagina] = useState(10);
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarProdutosPicking) {
				toast.warning('Você não tem permissão para acessar Lista de Produtos de Picking');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarProdutosPicking);
				setPermissaoCriar(permissoes.perfilPermissao.criarProdutosPicking);
				setPermissaoEditar(permissoes.perfilPermissao.editarProdutosPicking);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirProdutosPicking);
			}
		}
	}, [permissoes, erro, navigate]);

	//Depositante
	const [depositante, setDepositante] = useState('0');

	const selecionarDepositante = (dados: any) => {
		const id_depositante = dados.target.value;
		setDepositante(id_depositante);
		carregarDados(id_depositante);
	};

	const editarProduto = (dados: any) => {
		sessionStorage.setItem('IsFKLDepositantePicking', depositante);
		navigate(`/cadast/produto/prodpick/${dados.id_local}/editar`);
	};
	const excluirProduto = (dados: any) => {
		setIDWMS(dados.id_wms);
		setIDLocal(dados.id_local);
		setMsgPrincipalModal(`Deseja excluir o produto ${dados.codigo}`);
		setMsgAcao1('Cancelar');

		setMostraModalConfirm(true);
	};
	const visualizarProduto = (dados: any) => {
		sessionStorage.setItem('IsFKLDepositantePicking', depositante);
		navigate(`/cadast/produto/prodpick/${dados.id_local}/visualizar`);
	};

	//#region ==> Buscar dados do depositante ao informar pelo menos 3 caracteres e alimentar o datalist
	const [dadosDepositante, setDadosDepositante] = useState([]);

	async function buscarDepositante() {
		axios
			.get(Api + `/carregar/prodpick/depositante/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setDadosDepositante(resposta.data.depositante);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}
	//#endregion

	//#region Inicio novo desenvolvimento de funções

	function carregarDados(id_cliente: string) {
		if (id_cliente === '0') {
			setData([]);
			return;
		}
		setMostraModalCarregar(true);
		setTextoModalCarregando('Carregando dados...');
		axios
			.get(Api + `/lista/prodpick/filial/${id_filial}/${id_cliente}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				setData(resposta.data.produto);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data);
				}
			});
	}

	//#region -> Excluir
	function deleteProduto() {
		setMostraModalCarregar(true);
		setMostraModalConfirm(false);
		setTextoModalCarregando('Excluindo produto...');
		axios
			.delete(Api + `/excluir/prodpick/${id_filial}/${depositante}/${id_wms}/${id_local}/${usuario}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				toast.success(resposta.data.message);
				carregarDados(depositante);
			})
			.catch(function (erro) {
				setMostraModalCarregar(false);
				setTextoModalCarregando('');
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	useEffect(() => {
		carregarDados(depositante);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Listagem de Produtos de Picking</h4>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									{permissaoCriar && (
										<div className="col d-flex justify-content-start">
											<button
												className="btn btn-secondary"
												onClick={() => {
													if (depositante === '0') {
														return toast.error('Selecione um depositante para cadastrar um novo produto');
													}
													sessionStorage.setItem('IsFKLDepositantePicking', depositante);
													navigate('/cadast/produto/prodpick/novo/criar');
												}}
											>
												<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
												Novo Produto
											</button>
										</div>
									)}
									<div className="col d-flex justify-content-start">
										<select
											className="form-select bordasInferiorInput text-black"
											id="depositante"
											name="depositante"
											value={depositante}
											onClick={buscarDepositante}
											onChange={selecionarDepositante}
										>
											<option value="0" className="text-black">
												Selecione o Depositante
											</option>

											{dadosDepositante.map((item: any) => {
												return (
													<option key={item.id} value={item.id} className="text-black text-uppercase">
														{item.value}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="">
									<ClickableTable
										coluna={colunasGridTelaProdutosDePicking}
										itemsPerPage={itensPorPagina}
										data={data}
										onRowClick={() => {}}
										usaAcoes={true}
										acaoEditar={editarProduto}
										acaoExcluir={excluirProduto}
										acaoVisualizar={visualizarProduto}
										usaVisualizar={permissaoVisualizar}
										usaEditar={permissaoEditar}
										usaExcluir={permissaoExcluir}
										acessopesquisa="codigo"
										labelpesquisa="Procurar por produto de picking"
									/>
								</div>
								<div className="container">
									<div className="row">
										<div className="col-lg-2 m-auto text-center">
											<label>Itens por Página</label>
										</div>
										<div className="w-100"></div>
										<div className="col-lg-1 m-auto">
											<select
												value={itensPorPagina}
												onChange={function (e: any) {
													setItensPorPagina(e.target.value);
												}}
												className="form-select form-select-sm"
												aria-label=".form-select-sm example"
											>
												<option value={10} selected>
													10
												</option>
												<option value={20}>20</option>
												<option value={30}>30</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*MODAL CARREGANDO */}
			<Modal backdrop="static" size="sm" keyboard={false} centered>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1={msgAcao1}
				msgAcao2="Confirmar"
				msgPrincipal={msgPrincipalModal}
				Acao1={function () {
					setMostraModalConfirm(false);
				}}
				Acao2={deleteProduto}
			/>

			<ModalCarregando mostrar={mostraModalCarregar} mensagem={textoModalCarregando} />
		</>
	);
};

export { ProdutosDePicking };
