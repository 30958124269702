// Objetivo: Exportar as colunas do grid para serem utilizadas no componente EnderecoArmazem
import { ITabela } from "../../../../../components/Table/TabelaInterface";

export const colunasGrid: ITabela[] = [
	{
		titulo: 'ID Endereço',
		acesso: 'id_local',
	},
	{
		titulo: 'Status',
		acesso: 'ativo',
	},
	{
		titulo: 'Tipo',
		acesso: 'tipo',
	},

	{
		titulo: 'Temperatura',
		acesso: 'temperatura',
	},
	{
		titulo: 'Armazém',
		acesso: 'amz',
	},
	{
		titulo: 'Corredor',
		acesso: 'cor',
	},
	{
		titulo: 'Box',
		acesso: 'box',
	},
	{
		titulo: 'Altura',
		acesso: 'alt',
	},
	{
		titulo: 'Profundidade',
		acesso: 'prof',
	},
	{
		titulo: 'Usuário',
		acesso: 'usuario',
	},
];