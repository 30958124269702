import { useNavigate } from 'react-router-dom';

const NovaBarraPallet = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Barras Ativas</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tab-ger-estoq-barraPalletNovo-geral"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											href="#tab-ger-estoq-barraPalletNovo-barrasPallet"
											data-bs-toggle="tab"
											aria-expanded="false"
											className="nav-link rounded-0"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Barras / Pallet</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tab-ger-estoq-barraPalletNovo-geral">
										<div className="row mb-1">
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="processo">Processo</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="cliente">Cliente</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="criterio">Critério</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="observacoes">Observações</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="pallet">Pallet</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="produto">Produto</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="quantidade">Quantidade</label>
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="empenho">Empenho</label>
												</div>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-sm-2">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="pesoBruto">Peso Bruto</label>
												</div>
											</div>
											<div className="col-sm-2">
												<div className="form-floating">
													<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
													<label htmlFor="pesoLiquido">Peso Líquido</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane show" id="tab-ger-estoq-barraPalletNovo-barrasPallet">
										<div className="row mt-1">
											<div className="col-sm-3 mb-2">
												<button
													type="button"
													className="btn btn-warning rounded-pill"
													data-bs-toggle="modal"
													data-bs-target="#modal-ger-estoq-barraPalletNovo-novBarra"
												>
													Nova Barra
												</button>
											</div>
											<div className="row">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
														<thead className="table-light">
															<tr>
																<th>Pallet</th>
																<th>Barra</th>
																<th>Código</th>
																<th>Peso Bruto</th>
																<th>Peso Liquído</th>
																<th>Produção</th>
																<th>Validade</th>
																<th>Barra Sistem.</th>
																<th>RG</th>
																<th>Ações</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>61</td>
																<td>1234567890</td>
																<td>7001000322</td>
																<td>134,00</td>
																<td>120,50</td>
																<td>30/12/2021</td>
																<td>30/12/2023</td>
																<td>123456</td>
																<td>123456</td>
																<td className="d-flex flex-nowrap table-action">
																	<a
																		data-bs-toggle="modal"
																		data-bs-target="#modal-ger-estoq-barraPalletNovo-editBarra"
																		className="action-icon"
																	>
																		<i className="mdi mdi-square-edit-outline"></i>
																	</a>
																	<a href="javascript:void(0);" className="action-icon">
																		<i className="mdi mdi-delete"></i>
																	</a>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/gerenciamento/estoque/barraPallet')}
										>
											Cancelar
										</button>
										<button
											className="btn btn-info mb-2 me-1"
											type="button"
											onClick={() => navigate('/gerenciamento/estoque/barraPallet')}
										>
											Salvar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-ger-estoq-barraPalletNovo-novBarra"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Nova Barra
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Pallet</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
										<div className="col-sm-8">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Barra</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Código</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">RG</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Data Validade</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Peso Bruto</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="input-group flex-nowrap">
												<span className="input-group-text">Peso Liq.</span>
												<input type="text" className="form-control" aria-describedby="basic-addon1" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
									Cancelar
								</button>
								<button type="button" className="btn btn-info" data-bs-dismiss="modal">
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-ger-estoq-barraPalletNovo-editBarra"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Detalhe da Barra
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-4">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="pallet">Pallet</label>
											</div>
										</div>
										<div className="col-sm-8">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="barra">Barra</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="codigo">Código</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="rg">RG</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="dataValidade">Data Validade</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="pesoBruto">Peso Bruto</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-floating">
												<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" aria-describedby="basic-addon1" />
												<label htmlFor="pesoLiquido">Peso Liq.</label>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
									Cancelar
								</button>
								<button type="button" className="btn btn-info" data-bs-dismiss="modal">
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovaBarraPallet };
