const Integracao = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<div className="page-title-right"></div>
						<h4 className="page-title">Integração Arq. Produtos</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row mb-2">
								<div className="col-sm-4">
									<a href="#" className="btn btn-info rounded-pill mb-2">
										<i className="mdi mdi-plus-circle me-2"></i>
										Importação Arq.
									</a>
								</div>
							</div>
							<div className="table-responsive">
							<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
									<thead className="table-cabecalho">
										<tr>
											<th>Depositante</th>
											<th>Arquivo</th>
											<th>Data</th>
											<th>Usuário</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Dep 1</td>
											<td>Arquivo.xls</td>
											<td>19/10/2021 18:00:00</td>
											<td>hrocha</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Integracao };
