import { Link } from 'react-router-dom';
import { ModalIntegracaoSaidaExport } from './modalIntegracaoSaidaExport';

const IntegracaoSaida = (): JSX.Element => {
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Integração Saída</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2" style={{ display: 'flex', alignItems: 'end' }}>
									<div className="col-sm-3">
										<div className="form-floating">

											<input type="date" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label htmlFor="">DATA INICIO</label>
										</div>
									</div>
									<div className="col-sm-3">
										<div className=" form-floating">
											<input type="date" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label htmlFor="">DATA FIM</label>
										</div>
									</div>
									<div className="col-sm-3 form-floating p-0">

										<select className="form-select bordasInferiorInput text-black text-capitalize" placeholder=''>
											<option value="">---</option>
										</select>
										<label>Depositante</label>
									</div>
									<div className="col-1">
										<button className="btn btn-secondary">
											<i className="mdi mdi-magnify"></i>
										</button>
									</div>
									<div className="col-2 d-flex justify-content-end">
										<a
											className="btn btn-warning dropdown-toggle"
											data-bs-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Exportação Arqs
										</a>
										<div className="dropdown-menu">
											<a
												className="dropdown-item"
												data-bs-toggle="modal"
												data-bs-target="#modal-oper-gestPed-integrSaida-export"
											>
												Exportar TXT
											</a>
											<a
												className="dropdown-item"
												data-bs-toggle="modal"
												data-bs-target="#modal-oper-gestPed-integrSaida-export"
											>
												Exportar Excel
											</a>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>
													<input type="checkbox" />
												</th>
												<th>ID Pedido</th>
												<th>Cliente</th>
												<th>CNPJ</th>
												<th>Data Carga</th>
												<th>Pedido</th>
												<th>Destinatário</th>
												<th>Pedido Cliente</th>
												<th>Status</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<input type="checkbox" />
												</td>
												<td>61</td>
												<td>FKL Service</td>
												<td>00132143000181</td>
												<td>19/11/2021 16:00:00</td>
												<td>Pedidos HRR/1234</td>
												<td>HRR Alimentos</td>
												<td>9834</td>
												<td>Pendente</td>
												<td className="d-flex flex-nowrap table-action">
													<Link to={`/pco/contrFisc/gestPedidos/integracaoSaida/${0}`} className="action-icon">
														<i className="mdi mdi-square-edit-outline"></i>
													</Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalIntegracaoSaidaExport />
		</>
	);
};

export { IntegracaoSaida };
